import { Action } from "redux";

export enum EmailTemplateActionTypes {
  AddOrUpdateEmailTemplate = "EmailTemplates/AddOrUpdateEmailTemplate",
  DeleteEmailTemplate = "EmailTemplates/DeleteEmailTemplate"
}

export interface AddOrUpdateEmailTemplate extends Action {
  type: EmailTemplateActionTypes.AddOrUpdateEmailTemplate;
  name: string;
  templateContent: string;
}

export interface DeleteEmailTemplate extends Action {
  type: EmailTemplateActionTypes.DeleteEmailTemplate;
  name: string;
}

export const emailTemplateActions = {
  addTemplate: (name: string, templateContent: string): AddOrUpdateEmailTemplate => ({
    type: EmailTemplateActionTypes.AddOrUpdateEmailTemplate,
    name,
    templateContent
  }),
  deleteTemplate: (name: string): DeleteEmailTemplate => ({
    type: EmailTemplateActionTypes.DeleteEmailTemplate,
    name
  })
};

export type EmailTemplateActions = AddOrUpdateEmailTemplate | DeleteEmailTemplate;
