/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WidgetTypes {
    RecentOrders = 'RecentOrders',
    QuickPriceCheck = 'QuickPriceCheck',
    TechDocPortal = 'TechDocPortal',
    DeliveryTimes = 'DeliveryTimes',
    ProductLifeCycleStatusCheck = 'ProductLifeCycleStatusCheck',
    TerminologyBank = 'TerminologyBank'
}

export function WidgetTypesFromJSON(json: any): WidgetTypes {
    return WidgetTypesFromJSONTyped(json, false);
}

export function WidgetTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetTypes {
    return json as WidgetTypes;
}

export function WidgetTypesToJSON(value?: WidgetTypes | null): any {
    return value as any;
}

