import React, { ReactElement } from "react";
import { TFunction } from "i18next";
import { NumericInput } from "@abb/abb-common-ux-react";
import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { infoTypeConstants } from "../containers/QuoteView";
import { priceStringToFloatNumber } from "utilities/currencyUtils";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export interface QuoteGridNumericInputRendererProps extends ICellRendererParams {
  value: number | undefined | null;
  suffix: string;
  infoType: string;
  type: "normal" | "compact";
  onChange: (
    orderingCode: string,
    discountRate: number | null | undefined,
    unitQuotedPrice: number | null | undefined
  ) => void;
  min?: number;
  max?: number;
  isSimpleRenderer?: boolean;
  t: TFunction;
  hide?: boolean;
}

export const QuoteGridNumericInputRenderer = (props: QuoteGridNumericInputRendererProps) => {
  const handleChange = (value: number | null | undefined): void => {
    switch (props.infoType) {
      case infoTypeConstants.discountRate:
        props.onChange(props.data.orderingCode, value, props.data.unitQuotedPrice);
        break;
      case infoTypeConstants.unitQuotedPrice:
        props.onChange(props.data.orderingCode, props.data.discountRate, value);
        break;
    }
  };

  if (props.isSimpleRenderer) {
    const res = props.hide ? "" : props.value + " " + props.suffix;
    return res as unknown as ReactElement<any, any>;
  }

  return (
    <Container>
      {!props.hide ? (
        <NumericInput
          style={{ width: "130px" }}
          type={props.type}
          value={props.value}
          min={props.min}
          max={props.max}
          suffix={props.suffix}
          validationBar={props.value == null ? "error" : undefined}
          validationIcon={props.value == null ? "error" : undefined}
          validationMessage={props.value == null ? props.t("Required") : undefined}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          onLostFocus={() => {
            if (
              props.data.quotedPrice == null &&
              props.infoType == infoTypeConstants.unitQuotedPrice
            ) {
              handleChange(priceStringToFloatNumber(props.data.unitPrice));
            }
            if (
              props.data.discountRate == null &&
              props.infoType == infoTypeConstants.discountRate
            ) {
              handleChange(0);
            }
          }}
        />
      ) : undefined}
    </Container>
  );
};
