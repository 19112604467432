import {
  ApiEmailTemplatesEmailPreviewPostRequest,
  ApiEmailTemplatesTemplateNameDeleteRequest,
  ApiEmailTemplatesTemplateNameGetRequest,
  ApiEmailTemplatesUploadMultiplePostRequest,
  ApiResponse,
  EmailTemplate
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";
import { handleFileDownloadResponse } from "utilities/fileDownload";
import { EmailTemplateActions, EmailTemplateActionTypes } from "../actions/emailTemplateActions";
import { emailTemplateSagas } from "../sagas/emailTemplateSagas";

interface Requests {
  getAllRawEmailTemplates: RequestState<EmailTemplate[], undefined>;
  exportEmailTemplate: RequestState<ApiResponse<Blob>, ApiEmailTemplatesTemplateNameGetRequest>;
  uploadEmailTemplate: RequestState<void, undefined>;
  uploadEmailTemplates: RequestState<ApiEmailTemplatesUploadMultiplePostRequest, undefined>;
  deleteEmailTemplate: RequestState<void, ApiEmailTemplatesTemplateNameDeleteRequest>;
  sendPreview: RequestState<any, ApiEmailTemplatesEmailPreviewPostRequest>;
  getRequiredEmailTemplateKeys: RequestState<string[], undefined>;
}

export interface ManageEmailTemplatesState {
  requests: Requests;
  allRawEmailTemplates: EmailTemplate[] | undefined;
  downloadingTemplateNames: string[];
  emailTemplatesBeingProcessedForPreview: string[];
  requiredEmailTemplateKeys: string[];
}

const defaultState: ManageEmailTemplatesState = {
  requests: {
    getAllRawEmailTemplates: createInitialRequest(),
    exportEmailTemplate: createInitialRequest(),
    uploadEmailTemplate: createInitialRequest(),
    deleteEmailTemplate: createInitialRequest(),
    uploadEmailTemplates: createInitialRequest(),
    sendPreview: createInitialRequest(),
    getRequiredEmailTemplateKeys: createInitialRequest()
  },
  allRawEmailTemplates: undefined,
  downloadingTemplateNames: [],
  emailTemplatesBeingProcessedForPreview: [],
  requiredEmailTemplateKeys: []
};

const handleGetTemplateActions = (
  state: ManageEmailTemplatesState,
  action: EmailTemplateActions
) => {
  if (emailTemplateSagas.exportEmailTemplate.isRequestAction(action)) {
    state = produce(state, (draft) => {
      if (!action.payload.templateName) return state;
      draft.downloadingTemplateNames.push(action.payload.templateName);
    });
  } else if (emailTemplateSagas.exportEmailTemplate.isCompletedAction(action)) {
    if (!action.payload) return state;
    const parts = new URL(action.payload.raw.url).pathname.split("/");
    if (parts.length < 4) return state;
    const templateName = parts[3];
    state = produce(state, (draft) => {
      draft.downloadingTemplateNames = draft.downloadingTemplateNames.filter(
        (n) => n !== templateName
      );
    });
  }

  return state;
};

export function emailTemplatesReducer(
  state: ManageEmailTemplatesState = defaultState,
  action: EmailTemplateActions
): ManageEmailTemplatesState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: emailTemplateSagas.getAllRawTemplates.actionTypes,
      key: "getAllRawTemplates"
    },
    {
      actionTypes: emailTemplateSagas.exportEmailTemplate.actionTypes,
      key: "exportEmailTemplate"
    },
    {
      actionTypes: emailTemplateSagas.uploadEmailTemplate.actionTypes,
      key: "uploadEmailTemplate"
    },
    {
      actionTypes: emailTemplateSagas.deleteEmailTemplate.actionTypes,
      key: "deleteEmailTemplate"
    },
    {
      actionTypes: emailTemplateSagas.uploadEmailTemplates.actionTypes,
      key: "uploadEmailTemplates"
    },
    {
      actionTypes: emailTemplateSagas.sendPreview.actionTypes,
      key: "sendPreview"
    },
    {
      actionTypes: emailTemplateSagas.getRequiredEmailTemplateKeys.actionTypes,
      key: "getRequiredEmailTemplateKeys"
    }
  ]);

  if (emailTemplateSagas.getAllRawTemplates.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.allRawEmailTemplates = action.payload;
    });
  } else if (emailTemplateSagas.exportEmailTemplate.isCompletedAction(action)) {
    const parts = action.payload.raw.url.split("/");
    const templateName = parts[parts.length - 1];
    handleFileDownloadResponse(action.payload.raw, templateName + ".cshtml");
  }

  if (emailTemplateSagas.getRequiredEmailTemplateKeys.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.requiredEmailTemplateKeys = action.payload;
    });
  }

  state = handleGetTemplateActions(state, action);

  switch (action.type) {
    case EmailTemplateActionTypes.AddOrUpdateEmailTemplate:
      state = produce(state, (draft) => {
        const template = draft.allRawEmailTemplates?.find((t) => t.name === action.name);
        if (template) {
          template.templateContent = action.templateContent;
        } else {
          draft.allRawEmailTemplates?.push({
            name: action.name,
            templateContent: action.templateContent
          });
        }
      });
      break;
    case EmailTemplateActionTypes.DeleteEmailTemplate:
      state = produce(state, (draft) => {
        draft.allRawEmailTemplates = draft.allRawEmailTemplates?.filter(
          (t) => t.name !== action.name
        );
      });
      break;
  }

  return state;
}

export const getAllRawEmailTemplates = (state: AppState) =>
  state.emailTemplates.allRawEmailTemplates;
export const getExportEmailTemplate = (state: AppState) =>
  state.emailTemplates.requests.exportEmailTemplate;
export const getUploadEmailTemplateFile = (state: AppState) =>
  state.emailTemplates.requests.uploadEmailTemplate;
export const getDeleteEmailTemplate = (state: AppState) =>
  state.emailTemplates.requests.deleteEmailTemplate;
export const getDownloadingTemplateNames = (state: AppState) =>
  state.emailTemplates.downloadingTemplateNames;
export const getUploadEmailTemplatesRequest = (state: AppState) =>
  state.emailTemplates.requests.uploadEmailTemplates;
export const getSendPreviewRequest = (state: AppState) => state.emailTemplates.requests.sendPreview;
export const getRequiredEmailTemplateKeys = (state: AppState) =>
  state.emailTemplates.requiredEmailTemplateKeys;
