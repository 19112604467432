import { ApiUsersGetRequest } from "../../../../api/apis/UsersApi";
import { Action } from "redux";
import { ManageUserVm } from "api";

export enum ManageUsersViewActionTypes {
  StoreClickedGridIndex = "ManageUsers/StoreClickedGridIndex",
  ChangeManageUsersCriteria = "ManageUsers/ChangeManageUsersCriteria",
  SetSelectedUser = "ManageUsers/SetSelectedUser"
}

export interface StoreClickedGridIndex extends Action {
  type: ManageUsersViewActionTypes.StoreClickedGridIndex;
  index: number | undefined;
}

export interface ChangeManageUsersCriteria extends Action {
  type: ManageUsersViewActionTypes.ChangeManageUsersCriteria;
  criteria: ApiUsersGetRequest;
}

export interface SetSelectedUser extends Action {
  type: ManageUsersViewActionTypes.SetSelectedUser;
  user: ManageUserVm | undefined;
}

export const manageUsersViewActions = {
  storeClickedGridIndex: (index: number | undefined): StoreClickedGridIndex => ({
    type: ManageUsersViewActionTypes.StoreClickedGridIndex,
    index
  }),
  changeManageUsersCriteria: (criteria: ApiUsersGetRequest): ChangeManageUsersCriteria => ({
    type: ManageUsersViewActionTypes.ChangeManageUsersCriteria,
    criteria
  }),
  setSelectedUser: (user: ManageUserVm | undefined): SetSelectedUser => ({
    type: ManageUsersViewActionTypes.SetSelectedUser,
    user
  })
};

export type ManageUsersViewActions =
  | StoreClickedGridIndex
  | ChangeManageUsersCriteria
  | SetSelectedUser;
