import { RoleGroups } from "api";
import { HotjarSettings } from "configuration/hotJarConfig";
import { Action } from "redux";

export enum CommonActionTypes {
  SetConfiguratorBaseUrl = "Common/SetConfiguratorBaseUrl",
  SetApplicationRoleGroup = "Common/SetApplicationRoleGroup",
  ToggleCookiesConsentDialogVisibility = "Common/ToggleCookiesConsentDialogVisibility",
  AcceptCookiesConsent = "Common/AcceptCookiesConsent",
  StoreHotjarSettings = "Common/StoreHotjarSettings",
  SetIsHotjarInitialized = "Common/SetIsHotjarInitialized",
  DisableRegistrationWall = "Common/DisableRegistrationWall"
}

export interface SetConfiguratorBaseUrl extends Action {
  type: CommonActionTypes.SetConfiguratorBaseUrl;
  baseUrl: string | undefined;
}

export interface SetApplicationRoleGroup extends Action {
  type: CommonActionTypes.SetApplicationRoleGroup;
  roleGroup: RoleGroups | undefined;
}
export interface ToggleCookiesConsentDialogVisibility extends Action {
  type: CommonActionTypes.ToggleCookiesConsentDialogVisibility;
  showDialog: boolean;
}
export interface AcceptCookiesConsent extends Action {
  type: CommonActionTypes.AcceptCookiesConsent;
  acceptCookies: boolean;
}

export interface StoreHotjarSettings extends Action {
  type: CommonActionTypes.StoreHotjarSettings;
  settings: HotjarSettings;
}

export interface SetIsHotjarInitialized extends Action {
  type: CommonActionTypes.SetIsHotjarInitialized;
  isInitialized: boolean;
}

export interface DisableRegistrationWall extends Action {
  type: CommonActionTypes.DisableRegistrationWall;
}

export const commonActions = {
  setConfiguratorBaseUrl: (baseUrl: string | undefined): SetConfiguratorBaseUrl => ({
    type: CommonActionTypes.SetConfiguratorBaseUrl,
    baseUrl
  }),
  setApplicationRoleGroup: (roleGroup: RoleGroups | undefined): SetApplicationRoleGroup => ({
    type: CommonActionTypes.SetApplicationRoleGroup,
    roleGroup
  }),
  toggleCookiesConsentDialogVisibility: (
    showDialog: boolean
  ): ToggleCookiesConsentDialogVisibility => ({
    type: CommonActionTypes.ToggleCookiesConsentDialogVisibility,
    showDialog
  }),
  acceptCookiesConsent: (acceptCookies: boolean): AcceptCookiesConsent => ({
    type: CommonActionTypes.AcceptCookiesConsent,
    acceptCookies
  }),
  storeHotjarSettings: (settings: HotjarSettings): StoreHotjarSettings => ({
    type: CommonActionTypes.StoreHotjarSettings,
    settings
  }),
  setIsHotjarInitialized: (isInitialized: boolean): SetIsHotjarInitialized => ({
    type: CommonActionTypes.SetIsHotjarInitialized,
    isInitialized
  }),
  disableRegistrationWall: (): DisableRegistrationWall => ({
    type: CommonActionTypes.DisableRegistrationWall
  })
};

export type CommonActions =
  | SetConfiguratorBaseUrl
  | SetApplicationRoleGroup
  | ToggleCookiesConsentDialogVisibility
  | AcceptCookiesConsent
  | StoreHotjarSettings
  | SetIsHotjarInitialized
  | DisableRegistrationWall;
