/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShoppingCartStateTypes {
    Draft = 'Draft',
    WaitingForQuote = 'WaitingForQuote',
    Quote = 'Quote',
    OrderWaitingForApproval = 'OrderWaitingForApproval',
    OrderPlaced = 'OrderPlaced',
    Expired = 'Expired',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
    QuoteWaitingForApproval = 'QuoteWaitingForApproval',
    Archived = 'Archived',
    OrderPlacedExternally = 'OrderPlacedExternally',
    FrameAgreement = 'FrameAgreement',
    WaitingForFrameAgreementApproval = 'WaitingForFrameAgreementApproval',
    WaitingForFrameAgreement = 'WaitingForFrameAgreement',
    WaitingForRevision = 'WaitingForRevision',
    Revised = 'Revised'
}

export function ShoppingCartStateTypesFromJSON(json: any): ShoppingCartStateTypes {
    return ShoppingCartStateTypesFromJSONTyped(json, false);
}

export function ShoppingCartStateTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartStateTypes {
    return json as ShoppingCartStateTypes;
}

export function ShoppingCartStateTypesToJSON(value?: ShoppingCartStateTypes | null): any {
    return value as any;
}

