/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationNotificationDto,
    ApplicationNotificationDtoFromJSON,
    ApplicationNotificationDtoFromJSONTyped,
    ApplicationNotificationDtoToJSON,
} from './ApplicationNotificationDto';

/**
 * 
 * @export
 * @interface ApplicationNotificationVm
 */
export interface ApplicationNotificationVm {
    /**
     * 
     * @type {ApplicationNotificationDto}
     * @memberof ApplicationNotificationVm
     */
    notification?: ApplicationNotificationDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationNotificationVm
     */
    nextNotificationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationNotificationVm
     */
    previousNotificationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationNotificationVm
     */
    notificationCount: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationNotificationVm
     */
    orderOfCurrentNotification: number;
}

export function ApplicationNotificationVmFromJSON(json: any): ApplicationNotificationVm {
    return ApplicationNotificationVmFromJSONTyped(json, false);
}

export function ApplicationNotificationVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationNotificationVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notification': !exists(json, 'notification') ? undefined : ApplicationNotificationDtoFromJSON(json['notification']),
        'nextNotificationId': !exists(json, 'nextNotificationId') ? undefined : json['nextNotificationId'],
        'previousNotificationId': !exists(json, 'previousNotificationId') ? undefined : json['previousNotificationId'],
        'notificationCount': json['notificationCount'],
        'orderOfCurrentNotification': json['orderOfCurrentNotification'],
    };
}

export function ApplicationNotificationVmToJSON(value?: ApplicationNotificationVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notification': ApplicationNotificationDtoToJSON(value.notification),
        'nextNotificationId': value.nextNotificationId,
        'previousNotificationId': value.previousNotificationId,
        'notificationCount': value.notificationCount,
        'orderOfCurrentNotification': value.orderOfCurrentNotification,
    };
}

