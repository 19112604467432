/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductLifecycleStatusDto
 */
export interface ProductLifecycleStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ProductLifecycleStatusDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductLifecycleStatusDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLifecycleStatusDto
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLifecycleStatusDto
     */
    lifeCycleStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLifecycleStatusDto
     */
    nextLifeCycleStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductLifecycleStatusDto
     */
    lifeCycleStatusWillChangeDate?: Date | null;
}

export function ProductLifecycleStatusDtoFromJSON(json: any): ProductLifecycleStatusDto {
    return ProductLifecycleStatusDtoFromJSONTyped(json, false);
}

export function ProductLifecycleStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductLifecycleStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'lifeCycleStatus': !exists(json, 'lifeCycleStatus') ? undefined : json['lifeCycleStatus'],
        'nextLifeCycleStatus': !exists(json, 'nextLifeCycleStatus') ? undefined : json['nextLifeCycleStatus'],
        'lifeCycleStatusWillChangeDate': !exists(json, 'lifeCycleStatusWillChangeDate') ? undefined : (json['lifeCycleStatusWillChangeDate'] === null ? null : new Date(json['lifeCycleStatusWillChangeDate'])),
    };
}

export function ProductLifecycleStatusDtoToJSON(value?: ProductLifecycleStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'serialNumber': value.serialNumber,
        'lifeCycleStatus': value.lifeCycleStatus,
        'nextLifeCycleStatus': value.nextLifeCycleStatus,
        'lifeCycleStatusWillChangeDate': value.lifeCycleStatusWillChangeDate === undefined ? undefined : (value.lifeCycleStatusWillChangeDate === null ? null : value.lifeCycleStatusWillChangeDate.toISOString()),
    };
}

