import { Link } from "@abb/abb-common-ux-react";
import { Icon } from "@abb/abb-common-ux-react/components/Icon";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { ReactElement } from "react";
import styled from "styled-components";

interface EqualColumnFlexContainerProps {
  children: ReactElement | ReactElement[];
  gap?: string;
  direction?: string;
  grow?: number;
}

const EqualColumnContainer = styled.div<{ direction?: string; grow?: number; gap?: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: ${(props) => props.gap ?? props.theme.sizes.l};
  flex-direction: ${(props) => props.direction};
  > * {
    flex-basis: 0;
    flex-grow: ${(props) => props.grow ?? 1};
  }
`;

export const ContainerWIthTopBottomMargin = styled.div<{ margin?: string }>`
  margin-top: ${(props) => (props.margin ? props.margin : props.theme.sizes.l)};
  margin-bottom: ${(props) => (props.margin ? props.margin : props.theme.sizes.l)};
`;

export const EqualColumnFlexContainer = ({
  children,
  ...rest
}: EqualColumnFlexContainerProps): JSX.Element => {
  return <EqualColumnContainer {...rest}>{children}</EqualColumnContainer>;
};

const Container = styled.div<{ direction?: string; gap?: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: ${(props) => props.gap ?? props.theme.sizes.l};
  flex-direction: ${(props) => props.direction};
`;

interface FlexContainerProps {
  children: ReactElement | ReactElement[];
  gap?: string;
  direction?: string;
  grow?: number;
}

export const FlexContainer = ({ children, ...rest }: FlexContainerProps): JSX.Element => {
  return <Container {...rest}>{children}</Container>;
};

const Column = styled.div<{ grow?: string; gap?: string }>`
  flex-grow: ${(props) => props.grow};
`;

interface FlexColumnProps {
  children: ReactElement | ReactElement[];
  grow?: string;
  basis?: string;
}

export const FlexColumn = ({ children, ...rest }: FlexColumnProps): JSX.Element => {
  return <Column {...rest}>{children}</Column>;
};

export const ProductDetailsColumn = styled(Grid)<{ $isSelected?: boolean }>`
  margin-bottom: ${(props) => props.theme.sizes.xs};
  min-width: 200px;

  > div:first-child {
    margin-top: 0;
  }
  border-top: ${(props) =>
    props.$isSelected
      ? `${props.theme.sizes.xs} solid ${props.theme.colors.brandRed}`
      : `${props.theme.sizes.xs} solid transparent`};
`;

export const ProductFeature = styled(Grid)<{ $lite?: boolean }>`
  background-color: ${(props) =>
    props.$lite ? props.theme.colors.whitePrimary : props.theme.colors.grey10};
  min-height: 60px;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.xs};
  padding: ${(props) => props.theme.sizes.s + " " + props.theme.sizes.sm};
  border: ${(props) => (props.$lite ? "none" : `1px solid ${props.theme.colors.grey30}`)};
  border-left: 0;
  border-right: 0;
`;

export const ProductPreview = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  minHeight: "120px",
  marginBottom: theme.sizes.l,
  marginTop: theme.sizes.l
}));

export const ProductTitle = styled(ProductFeature)`
  height: 90px;
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.xs};
  background-color: ${(props) => props.theme.colors.grey10};
  border-style: solid;
  border-color: ${(props) => props.theme.colors.grey30};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
`;

export const AdditionalFeatureContainer = styled(Grid)(({ theme }) => ({
  minHeight: "32px",
  fontWeight: "normal",
  fontSize: theme.fonts.sizes.fontSizeM + " !important",
  ".ABB_CommonUX_Checkbox__root": {
    width: "100%",
    height: "auto",
    fontSize: theme.fonts.sizes.fontSizeM + " !important",
    span: {
      height: "auto",
      whiteSpace: "normal"
    }
  }
}));

export const LinkIcon = styled(Icon)(({ theme }) => ({
  marginRight: theme.sizes.xs,
  alignItems: "center"
}));

export const ClickableLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: theme.sizes.xxs
}));

export const PreviewImage = styled.img({
  width: "70%",
  maxWidth: "120px"
});

export const SmallText = styled.div(({ theme }) => ({
  fontSize: theme.fonts.sizes.fontSizeXs
}));

export const RecommendedTag = styled(Grid)<{ $isRecommended?: boolean }>(
  ({ $isRecommended, theme }) => ({
    visibility: $isRecommended ? "visible" : "hidden",
    margin: `${theme.sizes.s} 0`,
    fontWeight: "bold",
    ">div": {
      textAlign: "center"
    }
  })
);

export const ProductShortText = styled(Stack)(() => ({
  textOverflow: "ellipsis"
}));
