import { MenuItemSelection } from "models/menuItemSelection";
import React, { FC } from "react";
import { NavItem } from "./NavItem";

export interface TopNaviItemsProps {
  menuItems: MenuItemSelection[];
  useExternalLinks: boolean;
  enableReactRouterLinks: boolean;
  activePath?: string;
  baseUrl: string;
  onClick: (
    internalRoute: string,
    externalRoute: string | null | undefined,
    isLogOut: boolean
  ) => void;
  translateText?: (text: string) => string;
  isMobile: boolean;
}

export const TopNaviItems: FC<TopNaviItemsProps> = ({
  activePath,
  useExternalLinks,
  enableReactRouterLinks,
  menuItems,
  baseUrl,
  onClick,
  translateText,
  isMobile
}: TopNaviItemsProps) => {
  return (
    <>
      {menuItems.map((menuItem, idx) => (
        <NavItem
          isMobile={isMobile}
          menuItem={menuItem}
          activePath={activePath}
          useExternalLinks={useExternalLinks}
          enableReactRouterLinks={enableReactRouterLinks}
          onSelect={onClick}
          baseUrl={baseUrl}
          key={idx}
          translateText={translateText}
        />
      ))}
    </>
  );
};
