import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { productSelectionMatrixSagas } from "../sagas/productSelectionMatrixSagas";
import { Action } from "redux";
import { ModularProductsVm, StaticProductsVm } from "api";
import { SSC600Settings } from "api/models/SSC600Settings";

interface Requests {
  getModularProducts: RequestState<ModularProductsVm>;
  getStaticProducts: RequestState<StaticProductsVm>;
}

export interface ProductSelectionMatrixViewState {
  requests: Requests;
  modularProductsVm: ModularProductsVm;
  staticProductsVm: StaticProductsVm;
  ssc600Settings?: SSC600Settings;
}

const defaultState: ProductSelectionMatrixViewState = {
  modularProductsVm: {
    productSeriesCollection: [],
    productApplications: [],
    modularProducts: []
  },
  staticProductsVm: {
    productApplications: [],
    productSeries: [],
    categories: []
  },
  requests: {
    getModularProducts: createInitialRequest(),
    getStaticProducts: createInitialRequest()
  },
  ssc600Settings: undefined
};

export function productSelectionMatrixReducer(
  state: ProductSelectionMatrixViewState = defaultState,
  action: Action
): ProductSelectionMatrixViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: productSelectionMatrixSagas.getModularProducts.actionTypes,
      key: "getModularProducts"
    },
    {
      actionTypes: productSelectionMatrixSagas.getStaticProducts.actionTypes,
      key: "getStaticProducts"
    },
    {
      actionTypes: productSelectionMatrixSagas.getSSC600Settings.actionTypes,
      key: "getSSC600Settings"
    }
  ]);

  if (productSelectionMatrixSagas.getModularProducts.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.modularProductsVm = action.payload;
    });
  } else if (productSelectionMatrixSagas.getStaticProducts.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.staticProductsVm = action.payload;
    });
  } else if (productSelectionMatrixSagas.getSSC600Settings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.ssc600Settings = action.payload;
    });
  }

  return state;
}

export function getModularProducts(state: AppState): ModularProductsVm {
  return state.productSelectionMatrixView.modularProductsVm;
}

export function getStaticProducts(state: AppState): StaticProductsVm {
  return state.productSelectionMatrixView.staticProductsVm;
}

export function getSsc600Settings(state: AppState): SSC600Settings | undefined {
  return state.productSelectionMatrixView.ssc600Settings;
}
