import { RowClassParams, RowStyle } from "ag-grid-community";
import { TeamMemberDto, TeamMemberSources, TeamTypes, UserTeamRolesDto } from "api";
import { commonUXTheme } from "styles/commonUXVariables";

const roleAbbreviationFriendlyNameMap: { abbreviation: string, friendlyName: string, teamType: TeamTypes, isAdmin: boolean }[] = [
  { abbreviation: "BU1", friendlyName: "User administrator", teamType: TeamTypes.BusinessUnit, isAdmin: true },
  { abbreviation: "BU2", friendlyName: "User", teamType: TeamTypes.BusinessUnit, isAdmin: false },
];

export function roleAbbreviationToFriendlyName(abbreviation: string): string {
  const role = roleAbbreviationFriendlyNameMap.find(role => role.abbreviation === (abbreviation ?? "").toUpperCase());
  return role?.friendlyName || "";
}

export function getRoleAbbreviationsForTeamType(teamType?: TeamTypes): string[] {
  return roleAbbreviationFriendlyNameMap.filter(role => role.teamType === teamType).map(role => role.abbreviation) ?? [];
}

export function getTeamMemberGridRowStyle(params: RowClassParams<UserTeamRolesDto | TeamMemberDto>): RowStyle | undefined {
  if (!params.data) {
    return undefined;
  }

  switch (params.data.source) {
    case TeamMemberSources.AzureAd:
      return { backgroundColor: commonUXTheme.colors.green10 };
    case TeamMemberSources.UserRequested:
    default:
      return undefined;
  }
};

export function isTeamAdminRole(abbreviation: string): boolean {
  return roleAbbreviationFriendlyNameMap.some(role => role.abbreviation === abbreviation.toUpperCase() && role.isAdmin);
}