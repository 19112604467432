/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleType,
    ArticleTypeFromJSON,
    ArticleTypeFromJSONTyped,
    ArticleTypeToJSON,
} from './ArticleType';

/**
 * 
 * @export
 * @interface TechDocArticleDto
 */
export interface TechDocArticleDto {
    /**
     * 
     * @type {number}
     * @memberof TechDocArticleDto
     */
    relevanceRank?: number;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    htmlTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    htmlExcerpt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    htmlText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    fileContentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TechDocArticleDto
     */
    sourceUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TechDocArticleDto
     */
    updatedTime?: Date;
    /**
     * 
     * @type {ArticleType}
     * @memberof TechDocArticleDto
     */
    articleType?: ArticleType;
}

export function TechDocArticleDtoFromJSON(json: any): TechDocArticleDto {
    return TechDocArticleDtoFromJSONTyped(json, false);
}

export function TechDocArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechDocArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relevanceRank': !exists(json, 'relevanceRank') ? undefined : json['relevanceRank'],
        'htmlTitle': !exists(json, 'htmlTitle') ? undefined : json['htmlTitle'],
        'htmlExcerpt': !exists(json, 'htmlExcerpt') ? undefined : json['htmlExcerpt'],
        'htmlText': !exists(json, 'htmlText') ? undefined : json['htmlText'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'fileContentType': !exists(json, 'fileContentType') ? undefined : json['fileContentType'],
        'sourceUrl': !exists(json, 'sourceUrl') ? undefined : json['sourceUrl'],
        'updatedTime': !exists(json, 'updatedTime') ? undefined : (new Date(json['updatedTime'])),
        'articleType': !exists(json, 'articleType') ? undefined : ArticleTypeFromJSON(json['articleType']),
    };
}

export function TechDocArticleDtoToJSON(value?: TechDocArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relevanceRank': value.relevanceRank,
        'htmlTitle': value.htmlTitle,
        'htmlExcerpt': value.htmlExcerpt,
        'htmlText': value.htmlText,
        'fileName': value.fileName,
        'fileContentType': value.fileContentType,
        'sourceUrl': value.sourceUrl,
        'updatedTime': value.updatedTime === undefined ? undefined : (value.updatedTime.toISOString()),
        'articleType': ArticleTypeToJSON(value.articleType),
    };
}

