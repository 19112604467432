import { userSagas } from "applications/common/sagas/userSagas";
import {
  createGenericSaga,
  defaultSagaErrorHandler,
  SagaBuilder
} from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";

export const quickPriceCheckSagas = {
  getPricesFromOrderingCodes: createGenericSaga(
    "quickPriceSagas/getPriceProducts",
    getApiRegistry().pricesApi.apiPricesPriceListPost.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      // Getting prices updates the selectedManagedPriceListId field in userInformation
      onSuccess: () =>
        new SagaBuilder().dispatch(userSagas.getUserInformation.createAction(undefined)).build()()
    }
  ),
  getSinglePriceProductFromOrderingCode: createGenericSaga(
    "quickPriceSagas/getSinglePriceProductFromOrderingCode",
    getApiRegistry().pricesApi.apiPricesPriceListPost.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSinglePriceProductFromOrderingCodeWithoutPriceListId: createGenericSaga(
    "quickPriceSagas/getSinglePriceProductFromOrderingCodeWithoutPriceListId",
    getApiRegistry().pricesApi.apiPricesPost.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getUserPriceLists: createGenericSaga(
    "quickPriceSagas/getUserPriceLists",
    getApiRegistry().pricesApi.apiPricesCurrentUserPriceListsGet.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
