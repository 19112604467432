/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateShoppingCartFromFrameAgreementCommand
 */
export interface CreateShoppingCartFromFrameAgreementCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateShoppingCartFromFrameAgreementCommand
     */
    frameAgreementId: number;
}

export function CreateShoppingCartFromFrameAgreementCommandFromJSON(json: any): CreateShoppingCartFromFrameAgreementCommand {
    return CreateShoppingCartFromFrameAgreementCommandFromJSONTyped(json, false);
}

export function CreateShoppingCartFromFrameAgreementCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShoppingCartFromFrameAgreementCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frameAgreementId': json['frameAgreementId'],
    };
}

export function CreateShoppingCartFromFrameAgreementCommandToJSON(value?: CreateShoppingCartFromFrameAgreementCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frameAgreementId': value.frameAgreementId,
    };
}

