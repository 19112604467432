import { ColDef, GridApi } from "ag-grid-community";
import {
  ApiLifecycleManagementProductLifecycleStatusesGetRequest,
  ProductLifecycleStatusDto
} from "api";
import { getAuthInformation, getUserInformation } from "applications/common/reducers/userReducer";
import {
  createHeaderAndHeaderTooltip,
  getDefaultColumnSort,
  Grid
} from "framework/components/grid/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formGridDatetime } from "utilities/dateUtils";

interface ProductLifecycleStatusGridProps {
  setGridApi: (api: GridApi) => void;
}

export const defaultLifecycleStatusCriteria = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "code"
} as ApiLifecycleManagementProductLifecycleStatusesGetRequest;

// Makes it a bit easier to understand the logic in column definitions
const showIf = (x: boolean | undefined) => !x;

export const ProductLifecycleStatusGrid = ({ setGridApi }: ProductLifecycleStatusGridProps) => {
  const { t } = useTranslation();

  const userInformation = useSelector(getUserInformation);
  const authInformation = useSelector(getAuthInformation);
  const selectedCompany = userInformation?.selectedCompany;

  const colDefs: ColDef<ProductLifecycleStatusDto>[] = [
    {
      field: "code",
      ...createHeaderAndHeaderTooltip(t("Product name"))
    },
    {
      field: "serialNumber",
      ...createHeaderAndHeaderTooltip(t("Serial number"))
    },
    {
      field: "lifeCycleStatus",
      ...createHeaderAndHeaderTooltip(t("Current life-cycle status")),
      // Show only for registered users
      hide: showIf(authInformation.initialUserCheckDone && authInformation.isSignedIn)
    },
    {
      field: "nextLifeCycleStatus",
      ...createHeaderAndHeaderTooltip(t("Next life-cycle status")),
      // Show only if user has access to any company
      hide: showIf(selectedCompany != null)
    },
    {
      field: "lifeCycleStatusWillChangeDate",
      ...createHeaderAndHeaderTooltip(t("Estimated date for change")),
      valueFormatter: formGridDatetime,
      // Show only to internal companies
      hide: showIf(selectedCompany && Boolean(selectedCompany.isInternal))
    }
  ];

  return (
    <Grid
      isSortPersisted={true}
      defaultSort={getDefaultColumnSort(
        defaultLifecycleStatusCriteria.criteriaIsAscendingOrder,
        defaultLifecycleStatusCriteria.criteriaSortColumn
      )}
      t={t}
      statusBarOptions={{ showResetButton: true, showRowCount: true }}
      isOnlyResetSorting
      suppressCellFocus
      columnDefs={colDefs}
      colDefDefault={{
        flex: 1
      }}
      onGridReady={(params) => {
        setGridApi(params.api);
        params.columnApi.applyColumnState({
          state: defaultLifecycleStatusCriteria.criteriaSortColumn
            ? [
                {
                  colId: defaultLifecycleStatusCriteria.criteriaSortColumn,
                  sort: defaultLifecycleStatusCriteria.criteriaIsAscendingOrder ? "asc" : "desc"
                }
              ]
            : undefined
        });
      }}
      autosizeCols
      rowModelType="infinite"
      gridOptions={{
        onGridSizeChanged: (op) => op.api.sizeColumnsToFit()
      }}
      maxBlocksInCache={2}
      getRowId={(n) => n.data.id}
      containerStyle={{
        minHeight: 300
      }}
    />
  );
};
