/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SSC600Settings
 */
export interface SSC600Settings {
    /**
     * 
     * @type {string}
     * @memberof SSC600Settings
     */
    hardwareIncludedCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SSC600Settings
     */
    hardwareIncludedLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SSC600Settings
     */
    softwareOnlyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SSC600Settings
     */
    softwareOnlyLabel?: string | null;
}

export function SSC600SettingsFromJSON(json: any): SSC600Settings {
    return SSC600SettingsFromJSONTyped(json, false);
}

export function SSC600SettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SSC600Settings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hardwareIncludedCode': !exists(json, 'hardwareIncludedCode') ? undefined : json['hardwareIncludedCode'],
        'hardwareIncludedLabel': !exists(json, 'hardwareIncludedLabel') ? undefined : json['hardwareIncludedLabel'],
        'softwareOnlyCode': !exists(json, 'softwareOnlyCode') ? undefined : json['softwareOnlyCode'],
        'softwareOnlyLabel': !exists(json, 'softwareOnlyLabel') ? undefined : json['softwareOnlyLabel'],
    };
}

export function SSC600SettingsToJSON(value?: SSC600Settings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hardwareIncludedCode': value.hardwareIncludedCode,
        'hardwareIncludedLabel': value.hardwareIncludedLabel,
        'softwareOnlyCode': value.softwareOnlyCode,
        'softwareOnlyLabel': value.softwareOnlyLabel,
    };
}

