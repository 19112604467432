/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationOptionRule,
    ModificationOptionRuleFromJSON,
    ModificationOptionRuleFromJSONTyped,
    ModificationOptionRuleToJSON,
} from './ModificationOptionRule';
import {
    ModificationRecord,
    ModificationRecordFromJSON,
    ModificationRecordFromJSONTyped,
    ModificationRecordToJSON,
} from './ModificationRecord';

/**
 * 
 * @export
 * @interface Modifications
 */
export interface Modifications {
    /**
     * 
     * @type {string}
     * @memberof Modifications
     */
    materialCodeBefore?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Modifications
     */
    materialCodeAfter?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Modifications
     */
    materialCodeModificationRules?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Modifications
     */
    mainCodeModificationRule?: string | null;
    /**
     * 
     * @type {Array<ModificationOptionRule>}
     * @memberof Modifications
     */
    optionRules?: Array<ModificationOptionRule> | null;
    /**
     * 
     * @type {Array<ModificationRecord>}
     * @memberof Modifications
     */
    history?: Array<ModificationRecord> | null;
    /**
     * 
     * @type {ModificationRecord}
     * @memberof Modifications
     */
    latestProducedDeviceRevision?: ModificationRecord;
}

export function ModificationsFromJSON(json: any): Modifications {
    return ModificationsFromJSONTyped(json, false);
}

export function ModificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Modifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'materialCodeBefore': !exists(json, 'materialCodeBefore') ? undefined : json['materialCodeBefore'],
        'materialCodeAfter': !exists(json, 'materialCodeAfter') ? undefined : json['materialCodeAfter'],
        'materialCodeModificationRules': !exists(json, 'materialCodeModificationRules') ? undefined : json['materialCodeModificationRules'],
        'mainCodeModificationRule': !exists(json, 'mainCodeModificationRule') ? undefined : json['mainCodeModificationRule'],
        'optionRules': !exists(json, 'optionRules') ? undefined : (json['optionRules'] === null ? null : (json['optionRules'] as Array<any>).map(ModificationOptionRuleFromJSON)),
        'history': !exists(json, 'history') ? undefined : (json['history'] === null ? null : (json['history'] as Array<any>).map(ModificationRecordFromJSON)),
        'latestProducedDeviceRevision': !exists(json, 'latestProducedDeviceRevision') ? undefined : ModificationRecordFromJSON(json['latestProducedDeviceRevision']),
    };
}

export function ModificationsToJSON(value?: Modifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'materialCodeBefore': value.materialCodeBefore,
        'materialCodeAfter': value.materialCodeAfter,
        'materialCodeModificationRules': value.materialCodeModificationRules,
        'mainCodeModificationRule': value.mainCodeModificationRule,
        'optionRules': value.optionRules === undefined ? undefined : (value.optionRules === null ? null : (value.optionRules as Array<any>).map(ModificationOptionRuleToJSON)),
        'history': value.history === undefined ? undefined : (value.history === null ? null : (value.history as Array<any>).map(ModificationRecordToJSON)),
        'latestProducedDeviceRevision': ModificationRecordToJSON(value.latestProducedDeviceRevision),
    };
}

