import { AccessRights } from "api/models/AccessRights";
import { roleConstants } from "constants/roleConstants";
import { AccessTokenKeys } from "types/accessTokenKeys";
import jwt from "jwt-decode";

export interface AccessRightBasedAuthorization {
  identifier?: string | null;
  operation: "any" | "all";
  accessRights: AccessRights[];
}

// Used in multiple places around the application, so moved the actual auth object here.
// Basically, this condition determines whether the user can see all the companies relevant to order search.
export const canSeeAllCompaniesInCompanyDropdown: AccessRightBasedAuthorization = {
  operation: "any",
  accessRights: [AccessRights.AdvancedOrderTracking, AccessRights.ViewAllOrders],
  identifier: "0"
};

export function hasAllAccessRights(
  identifier: string | undefined | null,
  userAccessRights: { [key: string]: Array<string> },
  ...accessRights: AccessRights[]
): boolean {
  if (userAccessRights[AccessRights.SystemAdmin] !== undefined) {
    return true;
  }
  for (const ac of accessRights) {
    if (userAccessRights[ac] === undefined) {
      return false;
    } else if (
      identifier !== undefined &&
      identifier !== null &&
      !userAccessRights[ac].includes("0") &&
      !userAccessRights[ac].includes(identifier)
    ) {
      return false;
    }
  }
  return true;
}

export function hasAnyAccessRight(
  identifier: string | undefined | null,
  userAccessRights: { [key: string]: Array<string> },
  ...accessRights: AccessRights[]
): boolean {
  if (userAccessRights[AccessRights.SystemAdmin] !== undefined) {
    return true;
  }
  for (const ac of accessRights) {
    if (userAccessRights[ac] !== undefined) {
      if (identifier === undefined || identifier === null) {
        return true;
      }

      if (
        userAccessRights[ac].includes(roleConstants.GlobalRoleIdentifier) ||
        userAccessRights[ac].includes(identifier)
      ) {
        return true;
      }
    }
  }
  return false;
}

export function isAuthorizedToComponent(
  userAccessRights: { [key: string]: Array<string> },
  requirement: AccessRightBasedAuthorization
): boolean {
  if (requirement.accessRights.length === 0) {
    return true;
  }
  if (requirement.operation === "any") {
    return hasAnyAccessRight(requirement.identifier, userAccessRights, ...requirement.accessRights);
  } else {
    return hasAllAccessRights(
      requirement.identifier,
      userAccessRights,
      ...requirement.accessRights
    );
  }
}

export function decodeAccessToken(accessToken: string): AccessTokenKeys {
  const decodedToken = jwt(accessToken) as AccessTokenKeys;
  return decodedToken;
}
