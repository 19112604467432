import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const emailTemplateSagas = {
  getAllRawTemplates: createGenericSaga(
    "emailTemplateSagas/getAllRawTemplates",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesGet.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  exportEmailTemplate: createGenericSaga(
    "emailTemplateSagas/exportEmailTemplate",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesTemplateNameGetRaw.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: true,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  uploadEmailTemplate: createGenericSaga(
    "emailTemplateSagas/uploadEmailTemplate",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesPost.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Email template uploaded successfully"),
      onFail: defaultSagaErrorHandler
    }
  ),
  uploadEmailTemplates: createGenericSaga(
    "emailTemplateSagas/uploadEmailTemplates",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesUploadMultiplePost.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Email template uploaded successfully"),
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteEmailTemplate: createGenericSaga(
    "emailTemplateSagas/deleteEmailTemplate",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesTemplateNameDelete.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  sendPreview: createGenericSaga(
    "emailTemplateSagas/sendPreview",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesEmailPreviewPost.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler(
        "Requested created successfully. You should receive the email soon."
      ),
      onFail: defaultSagaErrorHandler
    }
  ),
  getRequiredEmailTemplateKeys: createGenericSaga(
    "emailTemplateSagas/getRequiredEmailTemplateKeys",
    getApiRegistry().emailTemplatesApi.apiEmailTemplatesRequiredTemplatesGet.bind(
      getApiRegistry().emailTemplatesApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
