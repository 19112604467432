/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GlobalSettingTypes,
    GlobalSettingTypesFromJSON,
    GlobalSettingTypesFromJSONTyped,
    GlobalSettingTypesToJSON,
} from './GlobalSettingTypes';

/**
 * 
 * @export
 * @interface GlobalSettingDto
 */
export interface GlobalSettingDto {
    /**
     * 
     * @type {number}
     * @memberof GlobalSettingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalSettingDto
     */
    value?: string | null;
    /**
     * 
     * @type {GlobalSettingTypes}
     * @memberof GlobalSettingDto
     */
    globalSettingType?: GlobalSettingTypes;
}

export function GlobalSettingDtoFromJSON(json: any): GlobalSettingDto {
    return GlobalSettingDtoFromJSONTyped(json, false);
}

export function GlobalSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'globalSettingType': !exists(json, 'globalSettingType') ? undefined : GlobalSettingTypesFromJSON(json['globalSettingType']),
    };
}

export function GlobalSettingDtoToJSON(value?: GlobalSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'title': value.title,
        'value': value.value,
        'globalSettingType': GlobalSettingTypesToJSON(value.globalSettingType),
    };
}

