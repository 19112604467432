import React, { useCallback, memo, useMemo, useEffect, useRef } from "react";
import { createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { TFunction } from "i18next";
import {
  ColDef,
  ICellRendererParams,
  GetRowIdFunc,
  ExcelExportParams,
  ValueGetterParams,
  GetContextMenuItemsParams,
  IRowNode,
  GetRowIdParams
} from "ag-grid-community";
import { ShoppingCartDto, ShoppingCartItemDto, ShoppingCartModificationItemDto } from "api";
import { getShowDetails, isDetailProduct, isStaticProduct } from "utilities/productUtils";
import {
  detailsGrid,
  getPopupParentDocumentBody,
  toggleShoppingCartPrintLayout
} from "utilities/gridUtils";
import {
  QuoteGridNumericInputRenderer,
  QuoteGridNumericInputRendererProps
} from "applications/manage/manageQuotes/components/QuoteGridNumericInputRenderer";
import {
  GridPriceAndActionsRenderer,
  GridPriceAndActionsRendererProps
} from "applications/shoppingCart/components/GridPriceAndActionsRenderer";
import {
  GridProductRendererProps,
  GridProductRenderer
} from "applications/shoppingCart/components/GridProductRenderer";
import { QuoteShoppingCartItemDto } from "models/quote/quoteDto";
import { DetailGrid } from "styles/detailGrid";
import { GridLoadingOverlay } from "framework/components/grid/GridLoadingOverlay";
import { infoTypeConstants } from "../containers/QuoteView";
import { priceStringToFloatNumber } from "utilities/currencyUtils";
import { getProductColumnValue } from "applications/shoppingCart/components/ShoppingCartGrid";
import { useTheme } from "styled-components";
import { getContextMenuItemsShoppingCartItem } from "utilities/gridContextMenuItemUtils";
import {
  getExcelExportParams,
  getExcelStyles,
  getCsvExportParams
} from "utilities/gridExportUtils";
import { commonUXTheme } from "styles/commonUXVariables";
import { filterUndefineds } from "utilities/objectUtils";
import { AgGridReact } from "ag-grid-react";
import { getProductQuantityRunningLowWarningPercentage } from "applications/common/reducers/shoppingCartReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  GridNumericInputRenderer,
  GridNumericInputRendererProps,
  NumericInputColorStatus
} from "applications/shoppingCart/components/GridNumericInputRenderer";
import { isFractionSmallerThanOrEqualTo } from "utilities/mathUtils";

interface LineItemDetailsGridProps {
  t: TFunction;
  shoppingCart: ShoppingCartDto | undefined;
  updateLineItem: (
    orderingCode: string,
    infoType: string,
    discountRate: number | null | undefined,
    unitQuotedPrice: number | null | undefined,
    quantity: number,
    additionalInformation: string
  ) => void;
  removeLineItem: (id: number, orderingCode: string) => void;
  openEditDialogAndSetSelectedShoppingCartItem: (shoppingCartItem: ShoppingCartItemDto) => void;
  isReadOnly: boolean;
  isPrintLayout: boolean;
  quoteReference: string | null | undefined;
  hidePrices?: boolean;
  isFrameAgreement: boolean;
  reviseMode: boolean;
  companyIsInternalOrMaintained: boolean;
  quoteId?: number;
}

const excelParams: ExcelExportParams = {
  allColumns: true,
  columnWidth: 100
};

const getRowClass = (): string | string[] => {
  return "grid-white-background grid-row-padding";
};

const compareStringsAsNumbers = (valueA: any, valueB: any) => {
  if (!valueA) return -1;
  if (!valueB) return 1;
  return Number(valueA.replace(/\D/g, "")) - Number(valueB.replace(/\D/g, ""));
};

export const LineItemDetailsGrid = memo(
  ({
    t,
    shoppingCart,
    updateLineItem,
    removeLineItem,
    openEditDialogAndSetSelectedShoppingCartItem,
    isReadOnly,
    isPrintLayout,
    quoteReference,
    hidePrices,
    isFrameAgreement,
    reviseMode,
    companyIsInternalOrMaintained,
    quoteId
  }: LineItemDetailsGridProps): JSX.Element => {
    const lowQuantityPercentage = useSelector(getProductQuantityRunningLowWarningPercentage);
    const gridRef = useRef<AgGridReact>(null);

    const dispatch = useDispatch();

    useEffect(() => {
      if (gridRef.current?.api) {
        toggleShoppingCartPrintLayout(isPrintLayout, gridRef.current.api, shoppingCart);
      }
    }, [isPrintLayout, shoppingCart]);

    const colDefs = useMemo(
      () =>
        [
          {
            ...createHeaderAndHeaderTooltip("#", t("Position")),
            field: "position",
            width: 50,
            cellRenderer: "agGroupCellRenderer"
          },
          {
            ...createHeaderAndHeaderTooltip(t("Product")),
            field: "additionalInformation",
            colId: "product",
            autoHeight: true,
            valueGetter: (params: ValueGetterParams) => getProductColumnValue(params.node, t),
            cellRenderer: GridProductRenderer,
            cellRendererParams: (params: ICellRendererParams): GridProductRendererProps => {
              const shoppingCartItem = params.data as ShoppingCartItemDto;
              return {
                ...params,
                t: t,
                displayName: isDetailProduct(shoppingCartItem.displayName)
                  ? shoppingCartItem.orderingCode
                  : shoppingCartItem.displayName,
                imgUrl: shoppingCartItem.imageUrl,
                compCodeOrOrderingCode:
                  shoppingCartItem.compositionCode && !shoppingCartItem.isModification
                    ? shoppingCartItem.compositionCode
                    : shoppingCartItem.orderingCode,
                orderingCode:
                  shoppingCartItem.compositionCode &&
                  shoppingCartItem.orderingCode !== shoppingCartItem.compositionCode
                    ? shoppingCartItem.orderingCode
                    : null,
                vendorOrderingCode: shoppingCartItem.vendorOrderingCode ?? null,
                additionalInformation: shoppingCartItem.additionalInformation,
                onClick: () => {
                  params.node.setExpanded(!params.node.expanded);
                },
                isModification: shoppingCartItem.isModification,
                isStaticProduct: isStaticProduct(shoppingCartItem.category),
                isRenewalProduct: shoppingCartItem.isRenewalProduct,
                isSoftwareProduct: shoppingCartItem.isSoftwareProduct,
                softwarePurchaseEmail: shoppingCartItem.softwarePurchaseEmail,
                showDetailsText: params.node.expanded ? t("Hide details") : t("Show details"),
                showDetails: getShowDetails(shoppingCartItem),
                icon: params.node.expanded ? "abb/caret-up" : "abb/caret-down",
                isSimpleRenderer: false,
                onlineDeliveryProductEmailHelpText:
                  shoppingCartItem.onlineDeliveryProductEmailHelpText
              };
            },
            flex: 1,
            minWidth: 300
          },
          {
            ...createHeaderAndHeaderTooltip(t("Quantity")),
            field: "quantity",
            cellStyle: (params) => {
              if (!params.data) return undefined;
              const frameAgreementDetails = params.data.frameAgreementDetails;
              if (
                frameAgreementDetails?.currentCount != undefined &&
                frameAgreementDetails.limit != undefined &&
                frameAgreementDetails.currentCount > frameAgreementDetails.limit
              ) {
                return {
                  color: commonUXTheme.colors.statusRed
                };
              }
              return undefined;
            },
            cellRenderer: GridNumericInputRenderer,
            cellRendererParams: (
              params: ICellRendererParams<QuoteShoppingCartItemDto>
            ): GridNumericInputRendererProps => {
              const shoppingCartItem = params.data!;
              const frameAgreementLimit = params.data?.frameAgreementDetails?.limit;
              const frameAgreementCurrentCount = params.data?.frameAgreementDetails?.currentCount;

              let status: NumericInputColorStatus = "normal";

              if (frameAgreementCurrentCount != undefined && frameAgreementLimit != undefined) {
                const currentCount = shoppingCartItem.quantity + frameAgreementCurrentCount;
                const remainingCount = frameAgreementLimit - currentCount;

                if (currentCount > frameAgreementLimit) {
                  // Over the frame agreement limit -> show error
                  status = "error";
                } else if (
                  lowQuantityPercentage !== undefined &&
                  isFractionSmallerThanOrEqualTo(
                    [remainingCount, frameAgreementLimit],
                    [lowQuantityPercentage.numerator, lowQuantityPercentage.denominator]
                  )
                ) {
                  // The math utility function is needed to get rid of floating point errors.
                  // Less than or exactly 10% of frame agreement quota remaining -> show warning
                  status = "warning";
                } else {
                  // More than 10% of frame agreement quota remaining -> show normal status
                  status = "good";
                }
              }

              return {
                ...params,
                min: 1,
                value: shoppingCartItem.quantity,
                onChange: (orderingCode, quantity, additionalInformation) =>
                  updateLineItem(
                    orderingCode,
                    infoTypeConstants.quantity,
                    shoppingCartItem.discountRate,
                    priceStringToFloatNumber(shoppingCartItem.unitQuotedPrice),
                    quantity,
                    additionalInformation
                  ),
                type: "compact",
                isSimpleRenderer: !reviseMode || shoppingCartItem.isModification,
                status,
                tooltip: {
                  normal: undefined,
                  warning: t(
                    "The quantity of this product is running low - please contact your sales representative for assistance"
                  ),
                  error: t(
                    "The quantity of this product must not exceed the frame agreement limit - please contact your sales representative for assistance"
                  ),
                  good: undefined
                }[status]
              };
            },
            minWidth: 110,
            maxWidth: 110
          },
          isFrameAgreement
            ? {
                ...createHeaderAndHeaderTooltip(t("Available")),
                valueGetter: (params) => {
                  const frameAgreement = params.data?.frameAgreementDetails;
                  if (!frameAgreement) return undefined;
                  return (frameAgreement.limit ?? 0) - (frameAgreement.currentCount ?? 0);
                }
              }
            : undefined,
          {
            ...createHeaderAndHeaderTooltip(t("Unit price")),
            field: "unitPrice",
            minWidth: 150,
            valueGetter: (params) =>
              params.context.hidePrices ? t("(Hidden)") : params.data?.unitPrice ?? ""
          },
          {
            ...createHeaderAndHeaderTooltip(t("Discount rate")),
            field: "discountRate",
            valueGetter: (params) =>
              params.data?.discountRate === Number.NEGATIVE_INFINITY
                ? ""
                : params.data?.discountRate + " %",
            cellRenderer: QuoteGridNumericInputRenderer,
            cellRendererParams: (
              params: ICellRendererParams
            ): QuoteGridNumericInputRendererProps => {
              const shoppingCartItem = params.data as QuoteShoppingCartItemDto;
              return {
                ...params,
                min: 0,
                max: 100,
                value: shoppingCartItem.discountRate,
                suffix: "%",
                infoType: infoTypeConstants.discountRate,
                onChange: (orderingCode, discountRate, unitQuotedPrice) =>
                  updateLineItem(
                    orderingCode,
                    infoTypeConstants.discountRate,
                    discountRate,
                    unitQuotedPrice,
                    shoppingCartItem.quantity,
                    shoppingCartItem.additionalInformation ?? ""
                  ),
                type: "compact",
                isSimpleRenderer:
                  priceStringToFloatNumber(shoppingCartItem.price) === 0
                    ? true
                    : isReadOnly || companyIsInternalOrMaintained,
                t: t,
                hide: shoppingCartItem.discountRate === Number.NEGATIVE_INFINITY
              };
            },
            minWidth: 150
          },
          {
            ...createHeaderAndHeaderTooltip(t("Unit quoted price")),
            field: "unitQuotedPrice",
            valueGetter: (params) =>
              params.context.hidePrices
                ? t("(Hidden)")
                : params.data?.unitQuotedPrice ?? params.data?.unitPrice,
            cellRenderer: hidePrices ? undefined : QuoteGridNumericInputRenderer,
            cellRendererParams: hidePrices
              ? undefined
              : (params: ICellRendererParams): QuoteGridNumericInputRendererProps => {
                  const shoppingCartItem = params.data as QuoteShoppingCartItemDto;
                  return {
                    ...params,
                    min: 0,
                    max: priceStringToFloatNumber(shoppingCartItem.unitPrice),
                    value: shoppingCartItem.unitQuotedPrice
                      ? priceStringToFloatNumber(shoppingCartItem.unitQuotedPrice)
                      : null,
                    suffix: shoppingCart?.priceListCurrency ?? "EUR",
                    infoType: infoTypeConstants.unitQuotedPrice,
                    onChange: (orderingCode, discountRate, unitQuotedPrice) =>
                      updateLineItem(
                        orderingCode,
                        infoTypeConstants.unitQuotedPrice,
                        discountRate,
                        unitQuotedPrice,
                        shoppingCartItem.quantity,
                        shoppingCartItem.additionalInformation ?? ""
                      ),
                    type: "compact",
                    isSimpleRenderer:
                      priceStringToFloatNumber(shoppingCartItem.price) === 0
                        ? true
                        : isReadOnly || companyIsInternalOrMaintained,
                    hide: params.value == null,
                    t: t
                  };
                },
            comparator: compareStringsAsNumbers,
            cellClass: "grid-react-container-flex",
            cellStyle: { border: "none" },
            minWidth: 180
          },
          {
            ...createHeaderAndHeaderTooltip(t("Total quoted price")),
            field: "quotedPrice",
            valueGetter: (params) =>
              params.context.hidePrices
                ? t("(Hidden)")
                : params.data?.quotedPrice ?? params.data?.price,
            cellRenderer: GridPriceAndActionsRenderer,
            cellRendererParams: (params: ICellRendererParams): GridPriceAndActionsRendererProps => {
              const shoppingCartItem = params.data as ShoppingCartItemDto;
              let rendererProps: GridPriceAndActionsRendererProps = {
                ...params,
                isBold: true,
                price: shoppingCartItem.price,
                quotedPrice: shoppingCartItem.quotedPrice,
                isModificationItem: false,
                t
              };
              if (reviseMode) {
                rendererProps = {
                  ...rendererProps,
                  onDelete: () =>
                    removeLineItem(shoppingCartItem.id, shoppingCartItem.orderingCode),
                  openEditDialogAndSetSelectedShoppingCartItem: () =>
                    openEditDialogAndSetSelectedShoppingCartItem(shoppingCartItem)
                };
              }
              return rendererProps;
            },
            comparator: compareStringsAsNumbers,
            autoHeight: true,
            cellStyle: { border: "none" },
            flex: 1,
            minWidth: 180
          }
        ] as (ColDef<QuoteShoppingCartItemDto> | undefined)[],
      [
        t,
        isFrameAgreement,
        hidePrices,
        reviseMode,
        lowQuantityPercentage,
        updateLineItem,
        isReadOnly,
        companyIsInternalOrMaintained,
        shoppingCart?.priceListCurrency,
        removeLineItem,
        openEditDialogAndSetSelectedShoppingCartItem
      ]
    );

    const getRowId: GetRowIdFunc = useCallback(
      (params: GetRowIdParams<ShoppingCartItemDto | ShoppingCartModificationItemDto>) =>
        `${params.data.position}_${params.data.id}_${params.data.orderingCode}`,
      []
    );
    const theme = useTheme();

    return (
      <DetailGrid
        masterDetail
        gridRef={gridRef}
        keepDetailRows
        detailRowAutoHeight
        embedFullWidthRows
        wrapHeaderText
        detailCellRendererParams={(params: any) => {
          const shoppingCartItem = params?.data as ShoppingCartItemDto;
          if (shoppingCartItem?.isModification == true) {
            const isQuotedShoppingCartItem = !!shoppingCartItem.quotedPrice;
            return {
              // provide the Grid Options to use on the Detail Grid
              detailGridOptions: {
                columnDefs: [
                  {
                    ...createHeaderAndHeaderTooltip("#", t("Position")),
                    field: "position",
                    width: 50
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Ordering code")),
                    field: "orderingCode",
                    hide: true
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Content of modification")),
                    field: "serialNumbers",
                    colId: "product",
                    autoHeight: true,
                    valueGetter: (params: ValueGetterParams) =>
                      getProductColumnValue(params.node, t),
                    cellRenderer: GridProductRenderer,
                    cellRendererParams: (params: ICellRendererParams): GridProductRendererProps => {
                      const shoppingCartModificationItem =
                        params.data as ShoppingCartModificationItemDto;
                      return {
                        ...params,
                        t: t,
                        displayName: isDetailProduct(shoppingCartModificationItem.displayName)
                          ? shoppingCartModificationItem.orderingCode
                          : shoppingCartModificationItem.displayName,
                        imgUrl: shoppingCartModificationItem.imageUrl,
                        compCodeOrOrderingCode:
                          shoppingCartModificationItem.compositionCode ??
                          shoppingCartModificationItem.orderingCode,
                        orderingCode:
                          shoppingCartModificationItem.compositionCode &&
                          shoppingCartModificationItem.orderingCode !==
                            shoppingCartModificationItem.compositionCode
                            ? shoppingCartModificationItem.orderingCode
                            : null,
                        additionalInformation: shoppingCartModificationItem.additionalInformation,
                        serialNumbers: shoppingCartModificationItem.serialNumbers,
                        onClick: () => {
                          params.node.setExpanded(!params.node.expanded);
                          params.api.refreshCells({ force: true });
                        },
                        showDetailsText: params.node.expanded
                          ? t("Hide details")
                          : t("Show details"),
                        showDetails: getShowDetails(shoppingCartModificationItem),
                        icon: params.node.expanded ? "abb/caret-up" : "abb/caret-down",
                        isSimpleRenderer: true
                      };
                    },
                    wrapText: true,
                    flex: 1,
                    width: 700
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Quantity")),
                    field: "quantity",
                    minWidth: isReadOnly ? 140 : 290
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Unit price")),
                    field: "unitPrice",
                    minWidth: isReadOnly ? 140 : 290,
                    hide: isQuotedShoppingCartItem
                  }
                ],
                defaultColDef: {
                  cellClass: "ag-mod-sales-cell"
                },
                masterDetail: true,
                keepDetailRows: true,
                detailRowAutoHeight: true,
                detailCellRendererParams: detailsGrid(t),
                suppressCellFocus: true,
                enableCellTextSelection: true,
                loadingOverlayComponent: GridLoadingOverlay,
                getRowClass: (): string | string[] => {
                  return "ag-details-mod-sales-row";
                },
                getContextMenuItems: (params: GetContextMenuItemsParams) =>
                  getContextMenuItemsShoppingCartItem({
                    params: params,
                    t: t,
                    dispatch: dispatch,
                    quoteId: quoteId
                  }),
                popupParent: getPopupParentDocumentBody()
              },
              // get the rows for each Detail Grid
              getDetailRowData: (params: any) => {
                params.successCallback(params.data.shoppingCartModificationItems);
              },
              getRowId: { getRowId }
            };
          } else {
            return detailsGrid(t);
          }
        }}
        isRowMaster={(item) => {
          return (
            !isStaticProduct(item.category) ||
            isDetailProduct(item.displayName) ||
            item.isModification
          );
        }}
        t={t}
        columnDefs={colDefs.filter(filterUndefineds)}
        rowData={shoppingCart?.shoppingCartItems ?? undefined}
        suppressRowClickSelection={true}
        animateRows
        getRowClass={getRowClass}
        disableResize={false}
        disableColumnAutoSize
        domLayout="autoHeight"
        getRowId={getRowId}
        gridOptions={{
          defaultExcelExportParams: excelParams
        }}
        onFirstDataRendered={(params) => {
          params.api.sizeColumnsToFit();
          params.api.forEachNode((rowNode: IRowNode<ShoppingCartItemDto>) => {
            if (rowNode.data?.isModification) {
              rowNode.setExpanded(true);
            }
          });
        }}
        colDefDefault={{
          tooltipValueGetter: undefined,
          cellStyle: { display: "flex", alignItems: "center" },
          cellClass: "start-from-top"
        }}
        suppressCellFocus={true}
        enableCellTextSelection
        getContextMenuItems={(params) =>
          getContextMenuItemsShoppingCartItem({
            params: params,
            t: t,
            dispatch: dispatch,
            quoteId: quoteId
          })
        }
        defaultExcelExportParams={getExcelExportParams(
          `${t("QuoteLineItemDetails")}_${quoteReference ?? "QT"}`,
          undefined,
          true
        )}
        excelStyles={getExcelStyles(theme)}
        defaultCsvExportParams={getCsvExportParams(
          `${t("QuoteLineItemDetails")}_${quoteReference ?? "QT"}`,
          true
        )}
        context={{ hidePrices }}
      />
    );
  }
);
