import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { ExplorerApplicationDto } from "api";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStateParams } from "utilities/useStateParams";
import { productExplorerActions } from "../actions/ProductExplorerActions";
import { getApplications } from "../reducers/productExplorerReducer";

export const SelectApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [applicationOptions, setApplicationOptions] = useState<ExplorerApplicationDto[]>([]);
  const applications = useSelector(getApplications);

  const fromStrToApp = useCallback(
    (str: string | null) => {
      let app: ExplorerApplicationDto | undefined = undefined;
      if (str) {
        app = applicationOptions.find((a) => a.code == str);
      }
      return app;
    },
    [applicationOptions]
  );

  const [selectedApplication, setSelectedApplication] = useStateParams<
    ExplorerApplicationDto | undefined
  >(
    undefined,
    "app",
    (s) => s?.code ?? "",
    fromStrToApp,
    1000,
    (v1, v2) => v1?.id === v2?.id
  );

  useEffect(() => {
    setApplicationOptions(applications);
  }, [applications]);

  // update filters
  useEffect(() => {
    dispatch(productExplorerActions.SetFilterApplications(selectedApplication?.id ?? undefined));
  }, [dispatch, selectedApplication]);

  return (
    <Dropdown
      placeholder={t("Select application")}
      monochrome
      value={
        selectedApplication
          ? [
              {
                value: selectedApplication.code,
                label: selectedApplication.name
              }
            ]
          : []
      }
      onChange={(item) => {
        item.length > 0
          ? setSelectedApplication(fromStrToApp(item[0].value))
          : setSelectedApplication(undefined);
      }}
      clearable
      searchable
      sizeClass={"small"}
    >
      {applicationOptions.map((a) => (
        <DropdownOption key={a.id} label={a.name ?? ""} value={a.code} />
      ))}
    </Dropdown>
  );
};
