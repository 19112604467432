import { UpdateUserCommand } from "./../api/models/UpdateUserCommand";
import {
  AccessRequestDto,
  CompanyDto,
  CurrentUserDto,
  PlaceOrderWithoutApprovalCommand,
  QuoteDto,
  RequestOrderApprovalCommand,
  ShoppingCartDto
} from "../../src/api/models";
import { RequestAccessFields } from "../applications/accessRequests/components/RequestAccessForm";
import { CustomerCodeType } from "../enums/customerCodeType";
import moment from "moment";
import { OrderSearchFields } from "../applications/deliveries/orders/components/OrderSearchForm";
import {
  ApiOrderGetRequest,
  ApiStatisticsGetRequest,
  ApiOrderNotificationsGetRequest,
  ApiOrderDownloadNotificationsGetRequest
} from "../api";
import { produce } from "immer";
import { getValidDateFormat, getDateTime, formatDate } from "./dateUtils";
import { StatisticsSearchFields } from "../applications/deliveries/statistics/components/StatisticsSearchForm";
import { NotificationSearchFields } from "../applications/deliveries/notifications/components/NotificationsSearchForm";
import { NotificationSearchBooleanOptions } from "../enums/notificationSearchBooleanOptions";
import { ShoppingCartForm } from "applications/shoppingCart/containers/ShoppingCartView";
import { isNil } from "lodash-es";
import { termsOfDeliveriesConstants } from "constants/termsOfDeliveryConstant";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";

export const mapShoppingCartFormToRequestOrderApprovalOrPlaceWithoutApprovalCommand = (
  shoppingCartForm: ShoppingCartForm,
  companyId: string,
  isExternal: boolean,
  isInEu: boolean,
  isOnlyAddedApprover: boolean
): RequestOrderApprovalCommand | PlaceOrderWithoutApprovalCommand => {
  return {
    hasRiskReviewBeenDone: shoppingCartForm.hasRiskReviewBeenDone,
    isExternal: isExternal,
    shoppingCartDeliveryAddress: shoppingCartForm.deliveryAddress,
    shoppingCartEndCustomer: shoppingCartForm.isEndCustomer
      ? undefined
      : shoppingCartForm.endCustomer,
    shoppingCartAccountCustomer: shoppingCartForm.accountCustomer,
    shoppingCartBillingAddress: shoppingCartForm.billingAddress,
    shoppingCartConsignee: shoppingCartForm.consignee,
    shippingMarks: shoppingCartForm.shippingMarks,
    specialInstructions: shoppingCartForm.specialInstructions,
    vatNumber: shoppingCartForm.vatNumber,
    forwarderContactInformation: shoppingCartForm.forwarderInfo,
    additionalInformation: shoppingCartForm.additionalInfo,
    salesChannelId: shoppingCartForm.salesChannel?.id,
    projectName: shoppingCartForm.projectName,
    //documentationRequirements?
    termsOfDeliveryCode: shoppingCartForm.termsOfDeliveryCode,
    requestedTermsOfDeliveryCode: shoppingCartForm.useDefaultTermsOfDelivery
      ? shoppingCartForm.termsOfDeliveryCode
      : shoppingCartForm.requestedTermsOfDeliveryCode,
    identifier: shoppingCartForm.identifier,
    customerReference: shoppingCartForm.customerReference,
    endCustomerReference: shoppingCartForm.endCustomerReference,
    requestedDeliveryDate: getDateTime(
      shoppingCartForm.requestedDeliveryDate,
      getValidDateFormat()
    ),
    acceptEarlierDelivery:
      shoppingCartForm.acceptEarlierDelivery && !!shoppingCartForm.requestedDeliveryDate
        ? true
        : null,
    acceptPartialDelivery: shoppingCartForm.acceptPartialDelivery ? true : false,
    companyId,
    proFormaItems:
      shoppingCartForm.useProFormaOrCommercialInvoice &&
        shoppingCartForm.hasCommercialInvoice === false &&
        !isInEu
        ? shoppingCartForm.proFormaItems?.map((x) => ({
          shoppingCartItemId: x.shoppingCartItemId,
          price: x.proFormaPrice
        }))
        : null,
    requestedOrderApproverEmail: shoppingCartForm.requestedOrderApproverEmail,
    pgCode: shoppingCartForm.pgCode,
    isOnlyAddedApprover: isOnlyAddedApprover
  };
};

export const mapShoppingCartToShoppingCartForm = (
  shoppingCart: ShoppingCartDto,
  termsOfDeliveryCode: string | null | undefined,
  termsOfPaymentDescription: string | null | undefined,
  defaultPg: string | null | undefined
): ShoppingCartForm => {
  const defaultTermsOfDeliveryCode =
    shoppingCart.termsOfDeliveryCode ?? termsOfDeliveryCode ?? termsOfDeliveriesConstants.Other;

  const form = {
    identifier: shoppingCart.guid,
    projectName: shoppingCart.projectName ?? undefined,
    sfdcReference: shoppingCart.sfdcReference ?? undefined,
    customerReference: shoppingCart.customerReference ?? undefined,
    endCustomerReference: shoppingCart.endCustomerReference ?? undefined,
    salesChannel: shoppingCart.salesChannelId
      ? { id: shoppingCart.salesChannelId, description: shoppingCart.salesChannelDescription }
      : undefined,
    hasRiskReviewBeenDone: shoppingCart.riskReviews.length > 0 ? true : null,
    isEndCustomer: shoppingCart.shoppingCartEndCustomer ? false : null,
    endCustomer: shoppingCart.shoppingCartEndCustomer,
    accountCustomer: shoppingCart.shoppingCartAccountCustomer,
    deliveryAddress: shoppingCart.shoppingCartDeliveryAddress,
    billingAddress: shoppingCart.shoppingCartBillingAddress,
    consignee: shoppingCart.shoppingCartConsignee,
    acceptEarlierDelivery: isNil(shoppingCart.acceptEarlierDelivery)
      ? true
      : shoppingCart.acceptEarlierDelivery,
    acceptPartialDelivery: isNil(shoppingCart.acceptPartialDelivery)
      ? true
      : shoppingCart.acceptPartialDelivery,
    forwarderInfo: shoppingCart.forwarderContactInformation ?? undefined,
    additionalInfo: shoppingCart.additionalInformation ?? undefined,
    shippingMarks: shoppingCart.shippingMarks ?? undefined,
    specialInstructions: shoppingCart.specialInstructions ?? undefined,
    requestedDeliveryDate: shoppingCart.requestedDeliveryDate
      ? formatDate(shoppingCart.requestedDeliveryDate)
      : undefined,
    hasCommercialInvoice: shoppingCart.commercialInvoices.length > 0 ? true : null,
    proFormaItems: shoppingCart.shoppingCartItems?.map((x) => ({
      ...x,
      shoppingCartItemId: x.id,
      proFormaPrice: x.proFormaItem?.price ?? undefined
    })),
    vatNumber: shoppingCart.vatNumber ?? undefined,
    isCustomDeliveryDate: shoppingCart.requestedDeliveryDate ? true : null,
    riskReviews: shoppingCart.riskReviews ?? [],
    commercialInvoices: shoppingCart.commercialInvoices ?? [],
    termsOfDeliveryCode: defaultTermsOfDeliveryCode,
    termsOfDeliveryDescription: shoppingCart.termsOfDeliveryDescription,
    useDefaultTermsOfDelivery:
      shoppingCart.termsOfDeliveryCode === shoppingCart.requestedTermsOfDeliveryCode,
    requestedTermsOfDeliveryCode:
      shoppingCart.requestedTermsOfDeliveryCode || defaultTermsOfDeliveryCode,
    requestedTermsOfDeliveryDescription:
      shoppingCart.requestedTermsOfDeliveryDescription || shoppingCart.termsOfDeliveryDescription,
    isPriorityConfirmed: undefined,
    requestedOrderApproverEmail: shoppingCart.requestedOrderApproverEmail,
    termsOfPaymentDescription: shoppingCart.termsOfPaymentDescription ?? termsOfPaymentDescription,
    pgCode: shoppingCart.pgCode ?? defaultPg,
    useProFormaOrCommercialInvoice: false
  };
  const hasProFormaItems = form.proFormaItems?.some((p) => p.proFormaPrice !== undefined);
  form.hasCommercialInvoice =
    form.hasCommercialInvoice === null
      ? hasProFormaItems == true
        ? false
        : null
      : form.hasCommercialInvoice;
  form.useProFormaOrCommercialInvoice = form.hasCommercialInvoice || hasProFormaItems;
  return form;
};

export const mapUserDtoToUpdateUserCommand = (userDto: CurrentUserDto): UpdateUserCommand => {
  return {
    ...userDto
  };
};

export const mapAccessRequestDtoToRequestAccessFields = (
  accessRequestDto: AccessRequestDto
): RequestAccessFields => ({
  id: accessRequestDto.id ?? null,
  firstName: accessRequestDto.firstName,
  lastName: accessRequestDto.lastName,
  email: accessRequestDto.email,
  userPrincipalName: accessRequestDto.userPrincipalName,
  userCompanyName: accessRequestDto.userCompanyName,
  addressCity: accessRequestDto.addressCity,
  countryCode: accessRequestDto.countryName,
  addressStreet: accessRequestDto.addressStreet,
  addressPostalCode: accessRequestDto.addressPostalCode,
  position: accessRequestDto.position,
  customerCode: accessRequestDto.etcdIcvCode ?? accessRequestDto.guidCode,
  customerCodeType: accessRequestDto.etcdIcvCode ? CustomerCodeType.ABB : CustomerCodeType.NonABB,
  comment: accessRequestDto.comment,
  pgCodes: accessRequestDto.pgCodes?.split(",") ?? [""],
  sapCustomerReferenceNumber: accessRequestDto.sapCustomerReferenceNumber,
  matchedCompanyId: accessRequestDto.matchedCompanyId,
  matchedCompany: accessRequestDto.matchedCompany,
  userId: accessRequestDto.userId,
  timeZone: accessRequestDto.timeZone ?? ""
});

export const mapOrderFieldsToOrderCriteria = (
  fields: OrderSearchFields,
  criteria: ApiOrderGetRequest,
  companyId?: string
): ApiOrderGetRequest => {
  return produce(criteria, (draft) => {
    draft.criteriaDateTimeFrom = getDateTime(fields.dateFrom, getValidDateFormat());
    draft.criteriaDateTimeTo = getDateTime(fields.dateTo, getValidDateFormat(), "endOf");
    draft.criteriaOrder = fields.order;
    draft.criteriaOrderStatus = fields.status;
    draft.criteriaOrderingCode = fields.orderingCode;
    draft.criteriaTrackingNumber = fields.trackingNumber;
    draft.criteriaCompanyId = companyId;
    draft.criteriaCountryCode = fields.countryCode;
    draft.criteriaEtcdIcvCode = fields.icvCode;
    draft.criteriaLimitedSearch = fields.limitedSearch;
    draft.criteriaCustomerCode = fields.customerCode;
    draft.criteriaMaterialCode = fields.materialCode;
    draft.criteriaPlantId = fields.plantId ? Number(fields.plantId) : undefined;
  });
};

export const mapStatisticsSearchFieldsToStatisticsCriteria = (
  fields: StatisticsSearchFields,
  criteria: ApiStatisticsGetRequest
): ApiStatisticsGetRequest => {
  return produce(criteria, (draft) => {
    draft.criteriaTimePeriod = fields.searchPeriod;
    draft.criteriaFrom = fields.dateFrom
      ? moment(fields.dateFrom, getValidDateFormat()).toDate()
      : undefined;
    draft.criteriaTo = fields.dateTo
      ? moment(fields.dateTo, getValidDateFormat()).toDate()
      : undefined;
  });
};

export const mapNotificationSearchFieldsToNotificationCriteria = (
  fields: NotificationSearchFields,
  criteria: ApiOrderNotificationsGetRequest
): ApiOrderNotificationsGetRequest => {
  return produce(criteria, (draft) => {
    switch (fields.orderStatus) {
      case NotificationSearchBooleanOptions.All:
        draft.criteriaOrderExists = undefined;
        break;
      case NotificationSearchBooleanOptions.IsSet:
        draft.criteriaOrderExists = true;
        break;
      case NotificationSearchBooleanOptions.NotSet:
        draft.criteriaOrderExists = false;
        break;
    }
    switch (fields.queuedStatus) {
      case NotificationSearchBooleanOptions.All:
        draft.criteriaQueued = undefined;
        break;
      case NotificationSearchBooleanOptions.IsSet:
        draft.criteriaQueued = true;
        break;
      case NotificationSearchBooleanOptions.NotSet:
        draft.criteriaQueued = false;
        break;
    }
    switch (fields.emailSentStatus) {
      case NotificationSearchBooleanOptions.All:
        draft.criteriaProcessed = undefined;
        break;
      case NotificationSearchBooleanOptions.IsSet:
        draft.criteriaProcessed = true;
        break;
      case NotificationSearchBooleanOptions.NotSet:
        draft.criteriaProcessed = false;
        break;
    }
    draft.criteriaCreatedFrom = fields.dateFrom
      ? moment(fields.dateFrom, getValidDateFormat()).toDate()
      : undefined;
    draft.criteriaCreatedTo = fields.dateTo
      ? moment(fields.dateTo, getValidDateFormat()).toDate()
      : undefined;
    draft.criteriaOrder = fields.order;
  });
};

export const mapNotificationSearchFieldsToOrderDownloadNotificationsCriteria = (
  fields: NotificationSearchFields,
  criteria: ApiOrderDownloadNotificationsGetRequest
): ApiOrderDownloadNotificationsGetRequest => {
  return produce(criteria, (draft) => {
    switch (fields.queuedStatus) {
      case NotificationSearchBooleanOptions.All:
        draft.criteriaEmailQueued = undefined;
        break;
      case NotificationSearchBooleanOptions.IsSet:
        draft.criteriaEmailQueued = true;
        break;
      case NotificationSearchBooleanOptions.NotSet:
        draft.criteriaEmailQueued = false;
        break;
    }
    switch (fields.emailSentStatus) {
      case NotificationSearchBooleanOptions.All:
        draft.criteriaEmailSent = undefined;
        break;
      case NotificationSearchBooleanOptions.IsSet:
        draft.criteriaEmailSent = true;
        break;
      case NotificationSearchBooleanOptions.NotSet:
        draft.criteriaEmailSent = false;
        break;
    }
    draft.criteriaCreatedFrom = fields.dateFrom
      ? moment(fields.dateFrom, getValidDateFormat()).toDate()
      : undefined;
    draft.criteriaCreatedTo = fields.dateTo
      ? moment(fields.dateTo, getValidDateFormat()).toDate()
      : undefined;
    draft.criteriaOrder = fields.order;
    draft.criteriaSerialNumber = fields.serialNumber;
  });
};

export const mapQuoteToReviseQuote = (
  shoppingCart: ShoppingCartDto,
  quote: QuoteDto,
  company: CompanyDto
): ReviseQuoteFromCart => {
  const isEndCustomer =
    shoppingCart?.shoppingCartEndCustomer?.guidCode?.toString() === company.globalCustomerNumber;

  const isAccountCustomer =
    shoppingCart?.shoppingCartAccountCustomer?.etcdIcvCode === company.globalCustomerNumber;

  const form: ReviseQuoteFromCart = {
    id: undefined,
    quoteReceiverEmail: quote?.quoteReceiverEmail ?? "",
    projectName: shoppingCart?.projectName,
    sfdcReference: shoppingCart?.sfdcReference,
    expectedOrderDate: moment(quote?.expectedOrderDate).format(getValidDateFormat()),
    tenderValidityDate: moment(quote?.tenderValidityDate).format(getValidDateFormat()),
    endCustomerCompanyName: shoppingCart?.shoppingCartEndCustomer?.name,
    endCustomerCompanyStreetAddress: shoppingCart?.shoppingCartEndCustomer?.streetAddress,
    endCustomerCompanyCity: shoppingCart?.shoppingCartEndCustomer?.city,
    endCustomerCompanyCountry: shoppingCart?.shoppingCartEndCustomer?.country,
    endCustomerCompanyCountryCode: shoppingCart?.shoppingCartEndCustomer?.countryCode,
    endCustomerGuid: shoppingCart?.shoppingCartEndCustomer?.guidCode,
    isEndCustomer: isEndCustomer,
    accountCustomerCompanyName: shoppingCart?.shoppingCartAccountCustomer?.name,
    accountCustomerCompanyStreetAddress: shoppingCart?.shoppingCartAccountCustomer?.streetAddress,
    accountCustomerCompanyCity: shoppingCart?.shoppingCartAccountCustomer?.city,
    accountCustomerCompanyCountry: shoppingCart?.shoppingCartAccountCustomer?.country,
    accountCustomerCompanyCountryCode: shoppingCart?.shoppingCartAccountCustomer?.countryCode,
    accountCustomerIcvCode: shoppingCart?.shoppingCartAccountCustomer?.etcdIcvCode,
    isAccountCustomer: isAccountCustomer,
    salesChannelDescription: shoppingCart.salesChannelDescription,
    pgCode: shoppingCart.pgCode,
    salesChannelId: shoppingCart.salesChannelId,
    winningPercentage: quote.winningPercentage,
    totalSalesPrice: company.isInternalBuyer ? quote.totalSalesPrice : undefined
  };

  return form;
};

export const mapShoppingCartToQuoteViewForm = (shoppingCart: ShoppingCartDto | undefined, company: CompanyDto | undefined): ReviseQuoteFromCart => {
  const isDraft = shoppingCart && shoppingCart.draftQuote;

  const isEndCustomer =
    shoppingCart?.shoppingCartEndCustomer?.guidCode?.toString() === company?.globalCustomerNumber;

  const isAccountCustomer =
    shoppingCart?.shoppingCartAccountCustomer?.etcdIcvCode === company?.globalCustomerNumber;

  return {
    id: isDraft && shoppingCart ? shoppingCart.draftQuote?.id : undefined,
    quoteReceiverEmail: isDraft && shoppingCart ? shoppingCart.draftQuote?.quoteReceiverEmail : undefined,
    projectName: shoppingCart?.projectName,
    sfdcReference: isDraft && shoppingCart ? shoppingCart.sfdcReference : undefined,
    expectedOrderDate: isDraft && shoppingCart && shoppingCart.draftQuote?.expectedOrderDate ? moment(shoppingCart.draftQuote?.expectedOrderDate).format(getValidDateFormat()) : undefined,
    tenderValidityDate: moment().endOf("day").add(3, "months").format(getValidDateFormat()),
    endCustomerCompanyName: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.name : undefined,
    endCustomerCompanyStreetAddress: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.streetAddress : undefined,
    endCustomerCompanyCity: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.city : undefined,
    endCustomerCompanyCountry: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.country : undefined,
    endCustomerCompanyCountryCode: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.countryCode : undefined,
    endCustomerGuid: isDraft && shoppingCart ? shoppingCart.shoppingCartEndCustomer?.guidCode : undefined,
    isEndCustomer: isDraft && shoppingCart?.shoppingCartEndCustomer ? isEndCustomer : undefined,
    accountCustomerCompanyName: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.name : undefined,
    accountCustomerCompanyStreetAddress: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.streetAddress : undefined,
    accountCustomerCompanyCity: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.city : undefined,
    accountCustomerCompanyCountry: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.country : undefined,
    accountCustomerCompanyCountryCode: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.countryCode : undefined,
    accountCustomerIcvCode: isDraft && shoppingCart ? shoppingCart.shoppingCartAccountCustomer?.etcdIcvCode : undefined,
    isAccountCustomer: isDraft && shoppingCart?.shoppingCartAccountCustomer ? isAccountCustomer : undefined,
    salesChannelDescription: isDraft && shoppingCart ? shoppingCart?.salesChannelDescription : undefined,
    pgCode: isDraft && shoppingCart ? shoppingCart?.pgCode : undefined,
    salesChannelId: isDraft && shoppingCart ? shoppingCart?.salesChannelId : undefined,
    winningPercentage: isDraft && shoppingCart && shoppingCart?.draftQuote ? shoppingCart?.draftQuote?.winningPercentage : undefined,
    totalSalesPrice: isDraft && shoppingCart && shoppingCart?.draftQuote ? shoppingCart?.draftQuote?.totalSalesPrice : undefined,
  };
};