/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseDocumentJustInTimeFileStates,
    LicenseDocumentJustInTimeFileStatesFromJSON,
    LicenseDocumentJustInTimeFileStatesFromJSONTyped,
    LicenseDocumentJustInTimeFileStatesToJSON,
} from './LicenseDocumentJustInTimeFileStates';

/**
 * 
 * @export
 * @interface UploadedJustInTimeFileDto
 */
export interface UploadedJustInTimeFileDto {
    /**
     * 
     * @type {string}
     * @memberof UploadedJustInTimeFileDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UploadedJustInTimeFileDto
     */
    uploadedAt?: Date;
    /**
     * 
     * @type {LicenseDocumentJustInTimeFileStates}
     * @memberof UploadedJustInTimeFileDto
     */
    licenseDocumentJustInTimeFileStateId?: LicenseDocumentJustInTimeFileStates;
}

export function UploadedJustInTimeFileDtoFromJSON(json: any): UploadedJustInTimeFileDto {
    return UploadedJustInTimeFileDtoFromJSONTyped(json, false);
}

export function UploadedJustInTimeFileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedJustInTimeFileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploadedAt': !exists(json, 'uploadedAt') ? undefined : (new Date(json['uploadedAt'])),
        'licenseDocumentJustInTimeFileStateId': !exists(json, 'licenseDocumentJustInTimeFileStateId') ? undefined : LicenseDocumentJustInTimeFileStatesFromJSON(json['licenseDocumentJustInTimeFileStateId']),
    };
}

export function UploadedJustInTimeFileDtoToJSON(value?: UploadedJustInTimeFileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'uploadedAt': value.uploadedAt === undefined ? undefined : (value.uploadedAt.toISOString()),
        'licenseDocumentJustInTimeFileStateId': LicenseDocumentJustInTimeFileStatesToJSON(value.licenseDocumentJustInTimeFileStateId),
    };
}

