import { createGenericSaga } from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const manageQuotesSagas = {
  getQuoteTermsAndCondition: createGenericSaga(
    "quoteSagas/getQuoteTermsAndCondition",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsQuotesQuoteIdGet.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false
    }
  )
};
