/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUsageDto
 */
export interface CompanyUsageDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyUsageDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUsageDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyUsageDto
     */
    manageCompanyViewed?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUsageDto
     */
    orderDetailsViewed?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUsageDto
     */
    orderItemSearch?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUsageDto
     */
    orderSearch?: number;
}

export function CompanyUsageDtoFromJSON(json: any): CompanyUsageDto {
    return CompanyUsageDtoFromJSONTyped(json, false);
}

export function CompanyUsageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUsageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'manageCompanyViewed': !exists(json, 'manageCompanyViewed') ? undefined : json['manageCompanyViewed'],
        'orderDetailsViewed': !exists(json, 'orderDetailsViewed') ? undefined : json['orderDetailsViewed'],
        'orderItemSearch': !exists(json, 'orderItemSearch') ? undefined : json['orderItemSearch'],
        'orderSearch': !exists(json, 'orderSearch') ? undefined : json['orderSearch'],
    };
}

export function CompanyUsageDtoToJSON(value?: CompanyUsageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'companyName': value.companyName,
        'manageCompanyViewed': value.manageCompanyViewed,
        'orderDetailsViewed': value.orderDetailsViewed,
        'orderItemSearch': value.orderItemSearch,
        'orderSearch': value.orderSearch,
    };
}

