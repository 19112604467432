/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessRequestStatusTypes {
    Open = 'Open',
    Approved = 'Approved',
    Rejected = 'Rejected',
    InProcess = 'InProcess'
}

export function AccessRequestStatusTypesFromJSON(json: any): AccessRequestStatusTypes {
    return AccessRequestStatusTypesFromJSONTyped(json, false);
}

export function AccessRequestStatusTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestStatusTypes {
    return json as AccessRequestStatusTypes;
}

export function AccessRequestStatusTypesToJSON(value?: AccessRequestStatusTypes | null): any {
    return value as any;
}

