/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColDefDto
 */
export interface ColDefDto {
    /**
     * 
     * @type {string}
     * @memberof ColDefDto
     */
    headerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ColDefDto
     */
    field?: string | null;
}

export function ColDefDtoFromJSON(json: any): ColDefDto {
    return ColDefDtoFromJSONTyped(json, false);
}

export function ColDefDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColDefDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headerName': !exists(json, 'headerName') ? undefined : json['headerName'],
        'field': !exists(json, 'field') ? undefined : json['field'],
    };
}

export function ColDefDtoToJSON(value?: ColDefDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headerName': value.headerName,
        'field': value.field,
    };
}

