/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestStatusTypes,
    AccessRequestStatusTypesFromJSON,
    AccessRequestStatusTypesFromJSONTyped,
    AccessRequestStatusTypesToJSON,
} from './AccessRequestStatusTypes';

/**
 * 
 * @export
 * @interface UpdateAccessRequestCommand
 */
export interface UpdateAccessRequestCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateAccessRequestCommand
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessRequestCommand
     */
    matchedCompanyId?: string | null;
    /**
     * 
     * @type {AccessRequestStatusTypes}
     * @memberof UpdateAccessRequestCommand
     */
    statusId: AccessRequestStatusTypes;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAccessRequestCommand
     */
    globalRolesToAdd?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAccessRequestCommand
     */
    globalRolesToRemove?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAccessRequestCommand
     */
    companyRolesToAdd?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateAccessRequestCommand
     */
    companyRolesToRemove?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccessRequestCommand
     */
    rejectionMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccessRequestCommand
     */
    disableNotification?: boolean | null;
}

export function UpdateAccessRequestCommandFromJSON(json: any): UpdateAccessRequestCommand {
    return UpdateAccessRequestCommandFromJSONTyped(json, false);
}

export function UpdateAccessRequestCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccessRequestCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'matchedCompanyId': !exists(json, 'matchedCompanyId') ? undefined : json['matchedCompanyId'],
        'statusId': AccessRequestStatusTypesFromJSON(json['statusId']),
        'globalRolesToAdd': !exists(json, 'globalRolesToAdd') ? undefined : json['globalRolesToAdd'],
        'globalRolesToRemove': !exists(json, 'globalRolesToRemove') ? undefined : json['globalRolesToRemove'],
        'companyRolesToAdd': !exists(json, 'companyRolesToAdd') ? undefined : json['companyRolesToAdd'],
        'companyRolesToRemove': !exists(json, 'companyRolesToRemove') ? undefined : json['companyRolesToRemove'],
        'rejectionMessage': !exists(json, 'rejectionMessage') ? undefined : json['rejectionMessage'],
        'disableNotification': !exists(json, 'disableNotification') ? undefined : json['disableNotification'],
    };
}

export function UpdateAccessRequestCommandToJSON(value?: UpdateAccessRequestCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'matchedCompanyId': value.matchedCompanyId,
        'statusId': AccessRequestStatusTypesToJSON(value.statusId),
        'globalRolesToAdd': value.globalRolesToAdd,
        'globalRolesToRemove': value.globalRolesToRemove,
        'companyRolesToAdd': value.companyRolesToAdd,
        'companyRolesToRemove': value.companyRolesToRemove,
        'rejectionMessage': value.rejectionMessage,
        'disableNotification': value.disableNotification,
    };
}

