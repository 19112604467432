import { createGenericSaga } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const lifecycleManagementSagas = {
  getProductLifeCycleStatuses: createGenericSaga(
    "lifecycleManagementSagas/getProductLifeCycleStatuses",
    getApiRegistry().lifecycleManagementApi.apiLifecycleManagementProductLifecycleStatusesGet.bind(
      getApiRegistry().lifecycleManagementApi
    )
  )
};
