import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { translationSagas } from "../sagas/translationSagas";
import { handleFileDownloadResponse } from "../../../../utilities/fileDownload";
import { ApiResponse, ImportTranslationsVm } from "api";
import {
  TranslationActions,
  TranslationActionTypes
} from "applications/common/actions/translationActions";

interface Requests {
  uploadTranslationFile: RequestState<ImportTranslationsVm, undefined>;
  getAvailableLanguages: RequestState<string[], undefined>;
  exportTranslationFile: RequestState<ApiResponse<Blob>, undefined>;
}

export interface ManageTranslationsState {
  requests: Requests;
  availableLanguages: string[] | undefined;
  importErrors: string[] | null;
}

const defaultState: ManageTranslationsState = {
  requests: {
    uploadTranslationFile: createInitialRequest(),
    getAvailableLanguages: createInitialRequest(),
    exportTranslationFile: createInitialRequest()
  },
  availableLanguages: undefined,
  importErrors: null
};

export function manageTranslationsReducer(
  state: ManageTranslationsState = defaultState,
  action: TranslationActions
): ManageTranslationsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: translationSagas.uploadTranslationFile.actionTypes,
      key: "uploadTranslationFile"
    },
    {
      actionTypes: translationSagas.getAvailableLanguages.actionTypes,
      key: "getAvailableLanguages"
    },
    {
      actionTypes: translationSagas.exportTranslationFile.actionTypes,
      key: "exportTranslationFile"
    }
  ]);

  if (translationSagas.getAvailableLanguages.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.availableLanguages = action.payload;
    });
  } else if (translationSagas.exportTranslationFile.isCompletedAction(action)) {
    handleFileDownloadResponse(action.payload.raw, "Translations.xlsm");
  }

  switch (action.type) {
    case TranslationActionTypes.SetImportErrors:
      state = produce(state, (draft) => {
        draft.importErrors = action.errors;
      });
      break;
  }

  return state;
}

export const getUploadTranslationFile = (
  state: AppState
): RequestState<ImportTranslationsVm, undefined> =>
  state.manageTranslations.requests.uploadTranslationFile;
export const getAvailableLanguages = (state: AppState): string[] | undefined =>
  state.manageTranslations.availableLanguages;
export const getExportTranslationFile = (
  state: AppState
): RequestState<ApiResponse<Blob>, undefined> =>
  state.manageTranslations.requests.exportTranslationFile;
export const getImportErrors = (state: AppState): string[] | null =>
  state.manageTranslations.importErrors;
