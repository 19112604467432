/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ImportTranslationsVm,
    ImportTranslationsVmFromJSON,
    ImportTranslationsVmToJSON,
} from '../models';

export interface ApiTranslationImportPostRequest {
    _import?: Blob;
}

export interface ApiTranslationLanguageLanguageIdentifierGetRequest {
    languageIdentifier: string;
}

/**
 * 
 */
export class TranslationApi extends runtime.BaseAPI {

    /**
     */
    async apiTranslationExportGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Translation/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTranslationExportGet(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTranslationExportGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTranslationImportPostRaw(requestParameters: ApiTranslationImportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImportTranslationsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters._import !== undefined) {
            formParams.append('import', requestParameters._import as any);
        }

        const response = await this.request({
            path: `/api/Translation/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportTranslationsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTranslationImportPost(requestParameters: ApiTranslationImportPostRequest, initOverrides?: RequestInit): Promise<ImportTranslationsVm> {
        const response = await this.apiTranslationImportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTranslationLanguageLanguageIdentifierGetRaw(requestParameters: ApiTranslationLanguageLanguageIdentifierGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        if (requestParameters.languageIdentifier === null || requestParameters.languageIdentifier === undefined) {
            throw new runtime.RequiredError('languageIdentifier','Required parameter requestParameters.languageIdentifier was null or undefined when calling apiTranslationLanguageLanguageIdentifierGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Translation/language/{languageIdentifier}`.replace(`{${"languageIdentifier"}}`, encodeURIComponent(String(requestParameters.languageIdentifier))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiTranslationLanguageLanguageIdentifierGet(requestParameters: ApiTranslationLanguageLanguageIdentifierGetRequest, initOverrides?: RequestInit): Promise<{ [key: string]: string; }> {
        const response = await this.apiTranslationLanguageLanguageIdentifierGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTranslationLanguagesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Translation/languages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiTranslationLanguagesGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiTranslationLanguagesGetRaw(initOverrides);
        return await response.value();
    }

}
