import {
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { createGenericSaga } from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";
import { AccessRequestStatusTypes } from "../../../../api/models/AccessRequestStatusTypes";

export const manageAccessRequestsSagas = {
  updateAccessRequest: createGenericSaga(
    "requestAccessSagas/updateAccessRequest",
    getApiRegistry().accessRequestsApi.apiAccessRequestsPut.bind(
      getApiRegistry().accessRequestsApi
    ),
    {
      takeEvery: false,
      onSuccess: (requestPayload, responsePayload) => {
        return defaultSagaSuccessHandler(
          createUpdateAccessRequestMessage(responsePayload.statusId)
        )();
      },
      onFail: defaultSagaErrorHandler
    }
  ),

  updateAccessRequestCompany: createGenericSaga(
    "requestAccessSagas/updateAccessRequestCompany",
    getApiRegistry().accessRequestsApi.apiAccessRequestsMatchedcompanyPatch.bind(
      getApiRegistry().accessRequestsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getRoles: createGenericSaga(
    "requestAccessSagas/getRoles",
    getApiRegistry().rolesApi.apiRolesGet.bind(getApiRegistry().rolesApi)
  )
};

const createUpdateAccessRequestMessage = (status: AccessRequestStatusTypes): string => {
  if (status === AccessRequestStatusTypes.Approved) {
    return "Access request approved";
  } else if (status === AccessRequestStatusTypes.Rejected) {
    return "Access request rejected";
  } else if (status === AccessRequestStatusTypes.InProcess) {
    return "Access request processing started";
  }
  return "Access request updated";
};
