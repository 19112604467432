/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAccountTypes {
    Unknown = 'Unknown',
    SelfRegisteredGuestAccount = 'SelfRegisteredGuestAccount',
    ManagedGuestAccount = 'ManagedGuestAccount',
    ExternalAccount = 'ExternalAccount',
    EmployeeAccount = 'EmployeeAccount'
}

export function UserAccountTypesFromJSON(json: any): UserAccountTypes {
    return UserAccountTypesFromJSONTyped(json, false);
}

export function UserAccountTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccountTypes {
    return json as UserAccountTypes;
}

export function UserAccountTypesToJSON(value?: UserAccountTypes | null): any {
    return value as any;
}

