/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    UserAccountTypes,
    UserAccountTypesFromJSON,
    UserAccountTypesFromJSONTyped,
    UserAccountTypesToJSON,
} from './UserAccountTypes';

/**
 * 
 * @export
 * @interface CurrentUserDto
 */
export interface CurrentUserDto {
    /**
     * 
     * @type {number}
     * @memberof CurrentUserDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    selectedCompanyId?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof CurrentUserDto
     */
    selectedCompany?: CompanyDto;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    homeCompanyId?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof CurrentUserDto
     */
    homeCompany?: CompanyDto;
    /**
     * 
     * @type {number}
     * @memberof CurrentUserDto
     */
    selectedManagedPriceListId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof CurrentUserDto
     */
    lastActive: Date;
    /**
     * 
     * @type {UserAccountTypes}
     * @memberof CurrentUserDto
     */
    userAccountTypeId: UserAccountTypes;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    selectedShoppingCartGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    timeZone: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserDto
     */
    countryCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserDto
     */
    showBanner: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentUserDto
     */
    demoMode: boolean;
}

export function CurrentUserDtoFromJSON(json: any): CurrentUserDto {
    return CurrentUserDtoFromJSONTyped(json, false);
}

export function CurrentUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'selectedCompanyId': !exists(json, 'selectedCompanyId') ? undefined : json['selectedCompanyId'],
        'selectedCompany': !exists(json, 'selectedCompany') ? undefined : CompanyDtoFromJSON(json['selectedCompany']),
        'homeCompanyId': !exists(json, 'homeCompanyId') ? undefined : json['homeCompanyId'],
        'homeCompany': !exists(json, 'homeCompany') ? undefined : CompanyDtoFromJSON(json['homeCompany']),
        'selectedManagedPriceListId': !exists(json, 'selectedManagedPriceListId') ? undefined : json['selectedManagedPriceListId'],
        'active': json['active'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'position': json['position'],
        'language': json['language'],
        'lastActive': (new Date(json['lastActive'])),
        'userAccountTypeId': UserAccountTypesFromJSON(json['userAccountTypeId']),
        'selectedShoppingCartGuid': !exists(json, 'selectedShoppingCartGuid') ? undefined : json['selectedShoppingCartGuid'],
        'timeZone': json['timeZone'],
        'countryCode': json['countryCode'],
        'showBanner': json['showBanner'],
        'demoMode': json['demoMode'],
    };
}

export function CurrentUserDtoToJSON(value?: CurrentUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'selectedCompanyId': value.selectedCompanyId,
        'selectedCompany': CompanyDtoToJSON(value.selectedCompany),
        'homeCompanyId': value.homeCompanyId,
        'homeCompany': CompanyDtoToJSON(value.homeCompany),
        'selectedManagedPriceListId': value.selectedManagedPriceListId,
        'active': value.active,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'position': value.position,
        'language': value.language,
        'lastActive': (value.lastActive.toISOString()),
        'userAccountTypeId': UserAccountTypesToJSON(value.userAccountTypeId),
        'selectedShoppingCartGuid': value.selectedShoppingCartGuid,
        'timeZone': value.timeZone,
        'countryCode': value.countryCode,
        'showBanner': value.showBanner,
        'demoMode': value.demoMode,
    };
}

