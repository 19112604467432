import { manageGlobalSettingsSagas } from "../sagas/manageGlobalSettingsSagas";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { GlobalSettingDto, GlobalSettingTypes } from "api/models";
import { Action } from "redux";

interface Requests {
  getGlobalSettings: RequestState<Array<GlobalSettingDto>>;
  updateGlobalSettings: RequestState;
}

export interface ManageGlobalSettingsState {
  requests: Requests;
  settings: GlobalSettingDto[];
}

const defaultState: ManageGlobalSettingsState = {
  requests: {
    getGlobalSettings: createInitialRequest(),
    updateGlobalSettings: createInitialRequest()
  },
  settings: []
};

export function manageGlobalSettingsReducer(
  state: ManageGlobalSettingsState = defaultState,
  action: Action
): ManageGlobalSettingsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageGlobalSettingsSagas.getGlobalSettings.actionTypes,
      key: "getGlobalSettings"
    },
    {
      actionTypes: manageGlobalSettingsSagas.updateGlobalSettings.actionTypes,
      key: "updateGlobalSettings"
    }
  ]);

  if (manageGlobalSettingsSagas.getGlobalSettings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.settings = action.payload;
    });
  }

  return state;
}

export const getGlobalSettings = (state: AppState): GlobalSettingDto[] =>
  state.manageGlobalSettings.settings;

export const updateSettingsRequest = (state: AppState): RequestState =>
  state.manageGlobalSettings.requests.updateGlobalSettings;

export const getGlobalSetting = (
  state: AppState,
  type: GlobalSettingTypes
): GlobalSettingDto | undefined =>
  state.manageGlobalSettings.settings.find((s) => s.globalSettingType === type);
