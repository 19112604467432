/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseFileDto,
    LicenseFileDtoFromJSON,
    LicenseFileDtoFromJSONTyped,
    LicenseFileDtoToJSON,
} from './LicenseFileDto';

/**
 * 
 * @export
 * @interface LicensePackageDto
 */
export interface LicensePackageDto {
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    identifier: string;
    /**
     * 
     * @type {Array<LicenseFileDto>}
     * @memberof LicensePackageDto
     */
    files: Array<LicenseFileDto>;
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    orderingCode: string;
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    readMeContent: string;
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    urlLinkContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    urlLinkName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicensePackageDto
     */
    compositionCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicensePackageDto
     */
    hasMatchingSubmitJustInTimeMessage: boolean;
}

export function LicensePackageDtoFromJSON(json: any): LicensePackageDto {
    return LicensePackageDtoFromJSONTyped(json, false);
}

export function LicensePackageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicensePackageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'files': ((json['files'] as Array<any>).map(LicenseFileDtoFromJSON)),
        'orderingCode': json['orderingCode'],
        'readMeContent': json['readMeContent'],
        'urlLinkContent': !exists(json, 'urlLinkContent') ? undefined : json['urlLinkContent'],
        'urlLinkName': !exists(json, 'urlLinkName') ? undefined : json['urlLinkName'],
        'compositionCode': json['compositionCode'],
        'hasMatchingSubmitJustInTimeMessage': json['hasMatchingSubmitJustInTimeMessage'],
    };
}

export function LicensePackageDtoToJSON(value?: LicensePackageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'files': ((value.files as Array<any>).map(LicenseFileDtoToJSON)),
        'orderingCode': value.orderingCode,
        'readMeContent': value.readMeContent,
        'urlLinkContent': value.urlLinkContent,
        'urlLinkName': value.urlLinkName,
        'compositionCode': value.compositionCode,
        'hasMatchingSubmitJustInTimeMessage': value.hasMatchingSubmitJustInTimeMessage,
    };
}

