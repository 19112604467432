/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProFormaItemDto,
    ProFormaItemDtoFromJSON,
    ProFormaItemDtoFromJSONTyped,
    ProFormaItemDtoToJSON,
} from './ProFormaItemDto';

/**
 * 
 * @export
 * @interface ShoppingCartModificationItemDto
 */
export interface ShoppingCartModificationItemDto {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartModificationItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartModificationItemDto
     */
    shoppingCartItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    orderingCode: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartModificationItemDto
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    quotedPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    unitPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    unitQuotedPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    materialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    plusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartModificationItemDto
     */
    position?: number;
    /**
     * 
     * @type {ProFormaItemDto}
     * @memberof ShoppingCartModificationItemDto
     */
    proFormaItem?: ProFormaItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartModificationItemDto
     */
    hasAccessories: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartModificationItemDto
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShoppingCartModificationItemDto
     */
    serialNumbers?: Array<string> | null;
}

export function ShoppingCartModificationItemDtoFromJSON(json: any): ShoppingCartModificationItemDto {
    return ShoppingCartModificationItemDtoFromJSONTyped(json, false);
}

export function ShoppingCartModificationItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartModificationItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shoppingCartItemId': !exists(json, 'shoppingCartItemId') ? undefined : json['shoppingCartItemId'],
        'orderingCode': json['orderingCode'],
        'quantity': json['quantity'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quotedPrice': !exists(json, 'quotedPrice') ? undefined : json['quotedPrice'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
        'unitQuotedPrice': !exists(json, 'unitQuotedPrice') ? undefined : json['unitQuotedPrice'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'plusCode': !exists(json, 'plusCode') ? undefined : json['plusCode'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'proFormaItem': !exists(json, 'proFormaItem') ? undefined : ProFormaItemDtoFromJSON(json['proFormaItem']),
        'hasAccessories': json['hasAccessories'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
    };
}

export function ShoppingCartModificationItemDtoToJSON(value?: ShoppingCartModificationItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shoppingCartItemId': value.shoppingCartItemId,
        'orderingCode': value.orderingCode,
        'quantity': value.quantity,
        'price': value.price,
        'quotedPrice': value.quotedPrice,
        'unitPrice': value.unitPrice,
        'unitQuotedPrice': value.unitQuotedPrice,
        'displayName': value.displayName,
        'materialCode': value.materialCode,
        'imageUrl': value.imageUrl,
        'plusCode': value.plusCode,
        'category': value.category,
        'compositionCode': value.compositionCode,
        'position': value.position,
        'proFormaItem': ProFormaItemDtoToJSON(value.proFormaItem),
        'hasAccessories': value.hasAccessories,
        'additionalInformation': value.additionalInformation,
        'serialNumbers': value.serialNumbers,
    };
}

