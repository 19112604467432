import {
  ApiReleaseNotesPostRequest,
  ApiReleaseNotesVersionDeleteRequest,
  ApiReleaseNotesVersionPutRequest,
  ReleaseNoteDto
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { Action } from "redux";
import { AppState } from "setup/appRootReducer";
import { manageReleaseNotesSagas } from "../sagas/manageReleaseNotesSagas";

interface Requests {
  getReleaseNotes: RequestState<ReleaseNoteDto[], undefined>;
  createReleaseNote: RequestState<ReleaseNoteDto, ApiReleaseNotesPostRequest>;
  updateReleaseNote: RequestState<ReleaseNoteDto, ApiReleaseNotesVersionPutRequest>;
  deleteReleaseNote: RequestState<string, ApiReleaseNotesVersionDeleteRequest>;
}

export interface ManageReleaseNotesViewState {
  requests: Requests;
  releaseNotes: ReleaseNoteDto[] | undefined;
}

const defaultState: ManageReleaseNotesViewState = {
  releaseNotes: undefined,
  requests: {
    getReleaseNotes: createInitialRequest(),
    createReleaseNote: createInitialRequest(),
    updateReleaseNote: createInitialRequest(),
    deleteReleaseNote: createInitialRequest()
  }
};

export function manageReleaseNotesReducer(
  state: ManageReleaseNotesViewState = defaultState,
  action: Action
): ManageReleaseNotesViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageReleaseNotesSagas.getReleaseNotes.actionTypes,
      key: "getReleaseNotes"
    },
    {
      actionTypes: manageReleaseNotesSagas.createReleaseNote.actionTypes,
      key: "createReleaseNote"
    },
    {
      actionTypes: manageReleaseNotesSagas.updateReleaseNote.actionTypes,
      key: "updateReleaseNote"
    },
    {
      actionTypes: manageReleaseNotesSagas.deleteReleaseNote.actionTypes,
      key: "deleteReleaseNote"
    }
  ]);

  if (manageReleaseNotesSagas.getReleaseNotes.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.releaseNotes = action.payload;
    });
  } else if (manageReleaseNotesSagas.updateReleaseNote.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.releaseNotes) {
        const index = draft.releaseNotes.findIndex((x) => x.version == action.payload.version);
        if (index >= 0) {
          draft.releaseNotes[index] = action.payload;
          draft.releaseNotes.sort((a, b) => (a.version > b.version ? -1 : 1));
        }
      }
    });
  } else if (manageReleaseNotesSagas.createReleaseNote.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.releaseNotes) {
        draft.releaseNotes.unshift(action.payload);
        draft.releaseNotes.sort((a, b) => (a.version > b.version ? -1 : 1));
      }
    });
  } else if (manageReleaseNotesSagas.deleteReleaseNote.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.releaseNotes) {
        const index = draft.releaseNotes.findIndex((x) => x.version == action.payload);
        if (index >= 0) {
          draft.releaseNotes.splice(index, 1);
        }
      }
    });
  }
  return state;
}

export const getCreateReleaseNoteState = (state: AppState) =>
  state.manageReleaseNotesView.requests.createReleaseNote;

export const getUpdateReleaseNotState = (state: AppState) =>
  state.manageReleaseNotesView.requests.updateReleaseNote;

export const getReleaseNotes = (state: AppState) => state.manageReleaseNotesView.releaseNotes;
