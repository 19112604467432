import { FormikProps } from "formik";
import moment from "moment";
import { getValidDateFormat } from "./dateUtils";
import { CustomValidationResult } from "@abb/abb-common-ux-react/components/Input";
import { RecursiveKeyOf } from "types/recursiveKeyOf";

export function isFormFieldValid<T>(
  formikBag: FormikProps<T>,
  key: keyof T
): CustomValidationResult {
  const touched = formikBag.touched[key];
  return {
    valid:
      touched === undefined || formikBag.errors[key] === undefined || formikBag.errors[key] === "",
    text: formikBag.errors[key] as string | undefined
  };
}

export function handleFormValueChange<T extends object>(
  formikBag: FormikProps<T>,
  key: keyof T,
  value: string
): void {
  formikBag.setFieldTouched(key.toString());
  formikBag.handleChange(key)(value);
}

export function handleFormikValueChange<T extends object>(
  formikBag: FormikProps<T>,
  key: RecursiveKeyOf<T>,
  value: any,
  shouldValidate = false
): void {
  const field = key.toString();
  formikBag.setFieldValue(field, value, shouldValidate);
  // setFieldValue uses react state under the hood so wait for the state update before touched
  setTimeout(() => formikBag.setFieldTouched(field, shouldValidate, shouldValidate));
}

export function parseDateStringForYupValidation(originalValue: string): Date {
  const format = getValidDateFormat();
  return moment(originalValue, format).toDate();
}

export const handlePressEnter = (
  event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  onSubmit: (() => Promise<void> | void) & (() => Promise<any> | void)
): undefined | boolean => {
  if (event.key === "Enter") {
    onSubmit();
  } else {
    return false;
  }
};

export const setNestedTouched = <T extends Record<string, any>>(
  formik: FormikProps<T>,
  parentObjName: KeysOfType<T, StringObject | undefined>
): void => {
  formik.setTouched({ ...formik.touched, [parentObjName]: {} as any });
  if (formik.values[parentObjName]) {
    Object.keys(formik.values[parentObjName] as unknown as Record<string, unknown>)?.forEach(
      (field) => {
        formik.setFieldTouched(`${String(parentObjName)}.${field}`, true, true);
      }
    );
  }
};

export type KeysOfType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never;
}[keyof O];

// accept object, not array
export type StringObject = {
  slice?: never;
  [key: string]: any;
};
