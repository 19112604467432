/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SetSupportPersonVisibilityCommand,
    SetSupportPersonVisibilityCommandFromJSON,
    SetSupportPersonVisibilityCommandToJSON,
    SupportPersonProfilePictureDto,
    SupportPersonProfilePictureDtoFromJSON,
    SupportPersonProfilePictureDtoToJSON,
    SupportRequestType,
    SupportRequestTypeFromJSON,
    SupportRequestTypeToJSON,
    VisibleSupportPersonDto,
    VisibleSupportPersonDtoFromJSON,
    VisibleSupportPersonDtoToJSON,
    VisibleSupportPersonType,
    VisibleSupportPersonTypeFromJSON,
    VisibleSupportPersonTypeToJSON,
} from '../models';

export interface ApiSupportPostRequest {
    type?: SupportRequestType;
    cc?: Array<string>;
    caseReference?: string;
    subject?: string;
    requestDescription?: string;
    attachments?: Array<Blob>;
}

export interface ApiSupportSupportPersonsGetRequest {
    type?: VisibleSupportPersonType;
}

export interface ApiSupportSupportPersonsProfilePicturesGetRequest {
    type?: VisibleSupportPersonType;
}

export interface ApiSupportSupportPersonsSetVisibilityPostRequest {
    setSupportPersonVisibilityCommand?: SetSupportPersonVisibilityCommand;
}

/**
 * 
 */
export class SupportApi extends runtime.BaseAPI {

    /**
     */
    async apiSupportPostRaw(requestParameters: ApiSupportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.cc) {
            queryParameters['Cc'] = requestParameters.cc;
        }

        if (requestParameters.caseReference !== undefined) {
            queryParameters['CaseReference'] = requestParameters.caseReference;
        }

        if (requestParameters.subject !== undefined) {
            queryParameters['Subject'] = requestParameters.subject;
        }

        if (requestParameters.requestDescription !== undefined) {
            queryParameters['RequestDescription'] = requestParameters.requestDescription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachments) {
            requestParameters.attachments.forEach((element) => {
                formParams.append('attachments', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Support`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSupportPost(requestParameters: ApiSupportPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSupportPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSupportSupportPersonsGetRaw(requestParameters: ApiSupportSupportPersonsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<VisibleSupportPersonDto>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Support/support-persons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VisibleSupportPersonDtoFromJSON));
    }

    /**
     */
    async apiSupportSupportPersonsGet(requestParameters: ApiSupportSupportPersonsGetRequest, initOverrides?: RequestInit): Promise<Array<VisibleSupportPersonDto>> {
        const response = await this.apiSupportSupportPersonsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSupportSupportPersonsProfilePicturesGetRaw(requestParameters: ApiSupportSupportPersonsProfilePicturesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SupportPersonProfilePictureDto>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Support/support-persons/profile-pictures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupportPersonProfilePictureDtoFromJSON));
    }

    /**
     */
    async apiSupportSupportPersonsProfilePicturesGet(requestParameters: ApiSupportSupportPersonsProfilePicturesGetRequest, initOverrides?: RequestInit): Promise<Array<SupportPersonProfilePictureDto>> {
        const response = await this.apiSupportSupportPersonsProfilePicturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSupportSupportPersonsSetVisibilityPostRaw(requestParameters: ApiSupportSupportPersonsSetVisibilityPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Support/support-persons/set-visibility`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSupportPersonVisibilityCommandToJSON(requestParameters.setSupportPersonVisibilityCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSupportSupportPersonsSetVisibilityPost(requestParameters: ApiSupportSupportPersonsSetVisibilityPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSupportSupportPersonsSetVisibilityPostRaw(requestParameters, initOverrides);
    }

}
