export const getFromLS = <T>(key: string): T | undefined => {
  let items: Record<string, T> = {};
  if (global.localStorage) {
    const item = global.localStorage.getItem(key);
    if (item) {
      items = JSON.parse(item) || {};
    }
  }
  return items[key];
};

export const saveToLS = (key: string, value: unknown): void => {
  if (global.localStorage) {
    global.localStorage.setItem(
      key,
      JSON.stringify({
        [key]: value
      })
    );
  }
};

export const getFromLSVersioned = <T>(
  key: string,
  requiredVersion: number | undefined
): T | undefined => {
  if (!global.localStorage) {
    return undefined;
  }

  const itemString = global.localStorage.getItem(key);
  if (itemString === null) return undefined;

  const item = (JSON.parse(itemString) || {}) as { data?: T; version?: unknown };

  const version = "version" in item && typeof item.version === "number" ? item.version : undefined;

  return version === requiredVersion ? item.data : undefined;
};

export const saveToLSVersioned = <T>(key: string, version: number | undefined, value: T): void => {
  if (!global.localStorage) return;
  global.localStorage.setItem(
    key,
    JSON.stringify({
      version,
      data: value
    })
  );
};
