import * as React from "react";
import styled from "styled-components";
import { CustomValidationResult } from "@abb/abb-common-ux-react";
import moment from "moment";
import { DatePicker, formatDate } from "./DatePicker";
import { getValidDateFormat, isDateInThePast } from "../../utilities/dateUtils";
import { ResizableInput } from "./styled/ResizableInput";

const DatePickerContainer = styled.div<{ positionFixed?: boolean }>`
  table {
    background: ${(props) => props.theme.colors.whitePrimary};
  }
  .rdtPicker {
    position: ${(props) => (props.positionFixed ? "fixed" : "absolute")};
  }
`;

export interface DatePickerInputProps {
  label: string;
  value: string | null | undefined;
  showClearIcon?: boolean;
  type: "normal" | "discreet";
  showValidationIconWhenInvalid?: boolean;
  showValidationBarWhenValid?: boolean;
  validator?: (value: string | null | undefined) => string | boolean | CustomValidationResult;
  onValueChange(newValue: string, datetimeInThePast?: boolean): void;
  instantValidation?: boolean | undefined;
  validationResult?: string | boolean | CustomValidationResult | null | undefined;
  positionFixed?: boolean;
}

export const DatePickerInput: React.FunctionComponent<DatePickerInputProps> = (props) => {
  return (
    <DatePickerContainer positionFixed={props.positionFixed}>
      <DatePicker
        onChange={(e) => {
          const formattedDate = formatDate(e);
          const dateInThePast = formattedDate ? isDateInThePast(e) : false;
          props.onValueChange(formattedDate ? formattedDate : "", dateInThePast);
        }}
        renderInput={(p, openCalendar) => {
          return renderDatePickerInputElement(openCalendar as () => void, props);
        }}
        value={props.value ? moment(props.value, getValidDateFormat()) : undefined}
        closeOnSelect={true}
      />
    </DatePickerContainer>
  );
};

function renderDatePickerInputElement(
  openCalendar: () => void,
  componentProps: DatePickerInputProps
) {
  return (
    <span
      onClick={() => {
        openCalendar();
      }}
    >
      <ResizableInput
        label={componentProps.label}
        type={componentProps.type}
        dataType="text"
        value={componentProps.value}
        showValidationIconWhenInvalid={componentProps.showValidationIconWhenInvalid}
        showClearIcon={componentProps.showClearIcon}
        validator={(v) => componentProps.validator?.(v) ?? true}
        instantValidation={componentProps.instantValidation}
        validationResult={componentProps.validationResult}
        onValueChange={componentProps.onValueChange}
        showValidationBarWhenValid={componentProps.showValidationBarWhenValid}
      />
    </span>
  );
}
