import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { Action } from "redux";
import { myKnowledgeAiSagas } from "../sagas/myKnowledgeAiSagas";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";

interface Requests {
  getMyKnowledgeAiJwt: RequestState<string, void>;
}

export interface MyKnowledgeAiState {
  requests: Requests;
  myKnowledgeAiJwt: string | undefined;
}

const defaultState: MyKnowledgeAiState = {
  requests: {
    getMyKnowledgeAiJwt: createInitialRequest()
  },
  myKnowledgeAiJwt: undefined
};

export function myKnowledgeAiReducer(
  state: MyKnowledgeAiState = defaultState,
  action: Action
): MyKnowledgeAiState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: myKnowledgeAiSagas.getMyKnowledgeAiJwt.actionTypes,
      key: "getMyKnowledgeAiJwt"
    }
  ]);

  if (myKnowledgeAiSagas.getMyKnowledgeAiJwt.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.myKnowledgeAiJwt = action.payload;
    });
  }

  return state;
}

export const getMyKnowledgeAiJwt = (state: AppState) => state.myKnowledgeAi.myKnowledgeAiJwt;
