import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import {
  ApiProductExplorerProductsGetRequest,
  ApiProductExplorerSearchFeaturesGetRequest,
  CategoriesMenuDto,
  ExplorerApplicationDto,
  FeatureDto,
  PaginationReturnModel,
  ProductSeriesExplorerDto,
  ProductVersionDetailDto
} from "api";
import { productExplorerSagas } from "../sagas/productExplorerSagas";
import {
  productExplorerActions,
  ProductExplorerActionTypes
} from "../actions/ProductExplorerActions";
import { RequestStatus } from "framework/state/requestStatus";
import { isEqual } from "lodash-es";

interface Requests {
  getApplications: RequestState;
  getMenuFeature: RequestState;
  getProductSeries: RequestState;
  getProducts: RequestState<ApiProductExplorerProductsGetRequest>;
  getSearchFeatures: RequestState<Array<FeatureDto>, ApiProductExplorerSearchFeaturesGetRequest>;
}

export interface ProductExplorerState {
  requests: Requests;
  applicationsDtoCollection: ExplorerApplicationDto[];
  menuFeatureDtoCollection: CategoriesMenuDto[];
  productSeriesCollection: ProductSeriesExplorerDto[];
  productsVersionDetailCollection: ProductVersionDetailDto[];
  criteriaReturnModel: PaginationReturnModel | undefined;
  activeSeries: ProductSeriesExplorerDto[];
  filters: ApiProductExplorerProductsGetRequest;
  featuresSearchResult: { features: FeatureDto[]; query: string | undefined };
}

const defaultState: ProductExplorerState = {
  applicationsDtoCollection: [],
  menuFeatureDtoCollection: [],
  productSeriesCollection: [],
  productsVersionDetailCollection: [],
  criteriaReturnModel: undefined,
  activeSeries: [],
  requests: {
    getApplications: createInitialRequest(),
    getMenuFeature: createInitialRequest(),
    getProductSeries: createInitialRequest(),
    getProducts: createInitialRequest(),
    getSearchFeatures: createInitialRequest()
  },
  // to change
  filters: {
    criteriaFunctionalityCodeList: [],
    criteriaPage: 0,
    criteriaPageSize: 6,
    criteriaApplicationId: undefined,
    criteriaStandard: undefined,
    criteriaProductSeriesId: undefined,
    criteriaLastVersionOnly: true,
    criteriaLifeCycleStatus: undefined
  },
  featuresSearchResult: { features: [], query: undefined }
};

export function productExplorerReducer(
  state: ProductExplorerState = defaultState,
  action: productExplorerActions
): ProductExplorerState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: productExplorerSagas.getApplications.actionTypes,
      key: "getApplications"
    },
    {
      actionTypes: productExplorerSagas.getMenuFeature.actionTypes,
      key: "getMenuFeature"
    },
    {
      actionTypes: productExplorerSagas.getProductSeries.actionTypes,
      key: "getProductSeries"
    },
    {
      actionTypes: productExplorerSagas.getProducts.actionTypes,
      key: "getProducts"
    },
    {
      actionTypes: productExplorerSagas.getSearchFeatures.actionTypes,
      key: "getSearchFeatures"
    }
  ]);

  if (productExplorerSagas.getApplications.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.applicationsDtoCollection = action.payload;
    });
  } else if (productExplorerSagas.getMenuFeature.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.menuFeatureDtoCollection = action.payload;
    });
  } else if (productExplorerSagas.getProductSeries.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.productSeriesCollection = action.payload;
    });
  } else if (productExplorerSagas.getProducts.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.productsVersionDetailCollection = action.payload.products;
      draft.criteriaReturnModel = action.payload.criteria;
      draft.activeSeries = action.payload.activeSeries;
    });
  } else if (productExplorerSagas.getSearchFeatures.isRequestAction(action)) {
    state = produce(state, (draft) => {
      draft.featuresSearchResult.features = [];
      draft.featuresSearchResult.query = action.payload.query;
    });
  } else if (productExplorerSagas.getSearchFeatures.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.featuresSearchResult.features = action.payload;
    });
  }
  /* when I change every filter beside page itself I should start from page 0  */
  switch (action.type) {
    case ProductExplorerActionTypes.SetFilterApplications:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaApplicationId != action.applicationId)
          draft.filters.criteriaApplicationId = action.applicationId;
        draft.filters.criteriaPage = 0;
      });
      break;
    case ProductExplorerActionTypes.SetFilterFunctionalities:
      state = produce(state, (draft) => {
        if (!isEqual(draft.filters.criteriaFunctionalityCodeList, action.functionalityIdList))
          draft.filters.criteriaPage = 0;
        draft.filters.criteriaFunctionalityCodeList = action.functionalityIdList;
      });
      break;
    case ProductExplorerActionTypes.SetFilterStandard:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaStandard != (action.standard ?? undefined))
          draft.filters.criteriaPage = 0;
        draft.filters.criteriaStandard = action.standard ?? undefined;
      });
      break;
    case ProductExplorerActionTypes.SetPageNumber:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaPage != action.pageNumber)
          draft.filters.criteriaPage = action.pageNumber;
      });
      break;
    case ProductExplorerActionTypes.SetPageSize:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaPageSize != action.pageSize) draft.filters.criteriaPage = 0;
        draft.filters.criteriaPageSize = action.pageSize;
      });
      break;
    case ProductExplorerActionTypes.SetSeriesId:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaProductSeriesId != action.seriesId)
          draft.filters.criteriaPage = 0;
        draft.filters.criteriaProductSeriesId = action.seriesId;
      });
      break;
    case ProductExplorerActionTypes.SetFilterShowOnlyLatest:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaLastVersionOnly != action.showOnlyLatest) {
          draft.filters.criteriaPage = 0;
          draft.filters.criteriaLastVersionOnly = action.showOnlyLatest;
        }
      });
      break;
    case ProductExplorerActionTypes.SetLifeCycleStatus:
      state = produce(state, (draft) => {
        if (draft.filters.criteriaLifeCycleStatus != action.lifeCycleStatus) {
          draft.filters.criteriaPage = 0;
          draft.filters.criteriaLifeCycleStatus = action.lifeCycleStatus;
        }
      });
      break;
  }
  return state;
}

export function getApplications(state: AppState): ExplorerApplicationDto[] {
  return state.productExplorer.applicationsDtoCollection;
}
export function getMenuFeatures(state: AppState): CategoriesMenuDto[] {
  return state.productExplorer.menuFeatureDtoCollection;
}
export function getMenuFeaturesStatus(state: AppState): RequestStatus {
  return state.productExplorer.requests.getMenuFeature.status;
}
export function getProductSeries(state: AppState): ProductSeriesExplorerDto[] {
  return state.productExplorer.productSeriesCollection;
}
export function getProducts(state: AppState): ProductVersionDetailDto[] {
  return state.productExplorer.productsVersionDetailCollection;
}
export function getFilter(state: AppState): ApiProductExplorerProductsGetRequest {
  return state.productExplorer.filters;
}
export function getFilterStatus(state: AppState): RequestStatus {
  return state.productExplorer.requests.getProducts.status;
}
export function getCriteriaReturnModel(state: AppState): PaginationReturnModel | undefined {
  return state.productExplorer.criteriaReturnModel;
}
export function getActiveSeries(state: AppState): ProductSeriesExplorerDto[] {
  return state.productExplorer.activeSeries;
}
export function getPage(state: AppState): number {
  return state.productExplorer.filters.criteriaPage;
}
export function getPageSize(state: AppState): number {
  return state.productExplorer.filters.criteriaPageSize;
}
export function getSearchFeatureResults(state: AppState) {
  return state.productExplorer.featuresSearchResult;
}
export function getSearchFeatureStatus(state: AppState) {
  return state.productExplorer.requests.getSearchFeatures.status;
}
/* 
I need to understand how to use this datatype
export const getApplicationDtoCollection = (
  state: AppState
): RequestState => state.productExplorer.requests.getApplicationDtoCollection; */
