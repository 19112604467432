import { Input } from "@abb/abb-common-ux-react/components/Input/Input";
import { QueryKeyConstants } from "constants/queryKeyConstants";
import React, { FormEvent, useState, useRef } from "react";
import styled from "styled-components";
import { routes } from "utilities/routes";

export interface KnowledgeWarehouseSearchInputProps {
  initialSearch?: string | undefined | null;
  placeHolderText: string;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsKnowledgeWarehouseSearchInputClosed: React.Dispatch<React.SetStateAction<boolean>>;
  isKnowledgeWarehouseSearchInputClosed: boolean;
  isMobile: boolean;
  isSmallScreen: boolean;
  baseUrl: string;
  navigate?: (path: string) => void;
}

const Form = styled.form`
  width: 100%;
`;

export const KnowledgeWarehouseSearchInput = ({
  initialSearch,
  placeHolderText,
  setMobileMenuOpen,
  setIsKnowledgeWarehouseSearchInputClosed,
  isKnowledgeWarehouseSearchInputClosed,
  isMobile,
  isSmallScreen,
  baseUrl,
  navigate
}: KnowledgeWarehouseSearchInputProps) => {
  const [search, setSearch] = useState(initialSearch);
  const inputRef = useRef<Input>(null);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (!isMobile && isSmallScreen && isKnowledgeWarehouseSearchInputClosed) {
      if (inputRef.current) inputRef.current.focus();
      setIsKnowledgeWarehouseSearchInputClosed(false);
      return;
    }
    if (!search) return;
    const queryParams = new URLSearchParams();
    queryParams.set(QueryKeyConstants.search, search || "");
    // if open can the menu needs to be closed

    const path = routes.training.knowledgeWarehouseView + "?" + queryParams.toString();
    if (navigate) {
      navigate(path);
    } else {
      document.location.href = baseUrl + path;
    }
    if (isMobile) setMobileMenuOpen(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        dataType="text"
        placeholder={placeHolderText}
        icon="abb/search"
        value={search}
        onValueChange={setSearch}
        style={{ width: "100%" }}
        onIconClick={() => handleSubmit()}
        onLostFocus={() => {
          // this is a fix to solve the problem that when pressing the icon to search lost is triggered too
          setTimeout(() => {
            if (!isMobile && isSmallScreen) {
              setIsKnowledgeWarehouseSearchInputClosed(true);
            }
          }, 200);
        }}
        ref={inputRef}
      />
    </Form>
  );
};
