import styled from "styled-components";
import Select from "react-select";
import { commonUXTheme } from "styles/commonUXVariables";
import { AppTopNaviItem } from "@abb/abb-common-ux-react/components/AppTopNaviItem";
import { Menu } from "@abb/abb-common-ux-react/components/Menu";
import { AppHeader } from "@abb/abb-common-ux-react/components/AppHeader";
import { Stack, Box, Grid, Icon } from "@mui/material";
import { Button } from "framework/components/Button";
import { companiesDropdownWidth } from "./CompaniesDropdown";
import { Link } from "react-router-dom";

export const HeaderHeight = 60;
export const PreferredWideScreenSize = 1850;
export const KnowledgeWarehouseSearchInputSize = 300;
export const KnowledgeWarehouseSearchInputSizeSmall = 60;
export const getDownMediaQuery = (limit: number) => {
  return `(max-width: ${limit}px)`;
};

//TODO: type for dropdown menu OnChangeValue option, use this as  Select<OptionType>
export type OptionType = {
  label?: string;
  value?: string;
};

export const CompanyDropdown = styled(Select)<{
  width?: number;
  menuIsOpen?: boolean;
  isLoading?: boolean;
}>`
  margin-top: 0px;
  width: 100%;
  .ABB__indicators {
    justify-content: flex-end;
  }
  min-width: ${(props) =>
    props.menuIsOpen
      ? props.width
        ? `${props.width}px`
        : companiesDropdownWidth + "px"
      : "170px"};
  .ABB__control {
    border: none !important;
    border-radius: 0px;
    border-color: transparent;
    box-shadow: none !important;
    cursor: pointer;
    :hover {
      background-color: ${commonUXTheme.colors.grey10};
    }
    color: ${commonUXTheme.colors.grey90};
  }
  .ABB__indicator-separator {
    width: 0px !important;
  }
  .ABB__value-container {
    color: ${commonUXTheme.colors.grey90};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
    flex-wrap: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: ${HeaderHeight}px;
  }
  .ABB__dropdown-indicator {
    display: ${(props) => (props.isLoading ? "none" : "flex")};
    color: ${commonUXTheme.colors.grey90};
  }
  .ABB__option {
    color: ${commonUXTheme.colors.grey90};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
    cursor: pointer;
    :active {
      background-color: ${commonUXTheme.colors.grey30} !important;
    }
  }
  .ABB__option--is-selected {
    background-color: ${commonUXTheme.colors.grey60} !important;
    color: ${commonUXTheme.colors.whitePrimary};
    :hover {
      background-color: ${commonUXTheme.colors.grey70} !important;
    }
  }
  .ABB__option--is-disabled {
    color: ${commonUXTheme.colors.grey90};
    color: ${commonUXTheme.colors.grey40};
  }
  .ABB__option--is-focused {
    background-color: ${commonUXTheme.colors.grey10};
  }
  .ABB__menu {
    min-width: ${companiesDropdownWidth}px;
  }
  .ABB__single-value {
    white-space: pre-wrap;
  }
  .ABB_Form__control {
    background: white;
    border-radius: 0px;
    border-color: ${commonUXTheme.colors.grey30};
    border-style: solid;
    border-width: ${commonUXTheme.borders.widths.borderWidthS};
    box-shadow: none !important;
    cursor: pointer;
    height: ${commonUXTheme.sizes.l};
    min-height: ${commonUXTheme.sizes.l};
    :hover {
      background: ${commonUXTheme.colors.grey10};
      border-color: ${commonUXTheme.colors.grey30};
    }
    color: ${commonUXTheme.colors.grey90};
  }
  .ABB_Form__indicator-separator {
    width: 0px !important;
  }
  .ABB_Form__value-container {
    color: ${commonUXTheme.colors.grey90};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
  }
  .ABB_Form__dropdown-indicator {
    color: ${commonUXTheme.colors.grey30};
    padding: 5px;
  }
  .ABB_Form__dropdown-indicator svg {
    width: ${commonUXTheme.sizes.m};
    height: ${commonUXTheme.sizes.m};
  }
  .ABB_Form__option {
    padding: 6px 12px;
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
    color: ${commonUXTheme.colors.grey90};
    cursor: pointer;
    :active {
      background-color: ${commonUXTheme.colors.grey10} !important;
    }
  }
  .ABB_Form__option--is-selected {
    background-color: ${commonUXTheme.colors.grey60} !important;
    color: ${commonUXTheme.colors.whitePrimary};
    :hover {
      background-color: ${commonUXTheme.colors.grey70} !important;
    }
  }
  .ABB_Form__option--is-disabled {
    background-color: ${commonUXTheme.colors.grey60} !important;
    color: ${commonUXTheme.colors.yellow10};
  }
  .ABB_Form__option--is-focused {
    background-color: ${commonUXTheme.colors.grey10};
  }
  .ABB_Form__single-value {
  }
  .ABB_Form__menu {
    margin-top: 0;
  }
`;

export const DropdownWrapper = styled.div<{ isMobile: boolean }>`
  height: ${HeaderHeight}px;
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.isMobile ? "1px solid " + commonUXTheme.colors.grey40 : "none"};
  .ABB__value-container {
    padding-left: ${(props) => (props.isMobile ? commonUXTheme.sizes.m : commonUXTheme.sizes.s)};
  }
`;

export const CustomAppHeader = styled(AppHeader)<{
  backgroundColor: string;
  isSmallScreen: boolean;
}>`
  background-color: ${(props) => props.backgroundColor} !important;
  height: ${HeaderHeight}px;
  .ABB_CommonUX_AppTopNavi__root {
    flex: auto;
    width: ${(props) => (props.isSmallScreen ? "100%" : "auto")};
  }

  .ABB_CommonUX_AppTopNavi__itemContainer {
    flex-wrap: wrap;
  }

  .ABB_CommonUX_AppTopNavi__item
    > .ABB_CommonUX_AppTopNavi__container
    > .ABB_CommonUX_AppTopNavi__header {
    height: ${HeaderHeight}px;
  }

  .ABB_CommonUX_AppTopNavi__item
    > .ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__textOnly
    > div.ABB_CommonUX_AppTopNavi__text {
    text-align: center;
    line-height: ${HeaderHeight}px;
    vertical-align: middle;
    padding-top: 0;
  }

  .ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light {
    height: ${HeaderHeight}px;
    margin-top: 0 !important;
    background-color: ${(props) => props.backgroundColor} !important;
    position: relative;
  }

  .ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__active
    > .ABB_CommonUX_AppTopNavi__container
    > .ABB_CommonUX_AppTopNavi__header:after {
    height: 4px;
    background-color: ${commonUXTheme.colors.brandRed};
  }
  .comparator {
    .ABB_CommonUX_AppTopNavi__header {
      flex-direction: row-reverse !important;
      gap: ${commonUXTheme.sizes.sm};
    }
  }
`;

export const AppHeaderContainer = styled(Grid)<{
  $mobileBreakpoint: string;
  $isMobileMenuOpen: boolean;
}>(({ $mobileBreakpoint, $isMobileMenuOpen }) => ({
  boxShadow: $isMobileMenuOpen ? "none" : "0px 1px 3px #00000033",
  zIndex: commonUXTheme.zIndices.appHeader,
  ".ABB_CommonUX_Menu__root": {
    ".MenuItem_Title": {
      color: `${commonUXTheme.colors.grey90} !important`,
      backgroundColor: commonUXTheme.colors.grey10,
      borderBottom: `1px solid ${commonUXTheme.colors.grey30}`
    }
  },
  ".ABB_CommonUX_Menu__menuItem": {
    "&:hover": {
      textDecoration: "underline"
    }
  },
  [`@media (max-width:${$mobileBreakpoint})`]: {
    height: $isMobileMenuOpen ? "100vh" : `${HeaderHeight}px`,
    overflow: "auto",
    paddingBottom: $isMobileMenuOpen ? commonUXTheme.sizes.l : "0",

    ".ABB_CommonUX_AppHeader__root": {
      height: $isMobileMenuOpen ? `calc(100vh - ${HeaderHeight}px)` : `${HeaderHeight}px`
    },
    ".ABB_CommonUX_Menu__root": {
      padding: 0,
      position: "relative",
      boxShadow: "none"
    },
    ".ABB_CommonUX_AppTopNavi__container": {
      height: "auto",
      borderBottom: `1px solid ${commonUXTheme.colors.grey40}`
    },
    ".ABB_CommonUX_AppTopNavi__item .ABB_CommonUX_AppTopNavi__container": {
      borderLeft: `${commonUXTheme.sizes.xs} solid transparent`
    },
    ".ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__light": {
      height: "auto",
      width: "100%"
    },
    ".ABB_CommonUX_AppTopNavi__item.ABB_CommonUX_AppTopNavi__active": {
      //color: commonUXTheme.colors.brandRed,
      ".ABB_CommonUX_AppTopNavi__container": {
        borderLeft: `${commonUXTheme.sizes.xs} solid ${commonUXTheme.colors.brandRed}`
      },
      ".ABB_CommonUX_AppTopNavi__header:after": {
        height: "0 !important"
      }
    },
    ".ABB_CommonUX_Menu__menuItem": {
      height: parseInt(commonUXTheme.sizes.lm.replace("px", ""), 10) * 2,
      boxSizing: "border-box"
    }
  }
}));

export const LogoAndNameContainer = styled.div<{
  isMobile: boolean;
  isMobileOpen: boolean;
  backgroundColor: string;
}>(({ isMobile, isMobileOpen, backgroundColor }) => ({
  position: isMobile ? "absolute" : "relative",
  top: 0,
  width: isMobile ? "100%" : "auto",
  height: HeaderHeight,
  display: "flex",
  alignItems: "center",
  paddingInline: commonUXTheme.sizes.m,
  boxShadow: !isMobileOpen || isMobile ? "none" : "0px 1px 3px #00000033",
  backgroundColor: backgroundColor,
  boxSizing: "border-box",
  gap: commonUXTheme.sizes.s
}));

export const AppLogo = styled.img`
  height: 22px;
  align-self: center;
  :hover {
    cursor: pointer;
  }
`;

export const CustomMenuIcon = styled(Icon)`
  img {
    max-width: 100%;
    display: block;
  }
`;

export const AppName = styled(Box)`
  margin: 0;
  font-family: ${commonUXTheme.fonts.families.fontAbbBold};
`;

export const DropdownLabel = styled.p`
  font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
  color: ${commonUXTheme.colors.grey50};
  margin-inline: ${commonUXTheme.sizes.xs};
  margin-block: auto;
`;

export const DropdownContainer = styled.div`
  width: 220px;
  margin-top: 0px;
`;

export const UserWrapper = styled(Stack)<{ $isMobile: boolean }>`
  flex-direction: row !important;
  position: relative;
  justify-content: center;
  align-items: center;
  max-height: ${HeaderHeight}px !important;
  display: ${(props) => (props.$isMobile ? "block !important" : "flex !important")};
`;

export const MenuItemsWrapper = styled(Stack)<{ $mobileMenuOpen: boolean; $isMobile: boolean }>`
  margin-top: ${(props) => (props.$mobileMenuOpen ? `${HeaderHeight}px` : "0")};
  flex-direction: ${(props) => (props.$isMobile ? "column !important" : "row !important")};
  flex-grow: 2;

  @media print {
    display: none;
  }
`;
export const RightIconsContainer = styled.div<{ $isMobile: boolean }>`
  position: ${(props) => (props.$isMobile ? "static" : "absolute")};
  display: flex;
  ${(props) => (props.$isMobile ? "" : "right: 0px;")};
  flex-direction: ${(props) => (props.$isMobile ? "column !important" : "row !important")};
  flex-grow: 2;
`;
export const KnowledgeWarehouseSearchInputWrapper = styled(Stack)<{
  $isMobile: boolean;
  $isSmallScreen: boolean;
  $isSmallScreenOpen: boolean;
  $backgroundColor: string;
}>`
  border-bottom: ${(props) => (props.$isMobile ? commonUXTheme.borders.widths.borderWidthS : "0")}
    solid ${commonUXTheme.colors.grey40};
  border-left: ${(props) => (props.$isMobile ? commonUXTheme.sizes.m : "0")} solid transparent;
  flex-direction: row !important;
  align-items: center;
  .ABB_CommonUX_Input__root {
    width: ${(props) =>
      props.$isMobile
        ? "100%"
        : props.$isSmallScreen
        ? props.$isSmallScreenOpen
          ? KnowledgeWarehouseSearchInputSize
          : KnowledgeWarehouseSearchInputSizeSmall
        : KnowledgeWarehouseSearchInputSize}px !important;
    > .ABB_CommonUX_Input__inputWrapper {
      height: 100%;
      border: 0;
      align-items: center;
      background-color: ${(props) =>
        props.$isSmallScreen
          ? props.$backgroundColor
          : commonUXTheme.colors.whitePrimary} !important;
      input {
        background: ${commonUXTheme.colors.whitePrimary};
        padding: ${(props) =>
          props.$isSmallScreen && !props.$isSmallScreenOpen
            ? "0"
            : `${commonUXTheme.sizes.xs} ${commonUXTheme.sizes.s}`};
        min-width: ${(props) => (props.$isSmallScreen && !props.$isSmallScreenOpen ? "0" : "2ch")};
      }
    }
    .ABB_CommonUX_Input__iconWrapper {
      margin: ${(props) =>
        props.$isSmallScreen
          ? "0"
          : `${commonUXTheme.sizes.xs} ${commonUXTheme.sizes.s} ${commonUXTheme.sizes.s} 0`};
      height: ${(props) =>
        props.$isSmallScreen
          ? `${KnowledgeWarehouseSearchInputSizeSmall}px`
          : commonUXTheme.sizes.m};
      width: ${(props) =>
        props.$isSmallScreen
          ? `${KnowledgeWarehouseSearchInputSizeSmall}px`
          : commonUXTheme.sizes.m};
      display: ${(props) => (props.$isSmallScreen ? "flex" : "block")};
      align-items: center;
      justify-content: center;
      > .ABB_CommonUX_Icon__root > .ABB_CommonUX_Icon__icon_abb_16 {
        font-family: ${(props) =>
          props.$isSmallScreen
            ? commonUXTheme.fonts.families.fontIconM
            : commonUXTheme.fonts.families.fontIconS};
        font-size: ${(props) =>
          props.$isSmallScreen
            ? commonUXTheme.fonts.sizes.fontSizeL
            : commonUXTheme.fonts.sizes.fontSizeM};
        height: ${(props) =>
          props.$isSmallScreen ? commonUXTheme.sizes.lm : commonUXTheme.sizes.m};
        width: ${(props) =>
          props.$isSmallScreen ? commonUXTheme.sizes.lm : commonUXTheme.sizes.m};
      }
    }
  }
`;
export const UserInfoText = styled.div<{ isSignedIn: boolean; $isMobile: boolean }>`
  margin: 0;
  font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: ${(props) => (!props.isSignedIn ? "pointer !important" : "inherit")};
  margin-left: ${(props) => (props.$isMobile ? "0" : commonUXTheme.sizes.sm)};
  margin-right: ${(props) => (props.$isMobile ? "0" : commonUXTheme.sizes.xs)};

  > span {
    margin-right: ${(props) =>
      props.isSignedIn ? commonUXTheme.sizes.xs : commonUXTheme.sizes.sm};
    margin-left: ${commonUXTheme.sizes.m};
  }
  .ABB_CommonUX_AppTopNavi__textOnly {
    justify-content: flex-start !important;
  }
`;

export const IconTopNavItem = styled(AppTopNaviItem)`
  .ABB_CommonUX_AppTopNavi__header.ABB_CommonUX_AppTopNavi__iconOnly {
    margin-right: 0;
    text-align: center;
    line-height: ${HeaderHeight}px;
    vertical-align: middle;
    padding-top: 0;
  }
  .ABB_CommonUX_AppTopNavi__header {
    height: ${HeaderHeight}px;
    width: ${HeaderHeight}px;
    justify-content: center;
    padding: 0;
    margin-inline: ${commonUXTheme.sizes.m};
    .ABB_CommonUX_AppTopNavi__caret {
      display: none !important;
    }
  }
  .ABB_CommonUX_AppTopNavi__light {
    height: ${HeaderHeight}px;
    width: ${HeaderHeight}px;
    margin-top: 0 !important;
  }
`;

export const TextTopNavItem = styled(AppTopNaviItem)<{ isMobile: boolean }>(({ isMobile }) => {
  return (
    isMobile && {
      height: "auto",
      "&.submenu .ABB_CommonUX_AppTopNavi__container:hover": {
        background: "inherit !important"
      },
      ".ABB_CommonUX_AppTopNavi__container": {
        "&:hover": {
          color: commonUXTheme.colors.brandRed,

          /* to change the color of the logo also of the comparator */
          img: {
            filter:
              "invert(22%) sepia(99%) saturate(7247%) hue-rotate(352deg) brightness(107%) contrast(115%)"
          }
        },
        ".ABB_CommonUX_AppTopNavi__textOnly": {
          justifyContent: "left !important",
          paddingLeft: `${commonUXTheme.sizes.sm} !important`
        }
      },
      ".ABB_CommonUX_AppTopNavi__caret": {
        marginLeft: "auto"
      },
      "&.submenu-open .ABB_CommonUX_Icon-16-style__icon-abb_caret-down_16:before": {
        transform: "rotate(90deg)",
        transition: "0.5s"
      },
      ".ABB_CommonUX_Icon-16-style__icon-abb_caret-down_16:before": {
        content: "'\\e998'",
        position: "absolute"
      },
      ".ABB_CommonUX_Menu__root.ABB_CommonUX_Menu__light": {
        backgroundColor: commonUXTheme.colors.whiteTertiary
      },
      ".ABB_CommonUX_AppTopNavi__iconAndText": {
        paddingLeft: `${commonUXTheme.sizes.sm} !important`,
        ".ABB_CommonUX_AppTopNavi__icon": {
          marginRight: `${commonUXTheme.sizes.sm} !important`
        }
      }
    }
  );
});

export const MenuItems = styled(Menu)<{ $isMobile: boolean }>`
  ul {
    .ABB_CommonUX_Menu__menuItem {
      border-left: ${commonUXTheme.sizes.xs} solid transparent;
    }

    .has_notification .ABB_CommonUX_Menu__menuItemTitle:after {
      content: "!";
      border-radius: 10px;
      min-width: 22px;
      border: 1px ${commonUXTheme.colors.brandRed};
      padding: ${commonUXTheme.sizes.xxs};
      padding-inline: ${commonUXTheme.sizes.s};
      background: ${commonUXTheme.colors.brandRed};
      font-size: ${commonUXTheme.fonts.sizes.fontSizeXs};
      color: ${commonUXTheme.colors.whitePrimary};
      text-align: center;
      cursor: pointer;
      pointer-events: none;
      margin-left: ${commonUXTheme.sizes.xs};
    }

    .ABB_CommonUX_Menu__selected {
      background-color: ${(props) =>
        props.$isMobile ? "inherit" : commonUXTheme.colors.whitePrimary} !important;
      color: ${commonUXTheme.colors.grey90} !important;
      border-left: ${commonUXTheme.sizes.xs} solid ${commonUXTheme.colors.brandRed};
    }
  }
`;

export const HamburgerButton = styled(Button)`
  margin-left: auto;
  border-radius: initial !important;
  border: none !important;
  &:active,
  &:hover {
    background-color: ${commonUXTheme.colors.whitePrimary} !important;
  }
  > div {
    min-width: 45px !important;
  }
  .ABB_CommonUX_Icon__root > i.ABB_CommonUX_Icon__icon_abb_16 {
    font-size: ${commonUXTheme.sizes.lm};
    height: ${commonUXTheme.sizes.lm};
    width: ${commonUXTheme.sizes.lm};
  }
`;

export const IconSuperscriptArea = styled.span<{ $isMobile: boolean }>`
  position: absolute;
  top: 15px;
  right: ${(props) => (props.$isMobile ? undefined : "8px")};
  left: ${(props) => (props.$isMobile ? "30px" : undefined)};
  border-radius: 10px;
  min-width: 20px;
  border: ${commonUXTheme.borders.widths.borderWidthS} ${commonUXTheme.colors.brandRed};
  padding-left: ${commonUXTheme.sizes.xs};
  padding-right: ${commonUXTheme.sizes.xs};
  background: ${commonUXTheme.colors.brandRed};
  font-size: ${commonUXTheme.fonts.sizes.fontSizeXs};
  color: ${commonUXTheme.colors.whitePrimary};
  text-align: center;
  pointer-events: none;
`;

export const StyledNavLink = styled(Link)`
  margin-inline-start: ${commonUXTheme.sizes.m};
  margin-inline-end: ${commonUXTheme.sizes.s};
`;
