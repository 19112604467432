import React from "react";
import { Dialog } from "framework/components/Dialog";

import { Stack } from "@mui/system";
import MarkdownViewer from "framework/components/MarkdownViewer";
import { GlobalSettingDto } from "api/models/GlobalSettingDto";
import { Heading } from "@abb/abb-common-ux-react/components/Heading";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";

interface DisclaimerDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disclaimerSetting: GlobalSettingDto | undefined;
}

const StyledDialog = styled(Dialog)`
  .ABB_CommonUX_Dialog__closeButton {
    z-index: ${commonUXTheme.zIndices.footerDisclaimer} !important;
  }
`;

export const DisclaimerDialog = ({
  isOpen,
  setIsOpen,
  disclaimerSetting
}: DisclaimerDialogProps) => {
  return (
    <StyledDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      dimBackground
      closeOnLostFocus
      showCloseButton
      contentClassName={"dialog-content overflow-auto"}
      containerClassName={"dialog-container"}
    >
      <Stack minHeight={200}>
        <Heading showCursor level={3} text={disclaimerSetting?.title ?? ""} />
        <MarkdownViewer markdownContent={disclaimerSetting?.value ?? ""} />
      </Stack>
    </StyledDialog>
  );
};
