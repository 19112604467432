/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationNotificationTypes,
    ApplicationNotificationTypesFromJSON,
    ApplicationNotificationTypesFromJSONTyped,
    ApplicationNotificationTypesToJSON,
} from './ApplicationNotificationTypes';

/**
 * 
 * @export
 * @interface ApplicationNotificationDto
 */
export interface ApplicationNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationNotificationDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNotificationDto
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNotificationDto
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNotificationDto
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNotificationDto
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationNotificationDto
     */
    user: string;
    /**
     * 
     * @type {ApplicationNotificationTypes}
     * @memberof ApplicationNotificationDto
     */
    notificationTypeId: ApplicationNotificationTypes;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationNotificationDto
     */
    isRead: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationNotificationDto
     */
    created: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationNotificationDto
     */
    contentIsInHtmlFormat: boolean;
}

export function ApplicationNotificationDtoFromJSON(json: any): ApplicationNotificationDto {
    return ApplicationNotificationDtoFromJSONTyped(json, false);
}

export function ApplicationNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'header': json['header'],
        'content': json['content'],
        'from': json['from'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'user': json['user'],
        'notificationTypeId': ApplicationNotificationTypesFromJSON(json['notificationTypeId']),
        'isRead': json['isRead'],
        'created': (new Date(json['created'])),
        'contentIsInHtmlFormat': json['contentIsInHtmlFormat'],
    };
}

export function ApplicationNotificationDtoToJSON(value?: ApplicationNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'header': value.header,
        'content': value.content,
        'from': value.from,
        'company': value.company,
        'user': value.user,
        'notificationTypeId': ApplicationNotificationTypesToJSON(value.notificationTypeId),
        'isRead': value.isRead,
        'created': (value.created.toISOString()),
        'contentIsInHtmlFormat': value.contentIsInHtmlFormat,
    };
}

