import { NodeDto } from "api";
import { Action } from "redux";
export enum KnowledgeWarehouseActionType {
  NodeExpanded = "knowledgeWarehouseAction/NodeExpanded",
  CurrentAction = "knowledgeWarehouseAction/CurrentAction",
  SearchState = "knowledgeWarehouseAction/SearchState",
  SetSearchText = "knowledgeWarehouseAction/SetSearchText",
  SetLoadingResults = "knowledgeWarehouseAction/SetLoadingResults",
  SetPageNumber = "knowledgeWarehouseAction/SetPageNumber",
  SetPageSize = "knowledgeWarehouseAction/SetPageSize",
  SetForceScroll = "knowledgeWarehouseAction/SetForceScroll",
  SetResetSearch = "knowledgeWarehouseAction/SetResetSearch",
  SetActiveCategories = "knowledgeWarehouseAction/SetActiveCategories"
}
export enum KnowledgeWarehouseAction {
  showingNode,
  showingArticle,
  showingSearch,
  showingNew
}

export interface NodeExpanded extends Action {
  type: KnowledgeWarehouseActionType.NodeExpanded;
  node: NodeDto | undefined;
}
export interface CurrentAction extends Action {
  type: KnowledgeWarehouseActionType.CurrentAction;
  action: KnowledgeWarehouseAction | undefined;
}

export interface SetSearchText extends Action {
  type: KnowledgeWarehouseActionType.SetSearchText;
  search: string | undefined;
}

export interface SetLoadingResults extends Action {
  type: KnowledgeWarehouseActionType.SetLoadingResults;
  loading: boolean | undefined;
}
export interface SetPageSize extends Action {
  type: KnowledgeWarehouseActionType.SetPageSize;
  size: number;
}
export interface SetPageNumber extends Action {
  type: KnowledgeWarehouseActionType.SetPageNumber;
  page: number;
}
export interface SetForceScroll extends Action {
  type: KnowledgeWarehouseActionType.SetForceScroll;
  force: boolean;
}
export interface SetResetSearch extends Action {
  type: KnowledgeWarehouseActionType.SetResetSearch;
  reset: boolean;
}

export interface SetActiveCategories extends Action {
  type: KnowledgeWarehouseActionType.SetActiveCategories;
  categories: string[];
}
export const KnowledgeWarehouseActions = {
  nodeExpanded: (node: NodeDto | undefined): NodeExpanded => ({
    type: KnowledgeWarehouseActionType.NodeExpanded,
    node
  }),
  action: (action: KnowledgeWarehouseAction | undefined): CurrentAction => ({
    type: KnowledgeWarehouseActionType.CurrentAction,
    action
  }),
  setSearchText: (search: string | undefined): SetSearchText => ({
    type: KnowledgeWarehouseActionType.SetSearchText,
    search
  }),
  setLoading: (loading: boolean | undefined): SetLoadingResults => ({
    type: KnowledgeWarehouseActionType.SetLoadingResults,
    loading
  }),
  setPageSize: (size: number): SetPageSize => ({
    type: KnowledgeWarehouseActionType.SetPageSize,
    size
  }),
  setPageNumber: (page: number): SetPageNumber => ({
    type: KnowledgeWarehouseActionType.SetPageNumber,
    page
  }),
  setForceScroll: (force: boolean): SetForceScroll => ({
    type: KnowledgeWarehouseActionType.SetForceScroll,
    force
  }),
  setResetSearch: (reset: boolean): SetResetSearch => ({
    type: KnowledgeWarehouseActionType.SetResetSearch,
    reset
  }),
  setActiveNameCategories: (categories: string[]): SetActiveCategories => ({
    type: KnowledgeWarehouseActionType.SetActiveCategories,
    categories
  })
};

export type KnowledgeWarehouseActions =
  | NodeExpanded
  | CurrentAction
  | SetLoadingResults
  | SetPageNumber
  | SetPageSize
  | SetSearchText
  | SetForceScroll
  | SetResetSearch
  | SetActiveCategories;
