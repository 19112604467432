import { GlobalSettingApi } from "api/apis/GlobalSettingApi";
import { ReleaseNotesApi } from "api/apis/ReleaseNotesApi";
import { GlobalSettingDto } from "api/models/GlobalSettingDto";
import { GlobalSettingTypes } from "api/models/GlobalSettingTypes";
import { ReleaseNoteDto } from "api/models/ReleaseNoteDto";
import { Configuration } from "api/runtime";
import { useCallback, useEffect, useState } from "react";

export const useRelaysOnlineFooter = (basePath: string) => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNoteDto[] | undefined>();

  const [disclaimerGlobalSetting, setDisclaimerGlobalSetting] = useState<
    GlobalSettingDto | undefined
  >(undefined);

  const getReleaseNotes = useCallback(async () => {
    const releaseNotesApi = new ReleaseNotesApi(new Configuration({ basePath: basePath }));

    try {
      const releaseNotes = await releaseNotesApi.apiReleaseNotesGet();
      setReleaseNotes(releaseNotes);
    } catch (e) {
      console.log(e);
    }
  }, [basePath]);

  const getGlobalSettings = useCallback(async () => {
    const globalSettingsApi = new GlobalSettingApi(new Configuration({ basePath: basePath }));

    try {
      const globalSettings = await globalSettingsApi.apiGlobalSettingGet({});
      setDisclaimerGlobalSetting(
        globalSettings.find((g) => g.globalSettingType === GlobalSettingTypes.Disclaimer)
      );
    } catch (e) {
      console.log(e);
    }
  }, [basePath]);

  useEffect(() => {
    if (!releaseNotes) {
      getReleaseNotes();
    }
  }, [getReleaseNotes, releaseNotes]);

  useEffect(() => {
    if (!disclaimerGlobalSetting) {
      getGlobalSettings();
    }
  }, [disclaimerGlobalSetting, getGlobalSettings]);

  return {
    releaseNotes,
    disclaimerGlobalSetting
  };
};
