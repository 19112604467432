/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProductParameter,
    ProductParameterFromJSON,
    ProductParameterFromJSONTyped,
    ProductParameterToJSON,
} from './ProductParameter';

/**
 * 
 * @export
 * @interface ConfiguratorProductDetails
 */
export interface ConfiguratorProductDetails {
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    orderingCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProductDetails
     */
    validationResult: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProductDetails
     */
    hasAccessories: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    materialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    staticProductType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    plusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    originalCompositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    originalPlusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    productCompositionCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConfiguratorProductDetails
     */
    isSoftwareProduct?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorProductDetails
     */
    modificationCaseId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfiguratorProductDetails
     */
    categoryName?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfiguratorProductDetails
     */
    excludedModificationMaterials?: Array<string> | null;
    /**
     * 
     * @type {Array<ProductParameter>}
     * @memberof ConfiguratorProductDetails
     */
    parameters?: Array<ProductParameter> | null;
}

export function ConfiguratorProductDetailsFromJSON(json: any): ConfiguratorProductDetails {
    return ConfiguratorProductDetailsFromJSONTyped(json, false);
}

export function ConfiguratorProductDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfiguratorProductDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCode': json['orderingCode'],
        'validationResult': json['validationResult'],
        'hasAccessories': json['hasAccessories'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'staticProductType': !exists(json, 'staticProductType') ? undefined : json['staticProductType'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'plusCode': !exists(json, 'plusCode') ? undefined : json['plusCode'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'originalCompositionCode': !exists(json, 'originalCompositionCode') ? undefined : json['originalCompositionCode'],
        'originalPlusCode': !exists(json, 'originalPlusCode') ? undefined : json['originalPlusCode'],
        'productCompositionCode': !exists(json, 'productCompositionCode') ? undefined : json['productCompositionCode'],
        'isSoftwareProduct': !exists(json, 'isSoftwareProduct') ? undefined : json['isSoftwareProduct'],
        'modificationCaseId': !exists(json, 'modificationCaseId') ? undefined : json['modificationCaseId'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'excludedModificationMaterials': !exists(json, 'excludedModificationMaterials') ? undefined : json['excludedModificationMaterials'],
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(ProductParameterFromJSON)),
    };
}

export function ConfiguratorProductDetailsToJSON(value?: ConfiguratorProductDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCode': value.orderingCode,
        'validationResult': value.validationResult,
        'hasAccessories': value.hasAccessories,
        'materialCode': value.materialCode,
        'staticProductType': value.staticProductType,
        'imageUrl': value.imageUrl,
        'displayName': value.displayName,
        'plusCode': value.plusCode,
        'category': value.category,
        'compositionCode': value.compositionCode,
        'originalCompositionCode': value.originalCompositionCode,
        'originalPlusCode': value.originalPlusCode,
        'productCompositionCode': value.productCompositionCode,
        'isSoftwareProduct': value.isSoftwareProduct,
        'modificationCaseId': value.modificationCaseId,
        'categoryName': value.categoryName,
        'excludedModificationMaterials': value.excludedModificationMaterials,
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(ProductParameterToJSON)),
    };
}

