/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ArticleType {
    Topic = 'Topic',
    Document = 'Document',
    Book = 'Book'
}

export function ArticleTypeFromJSON(json: any): ArticleType {
    return ArticleTypeFromJSONTyped(json, false);
}

export function ArticleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleType {
    return json as ArticleType;
}

export function ArticleTypeToJSON(value?: ArticleType | null): any {
    return value as any;
}

