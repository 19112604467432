/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRightDto,
    AccessRightDtoFromJSON,
    AccessRightDtoFromJSONTyped,
    AccessRightDtoToJSON,
} from './AccessRightDto';
import {
    AccessRightRoleDto,
    AccessRightRoleDtoFromJSON,
    AccessRightRoleDtoFromJSONTyped,
    AccessRightRoleDtoToJSON,
} from './AccessRightRoleDto';

/**
 * 
 * @export
 * @interface AccessRightsDto
 */
export interface AccessRightsDto {
    /**
     * 
     * @type {AccessRightDto}
     * @memberof AccessRightsDto
     */
    accessRight: AccessRightDto;
    /**
     * 
     * @type {Array<AccessRightRoleDto>}
     * @memberof AccessRightsDto
     */
    roles: Array<AccessRightRoleDto>;
}

export function AccessRightsDtoFromJSON(json: any): AccessRightsDto {
    return AccessRightsDtoFromJSONTyped(json, false);
}

export function AccessRightsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRightsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessRight': AccessRightDtoFromJSON(json['accessRight']),
        'roles': ((json['roles'] as Array<any>).map(AccessRightRoleDtoFromJSON)),
    };
}

export function AccessRightsDtoToJSON(value?: AccessRightsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessRight': AccessRightDtoToJSON(value.accessRight),
        'roles': ((value.roles as Array<any>).map(AccessRightRoleDtoToJSON)),
    };
}

