import { ShoppingCartAttachmentTypes } from "api/models/ShoppingCartAttachmentTypes";
import { ShoppingCartForm } from "applications/shoppingCart/containers/ShoppingCartView";
import { ErrorMessage, FormikProps } from "formik";
import { Button } from "framework/components/Button";
import { Error } from "framework/components/Error";
import { TFunction } from "i18next";
import React from "react";
import styled, { useTheme } from "styled-components";
import { ContentContainer } from "./ContentContainer";
import { DetailsRadioGroup } from "./DetailsRadioGroup";
import { DropZone } from "../../../../framework/components/DropZone";
import { handleFormikValueChange } from "utilities/formikUtils";

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.sizes.m};
  min-height: 54px;
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const Label = styled.label`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  margin-top: ${(props) => props.theme.sizes.sm};
  margin-bottom: ${(props) => props.theme.sizes.s};
`;

interface ExportInfoProps {
  t: TFunction;
  formik: FormikProps<ShoppingCartForm>;
  onClick: () => void;
  uploadAttachment: (uploadedFile: Blob, attachmentType: ShoppingCartAttachmentTypes) => void;
  deleteAttachment: (attachmentIdentifier: string) => void;
  isReadOnly?: boolean;
}

export const ExportInfo = ({
  t,
  formik,
  onClick,
  uploadAttachment,
  deleteAttachment,
  isReadOnly
}: ExportInfoProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ContentContainer title={t("Export")}>
      {isReadOnly ? (
        <>
          {formik.values.hasCommercialInvoice ? (
            <>
              <Label>{t("Commercial invoice")}</Label>
              {formik.values.commercialInvoices.map((x) => (
                <Text key={x.identifier}>{x.name}</Text>
              ))}
            </>
          ) : (
            <>
              {formik.values.useProFormaOrCommercialInvoice ? (
                <>
                  <Label>{t("Pro forma")}</Label>
                  <Button
                    onClick={onClick}
                    text={t("View pro forma values")}
                    buttonType="secondary"
                    sizeClass="small"
                    style={{ marginTop: theme.sizes.m }}
                  />
                </>
              ) : (
                <p>{t("No pro forma or commercial invoice selected")}</p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <DetailsRadioGroup
            t={t}
            isRequired
            title={t("Is pro forma or commercial invoice required?")}
            value={formik.values.useProFormaOrCommercialInvoice?.toString()}
            setValue={(v) =>
              handleFormikValueChange(formik, "useProFormaOrCommercialInvoice", v === "true")
            }
          />
          {formik.values.useProFormaOrCommercialInvoice ? (
            <>
              <DetailsRadioGroup
                t={t}
                isRequired
                title={t("Upload commercial invoice or fill pro forma values")}
                value={formik.values.hasCommercialInvoice?.toString()}
                firstOptionLabel={t("Commercial invoice")}
                secondOptionLabel={t("Pro forma")}
                setValue={(v: any) =>
                  handleFormikValueChange(formik, "hasCommercialInvoice", v === "true", true)
                }
              />
              <ErrorMessage name={"hasCommercialInvoice"}>
                {(err) => <Error msg={err} />}
              </ErrorMessage>
              <Wrapper>
                {formik.values.hasCommercialInvoice === false ? (
                  <Button
                    onClick={onClick}
                    text={t("Add pro forma values")}
                    buttonType="secondary"
                    sizeClass="small"
                  />
                ) : null}
                {formik.values.hasCommercialInvoice === true ? (
                  <DropZone
                    t={t}
                    onDropSuccess={(file) =>
                      file.blob &&
                      uploadAttachment(file.blob, ShoppingCartAttachmentTypes.CommercialInvoice)
                    }
                    files={formik.values.commercialInvoices}
                    onClickDelete={(file) => deleteAttachment(file.identifier)}
                    acceptedFileTypes={["application/pdf"]}
                  />
                ) : null}
                <ErrorMessage name={"proFormaItems"}>
                  {() => <Error msg={t("Required")} />}
                </ErrorMessage>
                <ErrorMessage name={"commercialInvoices"}>
                  {() => <Error msg={t("At least 1 commercial invoice file required")} />}
                </ErrorMessage>
              </Wrapper>
            </>
          ) : null}
        </>
      )}
    </ContentContainer>
  );
};
