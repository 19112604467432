import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Input } from "@abb/abb-common-ux-react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "framework/components/Button";
import { useTranslation } from "react-i18next";
import { consentSagas } from "applications/common/sagas/consentSagas";
import { HorizontalElementContainer } from "framework/components/HorizontalElementContainer";
import { getPrivacyConsentRequest } from "applications/common/reducers/userReducer";
import { RequestStatus } from "framework/state/requestStatus";

const Form = styled.form`
  width: 100%;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const FormInput = styled(Input)<{ isFirst?: boolean }>`
  margin-top: ${(props) => (props.isFirst ? 0 : props.theme.sizes.sm)};
  max-width: 400px;
  width: 100%;
`;

const FormButton = styled(Button)`
  width: 80px;
  margin-left: auto;
  margin-top: ${(props) => props.theme.sizes.m};
`;

const errorMessages = {
  maxChar: "Maximum of 256 characters",
  required: "Required"
};

export const getUserInformationLabels = (t: (text: string) => string) => {
  return {
    firstName: t("First name"),
    lastName: t("Last name")
  };
};

const getUserInformationKeys = (t: (text: string) => string) =>
  Object.keys(getUserInformationLabels(t)) as (keyof typeof getUserInformationLabels)[];

export const getUserInformationShape = (t: (text: string) => string) =>
  getUserInformationKeys(t).map((prop) => {
    return {
      property: prop,
      label: getUserInformationLabels(t)[prop]
    };
  });

interface Props {
  onClose: (params: any) => any;
}

export const UserNameForm = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const privacyConsentRequestStatus = useSelector(getPrivacyConsentRequest);
  const { t } = useTranslation();

  const getValidationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .max(256, errorMessages.maxChar)
        .required(getUserInformationLabels(t).firstName + " " + t("is required")),
      lastName: Yup.string()
        .max(256, errorMessages.maxChar)
        .required(getUserInformationLabels(t).lastName + " " + t("is required"))
    });
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: ""
    },
    onSubmit: (values) => {
      dispatch(
        consentSagas.privacyConsent.createAction({
          handlePrivacyConsentCommand: {
            consentGiven: true,
            givenName: values.firstName,
            surname: values.lastName
          }
        })
      );
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: getValidationSchema()
  });

  const { values, errors, touched, handleChange, handleBlur, submitForm, isValid, dirty } = formik;

  return (
    <Form>
      {getUserInformationShape(t).map((item, i) => {
        return (
          <FormInput
            isFirst={i === 0}
            key={item.property}
            dataType="text"
            label={item.label}
            value={values[item.property]}
            validationResult={{
              valid: errors[item.property] && touched[item.property] ? false : true,
              text: errors[item.property]
            }}
            instantValidation={true}
            showValidationIconWhenInvalid={true}
            onLostFocus={handleBlur}
            showValidationBarWhenInvalid={true}
            inputAttributes={{
              name: item.property,
              onChange: handleChange
            }}
          />
        );
      })}
      <HorizontalElementContainer>
        <FormButton
          text={t("Decline")}
          sizeClass="medium"
          buttonType="secondary"
          onClick={onClose}
          isLoading={false}
        />
        <FormButton
          text={t("Accept")}
          buttonType="primary"
          onClick={() => submitForm()}
          isLoading={privacyConsentRequestStatus.status === RequestStatus.Pending}
          disabled={!dirty || !isValid}
        />
      </HorizontalElementContainer>
    </Form>
  );
};
