/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MatrixItemDto
 */
export interface MatrixItemDto {
    /**
     * 
     * @type {string}
     * @memberof MatrixItemDto
     */
    displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof MatrixItemDto
     */
    isIec: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MatrixItemDto
     */
    isAnsi: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatrixItemDto
     */
    materialCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof MatrixItemDto
     */
    isLatestVersion?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MatrixItemDto
     */
    internalVisibility: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MatrixItemDto
     */
    publicVisibility?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MatrixItemDto
     */
    productGroupingCriteriaValues: Array<number>;
}

export function MatrixItemDtoFromJSON(json: any): MatrixItemDto {
    return MatrixItemDtoFromJSONTyped(json, false);
}

export function MatrixItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatrixItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'isIec': json['isIec'],
        'isAnsi': json['isAnsi'],
        'materialCode': json['materialCode'],
        'isLatestVersion': !exists(json, 'isLatestVersion') ? undefined : json['isLatestVersion'],
        'internalVisibility': json['internalVisibility'],
        'publicVisibility': !exists(json, 'publicVisibility') ? undefined : json['publicVisibility'],
        'productGroupingCriteriaValues': json['productGroupingCriteriaValues'],
    };
}

export function MatrixItemDtoToJSON(value?: MatrixItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'isIec': value.isIec,
        'isAnsi': value.isAnsi,
        'materialCode': value.materialCode,
        'isLatestVersion': value.isLatestVersion,
        'internalVisibility': value.internalVisibility,
        'publicVisibility': value.publicVisibility,
        'productGroupingCriteriaValues': value.productGroupingCriteriaValues,
    };
}

