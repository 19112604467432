import { Icon } from "@abb/abb-common-ux-react";
import React from "react";
import styled from "styled-components";
import { commonUXTheme } from "../../styles/commonUXVariables";
import { Button } from "./Button";
import { SectionHeader } from "./styled/SectionHeader";

const Container = styled.div<{ color: string; shape: Shape }>`
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.shape === "long" ? "row" : "column")};
  align-items: ${(props) => (props.shape === "long" ? "center" : "flex-start")};
  justify-content: flex-start;
  padding-inline: ${(props) => props.theme.sizes.lm};
  padding-block: ${(props) => (props.shape === "long" ? 0 : props.theme.sizes.lm)};
  width: 100%;
  background-color: ${(props) => props.color};
  padding-block: ${(props) => props.theme.sizes.l};
  gap: ${(props) => (props.shape === "long" ? props.theme.sizes.lm : props.theme.sizes.m)};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  width: auto;
  gap: ${(props) => props.theme.sizes.m};
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  word-break: break-word;
  white-space: pre-wrap;
  max-height: 10lh;
  overflow-y: auto;
`;
const SubHeaderText = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  word-break: break-word;
  white-space: pre-wrap;
  max-height: 10lh;
  overflow-y: auto;
  margin-bottom: ${(props) => props.theme.sizes.s};
  margin-top: ${(props) => props.theme.sizes.xs};
`;

const CustomIcon = styled(Icon)<{ shape: Shape }>`
  i {
    font-size: ${(props) => (props.shape === "long" ? "50" : "38")}px !important;
    width: ${(props) => (props.shape === "long" ? "50" : "38")}px !important;
    height: ${(props) => (props.shape === "long" ? "50" : "38")}px !important;
  }
`;

const CustomHeader = styled(SectionHeader)`
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;

type Status = "success" | "error" | "information" | "warning";

type Shape = "long" | "box";

interface StatusBannerProps {
  text?: React.ReactNode;
  icon?: string;
  headerText?: string;
  subHeaderText?: string;
  headerComponent?: React.ReactNode;
  status: Status;
  shape?: Shape;
  onClick?: () => void;
  buttonText?: string;
  buttonIsLoading?: boolean;
  buttonDisabled?: boolean;
}

const handleColorType = (state: Status) => {
  switch (state) {
    case "success":
      return commonUXTheme.colors.green20;
    case "error":
      return commonUXTheme.colors.red20;
    case "information":
      return commonUXTheme.colors.blue20;
    case "warning":
      return commonUXTheme.colors.yellow20;
  }
};

export const StatusBanner = ({
  text,
  icon,
  status,
  headerText,
  subHeaderText,
  headerComponent,
  shape = "long",
  onClick,
  buttonText,
  buttonIsLoading,
  buttonDisabled
}: StatusBannerProps): JSX.Element | null => (
  <Container color={handleColorType(status)} shape={shape}>
    {icon ? <CustomIcon name={icon} shape={shape} /> : null}
    {shape === "box" && (
      <>
        {headerText ? (
          <CustomHeader noBorderMargin text={headerText} />
        ) : headerComponent ? (
          headerComponent
        ) : null}
        {subHeaderText ? <SubHeaderText>{subHeaderText}</SubHeaderText> : null}
        {text ? <Text>{text}</Text> : null}
      </>
    )}

    {shape === "long" && (
      <div style={{ width: "100%" }}>
        {headerText && <CustomHeader noBorderMargin text={headerText} />}
        {subHeaderText ? <SubHeaderText>{subHeaderText}</SubHeaderText> : null}
        {text && <Text>{text}</Text>}
      </div>
    )}

    {onClick && (
      <ButtonContainer>
        <Button
          buttonType="primary-black"
          onClick={() => onClick()}
          text={buttonText}
          disabled={buttonDisabled}
          isLoading = {buttonIsLoading}
        />
      </ButtonContainer>
    )}
  </Container>
);
