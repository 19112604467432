import {
  createGenericSaga,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const productExplorerSagas = {
  getApplications: createGenericSaga(
    "productExplorerSagas/getApplications",
    getApiRegistry().productExplorerApi.apiProductExplorerApplicationsGet.bind(
      getApiRegistry().productExplorerApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getMenuFeature: createGenericSaga(
    "productExplorerSagas/getMenuFeature",
    getApiRegistry().productExplorerApi.apiProductExplorerMenuFeatureGet.bind(
      getApiRegistry().productExplorerApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getProductSeries: createGenericSaga(
    "productExplorerSagas/getProductSeries",
    getApiRegistry().productExplorerApi.apiProductExplorerSeriesGet.bind(
      getApiRegistry().productExplorerApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getProducts: createGenericSaga(
    "productExplorerSagas/getProducts",
    getApiRegistry().productExplorerApi.apiProductExplorerProductsGet.bind(
      getApiRegistry().productExplorerApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSearchFeatures: createGenericSaga(
    "productExplorerSagas/getSearchFeatures",
    getApiRegistry().productExplorerApi.apiProductExplorerSearchFeaturesGet.bind(
      getApiRegistry().productExplorerApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
