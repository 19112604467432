/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderDownloadNotificationDto
 */
export interface OrderDownloadNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    orderAcknowledgementNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    customerSoftwareUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    contactPersonEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDownloadNotificationDto
     */
    correspondingOrderLineStatusValidForEmailSending?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    orderLineStatus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDownloadNotificationDto
     */
    emailSent?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDownloadNotificationDto
     */
    queuedForEmailSending?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDownloadNotificationDto
     */
    orderLinkFormed?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDownloadNotificationDto
     */
    sentTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDownloadNotificationDto
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDownloadNotificationDto
     */
    sendEmailExpired?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDownloadNotificationDto
     */
    hangFireJobFailed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderDownloadNotificationDto
     */
    exceptionMessage?: string | null;
}

export function OrderDownloadNotificationDtoFromJSON(json: any): OrderDownloadNotificationDto {
    return OrderDownloadNotificationDtoFromJSONTyped(json, false);
}

export function OrderDownloadNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDownloadNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'orderAcknowledgementNumber': !exists(json, 'orderAcknowledgementNumber') ? undefined : json['orderAcknowledgementNumber'],
        'customerSoftwareUserName': !exists(json, 'customerSoftwareUserName') ? undefined : json['customerSoftwareUserName'],
        'contactPersonEmail': !exists(json, 'contactPersonEmail') ? undefined : json['contactPersonEmail'],
        'correspondingOrderLineStatusValidForEmailSending': !exists(json, 'correspondingOrderLineStatusValidForEmailSending') ? undefined : json['correspondingOrderLineStatusValidForEmailSending'],
        'orderLineStatus': !exists(json, 'orderLineStatus') ? undefined : json['orderLineStatus'],
        'emailSent': !exists(json, 'emailSent') ? undefined : (json['emailSent'] === null ? null : new Date(json['emailSent'])),
        'queuedForEmailSending': !exists(json, 'queuedForEmailSending') ? undefined : (json['queuedForEmailSending'] === null ? null : new Date(json['queuedForEmailSending'])),
        'orderLinkFormed': !exists(json, 'orderLinkFormed') ? undefined : (json['orderLinkFormed'] === null ? null : new Date(json['orderLinkFormed'])),
        'sentTime': !exists(json, 'sentTime') ? undefined : (json['sentTime'] === null ? null : new Date(json['sentTime'])),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'sendEmailExpired': !exists(json, 'sendEmailExpired') ? undefined : json['sendEmailExpired'],
        'hangFireJobFailed': !exists(json, 'hangFireJobFailed') ? undefined : json['hangFireJobFailed'],
        'exceptionMessage': !exists(json, 'exceptionMessage') ? undefined : json['exceptionMessage'],
    };
}

export function OrderDownloadNotificationDtoToJSON(value?: OrderDownloadNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'customerSoftwareUserName': value.customerSoftwareUserName,
        'contactPersonEmail': value.contactPersonEmail,
        'correspondingOrderLineStatusValidForEmailSending': value.correspondingOrderLineStatusValidForEmailSending,
        'orderLineStatus': value.orderLineStatus,
        'emailSent': value.emailSent === undefined ? undefined : (value.emailSent === null ? null : value.emailSent.toISOString()),
        'queuedForEmailSending': value.queuedForEmailSending === undefined ? undefined : (value.queuedForEmailSending === null ? null : value.queuedForEmailSending.toISOString()),
        'orderLinkFormed': value.orderLinkFormed === undefined ? undefined : (value.orderLinkFormed === null ? null : value.orderLinkFormed.toISOString()),
        'sentTime': value.sentTime === undefined ? undefined : (value.sentTime === null ? null : value.sentTime.toISOString()),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'sendEmailExpired': value.sendEmailExpired,
        'hangFireJobFailed': value.hangFireJobFailed,
        'exceptionMessage': value.exceptionMessage,
    };
}

