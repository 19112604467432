import React, { lazy } from "react";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { appStore } from "./setup/appStore";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "@abb/abb-common-ux-react/styles.css";
import { AppAuthWrapper } from "./framework/components/auth/AppAuthWrapper";
import { routes } from "../src/utilities/routes";
import AuthorizedComponent, {
  AuthorizedComponentProps
} from "./framework/components/AuthorizedComponent";
import "./i18n";
import styled from "styled-components";
import { AccessRights } from "api/models/AccessRights";
import { LoadingIndicator } from "@abb/abb-common-ux-react/components/LoadingIndicator";
import SelectorView from "applications/products/productSelector/SelectorView";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createRoot } from "react-dom/client";
import { SubscriptionsView } from "applications/deliveries/productInformation/containers/SubscriptionsView";
import { LifecycleManagementView } from "applications/deliveries/lifecycleManagement/containers/LifecycleManagementView";
import ManageTeamView from "applications/manage/manageTeams/containers/ManageTeamView";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const LoadingIndicatorContainer = styled.div`
  margin: auto;
`;

const DashboardView = lazy(() => import("applications/dashboard/containers/DashboardView"));
const DeliveriesHomeView = lazy(
  () => import("applications/deliveries/deliveriesHome/containers/DeliveriesHomeView")
);
const OrdersView = lazy(() => import("applications/deliveries/orders/containers/OrdersView"));
const ProductInformationView = lazy(
  () => import("applications/deliveries/productInformation/containers/ProductInformationView")
);
const FirmwareDownloadView = lazy(
  () => import("applications/deliveries/FirmwareDownload/containers/FirmwareDownloadView")
);
const DeliveryTimesView = lazy(
  () => import("applications/deliveries/deliveryTimes/containers/DeliveryTimesView")
);
const RequestAccessView = lazy(
  () => import("applications/accessRequests/containers/RequestAccessView")
);
const ManageAccessRequestsView = lazy(
  () => import("applications/manage/manageAccessRequests/containers/ManageAccessRequestsView")
);
const OrderDetailsView = lazy(
  () => import("applications/deliveries/orders/containers/OrderDetailsView")
);
const UserAccountView = lazy(() => import("applications/userAccount/containers/UserAccountView"));
const ManageUserView = lazy(
  () => import("applications/manage/manageUsers/containers/ManageUserView")
);
const ManageUsersView = lazy(
  () => import("applications/manage/manageUsers/containers/ManageUsersView")
);
const ManageCompaniesView = lazy(
  () => import("applications/manage/manageCompanies/containers/ManageCompaniesView")
);
const ManageCompanyView = lazy(
  () => import("applications/manage/manageCompanies/containers/ManageCompanyView")
);
const ProductSelectionMatrixView = lazy(
  () => import("applications/products/productSelectionMatrix/components/ProductSelectionMatrixView")
);
const ProductExplorerView = lazy(
  () => import("applications/products/productExplorer/containers/ProductExplorerView")
);
const ProductComparisonView = lazy(
  () => import("applications/products/productComparison/containers/productComparisonView")
);
const OrderNotificationsView = lazy(
  () => import("applications/deliveries/notifications/containers/NotificationsView")
);

const OrderDownloadNotificationsView = lazy(
  () =>
    import(
      "applications/deliveries/orderDownloadNotifications/containers/OrderDownloadNotificationsView"
    )
);

const StatisticsView = lazy(
  () => import("applications/deliveries/statistics/containers/StatisticsView")
);
const ShoppingCartView = lazy(
  () => import("applications/shoppingCart/containers/ShoppingCartView")
);
const ShoppingCartsView = lazy(
  () => import("applications/shoppingCarts/containers/ShoppingCartsView")
);
const ApplicationNotificationDetailsView = lazy(
  () =>
    import("applications/applicationNotifications/containers/ApplicationNotificationDetailsView")
);
const ApplicationNotificationsView = lazy(
  () => import("applications/applicationNotifications/containers/ApplicationNotificationsView")
);
const QuickPriceCheckView = lazy(
  () => import("applications/quickPriceCheck/containers/QuickPriceCheckView")
);
const PriceListView = lazy(
  () => import("applications/manage/managePriceList/containers/PriceListView")
);
const TranslationsView = lazy(
  () => import("applications/manage/manageTranslations/containers/TranslationsView")
);
const ManageQuotesView = lazy(
  () => import("applications/manage/manageQuotes/containers/ManageQuotesView")
);
const EmailTemplatesView = lazy(
  () => import("applications/manage/manageEmailTemplates/containers/EmailTemplatesView")
);

const QuoteView = lazy(() => import("applications/manage/manageQuotes/containers/QuoteView"));

const OrderLicensesView = lazy(
  () => import("applications/deliveries/productInformation/containers/OrderLicensesView")
);
const TrainingManagementView = lazy(
  () => import("applications/manage/training/containers/TrainingManagementView")
);
const KnowledgeWarehouseView = lazy(
  () => import("applications/knowledgeWarehouse/containers/KnowledgeWarehouseView")
);
const TrainingUserView = lazy(() => import("applications/training/containers/TrainingUserView"));
const TermsConditionsView = lazy(
  () => import("applications/manage/manageTermsConditions/containers/termsConditionsView")
);
const ManageReleaseNotesView = lazy(
  () => import("applications/manage/manageReleaseNotes/containers/ManageReleaseNotes")
);
const ManageGlobalSettingsView = lazy(
  () => import("applications/manage/globalSettings/containers/ManageGlobalSettingsView")
);
const SupportHomeView = lazy(() => import("applications/support/home/containers/SupportHomeView"));
const TechSupportView = lazy(
  () => import("applications/support/techSupport/containers/TechSupportView")
);
const SalesSupportView = lazy(
  () => import("applications/support/salesSupport/containers/SalesSupportView")
);
const TrainingSupportView = lazy(
  () => import("applications/support/trainingSupport/containers/TrainingSupportView")
);
const PlatformSupportView = lazy(
  () => import("applications/support/platformSupport/containers/PlatformSupportView")
);
const TerminologyBankView = lazy(
  () => import("applications/terminologyBank/containers/TerminologyBankView")
);
const TerminologyBankManagementView = lazy(
  () => import("applications/manage/terminologyBank/containers/TerminologyBankManagementView")
);

const ManageTeamsView = lazy(
  () => import("applications/manage/manageTeams/containers/ManageTeamsView")
);
const MyKnowledgeAiView = lazy(
  () => import("applications/myKnowledgeAi/containers/MyKnowledgeAiView")
);

const SuspensedView = (props: AuthorizedComponentProps) => (
  <React.Suspense
    fallback={
      <LoadingIndicatorContainer>
        <LoadingIndicator type="radial" determinate={false} sizeClass="large" />
      </LoadingIndicatorContainer>
    }
  >
    <AuthorizedComponent {...props} />
  </React.Suspense>
);

root.render(
  <Provider store={appStore}>
    <React.StrictMode>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <AppAuthWrapper>
          <Routes>
            {/* https://github.com/remix-run/react-router/discussions/8753 */}
            <Route
              path={routes.index}
              element={
                <SuspensedView accessRights={[]}>
                  <DashboardView key={routes.index} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.registrationCompleted}
              element={
                <SuspensedView accessRights={[]}>
                  <DashboardView registrationCompleted key={routes.registrationCompleted} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.user.requestAccess}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <RequestAccessView key={routes.user.requestAccess} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.orders.requestAccess}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <RequestAccessView showOrderTracking key={routes.orders.requestAccess} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.home}
              element={
                <SuspensedView accessRights={[]}>
                  <DeliveriesHomeView key={routes.orders.home} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.deliveryTimes}
              element={
                <SuspensedView accessRights={[AccessRights.ViewDeliveryTimes]}>
                  <DeliveryTimesView key={routes.orders.deliveryTimes} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.subscriptions}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ViewCompanyLicenses,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.IsSoftwareSubscriptionHolder
                  ]}
                >
                  <SubscriptionsView key={routes.orders.subscriptions} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.orders.ordersBacklog}
              element={
                <SuspensedView accessRights={[]}>
                  <OrdersView isBacklog={true} key={routes.orders.ordersBacklog} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.orderIdentifier}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <OrderDetailsView key={routes.orders.orderIdentifier} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.orders.index}
              element={
                <SuspensedView accessRights={[]}>
                  <OrdersView isBacklog={false} key={routes.orders.index} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.productInformation.index}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={true}
                    isSoftwareSearch={true}
                    key={routes.orders.productInformation.index}
                  />
                </SuspensedView>
              }
            />

            <Route
              path={routes.orders.lifecycleManagement}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <LifecycleManagementView />
                </SuspensedView>
              }
            />

            <Route
              path={routes.downloads.firmwareUpdates}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <FirmwareDownloadView key={routes.downloads.firmwareUpdates} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.downloads.index}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={true}
                    isSoftwareSearch={true}
                    key={routes.downloads.index}
                  />
                </SuspensedView>
              }
            />
            <Route
              path={routes.downloads.indexSerialNumber}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={true}
                    isSoftwareSearch={true}
                    key={routes.downloads.indexSerialNumber}
                  />
                </SuspensedView>
              }
            />
            <Route
              path={routes.downloads.documents}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={true}
                    isSoftwareSearch={false}
                    key={routes.downloads.documents}
                  />
                </SuspensedView>
              }
            />
            <Route
              path={routes.downloads.software}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={false}
                    isSoftwareSearch={true}
                    key={routes.downloads.software}
                  />
                </SuspensedView>
              }
            />
            <Route
              path={routes.downloads.productSerialNumber}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.DownloadRoutineTestReports,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <ProductInformationView
                    isDocumentSearch={true}
                    isSoftwareSearch={false}
                    key={routes.downloads.productSerialNumber}
                  />
                </SuspensedView>
              }
            />

            <Route
              path={routes.downloads.licenses}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ViewAllSoftwareLicenses,
                    AccessRights.ViewSoftwareLicense,
                    AccessRights.ViewCompanyLicenses
                  ]}
                >
                  <OrderLicensesView key={routes.downloads.licenses} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.user.information}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <UserAccountView key={routes.user.information} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.companies}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesCompanies,
                    AccessRights.CompanyAdmin,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ViewDeliveriesUsers,
                    AccessRights.ViewConfiguratorUsers
                  ]}
                >
                  <ManageCompaniesView key={routes.manage.companies} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.company}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesCompanies,
                    AccessRights.CompanyAdmin,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ViewDeliveriesUsers,
                    AccessRights.ViewConfiguratorUsers
                  ]}
                >
                  <ManageCompanyView key={routes.manage.company} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.users}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesUsers,
                    AccessRights.ManageDeliveriesUsersLimited,
                    AccessRights.CompanyAdmin,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ViewDeliveriesUsers,
                    AccessRights.ViewConfiguratorUsers
                  ]}
                >
                  <ManageUsersView key={routes.manage.users} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.usersEdit}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesUsers,
                    AccessRights.ManageDeliveriesUsersLimited,
                    AccessRights.CompanyAdmin,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ChangeOwnRoles,
                    AccessRights.ViewDeliveriesUsers,
                    AccessRights.ViewConfiguratorUsers
                  ]}
                >
                  <ManageUserView key={routes.manage.usersEdit} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.teams}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageTeamMembers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.SystemAdmin
                  ]}
                >
                  <ManageTeamsView key={routes.manage.teams} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.team}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageTeamMembers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.SystemAdmin
                  ]}
                >
                  <ManageTeamView key={routes.manage.team} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.statistics}
              // TODO Validate
              element={
                <SuspensedView accessRights={[AccessRights.ViewStatistics]}>
                  <StatisticsView key={routes.manage.statistics} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.accessRequestsEmailFilter}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesUsers,
                    AccessRights.ManageDeliveriesUsersLimited,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ProcessConfiguratorAccessRequests
                  ]}
                >
                  <ManageAccessRequestsView key={routes.manage.accessRequestsEmailFilter} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.training.managementView}
              element={
                <SuspensedView accessRights={[AccessRights.ManageTrainingPages]}>
                  <TrainingManagementView key={routes.training.managementView} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.training.knowledgeWarehouseView}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <KnowledgeWarehouseView key={routes.training.knowledgeWarehouseView} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.training.trainingView}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <TrainingUserView key={routes.training.trainingView} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.manage.accessRequests}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageDeliveriesUsers,
                    AccessRights.ManageDeliveriesUsersLimited,
                    AccessRights.ManageConfiguratorUsers,
                    AccessRights.ManagePlatformUsers,
                    AccessRights.ProcessConfiguratorAccessRequests
                  ]}
                >
                  <ManageAccessRequestsView key={routes.manage.accessRequests} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.orderNotifications}
              element={
                <SuspensedView accessRights={[AccessRights.ViewOrderNotifications]}>
                  <OrderNotificationsView key={routes.manage.orderNotifications} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.orderNotifications}
              element={
                <SuspensedView accessRights={[AccessRights.ViewOrderNotifications]}>
                  <OrderNotificationsView key={routes.manage.orderNotifications} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.orderDownloadNotifications}
              element={
                <SuspensedView accessRights={[AccessRights.ViewOrderNotifications]}>
                  <OrderDownloadNotificationsView key={routes.manage.orderDownloadNotifications} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.products.productSelector}
              element={
                <SuspensedView accessRights={[]}>
                  <SelectorView key={routes.products.productSelector} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.products.productSelectionMatrix}
              element={
                <SuspensedView accessRights={[]}>
                  <ProductSelectionMatrixView key={routes.products.productSelectionMatrix} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.translations}
              element={
                <SuspensedView accessRights={[AccessRights.SystemAdmin]}>
                  <TranslationsView key={routes.manage.translations} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.quickPriceCheck}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <QuickPriceCheckView key={routes.quickPriceCheck} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.priceLists}
              element={
                <SuspensedView
                  accessRights={[AccessRights.ManagePriceLists, AccessRights.ViewManagePriceLists]}
                >
                  <PriceListView key={routes.manage.priceLists} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.termsConditions}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ManageCompanyTermsAndConditions,
                    AccessRights.ManageCountryTermsAndConditions,
                    AccessRights.ManageSupplierTermsAndConditions
                  ]}
                >
                  <TermsConditionsView key={routes.manage.termsConditions} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.globalSettings}
              element={
                <SuspensedView accessRights={[AccessRights.SystemAdmin]}>
                  <ManageGlobalSettingsView key={routes.manage.globalSettings} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.terminologyBank}
              element={
                <SuspensedView accessRights={[AccessRights.ManageTerminologyBank]}>
                  <TerminologyBankManagementView key={routes.manage.terminologyBank} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.user.notifications}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <ApplicationNotificationsView key={routes.user.notifications} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.user.notificationDetails}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <ApplicationNotificationDetailsView key={routes.user.notificationDetails} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.shoppingCart}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <ShoppingCartView key={routes.shoppingCart} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.shoppingCartByGuid}
              element={
                <SuspensedView accessRights={[]}>
                  <ShoppingCartView key={routes.shoppingCartByGuid} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.shoppingCarts}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <ShoppingCartsView key={routes.shoppingCarts} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.quotes}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ViewAllPriceLists,
                    AccessRights.ApproveQuotes,
                    AccessRights.RequestedQuoteApprover,
                    AccessRights.LocalQuoteHandler,
                    AccessRights.LocalContactRegionalSales,
                    AccessRights.Pms,
                    AccessRights.ViewCartsAndQuotes
                  ]}
                >
                  <ManageQuotesView key={routes.manage.quotes} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.ownQuotes}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ViewAllPriceLists,
                    AccessRights.ApproveQuotes,
                    AccessRights.RequestedQuoteApprover,
                    AccessRights.LocalQuoteHandler,
                    AccessRights.LocalContactRegionalSales,
                    AccessRights.Pms,
                    AccessRights.ViewCartsAndQuotes
                  ]}
                >
                  <ManageQuotesView getOnlyOwnQuotes key={routes.manage.ownQuotes} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.quote}
              element={
                <SuspensedView accessRights={[]}>
                  <QuoteView key={routes.manage.quote} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.quoteById}
              element={
                <SuspensedView accessRights={[]}>
                  <QuoteView key={routes.manage.quoteById} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.emailTemplates}
              element={
                <SuspensedView
                  accessRights={[
                    AccessRights.ViewEmailTemplates,
                    AccessRights.ManageEmailTemplates
                  ]}
                >
                  <EmailTemplatesView key={routes.manage.emailTemplates} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.manage.releaseNotes}
              element={
                <SuspensedView accessRights={[AccessRights.ManageReleaseNotes]}>
                  <ManageReleaseNotesView key={routes.manage.releaseNotes} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.products.productExplorer}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <ProductExplorerView key={routes.products.productExplorer} />
                </SuspensedView>
              }
            />
            <Route
              path={routes.products.productComparator}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <ProductComparisonView key={routes.products.productComparator} />
                </SuspensedView>
              }
            />

            <Route
              path={routes.support.home}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <SupportHomeView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.techSupport}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <TechSupportView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.salesSupport}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <SalesSupportView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.trainingSupport}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <TrainingSupportView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.platformSupport}
              element={
                <SuspensedView accessRights={[AccessRights.UserAccount]}>
                  <PlatformSupportView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.terminologyBank}
              element={
                <SuspensedView accessRights={[]} showRegistrationBanner>
                  <TerminologyBankView />
                </SuspensedView>
              }
            />
            <Route
              path={routes.support.myKnowledgeAi}
              element={
                <SuspensedView accessRights={[AccessRights.ViewMyKnowledgeAi]}>
                  <MyKnowledgeAiView />
                </SuspensedView>
              }
            />
          </Routes>
        </AppAuthWrapper>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
