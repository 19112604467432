/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleAttachmentDto,
    ArticleAttachmentDtoFromJSON,
    ArticleAttachmentDtoFromJSONTyped,
    ArticleAttachmentDtoToJSON,
} from './ArticleAttachmentDto';
import {
    StringBasicArticleDto,
    StringBasicArticleDtoFromJSON,
    StringBasicArticleDtoFromJSONTyped,
    StringBasicArticleDtoToJSON,
} from './StringBasicArticleDto';

/**
 * 
 * @export
 * @interface ArticleDtoFormatted
 */
export interface ArticleDtoFormatted {
    /**
     * 
     * @type {number}
     * @memberof ArticleDtoFormatted
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleDtoFormatted
     */
    isTitleVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    nameHTML?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    textHTML?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    summary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleDtoFormatted
     */
    text?: string | null;
    /**
     * 
     * @type {Array<ArticleAttachmentDto>}
     * @memberof ArticleDtoFormatted
     */
    attachments?: Array<ArticleAttachmentDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArticleDtoFormatted
     */
    category?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDtoFormatted
     */
    updatedTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ArticleDtoFormatted
     */
    trainingOrder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleDtoFormatted
     */
    terminologyBankOrder?: number | null;
    /**
     * 
     * @type {StringBasicArticleDto}
     * @memberof ArticleDtoFormatted
     */
    formatted?: StringBasicArticleDto;
}

export function ArticleDtoFormattedFromJSON(json: any): ArticleDtoFormatted {
    return ArticleDtoFormattedFromJSONTyped(json, false);
}

export function ArticleDtoFormattedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleDtoFormatted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isTitleVisible': !exists(json, 'isTitleVisible') ? undefined : json['isTitleVisible'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'nameHTML': !exists(json, 'nameHTML') ? undefined : json['nameHTML'],
        'textHTML': !exists(json, 'textHTML') ? undefined : json['textHTML'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(ArticleAttachmentDtoFromJSON)),
        'category': !exists(json, 'category') ? undefined : json['category'],
        'updatedTime': !exists(json, 'updatedTime') ? undefined : (new Date(json['updatedTime'])),
        'trainingOrder': !exists(json, 'trainingOrder') ? undefined : json['trainingOrder'],
        'terminologyBankOrder': !exists(json, 'terminologyBankOrder') ? undefined : json['terminologyBankOrder'],
        'formatted': !exists(json, '_Formatted') ? undefined : StringBasicArticleDtoFromJSON(json['_Formatted']),
    };
}

export function ArticleDtoFormattedToJSON(value?: ArticleDtoFormatted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'title': value.title,
        'isTitleVisible': value.isTitleVisible,
        'keywords': value.keywords,
        'nameHTML': value.nameHTML,
        'textHTML': value.textHTML,
        'summary': value.summary,
        'text': value.text,
        'attachments': value.attachments === undefined ? undefined : (value.attachments === null ? null : (value.attachments as Array<any>).map(ArticleAttachmentDtoToJSON)),
        'category': value.category,
        'updatedTime': value.updatedTime === undefined ? undefined : (value.updatedTime.toISOString()),
        'trainingOrder': value.trainingOrder,
        'terminologyBankOrder': value.terminologyBankOrder,
        '_Formatted': StringBasicArticleDtoToJSON(value.formatted),
    };
}

