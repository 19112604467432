/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Fraction
 */
export interface Fraction {
    /**
     * 
     * @type {number}
     * @memberof Fraction
     */
    numerator: number;
    /**
     * 
     * @type {number}
     * @memberof Fraction
     */
    denominator: number;
}

export function FractionFromJSON(json: any): Fraction {
    return FractionFromJSONTyped(json, false);
}

export function FractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fraction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numerator': json['numerator'],
        'denominator': json['denominator'],
    };
}

export function FractionToJSON(value?: Fraction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numerator': value.numerator,
        'denominator': value.denominator,
    };
}

