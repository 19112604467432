import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SmallText } from "./common/Containers";
import { Heading, Icon } from "@abb/abb-common-ux-react";
import { SelectorApplicationFeatureDto, SelectorProductDto } from "api";
import ProductLinks from "./common/ProductLinks";
import { Stack } from "@mui/system";
import { Popover } from "@mui/material";

const ProductImage = styled.img({
  margin: "auto",
  width: "60%"
});

export const ToolBarIcon = styled(Icon)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: theme.sizes.m
}));

export const FeatureList = styled.ul(({ theme }) => ({
  listStyle: "inside",
  marginLeft: theme.sizes.m,
  fontSize: theme.fonts.sizes.fontSizeS,
  li: {
    marginTop: theme.sizes.xs,
    marginBottom: theme.sizes.xs
  }
}));

interface RecommendedStepProps {
  product?: SelectorProductDto;
  additionalFeatures?: SelectorApplicationFeatureDto[];
}

const RecommendedStep = ({ product, additionalFeatures }: RecommendedStepProps): JSX.Element => {
  const { t } = useTranslation();

  const selectedAvailableFeatures = useMemo(() => {
    const selectedFeatures = additionalFeatures?.filter((f) => f.isSelected).map((f) => f.id) ?? [];
    return product?.selectorApplicationFeatureProductValues
      ?.filter((fv) => selectedFeatures?.indexOf(fv.selectorApplicationFeatureId) >= 0)
      .map((fv) => fv.selectorApplicationFeature);
  }, [product, additionalFeatures]);

  const print = () => {
    window.print();
  };

  const shareLink = (e: React.MouseEvent<HTMLElement>) => {
    setShareBtnElement(e.currentTarget);
    navigator.clipboard.writeText(window.location.href);
  };

  const handleCopiedPopoverClose = () => {
    setShareBtnElement(null);
  };

  const [shareBtnElement, setShareBtnElement] = React.useState<HTMLElement | null>(null);
  const isCopiedPopoverOpen = !!shareBtnElement;

  return (
    <Box width={"100%"}>
      <Grid container mt={commonUXTheme.sizes.l} gap={1}>
        <Grid item container textAlign={"center"} justifyContent={"center"} md={6}>
          <Grid item xs={12} padding={{ xs: 0, md: commonUXTheme.sizes.xxl }}>
            <ProductImage src={product?.presentationImageUrl ?? ""} />
          </Grid>
        </Grid>
        <Grid container gap={1} item md>
          <Grid item xs={12}>
            <Heading
              text={t("Based on your selections, we recommend:")}
              showCursor={false}
              level={5}
            ></Heading>
            <Grid mt={commonUXTheme.sizes.s} container>
              <Grid item>
                <Heading level={4} showCursor={false} text={product?.name ?? ""}></Heading>
              </Grid>
              <Grid item xs direction={"row-reverse"} container alignItems={"center"}>
                <ToolBarIcon onClick={shareLink} name="abb/share" />
                <ToolBarIcon onClick={print} name="abb/print" />
                <Popover
                  onClose={handleCopiedPopoverClose}
                  open={isCopiedPopoverOpen}
                  anchorEl={shareBtnElement}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                >
                  <Stack p={commonUXTheme.sizes.xs}> {t("Copied to clipboard!")}</Stack>
                </Popover>
              </Grid>
            </Grid>
            <Grid>{product?.shortText}</Grid>
            <SmallText> {product?.longText}</SmallText>
          </Grid>
          <Grid item xs>
            <FeatureList>
              {product?.selectorProductHighlights
                ?.filter(
                  (h) => h.selectorSubCategory?.name && h.selectorSubCategory?.name.length > 2
                )
                .map((h) => <li key={h.id}>{h.selectorSubCategory?.name}</li>)}
            </FeatureList>
          </Grid>

          <Grid container></Grid>
          {!!selectedAvailableFeatures?.length && (
            <Grid container>
              <Grid item xs={12}>
                {t("Your additional feature selections")}
              </Grid>
              <FeatureList>
                {selectedAvailableFeatures?.map((h) => <li key={h?.id}> {h?.name}</li>)}
              </FeatureList>
            </Grid>
          )}

          <Grid alignSelf={"end"} item xs>
            <ProductLinks product={product}></ProductLinks>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecommendedStep;
