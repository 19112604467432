import {
  ApiTrainingNodesFlatGetRequest,
  ApiTrainingNodesRootsGetRequest,
  ApiTrainingSearchArticlePostRequest,
  ArticleDto,
  ArticleDtoFormatted,
  NodeDto,
  NodeFlattenedDto,
  SearchResponseDto
} from "api";
import { produce } from "immer";
import {
  TerminologyBankActionType,
  TerminologyBankActions
} from "../actions/terminologyBankActions";
import { AppState } from "setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { RequestStatus } from "framework/state/requestStatus";
import { terminologyBankSagas } from "../sagas/terminologyBankSagas";

interface Requests {
  getNodesFlat: RequestState<Array<NodeFlattenedDto>, ApiTrainingNodesFlatGetRequest>;
  getNodesRoots: RequestState<Array<NodeDto>, ApiTrainingNodesRootsGetRequest>;
  search: RequestState<SearchResponseDto, ApiTrainingSearchArticlePostRequest>;
}

export interface TerminologyBankState {
  requests: Requests;
  nodeExpandedTraining: NodeDto | undefined;
  openArticle: ArticleDto | undefined;
  nodes: NodeFlattenedDto[];
  roots: NodeDto[];
  nodeOnScreenId: string | undefined;
  search: string | undefined;
  isSearch: boolean | undefined;
  isLoading: boolean | undefined;
  pageSize: number;
  pageNumber: number;
  forceScroll: boolean;

  articlesHits: ArticleDtoFormatted[];
  articlesHitsNumber: number | undefined;
  categories:
    | {
        [key: string]: number;
      }
    | undefined;
}
const defaultState: TerminologyBankState = {
  requests: {
    getNodesFlat: createInitialRequest(),
    getNodesRoots: createInitialRequest(),
    search: createInitialRequest()
  },
  nodeExpandedTraining: undefined,
  openArticle: undefined,
  nodes: [],
  roots: [],
  nodeOnScreenId: undefined,
  search: undefined,
  isSearch: undefined,
  isLoading: false,
  pageNumber: 0,
  pageSize: 5,
  forceScroll: false,
  articlesHits: [],
  articlesHitsNumber: undefined,
  categories: undefined
};

export function terminologyBankReducer(
  state: TerminologyBankState = defaultState,
  action: TerminologyBankActions
): TerminologyBankState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: terminologyBankSagas.getNodesFlat.actionTypes,
      key: "getNodesFlat"
    },
    {
      actionTypes: terminologyBankSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: terminologyBankSagas.search.actionTypes,
      key: "search"
    }
  ]);
  if (terminologyBankSagas.getNodesFlat.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload;
    });
  if (terminologyBankSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.roots = action.payload;
    });

  if (terminologyBankSagas.search.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.articlesHits = action.payload.articles ?? [];
      draft.articlesHitsNumber = action.payload.estimatedTotalHits ?? 0;
      draft.categories = action.payload.facetDistribution ?? undefined;
      draft.isLoading = false;
    });
  }

  switch (action.type) {
    case TerminologyBankActionType.SetVisibleArticle:
      state = produce(state, (draft) => {
        draft.openArticle = action.article;
      });
      break;

    case TerminologyBankActionType.SetNodeOpen:
      state = produce(state, (draft) => {
        if (action.node?.id != draft.nodeExpandedTraining?.id) {
          draft.nodeExpandedTraining = action.node;
          draft.isSearch = false;
          draft.openArticle = undefined;
          draft.isLoading = true;
          draft.search = undefined;
          draft.pageNumber = 0;
          draft.forceScroll = true;
        }
      });
      break;

    case TerminologyBankActionType.SetNodeOnScreen:
      state = produce(state, (draft) => {
        draft.nodeOnScreenId = action.id;
      });
      break;
    case TerminologyBankActionType.SetSearchText:
      state = produce(state, (draft) => {
        if (draft.search != action.search) {
          draft.search = action.search;
          draft.isSearch = !action.search ? false : true;
          draft.isLoading = !action.search ? false : true;
          draft.pageNumber = 0;
          draft.forceScroll = true;
        }
      });
      break;
    case TerminologyBankActionType.SetLoadingResults:
      state = produce(state, (draft) => {
        draft.isLoading = action.loading;
      });
      break;
    case TerminologyBankActionType.SetPageNumber:
      state = produce(state, (draft) => {
        draft.pageNumber = action.page;
        draft.forceScroll = true;
      });
      break;
    case TerminologyBankActionType.SetPageSize:
      state = produce(state, (draft) => {
        draft.pageSize = action.size;
        draft.pageNumber = 0;
        draft.forceScroll = true;
      });
      break;
    case TerminologyBankActionType.SetForceScroll:
      state = produce(state, (draft) => {
        draft.forceScroll = action.force;
      });
      break;
  }
  return state;
}
export const getOpenArticle = (state: AppState): ArticleDto | undefined =>
  state.terminologyBank.openArticle;

export const getNumberOfHits = (state: AppState): number | undefined =>
  state.terminologyBank.articlesHitsNumber;
export const getHits = (state: AppState): ArticleDtoFormatted[] =>
  state.terminologyBank.articlesHits;
export const getHitsCategories = (state: AppState) => state.terminologyBank.categories;
export const getNodeExpandedTraining = (state: AppState): NodeDto | undefined =>
  state.terminologyBank.nodeExpandedTraining;
export const getNodes = (state: AppState): NodeFlattenedDto[] => state.terminologyBank.nodes;
export const getNodesStatus = (state: AppState): RequestStatus =>
  state.terminologyBank.requests.getNodesFlat.status;
export const getNodesRoots = (state: AppState): NodeDto[] => state.terminologyBank.roots;
export const getNodesRootsStatus = (state: AppState): RequestStatus =>
  state.terminologyBank.requests.getNodesRoots.status;
export const getNodesOnScreenId = (state: AppState): string | undefined =>
  state.terminologyBank.nodeOnScreenId;
export const getSearch = (state: AppState): string | undefined => state.terminologyBank.search;
export const getIsSearch = (state: AppState): boolean | undefined => state.terminologyBank.isSearch;
export const getIsLoading = (state: AppState): boolean | undefined =>
  state.terminologyBank.isLoading;
export const getPageSize = (state: AppState): number => state.terminologyBank.pageSize;
export const getPageNumber = (state: AppState): number => state.terminologyBank.pageNumber;
export const getForceScroll = (state: AppState): boolean => state.terminologyBank.forceScroll;
