import {
  UserShoppingCartsVm,
  ApiShoppingCartsGetRequest,
  ApiShoppingCartsArchivePatchRequest,
  ApiShoppingCartsOrderPlacedExternallyPatchRequest,
  ApiShoppingCartsClonePostRequest,
  ShoppingCartUpdatedDto
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { Action } from "redux";
import { AppState } from "setup/appRootReducer";
import { shoppingCartsViewSagas } from "../sagas/shoppingCartsViewSagas";
import { produce } from "immer";

interface Requests {
  getUserShoppingCarts: RequestState<UserShoppingCartsVm, ApiShoppingCartsGetRequest>;
  archiveShoppingCart: RequestState<void, ApiShoppingCartsArchivePatchRequest>;
  orderPlacedExternally: RequestState<void, ApiShoppingCartsOrderPlacedExternallyPatchRequest>;
  clone: RequestState<ShoppingCartUpdatedDto, ApiShoppingCartsClonePostRequest>;
}

export interface ShoppingCartsViewState {
  shoppingCarts: UserShoppingCartsVm | undefined;
  clonedId: string | undefined;
  requests: Requests;
}

const defaultState: ShoppingCartsViewState = {
  shoppingCarts: undefined,
  clonedId: undefined,
  requests: {
    getUserShoppingCarts: createInitialRequest(),
    archiveShoppingCart: createInitialRequest(),
    orderPlacedExternally: createInitialRequest(),
    clone: createInitialRequest()
  }
};

export function shoppingCartsViewReducers(
  state: ShoppingCartsViewState = defaultState,
  action: Action
): ShoppingCartsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: shoppingCartsViewSagas.archiveShoppingCart.actionTypes,
      key: "archiveShoppingCart"
    },
    {
      actionTypes: shoppingCartsViewSagas.orderPlacedExternally.actionTypes,
      key: "orderPlacedExternally"
    },
    {
      actionTypes: shoppingCartsViewSagas.clone.actionTypes,
      key: "clone"
    }
  ]);
  if (shoppingCartsViewSagas.clone.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.clonedId = action.payload.guid;
    });
  }
  return state;
}

export const getArchiveShoppingCartStatus = (state: AppState) =>
  state.shoppingCartsView.requests.archiveShoppingCart.status;

export const getOrderPlacedExternallyStatus = (state: AppState) =>
  state.shoppingCartsView.requests.orderPlacedExternally.status;
export const getCloneStatus = (state: AppState) => state.shoppingCartsView.requests.clone.status;
export const getClonedIdentifier = (state: AppState): string | undefined =>
  state.shoppingCartsView.clonedId;
