import React, { useMemo, memo, useEffect, useRef } from "react";
import { createHeaderAndHeaderTooltip } from "../../../../framework/components/grid/Grid";
import { TFunction } from "i18next";
import { ColDef, GridApi, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { DetailGrid } from "styles/detailGrid";
import {
  GridQuoteFilesActionsRenderer,
  GridQuoteFilesActionsRendererProps
} from "./GridQuoteFilesActionsRenderer";
import { QuoteAttachmentDto } from "api";
import { GridLoadingOverlay } from "framework/components/grid/GridLoadingOverlay";
import { AgGridReact } from "ag-grid-react";

export interface QuoteFilesDetailsGridProps {
  t: TFunction;
  isRemovingEnabled: boolean;
  attachments: QuoteAttachmentDto[];
  files: File[];
  handleFileDownload(attachment: QuoteAttachmentDto): void;
  removeFile(attachment: QuoteAttachmentDto): void;
  setGridApi(gridApi: GridApi): void;
  isPrinting: boolean;
}

export const QuoteFilesDetailsGrid = memo(
  ({
    t,
    attachments,
    isRemovingEnabled,
    files,
    handleFileDownload,
    removeFile,
    setGridApi,
    isPrinting
  }: QuoteFilesDetailsGridProps): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);

    useEffect(() => {
      if (gridRef.current?.api) {
        gridRef.current.api.setDomLayout(isPrinting ? "print" : "autoHeight");
      }
    }, [isPrinting]);

    const colDefs: ColDef[] = useMemo(
      () => [
        {
          headerName: "#",
          valueGetter: ({ node }: ValueGetterParams) => {
            return (node?.rowIndex ?? 0) + 1;
          },
          minWidth: 50,
          maxWidth: 50
        },
        {
          ...createHeaderAndHeaderTooltip(t("File")),
          field: "name",
          colId: "name",
          autoHeight: true,
          flex: 1
        },
        {
          cellRenderer: GridQuoteFilesActionsRenderer,
          ...createHeaderAndHeaderTooltip(t("Action")),
          tooltipValueGetter: () => "",
          cellRendererParams: (params: ICellRendererParams): GridQuoteFilesActionsRendererProps => {
            const attachment = params.data as QuoteAttachmentDto;
            return {
              ...params,
              onDownload: handleFileDownload,
              onRemove: removeFile,
              attachment,
              isRemovingEnabled:
                isRemovingEnabled || !!files.find((f) => f.name === attachment.name)
            };
          },
          minWidth: 70,
          maxWidth: 70,
          floatingFilter: false,
          sortable: false,
          suppressMenu: true
        }
      ],
      [t, handleFileDownload, removeFile, isRemovingEnabled, files]
    );

    return (
      <DetailGrid
        wrapHeaderText
        t={t}
        disableColumnAutoSize
        columnDefs={colDefs}
        rowData={attachments}
        suppressRowClickSelection={true}
        domLayout="autoHeight"
        enableCellTextSelection
        loadingOverlayComponent={GridLoadingOverlay}
        onGridReady={(params) => {
          setGridApi(params.api);
        }}
        gridRef={gridRef}
      />
    );
  }
);
