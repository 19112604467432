import * as React from "react";
import "react-datetime/css/react-datetime.css";
import ReactDatetimeClass from "react-datetime";
import moment, { isMoment } from "moment";
import { getValidDateFormat } from "../../utilities/dateUtils";

export type DatePickerProps = Omit<
  React.ComponentProps<typeof ReactDatetimeClass>,
  "dateFormat" | "timeFormat"
>;

export const DatePicker = (props: DatePickerProps) => {
  return <ReactDatetimeClass {...props} dateFormat={getValidDateFormat()} timeFormat={false} />;
};

export function formatDate(
  value: string | moment.Moment | React.FocusEvent<any>
): string | undefined {
  if (isMoment(value)) {
    return value.format(getValidDateFormat());
  }
  if (typeof value === "string") {
    const momentValue = moment(value, getValidDateFormat(), true);
    if (momentValue.isValid()) {
      return momentValue.format(getValidDateFormat());
    }
  }
}
