/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateGmdModificationCaseCommand
 */
export interface CreateGmdModificationCaseCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateGmdModificationCaseCommand
     */
    shoppingCartItemId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateGmdModificationCaseCommand
     */
    description?: string | null;
}

export function CreateGmdModificationCaseCommandFromJSON(json: any): CreateGmdModificationCaseCommand {
    return CreateGmdModificationCaseCommandFromJSONTyped(json, false);
}

export function CreateGmdModificationCaseCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGmdModificationCaseCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartItemId': json['shoppingCartItemId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreateGmdModificationCaseCommandToJSON(value?: CreateGmdModificationCaseCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartItemId': value.shoppingCartItemId,
        'description': value.description,
    };
}

