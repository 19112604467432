import React from "react";
import Banner from "assets/ROL_explore_offering.jpg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { commonUXTheme } from "styles/commonUXVariables";

const StartImage = styled.img`
  width: 100%;
  margin: ${commonUXTheme.sizes.m} 0;
`;

const StartStep = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack marginTop={commonUXTheme.sizes.l} marginBottom={commonUXTheme.sizes.l}>
      <StartImage src={Banner} alt={t("Banner with an image of stacked cubes")} />
    </Stack>
  );
};

export default StartStep;
