import React from "react";
import styled from "styled-components";
import { CommonUXSize } from "../../styles/commonUXVariables";

const ElementContainer = styled.div<{ isLeft?: boolean; gap: CommonUXSize | number }>`
  display: flex !important;
  flex-direction: row;
  margin-left: ${(props) => (props.isLeft ? "0px" : "auto")};
  margin-right: ${(props) => (props.isLeft ? "auto" : "0px")};
  gap: ${(props) => {
    if (typeof props.gap === "number") {
      return `${props.gap}px`;
    }
    return props.theme.sizes[props.gap];
  }};
`;

export const HorizontalElementContainer: React.FunctionComponent<{
  children: React.ReactNode;
  isLeft?: boolean;
  marginSizeBetweenElements?: CommonUXSize | number;
}> = ({ children, isLeft = false, marginSizeBetweenElements: marginBetweenElements = "sm" }) => {
  return (
    <ElementContainer isLeft={isLeft} gap={marginBetweenElements}>
      {children}
    </ElementContainer>
  );
};
