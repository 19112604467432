/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    addressStreet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    addressPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    addressCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    countryName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDto
     */
    lastActive?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    overrideStatusActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDto
     */
    accessAuditCompleted?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyDto
     */
    accessAuditDueTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    hasLateAccessAudit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    hasCompanyAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    companyDisplayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    canSelect: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isDeliverITRelevant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    hasDeliverItUsers?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    defaultTermsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isInternal?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    defaultPgCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    globalCustomerNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isMaintainedCustomer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isInternalBuyer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDto
     */
    isAbbCompany?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    pgCodes?: Array<string> | null;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'addressStreet': !exists(json, 'addressStreet') ? undefined : json['addressStreet'],
        'addressPostalCode': !exists(json, 'addressPostalCode') ? undefined : json['addressPostalCode'],
        'addressCity': !exists(json, 'addressCity') ? undefined : json['addressCity'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'lastActive': !exists(json, 'lastActive') ? undefined : (json['lastActive'] === null ? null : new Date(json['lastActive'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'overrideStatusActive': !exists(json, 'overrideStatusActive') ? undefined : json['overrideStatusActive'],
        'accessAuditCompleted': !exists(json, 'accessAuditCompleted') ? undefined : (json['accessAuditCompleted'] === null ? null : new Date(json['accessAuditCompleted'])),
        'accessAuditDueTo': !exists(json, 'accessAuditDueTo') ? undefined : (json['accessAuditDueTo'] === null ? null : new Date(json['accessAuditDueTo'])),
        'hasLateAccessAudit': !exists(json, 'hasLateAccessAudit') ? undefined : json['hasLateAccessAudit'],
        'hasCompanyAdmin': !exists(json, 'hasCompanyAdmin') ? undefined : json['hasCompanyAdmin'],
        'companyDisplayName': json['companyDisplayName'],
        'canSelect': json['canSelect'],
        'isDeliverITRelevant': !exists(json, 'isDeliverITRelevant') ? undefined : json['isDeliverITRelevant'],
        'hasDeliverItUsers': !exists(json, 'hasDeliverItUsers') ? undefined : json['hasDeliverItUsers'],
        'defaultTermsOfDeliveryCode': !exists(json, 'defaultTermsOfDeliveryCode') ? undefined : json['defaultTermsOfDeliveryCode'],
        'isInternal': !exists(json, 'isInternal') ? undefined : json['isInternal'],
        'defaultPgCode': !exists(json, 'defaultPgCode') ? undefined : json['defaultPgCode'],
        'globalCustomerNumber': !exists(json, 'globalCustomerNumber') ? undefined : json['globalCustomerNumber'],
        'isMaintainedCustomer': !exists(json, 'isMaintainedCustomer') ? undefined : json['isMaintainedCustomer'],
        'isInternalBuyer': !exists(json, 'isInternalBuyer') ? undefined : json['isInternalBuyer'],
        'isAbbCompany': !exists(json, 'isAbbCompany') ? undefined : json['isAbbCompany'],
        'pgCodes': !exists(json, 'pgCodes') ? undefined : json['pgCodes'],
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'addressStreet': value.addressStreet,
        'addressPostalCode': value.addressPostalCode,
        'addressCity': value.addressCity,
        'countryCode': value.countryCode,
        'countryName': value.countryName,
        'lastActive': value.lastActive === undefined ? undefined : (value.lastActive === null ? null : value.lastActive.toISOString()),
        'active': value.active,
        'overrideStatusActive': value.overrideStatusActive,
        'accessAuditCompleted': value.accessAuditCompleted === undefined ? undefined : (value.accessAuditCompleted === null ? null : value.accessAuditCompleted.toISOString()),
        'accessAuditDueTo': value.accessAuditDueTo === undefined ? undefined : (value.accessAuditDueTo === null ? null : value.accessAuditDueTo.toISOString()),
        'hasLateAccessAudit': value.hasLateAccessAudit,
        'hasCompanyAdmin': value.hasCompanyAdmin,
        'companyDisplayName': value.companyDisplayName,
        'canSelect': value.canSelect,
        'isDeliverITRelevant': value.isDeliverITRelevant,
        'hasDeliverItUsers': value.hasDeliverItUsers,
        'defaultTermsOfDeliveryCode': value.defaultTermsOfDeliveryCode,
        'isInternal': value.isInternal,
        'defaultPgCode': value.defaultPgCode,
        'globalCustomerNumber': value.globalCustomerNumber,
        'isMaintainedCustomer': value.isMaintainedCustomer,
        'isInternalBuyer': value.isInternalBuyer,
        'isAbbCompany': value.isAbbCompany,
        'pgCodes': value.pgCodes,
    };
}

