/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcknowledgeRevisionCommand
 */
export interface AcknowledgeRevisionCommand {
    /**
     * 
     * @type {number}
     * @memberof AcknowledgeRevisionCommand
     */
    quoteId: number;
}

export function AcknowledgeRevisionCommandFromJSON(json: any): AcknowledgeRevisionCommand {
    return AcknowledgeRevisionCommandFromJSONTyped(json, false);
}

export function AcknowledgeRevisionCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcknowledgeRevisionCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quoteId': json['quoteId'],
    };
}

export function AcknowledgeRevisionCommandToJSON(value?: AcknowledgeRevisionCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quoteId': value.quoteId,
    };
}

