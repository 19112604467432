/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SupplierTermsAndConditionType,
    SupplierTermsAndConditionTypeFromJSON,
    SupplierTermsAndConditionTypeFromJSONTyped,
    SupplierTermsAndConditionTypeToJSON,
} from './SupplierTermsAndConditionType';

/**
 * 
 * @export
 * @interface SupplierTermsAndConditionsDto
 */
export interface SupplierTermsAndConditionsDto {
    /**
     * 
     * @type {number}
     * @memberof SupplierTermsAndConditionsDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierTermsAndConditionsDto
     */
    attachmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SupplierTermsAndConditionsDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof SupplierTermsAndConditionsDto
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SupplierTermsAndConditionsDto
     */
    lastModified?: Date | null;
    /**
     * 
     * @type {SupplierTermsAndConditionType}
     * @memberof SupplierTermsAndConditionsDto
     */
    type?: SupplierTermsAndConditionType;
}

export function SupplierTermsAndConditionsDtoFromJSON(json: any): SupplierTermsAndConditionsDto {
    return SupplierTermsAndConditionsDtoFromJSONTyped(json, false);
}

export function SupplierTermsAndConditionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierTermsAndConditionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModified': !exists(json, 'lastModified') ? undefined : (json['lastModified'] === null ? null : new Date(json['lastModified'])),
        'type': !exists(json, 'type') ? undefined : SupplierTermsAndConditionTypeFromJSON(json['type']),
    };
}

export function SupplierTermsAndConditionsDtoToJSON(value?: SupplierTermsAndConditionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attachmentId': value.attachmentId,
        'identifier': value.identifier,
        'fileName': value.fileName,
        'extension': value.extension,
        'description': value.description,
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified === null ? null : value.lastModified.toISOString()),
        'type': SupplierTermsAndConditionTypeToJSON(value.type),
    };
}

