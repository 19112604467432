/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationMaterial,
    ModificationMaterialFromJSON,
    ModificationMaterialFromJSONTyped,
    ModificationMaterialToJSON,
} from './ModificationMaterial';

/**
 * 
 * @export
 * @interface ModificationVerificationResult
 */
export interface ModificationVerificationResult {
    /**
     * 
     * @type {string}
     * @memberof ModificationVerificationResult
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationVerificationResult
     */
    materialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationVerificationResult
     */
    orderCode?: string | null;
    /**
     * 
     * @type {Array<ModificationMaterial>}
     * @memberof ModificationVerificationResult
     */
    modificationMaterials?: Array<ModificationMaterial> | null;
}

export function ModificationVerificationResultFromJSON(json: any): ModificationVerificationResult {
    return ModificationVerificationResultFromJSONTyped(json, false);
}

export function ModificationVerificationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationVerificationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'orderCode': !exists(json, 'orderCode') ? undefined : json['orderCode'],
        'modificationMaterials': !exists(json, 'modificationMaterials') ? undefined : (json['modificationMaterials'] === null ? null : (json['modificationMaterials'] as Array<any>).map(ModificationMaterialFromJSON)),
    };
}

export function ModificationVerificationResultToJSON(value?: ModificationVerificationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'materialCode': value.materialCode,
        'orderCode': value.orderCode,
        'modificationMaterials': value.modificationMaterials === undefined ? undefined : (value.modificationMaterials === null ? null : (value.modificationMaterials as Array<any>).map(ModificationMaterialToJSON)),
    };
}

