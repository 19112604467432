import React from "react";
import {
  Grid,
  createHeaderAndHeaderTooltip
} from "../../../../framework/components/grid/Grid";
import {
  GridApi,
  ICellRendererParams,
  CellClickedEvent,
  GridReadyEvent,
  ColDef,
  ITooltipParams
} from "ag-grid-community";
import { ApiTeamsGetRequest, TeamDto, TeamMemberDto, TeamMemberSources, TeamTypes } from "../../../../api";
import { getDefaultColumnSort } from "../../../../framework/components/grid/Grid";
import { TFunction } from "react-i18next";
import { useCallback } from "react";
import { defaultTeamMembersCriteria } from "../reducers/manageTeamsViewReducer";
import { getTeamMemberGridRowStyle, isTeamAdminRole, roleAbbreviationToFriendlyName } from "utilities/teamUtils";
import { TeamMembersGridActionMethods, TeamMembersGridActions, TeamMembersGridActionsProps } from "./TeamMembersGridActions";

interface TeamMembersGridProps {
  t: TFunction;
  onSelectTeam?: (teamId: number) => void;
  setGridApi?: ((ref: GridApi) => void) | null;
  criteria?: React.MutableRefObject<ApiTeamsGetRequest | undefined>;
  actionMethods: TeamMembersGridActionMethods;
}

export const TeamMembersGrid = (props: TeamMembersGridProps): JSX.Element => {
  const t = props.t;

  const buildTooltip = (teamMember: TeamMemberDto | undefined): string => {
    if(!teamMember) return "";

    const tooltips = [];
    if (teamMember?.team?.type === TeamTypes.BusinessUnit) {
      if (teamMember?.source === TeamMemberSources.AzureAd) {
        tooltips.push(t("Business unit assigned by default. Access can not be removed."));

        if (isTeamAdminRole(teamMember?.roleAbbreviation ?? "")) {
          tooltips.push(t("User admin role assigned externally to first user or by user admin."));
        }
      }
    }
    
    return tooltips.join("\n");
  };

  const colDefs: ColDef[] = [
    {
      headerName: t("First name"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "firstName"
    },
    {
      headerName: t("Last name"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "lastName"
    },
    {
      headerName: t("Roles"),
      field: "roleAbbreviation",
      sortable: false,
      valueFormatter: (params: any) => roleAbbreviationToFriendlyName(params.value),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data)
    },
    {
      headerName: t("Status"),
      tooltipValueGetter: (params: ITooltipParams<TeamMemberDto>): string => buildTooltip(params.data),
      field: "status",
      sortable: false,
    },
    {
      cellRenderer: TeamMembersGridActions,
      ...createHeaderAndHeaderTooltip("Action"),
      field: "identifier",
      tooltipValueGetter: () => "",
      cellRendererParams: (params: ICellRendererParams): TeamMembersGridActionsProps => {
        return {
          ...params,
          icon: "abb/view",
          methods: props.actionMethods
        };
      },
      minWidth: 70,
      maxWidth: 70,
      floatingFilter: false,
      sortable: false,
      suppressMenu: true,
      filter: false,
      resizable: false,
    },
  ];

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if(props.setGridApi) {
        props.setGridApi(event.api);
      }
    },
    [props.setGridApi, props.criteria]
  );

  return (
    <Grid
      t={t}
      getRowStyle={(params) => getTeamMemberGridRowStyle(params)}
      statusBarOptions={{ showResetButton: true, showRowCount: true }}
      columnDefs={colDefs}
      rowModelType="infinite"
      isSortPersisted={true}
      defaultSort={getDefaultColumnSort(
        defaultTeamMembersCriteria.criteriaIsAscendingOrder,
        defaultTeamMembersCriteria.criteriaSortColumn
      )}
      
      rowSelection="single"
      cacheBlockSize={100}
      maxBlocksInCache={2}
      onCellClicked={(event: CellClickedEvent) => {
        const team = event.data as TeamDto;
        if(team?.id && props.onSelectTeam) {
          props.onSelectTeam(team.id);
        }
      }}
      showPointerOnRowHover={false}
      keepEditingIfFocusIsLost
      colDefDefault={
        {
          sortable: false
        }
      }
      getRowId={(params) => params.data.userId.toString()}
      
      suppressRowClickSelection={true}
      suppressCellFocus={true}
      onGridReady={onGridReady}
      autosizeCols
      disableColumnAutoSize
    />
  );
};