import Grid from "@mui/material/Grid";
import styled from "styled-components";

export const Card = styled(Grid)<{ selected?: boolean }>`
  justify-content: space-between;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.grey30 : props.theme.colors.grey10};
  cursor: pointer;
  min-height: 30px;
  align-content: center;
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.s};
  &:hover,
  &.selected {
    background-color: ${(props) => props.theme.colors.grey30};
  }
`;
