import React, { useEffect, useMemo, useState } from "react";
import { StepperProps as MaterialStepperProps } from "@mui/material/Stepper";
import {
  Stack,
  StepButton,
  StepIconProps,
  StepLabel,
  Stepper as MaterialStepper
} from "@mui/material";
import { StepProps as MaterialStepProps } from "@mui/material/Step";
import { Step as MaterialStep } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import styled from "styled-components";
import { Button } from "./Button";
import { useIsMobile } from "utilities/useIsMobile";

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 3,
    left: "calc(-50% + 6px)",
    right: "calc(50% + 6px)"
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors.brandRed
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.colors.brandRed
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.colors.brandRed,
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: "flex",
    height: 8,
    borderRadius: "50%",
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.colors.brandRed,
      backgroundColor: theme.colors.brandRed
    }),
    "& .StepIcon-circle__completed": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: theme.colors.brandRed,
      border: "1px solid " + theme.colors.brandRed
    },
    "& .StepIcon-circle": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      border: "1px solid " + theme.colors.brandRed,
      backgroundColor: theme.colors.whitePrimary
    }
  })
);

const CustomStepButton = styled(StepButton)(({ theme }) => ({
  "&.Mui-disabled[disabled]": {
    color: theme.colors.grey50,
    cursor: "not-allowed",
    pointerEvents: "auto"
  },
  ".MuiStepLabel-label": {
    color: theme.colors.blackPrimary,
    "&.Mui-active, &.Mui-completed": {
      color: theme.colors.blackPrimary
    },
    "&.Mui-active,:hover:not(.Mui-disabled)": {
      fontWeight: "bold"
    },
    "&.Mui-disabled": {
      color: theme.colors.grey50,
      cursor: "not-allowed",
      pointerEvents: "auto"
    }
  }
}));

const MuiMobileStepper = styled(MobileStepper)(({ theme }) => ({
  marginTop: theme.sizes.m,
  width: "100%",
  ".MuiMobileStepper-dot": {
    backgroundColor: theme.colors.whitePrimary,
    border: "1px solid",
    borderColor: theme.colors.brandRed,
    "&.MuiMobileStepper-dotActive": {
      backgroundColor: theme.colors.brandRed,
      borderColor: theme.colors.brandRed
    }
  }
}));

const MuiStepper = styled(MaterialStepper)(({ theme }) => ({
  marginBottom: theme.sizes.m
}));

export const Step: React.FC<MaterialStepProps> = ({
  children,
  //eslint-disable-next-line
  title,
  ...rest
}: MaterialStepProps) => {
  return <MaterialStep {...rest}>{children}</MaterialStep>;
};

interface StepperProps extends MaterialStepperProps {
  handleStep?: (step: number) => void;
  children: JSX.Element[];
}

const Stepper = ({
  connector,
  handleStep,
  activeStep: currentStep,
  children,
  ...rest
}: StepperProps): JSX.Element => {
  const [completedStep, setCompletedStep] = useState<number>(-1);
  const [activeStep, setActiveStep] = useState<number>(0);
  const stepsCount = children.length;

  const isMobile = useIsMobile();

  const nextBtnDisabled = useMemo(() => {
    return stepsCount <= activeStep + 1 || (children[activeStep + 1].props.disabled ?? false);
  }, [activeStep, stepsCount, children]);

  useEffect(() => {
    if (currentStep !== undefined) {
      setActiveStep(currentStep);
      if (currentStep > completedStep) {
        const completed = currentStep - 1;
        setCompletedStep(completed);
        if (handleStep) handleStep(completed + 1);
      }
    }
    // eslint-disable-next-line
  }, [currentStep, completedStep]);

  if (!connector) connector = <Connector />;

  const onStepNumberClick = (step: number) => {
    if (handleStep) handleStep(step);

    if (completedStep <= step) setActiveStep(step);
  };

  function StepIcon({ active, completed, className }: StepIconProps) {
    return (
      <StepIconRoot ownerState={{ active }} className={className}>
        {completed || active ? (
          <div className="StepIcon-circle__completed" />
        ) : (
          <div className="StepIcon-circle" />
        )}
      </StepIconRoot>
    );
  }

  return (
    <>
      {isMobile ? (
        <MuiMobileStepper
          variant="dots"
          steps={children.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              disabled={nextBtnDisabled}
              buttonType="secondary"
              onClick={() => handleStep && handleStep(activeStep + 1)}
              icon="abb/right"
            />
          }
          backButton={
            <Button
              disabled={activeStep === 0}
              buttonType="secondary"
              onClick={() => handleStep && handleStep(activeStep - 1)}
              icon="abb/left"
            />
          }
        />
      ) : (
        <MuiStepper alternativeLabel activeStep={activeStep} connector={connector} {...rest}>
          {children.map((step, index) => (
            <Step
              disabled={step.props?.disabled ?? false}
              key={step.props?.title?.toString() || ""}
              style={{
                minWidth: 0 // https://stackoverflow.com/questions/29503227/how-to-make-flexbox-items-the-same-size#comment103277659_29503264
              }}
            >
              <StepLabel StepIconComponent={() => <></>}></StepLabel>
              <CustomStepButton
                disableTouchRipple
                icon={StepIcon({
                  active: activeStep === index,
                  icon: null,
                  completed: completedStep >= index
                })}
                disabled={step.props?.disabled ?? false}
                onClick={() => onStepNumberClick(index)}
              >
                {step.props?.title}
              </CustomStepButton>
            </Step>
          ))}
        </MuiStepper>
      )}
      {children.map((c, i) => (
        // TODO: This is backward compatibility with the old tabs, avoid rendering other tabs
        <Stack
          key={c.props?.title?.toString() || ""}
          width={"100%"}
          display={i !== activeStep ? "none" : ""}
        >
          {c}
        </Stack>
      ))}
    </>
  );
};

export default Stepper;
