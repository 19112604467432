import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Dialog } from "framework/components/Dialog";
import { ResizableInput } from "framework/components/styled/ResizableInput";
import { StatusBanner } from "framework/components/StatusBanner";
import { DatePickerInput } from "framework/components/DatePickerInput";
import { formatDate, isDate1beforeDate2, isDateInThePast } from "utilities/dateUtils";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 600px;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div``;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const CustomDialog = styled(Dialog)`
  max-height: 95vh;
  overflow: auto;
`;

interface RequestRevisionDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  isLoading: boolean;
  onlyDateRevision: boolean;
  requestQuoteRevision: (revisionMessage: string) => void;
  validityDate: Date | undefined | null;
}

export const RequestRevisionDialog = ({
  isOpen,
  setIsOpen,
  t,
  isLoading,
  requestQuoteRevision,
  onlyDateRevision,
  validityDate
}: RequestRevisionDialogProps): JSX.Element => {
  const theme = useTheme();
  const [proposedValidityDate, setProposedValidityDate] = useState<string | undefined>();
  const [revisionMessage, setRevisionMessage] = useState<string | undefined>();

  return (
    <CustomDialog
      isOpen={isOpen}
      title={t("Request quote revision")}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
    >
      <Container>
        <>
          <SectionsContainer>
            {onlyDateRevision ? (
              <>
                <StatusBanner
                  status="information"
                  text={t(
                    "After the first order the only field editable is the validity date. If you need to change other fields, please create a new frame agreement."
                  )}
                  icon={"abb/information-circle-2"}
                  shape={"long"}
                />
                <InputWrapper style={{ marginTop: theme.sizes.l }}>
                  <DatePickerInput
                    label={t("Proposed new validity date")}
                    value={
                      proposedValidityDate ?? (validityDate ? formatDate(validityDate) : undefined)
                    }
                    type="normal"
                    onValueChange={(v: string) => {
                      setProposedValidityDate(v);
                      setRevisionMessage(v);
                    }}
                    validator={(v) => {
                      if (!v) {
                        return {
                          valid: false,
                          text: t("Please provide a new validity date.")
                        };
                      } else if (
                        !!validityDate &&
                        isDate1beforeDate2(v, formatDate(validityDate))
                      ) {
                        return {
                          valid: false,
                          text: t(
                            "The proposed validity date must be after the current validity date."
                          )
                        };
                      } else if (isDateInThePast(v)) {
                        return {
                          valid: false,
                          text: t("Date cannot be in the past.")
                        };
                      } else {
                        return true;
                      }
                    }}
                    instantValidation
                    showValidationIconWhenInvalid
                    positionFixed
                  />
                </InputWrapper>
              </>
            ) : (
              <InputWrapper>
                <ResizableInput
                  dataType="textarea"
                  label={t("Revision message")}
                  value={revisionMessage}
                  onValueChange={(v) => setRevisionMessage(v)}
                  placeholder={t("Reason for revision")}
                  inputAttributes={{
                    rows: 8
                  }}
                  required={true}
                  resizable={true}
                  resizeParams={{ maxHeight: "15lh", maxWidth: theme.breakpoints.xxl }}
                />
              </InputWrapper>
            )}
          </SectionsContainer>
          <ButtonContainer>
            <Button buttonType={"secondary"} text={t("Cancel")} onClick={() => setIsOpen(false)} />
            <Button
              buttonType={"primary"}
              text={t("Request")}
              onClick={() => requestQuoteRevision(revisionMessage ?? "")}
              isLoading={isLoading}
              disabled={!revisionMessage || revisionMessage === ""}
            />
          </ButtonContainer>
        </>
      </Container>
    </CustomDialog>
  );
};
