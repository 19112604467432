import styled from "styled-components";
import { Heading, HeadingProps } from "@abb/abb-common-ux-react/components/Heading";
import React from "react";

const StyledSmallHeader = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold} !important;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM} !important;
  line-height: ${(props) => props.theme.fonts.lineHeights.lineHeightM} !important;
  margin-bottom: ${(props) => props.theme.sizes.s};
`;

export const SmallHeader = (
  props: Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level">
): JSX.Element => (
  <StyledSmallHeader {...props} level={4} blackAndWhite={true} showCursor={false} />
);
