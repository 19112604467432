import React, { ReactNode, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { Dropdown, DropdownOption, Popup, WithPopup } from "@abb/abb-common-ux-react";
import { TFunction } from "i18next";
import { AdditionalQuoteRequesterDto, AdditionalQuoteRequesterStatus, UserBaseDto } from "api";
import { Button } from "framework/components/Button";
import { Link } from "framework/components/Link";
import { InputLabel } from "framework/components/InputLabel";
import { Formik } from "formik";
import { getAddRequestersValidationSchema } from "applications/shoppingCart/validationSchema";
import { handleFormikValueChange } from "utilities/formikUtils";
import { IconWithTooltip } from "framework/components/IconWithTooltip";
import { commonUXTheme } from "styles/commonUXVariables";

type QuoteDetailsRequestersProps = {
  label: string | undefined;
  text: ReactNode | undefined | null;
  actionText: ReactNode | undefined | null;
  t: TFunction;
  requesterCandidates: UserBaseDto[];
  isLoading: boolean;
  isAddingRequestorsAllowed: boolean;
  action: (requesters: string[]) => void;
  getRequesterCandidates: () => void;
  fontSize?: "small" | "medium" | "large";
  additionalRequesters: AdditionalQuoteRequesterDto[] | null | undefined;
  noMarginBottom?: boolean;
} & (
  | {
      valueIcon: string;
      valueTooltipText: string;
      valueIconColor: string;
    }
  | object
) &
  (
    | {
        labelIcon: string | undefined;
        labelTooltipText: string | undefined;
        labelIconColor: string;
      }
    | object
  );

const Label = styled.p<{ fontSize: "small" | "medium" | "large" }>`
  margin: 0;
  font-size: ${(props) => {
    switch (props.fontSize) {
      case "small":
        return props.theme.fonts.sizes.fontSizeXs;
      case "medium":
        return props.theme.fonts.sizes.fontSizeS;
      case "large":
        return props.theme.fonts.sizes.fontSizeLM;
    }
  }};
  color: ${(props) => props.theme.colors.grey60};
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fonts.families.fontAbbRegular};
  width: fit-content;
`;

const Text = styled.p<{ fontSize: "small" | "medium" | "large"; noMarginBottom: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.sizes.xs};
  margin: 0;
  margin-bottom: ${(props) => {
    return props.noMarginBottom ? "0px" : props.theme.sizes.m;
  }};
  font-size: ${(props) => {
    switch (props.fontSize) {
      case "small":
        return props.theme.fonts.sizes.fontSizeS;
      case "medium":
        return props.theme.fonts.sizes.fontSizeM;
      case "large":
        return props.theme.fonts.sizes.fontSizeL;
    }
  }};
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  min-width: ${(props) => props.theme.sizes.lm};
  white-space: pre-line;
  &:after {
    content: "";
    display: inline-block;
  }
`;

const IconLabelContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.xs};
  gap: ${(props) => props.theme.sizes.xs};
`;

const Container = styled.div`
  padding: ${(props) => props.theme.sizes.s};
  width: 100%;
  max-width: 500px;
`;

const DropdownContainer = styled.div`
  .ABB_CommonUX_Dropdown__comp {
    div[class$="-menu"] > div {
      max-height: 200px !important;
    }
  }
`;

const StyledMultiDropdown = styled(Dropdown)`
  [class$="-option"] {
    background-color: ${(props) => props.theme.colors.whitePrimary};
  }
`;

const StyledAddButton = styled(Button)`
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.xs};
  float: right;
`;

const getDefaultItemTemplate = (email: string, name: string) => (
  <span>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span>{name}</span>
      <span style={{ fontSize: "80%", opacity: ".7" }}>{email}</span>
    </div>
  </span>
);

//need for formik
export interface AdditionalRequesters {
  requesters: string[];
}

export const infoTypeConstants = {
  discountRate: "discountRate",
  unitQuotedPrice: "unitQuotedPrice",
  quantity: "quantity"
};

export const QuoteDetailsRequesters = ({
  label,
  text,
  actionText,
  t,
  requesterCandidates,
  isLoading,
  isAddingRequestorsAllowed,
  action,
  getRequesterCandidates,
  fontSize = "small",
  noMarginBottom = false,
  additionalRequesters
}: QuoteDetailsRequestersProps): JSX.Element => {
  const theme = useTheme();

  const validationSchema = useMemo(() => getAddRequestersValidationSchema(t), [t]);

  const initialAdditionalRequestersValues: AdditionalRequesters = {
    requesters: []
  };

  return (
    <div>
      <IconLabelContainer>
        {isAddingRequestorsAllowed ? (
          <WithPopup>
            <div>
              <Label fontSize={fontSize}>
                {label}
                <Link
                  onClick={() => {
                    if (requesterCandidates.length === 0) {
                      getRequesterCandidates();
                    }
                  }}
                  to={{
                    pathname: undefined
                  }}
                  displayInline={true}
                  style={{ marginLeft: theme.sizes.xs, fontSize: theme.fonts.sizes.fontSizeS }}
                  underlined
                >
                  {actionText}
                </Link>
              </Label>
            </div>
            <Popup
              trigger={"click"}
              position={["right center"]}
              disablePaddings={true}
              defaultOpen={false}
            >
              <Container>
                <Formik
                  initialValues={initialAdditionalRequestersValues}
                  onSubmit={() => {}}
                  validationSchema={validationSchema}
                >
                  {(formik) => (
                    <div>
                      <InputLabel label={t("Select additional quote requester(s)")} />
                      <DropdownContainer>
                        <StyledMultiDropdown
                          validationState={{
                            valid: formik.isValid
                          }}
                          multiselect
                          value={formik.values.requesters.map((u) => ({
                            value: u,
                            label: u
                          }))}
                          onChange={(v) => {
                            handleFormikValueChange(
                              formik,
                              "requesters",
                              v.map((u) => u.label),
                              true
                            );
                          }}
                          showSpinnerOnBottom={isLoading}
                          searchable
                          monochrome
                          showValidationIconWhenInvalid={false}
                          showValidationBarWhenInvalid={true}
                          clearable
                          allowAdd
                          createOptionText="Add"
                        >
                          {requesterCandidates?.map((c) => (
                            <DropdownOption
                              value={c.email}
                              label={c.email}
                              itemTemplate={getDefaultItemTemplate(
                                c.email,
                                c.firstName + " " + c.lastName
                              )}
                            />
                          ))}
                        </StyledMultiDropdown>
                      </DropdownContainer>
                      <StyledAddButton
                        buttonType="primary"
                        text="Add"
                        icon="abb/plus"
                        disabled={formik.values.requesters.length === 0 || !formik.isValid}
                        onClick={() => action(formik.values.requesters)}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </Formik>
              </Container>
            </Popup>
          </WithPopup>
        ) : (
          <Label fontSize={fontSize}>{label}</Label>
        )}
      </IconLabelContainer>
      <Text fontSize={fontSize} noMarginBottom={noMarginBottom}>
        {text}
      </Text>
      {additionalRequesters && additionalRequesters.length > 0 ? (
        <div style={{ marginTop: theme.sizes.m }}>
          {additionalRequesters?.map((r) => (
            <Text fontSize={fontSize} noMarginBottom={noMarginBottom}>
              {r.name}
              {r.status !== AdditionalQuoteRequesterStatus.Active ? (
                <IconWithTooltip
                  color={
                    r.status === AdditionalQuoteRequesterStatus.WaitingForRegistration
                      ? commonUXTheme.colors.statusRed
                      : commonUXTheme.colors.statusOrange
                  }
                  tooltipText={
                    r.status === AdditionalQuoteRequesterStatus.WaitingForRegistration
                      ? t("Awaiting for registration")
                      : t("Waiting for connecting to the company")
                  }
                  icon="abb/information-circle-2"
                  style={{ display: "inline" }}
                />
              ) : null}
            </Text>
          ))}
        </div>
      ) : null}
    </div>
  );
};
