/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleDto,
    ArticleDtoFromJSON,
    ArticleDtoFromJSONTyped,
    ArticleDtoToJSON,
} from './ArticleDto';

/**
 * 
 * @export
 * @interface FreeAndAssignedArticleDto
 */
export interface FreeAndAssignedArticleDto {
    /**
     * 
     * @type {Array<ArticleDto>}
     * @memberof FreeAndAssignedArticleDto
     */
    freeArticles?: Array<ArticleDto> | null;
    /**
     * 
     * @type {Array<ArticleDto>}
     * @memberof FreeAndAssignedArticleDto
     */
    assignedArticles?: Array<ArticleDto> | null;
}

export function FreeAndAssignedArticleDtoFromJSON(json: any): FreeAndAssignedArticleDto {
    return FreeAndAssignedArticleDtoFromJSONTyped(json, false);
}

export function FreeAndAssignedArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreeAndAssignedArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'freeArticles': !exists(json, 'freeArticles') ? undefined : (json['freeArticles'] === null ? null : (json['freeArticles'] as Array<any>).map(ArticleDtoFromJSON)),
        'assignedArticles': !exists(json, 'assignedArticles') ? undefined : (json['assignedArticles'] === null ? null : (json['assignedArticles'] as Array<any>).map(ArticleDtoFromJSON)),
    };
}

export function FreeAndAssignedArticleDtoToJSON(value?: FreeAndAssignedArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'freeArticles': value.freeArticles === undefined ? undefined : (value.freeArticles === null ? null : (value.freeArticles as Array<any>).map(ArticleDtoToJSON)),
        'assignedArticles': value.assignedArticles === undefined ? undefined : (value.assignedArticles === null ? null : (value.assignedArticles as Array<any>).map(ArticleDtoToJSON)),
    };
}

