/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectorSubCategoryDto,
    SelectorSubCategoryDtoFromJSON,
    SelectorSubCategoryDtoFromJSONTyped,
    SelectorSubCategoryDtoToJSON,
} from './SelectorSubCategoryDto';

/**
 * 
 * @export
 * @interface SelectorProductHighlightDto
 */
export interface SelectorProductHighlightDto {
    /**
     * 
     * @type {number}
     * @memberof SelectorProductHighlightDto
     */
    id?: number;
    /**
     * 
     * @type {SelectorSubCategoryDto}
     * @memberof SelectorProductHighlightDto
     */
    selectorSubCategory?: SelectorSubCategoryDto;
}

export function SelectorProductHighlightDtoFromJSON(json: any): SelectorProductHighlightDto {
    return SelectorProductHighlightDtoFromJSONTyped(json, false);
}

export function SelectorProductHighlightDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorProductHighlightDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'selectorSubCategory': !exists(json, 'selectorSubCategory') ? undefined : SelectorSubCategoryDtoFromJSON(json['selectorSubCategory']),
    };
}

export function SelectorProductHighlightDtoToJSON(value?: SelectorProductHighlightDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'selectorSubCategory': SelectorSubCategoryDtoToJSON(value.selectorSubCategory),
    };
}

