/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PricesRequest
 */
export interface PricesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PricesRequest
     */
    orderingCodes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PricesRequest
     */
    originalPlusCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PricesRequest
     */
    generateOrderingCodes?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PricesRequest
     */
    excludedPlusCodes?: Array<string> | null;
}

export function PricesRequestFromJSON(json: any): PricesRequest {
    return PricesRequestFromJSONTyped(json, false);
}

export function PricesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCodes': !exists(json, 'orderingCodes') ? undefined : json['orderingCodes'],
        'originalPlusCode': !exists(json, 'originalPlusCode') ? undefined : json['originalPlusCode'],
        'generateOrderingCodes': !exists(json, 'generateOrderingCodes') ? undefined : json['generateOrderingCodes'],
        'excludedPlusCodes': !exists(json, 'excludedPlusCodes') ? undefined : json['excludedPlusCodes'],
    };
}

export function PricesRequestToJSON(value?: PricesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCodes': value.orderingCodes,
        'originalPlusCode': value.originalPlusCode,
        'generateOrderingCodes': value.generateOrderingCodes,
        'excludedPlusCodes': value.excludedPlusCodes,
    };
}

