import {
  createGenericSaga,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const productComparisonSagas = {
  compareProducts: createGenericSaga(
    "productComparisonSagas/compareProducts",
    getApiRegistry().productComparatorApi.apiProductComparatorCompareProductsGet.bind(
      getApiRegistry().productComparatorApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getProductsNames: createGenericSaga(
    "productComparisonSagas/getProductsNames",
    getApiRegistry().productComparatorApi.apiProductComparatorProductsNamesGet.bind(
      getApiRegistry().productComparatorApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
