/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModificationRecord
 */
export interface ModificationRecord {
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    serialNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    modificationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    compositionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    createdOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationRecord
     */
    plusCodes?: string | null;
}

export function ModificationRecordFromJSON(json: any): ModificationRecord {
    return ModificationRecordFromJSONTyped(json, false);
}

export function ModificationRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'modificationId': !exists(json, 'modificationId') ? undefined : json['modificationId'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'compositionId': !exists(json, 'compositionId') ? undefined : json['compositionId'],
        'createdOn': !exists(json, 'createdOn') ? undefined : json['createdOn'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'plusCodes': !exists(json, 'plusCodes') ? undefined : json['plusCodes'],
    };
}

export function ModificationRecordToJSON(value?: ModificationRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'name': value.name,
        'modificationId': value.modificationId,
        'compositionCode': value.compositionCode,
        'compositionId': value.compositionId,
        'createdOn': value.createdOn,
        'status': value.status,
        'plusCodes': value.plusCodes,
    };
}

