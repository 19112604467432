/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HandlePrivacyConsentCommand
 */
export interface HandlePrivacyConsentCommand {
    /**
     * 
     * @type {boolean}
     * @memberof HandlePrivacyConsentCommand
     */
    consentGiven: boolean;
    /**
     * 
     * @type {string}
     * @memberof HandlePrivacyConsentCommand
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HandlePrivacyConsentCommand
     */
    surname?: string | null;
}

export function HandlePrivacyConsentCommandFromJSON(json: any): HandlePrivacyConsentCommand {
    return HandlePrivacyConsentCommandFromJSONTyped(json, false);
}

export function HandlePrivacyConsentCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlePrivacyConsentCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consentGiven': json['consentGiven'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
    };
}

export function HandlePrivacyConsentCommandToJSON(value?: HandlePrivacyConsentCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consentGiven': value.consentGiven,
        'givenName': value.givenName,
        'surname': value.surname,
    };
}

