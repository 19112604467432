import React, { memo } from "react";
import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";
import { Icon } from "@abb/abb-common-ux-react/components/Icon";
import { Button } from "framework/components/Button";
import { ShoppingCartItemDto } from "api";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import { createGmdModificationCaseRequest } from "applications/common/reducers/modificationSaleReducer";
import { commonUXTheme } from "styles/commonUXVariables";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { RequestStatus } from "framework/state/requestStatus";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const PriceContainer = styled.div`
  position: relative;
  width: fit-content;
  height: ${(props) => props.theme.sizes.l};
  margin-top: 0px;
`;

export const PriceText = styled.p<{ lineThrough?: boolean | null; isBolded?: boolean }>`
  margin: 0;
  font-family: ${(props) =>
    props.isBolded
      ? props.theme.fonts.families.fontAbbBold
      : props.theme.fonts.families.fontAbbRegular};
  text-decoration: ${(props) => (props.lineThrough ? "line-through" : undefined)};
  line-height: 32px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.sm};
  height: max-content;
  justify-content: flex-end;
  align-items: center;
`;

const CustomIcon = styled(Icon)`
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.grey70};
  }
  color: ${(props) => props.theme.colors.blackPrimary};
  margin: 0;
`;

export const PriceRedDot = styled.div`
  position: absolute;
  bottom: 15px;
  right: -5px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  border: ${(props) => `${props.theme.borders.widths.borderWidthS} ${props.theme.colors.brandRed}`};
  background: ${(props) => props.theme.colors.brandRed};
  margin-top: 0px;
`;

const AccessoriesLinkButton = styled(Button)`
  margin-left: ${(props) => props.theme.sizes.sm};
  :hover {
    text-decoration: none !important;
  }
`;

const ModificationButton = styled(Button)`
  margin-top: 0;
`;

export interface GridPriceAndActionsRendererProps extends ICellRendererParams {
  price: string | null | undefined;
  quotedPrice: string | null | undefined;
  t?: TFunction;
  isModificationItem?: boolean;
  onDelete?: (id: number, orderingCode?: string) => void;
  onCreateModificationCase?: (description: string) => void;
  accessoriesLink?: string;
  openEditDialogAndSetSelectedShoppingCartItem?: (shoppingCartItem: ShoppingCartItemDto) => void;
  isBold?: boolean;
  onShowInvalidModificationProductDialog?: () => void | undefined;
}

export const GridPriceAndActionsRenderer = memo((props: GridPriceAndActionsRendererProps) => {
  const modificationSaleRequest = useSelector(createGmdModificationCaseRequest);

  return (
    <Container>
      <div>
        {props.context?.hidePrices ? (
          <PriceContainer>
            <PriceText>{props.t ? props.t("(Hidden)") : "(Hidden)"}</PriceText>
          </PriceContainer>
        ) : (
          <>
            {props.price && parseFloat(props.price) !== 0 && (
              <PriceContainer>
                <PriceText
                  lineThrough={!!props.quotedPrice && props.quotedPrice !== props.price}
                  isBolded={props.isBold}
                >
                  {props.price}
                </PriceText>
                {props.quotedPrice && props.quotedPrice !== props.price ? <PriceRedDot /> : null}
              </PriceContainer>
            )}
            {props.quotedPrice && props.quotedPrice !== props.price && (
              <PriceContainer>
                <PriceText isBolded={props.isBold}>{props.quotedPrice}</PriceText>
              </PriceContainer>
            )}
          </>
        )}
      </div>
      <ActionsWrapper>
        {props.accessoriesLink && props.t ? (
          <AccessoriesLinkButton
            buttonType="secondary"
            href={props.accessoriesLink}
            text={props.t("Add accessories")}
            sizeClass="small"
          />
        ) : (
          <div></div>
        )}
        {props.data.hasPendingModification && props.t ? (
          <WithTooltip>
            <ModificationButton
              buttonType={"primary"}
              sizeClass="small"
              text={props.t("Create a modification case")}
              onClick={() => {
                if (props.onCreateModificationCase && props.t) {
                  props.onCreateModificationCase(
                    `${props.data.displayName} ${props.t("modification")}`
                  ); // static/default value: see wi 6542
                }
              }}
              disabled={props.context?.hidePrices}
              isLoading={modificationSaleRequest.status === RequestStatus.Pending}
            />
            <Tooltip disabled={!props.context?.hidePrices}>
              <p>{props.t("Disabled when prices are hidden. Select a price list.")}</p>
            </Tooltip>
          </WithTooltip>
        ) : null}
        {!props.isModificationItem && props.openEditDialogAndSetSelectedShoppingCartItem ? (
          <CustomIcon
            name={"abb/edit"}
            onClick={() =>
              props.openEditDialogAndSetSelectedShoppingCartItem?.(
                props.data as ShoppingCartItemDto
              )
            }
            sizeClass={"medium"}
          />
        ) : null}
        {props.onDelete ? (
          <CustomIcon
            name={"abb/trash"}
            onClick={() => {
              props.onDelete?.(props.data.id, props.data.orderingCode);
            }}
            sizeClass={"medium"}
          />
        ) : null}
        {props.onShowInvalidModificationProductDialog ? (
          <CustomIcon
            color={commonUXTheme.colors.statusRed}
            name={"abb/warning-circle-1"}
            onClick={() => {
              props.onShowInvalidModificationProductDialog?.();
            }}
            sizeClass={"medium"}
          />
        ) : null}
      </ActionsWrapper>
    </Container>
  );
});
