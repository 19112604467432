import { OrderSearchFields } from "../components/OrderSearchForm";
import { OrdersViewActions, OrdersViewActionTypes } from "../actions/ordersViewActions";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { ApiOrderGetRequest, OrderMetaDataVm } from "../../../../api";
import { OrderSearchStatus } from "../../../../api/models/OrderSearchStatus";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { orderSagas } from "../sagas/orderSagas";
import {
  ChangeSelectedCompany,
  DeliveriesHomeViewActionTypes
} from "../../deliveriesHome/actions/deliveriesHomeViewActions";

interface Requests {
  getOrderMetaData: RequestState<OrderMetaDataVm>;
}

export interface OrdersViewState {
  orderSearchFields: OrderSearchFields;
  orderCriteria: ApiOrderGetRequest;
  selectedOrderRowId: number | undefined;
  orderMetaData: OrderMetaDataVm;
  requests: Requests;
}

export const defaultOrderCriteria: ApiOrderGetRequest = {
  criteriaIsAscendingOrder: false,
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaSortColumn: "created"
};

export const orderSearchFormDefaultFields: OrderSearchFields = {
  order: undefined,
  orderingCode: undefined,
  trackingNumber: undefined,
  status: OrderSearchStatus.All,
  dateFrom: undefined,
  dateTo: undefined,
  icvCode: undefined,
  countryCode: undefined,
  limitedSearch: false,
  customerCode: undefined,
  plantId: undefined,
  materialCode: undefined
};

const defaultState: OrdersViewState = {
  orderSearchFields: orderSearchFormDefaultFields,
  orderCriteria: defaultOrderCriteria,
  selectedOrderRowId: undefined,
  orderMetaData: { countryCodes: [], plants: [] },
  requests: {
    getOrderMetaData: createInitialRequest()
  }
};

export function ordersViewReducer(
  state: OrdersViewState = defaultState,
  action: OrdersViewActions | ChangeSelectedCompany
): OrdersViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: orderSagas.getOrderMetaData.actionTypes,
      key: "orderMetaData"
    }
  ]);

  if (orderSagas.getOrderMetaData.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.orderMetaData = action.payload;
    });
  }

  switch (action.type) {
    case OrdersViewActionTypes.ChangeOrderSearchFields:
      state = produce(state, (draft) => {
        draft.orderSearchFields = action.fields;
        if (action.storeGridIndex) {
          draft.selectedOrderRowId = action.gridIndex;
        }
      });
      break;
    case OrdersViewActionTypes.ChangeOrderCriteria:
      state = produce(state, (draft) => {
        draft.orderCriteria = action.criteria;
      });
      break;
    case OrdersViewActionTypes.StoreClickedGridIndex:
      state = produce(state, (draft) => {
        draft.selectedOrderRowId = action.index;
      });
      break;
    case DeliveriesHomeViewActionTypes.ChangeSelectedCompany:
      state = produce(state, (draft) => {
        draft.selectedOrderRowId = undefined;
      });
      break;
  }
  return state;
}

export function selectOrderCriteria(state: AppState): ApiOrderGetRequest {
  return state.deliveries.ordersView.orderCriteria;
}

export function selectOrderSearchFields(state: AppState): OrderSearchFields {
  return state.deliveries.ordersView.orderSearchFields;
}

export function selectSelectedOrderRowId(state: AppState): number | undefined {
  return state.deliveries.ordersView.selectedOrderRowId;
}

export function selectOrderMetaData(state: AppState): OrderMetaDataVm {
  return state.deliveries.ordersView.orderMetaData;
}
