import {
  PriceProductVm,
  ShoppingCartDto,
  QuoteDto,
  QuoteAttachmentDto,
  PriceProductDto
} from "api";
import { Action } from "redux";

export enum ShoppingCartActionTypes {
  SetPriceProductVm = "shoppingCart/SetPriceProductVm",
  SetShoppingCart = "shoppingCart/SetShoppingCart",
  SetQuote = "shoppingCart/SetQuote",
  UpdateLineItem = "quote/UpdateLineItem",
  InitializeQuotedPrices = "shoppingCart/InitializeQuotedPrices",
  SetRedirectToOrderDetails = "shoppingCart/SetRedirectToOrderDetails",
  SetQuoteAttachments = "shoppingCart/SetQuoteAttachments",
  SetDraftQuoteFiles = "shoppingCart/SetDraftQuoteFiles",
  SetOpenRegistrationRequired = "shoppingCart/SetOpenRegistrationRequired",
  AddLineItem = "quote/AddLineItem",
  RemoveLineItem = "quote/RemoveLineItem"
}

export interface InitializeQuotedPrices extends Action {
  type: ShoppingCartActionTypes.InitializeQuotedPrices;
}

export interface SetPriceProductVm extends Action {
  type: ShoppingCartActionTypes.SetPriceProductVm;
  priceProductVm: PriceProductVm | undefined;
}

export interface SetShoppingCart extends Action {
  type: ShoppingCartActionTypes.SetShoppingCart;
  shoppingCart: ShoppingCartDto | undefined;
}

export interface SetQuote extends Action {
  type: ShoppingCartActionTypes.SetQuote;
  quote: QuoteDto | undefined;
}

export interface UpdateLineItem extends Action {
  type: ShoppingCartActionTypes.UpdateLineItem;
  orderingCode: string;
  infoType: string;
  discountRate: number | null | undefined;
  unitQuotedPrice: number | null | undefined;
  isQuote: boolean;
  quantity: number;
  additionalInformation: string;
}

export interface SetRedirectToOrderDetails extends Action {
  type: ShoppingCartActionTypes.SetRedirectToOrderDetails;
  value: boolean;
}

export interface SetQuoteAttachments extends Action {
  type: ShoppingCartActionTypes.SetQuoteAttachments;
  attachments: QuoteAttachmentDto[];
}

export interface SetDraftQuoteFiles extends Action {
  type: ShoppingCartActionTypes.SetDraftQuoteFiles;
  quoteFiles: File[];
}

export interface SetOpenRegistrationRequired extends Action {
  type: ShoppingCartActionTypes.SetOpenRegistrationRequired;
  isOpen: boolean;
}

export interface AddLineItem extends Action {
  type: ShoppingCartActionTypes.AddLineItem;
  priceProduct: PriceProductDto | undefined;
  isQuote: boolean;
  additionalInformation: string;
}

export interface RemoveLineItem extends Action {
  type: ShoppingCartActionTypes.RemoveLineItem;
  isQuote: boolean;
  orderingCode: string;
}

export const shoppingCartActions = {
  setPriceProductVm: (priceProductVm: PriceProductVm | undefined): SetPriceProductVm => ({
    type: ShoppingCartActionTypes.SetPriceProductVm,
    priceProductVm
  }),
  setShoppingCart: (shoppingCart: ShoppingCartDto | undefined): SetShoppingCart => ({
    type: ShoppingCartActionTypes.SetShoppingCart,
    shoppingCart
  }),
  setQuote: (quote: QuoteDto | undefined): SetQuote => ({
    type: ShoppingCartActionTypes.SetQuote,
    quote
  }),
  updateLineItem: (
    orderingCode: string,
    infoType: string,
    discountRate: number | null | undefined,
    unitQuotedPrice: number | null | undefined,
    isQuote: boolean,
    quantity: number,
    additionalInformation: string
  ): UpdateLineItem => ({
    type: ShoppingCartActionTypes.UpdateLineItem,
    orderingCode,
    infoType,
    discountRate,
    unitQuotedPrice,
    isQuote,
    quantity,
    additionalInformation
  }),
  initializeQuotedPrices: (): InitializeQuotedPrices => ({
    type: ShoppingCartActionTypes.InitializeQuotedPrices
  }),
  setRedirectToOrderDetails: (value: boolean): SetRedirectToOrderDetails => ({
    type: ShoppingCartActionTypes.SetRedirectToOrderDetails,
    value
  }),
  setQuoteAttachments: (attachments: QuoteAttachmentDto[]): SetQuoteAttachments => ({
    type: ShoppingCartActionTypes.SetQuoteAttachments,
    attachments
  }),
  setDraftQuoteFiles: (quoteFiles: File[]): SetDraftQuoteFiles => ({
    type: ShoppingCartActionTypes.SetDraftQuoteFiles,
    quoteFiles
  }),
  setOpenRegistrationRequired: (isOpen: boolean): SetOpenRegistrationRequired => ({
    type: ShoppingCartActionTypes.SetOpenRegistrationRequired,
    isOpen
  }),
  addLineItem: (
    priceProduct: PriceProductDto | undefined,
    isQuote: boolean,
    additionalInformation: string
  ): AddLineItem => ({
    type: ShoppingCartActionTypes.AddLineItem,
    priceProduct,
    isQuote,
    additionalInformation
  }),
  removeLineItem: (orderingCode: string, isQuote: boolean): RemoveLineItem => ({
    type: ShoppingCartActionTypes.RemoveLineItem,
    orderingCode,
    isQuote
  })
};

export type ShoppingCartActions =
  | SetPriceProductVm
  | SetShoppingCart
  | SetQuote
  | UpdateLineItem
  | InitializeQuotedPrices
  | SetRedirectToOrderDetails
  | SetQuoteAttachments
  | SetDraftQuoteFiles
  | SetOpenRegistrationRequired
  | AddLineItem
  | RemoveLineItem;
