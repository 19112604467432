import {
  AccessRights,
  AddOrUpdateShoppingCartItemsCommand,
  ApiModificationSalesCreateGmdModificationCasePostRequest,
  CompanyDto,
  QuoteStateTypes,
  ShoppingCartDto,
  ShoppingCartItemDto
} from "api";
import { ShoppingCartTabs } from "enums/shoppingCartTabs";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dispatch } from "redux";
import { CartName } from "../components/CartName";
import { ShoppingCartGrid } from "../components/ShoppingCartGrid";
import { TopSection } from "../components/TopSection";
import styled, { useTheme } from "styled-components";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { TFunction } from "i18next";
import { useDynamicCallback } from "framework/hooks/useDynamicCallback";
import { Button } from "framework/components/Button";
import { useSelector } from "react-redux";
import {
  getCreateShoppingCartStatus,
  getDisableQuoting,
  getQuote
} from "applications/common/reducers/shoppingCartReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { userActions } from "applications/common/actions/userActions";
import { WithPopup, Popup, WithTooltip, Tooltip } from "@abb/abb-common-ux-react";
import { GridApi, IRowNode } from "ag-grid-community";
import { routes } from "utilities/routes";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { hasAnyAccessRight } from "utilities/authUtils";
import { modificationSaleSagas } from "applications/common/sagas/modificationSaleSagas";
import { deliveriesHomeSagas } from "applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import {
  currentUserIsQuoteRequester,
  isModificationPending
} from "applications/shoppingCart/helpers";
import { PillLink } from "framework/components/PillLink";
import { QuoteAttachmentsUploader } from "applications/manage/manageQuotes/components/QuoteAttachmentsUploader";
import { toggleShoppingCartPrintLayout } from "utilities/gridUtils";
import { PrintButton } from "framework/components/PrintButton";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";
import { FormikProps } from "formik";
import { shoppingCartActions } from "applications/common/actions/shoppingCartActions";
import { ReviseMode } from "./ShoppingCartView";
import { StatusBanner } from "framework/components/StatusBanner";

const FlexRowWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PrintWrapper = styled.div`
  display: contents;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.sizes.lm};
`;

const HideInPrintWrapper = styled.div`
  display: contents;
  @media print {
    display: none;
  }
`;

const QuoteAttachmentsContainer = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.sizes.xxl};
  @media print {
    margin-block: ${(props) => props.theme.sizes.m};
  }
`;

const NameLabelContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

interface TabContainerProps {
  shoppingCart: ShoppingCartDto | undefined;
  dispatch: Dispatch;
  openEditDialogAndSetSelectedShoppingCartItem: (shoppingCartItem: ShoppingCartItemDto) => void;
  t: TFunction;
  activeTab: ShoppingCartTabs;
  isLoading: boolean;
  isReadOnly: boolean;
  countryCodeToCheck: string | null | undefined;
  selectedCompany: CompanyDto | undefined;
  openRequestQuotation?: (isSelfQuote: boolean) => void;
  asmFunctionalityButton?: JSX.Element | null | undefined;
  orderDetailsSection?: JSX.Element | null;
  quoteAttachmentsPanelIsVisible: boolean;
  reviseMode: ReviseMode;
  setIsReviseMode?: (value: boolean) => void;
  formikRevise?: FormikProps<ReviseQuoteFromCart>;
  showRequestRevisionButton?: boolean;
  setIsRequestRevisionDialogOpen?: (value: boolean) => void;
}

export const TabContentContainer = ({
  shoppingCart,
  dispatch,
  openEditDialogAndSetSelectedShoppingCartItem,
  t,
  activeTab,
  isLoading,
  isReadOnly,
  countryCodeToCheck,
  openRequestQuotation,
  selectedCompany,
  asmFunctionalityButton,
  orderDetailsSection,
  quoteAttachmentsPanelIsVisible,
  reviseMode,
  setIsReviseMode,
  formikRevise,
  showRequestRevisionButton,
  setIsRequestRevisionDialogOpen
}: TabContainerProps): JSX.Element => {
  const theme = useTheme();

  const createShoppingCartStatus = useSelector(getCreateShoppingCartStatus);
  const userAccessRights = useSelector(getUserAccessRights);
  const disableQuoting = useSelector(getDisableQuoting);
  const userInformation = useSelector(getUserInformation);
  const quote = useSelector(getQuote);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [isPrinting, setIsPrinting] = useState(false);
  const detailsRef = useRef(null);
  const gridContainerRef = useRef(null);
  const attachmentsRef = useRef(null);

  isReadOnly = isPrinting ? true : isReadOnly;

  useEffect(() => {
    if (gridApi) {
      toggleShoppingCartPrintLayout(isPrinting, gridApi, shoppingCart);
    }
  }, [gridApi, isPrinting, shoppingCart]);

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, gridApi]);

  const createNewCart = () => {
    dispatch(
      userActions.addConfirmEvent(
        () =>
          dispatch(
            shoppingCartSagas.createShoppingCart.createAction({
              createShoppingCartCommand: { managedPriceListId: shoppingCart?.managedPriceListId }
            })
          ),
        t("Create a new cart"),
        t(
          "New shopping cart will replace your current active cart.\n\nYour current cart can then be found in shopping carts view. Are you sure?"
        )
      )
    );
  };

  const updateShoppingCartItem = (
    orderingCode: string,
    infoType: string,
    discountRate: number | null | undefined,
    unitQuotedPrice: number | null | undefined,
    quantity: number,
    additionalInformation: string
  ) => {
    if (shoppingCart) {
      if (reviseMode == ReviseMode.Complete) {
        dispatch(
          shoppingCartActions.updateLineItem(
            orderingCode,
            infoType,
            discountRate,
            unitQuotedPrice,
            false,
            quantity,
            additionalInformation
          )
        );
      } else {
        const addOrUpdateShoppingCartItemsCommand: AddOrUpdateShoppingCartItemsCommand = {
          shoppingCartGuid: shoppingCart.guid,
          isAddToQuantity: false,
          shoppingCartItems: [
            { quantity: quantity, orderingCode: orderingCode, additionalInformation }
          ]
        };

        dispatch(
          shoppingCartSagas.addOrUpdateItemsInShoppingCart.createAction({
            addOrUpdateShoppingCartItemsCommand
          })
        );
      }
    }
  };

  const deleteShoppingCartItem = useCallback(
    (id: number, orderingCode?: string) => {
      if (reviseMode == ReviseMode.Complete && orderingCode) {
        dispatch(
          userActions.addConfirmEvent(
            () => dispatch(shoppingCartActions.removeLineItem(orderingCode, false)),
            t("Remove shopping cart item"),
            t("Are you sure you want to delete this shopping cart item?")
          )
        );
      } else {
        dispatch(
          userActions.addConfirmEvent(
            () => dispatch(shoppingCartSagas.deleteShoppingCartItem.createAction({ id })),
            t("Remove shopping cart item"),
            t("Are you sure you want to delete this shopping cart item?")
          )
        );
      }
    },
    [dispatch, reviseMode, t]
  );

  const deleteShoppingCartModificationItem = useCallback(
    (id: number) => {
      dispatch(
        userActions.addConfirmEvent(
          () => dispatch(shoppingCartSagas.deleteShoppingCartModificationItem.createAction({ id })),
          t("Remove shopping cart modification item"),
          t("Are you sure you want to delete this shopping cart modification item?")
        )
      );
    },
    [dispatch, t]
  );

  const deleteShoppingCartModificationItemSerialNumber = useCallback(
    (id: number, serialNumber: string) => {
      dispatch(
        userActions.addConfirmEvent(
          () =>
            dispatch(
              shoppingCartSagas.deleteShoppingCartModificationItemSerialNumber.createAction({
                id,
                serialNumber
              })
            ),
          t("Remove serial number"),
          t("Are you sure you want to delete this serial number?")
        )
      );
    },
    [dispatch, t]
  );

  const openInvalidModificationProductDialog = useCallback(() => {
    dispatch(
      userActions.addConfirmEvent(
        () => {
          return null;
        },
        t("Invalid modification product"),
        t(
          "The original pluscode which is imported from this serial number is invalid. Please select another serial number."
        )
      )
    );
  }, [dispatch, t]);

  const createModificationCase = useCallback(
    (id: number, description: string) => {
      if (id) {
        const createGmdModificationCaseRequestParams: ApiModificationSalesCreateGmdModificationCasePostRequest =
          {
            createGmdModificationCaseCommand: {
              shoppingCartItemId: id,
              description: description
            }
          };
        dispatch(
          modificationSaleSagas.createGmdModificationCase.createAction(
            createGmdModificationCaseRequestParams
          )
        );
      }
    },
    [dispatch]
  );

  const dynamicUpdateShoppingCartItem = useDynamicCallback(updateShoppingCartItem);

  useEffect(() => {
    if (gridApi) {
      gridApi?.forEachNode((rowNode: IRowNode<ShoppingCartItemDto>) => {
        if (rowNode.data?.isModification) {
          rowNode.setExpanded(true);
        }
      });
    }
  }, [gridApi, shoppingCart]);

  // Get system settings
  useEffect(() => {
    dispatch(deliveriesHomeSagas.getSystemSettings.createAction(undefined));
  }, [dispatch]);

  const isLocalHandler = useMemo(
    () =>
      hasAnyAccessRight(
        countryCodeToCheck,
        userAccessRights,
        AccessRights.LocalQuoteHandler,
        AccessRights.LocalContactRegionalSales,
        AccessRights.Pms,
        AccessRights.ManagePriceLists
      ),
    [countryCodeToCheck, userAccessRights]
  );

  const isASM = useMemo(
    () =>
      hasAnyAccessRight(
        countryCodeToCheck,
        userAccessRights,
        AccessRights.ManageMarginAnalysisToolQuotesAsAsm
      ),
    [countryCodeToCheck, userAccessRights]
  );

  const lockPriceListSelection = () => {
    if (shoppingCart?.isBasedOnFrameAgreement) {
      return true;
    }

    if (shoppingCart?.shoppingCartItems) {
      return shoppingCart.shoppingCartItems.some(
        (item: ShoppingCartItemDto) => item.isModification && !item.hasPendingModification
      );
    }
    return false;
  };

  const hasPendingModification = useMemo(() => isModificationPending(shoppingCart), [shoppingCart]);

  const demoMode = userInformation?.demoMode || false;
  const hidePrices = !shoppingCart?.managedPriceListId || demoMode;

  // Always enabled on frame agreements, to prevent case where use can't add products
  // to current cart, but also can't create new cart.
  const newCartIsEnabled = shoppingCart?.isBasedOnFrameAgreement
    ? true
    : (shoppingCart?.shoppingCartItems?.length ?? 0) > 0;

  const hideBuildMatQuoteButton = shoppingCart?.isBasedOnFrameAgreement;

  const isUploadingAttachmentsEnabled = useMemo((): boolean => {
    return (
      !!quote &&
      currentUserIsQuoteRequester(quote, userInformation) &&
      activeTab === ShoppingCartTabs.BuildCart
    );
  }, [activeTab, quote, userInformation]);

  const isRemovingAttachmentsEnabled = useMemo((): boolean => {
    return (
      !!quote &&
      currentUserIsQuoteRequester(quote, userInformation) &&
      quote.stateId !== QuoteStateTypes.Quote &&
      activeTab === ShoppingCartTabs.BuildCart
    );
  }, [activeTab, quote, userInformation]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {shoppingCart && (
          <NameLabelContainer>
            {shoppingCart?.isBasedOnFrameAgreement
              ? t("Frame agreement or project name")
              : shoppingCart?.activeQuote != null
              ? t("Quote or project name")
              : t("Shopping cart or project name")}
          </NameLabelContainer>
        )}
        <Wrapper>
          <LeftSideWrapper>
            <CartName
              projectName={shoppingCart?.projectName}
              dispatch={dispatch}
              shoppingCartGuid={shoppingCart?.guid}
              isReadOnly={isReadOnly}
            />
            {setIsReviseMode && (
              <>
                <Button
                  buttonType={"secondary"}
                  text={t("Edit")}
                  icon="abb/edit"
                  onClick={() => setIsReviseMode(true)}
                  disabled={reviseMode != ReviseMode.None}
                />
                {reviseMode != ReviseMode.None && (
                  <Button
                    style={{ marginLeft: theme.sizes.xs }}
                    buttonType="discreet"
                    text={t("Cancel")}
                    icon="abb/close"
                    onClick={() => {
                      setIsReviseMode(false);
                      if (shoppingCart) {
                        dispatch(
                          shoppingCartSagas.getShoppingCartById.createAction({
                            guid: shoppingCart.guid
                          })
                        );
                      }
                    }}
                  />
                )}
              </>
            )}
            {showRequestRevisionButton && setIsRequestRevisionDialogOpen && (
              <>
                <Button
                  style={{ marginLeft: theme.sizes.xs }}
                  buttonType="secondary"
                  text={t("Request revision")}
                  icon="abb/edit"
                  onClick={() => {
                    setIsRequestRevisionDialogOpen(true);
                  }}
                />
              </>
            )}
            <PrintButton
              t={t}
              componentsToPrint={[detailsRef, attachmentsRef, gridContainerRef]}
              documentTitle={`${t("ShoppingCart")}-${shoppingCart?.reference ?? ""}`}
              isPrintLoading={isPrinting}
              setIsPrintLoading={setIsPrinting}
              timeoutMilliseconds={2000}
              setDetailGridStyles
            />
            {shoppingCart?.frameAgreementReference && (
              <p>
                {t("Created from {{frameAgreementReference}}", {
                  frameAgreementReference: shoppingCart.frameAgreementReference
                })}
              </p>
            )}
          </LeftSideWrapper>
          <HideInPrintWrapper>{asmFunctionalityButton}</HideInPrintWrapper>
          {!isReadOnly ? (
            <FlexRowWrapper>
              {isASM &&
                !hideBuildMatQuoteButton &&
                (selectedCompany?.isInternal || selectedCompany?.isMaintainedCustomer) &&
                (hidePrices ? (
                  <WithTooltip>
                    <Button
                      buttonType="primary-black"
                      text={t("Build MAT quote")}
                      icon="abb/plus"
                      onClick={() => openRequestQuotation?.(true)}
                      sizeClass="small"
                      disabled={true}
                    />
                    <Tooltip>
                      {demoMode
                        ? t("Cannot build MAT quote while in demo mode")
                        : t("Cannot build MAT quote when prices are hidden")}
                    </Tooltip>
                  </WithTooltip>
                ) : (
                  <Button
                    buttonType="primary-black"
                    text={t("Build MAT quote")}
                    icon="abb/plus"
                    onClick={() => openRequestQuotation?.(true)}
                    sizeClass="small"
                    disabled={disableQuoting || hasPendingModification}
                  />
                ))}
              {(isLocalHandler || isASM) &&
                !selectedCompany?.isInternal &&
                !selectedCompany?.isMaintainedCustomer &&
                (hidePrices ? (
                  <WithTooltip>
                    <PillLink
                      type="primary-black"
                      icon="abb/plus"
                      to={`${routes.manage.quote}?cart=${shoppingCart?.guid}`}
                      size="small"
                      disabled={true}
                    >
                      {t("Build quote")}
                    </PillLink>
                    <Tooltip>
                      {demoMode
                        ? t("Building a quote is disabled while in demo mode")
                        : t("Building a quote is disabled while prices are hidden")}
                    </Tooltip>
                  </WithTooltip>
                ) : (
                  <PillLink
                    type="primary-black"
                    icon="abb/plus"
                    to={`${routes.manage.quote}?cart=${shoppingCart?.guid}`}
                    size="small"
                    disabled={disableQuoting || hasPendingModification}
                  >
                    {quote?.stateId === QuoteStateTypes.Draft
                      ? t("Continue building quote")
                      : t("Build quote")}
                  </PillLink>
                ))}
              <WithPopup>
                <div>
                  <Button
                    buttonType="secondary"
                    text={t("New cart")}
                    icon="abb/plus"
                    onClick={() => createNewCart()}
                    sizeClass="small"
                    isLoading={createShoppingCartStatus === RequestStatus.Pending}
                    disabled={!newCartIsEnabled}
                  />
                </div>
                <Popup
                  trigger="hover"
                  disabled={newCartIsEnabled}
                  position={["top center", "top left"]}
                >
                  <p style={{ margin: "0px" }}>
                    {t("Creating new cart only allowed when cart is not empty")}
                  </p>
                </Popup>
              </WithPopup>
            </FlexRowWrapper>
          ) : null}
        </Wrapper>
        <FlexRowWrapper>
          <div style={{ width: "100%" }}>
            {reviseMode == ReviseMode.Partial ? (
              <div>
                <StatusBanner
                  status="information"
                  text={t(
                    "After the first order the only field editable is the validity date. If you need to change other fields, please create a new frame agreement."
                  )}
                  icon={"abb/information-circle-2"}
                  shape={"long"}
                />
              </div>
            ) : undefined}
            {shoppingCart && (
              <PrintWrapper ref={detailsRef}>
                <TopSection
                  shoppingCart={shoppingCart}
                  dispatch={dispatch}
                  t={t}
                  icon={"abb/lock-closed"}
                  showPriceListDropdown={!isReadOnly && !demoMode}
                  lockPriceListSelection={lockPriceListSelection()}
                  orderDetailsSection={orderDetailsSection}
                  reviseMode={reviseMode}
                  formik={formikRevise}
                />
              </PrintWrapper>
            )}
            <PrintWrapper ref={gridContainerRef}>
              <ShoppingCartGrid
                shoppingCartState={shoppingCart?.stateId}
                shoppingCartReference={shoppingCart?.reference}
                setGridApi={setGridApi}
                key={activeTab}
                isReadOnly={isReadOnly}
                openEditDialogAndSetSelectedShoppingCartItem={
                  openEditDialogAndSetSelectedShoppingCartItem
                }
                t={t}
                updateShoppingCartItem={dynamicUpdateShoppingCartItem}
                deleteShoppingCartItem={deleteShoppingCartItem}
                deleteShoppingCartModificationItem={deleteShoppingCartModificationItem}
                deleteShoppingCartModificationItemSerialNumber={
                  deleteShoppingCartModificationItemSerialNumber
                }
                createModificationCase={createModificationCase}
                hidePrices={hidePrices}
                openInvalidModificationProductDialog={openInvalidModificationProductDialog}
                rowData={shoppingCart?.shoppingCartItems ?? []}
                isBasedOnFrameAgreement={shoppingCart?.isBasedOnFrameAgreement ?? false}
                reviseMode={reviseMode == ReviseMode.Complete}
                companyIsInternalOrMaintained={
                  shoppingCart?.company?.isMaintainedCustomer === true ||
                  shoppingCart?.company?.isInternal === true
                }
                shoppingCartIdentifier={shoppingCart?.guid ?? ""}
              />
            </PrintWrapper>
          </div>
        </FlexRowWrapper>
        <PrintWrapper ref={attachmentsRef}>
          {quoteAttachmentsPanelIsVisible ? (
            <QuoteAttachmentsContainer>
              <QuoteAttachmentsUploader
                isUploadingEnabled={isUploadingAttachmentsEnabled}
                isRemovingEnabled={isRemovingAttachmentsEnabled}
                isRequestQuotationDialogMode={false}
                isPrinting={isPrinting}
                reviseMode={reviseMode == ReviseMode.Complete}
              />
            </QuoteAttachmentsContainer>
          ) : undefined}
        </PrintWrapper>
      </>
    </div>
  );
};
