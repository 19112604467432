/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    ContactOrderHandlingCommand,
    ContactOrderHandlingCommandFromJSON,
    ContactOrderHandlingCommandToJSON,
    ContactOrderHandlingReasonDto,
    ContactOrderHandlingReasonDtoFromJSON,
    ContactOrderHandlingReasonDtoToJSON,
    ContactSalesSupportCommand,
    ContactSalesSupportCommandFromJSON,
    ContactSalesSupportCommandToJSON,
    ContactSupportTeamCommand,
    ContactSupportTeamCommandFromJSON,
    ContactSupportTeamCommandToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    DownloadOrderItemsFilesQuery,
    DownloadOrderItemsFilesQueryFromJSON,
    DownloadOrderItemsFilesQueryToJSON,
    GetOrderItemsQuery,
    GetOrderItemsQueryFromJSON,
    GetOrderItemsQueryToJSON,
    OnlineDeliveryVm,
    OnlineDeliveryVmFromJSON,
    OnlineDeliveryVmToJSON,
    OrderDetailsVm,
    OrderDetailsVmFromJSON,
    OrderDetailsVmToJSON,
    OrderDownloadNotificationsVm,
    OrderDownloadNotificationsVmFromJSON,
    OrderDownloadNotificationsVmToJSON,
    OrderItemsVm,
    OrderItemsVmFromJSON,
    OrderItemsVmToJSON,
    OrderLicensesVm,
    OrderLicensesVmFromJSON,
    OrderLicensesVmToJSON,
    OrderMetaDataVm,
    OrderMetaDataVmFromJSON,
    OrderMetaDataVmToJSON,
    OrderNotificationsMetadataVm,
    OrderNotificationsMetadataVmFromJSON,
    OrderNotificationsMetadataVmToJSON,
    OrderNotificationsVm,
    OrderNotificationsVmFromJSON,
    OrderNotificationsVmToJSON,
    OrderSearchStatus,
    OrderSearchStatusFromJSON,
    OrderSearchStatusToJSON,
    OrdersVm,
    OrdersVmFromJSON,
    OrdersVmToJSON,
    RemoveUploadedJustInTimeFileCommand,
    RemoveUploadedJustInTimeFileCommandFromJSON,
    RemoveUploadedJustInTimeFileCommandToJSON,
    ResetStatusDeliveryFailedJustInTimeFileCommand,
    ResetStatusDeliveryFailedJustInTimeFileCommandFromJSON,
    ResetStatusDeliveryFailedJustInTimeFileCommandToJSON,
    ShippingDetailDocumentTypes,
    ShippingDetailDocumentTypesFromJSON,
    ShippingDetailDocumentTypesToJSON,
    SoftwareSubscriptionsVm,
    SoftwareSubscriptionsVmFromJSON,
    SoftwareSubscriptionsVmToJSON,
    SupportContactedResult,
    SupportContactedResultFromJSON,
    SupportContactedResultToJSON,
    UnsubscribeSoftwareSubscriptionsCommand,
    UnsubscribeSoftwareSubscriptionsCommandFromJSON,
    UnsubscribeSoftwareSubscriptionsCommandToJSON,
} from '../models';

export interface ApiOrderContactOrderHandlingPostRequest {
    contactOrderHandlingCommand?: ContactOrderHandlingCommand;
}

export interface ApiOrderDetailsGetRequest {
    orderIdentifier?: string;
    fetchOnlyOrderLines?: boolean;
}

export interface ApiOrderDownloadNotificationsExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaSerialNumber?: string;
    criteriaOrder?: string;
    criteriaEmailSent?: boolean;
    criteriaEmailQueued?: boolean;
    criteriaOrderLinked?: boolean;
    criteriaCreatedFrom?: Date;
    criteriaCreatedTo?: Date;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiOrderDownloadNotificationsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaSerialNumber?: string;
    criteriaOrder?: string;
    criteriaEmailSent?: boolean;
    criteriaEmailQueued?: boolean;
    criteriaOrderLinked?: boolean;
    criteriaCreatedFrom?: Date;
    criteriaCreatedTo?: Date;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiOrderExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaOrder?: string;
    criteriaMaterialCode?: string;
    criteriaLimitedSearch?: boolean;
    criteriaOrderingCode?: string;
    criteriaTrackingNumber?: string;
    criteriaOrderStatus?: OrderSearchStatus;
    criteriaDateTimeFrom?: Date;
    criteriaDateTimeTo?: Date;
    criteriaEtcdIcvCode?: string;
    criteriaCountryCode?: string;
    criteriaCustomerCode?: string;
    criteriaPlantId?: number;
    criteriaFetchOrderLines?: boolean;
    criteriaCreateStatisticsEvent?: boolean;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiOrderGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaOrder?: string;
    criteriaMaterialCode?: string;
    criteriaLimitedSearch?: boolean;
    criteriaOrderingCode?: string;
    criteriaTrackingNumber?: string;
    criteriaOrderStatus?: OrderSearchStatus;
    criteriaDateTimeFrom?: Date;
    criteriaDateTimeTo?: Date;
    criteriaEtcdIcvCode?: string;
    criteriaCountryCode?: string;
    criteriaCustomerCode?: string;
    criteriaPlantId?: number;
    criteriaFetchOrderLines?: boolean;
    criteriaCreateStatisticsEvent?: boolean;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiOrderItemsExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaSerialNumbers?: Array<string>;
    criteriaSerialNumberFrom?: string;
    criteriaSerialNumberTo?: string;
    criteriaOrderAcknowledgementNumber?: string;
    criteriaOrderLineItem?: string;
    criteriaCompanyId?: string;
    criteriaOrder?: string;
    criteriaOrderingCode?: string;
    criteriaIsSoftwareQuery?: boolean;
    criteriaIsDocumentQuery?: boolean;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiOrderItemsPostRequest {
    getOrderItemsQuery?: GetOrderItemsQuery;
}

export interface ApiOrderLicenseFilesGetRequest {
    identifier?: string;
    fileName?: string;
}

export interface ApiOrderLicensesPutRequest {
    serialNumber?: string;
    provideAutomaticDownloadInformation?: boolean;
}

export interface ApiOrderNotificationsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaOrderExists?: boolean;
    criteriaQueued?: boolean;
    criteriaProcessed?: boolean;
    criteriaCreatedFrom?: Date;
    criteriaCreatedTo?: Date;
    criteriaOrder?: string;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiOrderOnlineDeliveryIdentifierPostRequest {
    identifier: string;
}

export interface ApiOrderOrderItemFilesPostRequest {
    downloadOrderItemsFilesQuery?: DownloadOrderItemsFilesQuery;
}

export interface ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest {
    removeUploadedJustInTimeFileCommand?: RemoveUploadedJustInTimeFileCommand;
}

export interface ApiOrderResendDownloadNotificationSerialNumberPostRequest {
    serialNumber: string;
}

export interface ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest {
    resetStatusDeliveryFailedJustInTimeFileCommand?: ResetStatusDeliveryFailedJustInTimeFileCommand;
}

export interface ApiOrderSalesSupportRequestPostRequest {
    contactSalesSupportCommand?: ContactSalesSupportCommand;
}

export interface ApiOrderShippingDetailFilesGetRequest {
    shippingDetailId?: number;
    documentTypes?: Array<ShippingDetailDocumentTypes>;
}

export interface ApiOrderSubscriptionsExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaCanRenew?: Array<boolean>;
    criteriaCompositionCode?: string;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiOrderSubscriptionsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaCanRenew?: Array<boolean>;
    criteriaCompositionCode?: string;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiOrderSupportTeamRequestPostRequest {
    contactSupportTeamCommand?: ContactSupportTeamCommand;
}

export interface ApiOrderUnsubscribePatchRequest {
    unsubscribeSoftwareSubscriptionsCommand?: UnsubscribeSoftwareSubscriptionsCommand;
}

export interface ApiOrderUploadAttachmentForSupportIdPostRequest {
    id: number;
    attachment?: Blob;
}

export interface ApiOrderUploadJustInTimeFileIdentifierPostRequest {
    identifier: string;
    fileToUpload?: Blob;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    async apiOrderContactOrderHandlingPostRaw(requestParameters: ApiOrderContactOrderHandlingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/contact-order-handling`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactOrderHandlingCommandToJSON(requestParameters.contactOrderHandlingCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderContactOrderHandlingPost(requestParameters: ApiOrderContactOrderHandlingPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderContactOrderHandlingPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderContactOrderHandlingReasonsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ContactOrderHandlingReasonDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/contact-order-handling-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactOrderHandlingReasonDtoFromJSON));
    }

    /**
     */
    async apiOrderContactOrderHandlingReasonsGet(initOverrides?: RequestInit): Promise<Array<ContactOrderHandlingReasonDto>> {
        const response = await this.apiOrderContactOrderHandlingReasonsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderDetailsGetRaw(requestParameters: ApiOrderDetailsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsVm>> {
        const queryParameters: any = {};

        if (requestParameters.orderIdentifier !== undefined) {
            queryParameters['OrderIdentifier'] = requestParameters.orderIdentifier;
        }

        if (requestParameters.fetchOnlyOrderLines !== undefined) {
            queryParameters['FetchOnlyOrderLines'] = requestParameters.fetchOnlyOrderLines;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDetailsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderDetailsGet(requestParameters: ApiOrderDetailsGetRequest, initOverrides?: RequestInit): Promise<OrderDetailsVm> {
        const response = await this.apiOrderDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderDownloadNotificationsExportPostRaw(requestParameters: ApiOrderDownloadNotificationsExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderDownloadNotificationsExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderDownloadNotificationsExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaSerialNumber !== undefined) {
            queryParameters['Criteria.SerialNumber'] = requestParameters.criteriaSerialNumber;
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaEmailSent !== undefined) {
            queryParameters['Criteria.EmailSent'] = requestParameters.criteriaEmailSent;
        }

        if (requestParameters.criteriaEmailQueued !== undefined) {
            queryParameters['Criteria.EmailQueued'] = requestParameters.criteriaEmailQueued;
        }

        if (requestParameters.criteriaOrderLinked !== undefined) {
            queryParameters['Criteria.OrderLinked'] = requestParameters.criteriaOrderLinked;
        }

        if (requestParameters.criteriaCreatedFrom !== undefined) {
            queryParameters['Criteria.CreatedFrom'] = (requestParameters.criteriaCreatedFrom as any).toISOString();
        }

        if (requestParameters.criteriaCreatedTo !== undefined) {
            queryParameters['Criteria.CreatedTo'] = (requestParameters.criteriaCreatedTo as any).toISOString();
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/downloadNotifications/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderDownloadNotificationsExportPost(requestParameters: ApiOrderDownloadNotificationsExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderDownloadNotificationsExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderDownloadNotificationsGetRaw(requestParameters: ApiOrderDownloadNotificationsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDownloadNotificationsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderDownloadNotificationsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderDownloadNotificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaSerialNumber !== undefined) {
            queryParameters['Criteria.SerialNumber'] = requestParameters.criteriaSerialNumber;
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaEmailSent !== undefined) {
            queryParameters['Criteria.EmailSent'] = requestParameters.criteriaEmailSent;
        }

        if (requestParameters.criteriaEmailQueued !== undefined) {
            queryParameters['Criteria.EmailQueued'] = requestParameters.criteriaEmailQueued;
        }

        if (requestParameters.criteriaOrderLinked !== undefined) {
            queryParameters['Criteria.OrderLinked'] = requestParameters.criteriaOrderLinked;
        }

        if (requestParameters.criteriaCreatedFrom !== undefined) {
            queryParameters['Criteria.CreatedFrom'] = (requestParameters.criteriaCreatedFrom as any).toISOString();
        }

        if (requestParameters.criteriaCreatedTo !== undefined) {
            queryParameters['Criteria.CreatedTo'] = (requestParameters.criteriaCreatedTo as any).toISOString();
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/downloadNotifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDownloadNotificationsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderDownloadNotificationsGet(requestParameters: ApiOrderDownloadNotificationsGetRequest, initOverrides?: RequestInit): Promise<OrderDownloadNotificationsVm> {
        const response = await this.apiOrderDownloadNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderExportPostRaw(requestParameters: ApiOrderExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaMaterialCode !== undefined) {
            queryParameters['Criteria.MaterialCode'] = requestParameters.criteriaMaterialCode;
        }

        if (requestParameters.criteriaLimitedSearch !== undefined) {
            queryParameters['Criteria.LimitedSearch'] = requestParameters.criteriaLimitedSearch;
        }

        if (requestParameters.criteriaOrderingCode !== undefined) {
            queryParameters['Criteria.OrderingCode'] = requestParameters.criteriaOrderingCode;
        }

        if (requestParameters.criteriaTrackingNumber !== undefined) {
            queryParameters['Criteria.TrackingNumber'] = requestParameters.criteriaTrackingNumber;
        }

        if (requestParameters.criteriaOrderStatus !== undefined) {
            queryParameters['Criteria.OrderStatus'] = requestParameters.criteriaOrderStatus;
        }

        if (requestParameters.criteriaDateTimeFrom !== undefined) {
            queryParameters['Criteria.DateTimeFrom'] = (requestParameters.criteriaDateTimeFrom as any).toISOString();
        }

        if (requestParameters.criteriaDateTimeTo !== undefined) {
            queryParameters['Criteria.DateTimeTo'] = (requestParameters.criteriaDateTimeTo as any).toISOString();
        }

        if (requestParameters.criteriaEtcdIcvCode !== undefined) {
            queryParameters['Criteria.EtcdIcvCode'] = requestParameters.criteriaEtcdIcvCode;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaCustomerCode !== undefined) {
            queryParameters['Criteria.CustomerCode'] = requestParameters.criteriaCustomerCode;
        }

        if (requestParameters.criteriaPlantId !== undefined) {
            queryParameters['Criteria.PlantId'] = requestParameters.criteriaPlantId;
        }

        if (requestParameters.criteriaFetchOrderLines !== undefined) {
            queryParameters['Criteria.FetchOrderLines'] = requestParameters.criteriaFetchOrderLines;
        }

        if (requestParameters.criteriaCreateStatisticsEvent !== undefined) {
            queryParameters['Criteria.CreateStatisticsEvent'] = requestParameters.criteriaCreateStatisticsEvent;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderExportPost(requestParameters: ApiOrderExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderGetRaw(requestParameters: ApiOrderGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaMaterialCode !== undefined) {
            queryParameters['Criteria.MaterialCode'] = requestParameters.criteriaMaterialCode;
        }

        if (requestParameters.criteriaLimitedSearch !== undefined) {
            queryParameters['Criteria.LimitedSearch'] = requestParameters.criteriaLimitedSearch;
        }

        if (requestParameters.criteriaOrderingCode !== undefined) {
            queryParameters['Criteria.OrderingCode'] = requestParameters.criteriaOrderingCode;
        }

        if (requestParameters.criteriaTrackingNumber !== undefined) {
            queryParameters['Criteria.TrackingNumber'] = requestParameters.criteriaTrackingNumber;
        }

        if (requestParameters.criteriaOrderStatus !== undefined) {
            queryParameters['Criteria.OrderStatus'] = requestParameters.criteriaOrderStatus;
        }

        if (requestParameters.criteriaDateTimeFrom !== undefined) {
            queryParameters['Criteria.DateTimeFrom'] = (requestParameters.criteriaDateTimeFrom as any).toISOString();
        }

        if (requestParameters.criteriaDateTimeTo !== undefined) {
            queryParameters['Criteria.DateTimeTo'] = (requestParameters.criteriaDateTimeTo as any).toISOString();
        }

        if (requestParameters.criteriaEtcdIcvCode !== undefined) {
            queryParameters['Criteria.EtcdIcvCode'] = requestParameters.criteriaEtcdIcvCode;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaCustomerCode !== undefined) {
            queryParameters['Criteria.CustomerCode'] = requestParameters.criteriaCustomerCode;
        }

        if (requestParameters.criteriaPlantId !== undefined) {
            queryParameters['Criteria.PlantId'] = requestParameters.criteriaPlantId;
        }

        if (requestParameters.criteriaFetchOrderLines !== undefined) {
            queryParameters['Criteria.FetchOrderLines'] = requestParameters.criteriaFetchOrderLines;
        }

        if (requestParameters.criteriaCreateStatisticsEvent !== undefined) {
            queryParameters['Criteria.CreateStatisticsEvent'] = requestParameters.criteriaCreateStatisticsEvent;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrdersVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderGet(requestParameters: ApiOrderGetRequest, initOverrides?: RequestInit): Promise<OrdersVm> {
        const response = await this.apiOrderGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderItemsExportPostRaw(requestParameters: ApiOrderItemsExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderItemsExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderItemsExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaSerialNumbers) {
            queryParameters['Criteria.SerialNumbers'] = requestParameters.criteriaSerialNumbers;
        }

        if (requestParameters.criteriaSerialNumberFrom !== undefined) {
            queryParameters['Criteria.SerialNumberFrom'] = requestParameters.criteriaSerialNumberFrom;
        }

        if (requestParameters.criteriaSerialNumberTo !== undefined) {
            queryParameters['Criteria.SerialNumberTo'] = requestParameters.criteriaSerialNumberTo;
        }

        if (requestParameters.criteriaOrderAcknowledgementNumber !== undefined) {
            queryParameters['Criteria.OrderAcknowledgementNumber'] = requestParameters.criteriaOrderAcknowledgementNumber;
        }

        if (requestParameters.criteriaOrderLineItem !== undefined) {
            queryParameters['Criteria.OrderLineItem'] = requestParameters.criteriaOrderLineItem;
        }

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaOrderingCode !== undefined) {
            queryParameters['Criteria.OrderingCode'] = requestParameters.criteriaOrderingCode;
        }

        if (requestParameters.criteriaIsSoftwareQuery !== undefined) {
            queryParameters['Criteria.IsSoftwareQuery'] = requestParameters.criteriaIsSoftwareQuery;
        }

        if (requestParameters.criteriaIsDocumentQuery !== undefined) {
            queryParameters['Criteria.IsDocumentQuery'] = requestParameters.criteriaIsDocumentQuery;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/items/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderItemsExportPost(requestParameters: ApiOrderItemsExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderItemsExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderItemsPostRaw(requestParameters: ApiOrderItemsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderItemsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/items`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOrderItemsQueryToJSON(requestParameters.getOrderItemsQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderItemsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderItemsPost(requestParameters: ApiOrderItemsPostRequest, initOverrides?: RequestInit): Promise<OrderItemsVm> {
        const response = await this.apiOrderItemsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderLicenseFilesGetRaw(requestParameters: ApiOrderLicenseFilesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.identifier !== undefined) {
            queryParameters['Identifier'] = requestParameters.identifier;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['FileName'] = requestParameters.fileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/licenseFiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderLicenseFilesGet(requestParameters: ApiOrderLicenseFilesGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderLicenseFilesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderLicensesPutRaw(requestParameters: ApiOrderLicensesPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderLicensesVm>> {
        const queryParameters: any = {};

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serialNumber'] = requestParameters.serialNumber;
        }

        if (requestParameters.provideAutomaticDownloadInformation !== undefined) {
            queryParameters['provideAutomaticDownloadInformation'] = requestParameters.provideAutomaticDownloadInformation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/licenses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderLicensesVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderLicensesPut(requestParameters: ApiOrderLicensesPutRequest, initOverrides?: RequestInit): Promise<OrderLicensesVm> {
        const response = await this.apiOrderLicensesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderMetaDataGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderMetaDataVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/metaData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderMetaDataVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderMetaDataGet(initOverrides?: RequestInit): Promise<OrderMetaDataVm> {
        const response = await this.apiOrderMetaDataGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderNotificationsGetRaw(requestParameters: ApiOrderNotificationsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderNotificationsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderNotificationsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderNotificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaOrderExists !== undefined) {
            queryParameters['Criteria.OrderExists'] = requestParameters.criteriaOrderExists;
        }

        if (requestParameters.criteriaQueued !== undefined) {
            queryParameters['Criteria.Queued'] = requestParameters.criteriaQueued;
        }

        if (requestParameters.criteriaProcessed !== undefined) {
            queryParameters['Criteria.Processed'] = requestParameters.criteriaProcessed;
        }

        if (requestParameters.criteriaCreatedFrom !== undefined) {
            queryParameters['Criteria.CreatedFrom'] = (requestParameters.criteriaCreatedFrom as any).toISOString();
        }

        if (requestParameters.criteriaCreatedTo !== undefined) {
            queryParameters['Criteria.CreatedTo'] = (requestParameters.criteriaCreatedTo as any).toISOString();
        }

        if (requestParameters.criteriaOrder !== undefined) {
            queryParameters['Criteria.Order'] = requestParameters.criteriaOrder;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderNotificationsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderNotificationsGet(requestParameters: ApiOrderNotificationsGetRequest, initOverrides?: RequestInit): Promise<OrderNotificationsVm> {
        const response = await this.apiOrderNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderNotificationsMetadataGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderNotificationsMetadataVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/notificationsMetadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderNotificationsMetadataVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderNotificationsMetadataGet(initOverrides?: RequestInit): Promise<OrderNotificationsMetadataVm> {
        const response = await this.apiOrderNotificationsMetadataGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderOnlineDeliveryIdentifierPostRaw(requestParameters: ApiOrderOnlineDeliveryIdentifierPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnlineDeliveryVm>> {
        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling apiOrderOnlineDeliveryIdentifierPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/onlineDelivery/{identifier}`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters.identifier))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnlineDeliveryVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderOnlineDeliveryIdentifierPost(requestParameters: ApiOrderOnlineDeliveryIdentifierPostRequest, initOverrides?: RequestInit): Promise<OnlineDeliveryVm> {
        const response = await this.apiOrderOnlineDeliveryIdentifierPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderOrderItemFilesPostRaw(requestParameters: ApiOrderOrderItemFilesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/orderItemFiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DownloadOrderItemsFilesQueryToJSON(requestParameters.downloadOrderItemsFilesQuery),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderOrderItemFilesPost(requestParameters: ApiOrderOrderItemFilesPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderOrderItemFilesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderRemoveUploadedJustInTimeFileCommandPatchRaw(requestParameters: ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/removeUploadedJustInTimeFileCommand`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveUploadedJustInTimeFileCommandToJSON(requestParameters.removeUploadedJustInTimeFileCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderRemoveUploadedJustInTimeFileCommandPatch(requestParameters: ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderRemoveUploadedJustInTimeFileCommandPatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderResendDownloadNotificationSerialNumberPostRaw(requestParameters: ApiOrderResendDownloadNotificationSerialNumberPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling apiOrderResendDownloadNotificationSerialNumberPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/resendDownloadNotification/{serialNumber}`.replace(`{${"serialNumber"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderResendDownloadNotificationSerialNumberPost(requestParameters: ApiOrderResendDownloadNotificationSerialNumberPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderResendDownloadNotificationSerialNumberPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderResetStatusDeliveryFailedJustInTimeFilePatchRaw(requestParameters: ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/resetStatusDeliveryFailedJustInTimeFile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ResetStatusDeliveryFailedJustInTimeFileCommandToJSON(requestParameters.resetStatusDeliveryFailedJustInTimeFileCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderResetStatusDeliveryFailedJustInTimeFilePatch(requestParameters: ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderResetStatusDeliveryFailedJustInTimeFilePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderSalesSupportRequestPostRaw(requestParameters: ApiOrderSalesSupportRequestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/salesSupportRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSalesSupportCommandToJSON(requestParameters.contactSalesSupportCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderSalesSupportRequestPost(requestParameters: ApiOrderSalesSupportRequestPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderSalesSupportRequestPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderShippingDetailFilesGetRaw(requestParameters: ApiOrderShippingDetailFilesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.shippingDetailId !== undefined) {
            queryParameters['ShippingDetailId'] = requestParameters.shippingDetailId;
        }

        if (requestParameters.documentTypes) {
            queryParameters['DocumentTypes'] = requestParameters.documentTypes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/shippingDetailFiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderShippingDetailFilesGet(requestParameters: ApiOrderShippingDetailFilesGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderShippingDetailFilesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderSubscriptionsExportPostRaw(requestParameters: ApiOrderSubscriptionsExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderSubscriptionsExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderSubscriptionsExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaCanRenew) {
            queryParameters['Criteria.CanRenew'] = requestParameters.criteriaCanRenew;
        }

        if (requestParameters.criteriaCompositionCode !== undefined) {
            queryParameters['Criteria.CompositionCode'] = requestParameters.criteriaCompositionCode;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/subscriptions/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiOrderSubscriptionsExportPost(requestParameters: ApiOrderSubscriptionsExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiOrderSubscriptionsExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderSubscriptionsGetRaw(requestParameters: ApiOrderSubscriptionsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SoftwareSubscriptionsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiOrderSubscriptionsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiOrderSubscriptionsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaCanRenew) {
            queryParameters['Criteria.CanRenew'] = requestParameters.criteriaCanRenew;
        }

        if (requestParameters.criteriaCompositionCode !== undefined) {
            queryParameters['Criteria.CompositionCode'] = requestParameters.criteriaCompositionCode;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SoftwareSubscriptionsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderSubscriptionsGet(requestParameters: ApiOrderSubscriptionsGetRequest, initOverrides?: RequestInit): Promise<SoftwareSubscriptionsVm> {
        const response = await this.apiOrderSubscriptionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderSupportTeamRequestPostRaw(requestParameters: ApiOrderSupportTeamRequestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SupportContactedResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/support-team-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSupportTeamCommandToJSON(requestParameters.contactSupportTeamCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportContactedResultFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderSupportTeamRequestPost(requestParameters: ApiOrderSupportTeamRequestPostRequest, initOverrides?: RequestInit): Promise<SupportContactedResult> {
        const response = await this.apiOrderSupportTeamRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOrderUnsubscribePatchRaw(requestParameters: ApiOrderUnsubscribePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Order/unsubscribe`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UnsubscribeSoftwareSubscriptionsCommandToJSON(requestParameters.unsubscribeSoftwareSubscriptionsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderUnsubscribePatch(requestParameters: ApiOrderUnsubscribePatchRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderUnsubscribePatchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderUploadAttachmentForSupportIdPostRaw(requestParameters: ApiOrderUploadAttachmentForSupportIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrderUploadAttachmentForSupportIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.attachment !== undefined) {
            formParams.append('attachment', requestParameters.attachment as any);
        }

        const response = await this.request({
            path: `/api/Order/upload-attachment-for-support/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrderUploadAttachmentForSupportIdPost(requestParameters: ApiOrderUploadAttachmentForSupportIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiOrderUploadAttachmentForSupportIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOrderUploadJustInTimeFileIdentifierPostRaw(requestParameters: ApiOrderUploadJustInTimeFileIdentifierPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OnlineDeliveryVm>> {
        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling apiOrderUploadJustInTimeFileIdentifierPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.fileToUpload !== undefined) {
            formParams.append('fileToUpload', requestParameters.fileToUpload as any);
        }

        const response = await this.request({
            path: `/api/Order/uploadJustInTimeFile/{identifier}`.replace(`{${"identifier"}}`, encodeURIComponent(String(requestParameters.identifier))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnlineDeliveryVmFromJSON(jsonValue));
    }

    /**
     */
    async apiOrderUploadJustInTimeFileIdentifierPost(requestParameters: ApiOrderUploadJustInTimeFileIdentifierPostRequest, initOverrides?: RequestInit): Promise<OnlineDeliveryVm> {
        const response = await this.apiOrderUploadJustInTimeFileIdentifierPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
