/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateShoppingCartItemSoftwarePurchaseEmailDto
 */
export interface UpdateShoppingCartItemSoftwarePurchaseEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateShoppingCartItemSoftwarePurchaseEmailDto
     */
    softwarePurchaseEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateShoppingCartItemSoftwarePurchaseEmailDto
     */
    sendEmail?: boolean;
}

export function UpdateShoppingCartItemSoftwarePurchaseEmailDtoFromJSON(json: any): UpdateShoppingCartItemSoftwarePurchaseEmailDto {
    return UpdateShoppingCartItemSoftwarePurchaseEmailDtoFromJSONTyped(json, false);
}

export function UpdateShoppingCartItemSoftwarePurchaseEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShoppingCartItemSoftwarePurchaseEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'softwarePurchaseEmail': json['softwarePurchaseEmail'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
    };
}

export function UpdateShoppingCartItemSoftwarePurchaseEmailDtoToJSON(value?: UpdateShoppingCartItemSoftwarePurchaseEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'softwarePurchaseEmail': value.softwarePurchaseEmail,
        'sendEmail': value.sendEmail,
    };
}

