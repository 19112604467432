/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    UserDto,
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface UsersVm
 */
export interface UsersVm {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UsersVm
     */
    users: Array<UserDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof UsersVm
     */
    criteria: PaginationReturnModel;
}

export function UsersVmFromJSON(json: any): UsersVm {
    return UsersVmFromJSONTyped(json, false);
}

export function UsersVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': ((json['users'] as Array<any>).map(UserDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function UsersVmToJSON(value?: UsersVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': ((value.users as Array<any>).map(UserDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

