import { SagaMiddleware } from "redux-saga";
import { call, put, takeLatest, takeEvery, PutEffect, CallEffect } from "redux-saga/effects";
import { completeRequest, failRequest, request, RequestFailed } from "../actions/asyncActions";
import { Action } from "redux";
import { PayloadAction } from "../actions/payloadAction";
import { userActions } from "../../applications/common/actions/userActions";
import { joinErrors } from "../../utilities/errorUtils";
import { NotificationSeverity } from "api";

export interface GenericSagaOptions<TResponsePayload = undefined, TRequestPayload = undefined> {
  actionTypes: [string, string, string, string];
  parameters?: SagaOptionalParameters<TRequestPayload, TResponsePayload>;
  operation: (requestPayload: TRequestPayload) => Promise<TResponsePayload>;
  createAction: (requestPayload: TRequestPayload) => PayloadAction<TRequestPayload>;
  clearRequestState: () => Action;
  isRequestAction: (action: Action) => action is PayloadAction<TRequestPayload>;
  isCompletedAction: (action: Action) => action is PayloadAction<TResponsePayload>;
  isFailedAction: (action: Action) => action is RequestFailed<TRequestPayload>;
}

export interface SagaOptionalParameters<TRequestPayload, TResponsePayload> {
  takeEvery: boolean;
  onSuccess?: (
    requestPayload: TRequestPayload,
    responsePayload: TResponsePayload
  ) => Generator<any> | undefined;
  onFail?: (
    requestPayload: TRequestPayload,
    errorTitle?: string,
    errorDetails?: { [id: string]: string[] },
    errorDetail?: string,
    additionalInformation?: { [id: string]: string }
  ) => Generator<any> | undefined;
}

export function createGenericSaga<TResponsePayload = undefined, TRequestPayload = undefined>(
  typePrefix: string,
  operation: (requestPayload: TRequestPayload) => Promise<TResponsePayload>,
  parameters?: SagaOptionalParameters<TRequestPayload, TResponsePayload>
): GenericSagaOptions<TResponsePayload, TRequestPayload> {
  const requestedType = `${typePrefix}Requested`;
  const completedType = `${typePrefix}Completed`;
  const failedType = `${typePrefix}Failed`;
  const clearType = `${typePrefix}Cleared`;

  return {
    actionTypes: [requestedType, completedType, failedType, clearType],
    operation: operation,
    parameters: parameters,
    createAction: (requestPayload: TRequestPayload) => request(requestedType, requestPayload),
    clearRequestState: () => ({ type: clearType }),
    isRequestAction: (action: Action): action is PayloadAction<TRequestPayload> =>
      action.type === requestedType,
    isCompletedAction: (action: Action): action is PayloadAction<TResponsePayload> =>
      action.type === completedType,
    isFailedAction: (action: Action): action is RequestFailed<TRequestPayload> =>
      action.type === failedType
  };
}

export interface CreateSagaOptions<TRequestPayload, TResponsePayload> {
  actionTypes: [string, string, string, string];
  operation: (requestPayload: TRequestPayload) => Promise<TResponsePayload>;
  parameters?: SagaOptionalParameters<TRequestPayload, TResponsePayload>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* genericAsyncOperation<TRequestPayload, TResponsePayload>(
  options: CreateSagaOptions<any, any>,
  action: PayloadAction<TRequestPayload>
): Generator<any, any, any> {
  try {
    const response = yield call(options.operation, action.payload);
    yield put(completeRequest(options.actionTypes[1], response));

    if (options.parameters?.onSuccess) {
      yield options.parameters.onSuccess(action.payload, response);
    }
  } catch (e) {
    yield put(failRequest(options.actionTypes[2], (e as any)?.message, action.payload));
    let err = undefined;
    // Errors from msalApp are not of json type.
    try {
      err = yield (e as any)?.json();
    } catch (ee) {
      err = undefined;
    }

    if (options.parameters?.onFail) {
      yield options.parameters.onFail(
        action.payload,
        err !== undefined ? err.title : undefined,
        err !== undefined ? err.errors : undefined,
        err !== undefined ? err.detail : undefined,
        err !== undefined ? err.additionalInformation : undefined
      );
    }
  }
}

export function createSaga(
  sagaMiddleware: SagaMiddleware<Record<string, unknown>>,
  options: CreateSagaOptions<any, any>
): void {
  const saga = function* () {
    if (options.parameters && options.parameters.takeEvery) {
      yield takeEvery(options.actionTypes[0], genericAsyncOperation, options);
    } else {
      yield takeLatest(options.actionTypes[0], genericAsyncOperation, options);
    }
  };

  sagaMiddleware.run(saga);
}

export function createSagas(
  sagaMiddleware: SagaMiddleware<Record<string, unknown>>,
  sagas: {
    [index: string]: CreateSagaOptions<any, any>;
  }
): void {
  for (const prop in sagas) {
    if (sagas.hasOwnProperty(prop)) {
      createSaga(sagaMiddleware, {
        actionTypes: sagas[prop].actionTypes as [string, string, string, string],
        operation: sagas[prop].operation,
        parameters: sagas[prop].parameters
      });
    }
  }
}

export function* dispatch(
  actionOrActionTypeString: Action | string
): Generator<PutEffect<Action<any>>, void, unknown> {
  let action = actionOrActionTypeString;
  if (typeof actionOrActionTypeString === "string") {
    action = {
      type: actionOrActionTypeString
    };
  }
  yield put(action as Action);
}

interface Effect {
  effect: (...args: any) => Generator<any>;
  args?: any;
}

export class SagaBuilder {
  private effects: Effect[] = [];

  build(): () => Generator<CallEffect<any>, void, unknown> {
    const effects = this.effects;
    return function* () {
      for (let i = 0, len = effects.length; i < len; ++i) {
        if (effects[i].args != null) {
          yield call(effects[i].effect, ...effects[i].args);
        } else {
          yield call(effects[i].effect);
        }
      }
    };
  }

  dispatch(actionOrActionTypeString: Action | string): SagaBuilder {
    this.effects.push({ effect: () => dispatch(actionOrActionTypeString) });
    return this;
  }

  triggerSaga(actionTypePrefix: string): SagaBuilder {
    this.effects.push({ effect: () => dispatch(`${actionTypePrefix}Requested`) });
    return this;
  }

  generator(generator: (...args: any) => any, ...args: any): SagaBuilder {
    this.effects.push({ effect: generator, args: args });
    return this;
  }
}

export function defaultSagaErrorHandler<TRequestPayload>(
  requestPayload: TRequestPayload,
  errorTitle?: string,
  errorDetails?: { [id: string]: string[] },
  errorDetail?: string,
  additionalInformation?: { [id: string]: string },
  skipTranslationForTitle?: boolean,
  skipTranslationForDetails?: boolean
): Generator<any> {
  return new SagaBuilder()
    .dispatch(
      userActions.addNotification(
        NotificationSeverity.Error,
        errorTitle ?? "Error occured",
        undefined,
        undefined,
        undefined,
        errorDetails ? joinErrors(errorDetails) : errorDetail,
        skipTranslationForTitle,
        skipTranslationForDetails
      )
    )
    .build()();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultSagaSuccessHandler = <TRequestPayload>(
  message: string,
  timeout?: number
): (() => Generator<CallEffect<any>, void, unknown>) =>
  new SagaBuilder()
    .dispatch(userActions.addNotification(NotificationSeverity.Success, message, false, timeout))
    .build();

// SNACKBAR EXAMPLE

/*  successMessage(message: string) {
    this.effects.push(() => successMessage(message));
    return this;
  }

  failMessage(message: string) {
    this.effects.push(() => errorMessage(message));
    return this;
  } */

/* export function* successMessage(message: string) {
  yield put(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "success",
      },
    })
  );
}

export function* errorMessage(message: string) {
  yield put(
    enqueueSnackbar({
      message: message,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: "error",
      },
    })
  );
} */

// EXAMPLE HOW TO USE

/* deleteSetup: createGenericSaga(
    "setupSagas/deleteSetup",
    getApiRegistry().setupApi.deleteSetup.bind(getApiRegistry().setupApi),
    {
      takeEvery: false,
      onSuccess: new SagaBuilder()
        // Space left intentionally after the t-letter of i18n.t to not have this example listed when searching for all translations 
        .successMessage(i18n.t ("administration.setup.deleteSetupSuccess"))
        .dispatch(hideModal(ModalTypes.ConfirmDelete))
        .triggerSaga("setupSagas/loadSetups")
        .build(),

      // Space left intentionally after the t-letter of i18n.t to not have this example listed when searching for all translations 
      onFail: new SagaBuilder().failMessage(i18n.t ("administration.setup.deleteSetupFail")).build(),
    }
  ), */
