import saveAs from "file-saver";
import html2canvas from "html2canvas";
import JSZip from "jszip";

export const scrollToTop = (timeOut: number): void => {
  const el = document.querySelector(".ABB_CommonUX_AppContent__root") as HTMLDivElement;
  setTimeout(
    () =>
      el?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      }),
    timeOut
  );
};
export const scrollIntoView = (timeOut: number, className: string): void => {
  const el = document.querySelector(className) as HTMLDivElement;
  setTimeout(() => el?.scrollIntoView({ behavior: "smooth" }), timeOut);
};

export interface ExportPngItem {
  element: Element;
  fileName: string;
  imageQuality?: number;
  renderScale?: number;
}

export const exportComponentAsPng = async ({
  element,
  fileName,
  imageQuality,
  renderScale
}: ExportPngItem) => {
  getComponentAsPngBlob(element, imageQuality, renderScale).then((blob) => saveAs(blob, fileName));
};

export const exportComponentsAsPngZip = async (
  exportItems: ExportPngItem[],
  zipFolderName: string,
  updateExportProgress?: (currentExportIndex: number, totalToExport: number) => void
) => {
  const zip = new JSZip();
  for (let i = 0; i < exportItems.length; i++) {
    let fileName = exportItems[i].fileName ?? (i + 1).toString();

    // Add index at end of file name, if there exists file with same name
    if (zip.file(fileName + ".png") !== null) {
      let i = 1;
      while (zip.file(`${fileName}_${i}.png`) !== null && i <= 50) i++;
      fileName = `${fileName}_${i}`;
    }
    fileName += ".png";

    zip.file(
      fileName,
      await getComponentAsPngBlob(
        exportItems[i].element,
        exportItems[i].imageQuality,
        exportItems[i].renderScale
      )
    );
    if (updateExportProgress) updateExportProgress(i + 1, exportItems.length);
  }
  zip.generateAsync({ type: "blob" }).then((content) => saveAs(content, `${zipFolderName}.zip`));
};

const getComponentAsPngBlob = async (element: Element, imageQuality = 1, renderScale = 1) => {
  const canvas = await html2canvas(element as HTMLElement, { scale: renderScale });
  const link = document.createElement("a");
  link.href = canvas.toDataURL("image/png", imageQuality);
  link.download = `${element.id}.png`;
  const blob = await fetch(link.href).then((response) => response.blob());
  return blob;
};
