function getFileName(res: Response, defaultFileName: string): string {
  let fileName = defaultFileName;
  const contentDispositionHeader = res.headers.get("Content-Disposition");
  if (contentDispositionHeader) {
    const splitted = contentDispositionHeader.split(";");
    if (splitted.length > 2) {
      fileName = splitted[1].trim().replace("filename=", "");
      fileName = fileName.replaceAll('"', "");
    }
  }
  return fileName;
}

export function handleFileDownloadResponse(res: Response, defaultFileName: string): void {
  const fileName = getFileName(res, defaultFileName);
  res.blob().then((b) => {
    const url = window.URL.createObjectURL(b);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
}
/* wait the download to be completed */
export async function asyncHandleFileDownloadResponse(res: Response, defaultFileName: string) {
  const fileName = getFileName(res, defaultFileName);
  await res.blob().then((b) => {
    const url = window.URL.createObjectURL(b);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
}

export function handleBlobFileDownload(file: Blob, fileName: string) {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
}
