/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeMemberRoleCommand
 */
export interface ChangeMemberRoleCommand {
    /**
     * 
     * @type {number}
     * @memberof ChangeMemberRoleCommand
     */
    teamId: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeMemberRoleCommand
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeMemberRoleCommand
     */
    roleAbbreviation: string;
}

export function ChangeMemberRoleCommandFromJSON(json: any): ChangeMemberRoleCommand {
    return ChangeMemberRoleCommandFromJSONTyped(json, false);
}

export function ChangeMemberRoleCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeMemberRoleCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamId': json['teamId'],
        'userId': json['userId'],
        'roleAbbreviation': json['roleAbbreviation'],
    };
}

export function ChangeMemberRoleCommandToJSON(value?: ChangeMemberRoleCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamId': value.teamId,
        'userId': value.userId,
        'roleAbbreviation': value.roleAbbreviation,
    };
}

