import styled from "styled-components";
export const MainContainerComparator = styled.div`
  .largeCard {
    flex: 0 1 36%;
  }
  .smallCard {
    flex: 0 1 21%;
  }
`;
export const ProductsCardsContainer = styled.div`
  padding-bottom: ${(props) => props.theme.sizes.s};
`;
