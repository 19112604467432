import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { ProductLifecycleStatus } from "api";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useStateParams } from "utilities/useStateParams";
import { productExplorerActions } from "../actions/ProductExplorerActions";
import { commonUXTheme } from "styles/commonUXVariables";

export const SelectLifeCycleStatus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fromStrToCycle = useCallback((str: string | null) => {
    let cycle: ProductLifecycleStatus | undefined = undefined;
    if (str) {
      switch (str) {
        case ProductLifecycleStatus.Active:
          cycle = ProductLifecycleStatus.Active;
          break;
        case ProductLifecycleStatus.Classic:
          cycle = ProductLifecycleStatus.Classic;
          break;
        case ProductLifecycleStatus.Limited:
          cycle = ProductLifecycleStatus.Limited;
          break;
        case ProductLifecycleStatus.Obsolete:
          cycle = ProductLifecycleStatus.Obsolete;
          break;
        default:
          cycle = undefined;
          break;
      }
    }
    return cycle;
  }, []);

  const [lifeCycleStatus, setLifeCycleStatus] = useStateParams<ProductLifecycleStatus | undefined>(
    undefined,
    "cycle",
    (s) => s?.toString() ?? "",
    fromStrToCycle
  );

  // update filters
  useEffect(() => {
    dispatch(productExplorerActions.SetLifeCycleStatus(lifeCycleStatus));
  }, [dispatch, lifeCycleStatus]);

  return (
    <Dropdown
      placeholder={t("Select life-cycle status")}
      monochrome
      value={
        lifeCycleStatus
          ? [
              {
                value: lifeCycleStatus,
                label: lifeCycleStatus
              }
            ]
          : []
      }
      style={{ paddingTop: commonUXTheme.sizes.s }}
      onChange={(item) => {
        item.length > 0
          ? setLifeCycleStatus(fromStrToCycle(item[0].label))
          : setLifeCycleStatus(undefined);
      }}
      clearable
      sizeClass={"small"}
    >
      {Object.keys(ProductLifecycleStatus).map((key) => (
        <DropdownOption key={key} label={key} value={key} />
      ))}
    </Dropdown>
  );
};
