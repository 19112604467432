/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchiveShoppingCartCommand
 */
export interface ArchiveShoppingCartCommand {
    /**
     * 
     * @type {string}
     * @memberof ArchiveShoppingCartCommand
     */
    identifier?: string;
}

export function ArchiveShoppingCartCommandFromJSON(json: any): ArchiveShoppingCartCommand {
    return ArchiveShoppingCartCommandFromJSONTyped(json, false);
}

export function ArchiveShoppingCartCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveShoppingCartCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
    };
}

export function ArchiveShoppingCartCommandToJSON(value?: ArchiveShoppingCartCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
    };
}

