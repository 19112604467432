import React, { useCallback, useEffect, useMemo } from "react";
import { CurrentUserDto, ShoppingCartDto, ShoppingCartStateTypes } from "api";
import { userActions } from "applications/common/actions/userActions";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { StatusBanner } from "framework/components/StatusBanner";
import { TFunction } from "i18next";
import { Dispatch } from "redux";
import { isQuoteExpired } from "applications/shoppingCart/helpers";
import { shoppingCartsViewSagas } from "applications/shoppingCarts/sagas/shoppingCartsViewSagas";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePrevious } from "framework/hooks/usePrevious";
import {
  getClonedIdentifier,
  getCloneStatus
} from "applications/shoppingCarts/reducers/shoppingCartsViewReducer";
import { routes } from "utilities/routes";
import { RequestStatus } from "framework/state/requestStatus";

interface ShoppingCartStatusBannerProps {
  shoppingCart: ShoppingCartDto | undefined;
  userInformation: CurrentUserDto | undefined | null;
  t: TFunction;
  dispatch: Dispatch;
}

export const ShoppingCartStatusBanner = ({
  shoppingCart,
  userInformation,
  t,
  dispatch
}: ShoppingCartStatusBannerProps): JSX.Element | null => {
  const navigate = useNavigate();

  const cloneStatus = useSelector(getCloneStatus);
  const clonedId = useSelector(getClonedIdentifier);

  const prevCloneStatus = usePrevious(cloneStatus);

  const isCurrentUserOwner = useMemo(() => {
    return shoppingCart?.ownerEmail === userInformation?.email;
  }, [shoppingCart, userInformation]);

  const navigateTo = useCallback(
    (guid: string) => {
      if (guid === userInformation?.selectedShoppingCartGuid) {
        navigate(routes.shoppingCart);
      } else {
        navigate(`${routes.shoppingCart}/${guid}`);
      }
    },
    [navigate, userInformation?.selectedShoppingCartGuid]
  );

  useEffect(() => {
    if (
      prevCloneStatus === RequestStatus.Pending &&
      cloneStatus === RequestStatus.Completed &&
      clonedId != null
    ) {
      navigateTo(clonedId);

      if (clonedId) {
        dispatch(userActions.updateSelectedShoppingCartGuid(clonedId));
      }
    }
  }, [cloneStatus, clonedId, dispatch, navigateTo, prevCloneStatus]);

  return shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote ? (
    <StatusBanner
      icon={"abb/time"}
      headerText={t("This cart is waiting for a quote")}
      status="warning"
      shape={"long"}
    />
  ) : shoppingCart?.activeQuote?.frameAgreement === true &&
    shoppingCart?.stateId === ShoppingCartStateTypes.Archived ? (
    <StatusBanner
      icon={"abb/view"}
      headerText={t("This cart is not active")}
      text={
        <b>
          {t(
            "Please note: This quote has been archived. If needed, please clone the content of the cart and create a new quote."
          )}
        </b>
      }
      status="information"
      shape={"long"}
      onClick={() => {
        dispatch(
          shoppingCartsViewSagas.clone.createAction({
            cloneShoppingCartCommand: { shoppingCartGuid: shoppingCart.guid }
          })
        );
      }}
      buttonText={t("Clone cart")}
      buttonIsLoading={cloneStatus === RequestStatus.Pending}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.Cancelled ||
    shoppingCart?.stateId === ShoppingCartStateTypes.Archived ||
    (shoppingCart?.stateId === ShoppingCartStateTypes.Draft &&
      shoppingCart.guid !== userInformation?.selectedShoppingCartGuid) ? (
    <StatusBanner
      icon={"abb/view"}
      headerText={t("This cart is not active")}
      status="information"
      shape={"long"}
      text={
        isCurrentUserOwner
          ? t(
              "If you want to edit the contents, request a quote or proceed to order you need to switch to editing mode by activating the cart"
            )
          : t("Only the shopping cart owner can activate this cart")
      }
      onClick={() =>
        dispatch(
          userActions.addConfirmEvent(
            () =>
              dispatch(
                shoppingCartSagas.activateShoppingCart.createAction({
                  activateShoppingCartCommand: { shoppingCartGuid: shoppingCart.guid }
                })
              ),
            t("Activate cart"),
            t(
              "This shopping cart will become your active cart.\n\nYour current active cart can then be found in shopping carts view. Are you sure?"
            )
          )
        )
      }
      buttonText={t("Activate cart")}
      buttonDisabled={!isCurrentUserOwner}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.Rejected ? (
    <StatusBanner
      icon={"abb/error-circle-1"}
      headerText={
        shoppingCart.activeQuote?.rejectMessage ? t("Rejected with message:") : t("Rejected")
      }
      text={
        <b>
          {t(
            "Please note: This quote has been rejected. If needed, please clone the content of the cart and create a new quote request."
          )}
        </b>
      }
      subHeaderText={shoppingCart.activeQuote?.rejectMessage ?? undefined}
      status="error"
      shape={"long"}
      onClick={() => {
        dispatch(
          shoppingCartsViewSagas.clone.createAction({
            cloneShoppingCartCommand: { shoppingCartGuid: shoppingCart.guid }
          })
        );
      }}
      buttonText={t("Clone cart")}
      buttonIsLoading={cloneStatus === RequestStatus.Pending}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.Quote ? (
    isQuoteExpired(shoppingCart) ? (
      <StatusBanner
        icon={"abb/information-circle-1"}
        headerText={t(
          "Quote has expired. Please contact the quote creator or request a quote again."
        )}
        status="warning"
        shape={"long"}
      />
    ) : (
      <StatusBanner
        icon={"abb/check-mark"}
        headerText={t("Quote received, you are ready to order")}
        status="success"
        shape={"long"}
      />
    )
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.OrderWaitingForApproval ? (
    <StatusBanner
      icon={"abb/time"}
      headerText={t("Order is waiting for approval")}
      status="warning"
      shape={"long"}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.QuoteWaitingForApproval ? (
    <StatusBanner
      icon={"abb/time"}
      headerText={t("Quote is waiting for approval")}
      status="warning"
      shape={"long"}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.OrderPlaced ||
    shoppingCart?.stateId === ShoppingCartStateTypes.OrderPlacedExternally ? (
    <StatusBanner
      status={"success"}
      icon={"abb/check-mark-circle-2"}
      headerText={t("Order has been created successfully")}
      shape={"long"}
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.FrameAgreement ? (
    <StatusBanner
      status="success"
      icon="abb/check-mark-circle-2"
      headerText={t("Frame agreement has been created successfully")}
      shape="long"
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForFrameAgreement ? (
    <StatusBanner
      status="warning"
      icon="abb/time"
      headerText={t("This cart is waiting for a frame agreement")}
      shape="long"
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForFrameAgreementApproval ? (
    <StatusBanner
      status="warning"
      icon="abb/time"
      headerText={t("Frame agreement is waiting for approval")}
      shape="long"
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision ? (
    <StatusBanner
      status="warning"
      icon="abb/time"
      headerText={t("Quote is waiting for acknowledge the revision")}
      shape="long"
    />
  ) : shoppingCart?.stateId === ShoppingCartStateTypes.Revised ? (
    <StatusBanner
      status="error"
      icon="abb/error-circle-1"
      headerText={t("Quote was revised")}
      shape="long"
    />
  ) : null;
};
