import { GridApi, IGetRowsParams } from "ag-grid-community";
import {
  AccessRights,
  CreateShoppingCartCommand,
  RenewSubscriptionsCommand,
  SoftwareSubscriptionsDto
} from "api";
import {
  ApiOrderSubscriptionsExportPostRequest,
  ApiOrderSubscriptionsGetRequest
} from "api/apis/OrderApi";
import { userActions } from "applications/common/actions/userActions";
import {
  getCreateShoppingCartStatus,
  getRenewSubscriptionsStatus
} from "applications/common/reducers/shoppingCartReducer";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getSelectedCompany } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import { AppContentWrapper } from "framework/components/AppContentWrapper";
import { Button } from "framework/components/Button";
import { GridExportParams, getSortModel } from "framework/components/grid/Grid";
import { HorizontalElementContainer } from "framework/components/HorizontalElementContainer";
import { usePrevious } from "framework/hooks/usePrevious";
import { getApiRegistry } from "framework/state/apiRegistry";
import { RequestStatus } from "framework/state/requestStatus";
import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { apiGridErrorHandler } from "utilities/errorUtils";
import { formSubscriptionCriteriaFilters } from "utilities/filters";
import { routes } from "utilities/routes";
import {
  SoftwareSubscriptionsGrid,
  toggleAllSubscriptionRows
} from "../components/SoftwareSubscriptionsGrid";
import { productInformationSagas } from "../sagas/productInformationSagas";
import { getUnsubscribeStatus } from "../reducers/productInformationViewReducer";
import { exportServerSideCsv } from "utilities/gridExportUtils";
import { hasAnyAccessRight } from "utilities/authUtils";
import { roleConstants } from "constants/roleConstants";
import { WarnCompanyDialog } from "../components/WarnCompanyDialog";

export const defaultSubscriptionsCriteria: ApiOrderSubscriptionsGetRequest = {
  criteriaPage: 0,
  criteriaIsAscendingOrder: true,
  criteriaPageSize: 100,
  criteriaSortColumn: "renewalPeriodStart"
};

const closingRequiredStatus = "Closing required";

const TopButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
`;

const getIsEnableSelectAll = (
  gridApi: GridApi | null,
  setEnableSelectAll: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  let hasSelectableRow = false;
  gridApi?.forEachNode((node) => {
    const softwareSubscription = node.data as SoftwareSubscriptionsDto;
    if (softwareSubscription?.canRenew) {
      if (!node.isSelected() && !hasSelectableRow) {
        hasSelectableRow = true;
        setEnableSelectAll(true);
        return;
      }
    }
  });
  if (!hasSelectableRow) {
    setEnableSelectAll(false);
  }
};

export const SubscriptionsView = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInformation = useSelector(getUserInformation);
  const selectedCompany = useSelector(getSelectedCompany);
  const renewSubscriptionsStatus = useSelector(getRenewSubscriptionsStatus);
  const previousRenewSubscriptionsStatus = usePrevious(renewSubscriptionsStatus);
  const createShoppingCartStatus = useSelector(getCreateShoppingCartStatus);
  const unsubscribeStatus = useSelector(getUnsubscribeStatus);
  const previousUnsubscribeStatus = usePrevious(unsubscribeStatus);
  const accessRights = useSelector(getUserAccessRights);

  const [subscriptionsGrid, setSubscriptionsGrid] = useState<GridApi | null>(null);
  const [selectedRows, setSelectedRows] = useState<SoftwareSubscriptionsDto[]>([]);
  const [isSelectAllEnabled, setIsSelectAllEnabled] = useState(true);
  const [isWarnDialogOpen, setIsWarnDialogOpen] = useState(false);

  const onSetProductInformationGridRefSet = useCallback((ref: GridApi) => {
    setSubscriptionsGrid(ref);
  }, []);

  const [searchParams] = useSearchParams();

  const renewSubscriptions = async () => {
    let shoppingCartGuid = userInformation?.selectedShoppingCartGuid;
    if (!shoppingCartGuid) {
      const createShoppingCartCommand: CreateShoppingCartCommand = {
        managedPriceListId: null
      };
      try {
        const { guid } = await getApiRegistry().shoppingCartsApi.apiShoppingCartsPost({
          createShoppingCartCommand
        });
        shoppingCartGuid = guid;
        dispatch(userActions.updateSelectedShoppingCartGuid(shoppingCartGuid));
      } catch (e) {
        apiGridErrorHandler(e, dispatch);
      }
    }

    if (shoppingCartGuid) {
      try {
        const renewSubscriptionsCommand: RenewSubscriptionsCommand = {
          shoppingCartGuid: shoppingCartGuid,
          subscriptionGuids: selectedRows.map((row: SoftwareSubscriptionsDto) => row.guid)
        };
        dispatch(
          shoppingCartSagas.renewSubscriptions.createAction({
            renewSubscriptionsCommand
          })
        );
      } catch (e) {
        apiGridErrorHandler(e, dispatch);
      }
    }
  };

  // I don't really like this text
  const unsubscribe = async () => {
    dispatch(
      userActions.addConfirmEvent(
        () =>
          dispatch(
            productInformationSagas.unsubscribe.createAction({
              unsubscribeSoftwareSubscriptionsCommand: {
                listIds: selectedRows.map((row: SoftwareSubscriptionsDto) => row.id)
              }
            })
          ),
        t("Unsubscribe"),
        t("Your will be unsubscribed. Are you sure you want to proceed?")
      )
    );
  };
  const dataSource = useCallback(
    (criteria: ApiOrderSubscriptionsGetRequest) => {
      return {
        getRows: (params: IGetRowsParams) => {
          const page = Math.floor(params.startRow / criteria.criteriaPageSize);
          const api = getApiRegistry().orderApi;

          const sortModel = getSortModel(params.sortModel);

          console.log(params);

          const filterCriteria = formSubscriptionCriteriaFilters(params.filterModel);

          console.log(filterCriteria);
          const newCriteria = produce(criteria, (draft) => {
            draft.criteriaSortColumn = sortModel?.sortColumn ?? draft.criteriaSortColumn;
            draft.criteriaPage = page;
            draft.criteriaIsAscendingOrder = sortModel?.isAsc ?? draft.criteriaIsAscendingOrder;
          });

          const criteriaToSet = { ...newCriteria, ...filterCriteria };

          const canSearchWithoutDefiningCompany =
            hasAnyAccessRight(
              roleConstants.GlobalRoleIdentifier,
              accessRights,
              AccessRights.ViewAllSoftwareLicenses
            ) || hasAnyAccessRight(null, accessRights, AccessRights.IsSoftwareSubscriptionHolder);

          if (!criteriaToSet.criteriaCompanyId && !canSearchWithoutDefiningCompany) {
            setIsWarnDialogOpen(true);
            return;
          }

          subscriptionsGrid?.showLoadingOverlay();

          api
            .apiOrderSubscriptionsGet(criteriaToSet)
            .then((res) => {
              if (res.softwareSubscriptions.length === 0) {
                subscriptionsGrid?.showNoRowsOverlay();
              } else {
                subscriptionsGrid?.hideOverlay();
              }

              params.successCallback(res.softwareSubscriptions, res.criteria.totalCount);
              subscriptionsGrid?.sizeColumnsToFit();
              getIsEnableSelectAll(subscriptionsGrid, setIsSelectAllEnabled);
            })
            .catch((err) => {
              console.error("Failed to fetch subscriptions", err);
              subscriptionsGrid?.hideOverlay();
            });
        }
      };
    },
    [accessRights, subscriptionsGrid]
  );

  const handleExport = useCallback(
    (params: GridExportParams) => {
      const api = getApiRegistry().orderApi;
      const sortModel = getSortModel(params.columnApi.getColumnState());
      const newCriteria = produce(defaultSubscriptionsCriteria, (draft) => {
        draft.criteriaSortColumn = sortModel?.sortColumn;
        draft.criteriaIsAscendingOrder = sortModel?.isAsc;
      });
      const filterCriteria = formSubscriptionCriteriaFilters(params.api.getFilterModel() as any);
      const criteriaToSet = {
        ...newCriteria,
        ...filterCriteria,
        criteriaCompanyId: selectedCompany?.value,
        criteriaCompositionCode: searchParams.get("product") ?? undefined
      };
      exportServerSideCsv<ApiOrderSubscriptionsExportPostRequest>(
        criteriaToSet,
        api.apiOrderSubscriptionsExportPost.bind(api),
        params.api,
        dispatch,
        t
      );
    },
    [dispatch, selectedCompany, searchParams, t]
  );

  useEffect(() => {
    if (subscriptionsGrid && dataSource) {
      const productParam = searchParams.get("product") ?? undefined;
      subscriptionsGrid.setDatasource(
        dataSource({
          ...defaultSubscriptionsCriteria,
          criteriaCompanyId: selectedCompany?.value,
          criteriaCompositionCode: productParam
        })
      );
    }
  }, [selectedCompany, subscriptionsGrid, dataSource, searchParams]);

  useEffect(() => {
    if (
      renewSubscriptionsStatus === RequestStatus.Completed &&
      previousRenewSubscriptionsStatus === RequestStatus.Pending
    ) {
      subscriptionsGrid?.hideOverlay();
      navigate(routes.shoppingCart);
    }
  }, [previousRenewSubscriptionsStatus, renewSubscriptionsStatus, subscriptionsGrid, navigate]);

  useEffect(() => {
    if (
      subscriptionsGrid &&
      unsubscribeStatus === RequestStatus.Completed &&
      previousUnsubscribeStatus === RequestStatus.Pending
    ) {
      subscriptionsGrid.hideOverlay();
      selectedRows.forEach((row) => {
        const rowNode = subscriptionsGrid.getRowNode(row.guid);
        if (rowNode) {
          const softwareSubscriptionsDto = rowNode.data as SoftwareSubscriptionsDto;
          softwareSubscriptionsDto.canRenew = false;
          softwareSubscriptionsDto.closingRequired = true;
          softwareSubscriptionsDto.statusLabel = closingRequiredStatus;
          rowNode.setData(softwareSubscriptionsDto);
        }
      });
      subscriptionsGrid.deselectAll();
    }
  }, [previousUnsubscribeStatus, selectedRows, subscriptionsGrid, unsubscribeStatus]);

  return (
    <AppContentWrapper isMainView pageTitle={t("Available subscriptions for the company")}>
      <WarnCompanyDialog
        isDialogOpen={isWarnDialogOpen}
        onClose={() => setIsWarnDialogOpen(false)}
      />
      <TopButtonsContainer>
        <HorizontalElementContainer isLeft={true}>
          <Button
            onClick={() => {
              toggleAllSubscriptionRows(subscriptionsGrid, true);
            }}
            text={t("Select all")}
            buttonType="secondary"
            sizeClass="medium"
            disabled={!isSelectAllEnabled}
          />
          <Button
            onClick={() => toggleAllSubscriptionRows(subscriptionsGrid, false)}
            text={t("Clear")}
            sizeClass="medium"
            buttonType="secondary"
            disabled={!selectedRows || selectedRows.length === 0}
          />
        </HorizontalElementContainer>
      </TopButtonsContainer>
      <SoftwareSubscriptionsGrid
        setGridApi={onSetProductInformationGridRefSet}
        setSelectedRows={setSelectedRows}
        setIsSelectAllEnabled={setIsSelectAllEnabled}
        onExportCsv={handleExport}
      />
      <ButtonContainer>
        <Button
          onClick={() => {
            renewSubscriptions();
          }}
          text={t("Renew")}
          sizeClass="medium"
          buttonType="primary"
          isLoading={
            createShoppingCartStatus === RequestStatus.Pending ||
            renewSubscriptionsStatus === RequestStatus.Pending
          }
          disabled={
            !selectedRows ||
            selectedRows.length === 0 ||
            createShoppingCartStatus === RequestStatus.Pending ||
            renewSubscriptionsStatus === RequestStatus.Pending
          }
        />
        <Button
          onClick={() => {
            unsubscribe();
          }}
          text={t("Unsubscribe")}
          sizeClass="medium"
          buttonType="primary"
          isLoading={unsubscribeStatus === RequestStatus.Pending}
          // to be selected need to be in renew period and open
          disabled={!selectedRows || selectedRows.length === 0}
        />
      </ButtonContainer>
    </AppContentWrapper>
  );
};
