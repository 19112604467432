/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRightsDto,
    AccessRightsDtoFromJSON,
    AccessRightsDtoFromJSONTyped,
    AccessRightsDtoToJSON,
} from './AccessRightsDto';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';

/**
 * 
 * @export
 * @interface RoleGroupAccessRightsDto
 */
export interface RoleGroupAccessRightsDto {
    /**
     * 
     * @type {RoleGroups}
     * @memberof RoleGroupAccessRightsDto
     */
    id: RoleGroups;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupAccessRightsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupAccessRightsDto
     */
    friendlyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupAccessRightsDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<AccessRightsDto>}
     * @memberof RoleGroupAccessRightsDto
     */
    accessRights: Array<AccessRightsDto>;
}

export function RoleGroupAccessRightsDtoFromJSON(json: any): RoleGroupAccessRightsDto {
    return RoleGroupAccessRightsDtoFromJSONTyped(json, false);
}

export function RoleGroupAccessRightsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleGroupAccessRightsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': RoleGroupsFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'friendlyName': !exists(json, 'friendlyName') ? undefined : json['friendlyName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'accessRights': ((json['accessRights'] as Array<any>).map(AccessRightsDtoFromJSON)),
    };
}

export function RoleGroupAccessRightsDtoToJSON(value?: RoleGroupAccessRightsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': RoleGroupsToJSON(value.id),
        'name': value.name,
        'friendlyName': value.friendlyName,
        'description': value.description,
        'accessRights': ((value.accessRights as Array<any>).map(AccessRightsDtoToJSON)),
    };
}

