import { useMemo } from "react";
import { commonUXTheme } from "styles/commonUXVariables";
import {
  HeaderHeight,
  PreferredWideScreenSize,
  KnowledgeWarehouseSearchInputSize,
  KnowledgeWarehouseSearchInputSizeSmall
} from "../components/StyledComponents";
import { MenuBarVm, UserVm } from "api";
import { companiesDropdownWidth } from "../components/CompaniesDropdown";

const defaultFont = [
  commonUXTheme.fonts.families.fontAbbRegular,
  commonUXTheme.fonts.families.fontAbbMedium,
  commonUXTheme.fonts.families.fontAbbLight,
  commonUXTheme.fonts.families.fontAbbBold
].join(",");

const calculateRawTextWidth = (text: string, fontSize: number, font = defaultFont) => {
  // TODO: No need to create a new canvas element each time this is called, the canvas could be reused.
  // Not a big problem currently because this function is only called inside useMemo.
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return null;
  context.font = `${fontSize}px ${font}`;
  const metrics = context.measureText(text);
  const textWidth = metrics.width;

  return textWidth;
};

const calculateMenubarItemWidth = (text: string, fontSize: number, hasSubmenus: boolean) => {
  const textWidth = calculateRawTextWidth(text, fontSize) ?? 0;
  const padding = 32;
  const base = textWidth + padding;
  const submenuWidth = 32;

  const unclampedWidth = base + (hasSubmenus ? submenuWidth : 0);

  return Math.max(unclampedWidth, 100);
};

export const useMenuBarMobileBreakpoint = ({
  applicationName,
  translateText,
  menuVm,
  currentUser,
  shouldDrawCompaniesDropdown,
  shouldDrawWarehouseSearchInput
}: {
  applicationName: string;
  translateText?: (text: string) => string;
  menuVm: MenuBarVm | undefined;
  currentUser: UserVm | undefined;
  shouldDrawCompaniesDropdown: boolean;
  shouldDrawWarehouseSearchInput: boolean;
}) => {
  const applicationNameWidth = useMemo(() => {
    const applicationNameRawTextWidth =
      calculateRawTextWidth(
        applicationName,
        parseInt(commonUXTheme.fonts.sizes.fontSizeM.replace("px", "")),
        commonUXTheme.fonts.families.fontAbbBold
      ) ?? 0;
    const flexGaps = parseInt(commonUXTheme.sizes.s.replace("px", "")) * 2;
    const spacer = 1 + parseInt(commonUXTheme.sizes.s.replace("px", "")) * 2; // width + margins

    const width = applicationNameRawTextWidth + spacer + flexGaps;
    return width;
  }, [applicationName]);

  const companyLabelWidth = useMemo(() => {
    // Also include the "Company:" text
    const text = (translateText ? translateText("Company") : "Company") + ":";
    const companyTextRawWidth =
      calculateRawTextWidth(
        text,
        parseInt(commonUXTheme.fonts.sizes.fontSizeS.replace("px", ""))
      ) ?? 0;

    const margin = parseInt(commonUXTheme.sizes.xs.replace("px", ""));
    const companyTextWidth = companyTextRawWidth + margin * 2; // Left and right margin
    return companyTextWidth;
  }, [translateText]);

  const userLabelWidth = useMemo(() => {
    if (currentUser?.userDetails?.email) {
      // Username/email should be shown
      const userRawWidth =
        calculateRawTextWidth(
          currentUser.userDetails.email,
          parseInt(commonUXTheme.fonts.sizes.fontSizeS.replace("px", ""))
        ) ?? 0;
      const userEmailMargins =
        parseInt(commonUXTheme.sizes.sm.replace("px", "")) +
        parseInt(commonUXTheme.sizes.xs.replace("px", ""));
      const userEmailWidth = userRawWidth + userEmailMargins;
      return userEmailWidth;
    }
    return 0;
  }, [currentUser?.userDetails?.email]);

  const mobileBreakpoint = useMemo(() => {
    let breakpoint = 0;

    const logoWidth = (22 * 8) / 3; // The logo's height is 22px, and the image has a 8:3 aspect ratio
    const logoFullWidth = logoWidth + 2 * parseInt(commonUXTheme.sizes.m.replace("px", "")); // logo + left and right margins

    breakpoint += logoFullWidth;

    if (menuVm?.menuItems?.length) {
      for (let i = 0; i < menuVm.menuItems.length; i++) {
        const menuItem = menuVm.menuItems[i];
        const hasSubmenus = (menuItem.children ?? []).length > 0;
        const text = menuItem.title;
        const fontSize = parseInt(commonUXTheme.fonts.sizes.fontSizeM.replace("px", ""));

        const width = calculateMenubarItemWidth(text, fontSize, hasSubmenus) ?? 0;
        breakpoint += width;
      }
    }

    if (menuVm?.comparisonItems?.length) {
      breakpoint += HeaderHeight;
    }
    if (menuVm?.shoppingCartItems?.length) {
      breakpoint += HeaderHeight;
    }
    if (menuVm?.notificationItems?.length) {
      breakpoint += HeaderHeight;
    }

    if (shouldDrawCompaniesDropdown) {
      breakpoint += companiesDropdownWidth;
    }
    if (shouldDrawWarehouseSearchInput) {
      breakpoint += KnowledgeWarehouseSearchInputSizeSmall + parseInt(commonUXTheme.sizes.s);
    }
    if (menuVm?.userMenuItems?.length) {
      // User is logged in, show user profile icon
      breakpoint += HeaderHeight;
    } else {
      const text = translateText
        ? translateText("Sign in") + " | " + translateText("Create account")
        : "Sign in | Create account";
      const rawWidth =
        calculateRawTextWidth(
          text,
          parseInt(commonUXTheme.fonts.sizes.fontSizeS.replace("px", ""))
        ) ?? 0;
      const margins =
        parseInt(commonUXTheme.sizes.xs.replace("px", "")) +
        parseInt(commonUXTheme.sizes.sm.replace("px", "")) +
        parseInt(commonUXTheme.sizes.sm.replace("px", "")) +
        parseInt(commonUXTheme.sizes.m.replace("px", ""));
      const width = rawWidth + margins;
      breakpoint += width;
    }

    return breakpoint;
  }, [menuVm, shouldDrawCompaniesDropdown, shouldDrawWarehouseSearchInput, translateText]);

  const wideScreenSize = Math.max(
    mobileBreakpoint +
      applicationNameWidth +
      companyLabelWidth +
      userLabelWidth +
      KnowledgeWarehouseSearchInputSize -
      KnowledgeWarehouseSearchInputSizeSmall,
    PreferredWideScreenSize
  );

  return {
    wideScreenSize,
    mobileBreakpoint
  };
};
