import { Action } from "redux";
import { UserWidgetDto } from "../../../api/models/UserWidgetDto";
import { WidgetTypes } from "../../../api/models/WidgetTypes";

export enum DashboardActionTypes {
  ToggleWidgetVisibility = "Dashboard/ToggleWidgetVisibility",
  UpdateUserWidgets = "Dashboard/UpdateUserWidgets"
}

export interface ToggleWidgetVisibility extends Action {
  type: DashboardActionTypes.ToggleWidgetVisibility;
  widgetId: WidgetTypes;
  isVisible: boolean;
}

export interface UpdateUserWidgets extends Action {
  type: DashboardActionTypes.UpdateUserWidgets;
  widgets: UserWidgetDto[];
}

export const dashboardActions = {
  toggleWidgetVisibility: (widgetId: WidgetTypes, isVisible: boolean): ToggleWidgetVisibility => ({
    type: DashboardActionTypes.ToggleWidgetVisibility,
    widgetId,
    isVisible
  }),
  updateUserWidgets: (widgets: UserWidgetDto[]): UpdateUserWidgets => ({
    type: DashboardActionTypes.UpdateUserWidgets,
    widgets
  })
};

export type DashboardActions = UpdateUserWidgets | ToggleWidgetVisibility;
