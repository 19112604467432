/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FrameAgreementSettingsDto,
    FrameAgreementSettingsDtoFromJSON,
    FrameAgreementSettingsDtoFromJSONTyped,
    FrameAgreementSettingsDtoToJSON,
} from './FrameAgreementSettingsDto';
import {
    GeneralApplicationSettingsDto,
    GeneralApplicationSettingsDtoFromJSON,
    GeneralApplicationSettingsDtoFromJSONTyped,
    GeneralApplicationSettingsDtoToJSON,
} from './GeneralApplicationSettingsDto';
import {
    QuoteSettingsDto,
    QuoteSettingsDtoFromJSON,
    QuoteSettingsDtoFromJSONTyped,
    QuoteSettingsDtoToJSON,
} from './QuoteSettingsDto';
import {
    SoftwareProductRedirectsDto,
    SoftwareProductRedirectsDtoFromJSON,
    SoftwareProductRedirectsDtoFromJSONTyped,
    SoftwareProductRedirectsDtoToJSON,
} from './SoftwareProductRedirectsDto';
import {
    SystemSettingDto,
    SystemSettingDtoFromJSON,
    SystemSettingDtoFromJSONTyped,
    SystemSettingDtoToJSON,
} from './SystemSettingDto';

/**
 * 
 * @export
 * @interface SystemSettingsVm
 */
export interface SystemSettingsVm {
    /**
     * 
     * @type {SystemSettingDto}
     * @memberof SystemSettingsVm
     */
    systemSetting: SystemSettingDto;
    /**
     * 
     * @type {GeneralApplicationSettingsDto}
     * @memberof SystemSettingsVm
     */
    generalApplicationSettings: GeneralApplicationSettingsDto;
    /**
     * 
     * @type {number}
     * @memberof SystemSettingsVm
     */
    companyAccessAuditIntervalInDays: number;
    /**
     * 
     * @type {QuoteSettingsDto}
     * @memberof SystemSettingsVm
     */
    quoteSettings?: QuoteSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof SystemSettingsVm
     */
    version: string;
    /**
     * 
     * @type {FrameAgreementSettingsDto}
     * @memberof SystemSettingsVm
     */
    frameAgreementSettings: FrameAgreementSettingsDto;
    /**
     * 
     * @type {SoftwareProductRedirectsDto}
     * @memberof SystemSettingsVm
     */
    softwareProductRedirects: SoftwareProductRedirectsDto;
}

export function SystemSettingsVmFromJSON(json: any): SystemSettingsVm {
    return SystemSettingsVmFromJSONTyped(json, false);
}

export function SystemSettingsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemSettingsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemSetting': SystemSettingDtoFromJSON(json['systemSetting']),
        'generalApplicationSettings': GeneralApplicationSettingsDtoFromJSON(json['generalApplicationSettings']),
        'companyAccessAuditIntervalInDays': json['companyAccessAuditIntervalInDays'],
        'quoteSettings': !exists(json, 'quoteSettings') ? undefined : QuoteSettingsDtoFromJSON(json['quoteSettings']),
        'version': json['version'],
        'frameAgreementSettings': FrameAgreementSettingsDtoFromJSON(json['frameAgreementSettings']),
        'softwareProductRedirects': SoftwareProductRedirectsDtoFromJSON(json['softwareProductRedirects']),
    };
}

export function SystemSettingsVmToJSON(value?: SystemSettingsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systemSetting': SystemSettingDtoToJSON(value.systemSetting),
        'generalApplicationSettings': GeneralApplicationSettingsDtoToJSON(value.generalApplicationSettings),
        'companyAccessAuditIntervalInDays': value.companyAccessAuditIntervalInDays,
        'quoteSettings': QuoteSettingsDtoToJSON(value.quoteSettings),
        'version': value.version,
        'frameAgreementSettings': FrameAgreementSettingsDtoToJSON(value.frameAgreementSettings),
        'softwareProductRedirects': SoftwareProductRedirectsDtoToJSON(value.softwareProductRedirects),
    };
}

