import { UserVm } from "api/models/UserVm";
import React, { FC, useMemo } from "react";
import { NavItem } from "./NavItem";
import {
  getDownMediaQuery,
  IconSuperscriptArea,
  UserInfoText,
  UserWrapper
} from "./StyledComponents";
import { MenuItemTypes } from "api/models/MenuItemTypes";
import { MenuItemSelection } from "models/menuItemSelection";
import { routes } from "utilities/routes";
import { Stack, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UserMenuBarProps {
  currentUser: UserVm | undefined;
  activePath?: string;
  useExternalLinks?: boolean;
  enableReactRouterLinks?: boolean;
  basePath: string;
  menuItem: MenuItemSelection[];
  logOutIdentifier: string;
  isSignedIn?: boolean;
  onClickNavigationItem?: (internalRoute: string, externalRoute?: string | null) => void;
  onSignIn?: () => void;
  onSignOut?: () => void;
  translateText?: (text: string) => string;
  isMobile: boolean;
  wideScreenSize: number;
}

export const UserMenuBar: FC<UserMenuBarProps> = ({
  currentUser,
  activePath,
  useExternalLinks,
  enableReactRouterLinks = true,
  basePath,
  menuItem,
  logOutIdentifier,
  isSignedIn,
  onClickNavigationItem,
  onSignIn,
  onSignOut,
  translateText,
  isMobile,
  wideScreenSize
}: UserMenuBarProps) => {
  const getTranslation = (text: string) => {
    if (translateText) {
      return translateText(text);
    }
    return text;
  };

  const { t } = useTranslation();
  const hasSignedIn = isSignedIn || currentUser !== undefined;

  const isSmallScreen = useMediaQuery(getDownMediaQuery(wideScreenSize), {
    noSsr: true
  });

  const userHasNotCreatedAccount =
    currentUser !== undefined && currentUser.userDetails === undefined;
  //adds the username menu item to the profile submenu items for smaller screens
  const menuItemWithUserName = useMemo(() => {
    if (
      menuItem[0] &&
      menuItem[0].children &&
      menuItem[0].children[0] &&
      !menuItem[0].children[0].isTitleItem
    ) {
      // user has not accepted the privacy consent to create account
      menuItem[0].children[0].hasNotification = userHasNotCreatedAccount;

      if (currentUser && isSmallScreen) {
        menuItem[0].children = [
          {
            title: currentUser?.userInfo?.email ?? "",
            internalRoute: "",
            menuItemTypeId: MenuItemTypes.MenuItem,
            isLogOut: false,
            isDisabled: false,
            isTitleItem: true
          },
          ...menuItem[0].children
        ];
      }
    }
    return menuItem;
  }, [currentUser, menuItem, isSmallScreen, userHasNotCreatedAccount]);

  const onSignInClick = () => {
    if (!hasSignedIn && onSignIn) {
      onSignIn();
    }
  };

  const mobileUserMenuItems = (
    <>
      <div
        onClick={onSignInClick}
        className="ABB_CommonUX_AppTopNavi__item ABB_CommonUX_AppTopNavi__light"
      >
        <div className="ABB_CommonUX_AppTopNavi__container">
          <span className="ABB_CommonUX_AppTopNavi__header ABB_CommonUX_AppTopNavi__textOnly">
            <div className="ABB_CommonUX_AppTopNavi__text"> {`${getTranslation("Sign in")}`}</div>
          </span>
        </div>
      </div>
      <a
        href={`https://www.usermanagement.abb.com/account/Register?returnUrl=${window.location.origin}${routes.registrationCompleted}`}
        className="ABB_CommonUX_AppTopNavi__item ABB_CommonUX_AppTopNavi__light "
      >
        <div className="ABB_CommonUX_AppTopNavi__container">
          <span className="ABB_CommonUX_AppTopNavi__header ABB_CommonUX_AppTopNavi__textOnly">
            <div className="ABB_CommonUX_AppTopNavi__text">
              {" "}
              {`${getTranslation("Create account")}`}
            </div>
          </span>
        </div>
      </a>
    </>
  );

  return (
    <UserWrapper $isMobile={isMobile}>
      <Stack display={hasSignedIn && isSmallScreen ? "none" : "block"}>
        <UserInfoText isSignedIn={hasSignedIn} $isMobile={isMobile}>
          {hasSignedIn ? (
            isSmallScreen ? (
              ""
            ) : (
              currentUser?.userInfo?.email ?? ""
            )
          ) : isMobile ? (
            <>{mobileUserMenuItems}</>
          ) : (
            <span>
              <span onClick={onSignInClick}>{`${getTranslation("Sign in")} `}</span>
              <span> | </span>
              <a
                style={{
                  color: "inherit"
                }}
                href={`https://www.usermanagement.abb.com/account/Register?returnUrl=${window.location.origin}${routes.registrationCompleted}`}
              >
                {getTranslation("Create account")}
              </a>
            </span>
          )}
        </UserInfoText>
      </Stack>
      {hasSignedIn ? (
        <>
          <NavItem
            isMobile={isMobile}
            onSelect={(
              internalRoute: string,
              externalRoute: string | null | undefined,
              isLogOut: boolean
            ) => {
              if (isLogOut) {
                onSignOut && onSignOut();
              } else {
                onClickNavigationItem && onClickNavigationItem(internalRoute, externalRoute);
              }
            }}
            activePath={activePath}
            baseUrl={basePath}
            enableReactRouterLinks={enableReactRouterLinks}
            useExternalLinks={useExternalLinks}
            menuItem={
              menuItemWithUserName.length > 0
                ? menuItemWithUserName[0]
                : {
                    // fallback, if fetching menu items from the backend fails.
                    title: "",
                    internalRoute: logOutIdentifier,
                    menuItemTypeId: MenuItemTypes.UserMenu,
                    isDisabled: false,
                    isLogOut: false,
                    children: [
                      {
                        title: "Log out",
                        internalRoute: logOutIdentifier,
                        menuItemTypeId: MenuItemTypes.UserMenu,
                        isDisabled: false,
                        isLogOut: true,
                        children: []
                      }
                    ]
                  }
            }
          ></NavItem>
          {currentUser !== undefined && currentUser.userDetails == undefined && (
            <IconSuperscriptArea
              title={t(
                "Please accept privacy consent to create a user account and unlock features in the application"
              )}
              $isMobile={isMobile}
            >
              !
            </IconSuperscriptArea>
          )}
        </>
      ) : null}
    </UserWrapper>
  );
};
