/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    LifecycleStatusesVm,
    LifecycleStatusesVmFromJSON,
    LifecycleStatusesVmToJSON,
} from '../models';

export interface ApiLifecycleManagementProductLifecycleStatusesGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaKeywords?: Array<string>;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

/**
 * 
 */
export class LifecycleManagementApi extends runtime.BaseAPI {

    /**
     */
    async apiLifecycleManagementProductLifecycleStatusesGetRaw(requestParameters: ApiLifecycleManagementProductLifecycleStatusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LifecycleStatusesVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiLifecycleManagementProductLifecycleStatusesGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiLifecycleManagementProductLifecycleStatusesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaKeywords) {
            queryParameters['Criteria.Keywords'] = requestParameters.criteriaKeywords;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/LifecycleManagement/product-lifecycle-statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LifecycleStatusesVmFromJSON(jsonValue));
    }

    /**
     */
    async apiLifecycleManagementProductLifecycleStatusesGet(requestParameters: ApiLifecycleManagementProductLifecycleStatusesGetRequest, initOverrides?: RequestInit): Promise<LifecycleStatusesVm> {
        const response = await this.apiLifecycleManagementProductLifecycleStatusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
