/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessRequestDto,
    AccessRequestDtoFromJSON,
    AccessRequestDtoToJSON,
    AccessRequestMetadataVm,
    AccessRequestMetadataVmFromJSON,
    AccessRequestMetadataVmToJSON,
    AccessRequestStatusTypes,
    AccessRequestStatusTypesFromJSON,
    AccessRequestStatusTypesToJSON,
    AccessRequestsVm,
    AccessRequestsVmFromJSON,
    AccessRequestsVmToJSON,
    CreateAccessRequestCommand,
    CreateAccessRequestCommandFromJSON,
    CreateAccessRequestCommandToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsToJSON,
    UpdateAccessRequestCommand,
    UpdateAccessRequestCommandFromJSON,
    UpdateAccessRequestCommandToJSON,
    UpdateAccessRequestCompanyCommand,
    UpdateAccessRequestCompanyCommandFromJSON,
    UpdateAccessRequestCompanyCommandToJSON,
} from '../models';

export interface ApiAccessRequestsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaAssigneeEmail?: string;
    criteriaFirstName?: string;
    criteriaLastName?: string;
    criteriaEmail?: string;
    criteriaUserCompanyName?: string;
    criteriaPosition?: string;
    criteriaCountryCode?: string;
    criteriaComment?: string;
    criteriaCreatedFrom?: Date;
    criteriaCreatedTo?: Date;
    criteriaStatuses?: Array<AccessRequestStatusTypes>;
    criteriaRoleGroups?: Array<RoleGroups>;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiAccessRequestsMatchedcompanyPatchRequest {
    updateAccessRequestCompanyCommand?: UpdateAccessRequestCompanyCommand;
}

export interface ApiAccessRequestsMetadataGetRequest {
    roleGroup?: RoleGroups;
}

export interface ApiAccessRequestsPostRequest {
    createAccessRequestCommand?: CreateAccessRequestCommand;
}

export interface ApiAccessRequestsPutRequest {
    updateAccessRequestCommand?: UpdateAccessRequestCommand;
}

/**
 * 
 */
export class AccessRequestsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccessRequestsGetRaw(requestParameters: ApiAccessRequestsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessRequestsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiAccessRequestsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiAccessRequestsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaAssigneeEmail !== undefined) {
            queryParameters['Criteria.AssigneeEmail'] = requestParameters.criteriaAssigneeEmail;
        }

        if (requestParameters.criteriaFirstName !== undefined) {
            queryParameters['Criteria.FirstName'] = requestParameters.criteriaFirstName;
        }

        if (requestParameters.criteriaLastName !== undefined) {
            queryParameters['Criteria.LastName'] = requestParameters.criteriaLastName;
        }

        if (requestParameters.criteriaEmail !== undefined) {
            queryParameters['Criteria.Email'] = requestParameters.criteriaEmail;
        }

        if (requestParameters.criteriaUserCompanyName !== undefined) {
            queryParameters['Criteria.UserCompanyName'] = requestParameters.criteriaUserCompanyName;
        }

        if (requestParameters.criteriaPosition !== undefined) {
            queryParameters['Criteria.Position'] = requestParameters.criteriaPosition;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaComment !== undefined) {
            queryParameters['Criteria.Comment'] = requestParameters.criteriaComment;
        }

        if (requestParameters.criteriaCreatedFrom !== undefined) {
            queryParameters['Criteria.CreatedFrom'] = (requestParameters.criteriaCreatedFrom as any).toISOString();
        }

        if (requestParameters.criteriaCreatedTo !== undefined) {
            queryParameters['Criteria.CreatedTo'] = (requestParameters.criteriaCreatedTo as any).toISOString();
        }

        if (requestParameters.criteriaStatuses) {
            queryParameters['Criteria.Statuses'] = requestParameters.criteriaStatuses;
        }

        if (requestParameters.criteriaRoleGroups) {
            queryParameters['Criteria.RoleGroups'] = requestParameters.criteriaRoleGroups;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRequests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsGet(requestParameters: ApiAccessRequestsGetRequest, initOverrides?: RequestInit): Promise<AccessRequestsVm> {
        const response = await this.apiAccessRequestsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsMatchedcompanyPatchRaw(requestParameters: ApiAccessRequestsMatchedcompanyPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessRequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRequests/matchedcompany`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccessRequestCompanyCommandToJSON(requestParameters.updateAccessRequestCompanyCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsMatchedcompanyPatch(requestParameters: ApiAccessRequestsMatchedcompanyPatchRequest, initOverrides?: RequestInit): Promise<AccessRequestDto> {
        const response = await this.apiAccessRequestsMatchedcompanyPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsMetadataGetRaw(requestParameters: ApiAccessRequestsMetadataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessRequestMetadataVm>> {
        const queryParameters: any = {};

        if (requestParameters.roleGroup !== undefined) {
            queryParameters['RoleGroup'] = requestParameters.roleGroup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRequests/metadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestMetadataVmFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsMetadataGet(requestParameters: ApiAccessRequestsMetadataGetRequest, initOverrides?: RequestInit): Promise<AccessRequestMetadataVm> {
        const response = await this.apiAccessRequestsMetadataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccessRequestsPostRaw(requestParameters: ApiAccessRequestsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccessRequestCommandToJSON(requestParameters.createAccessRequestCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccessRequestsPost(requestParameters: ApiAccessRequestsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccessRequestsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccessRequestsPutRaw(requestParameters: ApiAccessRequestsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccessRequestDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRequests`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccessRequestCommandToJSON(requestParameters.updateAccessRequestCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessRequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiAccessRequestsPut(requestParameters: ApiAccessRequestsPutRequest, initOverrides?: RequestInit): Promise<AccessRequestDto> {
        const response = await this.apiAccessRequestsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
