/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderLineItemQuantity,
    OrderLineItemQuantityFromJSON,
    OrderLineItemQuantityFromJSONTyped,
    OrderLineItemQuantityToJSON,
} from './OrderLineItemQuantity';

/**
 * 
 * @export
 * @interface ShippingDetailsDto
 */
export interface ShippingDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ShippingDetailsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    orderAcknowledgementNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    orderLineItem: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    shippingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    invoiceNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingDetailsDto
     */
    shippedQuantity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    forwarder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    trackingNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShippingDetailsDto
     */
    trackingLink?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingDetailsDto
     */
    packingListDocumentsAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingDetailsDto
     */
    packingDetailsDocumentsAvailable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ShippingDetailsDto
     */
    shipped?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShippingDetailsDto
     */
    orderLineItems?: Array<string> | null;
    /**
     * 
     * @type {Array<OrderLineItemQuantity>}
     * @memberof ShippingDetailsDto
     */
    shippedQuantities?: Array<OrderLineItemQuantity> | null;
}

export function ShippingDetailsDtoFromJSON(json: any): ShippingDetailsDto {
    return ShippingDetailsDtoFromJSONTyped(json, false);
}

export function ShippingDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderAcknowledgementNumber': json['orderAcknowledgementNumber'],
        'orderLineItem': json['orderLineItem'],
        'shippingNumber': json['shippingNumber'],
        'invoiceNumber': json['invoiceNumber'],
        'shippedQuantity': !exists(json, 'shippedQuantity') ? undefined : json['shippedQuantity'],
        'forwarder': !exists(json, 'forwarder') ? undefined : json['forwarder'],
        'trackingNumber': !exists(json, 'trackingNumber') ? undefined : json['trackingNumber'],
        'trackingLink': !exists(json, 'trackingLink') ? undefined : json['trackingLink'],
        'packingListDocumentsAvailable': json['packingListDocumentsAvailable'],
        'packingDetailsDocumentsAvailable': json['packingDetailsDocumentsAvailable'],
        'shipped': !exists(json, 'shipped') ? undefined : (json['shipped'] === null ? null : new Date(json['shipped'])),
        'orderLineItems': !exists(json, 'orderLineItems') ? undefined : json['orderLineItems'],
        'shippedQuantities': !exists(json, 'shippedQuantities') ? undefined : (json['shippedQuantities'] === null ? null : (json['shippedQuantities'] as Array<any>).map(OrderLineItemQuantityFromJSON)),
    };
}

export function ShippingDetailsDtoToJSON(value?: ShippingDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'orderLineItem': value.orderLineItem,
        'shippingNumber': value.shippingNumber,
        'invoiceNumber': value.invoiceNumber,
        'shippedQuantity': value.shippedQuantity,
        'forwarder': value.forwarder,
        'trackingNumber': value.trackingNumber,
        'trackingLink': value.trackingLink,
        'packingListDocumentsAvailable': value.packingListDocumentsAvailable,
        'packingDetailsDocumentsAvailable': value.packingDetailsDocumentsAvailable,
        'shipped': value.shipped === undefined ? undefined : (value.shipped === null ? null : value.shipped.toISOString()),
        'orderLineItems': value.orderLineItems,
        'shippedQuantities': value.shippedQuantities === undefined ? undefined : (value.shippedQuantities === null ? null : (value.shippedQuantities as Array<any>).map(OrderLineItemQuantityToJSON)),
    };
}

