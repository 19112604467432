/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuoteSettingsDto
 */
export interface QuoteSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof QuoteSettingsDto
     */
    maxQuoteFileSizeInMb?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteSettingsDto
     */
    maxQuoteFileCountUploaded?: number;
    /**
     * 
     * @type {number}
     * @memberof QuoteSettingsDto
     */
    defaultValidityMonths?: number;
}

export function QuoteSettingsDtoFromJSON(json: any): QuoteSettingsDto {
    return QuoteSettingsDtoFromJSONTyped(json, false);
}

export function QuoteSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteSettingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxQuoteFileSizeInMb': !exists(json, 'maxQuoteFileSizeInMb') ? undefined : json['maxQuoteFileSizeInMb'],
        'maxQuoteFileCountUploaded': !exists(json, 'maxQuoteFileCountUploaded') ? undefined : json['maxQuoteFileCountUploaded'],
        'defaultValidityMonths': !exists(json, 'defaultValidityMonths') ? undefined : json['defaultValidityMonths'],
    };
}

export function QuoteSettingsDtoToJSON(value?: QuoteSettingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxQuoteFileSizeInMb': value.maxQuoteFileSizeInMb,
        'maxQuoteFileCountUploaded': value.maxQuoteFileCountUploaded,
        'defaultValidityMonths': value.defaultValidityMonths,
    };
}

