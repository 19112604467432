/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleTypes,
    RoleTypesFromJSON,
    RoleTypesFromJSONTyped,
    RoleTypesToJSON,
} from './RoleTypes';

/**
 * 
 * @export
 * @interface AccessRightRoleDto
 */
export interface AccessRightRoleDto {
    /**
     * 
     * @type {number}
     * @memberof AccessRightRoleDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRightRoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRightRoleDto
     */
    abbreviation: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRightRoleDto
     */
    description?: string | null;
    /**
     * 
     * @type {RoleTypes}
     * @memberof AccessRightRoleDto
     */
    roleTypeId: RoleTypes;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRightRoleDto
     */
    defaultRole: boolean;
}

export function AccessRightRoleDtoFromJSON(json: any): AccessRightRoleDto {
    return AccessRightRoleDtoFromJSONTyped(json, false);
}

export function AccessRightRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRightRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'abbreviation': json['abbreviation'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'roleTypeId': RoleTypesFromJSON(json['roleTypeId']),
        'defaultRole': json['defaultRole'],
    };
}

export function AccessRightRoleDtoToJSON(value?: AccessRightRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'abbreviation': value.abbreviation,
        'description': value.description,
        'roleTypeId': RoleTypesToJSON(value.roleTypeId),
        'defaultRole': value.defaultRole,
    };
}

