/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    azureAdId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {CompanyDto}
     * @memberof UserDto
     */
    homeCompany?: CompanyDto;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isGuest: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    position: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    userRoles: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastActive?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    countryCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isExternal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isInternal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    externalSource?: string | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'azureAdId': json['azureAdId'],
        'isDeleted': json['isDeleted'],
        'homeCompany': !exists(json, 'homeCompany') ? undefined : CompanyDtoFromJSON(json['homeCompany']),
        'active': json['active'],
        'isGuest': json['isGuest'],
        'position': json['position'],
        'userRoles': json['userRoles'],
        'lastActive': !exists(json, 'lastActive') ? undefined : (json['lastActive'] === null ? null : new Date(json['lastActive'])),
        'countryCode': json['countryCode'],
        'isExternal': !exists(json, 'isExternal') ? undefined : json['isExternal'],
        'isInternal': !exists(json, 'isInternal') ? undefined : json['isInternal'],
        'externalSource': !exists(json, 'externalSource') ? undefined : json['externalSource'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'azureAdId': value.azureAdId,
        'isDeleted': value.isDeleted,
        'homeCompany': CompanyDtoToJSON(value.homeCompany),
        'active': value.active,
        'isGuest': value.isGuest,
        'position': value.position,
        'userRoles': value.userRoles,
        'lastActive': value.lastActive === undefined ? undefined : (value.lastActive === null ? null : value.lastActive.toISOString()),
        'countryCode': value.countryCode,
        'isExternal': value.isExternal,
        'isInternal': value.isInternal,
        'externalSource': value.externalSource,
    };
}

