/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactOrderHandlingReasons {
    CertificateRequest = 'CertificateRequest',
    OrderDetailsMissing = 'OrderDetailsMissing',
    SerialNumbersMissing = 'SerialNumbersMissing',
    Other = 'Other'
}

export function ContactOrderHandlingReasonsFromJSON(json: any): ContactOrderHandlingReasons {
    return ContactOrderHandlingReasonsFromJSONTyped(json, false);
}

export function ContactOrderHandlingReasonsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactOrderHandlingReasons {
    return json as ContactOrderHandlingReasons;
}

export function ContactOrderHandlingReasonsToJSON(value?: ContactOrderHandlingReasons | null): any {
    return value as any;
}

