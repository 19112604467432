import { Button } from "framework/components/Button";
import { Dialog } from "framework/components/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";

export interface WarnCompanyDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
}

export const WarnCompanyDialog: React.FunctionComponent<WarnCompanyDialogProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={props.isDialogOpen} onClose={props.onClose} title={t("Company required")}>
      <p>{t("Please select a company to see the its available subscriptions.")} </p>
      <Button
        style={{ marginLeft: "auto" }}
        buttonType="primary"
        text={t("Ok")}
        onClick={props.onClose}
      />
    </Dialog>
  );
};
