/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTrainingNodeCommand,
    AddTrainingNodeCommandFromJSON,
    AddTrainingNodeCommandToJSON,
    ArticleDto,
    ArticleDtoFromJSON,
    ArticleDtoToJSON,
    ArticleSource,
    ArticleSourceFromJSON,
    ArticleSourceToJSON,
    DeleteNodeCommand,
    DeleteNodeCommandFromJSON,
    DeleteNodeCommandToJSON,
    FreeAndAssignedArticleDto,
    FreeAndAssignedArticleDtoFromJSON,
    FreeAndAssignedArticleDtoToJSON,
    GetSearchArticlesQuery,
    GetSearchArticlesQueryFromJSON,
    GetSearchArticlesQueryToJSON,
    NodeDto,
    NodeDtoFromJSON,
    NodeDtoToJSON,
    NodeFlattenedDto,
    NodeFlattenedDtoFromJSON,
    NodeFlattenedDtoToJSON,
    NodeType,
    NodeTypeFromJSON,
    NodeTypeToJSON,
    NodeWithPath,
    NodeWithPathFromJSON,
    NodeWithPathToJSON,
    SearchResponseDto,
    SearchResponseDtoFromJSON,
    SearchResponseDtoToJSON,
    SendFeedbackCommand,
    SendFeedbackCommandFromJSON,
    SendFeedbackCommandToJSON,
    UpdateArticleCommand,
    UpdateArticleCommandFromJSON,
    UpdateArticleCommandToJSON,
    UpdateArticleNodeCommand,
    UpdateArticleNodeCommandFromJSON,
    UpdateArticleNodeCommandToJSON,
    UpdateTrainingNodeCommand,
    UpdateTrainingNodeCommandFromJSON,
    UpdateTrainingNodeCommandToJSON,
} from '../models';

export interface ApiTrainingArticleIdGetRequest {
    id: number;
}

export interface ApiTrainingArticlePutRequest {
    updateArticleCommand?: UpdateArticleCommand;
}

export interface ApiTrainingArticlesFreeAndAssignedGetRequest {
    nodeId?: string;
    source?: ArticleSource;
}

export interface ApiTrainingArticlesGetRequest {
    nodeId?: string;
    source?: ArticleSource;
    getRecent?: number;
}

export interface ApiTrainingAttachmentGetRequest {
    attachmentId: number;
    fileName: string;
}

export interface ApiTrainingFeedbackPostRequest {
    sendFeedbackCommand?: SendFeedbackCommand;
}

export interface ApiTrainingNodeDeleteRequest {
    deleteNodeCommand?: DeleteNodeCommand;
}

export interface ApiTrainingNodePostRequest {
    addTrainingNodeCommand?: AddTrainingNodeCommand;
}

export interface ApiTrainingNodePrevAndNextGetRequest {
    id?: string;
}

export interface ApiTrainingNodePutRequest {
    updateTrainingNodeCommand?: UpdateTrainingNodeCommand;
}

export interface ApiTrainingNodesFlatGetRequest {
    nodeType?: NodeType;
}

export interface ApiTrainingNodesRootsGetRequest {
    nodeType?: NodeType;
}

export interface ApiTrainingNodesWithPathGetRequest {
    nodeType?: NodeType;
}

export interface ApiTrainingSearchArticlePostRequest {
    getSearchArticlesQuery?: GetSearchArticlesQuery;
}

export interface ApiTrainingUpdateArticleNodePutRequest {
    updateArticleNodeCommand?: UpdateArticleNodeCommand;
}

/**
 * 
 */
export class TrainingApi extends runtime.BaseAPI {

    /**
     */
    async apiTrainingArticleIdGetRaw(requestParameters: ApiTrainingArticleIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTrainingArticleIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingArticleIdGet(requestParameters: ApiTrainingArticleIdGetRequest, initOverrides?: RequestInit): Promise<ArticleDto> {
        const response = await this.apiTrainingArticleIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingArticlePutRaw(requestParameters: ApiTrainingArticlePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ArticleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/article`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateArticleCommandToJSON(requestParameters.updateArticleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingArticlePut(requestParameters: ApiTrainingArticlePutRequest, initOverrides?: RequestInit): Promise<ArticleDto> {
        const response = await this.apiTrainingArticlePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingArticlesFreeAndAssignedGetRaw(requestParameters: ApiTrainingArticlesFreeAndAssignedGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FreeAndAssignedArticleDto>> {
        const queryParameters: any = {};

        if (requestParameters.nodeId !== undefined) {
            queryParameters['NodeId'] = requestParameters.nodeId;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['Source'] = requestParameters.source;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/articles-free-and-assigned`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FreeAndAssignedArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingArticlesFreeAndAssignedGet(requestParameters: ApiTrainingArticlesFreeAndAssignedGetRequest, initOverrides?: RequestInit): Promise<FreeAndAssignedArticleDto> {
        const response = await this.apiTrainingArticlesFreeAndAssignedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingArticlesGetRaw(requestParameters: ApiTrainingArticlesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        const queryParameters: any = {};

        if (requestParameters.nodeId !== undefined) {
            queryParameters['NodeId'] = requestParameters.nodeId;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['Source'] = requestParameters.source;
        }

        if (requestParameters.getRecent !== undefined) {
            queryParameters['GetRecent'] = requestParameters.getRecent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/articles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     */
    async apiTrainingArticlesGet(requestParameters: ApiTrainingArticlesGetRequest, initOverrides?: RequestInit): Promise<Array<ArticleDto>> {
        const response = await this.apiTrainingArticlesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingAttachmentGetRaw(requestParameters: ApiTrainingAttachmentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling apiTrainingAttachmentGet.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling apiTrainingAttachmentGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.attachmentId !== undefined) {
            queryParameters['AttachmentId'] = requestParameters.attachmentId;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['FileName'] = requestParameters.fileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/attachment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTrainingAttachmentGet(requestParameters: ApiTrainingAttachmentGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTrainingAttachmentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingFeedbackPostRaw(requestParameters: ApiTrainingFeedbackPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendFeedbackCommandToJSON(requestParameters.sendFeedbackCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingFeedbackPost(requestParameters: ApiTrainingFeedbackPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingFeedbackPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTrainingNodeDeleteRaw(requestParameters: ApiTrainingNodeDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/node`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteNodeCommandToJSON(requestParameters.deleteNodeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiTrainingNodeDelete(requestParameters: ApiTrainingNodeDeleteRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiTrainingNodeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingNodePostRaw(requestParameters: ApiTrainingNodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/node`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTrainingNodeCommandToJSON(requestParameters.addTrainingNodeCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingNodePost(requestParameters: ApiTrainingNodePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingNodePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTrainingNodePrevAndNextGetRaw(requestParameters: ApiTrainingNodePrevAndNextGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NodeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/node/prev-and-next`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeDtoFromJSON));
    }

    /**
     */
    async apiTrainingNodePrevAndNextGet(requestParameters: ApiTrainingNodePrevAndNextGetRequest, initOverrides?: RequestInit): Promise<Array<NodeDto>> {
        const response = await this.apiTrainingNodePrevAndNextGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingNodePutRaw(requestParameters: ApiTrainingNodePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NodeWithPath>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/node`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTrainingNodeCommandToJSON(requestParameters.updateTrainingNodeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeWithPathFromJSON));
    }

    /**
     */
    async apiTrainingNodePut(requestParameters: ApiTrainingNodePutRequest, initOverrides?: RequestInit): Promise<Array<NodeWithPath>> {
        const response = await this.apiTrainingNodePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingNodesFlatGetRaw(requestParameters: ApiTrainingNodesFlatGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NodeFlattenedDto>>> {
        const queryParameters: any = {};

        if (requestParameters.nodeType !== undefined) {
            queryParameters['NodeType'] = requestParameters.nodeType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/nodes/flat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeFlattenedDtoFromJSON));
    }

    /**
     */
    async apiTrainingNodesFlatGet(requestParameters: ApiTrainingNodesFlatGetRequest, initOverrides?: RequestInit): Promise<Array<NodeFlattenedDto>> {
        const response = await this.apiTrainingNodesFlatGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingNodesRootsGetRaw(requestParameters: ApiTrainingNodesRootsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NodeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.nodeType !== undefined) {
            queryParameters['NodeType'] = requestParameters.nodeType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/nodes/roots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeDtoFromJSON));
    }

    /**
     */
    async apiTrainingNodesRootsGet(requestParameters: ApiTrainingNodesRootsGetRequest, initOverrides?: RequestInit): Promise<Array<NodeDto>> {
        const response = await this.apiTrainingNodesRootsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingNodesWithPathGetRaw(requestParameters: ApiTrainingNodesWithPathGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NodeWithPath>>> {
        const queryParameters: any = {};

        if (requestParameters.nodeType !== undefined) {
            queryParameters['NodeType'] = requestParameters.nodeType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/nodes-with-path`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NodeWithPathFromJSON));
    }

    /**
     */
    async apiTrainingNodesWithPathGet(requestParameters: ApiTrainingNodesWithPathGetRequest, initOverrides?: RequestInit): Promise<Array<NodeWithPath>> {
        const response = await this.apiTrainingNodesWithPathGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingPublishArticlesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/publish-articles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiTrainingPublishArticlesGet(initOverrides?: RequestInit): Promise<object> {
        const response = await this.apiTrainingPublishArticlesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingRetrieveArticlesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/retrieve-articles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingRetrieveArticlesGet(initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingRetrieveArticlesGetRaw(initOverrides);
    }

    /**
     */
    async apiTrainingSearchArticlePostRaw(requestParameters: ApiTrainingSearchArticlePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/searchArticle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetSearchArticlesQueryToJSON(requestParameters.getSearchArticlesQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTrainingSearchArticlePost(requestParameters: ApiTrainingSearchArticlePostRequest, initOverrides?: RequestInit): Promise<SearchResponseDto> {
        const response = await this.apiTrainingSearchArticlePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTrainingUpdateArticleNodePutRaw(requestParameters: ApiTrainingUpdateArticleNodePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Training/update-article-node`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateArticleNodeCommandToJSON(requestParameters.updateArticleNodeCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTrainingUpdateArticleNodePut(requestParameters: ApiTrainingUpdateArticleNodePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTrainingUpdateArticleNodePutRaw(requestParameters, initOverrides);
    }

}
