/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuoteAttachmentDto
 */
export interface QuoteAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof QuoteAttachmentDto
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteAttachmentDto
     */
    name: string;
}

export function QuoteAttachmentDtoFromJSON(json: any): QuoteAttachmentDto {
    return QuoteAttachmentDtoFromJSONTyped(json, false);
}

export function QuoteAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'name': json['name'],
    };
}

export function QuoteAttachmentDtoToJSON(value?: QuoteAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'name': value.name,
    };
}

