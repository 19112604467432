/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailRegistrationStatus,
    EmailRegistrationStatusFromJSON,
    EmailRegistrationStatusFromJSONTyped,
    EmailRegistrationStatusToJSON,
} from './EmailRegistrationStatus';

/**
 * 
 * @export
 * @interface SoftwareSubscriptionEmailRegistrationSentDto
 */
export interface SoftwareSubscriptionEmailRegistrationSentDto {
    /**
     * 
     * @type {number}
     * @memberof SoftwareSubscriptionEmailRegistrationSentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionEmailRegistrationSentDto
     */
    softwarePurchaseEmail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareSubscriptionEmailRegistrationSentDto
     */
    requestCreated?: Date | null;
    /**
     * 
     * @type {EmailRegistrationStatus}
     * @memberof SoftwareSubscriptionEmailRegistrationSentDto
     */
    statusRegistration?: EmailRegistrationStatus;
    /**
     * 
     * @type {number}
     * @memberof SoftwareSubscriptionEmailRegistrationSentDto
     */
    shoppingCartItemId?: number;
}

export function SoftwareSubscriptionEmailRegistrationSentDtoFromJSON(json: any): SoftwareSubscriptionEmailRegistrationSentDto {
    return SoftwareSubscriptionEmailRegistrationSentDtoFromJSONTyped(json, false);
}

export function SoftwareSubscriptionEmailRegistrationSentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareSubscriptionEmailRegistrationSentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'softwarePurchaseEmail': !exists(json, 'softwarePurchaseEmail') ? undefined : json['softwarePurchaseEmail'],
        'requestCreated': !exists(json, 'requestCreated') ? undefined : (json['requestCreated'] === null ? null : new Date(json['requestCreated'])),
        'statusRegistration': !exists(json, 'statusRegistration') ? undefined : EmailRegistrationStatusFromJSON(json['statusRegistration']),
        'shoppingCartItemId': !exists(json, 'shoppingCartItemId') ? undefined : json['shoppingCartItemId'],
    };
}

export function SoftwareSubscriptionEmailRegistrationSentDtoToJSON(value?: SoftwareSubscriptionEmailRegistrationSentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'softwarePurchaseEmail': value.softwarePurchaseEmail,
        'requestCreated': value.requestCreated === undefined ? undefined : (value.requestCreated === null ? null : value.requestCreated.toISOString()),
        'statusRegistration': EmailRegistrationStatusToJSON(value.statusRegistration),
        'shoppingCartItemId': value.shoppingCartItemId,
    };
}

