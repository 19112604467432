/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryTimeDto
 */
export interface DeliveryTimeDto {
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    productLineGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    exceptionInOrderCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeDto
     */
    maxLotSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeDto
     */
    maxLotSize2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    deliveryTimeWithUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    deliveryTimeWithUnit2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeDto
     */
    expeditedDeliveryTimeWeeks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeDto
     */
    expeditedMaxLotSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeDto
     */
    comments?: string | null;
}

export function DeliveryTimeDtoFromJSON(json: any): DeliveryTimeDto {
    return DeliveryTimeDtoFromJSONTyped(json, false);
}

export function DeliveryTimeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryTimeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'productLineGroup': !exists(json, 'productLineGroup') ? undefined : json['productLineGroup'],
        'exceptionInOrderCode': !exists(json, 'exceptionInOrderCode') ? undefined : json['exceptionInOrderCode'],
        'maxLotSize': !exists(json, 'maxLotSize') ? undefined : json['maxLotSize'],
        'maxLotSize2': !exists(json, 'maxLotSize2') ? undefined : json['maxLotSize2'],
        'deliveryTimeWithUnit': !exists(json, 'deliveryTimeWithUnit') ? undefined : json['deliveryTimeWithUnit'],
        'deliveryTimeWithUnit2': !exists(json, 'deliveryTimeWithUnit2') ? undefined : json['deliveryTimeWithUnit2'],
        'expeditedDeliveryTimeWeeks': !exists(json, 'expeditedDeliveryTimeWeeks') ? undefined : json['expeditedDeliveryTimeWeeks'],
        'expeditedMaxLotSize': !exists(json, 'expeditedMaxLotSize') ? undefined : json['expeditedMaxLotSize'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function DeliveryTimeDtoToJSON(value?: DeliveryTimeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'productLineGroup': value.productLineGroup,
        'exceptionInOrderCode': value.exceptionInOrderCode,
        'maxLotSize': value.maxLotSize,
        'maxLotSize2': value.maxLotSize2,
        'deliveryTimeWithUnit': value.deliveryTimeWithUnit,
        'deliveryTimeWithUnit2': value.deliveryTimeWithUnit2,
        'expeditedDeliveryTimeWeeks': value.expeditedDeliveryTimeWeeks,
        'expeditedMaxLotSize': value.expeditedMaxLotSize,
        'comments': value.comments,
    };
}

