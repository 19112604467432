import React from "react";
import styled, { CSSProperties } from "styled-components";
import { SectionHeader } from "framework/components/styled/SectionHeader";

const Container = styled.div<{ flex: number }>`
  background-color: ${(props) => props.theme.colors.whiteTertiary};
  padding: ${(props) => props.theme.sizes.lm};
  min-width: 400px;
  width: 100%;
  flex: ${(props) => props.flex};
`;

const ChildrenContainer = styled.div<{ gap?: CSSProperties["gap"] }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.gap !== undefined ? `gap: ${props.gap};` : "")}
`;

const CustomSectionHeader = styled(SectionHeader)`
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

type ContentContainerProps = {
  title: string;
  children: React.ReactNode[] | React.ReactNode;
  flex?: number;
  gap?: CSSProperties["gap"];
};

export const ContentContainer = ({
  title,
  children,
  flex = 1,
  gap
}: ContentContainerProps): JSX.Element => (
  <Container flex={flex}>
    <CustomSectionHeader text={title}></CustomSectionHeader>
    <ChildrenContainer gap={gap}>{children}</ChildrenContainer>
  </Container>
);
