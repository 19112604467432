/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddOrUpdateManagedPriceListCommand
 */
export interface AddOrUpdateManagedPriceListCommand {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    priceListId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    countryCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    multiplier?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    masterRow?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    isFallback?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AddOrUpdateManagedPriceListCommand
     */
    validityDate?: Date | null;
}

export function AddOrUpdateManagedPriceListCommandFromJSON(json: any): AddOrUpdateManagedPriceListCommand {
    return AddOrUpdateManagedPriceListCommandFromJSONTyped(json, false);
}

export function AddOrUpdateManagedPriceListCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrUpdateManagedPriceListCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'priceListId': !exists(json, 'priceListId') ? undefined : json['priceListId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'multiplier': !exists(json, 'multiplier') ? undefined : json['multiplier'],
        'masterRow': !exists(json, 'masterRow') ? undefined : json['masterRow'],
        'isFallback': !exists(json, 'isFallback') ? undefined : json['isFallback'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'validityDate': !exists(json, 'validityDate') ? undefined : (json['validityDate'] === null ? null : new Date(json['validityDate'])),
    };
}

export function AddOrUpdateManagedPriceListCommandToJSON(value?: AddOrUpdateManagedPriceListCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'priceListId': value.priceListId,
        'companyId': value.companyId,
        'countryCode': value.countryCode,
        'multiplier': value.multiplier,
        'masterRow': value.masterRow,
        'isFallback': value.isFallback,
        'active': value.active,
        'validityDate': value.validityDate === undefined ? undefined : (value.validityDate === null ? null : value.validityDate.toISOString()),
    };
}

