import { Dialog } from "@abb/abb-common-ux-react";
import { getRejectQuoteStatus } from "applications/common/reducers/shoppingCartReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { Button } from "framework/components/Button";
import { HorizontalElementContainer } from "framework/components/HorizontalElementContainer";
import { StatusBanner } from "framework/components/StatusBanner";
import { ResizableInput } from "framework/components/styled/ResizableInput";
import { usePrevious } from "framework/hooks/usePrevious";
import { RequestStatus } from "framework/state/requestStatus";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  min-width: 300px;
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

interface RejectQuoteDialogProps {
  t: TFunction;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFrameAgreement?: boolean;
  shoppingCartGuid: string;
}

const maxCharacterCount = 256;

export const RejectQuoteDialog = ({
  shoppingCartGuid,
  t,
  isOpen,
  setIsOpen,
  isFrameAgreement
}: RejectQuoteDialogProps) => {
  const theme = useTheme();
  const rejectQuoteStatus = useSelector(getRejectQuoteStatus);
  const prevRejectQuoteStatus = usePrevious(rejectQuoteStatus);

  const [rejectMessage, setRejectMessage] = useState<string | undefined>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      rejectQuoteStatus?.status === RequestStatus.Completed &&
      prevRejectQuoteStatus?.status === RequestStatus.Pending
    ) {
      setIsOpen(false);
    }
  }, [rejectQuoteStatus, prevRejectQuoteStatus, setIsOpen]);

  const rejectQuote = (rejectMessage: string | undefined) => {
    dispatch(
      shoppingCartSagas.rejectQuote.createAction({
        rejectQuoteCommand: {
          shoppingCartGuid: shoppingCartGuid,
          rejectMessage: rejectMessage
        }
      })
    );
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={isFrameAgreement ? t("Reject frame agreement") : t("Reject quote")}
      contentClassName="dialog-content"
      showCloseButton
      closeOnLostFocus
      dimBackground
    >
      <Container>
        <div>
          <StatusBanner
            subHeaderText={
              isFrameAgreement
                ? t("Are you sure you want to reject this frame agreement?")
                : t("Are you sure you want to reject this quote?")
            }
            text={
              isFrameAgreement
                ? t(
                    "The rejected frame agreement cannot be re-activated. Rather request for revision, if needed."
                  )
                : t(
                    "The rejected quote cannot be re-activated. Rather request for revision, if needed."
                  )
            }
            status="warning"
            icon="abb/warning-circle-1"
          />
        </div>
        <ResizableInput
          style={{ marginBottom: 8 }}
          maxLength={maxCharacterCount}
          dataType="textarea"
          label={t("Message")}
          value={rejectMessage}
          onValueChange={(v) => setRejectMessage(v)}
          placeholder={
            isFrameAgreement
              ? t("Reason for frame agreement rejection")
              : t("Reason for quote rejection")
          }
          description={t("({{dividend}}/{{divisor}})", {
            dividend: rejectMessage?.length ?? 0,
            divisor: maxCharacterCount
          })}
          inputAttributes={{
            rows: 8
          }}
          resizable={true}
          resizeParams={{ resizeAxis: "vertical", maxHeight: theme.breakpoints.sm }}
        />
        <HorizontalElementContainer>
          <Button text={t("Close")} onClick={() => setIsOpen(false)} buttonType="secondary" />
          <Button
            text={t("Reject")}
            onClick={() => {
              rejectQuote(rejectMessage);
            }}
            buttonType="primary"
            isLoading={rejectQuoteStatus?.status === RequestStatus.Pending}
          />
        </HorizontalElementContainer>
      </Container>
    </Dialog>
  );
};
