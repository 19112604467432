/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportPersonProfilePictureDto
 */
export interface SupportPersonProfilePictureDto {
    /**
     * 
     * @type {string}
     * @memberof SupportPersonProfilePictureDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupportPersonProfilePictureDto
     */
    profilePicture?: string | null;
}

export function SupportPersonProfilePictureDtoFromJSON(json: any): SupportPersonProfilePictureDto {
    return SupportPersonProfilePictureDtoFromJSONTyped(json, false);
}

export function SupportPersonProfilePictureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportPersonProfilePictureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : json['profilePicture'],
    };
}

export function SupportPersonProfilePictureDtoToJSON(value?: SupportPersonProfilePictureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'profilePicture': value.profilePicture,
    };
}

