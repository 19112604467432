import React from "react";
import { Icon } from "@abb/abb-common-ux-react";
import styled from "styled-components";

type ErrorProps = { msg: string };

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    color: red;
  }
  padding-block: ${(props) => props.theme.sizes.s};
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  font-weight: 500;
  margin: 0;
  margin-left: ${(props) => props.theme.sizes.s};
  color: ${(props) => props.theme.colors.grey70};
`;

export const Error = ({ msg }: ErrorProps): JSX.Element => {
  return (
    <Wrapper>
      <Icon name="abb/error-circle-1" />
      <Text>{msg}</Text>
    </Wrapper>
  );
};
