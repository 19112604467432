import { IStatusPanelParams } from "ag-grid-community";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const RowLabel = styled.span`
  color: ${(props) => props.theme.colors.grey50};
  font-weight: normal;
  margin-right: ${(props) => props.theme.sizes.xs};
`;

const RowCount = styled.span`
  color: ${(props) => props.theme.colors.grey90};
  font-weight: normal;
`;

const RowCountContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  align-self: center;
`;

interface GridStatusBarSelectedRowsCountProps extends IStatusPanelParams {
  t: TFunction;
}

export const GridStatusBarSelectedRowsCount = (props: GridStatusBarSelectedRowsCountProps) => {
  const [selectedRowsCount, setSelectedRowsCount] = useState<number | undefined>();

  const handleSelectedRowsCount = useCallback(() => {
    setSelectedRowsCount(props.api.getSelectedRows()?.length);
  }, [props.api]);

  useEffect(() => {
    props.api.addEventListener("selectionChanged", handleSelectedRowsCount);
    return () => props.api.removeEventListener("selectionChanged", handleSelectedRowsCount);
  }, [props.api, handleSelectedRowsCount]);

  return (
    <RowCountContainer>
      {selectedRowsCount ? (
        <>
          <RowLabel>{props.t("Selected Rows")}</RowLabel>
          <RowCount>{selectedRowsCount}</RowCount>
        </>
      ) : null}
    </RowCountContainer>
  );
};
