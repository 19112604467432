/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactSalesSupportReasons,
    ContactSalesSupportReasonsFromJSON,
    ContactSalesSupportReasonsFromJSONTyped,
    ContactSalesSupportReasonsToJSON,
} from './ContactSalesSupportReasons';

/**
 * 
 * @export
 * @interface ContactSalesSupportCommand
 */
export interface ContactSalesSupportCommand {
    /**
     * 
     * @type {string}
     * @memberof ContactSalesSupportCommand
     */
    orderAcknowledgementNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSalesSupportCommand
     */
    deliveringPlant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSalesSupportCommand
     */
    message?: string | null;
    /**
     * 
     * @type {ContactSalesSupportReasons}
     * @memberof ContactSalesSupportCommand
     */
    reason?: ContactSalesSupportReasons;
}

export function ContactSalesSupportCommandFromJSON(json: any): ContactSalesSupportCommand {
    return ContactSalesSupportCommandFromJSONTyped(json, false);
}

export function ContactSalesSupportCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSalesSupportCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderAcknowledgementNumber': !exists(json, 'orderAcknowledgementNumber') ? undefined : json['orderAcknowledgementNumber'],
        'deliveringPlant': !exists(json, 'deliveringPlant') ? undefined : json['deliveringPlant'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'reason': !exists(json, 'reason') ? undefined : ContactSalesSupportReasonsFromJSON(json['reason']),
    };
}

export function ContactSalesSupportCommandToJSON(value?: ContactSalesSupportCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'deliveringPlant': value.deliveringPlant,
        'message': value.message,
        'reason': ContactSalesSupportReasonsToJSON(value.reason),
    };
}

