import {
  createGenericSaga,
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const manageTermsConditionsSagas = {
  addOrUpdateSupplierTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/addOrUpdateSupplierTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsSupplierTypePost.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file saved"),
      onFail: defaultSagaErrorHandler
    }
  ),
  addOrUpdateCompanyTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/addOrUpdateCompaniesTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCompaniesCompanyIdPost.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file saved"),
      onFail: defaultSagaErrorHandler
    }
  ),
  addOrUpdateCountryTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/addOrUpdateCountryTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCountriesCountryCodePost.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file saved"),
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/deleteTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsIdDelete.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file deleted"),
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadSupplierTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/downloadSupplierTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsSupplierTypeGetRaw.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadCompanyTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/downloadCompanyTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCompaniesCompanyIdGetRaw.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadCountryTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/downloadCountryTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCountriesCountryCodeGetRaw.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadQuoteTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/downloadQuoteTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsQuotesQuoteIdDownloadGetRaw.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file downloaded"),
      onFail: (requestPayload) =>
        defaultSagaErrorHandler(requestPayload.quoteId, "Fail to download Terms & Conditions file")
    }
  ),
  downloadCurrentUserTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/downloadCurrentUserTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCurrentUserDownloadGetRaw.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Terms & Conditions file downloaded"),
      onFail: (requestPayload) =>
        defaultSagaErrorHandler(requestPayload, "Fail to download Terms & Conditions file")
    }
  ),
  getCurrentUserTermsConditions: createGenericSaga(
    "manageTermsConditionsSagas/getCurrentUserTermsConditions",
    getApiRegistry().termsAndConditionsApi.apiTermsAndConditionsCurrentUserGet.bind(
      getApiRegistry().termsAndConditionsApi
    ),
    {
      takeEvery: false
    }
  )
};
