/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';

/**
 * 
 * @export
 * @interface CompaniesVm
 */
export interface CompaniesVm {
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof CompaniesVm
     */
    companies: Array<CompanyDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof CompaniesVm
     */
    criteria: PaginationReturnModel;
}

export function CompaniesVmFromJSON(json: any): CompaniesVm {
    return CompaniesVmFromJSONTyped(json, false);
}

export function CompaniesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompaniesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(CompanyDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function CompaniesVmToJSON(value?: CompaniesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(CompanyDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

