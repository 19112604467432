/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationProcess,
    ModificationProcessFromJSON,
    ModificationProcessFromJSONTyped,
    ModificationProcessToJSON,
} from './ModificationProcess';

/**
 * 
 * @export
 * @interface AddOrUpdateShoppingCartItemDto
 */
export interface AddOrUpdateShoppingCartItemDto {
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    orderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    originalPlusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    serialNumbers?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    productCompositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    materialCode?: string | null;
    /**
     * 
     * @type {ModificationProcess}
     * @memberof AddOrUpdateShoppingCartItemDto
     */
    modificationProcessInfo?: ModificationProcess;
}

export function AddOrUpdateShoppingCartItemDtoFromJSON(json: any): AddOrUpdateShoppingCartItemDto {
    return AddOrUpdateShoppingCartItemDtoFromJSONTyped(json, false);
}

export function AddOrUpdateShoppingCartItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrUpdateShoppingCartItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'orderingCode': !exists(json, 'orderingCode') ? undefined : json['orderingCode'],
        'originalPlusCode': !exists(json, 'originalPlusCode') ? undefined : json['originalPlusCode'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
        'productCompositionCode': !exists(json, 'productCompositionCode') ? undefined : json['productCompositionCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'modificationProcessInfo': !exists(json, 'modificationProcessInfo') ? undefined : ModificationProcessFromJSON(json['modificationProcessInfo']),
    };
}

export function AddOrUpdateShoppingCartItemDtoToJSON(value?: AddOrUpdateShoppingCartItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'orderingCode': value.orderingCode,
        'originalPlusCode': value.originalPlusCode,
        'additionalInformation': value.additionalInformation,
        'serialNumbers': value.serialNumbers,
        'productCompositionCode': value.productCompositionCode,
        'materialCode': value.materialCode,
        'modificationProcessInfo': ModificationProcessToJSON(value.modificationProcessInfo),
    };
}

