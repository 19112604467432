/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManageUserRoleDto,
    ManageUserRoleDtoFromJSON,
    ManageUserRoleDtoFromJSONTyped,
    ManageUserRoleDtoToJSON,
} from './ManageUserRoleDto';
import {
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';
import {
    TeamMemberSources,
    TeamMemberSourcesFromJSON,
    TeamMemberSourcesFromJSONTyped,
    TeamMemberSourcesToJSON,
} from './TeamMemberSources';
import {
    TeamMemberStatus,
    TeamMemberStatusFromJSON,
    TeamMemberStatusFromJSONTyped,
    TeamMemberStatusToJSON,
} from './TeamMemberStatus';

/**
 * 
 * @export
 * @interface UserTeamRolesDto
 */
export interface UserTeamRolesDto {
    /**
     * 
     * @type {TeamDto}
     * @memberof UserTeamRolesDto
     */
    team: TeamDto;
    /**
     * 
     * @type {Array<ManageUserRoleDto>}
     * @memberof UserTeamRolesDto
     */
    roles: Array<ManageUserRoleDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UserTeamRolesDto
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserTeamRolesDto
     */
    isInUse: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserTeamRolesDto
     */
    userId: number;
    /**
     * 
     * @type {TeamMemberSources}
     * @memberof UserTeamRolesDto
     */
    source?: TeamMemberSources;
    /**
     * 
     * @type {TeamMemberStatus}
     * @memberof UserTeamRolesDto
     */
    status?: TeamMemberStatus;
}

export function UserTeamRolesDtoFromJSON(json: any): UserTeamRolesDto {
    return UserTeamRolesDtoFromJSONTyped(json, false);
}

export function UserTeamRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTeamRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'team': TeamDtoFromJSON(json['team']),
        'roles': ((json['roles'] as Array<any>).map(ManageUserRoleDtoFromJSON)),
        'isActive': json['isActive'],
        'isInUse': json['isInUse'],
        'userId': json['userId'],
        'source': !exists(json, 'source') ? undefined : TeamMemberSourcesFromJSON(json['source']),
        'status': !exists(json, 'status') ? undefined : TeamMemberStatusFromJSON(json['status']),
    };
}

export function UserTeamRolesDtoToJSON(value?: UserTeamRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'team': TeamDtoToJSON(value.team),
        'roles': ((value.roles as Array<any>).map(ManageUserRoleDtoToJSON)),
        'isActive': value.isActive,
        'isInUse': value.isInUse,
        'userId': value.userId,
        'source': TeamMemberSourcesToJSON(value.source),
        'status': TeamMemberStatusToJSON(value.status),
    };
}

