/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestAssigneeDto,
    AccessRequestAssigneeDtoFromJSON,
    AccessRequestAssigneeDtoFromJSONTyped,
    AccessRequestAssigneeDtoToJSON,
} from './AccessRequestAssigneeDto';
import {
    AccessRequestRoleDto,
    AccessRequestRoleDtoFromJSON,
    AccessRequestRoleDtoFromJSONTyped,
    AccessRequestRoleDtoToJSON,
} from './AccessRequestRoleDto';
import {
    AccessRequestStatusTypes,
    AccessRequestStatusTypesFromJSON,
    AccessRequestStatusTypesFromJSONTyped,
    AccessRequestStatusTypesToJSON,
} from './AccessRequestStatusTypes';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';

/**
 * 
 * @export
 * @interface AccessRequestDto
 */
export interface AccessRequestDto {
    /**
     * 
     * @type {number}
     * @memberof AccessRequestDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AccessRequestDto
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AccessRequestDto
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    userPrincipalName: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    userCompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    addressStreet: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    addressCity: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    addressPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    countryName: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    pgCodes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    etcdIcvCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    guidCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    sapCustomerReferenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    matchedCompanyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    timeZone?: string | null;
    /**
     * 
     * @type {AccessRequestStatusTypes}
     * @memberof AccessRequestDto
     */
    statusId: AccessRequestStatusTypes;
    /**
     * 
     * @type {RoleGroups}
     * @memberof AccessRequestDto
     */
    roleGroupId: RoleGroups;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    roleGroup?: string | null;
    /**
     * 
     * @type {Array<AccessRequestAssigneeDto>}
     * @memberof AccessRequestDto
     */
    assignees?: Array<AccessRequestAssigneeDto> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestDto
     */
    rejectionMessage?: string | null;
    /**
     * 
     * @type {CompanyDto}
     * @memberof AccessRequestDto
     */
    matchedCompany?: CompanyDto;
    /**
     * 
     * @type {Array<AccessRequestRoleDto>}
     * @memberof AccessRequestDto
     */
    accessRequestRoles: Array<AccessRequestRoleDto>;
}

export function AccessRequestDtoFromJSON(json: any): AccessRequestDto {
    return AccessRequestDtoFromJSONTyped(json, false);
}

export function AccessRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'created': (new Date(json['created'])),
        'email': json['email'],
        'userPrincipalName': json['userPrincipalName'],
        'userCompanyName': json['userCompanyName'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'addressStreet': json['addressStreet'],
        'addressCity': json['addressCity'],
        'addressPostalCode': !exists(json, 'addressPostalCode') ? undefined : json['addressPostalCode'],
        'countryName': json['countryName'],
        'pgCodes': !exists(json, 'pgCodes') ? undefined : json['pgCodes'],
        'etcdIcvCode': !exists(json, 'etcdIcvCode') ? undefined : json['etcdIcvCode'],
        'guidCode': !exists(json, 'guidCode') ? undefined : json['guidCode'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'sapCustomerReferenceNumber': !exists(json, 'sapCustomerReferenceNumber') ? undefined : json['sapCustomerReferenceNumber'],
        'matchedCompanyId': !exists(json, 'matchedCompanyId') ? undefined : json['matchedCompanyId'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'statusId': AccessRequestStatusTypesFromJSON(json['statusId']),
        'roleGroupId': RoleGroupsFromJSON(json['roleGroupId']),
        'roleGroup': !exists(json, 'roleGroup') ? undefined : json['roleGroup'],
        'assignees': !exists(json, 'assignees') ? undefined : (json['assignees'] === null ? null : (json['assignees'] as Array<any>).map(AccessRequestAssigneeDtoFromJSON)),
        'statusDescription': !exists(json, 'statusDescription') ? undefined : json['statusDescription'],
        'rejectionMessage': !exists(json, 'rejectionMessage') ? undefined : json['rejectionMessage'],
        'matchedCompany': !exists(json, 'matchedCompany') ? undefined : CompanyDtoFromJSON(json['matchedCompany']),
        'accessRequestRoles': ((json['accessRequestRoles'] as Array<any>).map(AccessRequestRoleDtoFromJSON)),
    };
}

export function AccessRequestDtoToJSON(value?: AccessRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'created': (value.created.toISOString()),
        'email': value.email,
        'userPrincipalName': value.userPrincipalName,
        'userCompanyName': value.userCompanyName,
        'position': value.position,
        'addressStreet': value.addressStreet,
        'addressCity': value.addressCity,
        'addressPostalCode': value.addressPostalCode,
        'countryName': value.countryName,
        'pgCodes': value.pgCodes,
        'etcdIcvCode': value.etcdIcvCode,
        'guidCode': value.guidCode,
        'comment': value.comment,
        'sapCustomerReferenceNumber': value.sapCustomerReferenceNumber,
        'matchedCompanyId': value.matchedCompanyId,
        'countryCode': value.countryCode,
        'timeZone': value.timeZone,
        'statusId': AccessRequestStatusTypesToJSON(value.statusId),
        'roleGroupId': RoleGroupsToJSON(value.roleGroupId),
        'roleGroup': value.roleGroup,
        'assignees': value.assignees === undefined ? undefined : (value.assignees === null ? null : (value.assignees as Array<any>).map(AccessRequestAssigneeDtoToJSON)),
        'statusDescription': value.statusDescription,
        'rejectionMessage': value.rejectionMessage,
        'matchedCompany': CompanyDtoToJSON(value.matchedCompany),
        'accessRequestRoles': ((value.accessRequestRoles as Array<any>).map(AccessRequestRoleDtoToJSON)),
    };
}

