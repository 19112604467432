/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRights,
    AccessRightsFromJSON,
    AccessRightsFromJSONTyped,
    AccessRightsToJSON,
} from './AccessRights';
import {
    MenuItemTypes,
    MenuItemTypesFromJSON,
    MenuItemTypesFromJSONTyped,
    MenuItemTypesToJSON,
} from './MenuItemTypes';

/**
 * 
 * @export
 * @interface MenuItemDto
 */
export interface MenuItemDto {
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    internalRoute: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    externalLink?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MenuItemDto
     */
    isLogOut: boolean;
    /**
     * 
     * @type {MenuItemTypes}
     * @memberof MenuItemDto
     */
    menuItemTypeId: MenuItemTypes;
    /**
     * 
     * @type {boolean}
     * @memberof MenuItemDto
     */
    isHrefLink?: boolean;
    /**
     * 
     * @type {Array<AccessRights>}
     * @memberof MenuItemDto
     */
    accessRights?: Array<AccessRights> | null;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuItemDto
     */
    children?: Array<MenuItemDto> | null;
}

export function MenuItemDtoFromJSON(json: any): MenuItemDto {
    return MenuItemDtoFromJSONTyped(json, false);
}

export function MenuItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'internalRoute': json['internalRoute'],
        'externalLink': !exists(json, 'externalLink') ? undefined : json['externalLink'],
        'isLogOut': json['isLogOut'],
        'menuItemTypeId': MenuItemTypesFromJSON(json['menuItemTypeId']),
        'isHrefLink': !exists(json, 'isHrefLink') ? undefined : json['isHrefLink'],
        'accessRights': !exists(json, 'accessRights') ? undefined : (json['accessRights'] === null ? null : (json['accessRights'] as Array<any>).map(AccessRightsFromJSON)),
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(MenuItemDtoFromJSON)),
    };
}

export function MenuItemDtoToJSON(value?: MenuItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'internalRoute': value.internalRoute,
        'externalLink': value.externalLink,
        'isLogOut': value.isLogOut,
        'menuItemTypeId': MenuItemTypesToJSON(value.menuItemTypeId),
        'isHrefLink': value.isHrefLink,
        'accessRights': value.accessRights === undefined ? undefined : (value.accessRights === null ? null : (value.accessRights as Array<any>).map(AccessRightsToJSON)),
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(MenuItemDtoToJSON)),
    };
}

