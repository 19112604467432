import React from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { AddressInfo } from "../AddressInfo";
import { Button } from "framework/components/Button";
import { ErrorMessage } from "formik";
import { Error } from "framework/components/Error";
import { Addresses } from "applications/shoppingCart/validationSchema";

const CustomButton = styled(Button)`
  margin-block: ${(props) => props.theme.sizes.m};
`;

interface AddressContainerProps {
  objKeyName: string;
  isReadOnly: boolean;
  t: TFunction;
  setIsDialogOpen: () => void;
  values: Addresses | undefined;
  buttonText: string;
}

export const AddressContainer = ({
  t,
  isReadOnly,
  values,
  setIsDialogOpen,
  objKeyName,
  buttonText
}: AddressContainerProps): JSX.Element => {
  return (
    <>
      {values ? (
        <AddressInfo
          name={values.name}
          department={values.reference}
          postalCode={values.postalCode}
          state={values.state}
          streetAddress={values.streetAddress}
          city={values.city}
          country={values.country}
          contactPerson={values.contactPerson}
          contactPersonEmail={values.contactPersonEmail}
          contactPersonTelephone={values.contactPersonTelephone}
          code={values.etcdIcvCode || values.guidCode}
          pgCode={values.pgCode}
        />
      ) : null}
      {!isReadOnly ? (
        <ErrorMessage name={objKeyName}>
          {() => <Error msg={"Missing or invalid info"} />}
        </ErrorMessage>
      ) : null}
      {isReadOnly ? null : (
        <CustomButton
          buttonType="secondary"
          text={values && Object.values(values).some((x) => Boolean(x)) ? t("Edit") : t(buttonText)}
          onClick={() => setIsDialogOpen()}
          sizeClass="small"
        />
      )}
    </>
  );
};
