/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationProduct,
    ModificationProductFromJSON,
    ModificationProductFromJSONTyped,
    ModificationProductToJSON,
} from './ModificationProduct';

/**
 * 
 * @export
 * @interface VerifyGmdModificationCommand
 */
export interface VerifyGmdModificationCommand {
    /**
     * 
     * @type {number}
     * @memberof VerifyGmdModificationCommand
     */
    modificationProcess: number;
    /**
     * 
     * @type {string}
     * @memberof VerifyGmdModificationCommand
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyGmdModificationCommand
     */
    materialCode: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyGmdModificationCommand
     */
    orderCode: string;
    /**
     * 
     * @type {ModificationProduct}
     * @memberof VerifyGmdModificationCommand
     */
    modificationProduct: ModificationProduct;
}

export function VerifyGmdModificationCommandFromJSON(json: any): VerifyGmdModificationCommand {
    return VerifyGmdModificationCommandFromJSONTyped(json, false);
}

export function VerifyGmdModificationCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyGmdModificationCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modificationProcess': json['modificationProcess'],
        'serialNumber': json['serialNumber'],
        'materialCode': json['materialCode'],
        'orderCode': json['orderCode'],
        'modificationProduct': ModificationProductFromJSON(json['modificationProduct']),
    };
}

export function VerifyGmdModificationCommandToJSON(value?: VerifyGmdModificationCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modificationProcess': value.modificationProcess,
        'serialNumber': value.serialNumber,
        'materialCode': value.materialCode,
        'orderCode': value.orderCode,
        'modificationProduct': ModificationProductToJSON(value.modificationProduct),
    };
}

