/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseDocumentRequestStates,
    LicenseDocumentRequestStatesFromJSON,
    LicenseDocumentRequestStatesFromJSONTyped,
    LicenseDocumentRequestStatesToJSON,
} from './LicenseDocumentRequestStates';
import {
    UploadedJustInTimeFileDto,
    UploadedJustInTimeFileDtoFromJSON,
    UploadedJustInTimeFileDtoFromJSONTyped,
    UploadedJustInTimeFileDtoToJSON,
} from './UploadedJustInTimeFileDto';

/**
 * 
 * @export
 * @interface JustInTimeLicenseDto
 */
export interface JustInTimeLicenseDto {
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    orderingCode: string;
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    compositionCode: string;
    /**
     * 
     * @type {LicenseDocumentRequestStates}
     * @memberof JustInTimeLicenseDto
     */
    onlineDeliveryState: LicenseDocumentRequestStates;
    /**
     * 
     * @type {LicenseDocumentRequestStates}
     * @memberof JustInTimeLicenseDto
     */
    justInTimeFilesDeliveryState: LicenseDocumentRequestStates;
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    fileToUploadDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof JustInTimeLicenseDto
     */
    timeoutExceeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JustInTimeLicenseDto
     */
    isPendingRetrieveProduct: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JustInTimeLicenseDto
     */
    hasFilesToUpload: boolean;
    /**
     * 
     * @type {string}
     * @memberof JustInTimeLicenseDto
     */
    licenseFileNameToBeGenerated?: string | null;
    /**
     * 
     * @type {Array<UploadedJustInTimeFileDto>}
     * @memberof JustInTimeLicenseDto
     */
    uploadedJustInTimeFiles: Array<UploadedJustInTimeFileDto>;
}

export function JustInTimeLicenseDtoFromJSON(json: any): JustInTimeLicenseDto {
    return JustInTimeLicenseDtoFromJSONTyped(json, false);
}

export function JustInTimeLicenseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JustInTimeLicenseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'orderingCode': json['orderingCode'],
        'compositionCode': json['compositionCode'],
        'onlineDeliveryState': LicenseDocumentRequestStatesFromJSON(json['onlineDeliveryState']),
        'justInTimeFilesDeliveryState': LicenseDocumentRequestStatesFromJSON(json['justInTimeFilesDeliveryState']),
        'fileType': json['fileType'],
        'fileToUploadDescription': json['fileToUploadDescription'],
        'timeoutExceeded': json['timeoutExceeded'],
        'isPendingRetrieveProduct': json['isPendingRetrieveProduct'],
        'hasFilesToUpload': json['hasFilesToUpload'],
        'licenseFileNameToBeGenerated': !exists(json, 'licenseFileNameToBeGenerated') ? undefined : json['licenseFileNameToBeGenerated'],
        'uploadedJustInTimeFiles': ((json['uploadedJustInTimeFiles'] as Array<any>).map(UploadedJustInTimeFileDtoFromJSON)),
    };
}

export function JustInTimeLicenseDtoToJSON(value?: JustInTimeLicenseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'orderingCode': value.orderingCode,
        'compositionCode': value.compositionCode,
        'onlineDeliveryState': LicenseDocumentRequestStatesToJSON(value.onlineDeliveryState),
        'justInTimeFilesDeliveryState': LicenseDocumentRequestStatesToJSON(value.justInTimeFilesDeliveryState),
        'fileType': value.fileType,
        'fileToUploadDescription': value.fileToUploadDescription,
        'timeoutExceeded': value.timeoutExceeded,
        'isPendingRetrieveProduct': value.isPendingRetrieveProduct,
        'hasFilesToUpload': value.hasFilesToUpload,
        'licenseFileNameToBeGenerated': value.licenseFileNameToBeGenerated,
        'uploadedJustInTimeFiles': ((value.uploadedJustInTimeFiles as Array<any>).map(UploadedJustInTimeFileDtoToJSON)),
    };
}

