import styled from "styled-components";
import { Heading, HeadingProps } from "@abb/abb-common-ux-react/components/Heading";
import React from "react";

const StyledSectionHeader = styled(Heading)<{ noBorderMargin?: boolean }>`
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold} !important;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeLM} !important;
  line-height: ${(props) => props.theme.fonts.lineHeights.lineHeightM} !important;
  margin-bottom: ${(props) => (props.noBorderMargin ? "0px" : props.theme.sizes.s)};
`;

interface SectionHeaderProps extends Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level"> {
  noBorderMargin?: boolean;
}

export const SectionHeader = (props: SectionHeaderProps): JSX.Element => (
  <StyledSectionHeader {...props} level={3} blackAndWhite={true} showCursor={false} />
);
