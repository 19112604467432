import React, { useState, useEffect } from "react";
import { SubHeader } from "../../../framework/components/styled/SubHeader";
import styled from "styled-components";
import { Input } from "@abb/abb-common-ux-react/components/Input/Input";
import { Icon, CustomValidationResult } from "@abb/abb-common-ux-react";
import { handlePressEnter } from "../../../utilities/formikUtils";
import { RequestStatus } from "../../../framework/state/requestStatus";
import { Dispatch } from "redux";
import { getGetUpdateShoppingCartProjectNameStatus } from "applications/common/reducers/shoppingCartReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { useSelector } from "react-redux";
import { usePrevious } from "../../../framework/hooks/usePrevious";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.sizes.l};
`;

const CustomIcon = styled(Icon)`
  :hover {
    cursor: pointer;
  }
  margin-left: ${(props) => props.theme.sizes.sm};
`;

interface CartNameProps {
  projectName?: string;
  dispatch: Dispatch;
  shoppingCartGuid: string | null | undefined;
  isReadOnly: boolean;
}

const validator = (
  value: string | null | undefined,
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>
): CustomValidationResult => {
  if (value && value.length > 2 && value.length <= 50) {
    setIsValid(true);
    return {
      valid: true
    };
  } else {
    setIsValid(false);
    return {
      valid: false,
      text: "Name must be between 2 and 50 characters"
    };
  }
};

export const CartName = ({
  projectName,
  dispatch,
  shoppingCartGuid,
  isReadOnly
}: CartNameProps): JSX.Element => {
  const [name, setName] = useState<string | undefined>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const updateShoppingCartProjectNameStatus = useSelector(
    getGetUpdateShoppingCartProjectNameStatus
  );
  const prevUpdateShoppingCartProjectNameStatus = usePrevious(updateShoppingCartProjectNameStatus);

  const updateShoppingCartProjectName = (projectName: string) => {
    if (shoppingCartGuid) {
      dispatch(
        shoppingCartSagas.updateShoppingCartProjectName.createAction({
          guid: shoppingCartGuid,
          updateShoppingCartProjectName: { projectName }
        })
      );
    }
  };

  useEffect(() => {
    setName(projectName);
  }, [projectName]);

  const handleKeyUpAndFocusLost = () => {
    if (isValid && name && name !== projectName) {
      updateShoppingCartProjectName(name);
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    if (
      updateShoppingCartProjectNameStatus === RequestStatus.Failed &&
      prevUpdateShoppingCartProjectNameStatus === RequestStatus.Pending
    ) {
      setName(projectName);
    }
    //eslint-disable-next-line
  }, [updateShoppingCartProjectNameStatus]);

  return (
    <Container>
      {isEditMode ? (
        <Input
          showValidationBarWhenInvalid
          dataType="text"
          instantValidation
          validator={(v) => validator(v, setIsValid)}
          value={name}
          onKeyUp={(e) => handlePressEnter(e, handleKeyUpAndFocusLost)}
          onValueChange={(v) => setName(v)}
          onLostFocus={() => {
            handleKeyUpAndFocusLost();
          }}
        />
      ) : (
        <SubHeader text={name ?? ""} />
      )}
      {!isReadOnly ? (
        <CustomIcon
          onClick={() => setIsEditMode((isEditingMode) => !isEditingMode)}
          name={"abb/edit"}
          sizeClass={"medium"}
        />
      ) : null}
    </Container>
  );
};
