import React from "react";
import MarkdownViewer from "framework/components/MarkdownViewer";
import { commonUXTheme } from "styles/commonUXVariables";
import {
  ReleaseNotesContainer,
  ReleaseNotesContentContainer,
  Hr,
  ReleaseNotesSectionHeader,
  ReleaseNotesSubHeader,
  DialogWithEmbeddedStyles
} from "./StyledComponents";
import "./footerStyles.css";
import { ReleaseNoteDto } from "api";

interface ReleseNotesDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  releaseNotes: ReleaseNoteDto[] | undefined;
}

export const ReleaseNotesDialog = ({ isOpen, setIsOpen, releaseNotes }: ReleseNotesDialogProps) => {
  return (
    <DialogWithEmbeddedStyles
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      dimBackground
      closeOnLostFocus
      showCloseButton
      contentClassName={"dialog-content overflow-auto"}
      containerClassName={"dialog-container"}
    >
      <ReleaseNotesContainer>
        <ReleaseNotesSubHeader text={"Release notes"} />
        <ReleaseNotesContentContainer>
          {releaseNotes?.map((x, i) => (
            <div key={x.version}>
              <ReleaseNotesSectionHeader
                text={x.version ?? ""}
                style={{ color: commonUXTheme.colors.grey60 }}
              />
              <MarkdownViewer markdownContent={x.description ?? ""} />
              {i !== releaseNotes.length - 1 && <Hr />}
            </div>
          ))}
        </ReleaseNotesContentContainer>
      </ReleaseNotesContainer>
    </DialogWithEmbeddedStyles>
  );
};
