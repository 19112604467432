import { Icon } from "@abb/abb-common-ux-react";
import React, { useState } from "react";
import styled from "styled-components";
import { FileRejection, useDropzone } from "react-dropzone";
import { TFunction } from "i18next";
import { v4 as uuid } from "uuid";
import { humanFileSize } from "utilities/stringUtils";
import { DropZoneFileList } from "./DropZoneFileList";
import { Error } from "./Error";

const DropZoneContainer = styled.div<{ isDragActive?: boolean }>`
  background-color: ${(props) =>
    props.isDragActive ? props.theme.colors.grey20 : props.theme.colors.whitePrimary};
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.theme.borders.widths.borderWidthS} dashed
    ${(props) => props.theme.colors.grey30} !important;
  padding: ${(props) => props.theme.sizes.m};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.sizes.m};
`;

const DropZoneText = styled.p`
  margin-right: auto;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  color: ${(props) => props.theme.colors.grey60};
`;

const DropZoneContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.sizes.s};
`;

interface DropZoneProps {
  t: TFunction;
  onDropSuccess: (file: UploadingFile) => void;
  acceptedFileTypes?: string | string[];
  onClickDelete?: (file: UploadingFile) => void;
  onClickDownload?: (file: UploadingFile) => void;
  files?: UploadingFile[];
  maxFileSizeSum?: number;
}

export interface UploadingFile {
  blob?: File;
  name: string;
  identifier: string;
  id?: number;
  size?: number;
}

export const DropZone = ({
  t,
  onDropSuccess,
  acceptedFileTypes,
  onClickDelete,
  onClickDownload,
  files,
  maxFileSizeSum
}: DropZoneProps): JSX.Element => {
  const [dropErrors, setDropErrors] = useState<string[]>([]);

  const totalSize = files?.reduce((sum, file) => sum + (file.size || 0), 0) || 0;

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const dropErrors: string[] = [];

    if (acceptedFiles && acceptedFiles.length > 0) {
      onDropSuccess({
        blob: acceptedFiles[0],
        identifier: uuid(),
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size
      });
    }

    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach((fileRejection) => {
        if (fileRejection.errors.some((e) => e.code === "file-invalid-type")) {
          dropErrors.push(t("Only pdf files are allowed"));
        }
        // Here you can add more errors if needed, with errors.push
      });
    }

    setDropErrors(dropErrors);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false
  });

  const allErrors =
    maxFileSizeSum !== undefined && totalSize > maxFileSizeSum
      ? dropErrors.concat(
          t("All file sizes combined must not exceed {{limit}}", {
            limit: humanFileSize(maxFileSizeSum)
          })
        )
      : dropErrors;

  return (
    <div>
      <DropZoneContainer
        {...getRootProps()}
        isDragActive={isDragActive}
        id={"dropzone"}
        className="DropZone_Container"
      >
        <input {...getInputProps()} />
        <Wrapper>
          <DropZoneContentWrapper>
            <Icon name="abb/upload" sizeClass="small" />
            <DropZoneText>{t("Drop file here, or click to select")}</DropZoneText>
          </DropZoneContentWrapper>
          <DropZoneFileList
            files={files}
            onClickDelete={onClickDelete}
            onClickDownload={onClickDownload}
          />
        </Wrapper>
      </DropZoneContainer>
      {allErrors.length > 0 && (
        <Error
          msg={(allErrors.length === 1 ? t("Error:") : t("Errors:")) + " " + allErrors.join(", ")}
        />
      )}
    </div>
  );
};
