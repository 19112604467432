import React, { useState, useCallback, useEffect } from "react";
import { AppContentWrapper } from "../../../../framework/components/AppContentWrapper";
import { GridApi, IGetRowsParams } from "ag-grid-community";
import {
  TeamDto,
  ApiTeamsMembersGetRequest,
  TeamMemberDto,
  TeamMemberStatus
} from "../../../../api";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";
import { AgGridSortModel, getSortModel } from "../../../../framework/components/grid/Grid";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import { usePrevious } from "../../../../framework/hooks/usePrevious";
import { apiGridErrorHandler } from "../../../../utilities/errorUtils";
import { SubHeader } from "../../../../framework/components/styled/SubHeader";
import { useTranslation } from "react-i18next";
import { ManageTeamsGridUsage, TeamsGrid } from "../components/TeamsGrid";
import { getManageTeam, getChangeMemberStatusRequest, getChangeMemberRoleRequest } from "../reducers/manageTeamsViewReducer";
import { manageTeamsSagas } from "../sagas/manageTeamsSagas";
import { TeamMembersGrid } from "../components/TeamMembersGrid";
import { ChangeTeamMemberRoleDialog } from "../components/ChangeTeamMemberRoleDialog";

const TeamGridContainer = styled.div`
  width: 100%;
`;

const TeamMembersGridContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const defaultAddTeamUsersCriteria = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "lastName",
  criteriaIsInternal: [true]
};

export const manageTeamMembersDefaultCriteria: ApiTeamsMembersGetRequest = {
  criteriaPage: 0,
  criteriaPageSize: 100,
  criteriaIsAscendingOrder: true,
  criteriaSortColumn: "lastName"
};

const ManageTeamView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { teamId } = useParams<{ teamId?: string }>();

  const [manageTeamMembersGrid, setManageTeamMembersGrid] = useState<GridApi | null>(null);
  const [changeTeamMemberRoleDialogOpen, setChangeTeamMemberRoleDialogOpen] = useState<boolean>(false);
  const [editedTeamMember, setEditedTeamMember] = useState<TeamMemberDto | undefined>(undefined);

  // Selector
  const team: TeamDto | undefined = useSelector(getManageTeam);
  const changeMemberStatusRequest = useSelector(getChangeMemberStatusRequest);
  const changeMemberRoleRequest = useSelector(getChangeMemberRoleRequest);

  // Previous
  const prevChangeMemberStatusRequest = usePrevious(changeMemberStatusRequest);
  const prevChangeMemberRoleRequest = usePrevious(changeMemberRoleRequest);

  useEffect(() => {
    if (teamId) {
      dispatch(
        manageTeamsSagas.getTeam.createAction({
          id: parseInt(teamId, 10)
        })
      );
    }
  }, [teamId, dispatch]);
  

  const onSetManageTeamMembersGridRefSet = useCallback((ref: GridApi) => {
    setManageTeamMembersGrid(ref);
  }, []);

  const produceTeamMembersCriteria = useCallback(
    (filterModel: any, sortModel?: AgGridSortModel, page?: number, rowCount?: number) => {
      return produce(manageTeamMembersDefaultCriteria, (draft) => {
        draft.criteriaSortColumn = sortModel?.sortColumn ?? draft.criteriaSortColumn;
        if (page !== undefined) draft.criteriaPage = page;
        draft.criteriaIsAscendingOrder = sortModel?.isAsc ?? draft.criteriaIsAscendingOrder;
        draft.criteriaTeamId = teamId ? parseInt(teamId, 10) : undefined;
        draft.criteriaSkipTotalRowCount = page !== 0 && rowCount !== undefined;
      });
    },
    [teamId]
  );

  const teamMembersDataSource = (teamMembersCriteria: ApiTeamsMembersGetRequest) => {
    let rowCount: number | undefined = undefined;
    return {
      getRows: (params: IGetRowsParams) => {
        manageTeamMembersGrid?.showLoadingOverlay();
        const page = Math.floor(params.startRow / teamMembersCriteria.criteriaPageSize);
        const api = getApiRegistry().teamsApi;

        const sortModel = getSortModel(params.sortModel);
        const newCriteria = produceTeamMembersCriteria(params.filterModel, sortModel, page, rowCount);
        
        api
          .apiTeamsMembersGet(newCriteria)
          .then((res) => {
            if (newCriteria.criteriaSkipTotalRowCount !== true) {
              rowCount = res.criteria.totalCount;
            }
            
            params.successCallback(res.teamMembers, rowCount);
            if (res.teamMembers.length === 0) {
              manageTeamMembersGrid?.showNoRowsOverlay();
            } else {
              manageTeamMembersGrid?.hideOverlay();
            }
            manageTeamMembersGrid?.sizeColumnsToFit();
          })
          .catch((err) => {
            apiGridErrorHandler(err, dispatch);
            manageTeamMembersGrid?.hideOverlay();
          });
      }
    };
  };

  const teamMembersDataSourceCallBack = useCallback(teamMembersDataSource, [
    teamId,
    manageTeamMembersGrid,
    dispatch
  ]);

  useEffect(() => {
    if (manageTeamMembersGrid && teamId) {
      manageTeamMembersGrid.setDatasource(
        teamMembersDataSourceCallBack(manageTeamMembersDefaultCriteria)
      );
    }
  }, [teamId, manageTeamMembersGrid, teamMembersDataSourceCallBack]);

  useEffect(() => {
    if(changeMemberStatusRequest.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.showLoadingOverlay();
    } else if(changeMemberStatusRequest.status === RequestStatus.Completed && prevChangeMemberStatusRequest?.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.redrawRows();
      manageTeamMembersGrid?.refreshInfiniteCache();
    } else if(changeMemberStatusRequest.status === RequestStatus.Failed) {
      manageTeamMembersGrid?.hideOverlay();
    }
  }, [changeMemberStatusRequest, prevChangeMemberStatusRequest]);

  const changeTeamMemberStatus = (teamMember: TeamMemberDto, status: TeamMemberStatus) => {
    dispatch(
      manageTeamsSagas.changeTeamMemberStatus.createAction({
        changeMemberStatusCommand: {
          userId: teamMember.userId ?? 0,
          teamId: teamMember.team?.id ?? 0,
          status: status
        }
      })
    );
  }

  useEffect(() => {
    if(changeMemberRoleRequest.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.showLoadingOverlay();
    } else if(changeMemberRoleRequest.status === RequestStatus.Completed && prevChangeMemberRoleRequest?.status === RequestStatus.Pending) {
      manageTeamMembersGrid?.redrawRows();
      manageTeamMembersGrid?.refreshInfiniteCache();
    } else if(changeMemberRoleRequest.status === RequestStatus.Failed) {
      manageTeamMembersGrid?.hideOverlay();
    }
  }, [changeMemberRoleRequest, prevChangeMemberRoleRequest]);

  const changeTeamMemberRole = (teamMember: TeamMemberDto, newRoleAbbreviation: string) => {
    setChangeTeamMemberRoleDialogOpen(false);

    dispatch(
      manageTeamsSagas.changeTeamMemberRole.createAction({
        changeMemberRoleCommand: {
          userId: teamMember.userId ?? 0,
          teamId: teamMember.team?.id ?? 0,
          roleAbbreviation: newRoleAbbreviation
        }
      })
    );
  }

  return (
    <>
      {changeTeamMemberRoleDialogOpen && editedTeamMember && team ? (
        <ChangeTeamMemberRoleDialog
          isOpen={changeTeamMemberRoleDialogOpen}
          setIsOpen={setChangeTeamMemberRoleDialogOpen}
          t={t}
          team={team}
          teamMember={editedTeamMember}
          changeTeamMemberRole={changeTeamMemberRole}
        />
      ) : null}
      <AppContentWrapper pageTitle={t("Manage team")}>
        <TeamGridContainer>
          <TeamsGrid
            usageType={ManageTeamsGridUsage.ViewSingle}
            t={t}
            setGridApi={null}
            rowData={team ? [team] : undefined}
          />
        </TeamGridContainer>
        <TeamMembersGridContainer>
          <SubHeader text={t("Team members")}></SubHeader>
          <TeamMembersGrid
            t={t}
            setGridApi={onSetManageTeamMembersGridRefSet}
            actionMethods={{
              approve: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatus.Approved),
              reject: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatus.Rejected),
              remove: (teamMember: TeamMemberDto) => changeTeamMemberStatus(teamMember, TeamMemberStatus.Removed),
              changeRole: (teamMember: TeamMemberDto) => {
                setEditedTeamMember(teamMember);
                setChangeTeamMemberRoleDialogOpen(true);
              }
            }}
          />
        </TeamMembersGridContainer>
      </AppContentWrapper>
    </>
  );
};

export default ManageTeamView;
