/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportContactedResult
 */
export interface SupportContactedResult {
    /**
     * 
     * @type {number}
     * @memberof SupportContactedResult
     */
    issueId?: number;
}

export function SupportContactedResultFromJSON(json: any): SupportContactedResult {
    return SupportContactedResultFromJSONTyped(json, false);
}

export function SupportContactedResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportContactedResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issueId': !exists(json, 'issueId') ? undefined : json['issueId'],
    };
}

export function SupportContactedResultToJSON(value?: SupportContactedResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issueId': value.issueId,
    };
}

