import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AdditionalFeatureContainer,
  PreviewImage,
  ProductDetailsColumn,
  ProductFeature,
  ProductPreview,
  ProductShortText,
  ProductTitle,
  RecommendedTag
} from "./common/Containers";
import { commonUXTheme } from "styles/commonUXVariables";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Carousel, CarouselItem } from "framework/components/Carousel";
import { Collapsible, CollapsibleContainer, Heading, Icon } from "@abb/abb-common-ux-react";
import {
  SelectorApplicationFeatureDto,
  SelectorApplicationFeatureProductValueDto,
  SelectorProductDto
} from "api";
import { Stack } from "@mui/material";
import { Button } from "framework/components/Button";
import ProductLinks from "./common/ProductLinks";
import { Tooltip } from "framework/components/Tooltip";
import { IconWithTooltip } from "framework/components/IconWithTooltip";

const StyledCollapsibleContainer = styled(CollapsibleContainer)(({ theme }) => ({
  ".ABB_CommonUX_Collapsible__headerExpanded": {
    boxSizing: "border-box"
  },
  ".ABB_CommonUX_Collapsible__root": {
    marginBottom: commonUXTheme.sizes.xs,
    border: `1px solid ${theme.colors.grey30}`,
    borderLeft: 0,
    borderRight: 0
  },
  ".ABB_CommonUX_Collapsible__header,.ABB_CommonUX_Collapsible__customContent": {
    background: theme.colors.grey10,
    paddingLeft: `${commonUXTheme.sizes.l} !important`,
    whiteSpace: "normal"
  },
  ".ABB_CommonUX_Collapsible__customContent": {
    background: theme.colors.grey10,
    paddingLeft: `${commonUXTheme.sizes.m} !important`
  },
  ".ABB_CommonUX_Collapsible__expanderIcon": {
    left: `${commonUXTheme.sizes.xs} !important`
  },
  ".ABB_CommonUX_Collapsible__root__contentInner": {
    background: "#F6F6F6"
  }
}));

const AvailabilityCheck = styled(Icon)(({ theme }) => ({
  cursor: "auto",
  color: theme.colors.statusGreen,
  marginTop: theme.sizes.s,
  marginLeft: theme.sizes.s
}));

const SelectButtonContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.sizes.m} ${theme.sizes.l}`,
  backgroundColor: theme.colors.grey10
}));

const AdditionalFeaturesContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.grey10
}));

const AdditionalFeature = styled(Stack)<{ $disabled: boolean }>(({ theme, $disabled }) => ({
  padding: theme.sizes.xs,
  opacity: $disabled ? 0.5 : 1
}));

const BasicFeature = styled(Stack)(({ theme }) => ({
  padding: `0 ${theme.sizes.s}`,
  height: "40px"
}));

interface ComparisonStepProps {
  products: SelectorProductDto[];
  additionalFeatures?: SelectorApplicationFeatureDto[] | null;
  onProductSelected: (product: SelectorProductDto) => void;
  selectedProductId?: number;
  defaultApplicationFeatureId?: number | null;
}

const ComparisonStep = ({
  products,
  additionalFeatures,
  onProductSelected,
  selectedProductId,
  defaultApplicationFeatureId
}: ComparisonStepProps): JSX.Element => {
  const { t } = useTranslation();
  const itemWidth = 250;
  const [isBasicExpanded, setIsBasicExpanded] = useState(false);
  const collapsibleArray = useRef<(CollapsibleContainer | null)[]>([]);

  const additionalFeatureLeftIcon = (
    feature: SelectorApplicationFeatureDto,
    productFeatureValue?: SelectorApplicationFeatureProductValueDto
  ) => {
    return (
      <AdditionalFeatureContainer container>
        <Grid
          xs="auto"
          item
          visibility={productFeatureValue?.isSelectedFeature ? "visible" : "hidden"}
        >
          <AvailabilityCheck name="abb/check-mark"></AvailabilityCheck>
        </Grid>
        <Grid xs item alignSelf={"center"} ml={commonUXTheme.sizes.s}>
          {feature.name}
        </Grid>
      </AdditionalFeatureContainer>
    );
  };

  const expandBasicFeatures = (clickedIndex: number) => {
    if (collapsibleArray) {
      collapsibleArray.current.forEach((c, i) => {
        if (i !== clickedIndex && c) {
          isBasicExpanded ? c.collapseAll() : c.expandAll();
        }
      });
      setIsBasicExpanded(!isBasicExpanded);
    }
  };

  return (
    <Box width={"100%"}>
      <Grid container mt={commonUXTheme.sizes.l} gap={1}>
        <Grid item flexGrow={1} xs minWidth={itemWidth}>
          <Carousel gap={1}>
            {products?.map((p, index) => (
              <CarouselItem minWidth={300} maxWidth={300} key={p.id}>
                <Stack width={"100%"}>
                  <ProductPreview>
                    <PreviewImage src={p.previewImageUrl ?? ""} />
                  </ProductPreview>
                  {
                    <RecommendedTag $isRecommended={index == 0}>
                      <div> {t("Recommended")}</div>
                    </RecommendedTag>
                  }
                  <ProductDetailsColumn mt={commonUXTheme.sizes.s} $isSelected={p.isSelected}>
                    <ProductTitle container alignItems={"center"}>
                      <Stack direction={"row"} width={"100%"}>
                        <b>{p.name}</b>
                        <IconWithTooltip
                          icon="abb/information-circle-1"
                          sizeClass="small"
                          color={commonUXTheme.colors.brandBlack}
                          style={{ marginLeft: commonUXTheme.sizes.s }}
                          tooltipText={p.longText ?? ""}
                        />
                      </Stack>
                      <ProductShortText>
                        {p.shortText && p.shortText?.length > 70
                          ? p.shortText?.substring(0, 70) + "..."
                          : p.shortText}
                      </ProductShortText>
                    </ProductTitle>
                    {p.selectorProductHighlights?.[0] && defaultApplicationFeatureId && (
                      <ProductFeature
                        key={p.selectorProductHighlights?.[0].id}
                        $lite={true}
                        container
                        alignItems={"center"}
                      >
                        {p.selectorProductHighlights?.[0].selectorSubCategory?.name}
                      </ProductFeature>
                    )}

                    <StyledCollapsibleContainer
                      ref={(ele) => (collapsibleArray.current[index] = ele)}
                      expandOnTitleClick={true}
                    >
                      <Collapsible
                        onClick={() => expandBasicFeatures(index)}
                        onClickExpander={() => expandBasicFeatures(index)}
                        key={"features"}
                        itemId={"items"}
                        title={
                          <Heading
                            style={{ fontSize: commonUXTheme.fonts.sizes.fontSizeM }}
                            text={t("Basic features")}
                            showCursor={false}
                            level={5}
                          ></Heading>
                        }
                      >
                        {p.selectorProductHighlights?.map((h, i) =>
                          i > 0 || !defaultApplicationFeatureId ? (
                            <BasicFeature key={h.id}>
                              <Tooltip title={h.selectorSubCategory?.categoryName ?? ""} arrow>
                                <span>{h.selectorSubCategory?.name}</span>
                              </Tooltip>
                            </BasicFeature>
                          ) : (
                            <span key={h.id}></span>
                          )
                        )}
                      </Collapsible>
                    </StyledCollapsibleContainer>
                    <AdditionalFeaturesContainer>
                      <Stack
                        mt={commonUXTheme.sizes.s}
                        mb={commonUXTheme.sizes.s}
                        ml={commonUXTheme.sizes.sm}
                      >
                        <Heading
                          style={{ fontSize: commonUXTheme.fonts.sizes.fontSizeM }}
                          text={t("Additional features")}
                          showCursor={false}
                          level={5}
                        ></Heading>
                      </Stack>
                      {additionalFeatures
                        ?.filter((f) => f.id !== defaultApplicationFeatureId)
                        ?.map((f) => {
                          const productFeatureValue =
                            p.selectorApplicationFeatureProductValues?.find(
                              (fv) => fv.selectorApplicationFeatureId == f.id
                            );
                          return (
                            <AdditionalFeature $disabled={!productFeatureValue} key={f.id}>
                              {additionalFeatureLeftIcon(f, productFeatureValue)}
                            </AdditionalFeature>
                          );
                        })}
                      <AdditionalFeature $disabled={false} marginLeft={commonUXTheme.sizes.s}>
                        <ProductLinks product={p}></ProductLinks>
                      </AdditionalFeature>
                    </AdditionalFeaturesContainer>
                    <SelectButtonContainer>
                      <Button
                        onClick={() => onProductSelected(p)}
                        buttonType={p.id === selectedProductId ? "primary-black" : "secondary"}
                        text={p.id === selectedProductId ? t("Selected") : t("Select")}
                      ></Button>
                    </SelectButtonContainer>
                  </ProductDetailsColumn>
                </Stack>
              </CarouselItem>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComparisonStep;
