/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    ProductLifecycleStatusDto,
    ProductLifecycleStatusDtoFromJSON,
    ProductLifecycleStatusDtoFromJSONTyped,
    ProductLifecycleStatusDtoToJSON,
} from './ProductLifecycleStatusDto';

/**
 * 
 * @export
 * @interface LifecycleStatusesVm
 */
export interface LifecycleStatusesVm {
    /**
     * 
     * @type {Array<ProductLifecycleStatusDto>}
     * @memberof LifecycleStatusesVm
     */
    lifecycleStatuses: Array<ProductLifecycleStatusDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof LifecycleStatusesVm
     */
    criteria: PaginationReturnModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof LifecycleStatusesVm
     */
    keywordsWithoutResults: Array<string>;
}

export function LifecycleStatusesVmFromJSON(json: any): LifecycleStatusesVm {
    return LifecycleStatusesVmFromJSONTyped(json, false);
}

export function LifecycleStatusesVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifecycleStatusesVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lifecycleStatuses': ((json['lifecycleStatuses'] as Array<any>).map(ProductLifecycleStatusDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
        'keywordsWithoutResults': json['keywordsWithoutResults'],
    };
}

export function LifecycleStatusesVmToJSON(value?: LifecycleStatusesVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lifecycleStatuses': ((value.lifecycleStatuses as Array<any>).map(ProductLifecycleStatusDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
        'keywordsWithoutResults': value.keywordsWithoutResults,
    };
}

