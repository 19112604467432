/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderLineDto,
    OrderLineDtoFromJSON,
    OrderLineDtoFromJSONTyped,
    OrderLineDtoToJSON,
} from './OrderLineDto';

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAcknowledgementNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    deliveringPlant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    purchaseOrderNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    endCustomerPurchaseOrderNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDto
     */
    created?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    supplyingPlant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {Array<OrderLineDto>}
     * @memberof OrderDto
     */
    orderLines?: Array<OrderLineDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    isGtsDeliveryHardBlock?: boolean;
}

export function OrderDtoFromJSON(json: any): OrderDto {
    return OrderDtoFromJSONTyped(json, false);
}

export function OrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderAcknowledgementNumber': json['orderAcknowledgementNumber'],
        'deliveringPlant': !exists(json, 'deliveringPlant') ? undefined : json['deliveringPlant'],
        'purchaseOrderNumber': !exists(json, 'purchaseOrderNumber') ? undefined : json['purchaseOrderNumber'],
        'endCustomerPurchaseOrderNumber': !exists(json, 'endCustomerPurchaseOrderNumber') ? undefined : json['endCustomerPurchaseOrderNumber'],
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'supplyingPlant': !exists(json, 'supplyingPlant') ? undefined : json['supplyingPlant'],
        'identifier': json['identifier'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'orderLines': !exists(json, 'orderLines') ? undefined : (json['orderLines'] === null ? null : (json['orderLines'] as Array<any>).map(OrderLineDtoFromJSON)),
        'isGtsDeliveryHardBlock': !exists(json, 'isGtsDeliveryHardBlock') ? undefined : json['isGtsDeliveryHardBlock'],
    };
}

export function OrderDtoToJSON(value?: OrderDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'deliveringPlant': value.deliveringPlant,
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'endCustomerPurchaseOrderNumber': value.endCustomerPurchaseOrderNumber,
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
        'status': value.status,
        'supplyingPlant': value.supplyingPlant,
        'identifier': value.identifier,
        'companyId': value.companyId,
        'orderLines': value.orderLines === undefined ? undefined : (value.orderLines === null ? null : (value.orderLines as Array<any>).map(OrderLineDtoToJSON)),
        'isGtsDeliveryHardBlock': value.isGtsDeliveryHardBlock,
    };
}

