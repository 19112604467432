import {
  createGenericSaga,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const productSelectionMatrixSagas = {
  getModularProducts: createGenericSaga(
    "productSelectionMatrixSagas/getModularProducts",
    getApiRegistry().productSelectionMatrixApi.apiProductSelectionMatrixModularProductsGet.bind(
      getApiRegistry().productSelectionMatrixApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getStaticProducts: createGenericSaga(
    "productSelectionMatrixSagas/getStaticProducts",
    getApiRegistry().productSelectionMatrixApi.apiProductSelectionMatrixStaticProductsGet.bind(
      getApiRegistry().productSelectionMatrixApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSSC600Settings: createGenericSaga(
    "productSelectionMatrixSagas/getSSC600Settings",
    getApiRegistry().selectorApi.apiSelectorSSC600SettingsGet.bind(getApiRegistry().selectorApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
