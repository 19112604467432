/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestFunctionalitySelections,
    AccessRequestFunctionalitySelectionsFromJSON,
    AccessRequestFunctionalitySelectionsFromJSONTyped,
    AccessRequestFunctionalitySelectionsToJSON,
} from './AccessRequestFunctionalitySelections';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';

/**
 * 
 * @export
 * @interface AccessRequestFunctionalitySelectionDto
 */
export interface AccessRequestFunctionalitySelectionDto {
    /**
     * 
     * @type {string}
     * @memberof AccessRequestFunctionalitySelectionDto
     */
    description: string;
    /**
     * 
     * @type {AccessRequestFunctionalitySelections}
     * @memberof AccessRequestFunctionalitySelectionDto
     */
    type: AccessRequestFunctionalitySelections;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestFunctionalitySelectionDto
     */
    isDefaultSelection: boolean;
    /**
     * 
     * @type {RoleGroups}
     * @memberof AccessRequestFunctionalitySelectionDto
     */
    roleGroup: RoleGroups;
}

export function AccessRequestFunctionalitySelectionDtoFromJSON(json: any): AccessRequestFunctionalitySelectionDto {
    return AccessRequestFunctionalitySelectionDtoFromJSONTyped(json, false);
}

export function AccessRequestFunctionalitySelectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestFunctionalitySelectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'type': AccessRequestFunctionalitySelectionsFromJSON(json['type']),
        'isDefaultSelection': json['isDefaultSelection'],
        'roleGroup': RoleGroupsFromJSON(json['roleGroup']),
    };
}

export function AccessRequestFunctionalitySelectionDtoToJSON(value?: AccessRequestFunctionalitySelectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'type': AccessRequestFunctionalitySelectionsToJSON(value.type),
        'isDefaultSelection': value.isDefaultSelection,
        'roleGroup': RoleGroupsToJSON(value.roleGroup),
    };
}

