/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShoppingCartAccountCustomerDto
 */
export interface ShoppingCartAccountCustomerDto {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartAccountCustomerDto
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    reference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    contactPerson?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    contactPersonEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    contactPersonTelephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    guidCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    etcdIcvCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartAccountCustomerDto
     */
    pgCode?: string | null;
}

export function ShoppingCartAccountCustomerDtoFromJSON(json: any): ShoppingCartAccountCustomerDto {
    return ShoppingCartAccountCustomerDtoFromJSONTyped(json, false);
}

export function ShoppingCartAccountCustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartAccountCustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'contactPersonEmail': !exists(json, 'contactPersonEmail') ? undefined : json['contactPersonEmail'],
        'contactPersonTelephone': !exists(json, 'contactPersonTelephone') ? undefined : json['contactPersonTelephone'],
        'guidCode': !exists(json, 'guidCode') ? undefined : json['guidCode'],
        'etcdIcvCode': !exists(json, 'etcdIcvCode') ? undefined : json['etcdIcvCode'],
        'pgCode': !exists(json, 'pgCode') ? undefined : json['pgCode'],
    };
}

export function ShoppingCartAccountCustomerDtoToJSON(value?: ShoppingCartAccountCustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'reference': value.reference,
        'streetAddress': value.streetAddress,
        'postalCode': value.postalCode,
        'city': value.city,
        'state': value.state,
        'country': value.country,
        'countryCode': value.countryCode,
        'contactPerson': value.contactPerson,
        'contactPersonEmail': value.contactPersonEmail,
        'contactPersonTelephone': value.contactPersonTelephone,
        'guidCode': value.guidCode,
        'etcdIcvCode': value.etcdIcvCode,
        'pgCode': value.pgCode,
    };
}

