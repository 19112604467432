/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShoppingCartAttachmentTypes {
    CommercialInvoice = 'CommercialInvoice',
    RiskReview = 'RiskReview'
}

export function ShoppingCartAttachmentTypesFromJSON(json: any): ShoppingCartAttachmentTypes {
    return ShoppingCartAttachmentTypesFromJSONTyped(json, false);
}

export function ShoppingCartAttachmentTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartAttachmentTypes {
    return json as ShoppingCartAttachmentTypes;
}

export function ShoppingCartAttachmentTypesToJSON(value?: ShoppingCartAttachmentTypes | null): any {
    return value as any;
}

