/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmailTemplate,
    EmailTemplateFromJSON,
    EmailTemplateToJSON,
    SendEmailTemplatePreviewCommand,
    SendEmailTemplatePreviewCommandFromJSON,
    SendEmailTemplatePreviewCommandToJSON,
} from '../models';

export interface ApiEmailTemplatesEmailPreviewPostRequest {
    sendEmailTemplatePreviewCommand?: SendEmailTemplatePreviewCommand;
}

export interface ApiEmailTemplatesPostRequest {
    replacedFileName?: string;
    _import?: Blob;
}

export interface ApiEmailTemplatesTemplateNameDeleteRequest {
    templateName: string;
}

export interface ApiEmailTemplatesTemplateNameGetRequest {
    templateName: string;
}

export interface ApiEmailTemplatesUploadMultiplePostRequest {
    files?: Array<Blob>;
}

/**
 * 
 */
export class EmailTemplatesApi extends runtime.BaseAPI {

    /**
     */
    async apiEmailTemplatesEmailPreviewPostRaw(requestParameters: ApiEmailTemplatesEmailPreviewPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/EmailTemplates/emailPreview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailTemplatePreviewCommandToJSON(requestParameters.sendEmailTemplatePreviewCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEmailTemplatesEmailPreviewPost(requestParameters: ApiEmailTemplatesEmailPreviewPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiEmailTemplatesEmailPreviewPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiEmailTemplatesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EmailTemplate>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/EmailTemplates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailTemplateFromJSON));
    }

    /**
     */
    async apiEmailTemplatesGet(initOverrides?: RequestInit): Promise<Array<EmailTemplate>> {
        const response = await this.apiEmailTemplatesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiEmailTemplatesPostRaw(requestParameters: ApiEmailTemplatesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.replacedFileName !== undefined) {
            queryParameters['replacedFileName'] = requestParameters.replacedFileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters._import !== undefined) {
            formParams.append('import', requestParameters._import as any);
        }

        const response = await this.request({
            path: `/api/EmailTemplates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEmailTemplatesPost(requestParameters: ApiEmailTemplatesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiEmailTemplatesPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiEmailTemplatesRequiredTemplatesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/EmailTemplates/required-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiEmailTemplatesRequiredTemplatesGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiEmailTemplatesRequiredTemplatesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiEmailTemplatesTemplateNameDeleteRaw(requestParameters: ApiEmailTemplatesTemplateNameDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.templateName === null || requestParameters.templateName === undefined) {
            throw new runtime.RequiredError('templateName','Required parameter requestParameters.templateName was null or undefined when calling apiEmailTemplatesTemplateNameDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/EmailTemplates/{templateName}`.replace(`{${"templateName"}}`, encodeURIComponent(String(requestParameters.templateName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEmailTemplatesTemplateNameDelete(requestParameters: ApiEmailTemplatesTemplateNameDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiEmailTemplatesTemplateNameDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiEmailTemplatesTemplateNameGetRaw(requestParameters: ApiEmailTemplatesTemplateNameGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EmailTemplate>> {
        if (requestParameters.templateName === null || requestParameters.templateName === undefined) {
            throw new runtime.RequiredError('templateName','Required parameter requestParameters.templateName was null or undefined when calling apiEmailTemplatesTemplateNameGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/EmailTemplates/{templateName}`.replace(`{${"templateName"}}`, encodeURIComponent(String(requestParameters.templateName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTemplateFromJSON(jsonValue));
    }

    /**
     */
    async apiEmailTemplatesTemplateNameGet(requestParameters: ApiEmailTemplatesTemplateNameGetRequest, initOverrides?: RequestInit): Promise<EmailTemplate> {
        const response = await this.apiEmailTemplatesTemplateNameGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiEmailTemplatesUploadMultiplePostRaw(requestParameters: ApiEmailTemplatesUploadMultiplePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/EmailTemplates/uploadMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEmailTemplatesUploadMultiplePost(requestParameters: ApiEmailTemplatesUploadMultiplePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiEmailTemplatesUploadMultiplePostRaw(requestParameters, initOverrides);
    }

}
