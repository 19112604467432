/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EndpointDto
 */
export interface EndpointDto {
    /**
     * 
     * @type {string}
     * @memberof EndpointDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndpointDto
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndpointDto
     */
    route?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndpointDto
     */
    controllerMethod?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EndpointDto
     */
    isAnonymous?: boolean;
}

export function EndpointDtoFromJSON(json: any): EndpointDto {
    return EndpointDtoFromJSONTyped(json, false);
}

export function EndpointDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EndpointDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': !exists(json, 'method') ? undefined : json['method'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'route': !exists(json, 'route') ? undefined : json['route'],
        'controllerMethod': !exists(json, 'controllerMethod') ? undefined : json['controllerMethod'],
        'isAnonymous': !exists(json, 'isAnonymous') ? undefined : json['isAnonymous'],
    };
}

export function EndpointDtoToJSON(value?: EndpointDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
        'action': value.action,
        'route': value.route,
        'controllerMethod': value.controllerMethod,
        'isAnonymous': value.isAnonymous,
    };
}

