/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyAccessAuditVm
 */
export interface CompanyAccessAuditVm {
    /**
     * 
     * @type {string}
     * @memberof CompanyAccessAuditVm
     */
    companyId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyAccessAuditVm
     */
    companyAccessAuditDueTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CompanyAccessAuditVm
     */
    companyAccessAuditCompleted?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyAccessAuditVm
     */
    hasLateAccessAudit?: boolean | null;
}

export function CompanyAccessAuditVmFromJSON(json: any): CompanyAccessAuditVm {
    return CompanyAccessAuditVmFromJSONTyped(json, false);
}

export function CompanyAccessAuditVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAccessAuditVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyAccessAuditDueTo': !exists(json, 'companyAccessAuditDueTo') ? undefined : (json['companyAccessAuditDueTo'] === null ? null : new Date(json['companyAccessAuditDueTo'])),
        'companyAccessAuditCompleted': !exists(json, 'companyAccessAuditCompleted') ? undefined : (json['companyAccessAuditCompleted'] === null ? null : new Date(json['companyAccessAuditCompleted'])),
        'hasLateAccessAudit': !exists(json, 'hasLateAccessAudit') ? undefined : json['hasLateAccessAudit'],
    };
}

export function CompanyAccessAuditVmToJSON(value?: CompanyAccessAuditVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'companyAccessAuditDueTo': value.companyAccessAuditDueTo === undefined ? undefined : (value.companyAccessAuditDueTo === null ? null : value.companyAccessAuditDueTo.toISOString()),
        'companyAccessAuditCompleted': value.companyAccessAuditCompleted === undefined ? undefined : (value.companyAccessAuditCompleted === null ? null : value.companyAccessAuditCompleted.toISOString()),
        'hasLateAccessAudit': value.hasLateAccessAudit,
    };
}

