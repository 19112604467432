import {
  ApiOrderDetailsGetRequest,
  OrderDetailsVm,
  ApiResponse,
  ApiOrderShippingDetailFilesGetRequest
} from "../../../../api";
import { Action } from "redux";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { orderSagas } from "../sagas/orderSagas";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { handleFileDownloadResponse } from "../../../../utilities/fileDownload";

interface Requests {
  getOrderDetails: RequestState<OrderDetailsVm>;
  sendSalesSupport: RequestState<void>;
  downloadShippingDetailFiles: RequestState<
    ApiResponse<Blob>,
    ApiOrderShippingDetailFilesGetRequest
  >;
}

export interface OrdersDetailsViewState {
  orderDetailsCriteria: ApiOrderDetailsGetRequest | undefined;
  orderDetails: OrderDetailsVm | undefined;
  requests: Requests;
}

const defaultState: OrdersDetailsViewState = {
  orderDetailsCriteria: undefined,
  orderDetails: undefined,
  requests: {
    getOrderDetails: createInitialRequest(),
    sendSalesSupport: createInitialRequest(),
    downloadShippingDetailFiles: createInitialRequest()
  }
};

export function ordersDetailsViewReducer(
  state: OrdersDetailsViewState = defaultState,
  action: Action
): OrdersDetailsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: orderSagas.getOrderdetails.actionTypes,
      key: "getOrderDetails"
    },
    {
      actionTypes: orderSagas.sendSalesSupport.actionTypes,
      key: "sendSalesSupport"
    },
    {
      actionTypes: orderSagas.getOrders.actionTypes,
      key: "getOrders"
    }
  ]);

  if (orderSagas.getOrderdetails.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.orderDetails = action.payload;
    });
  }

  if (orderSagas.downloadShippingDetailFiles.isCompletedAction(action)) {
    handleFileDownloadResponse(action.payload.raw, "ShippingDocuments.pdf");
  }
  return state;
}

export const selectGetOrderDetailsRequest = (state: AppState): RequestState<OrderDetailsVm> =>
  state.deliveries.orderDetailsView.requests.getOrderDetails;

export const selectOrderDetailsVm = (state: AppState): OrderDetailsVm | undefined =>
  state.deliveries.orderDetailsView.orderDetails;

export const selectSendSalesSupportRequest = (state: AppState): RequestState<void> =>
  state.deliveries.orderDetailsView.requests.sendSalesSupport;
