import TagManager, { TagManagerArgs } from "react-gtm-module";

const SingletonFactory = () => {
  let isTagManagerInitialized = false;

  return {
    useGoogleTagManager: () => {
      if (!isTagManagerInitialized) {
        isTagManagerInitialized = true;
        if (window.location.hostname === process.env.REACT_APP_ENABLE_GTM_PROD) {
          const tagManagerArgs: TagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TM_ID_PROD!
          };
          TagManager.initialize(tagManagerArgs);
        } else if (window.location.hostname === process.env.REACT_APP_ENABLE_GTM_QA) {
          const tagManagerArgs: TagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TM_ID_QA!
          };
          TagManager.initialize(tagManagerArgs);
        }
      }
    }
  };
};

export default SingletonFactory().useGoogleTagManager;
