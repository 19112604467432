import { ContactOrderHandlingReasonDto, SupportContactedResult } from "api";
import {
  ApiOrderContactOrderHandlingPostRequest,
  ApiOrderLicenseFilesGetRequest,
  ApiOrderLicensesPutRequest,
  ApiOrderOnlineDeliveryIdentifierPostRequest,
  ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest,
  ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest,
  ApiOrderSupportTeamRequestPostRequest,
  ApiOrderUnsubscribePatchRequest,
  ApiOrderUploadAttachmentForSupportIdPostRequest,
  ApiOrderUploadJustInTimeFileIdentifierPostRequest
} from "api/apis/OrderApi";
import { NotificationSeverity } from "api/models/NotificationSeverity";
import { OnlineDeliveryVm } from "api/models/OnlineDeliveryVm";
import { OrderLicensesVm } from "api/models/OrderLicensesVm";
import { ApiResponse } from "api/runtime";
import { userActions } from "applications/common/actions/userActions";
import {
  createGenericSaga,
  CreateSagaOptions,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler,
  GenericSagaOptions,
  SagaBuilder
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

interface ProductInformationSagas {
  [index: string]: CreateSagaOptions<any, any>;
  getOrderLicenseFiles: GenericSagaOptions<OrderLicensesVm, ApiOrderLicensesPutRequest>;
  downloadLicenseFiles: GenericSagaOptions<ApiResponse<Blob>, ApiOrderLicenseFilesGetRequest>;
  uploadJustInTimeFile: GenericSagaOptions<
    OnlineDeliveryVm,
    ApiOrderUploadJustInTimeFileIdentifierPostRequest
  >;
  triggerOnlineDelivery: GenericSagaOptions<
    OnlineDeliveryVm,
    ApiOrderOnlineDeliveryIdentifierPostRequest
  >;
  unsubscribe: GenericSagaOptions<void, ApiOrderUnsubscribePatchRequest>;
  contactSupportTeam: GenericSagaOptions<
    SupportContactedResult,
    ApiOrderSupportTeamRequestPostRequest
  >;
  getContactOrderHandlingReasons: GenericSagaOptions<
    ContactOrderHandlingReasonDto[],
    RequestInit | undefined
  >;
  contactOrderHandling: GenericSagaOptions<void, ApiOrderContactOrderHandlingPostRequest>;
  removeJustInTimeFile: GenericSagaOptions<
    void,
    ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest
  >;
  resetStatusDeliveryJustInTimeFile: GenericSagaOptions<
    void,
    ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest
  >;
  uploadAttachmentForSupport: GenericSagaOptions<
    void,
    ApiOrderUploadAttachmentForSupportIdPostRequest
  >;
}

export const productInformationSagas: ProductInformationSagas = {
  getOrderLicenseFiles: createGenericSaga(
    "productInformationSagas/getOrderLicensesFiles",
    getApiRegistry().orderApi.apiOrderLicensesPut.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: (requestPayload, response) => {
        if (response.automaticDownloadInformation) {
          return new SagaBuilder()
            .dispatch(
              productInformationSagas.downloadLicenseFiles.createAction({
                identifier: response.automaticDownloadInformation.licenseIdentifier,
                fileName: response.automaticDownloadInformation.fileName ?? ""
              })
            )
            .build()();
        }

        return undefined;
      }
    }
  ),
  downloadLicenseFiles: createGenericSaga(
    "productInformationSagas/downloadOrderLicensesFiles",
    getApiRegistry().orderApi.apiOrderLicenseFilesGetRaw.bind(getApiRegistry().orderApi),
    {
      onFail: (payload, errorTitle, errorDetails, errorDetail, additionalInformation) => {
        const automaticRetryGuidKey = "automaticRetryGuid";
        const serialNumberKey = "serialNumber";

        // When download fails, try to refetch serial number information or trigger online delivery automatically.
        if (additionalInformation && additionalInformation[serialNumberKey]) {
          let sagaBuilder = new SagaBuilder();
          sagaBuilder = sagaBuilder.dispatch(
            productInformationSagas.getOrderLicenseFiles.createAction({
              serialNumber: additionalInformation[serialNumberKey]
            })
          );

          // If automatic retry information has been defined, we can try that automatically.
          // Otherwise, just show an error.
          if (additionalInformation[automaticRetryGuidKey]) {
            sagaBuilder = sagaBuilder.dispatch(
              productInformationSagas.triggerOnlineDelivery.createAction({
                identifier: additionalInformation[automaticRetryGuidKey]
              })
            );
          } else {
            sagaBuilder = sagaBuilder.dispatch(
              userActions.addNotification(
                NotificationSeverity.Error,
                errorTitle ?? "Error occurred",
                undefined,
                undefined,
                undefined,
                errorDetail,
                undefined,
                undefined
              )
            );
          }

          return sagaBuilder.build()();
        } else {
          return defaultSagaErrorHandler(payload, errorTitle, errorDetails, errorDetail, undefined);
        }
      },
      takeEvery: false
    }
  ),
  uploadJustInTimeFile: createGenericSaga(
    "productInformationSagas/uploadJustInTimeFile",
    getApiRegistry().orderApi.apiOrderUploadJustInTimeFileIdentifierPost.bind(
      getApiRegistry().orderApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler(
        "File uploaded successfully! You'll be notified when the download is available. Please note that this can take up to 10 minutes.",
        5000
      )
    }
  ),
  triggerOnlineDelivery: createGenericSaga(
    "productInformationSagas/triggerOnlineDelivery",
    getApiRegistry().orderApi.apiOrderOnlineDeliveryIdentifierPost.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler(
        "Online delivery initiated successfully! You'll be notified when the download is available. Please note that this can take up to 10 minutes.",
        5000
      )
    }
  ),
  unsubscribe: createGenericSaga(
    "productInformationSagas/unsubscribe",
    getApiRegistry().orderApi.apiOrderUnsubscribePatch.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Unsubscribe request sent.")
    }
  ),
  contactSupportTeam: createGenericSaga(
    "productInformationSagas/contactSupportTeam",
    getApiRegistry().orderApi.apiOrderSupportTeamRequestPost.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: (requestPayload: ApiOrderSupportTeamRequestPostRequest) => {
        if (requestPayload.contactSupportTeamCommand?.showNotification === true) {
          return new SagaBuilder()
            .dispatch(
              userActions.addNotification(
                NotificationSeverity.Success,
                "Support request sent.",
                false
              )
            )
            .build()();
        }
        return undefined;
      }
    }
  ),
  uploadAttachmentForSupport: createGenericSaga(
    "productInformationSagas/uploadAttachmentForSupport",
    getApiRegistry().orderApi.apiOrderUploadAttachmentForSupportIdPost.bind(
      getApiRegistry().orderApi
    ),
    {
      takeEvery: false,
      onFail: (requestPayload) =>
        defaultSagaErrorHandler(requestPayload.id, "Uploading attachment for support failed"),
      onSuccess: defaultSagaSuccessHandler("Support request sent.")
    }
  ),
  getContactOrderHandlingReasons: createGenericSaga(
    "productInformationSagas/getContactOrderHandlingReasons",
    getApiRegistry().orderApi.apiOrderContactOrderHandlingReasonsGet.bind(
      getApiRegistry().orderApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  contactOrderHandling: createGenericSaga(
    "productInformationSagas/contactOrderHandling",
    getApiRegistry().orderApi.apiOrderContactOrderHandlingPost.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Request sent to order handling.")
    }
  ),
  removeJustInTimeFile: createGenericSaga(
    "productInformationSagas/removeJustInTimeFile",
    getApiRegistry().orderApi.apiOrderRemoveUploadedJustInTimeFileCommandPatch.bind(
      getApiRegistry().orderApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: (requestPayload: ApiOrderRemoveUploadedJustInTimeFileCommandPatchRequest) => {
        if (requestPayload.removeUploadedJustInTimeFileCommand?.reloadLicense) {
          return new SagaBuilder()
            .dispatch(
              productInformationSagas.getOrderLicenseFiles.createAction({
                serialNumber:
                  requestPayload.removeUploadedJustInTimeFileCommand.serialNumber ?? undefined
              })
            )
            .build()();
        }
        return undefined;
      }
    }
  ),
  resetStatusDeliveryJustInTimeFile: createGenericSaga(
    "productInformationSagas/resetStatusDeliveryJustInTimeFile",
    getApiRegistry().orderApi.apiOrderResetStatusDeliveryFailedJustInTimeFilePatch.bind(
      getApiRegistry().orderApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: (requestPayload: ApiOrderResetStatusDeliveryFailedJustInTimeFilePatchRequest) => {
        if (requestPayload.resetStatusDeliveryFailedJustInTimeFileCommand?.reloadLicense) {
          return new SagaBuilder()
            .dispatch(
              productInformationSagas.getOrderLicenseFiles.createAction({
                serialNumber:
                  requestPayload.resetStatusDeliveryFailedJustInTimeFileCommand.serialNumber ?? undefined
              })
            )
            .dispatch(
              userActions.addNotification(NotificationSeverity.Success, "Status reset successfully!")
            )
            .build()();
        }
        return undefined;
      },
       
    }
  )
};
