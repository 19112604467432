import { Middleware, FetchParams, RequestContext } from "../../../api";
import { appStore } from "../../../setup/appStore";
import { AppState } from "../../../setup/appRootReducer";
import { getMsalClient } from "./msalClient";
import { userActions } from "../../../applications/common/actions/userActions";

const tresholdLimit = 300000; // 5 MIN in ms

export const preMiddleware: Middleware = {
  pre: async (context: RequestContext) => {
    const headers = context.init.headers as { [key: string]: string };
    const appState = appStore.getState() as AppState;
    const currentUser = appState.user.user;
    let token = appState.user.authInformation.accessToken ?? "";
    const refreshThreshold = new Date(Date.now() + tresholdLimit);

    if (
      currentUser &&
      currentUser.accountInfo &&
      currentUser.tokenExpiresOn &&
      refreshThreshold > currentUser.tokenExpiresOn
    ) {
      if (!appState.user.authSettings) {
        return;
      }
      const msalClient = getMsalClient(appState.user.authSettings);
      if (!msalClient) {
        return;
      }
      token = await msalClient
        .acquireTokenSilent({
          scopes: [appState.user.authSettings.resourceScope, "offline_access"],
          account: currentUser.accountInfo
        })
        .then((res) => {
          appStore.dispatch(userActions.refreshToken(res));
          return res.accessToken;
        })
        .catch((err) => {
          console.error(err);
          msalClient.acquireTokenRedirect({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scopes: [appState.user.authSettings!.resourceScope, "offline_access"]
          });
          // Will never return as acquireTokenRedirect will redirect away from the page.
          return "";
        });
    }

    headers["Authorization"] = `Bearer ${token}`;
    const params: FetchParams = {
      url: context.url,
      init: { ...context.init, headers: headers }
    };
    return Promise.resolve(params);
  }
};
