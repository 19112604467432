import { PublicClientApplication, SilentRequest, AuthenticationResult } from "@azure/msal-browser";
import { AuthSettings } from "../../../configuration/authConfig";

export function getMsalClient(
  authSettings: AuthSettings | undefined
): PublicClientApplication | undefined {
  if (!authSettings) {
    return undefined;
  }
  return new PublicClientApplication({
    auth: {
      clientId: authSettings.clientId,
      authority: authSettings.authority,
      redirectUri: authSettings.redirectUri,
      postLogoutRedirectUri: window.location.origin + process.env.REACT_APP_BASENAME
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  });
}

export interface GetTokenParams {
  authSettings: AuthSettings;
  request: SilentRequest;
  redirectToLogInPageWhenRefreshFails?: boolean;
}

export async function getAdToken(params: GetTokenParams): Promise<AuthenticationResult> {
  const client = getMsalClient(params.authSettings);
  if (!client) {
    throw Error("Could not create Msal client");
  }
  return await client.acquireTokenSilent(params.request);
}
