/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FirmwareDetailsDto,
    FirmwareDetailsDtoFromJSON,
    FirmwareDetailsDtoFromJSONTyped,
    FirmwareDetailsDtoToJSON,
} from './FirmwareDetailsDto';

/**
 * 
 * @export
 * @interface FirmwareDetailsResponseDto
 */
export interface FirmwareDetailsResponseDto {
    /**
     * 
     * @type {Array<FirmwareDetailsDto>}
     * @memberof FirmwareDetailsResponseDto
     */
    firmwareDetails: Array<FirmwareDetailsDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FirmwareDetailsResponseDto
     */
    missingSerialNumbers: Array<string>;
}

export function FirmwareDetailsResponseDtoFromJSON(json: any): FirmwareDetailsResponseDto {
    return FirmwareDetailsResponseDtoFromJSONTyped(json, false);
}

export function FirmwareDetailsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmwareDetailsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firmwareDetails': ((json['firmwareDetails'] as Array<any>).map(FirmwareDetailsDtoFromJSON)),
        'missingSerialNumbers': json['missingSerialNumbers'],
    };
}

export function FirmwareDetailsResponseDtoToJSON(value?: FirmwareDetailsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firmwareDetails': ((value.firmwareDetails as Array<any>).map(FirmwareDetailsDtoToJSON)),
        'missingSerialNumbers': value.missingSerialNumbers,
    };
}

