import { createGenericSaga, defaultSagaErrorHandler } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const trainingSagas = {
  getNodesFlat: createGenericSaga(
    "trainingSagas/getNodesFlat",
    getApiRegistry().trainingApi.apiTrainingNodesFlatGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: true,
      onFail: defaultSagaErrorHandler
    }
  ),

  getNodesRoots: createGenericSaga(
    "trainingSagas/getNodesRoots",
    getApiRegistry().trainingApi.apiTrainingNodesRootsGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  search: createGenericSaga(
    "trainingSagas/search",
    getApiRegistry().trainingApi.apiTrainingSearchArticlePost.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
