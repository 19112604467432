import { Action } from "redux";
import { OrderSearchFields } from "../components/OrderSearchForm";
import { ApiOrderGetRequest } from "../../../../api";

export enum OrdersViewActionTypes {
  ChangeOrderSearchFields = "OrdersView/ChangeSearchFields",
  ChangeOrderCriteria = "OrdersView/ChangeCriteria",
  StoreClickedGridIndex = "OrdersView/StoreClickedGridIndex"
}

export interface ChangeSearchFields extends Action {
  type: OrdersViewActionTypes.ChangeOrderSearchFields;
  fields: OrderSearchFields;
  storeGridIndex?: boolean;
  gridIndex?: number;
}

export interface ChangeOrderCriteria extends Action {
  type: OrdersViewActionTypes.ChangeOrderCriteria;
  criteria: ApiOrderGetRequest;
}

export interface StoreClickedGridIndex {
  type: OrdersViewActionTypes.StoreClickedGridIndex;
  index: number | undefined;
}

export const ordersViewActions = {
  changeSearchFields: (
    fields: OrderSearchFields,
    storeGridIndex?: boolean,
    gridIndex?: number
  ): ChangeSearchFields => ({
    type: OrdersViewActionTypes.ChangeOrderSearchFields,
    fields,
    storeGridIndex,
    gridIndex
  }),
  changeOrderCriteria: (criteria: ApiOrderGetRequest): ChangeOrderCriteria => ({
    type: OrdersViewActionTypes.ChangeOrderCriteria,
    criteria
  }),
  storeClickedGridIndex: (index: number | undefined): StoreClickedGridIndex => ({
    type: OrdersViewActionTypes.StoreClickedGridIndex,
    index
  })
};

export type OrdersViewActions = ChangeSearchFields | ChangeOrderCriteria | StoreClickedGridIndex;
