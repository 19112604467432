/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleTypes,
    RoleTypesFromJSON,
    RoleTypesFromJSONTyped,
    RoleTypesToJSON,
} from './RoleTypes';

/**
 * 
 * @export
 * @interface AccessRequestRoleDto
 */
export interface AccessRequestRoleDto {
    /**
     * 
     * @type {number}
     * @memberof AccessRequestRoleDto
     */
    roleId?: number;
    /**
     * 
     * @type {RoleTypes}
     * @memberof AccessRequestRoleDto
     */
    roleTypeId?: RoleTypes;
}

export function AccessRequestRoleDtoFromJSON(json: any): AccessRequestRoleDto {
    return AccessRequestRoleDtoFromJSONTyped(json, false);
}

export function AccessRequestRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleTypeId': !exists(json, 'roleTypeId') ? undefined : RoleTypesFromJSON(json['roleTypeId']),
    };
}

export function AccessRequestRoleDtoToJSON(value?: AccessRequestRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'roleTypeId': RoleTypesToJSON(value.roleTypeId),
    };
}

