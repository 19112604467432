import React, { useEffect, useState } from "react";
import { Button as AbbButton } from "@abb/abb-common-ux-react";
import styled, { useTheme } from "styled-components";
import { TFunction } from "i18next";
import { Dispatch } from "redux";
import { Button } from "../../../framework/components/Button";
import { ShoppingCartItemDto } from "api";
import { shoppingCartSagas } from "../../common/sagas/shoppingCartSagas";
import { useSelector } from "react-redux";
import { getUpdateShoppingCartItemAdditionalInformationStatus } from "applications/common/reducers/shoppingCartReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { usePrevious } from "../../../framework/hooks/usePrevious";
import { Dialog } from "framework/components/Dialog";
import { ResizableInput } from "framework/components/styled/ResizableInput";
import { shoppingCartActions } from "applications/common/actions/shoppingCartActions";

const Container = styled.div`
  min-height: 90px;
  height: 100%;
  width: 100%;
  min-width: 540px;
`;

// HorizontalElementContainer doesn't work inside the dialog for some reason(?)
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.sm};
  margin-block: ${(props) => props.theme.sizes.m};
`;

interface EditProductDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  selectedShoppingCartItem: ShoppingCartItemDto | undefined;
  dispatch: Dispatch;
  reviseMode: boolean;
  isQuoteView: boolean;
}

export const EditProductDialog = ({
  isOpen,
  t,
  setIsOpen,
  selectedShoppingCartItem,
  dispatch,
  reviseMode,
  isQuoteView
}: EditProductDialogProps): JSX.Element => {
  const theme = useTheme();
  const [description, setDescription] = useState<string>("");
  const loadingStatus = useSelector(getUpdateShoppingCartItemAdditionalInformationStatus);
  const prevLoadingStatus = usePrevious(loadingStatus);

  const EditProduct = () => {
    if (selectedShoppingCartItem) {
      if (reviseMode) {
        dispatch(
          // we can pass undefined because infotype parameter is empty, changes will not be applied then
          shoppingCartActions.updateLineItem(
            selectedShoppingCartItem.orderingCode,
            "",
            undefined,
            undefined,
            isQuoteView,
            selectedShoppingCartItem.quantity,
            description
          )
        );
        setIsOpen(false);
      } else {
        dispatch(
          shoppingCartSagas.updateShoppingCartItemAdditionalInformation.createAction({
            id: selectedShoppingCartItem.id,
            updateShoppingCartItemAdditionalInformationDto: {
              additionalInformation: description
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (selectedShoppingCartItem) {
      setDescription(selectedShoppingCartItem?.additionalInformation ?? "");
    }
  }, [selectedShoppingCartItem]);

  useEffect(() => {
    if (loadingStatus === RequestStatus.Completed && prevLoadingStatus === RequestStatus.Pending) {
      setIsOpen((open) => !open);
    }
  }, [loadingStatus, setIsOpen, prevLoadingStatus]);

  return (
    <Dialog
      contentClassName="dialog-content overflow-auto"
      title={selectedShoppingCartItem?.displayName}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Container>
        <ResizableInput
          value={description}
          onValueChange={(v) => setDescription(v)}
          dataType="textarea"
          label={t("Additional information regarding the product")}
          inputAttributes={{
            rows: "12"
          }}
          defaultType="large"
          style={{ marginTop: theme.sizes.lm }}
        />
        <ButtonContainer>
          <AbbButton
            type="discreet-black"
            text={t("Cancel")}
            shape="pill"
            onClick={() => setIsOpen((open) => !open)}
          />
          <Button
            buttonType="primary"
            text={t("Confirm changes")}
            onClick={() => EditProduct()}
            isLoading={loadingStatus === RequestStatus.Pending}
          />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};
