/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionQuoteHistoryItem
 */
export interface RevisionQuoteHistoryItem {
    /**
     * 
     * @type {number}
     * @memberof RevisionQuoteHistoryItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    identifier?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RevisionQuoteHistoryItem
     */
    revisionCreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    requestedRevisionBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    requestedRevisionByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    revisedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    revisedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    approvedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    originalRequestedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    originalRequestedByEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevisionQuoteHistoryItem
     */
    reference?: string | null;
}

export function RevisionQuoteHistoryItemFromJSON(json: any): RevisionQuoteHistoryItem {
    return RevisionQuoteHistoryItemFromJSONTyped(json, false);
}

export function RevisionQuoteHistoryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionQuoteHistoryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'revisionCreated': !exists(json, 'revisionCreated') ? undefined : (new Date(json['revisionCreated'])),
        'requestedRevisionBy': !exists(json, 'requestedRevisionBy') ? undefined : json['requestedRevisionBy'],
        'requestedRevisionByEmail': !exists(json, 'requestedRevisionByEmail') ? undefined : json['requestedRevisionByEmail'],
        'revisedBy': !exists(json, 'revisedBy') ? undefined : json['revisedBy'],
        'revisedByEmail': !exists(json, 'revisedByEmail') ? undefined : json['revisedByEmail'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : json['approvedBy'],
        'originalRequestedBy': !exists(json, 'originalRequestedBy') ? undefined : json['originalRequestedBy'],
        'originalRequestedByEmail': !exists(json, 'originalRequestedByEmail') ? undefined : json['originalRequestedByEmail'],
        'reference': !exists(json, 'reference') ? undefined : json['reference'],
    };
}

export function RevisionQuoteHistoryItemToJSON(value?: RevisionQuoteHistoryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'revisionCreated': value.revisionCreated === undefined ? undefined : (value.revisionCreated.toISOString()),
        'requestedRevisionBy': value.requestedRevisionBy,
        'requestedRevisionByEmail': value.requestedRevisionByEmail,
        'revisedBy': value.revisedBy,
        'revisedByEmail': value.revisedByEmail,
        'approvedBy': value.approvedBy,
        'originalRequestedBy': value.originalRequestedBy,
        'originalRequestedByEmail': value.originalRequestedByEmail,
        'reference': value.reference,
    };
}

