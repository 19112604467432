import { ProductLifecycleStatus, Standards } from "api";
import { Action } from "redux";
export enum ProductExplorerActionTypes {
  SetFilterApplications = "ProductExplorer/SetFilter/Applications",
  SetFilterFunctionalities = "ProductExplorer/SetFilter/Functionalities",
  SetFilterStandard = "ProductExplorer/SetFilter/Standard",
  SetPageNumber = "ProductExplorer/SetFilter/PageNumber",
  SetPageSize = "ProductExplorer/SetFilter/PageSize",
  SetSeriesId = "ProductExplorer/SetFilter/SetSeriesId",
  SetFilterShowOnlyLatest = "ProductExplorer/SetFilter/ShowOnlyLatest",
  SetLifeCycleStatus = "ProductExplorer/SetFilter/SetLifeCycleStatus"
}

export interface SetFilterApplications extends Action {
  type: ProductExplorerActionTypes.SetFilterApplications;
  applicationId: number | undefined;
}
export interface SetFilterFunctionalities extends Action {
  type: ProductExplorerActionTypes.SetFilterFunctionalities;
  functionalityIdList: Array<string>;
}
export interface SetFilterStandard extends Action {
  type: ProductExplorerActionTypes.SetFilterStandard;
  standard: Standards | undefined;
}
export interface SetPageNumber extends Action {
  type: ProductExplorerActionTypes.SetPageNumber;
  pageNumber: number;
}
export interface SetPageSize extends Action {
  type: ProductExplorerActionTypes.SetPageSize;
  pageSize: number;
}
export interface SetSeriesId extends Action {
  type: ProductExplorerActionTypes.SetSeriesId;
  seriesId: number | undefined;
}
export interface SetFilterShowOnlyLatest extends Action {
  type: ProductExplorerActionTypes.SetFilterShowOnlyLatest;
  showOnlyLatest: boolean;
}
export interface SetLifeCycleStatus extends Action {
  type: ProductExplorerActionTypes.SetLifeCycleStatus;
  lifeCycleStatus: ProductLifecycleStatus | undefined;
}
export const productExplorerActions = {
  SetFilterApplications: (applicationId: number | undefined): SetFilterApplications => ({
    type: ProductExplorerActionTypes.SetFilterApplications,
    applicationId
  }),
  SetFilterFunctionalities: (functionalityIdList: Array<string>): SetFilterFunctionalities => ({
    type: ProductExplorerActionTypes.SetFilterFunctionalities,
    functionalityIdList
  }),
  SetFilterStandard: (standard: Standards | undefined): SetFilterStandard => ({
    type: ProductExplorerActionTypes.SetFilterStandard,
    standard
  }),
  SetPageNumber: (pageNumber: number): SetPageNumber => ({
    type: ProductExplorerActionTypes.SetPageNumber,
    pageNumber
  }),
  SetPageSize: (pageSize: number): SetPageSize => ({
    type: ProductExplorerActionTypes.SetPageSize,
    pageSize
  }),
  SetSeriesId: (seriesId: number | undefined): SetSeriesId => ({
    type: ProductExplorerActionTypes.SetSeriesId,
    seriesId
  }),
  SetFilterShowOnlyLatest: (showOnlyLatest: boolean): SetFilterShowOnlyLatest => ({
    type: ProductExplorerActionTypes.SetFilterShowOnlyLatest,
    showOnlyLatest
  }),
  SetLifeCycleStatus: (
    lifeCycleStatus: ProductLifecycleStatus | undefined
  ): SetLifeCycleStatus => ({
    type: ProductExplorerActionTypes.SetLifeCycleStatus,
    lifeCycleStatus
  })
};

export type productExplorerActions =
  | SetFilterApplications
  | SetFilterFunctionalities
  | SetFilterStandard
  | SetPageNumber
  | SetPageSize
  | SetSeriesId
  | SetFilterShowOnlyLatest
  | SetLifeCycleStatus;
