import {
  createGenericSaga,
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const manageCompaniesSagas = {
  getCompanyToManage: createGenericSaga(
    "manageCompaniesSagas/getCompanyToManage",
    getApiRegistry().companiesApi.apiCompaniesIdGet.bind(getApiRegistry().companiesApi)
  ),
  deleteCompanyUser: createGenericSaga(
    "manageCompaniesSagas/deleteCompanyUser",
    getApiRegistry().companiesApi.apiCompaniesCompanyUsersDelete.bind(
      getApiRegistry().companiesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("User removed"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateUserCompanyAccess: createGenericSaga(
    "manageCompaniesSagas/updateUserCompanyAccess",
    getApiRegistry().companiesApi.apiCompaniesCompanyUsersPatch.bind(getApiRegistry().companiesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("User access updated"),
      onFail: defaultSagaErrorHandler
    }
  ),
  addUserToCompany: createGenericSaga(
    "manageCompaniesSagas/addUserToCompany",
    getApiRegistry().companiesApi.apiCompaniesCompanyUsersPost.bind(getApiRegistry().companiesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Access right created"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateCompanyOverrideStatus: createGenericSaga(
    "manageCompanies/updateCompanyOverrideStatus",
    getApiRegistry().companiesApi.apiCompaniesOverrideStatusPatch.bind(
      getApiRegistry().companiesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Override status updated"),
      onFail: defaultSagaErrorHandler
    }
  ),
  companyAccessAudit: createGenericSaga(
    "manageCompanies/companyAccessAudit",
    getApiRegistry().companiesApi.apiCompaniesAccessAuditPost.bind(getApiRegistry().companiesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Access audit done!"),
      onFail: defaultSagaErrorHandler
    }
  ),
  overrideStatusAudit: createGenericSaga(
    "manageCompanies/overrideStatusAudit",
    getApiRegistry().systemSettingsApi.apiSystemSettingsOverrideStatusAuditPatch.bind(
      getApiRegistry().systemSettingsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Override audit done!"),
      onFail: defaultSagaErrorHandler
    }
  )
};
