import { Link } from "@abb/abb-common-ux-react";
import { getGeneralApplicationSettings } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface TermsAndConditionsLinkProps {
  onClick: () => void;
}

const TermsAndConditionsLink = ({ onClick }: TermsAndConditionsLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const applicationSettings = useSelector(getGeneralApplicationSettings);

  return applicationSettings.hideTermsAndConditionsLinks ? (
    <></>
  ) : (
    <Link discreet preventRedirect underlined={false} iconBefore={"abb/download"} onClick={onClick}>
      {t("Terms and conditions")}
    </Link>
  );
};

export default TermsAndConditionsLink;
