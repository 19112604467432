import React, { useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { Dropdown, DropdownOption, Icon } from "@abb/abb-common-ux-react";
import { Button } from "framework/components/Button";
import { useTranslation } from "react-i18next";
import {
  CardActionsFlex,
  CardsContainer,
  ContentCard,
  DropDownItems,
  DropDownItemsSelected,
  Introduction,
  ProductCard
} from "./StyledComponents";
import { productComparisonActions } from "../actions/ProductComparisonActions";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "utilities/routes";
import { useNavigate } from "react-router-dom";
import { NotificationSeverity, ProductVersionDetailDto } from "api";
import { getConfiguratorModularProductLink } from "utilities/urlUtils";
import { getConfiguratorBaseUrl } from "applications/common/reducers/commonReducer";
import noImg from "assets/NoPic.gif";
import { Link } from "framework/components/Link";
import { getApiRegistry } from "framework/state/apiRegistry";
import { getProducts, getProductsNames } from "../reducers/productComparisonReducers";
import { CustomCheckbox } from "applications/products/productExplorer/components/StyledComponents";
import { productComparisonSagas } from "../sagas/productComparisonSagas";
import { commonUXTheme } from "styles/commonUXVariables";
import { userActions } from "applications/common/actions/userActions";
interface Props {
  products: ProductVersionDetailDto[];
  maxElementInCompare: number;
  minimal?: boolean;
}
export const ProductsCards = ({ products: productsToCompare, maxElementInCompare }: Props) => {
  const [useLastVersion, setUseLastVersion] = React.useState<boolean>(true);
  const [selectedSingle, setSelectedSingle] = React.useState<
    | {
        value: number;
        label?: string;
      }
    | undefined
  >(undefined);

  // Selector
  const lstProductsNames = useSelector(getProductsNames);
  const productsVersion = useSelector(getProducts);
  // Const
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* get names for dropdown */
  useEffect(() => {
    dispatch(
      productComparisonSagas.getProductsNames.createAction({ lastVersionOnly: useLastVersion })
    );
  }, [dispatch, useLastVersion]);

  useEffect(() => {
    const productId = selectedSingle?.value;
    if (productId == null) return;
    if (productsVersion.length > 0 && productsVersion.some((p) => p.id == productId)) {
      const a = productsVersion.find((p) => p.id == productId);
      if (a) dispatch(productComparisonActions.addToProductToCompare(a));
      setSelectedSingle(undefined);
    } else {
      getApiRegistry()
        .productExplorerApi.apiProductExplorerProductIdGet({ id: productId })
        .then((res) => {
          dispatch(productComparisonActions.addToProductToCompare(res));
          setSelectedSingle(undefined);
        })
        .catch((res) => {
          if (res && res.status === 404) {
            dispatch(
              userActions.addNotification(
                NotificationSeverity.Error,
                t("Product not found. If the error persists, please contact us.")
              )
            );
          } else {
            res.json().then((json: any) => {
              dispatch(
                userActions.addNotification(
                  NotificationSeverity.Error,
                  t("An unknown error occurred while getting the product: {{error}}", {
                    error: json.detail
                  })
                )
              );
            });
          }
        });
    }
  }, [dispatch, productsVersion, selectedSingle, t]);

  const configuratorBaseUrl = useSelector(getConfiguratorBaseUrl);

  return (
    <CardsContainer>
      <Introduction className="largeCard">
        <div>
          <p>
            <b>{t(" The Relays-Online product comparator")}</b>
            {t(
              " lets you easily compare products according to the characteristics you need to meet your protection and control requirements. Products can be added and compared easily. This guide further assists in the in the selection of the most appropriate product for your needs and opens the path to configuring product ordering codes and ultimately, placing an order. "
            )}
          </p>
        </div>
      </Introduction>

      {productsToCompare.map((product) => (
        <ProductCard className="smallCard" key={product.id}>
          <>
            <CardHeader
              action={
                <Icon
                  name="abb/close"
                  sizeClass="medium"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    dispatch(productComparisonActions.removeToProductToCompare(product))
                  }
                />
              }
            />
            <CardMedia component="img" image={product.previewImage ?? noImg} alt={product.name} />
          </>
          <CardContent style={{ padding: "1rem" }}>
            <>
              <h4>{product.name}</h4>
              <div style={{ marginTop: "1rem" }}>{product.shortDescription}</div>{" "}
              {/* possible to use long description */}
              <Link
                className="read-more"
                iconBefore={"abb/open-in-new-window"}
                to={product.pageUrl}
                target="_blank"
                style={{ marginTop: "1rem" }}
              >
                {t("Read more about the product")}
              </Link>
            </>
          </CardContent>
          {product.hasActiveStatus ? (
            <CardActionsFlex>
              <Button
                text={t("Configure product")}
                buttonType="secondary"
                href={
                  product.materialCode
                    ? getConfiguratorModularProductLink(
                        configuratorBaseUrl,
                        product.materialCode,
                        product.materialCodeMasks
                      )
                    : undefined
                }
                onClick={() =>
                  !product.materialCode
                    ? navigate(routes.products.productSelectionMatrix, {
                        state: {
                          scrollToStaticProducts: true
                        }
                      })
                    : undefined
                }
                style={{ marginBottom: "1rem" }}
              />
            </CardActionsFlex>
          ) : null}
        </ProductCard>
      ))}

      {productsToCompare.length < maxElementInCompare ? (
        <ContentCard className="smallCard" style={{ overflow: "visible" }}>
          <CardContent style={{ padding: "1rem" }}>
            <h3>{t("Add relay to comparator")}</h3>
          </CardContent>
          <CardActionsFlex>
            <Dropdown
              label={t("Select product to compare")}
              placeholder={t("Search product name")}
              monochrome
              value={selectedSingle ? [selectedSingle] : undefined}
              onChange={(val) => {
                setSelectedSingle(val.length > 0 ? val[0] : undefined);
              }}
              initialOpen={false}
              searchable
              style={{ zIndex: commonUXTheme.zIndices.regularDropdown, margin: 0 }}
              showSpinnerOnBottom={lstProductsNames.length === 0}
            >
              {lstProductsNames.map((o) => (
                <DropdownOption
                  key={o.id}
                  label={o.name}
                  value={o.id}
                  disabled={productsToCompare.some((p) => p.id === o.id)}
                  menuItemTemplate={<DropDownItems>{o.name}</DropDownItems>}
                  selectedItemTemplate={<DropDownItemsSelected>{o.name}</DropDownItemsSelected>}
                />
              ))}
            </Dropdown>
            <CustomCheckbox
              value={useLastVersion}
              label={t("Show latest version of products only")}
              onChange={() => setUseLastVersion(!useLastVersion)}
              monochrome
              sizeClass="small"
            />
          </CardActionsFlex>
        </ContentCard>
      ) : undefined}
    </CardsContainer>
  );
};
