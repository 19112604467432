import { createGenericSaga, defaultSagaErrorHandler } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const terminologyBankSagas = {
  getNodesFlat: createGenericSaga(
    "terminologyBankSagas/getNodesFlat",
    getApiRegistry().trainingApi.apiTrainingNodesFlatGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: true,
      onFail: defaultSagaErrorHandler
    }
  ),
  getNodesRoots: createGenericSaga(
    "terminologyBankSagas/getNodesRoots",
    getApiRegistry().trainingApi.apiTrainingNodesRootsGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  search: createGenericSaga(
    "terminologyBankSagas/search",
    getApiRegistry().trainingApi.apiTrainingSearchArticlePost.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
