import { Heading, HeadingProps } from "@abb/abb-common-ux-react/components/Heading";
import { Link } from "@abb/abb-common-ux-react/components/Link";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import React from "react";
import { dialogStyles } from "styles/dialogStyles";
import { headerStyles } from "styles/headerStyles";
import { Dialog } from "@abb/abb-common-ux-react/components/Dialog";
import { markdownStyles } from "styles/markdownStyles";
import { PillLink } from "framework/components/PillLink";

export const FooterBackground = styled.footer<{
  backgroundImage: string;
  backgroundImageWebP: string;
}>`
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 70%),
      url(${(props) => props.backgroundImage});
    @supports (
      background-image: -webkit-image-set(url(${(props) => props.backgroundImageWebP}) 1x)
    ) {
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 70%),
        url(${(props) => props.backgroundImageWebP});
    }
    background-position: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: ${commonUXTheme.breakpoints.lg}) {
      filter: opacity(0.4);
      background-image: url(${(props) => props.backgroundImage});
      @supports (
        background-image: -webkit-image-set(url(${(props) => props.backgroundImageWebP}) 1x)
      ) {
        background-image: url(${(props) => props.backgroundImageWebP});
      }
    }
  }
  position: relative;
  padding-block: ${commonUXTheme.sizes.xl};
  padding-inline: ${commonUXTheme.sizes.lm};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

// For footer
export const MaxWidthContainer = styled.div<{ maxWidth: number }>`
  max-width: ${(props) => props.maxWidth}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const FooterHeader = styled(Heading)`
  font-family: ${commonUXTheme.fonts.families.fontAbbBold} !important;
  font-size: ${commonUXTheme.fonts.sizes.fontSizeM} !important;
  line-height: ${commonUXTheme.fonts.lineHeights.lineHeightM} !important;
  margin-bottom: ${commonUXTheme.sizes.s};
`;

export const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${commonUXTheme.breakpoints.lg}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: ${commonUXTheme.sizes.lm};
  @media (max-width: 830px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const FooterTopItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-inline: ${commonUXTheme.sizes.xl};

  &:first-child {
    padding-left: 0;
    padding-right: ${commonUXTheme.sizes.xl};
  }

  &:last-child {
    padding-right: 0;
    padding-left: ${commonUXTheme.sizes.xl};
  }

  @media (max-width: ${commonUXTheme.breakpoints.lg}) {
    &:not(:last-child) {
      margin-bottom: ${commonUXTheme.sizes.xl};
    }
    padding-inline: 0 !important;
  }
`;

export const FooterButton = styled(PillLink).attrs(() => ({
  useExternalLinks: true
}))`
  text-decoration: none !important;
  width: 150px;
  margin-top: auto;
  @media (max-width: ${commonUXTheme.breakpoints.lg}) {
    margin-top: 0px;
  }
`;

export const FooterSplitter = styled.hr`
  border-top-color: ${commonUXTheme.colors.grey30};
  margin: ${commonUXTheme.sizes.l} 0px;
`;

export const DisclaimerText = styled.p`
  font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
  color: ${commonUXTheme.colors.grey60};
`;

export const FooterLink = styled(Link).attrs(() => {
  return {
    discreet: true,
    underlined: false
  };
})`
  :hover {
    cursor: pointer;
  }
`;

// z-index must be higher than FooterBackground z-index to render the content above the background image.
export const FooterContent = styled.div`
  margin: 0px auto;
  z-index: ${commonUXTheme.zIndices.footerContent};
  width: 100%;
  max-width: ${commonUXTheme.breakpoints.xxl};
  @media print {
    display: none;
  }
`;

export const FooterContainer = styled.div`
  background-color: ${commonUXTheme.colors.grey10};
`;

export const ReleaseNotesContainer = styled.div`
  min-height: 60vh;
`;

export const ReleaseNotesContentContainer = styled.div`
  margin-top: ${commonUXTheme.sizes.lm};
`;

export const Hr = styled.hr`
  margin-top: ${commonUXTheme.sizes.m} !important;
  margin-bottom: ${commonUXTheme.sizes.m} !important;
  border-top: ${commonUXTheme.borders.widths.borderWidthM} solid ${commonUXTheme.colors.grey30} !important;
  opacity: 1 !important;
`;

const StyledSectionHeader = styled(Heading)<{ noBorderMargin?: boolean }>`
  font-family: ${commonUXTheme.fonts.families.fontAbbBold} !important;
  font-size: ${commonUXTheme.fonts.sizes.fontSizeLM} !important;
  line-height: ${commonUXTheme.fonts.lineHeights.lineHeightM} !important;
  margin-bottom: ${(props) => (props.noBorderMargin ? "0px" : commonUXTheme.sizes.s)};
`;

interface SectionHeaderProps extends Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level"> {
  noBorderMargin?: boolean;
}

export const ReleaseNotesSectionHeader = (props: SectionHeaderProps): JSX.Element => (
  <StyledSectionHeader {...props} level={3} blackAndWhite={true} showCursor={false} />
);

const StyledSubHeader = styled(Heading)`
  font-family: ${commonUXTheme.fonts.families.fontAbbBold} !important;
  font-size: ${commonUXTheme.fonts.sizes.fontSizeL} !important;
  line-height: ${commonUXTheme.fonts.lineHeights.lineHeightMl} !important;
`;

export const ReleaseNotesSubHeader = (
  props: Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level">
): JSX.Element => <StyledSubHeader {...props} level={2} blackAndWhite={true} showCursor={false} />;

export const DialogWithEmbeddedStyles = styled(Dialog)`
  ${dialogStyles}
  ${headerStyles}
  ${markdownStyles}
  p {
    margin-top: ${commonUXTheme.sizes.m} !important;
  }
`;
