import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const deliveriesHomeSagas = {
  getCompanies: createGenericSaga(
    "deliveriesHomeSagas/getCompanies",
    getApiRegistry().companiesApi.apiCompaniesGet.bind(getApiRegistry().companiesApi)
  ),
  getSystemSettings: createGenericSaga(
    "deliveriesHomeSagas/getSystemSettings",
    getApiRegistry().systemSettingsApi.apiSystemSettingsGet.bind(getApiRegistry().systemSettingsApi)
  ),
  updateFrontPageContent: createGenericSaga(
    "deliveriesHomeSagas/updateFrontPageContent",
    getApiRegistry().systemSettingsApi.apiSystemSettingsFrontpagePut.bind(
      getApiRegistry().systemSettingsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Content saved"),
      onFail: defaultSagaErrorHandler
    }
  ),
  sendHelpRequest: createGenericSaga(
    "deliveriesHomeSagas/sendHelpRequest",
    getApiRegistry().helpRequestApi.apiHelpRequestSendHelpRequestPost.bind(
      getApiRegistry().helpRequestApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Help request sent"),
      onFail: defaultSagaErrorHandler
    }
  )
};
