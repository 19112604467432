/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeMemberActivityCommand,
    ChangeMemberActivityCommandFromJSON,
    ChangeMemberActivityCommandToJSON,
    ChangeMemberRoleCommand,
    ChangeMemberRoleCommandFromJSON,
    ChangeMemberRoleCommandToJSON,
    ChangeMemberStatusCommand,
    ChangeMemberStatusCommandFromJSON,
    ChangeMemberStatusCommandToJSON,
    ChangeMemberTeamInUseCommand,
    ChangeMemberTeamInUseCommandFromJSON,
    ChangeMemberTeamInUseCommandToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoToJSON,
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoToJSON,
    TeamMembersVm,
    TeamMembersVmFromJSON,
    TeamMembersVmToJSON,
    TeamTypes,
    TeamTypesFromJSON,
    TeamTypesToJSON,
    TeamsVm,
    TeamsVmFromJSON,
    TeamsVmToJSON,
} from '../models';

export interface ApiTeamsChangeMemberActivityPostRequest {
    changeMemberActivityCommand?: ChangeMemberActivityCommand;
}

export interface ApiTeamsChangeMemberRolePostRequest {
    changeMemberRoleCommand?: ChangeMemberRoleCommand;
}

export interface ApiTeamsChangeMemberStatusPostRequest {
    changeMemberStatusCommand?: ChangeMemberStatusCommand;
}

export interface ApiTeamsChangeMemberTeamInUsePostRequest {
    changeMemberTeamInUseCommand?: ChangeMemberTeamInUseCommand;
}

export interface ApiTeamsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaName?: string;
    criteriaDescription?: string;
    criteriaTypes?: Array<TeamTypes>;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiTeamsIdGetRequest {
    id: number;
}

export interface ApiTeamsMembersGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaTeamId?: number;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiTeamsUserTeamsGetRequest {
    criteriaUserId?: number;
}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     */
    async apiTeamsChangeMemberActivityPostRaw(requestParameters: ApiTeamsChangeMemberActivityPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberActivity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberActivityCommandToJSON(requestParameters.changeMemberActivityCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberActivityPost(requestParameters: ApiTeamsChangeMemberActivityPostRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberActivityPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberRolePostRaw(requestParameters: ApiTeamsChangeMemberRolePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberRole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberRoleCommandToJSON(requestParameters.changeMemberRoleCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberRolePost(requestParameters: ApiTeamsChangeMemberRolePostRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberRolePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberStatusPostRaw(requestParameters: ApiTeamsChangeMemberStatusPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberStatusCommandToJSON(requestParameters.changeMemberStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberStatusPost(requestParameters: ApiTeamsChangeMemberStatusPostRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberStatusPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsChangeMemberTeamInUsePostRaw(requestParameters: ApiTeamsChangeMemberTeamInUsePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/ChangeMemberTeamInUse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMemberTeamInUseCommandToJSON(requestParameters.changeMemberTeamInUseCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMemberDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsChangeMemberTeamInUsePost(requestParameters: ApiTeamsChangeMemberTeamInUsePostRequest, initOverrides?: RequestInit): Promise<TeamMemberDto> {
        const response = await this.apiTeamsChangeMemberTeamInUsePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsGetRaw(requestParameters: ApiTeamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiTeamsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiTeamsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaName !== undefined) {
            queryParameters['Criteria.Name'] = requestParameters.criteriaName;
        }

        if (requestParameters.criteriaDescription !== undefined) {
            queryParameters['Criteria.Description'] = requestParameters.criteriaDescription;
        }

        if (requestParameters.criteriaTypes) {
            queryParameters['Criteria.Types'] = requestParameters.criteriaTypes;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsGet(requestParameters: ApiTeamsGetRequest, initOverrides?: RequestInit): Promise<TeamsVm> {
        const response = await this.apiTeamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsIdGetRaw(requestParameters: ApiTeamsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTeamsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsIdGet(requestParameters: ApiTeamsIdGetRequest, initOverrides?: RequestInit): Promise<TeamDto> {
        const response = await this.apiTeamsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsMembersGetRaw(requestParameters: ApiTeamsMembersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TeamMembersVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiTeamsMembersGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiTeamsMembersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaTeamId !== undefined) {
            queryParameters['Criteria.TeamId'] = requestParameters.criteriaTeamId;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/Members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamMembersVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTeamsMembersGet(requestParameters: ApiTeamsMembersGetRequest, initOverrides?: RequestInit): Promise<TeamMembersVm> {
        const response = await this.apiTeamsMembersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeamsUserTeamsGetRaw(requestParameters: ApiTeamsUserTeamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TeamDto>>> {
        const queryParameters: any = {};

        if (requestParameters.criteriaUserId !== undefined) {
            queryParameters['Criteria.UserId'] = requestParameters.criteriaUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Teams/UserTeams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamDtoFromJSON));
    }

    /**
     */
    async apiTeamsUserTeamsGet(requestParameters: ApiTeamsUserTeamsGetRequest, initOverrides?: RequestInit): Promise<Array<TeamDto>> {
        const response = await this.apiTeamsUserTeamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
