/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserCommand
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    timeZone: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    countryCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    demoMode: boolean;
}

export function UpdateUserCommandFromJSON(json: any): UpdateUserCommand {
    return UpdateUserCommandFromJSONTyped(json, false);
}

export function UpdateUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'position': json['position'],
        'language': json['language'],
        'timeZone': json['timeZone'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'demoMode': json['demoMode'],
    };
}

export function UpdateUserCommandToJSON(value?: UpdateUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'position': value.position,
        'language': value.language,
        'timeZone': value.timeZone,
        'countryCode': value.countryCode,
        'demoMode': value.demoMode,
    };
}

