import { CompanyDto } from "api/models/CompanyDto";
import { UserVm } from "api/models/UserVm";
import { debounce } from "lodash-es";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { InputActionMeta, OnChangeValue } from "react-select";
import { isAuthorizedToComponent } from "utilities/authUtils";
import { NavMenuCompany } from "./NavItem";
import {
  CompanyDropdown,
  DropdownLabel,
  DropdownWrapper,
  getDownMediaQuery,
  OptionType
} from "./StyledComponents";
import { AccessRights } from "api/models/AccessRights";
import { usePrevious } from "framework/hooks/usePrevious";
import { roleConstants } from "constants/roleConstants";
import { Box, useMediaQuery } from "@mui/material";
import { RoleGroups } from "api/models/RoleGroups";

export const companiesDropdownWidth = 400;

const AllCompaniesSelection: NavMenuCompany = {
  label: "All companies",
  value: undefined
};

interface CompaniesDropdownProps {
  userCompanies: CompanyDto[];
  userInitialCompanySelection: CompanyDto | undefined;
  currentUser: UserVm | undefined;
  roleGroup: RoleGroups | undefined;
  isLoadingCompanies?: boolean;
  isMobile: boolean;
  handleLoadUserCompanies: (
    reset: boolean,
    input?: string,
    isDefault?: boolean,
    resetDefault?: boolean
  ) => void;
  handleSelectCompany: (company: NavMenuCompany) => void;
  translateText?: (text: string) => string;
  wideScreenSize: number;
}

enum AllCompaniesAccess {
  NoAccess = 0,
  ReadAccess = 1,
  SelectAccess = 2
}

export const CompaniesDropdown: FC<CompaniesDropdownProps> = ({
  userCompanies,
  userInitialCompanySelection,
  currentUser,
  roleGroup,
  handleLoadUserCompanies,
  handleSelectCompany,
  translateText,
  isLoadingCompanies,
  isMobile,
  wideScreenSize
}: CompaniesDropdownProps) => {
  // State
  const companyDropdownRef = useRef<HTMLDivElement>(null);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState<boolean>(false);
  const [companySearchDropdownWidth, setCompanySearchDropdownWidth] = useState<number | undefined>(
    undefined
  );

  const [shouldGetCompaniesOnOpen, setShouldGetCompaniesOnOpen] = useState(false);
  const prevRoleGroup = usePrevious(roleGroup);

  const getTranslation = (text: string) => {
    if (translateText) {
      return translateText(text);
    }
    return text;
  };

  const getTranslationCallback = useCallback(getTranslation, [translateText]);

  const [selectedCompany, setSelectedCompany] = useState<NavMenuCompany | undefined>(undefined);
  const debouncedLoadMoreRows = debounce(handleLoadUserCompanies, 500);

  const isSmallScreen = useMediaQuery(getDownMediaQuery(wideScreenSize), {
    noSsr: true
  });

  const getAllCompaniesSelectionAccessLevel = (
    roleGroup: RoleGroups | undefined
  ): AllCompaniesAccess => {
    // Selection is visible with these access rights for company "0" which means global access
    let accessRightsToCheck: AccessRights[] = [
      AccessRights.ManageDeliveriesCompanies,
      AccessRights.ViewAllOrders,
      AccessRights.ManagePriceLists,
      AccessRights.ViewCompanyPriceLists
    ];

    if (
      !isAuthorizedToComponent(currentUser?.userAccessRights ?? {}, {
        operation: "any",
        accessRights: accessRightsToCheck,
        identifier: roleConstants.GlobalRoleIdentifier
      })
    ) {
      return AllCompaniesAccess.NoAccess;
    }

    let access: AllCompaniesAccess = AllCompaniesAccess.ReadAccess;

    // Set application specific access rights to check if defined
    if (roleGroup === RoleGroups.DeliverIt) {
      accessRightsToCheck = [AccessRights.ManageDeliveriesCompanies, AccessRights.ViewAllOrders];
    } else if (roleGroup === RoleGroups.Configurator) {
      accessRightsToCheck = [AccessRights.ManagePriceLists, AccessRights.ViewCompanyPriceLists];
    }

    if (
      isAuthorizedToComponent(currentUser?.userAccessRights ?? {}, {
        operation: "any",
        accessRights: accessRightsToCheck,
        identifier: roleConstants.GlobalRoleIdentifier
      })
    ) {
      access = AllCompaniesAccess.SelectAccess;
    }
    return access;
  };

  const getAllCompaniesSelectionAccessLevelCallback = useCallback(
    getAllCompaniesSelectionAccessLevel,
    [currentUser]
  );

  // There were issues getting the menubar synced in case the application changes, so using a state variable to indicate
  // the companies should be reloaded if the application has changed.
  useEffect(() => {
    if (roleGroup !== prevRoleGroup) {
      setShouldGetCompaniesOnOpen(true);
    }
  }, [roleGroup, prevRoleGroup]);

  // Initial selection, from back end.
  // TODO need validation. Should be done in the backend.
  useEffect(() => {
    if (!userInitialCompanySelection) {
      if (getAllCompaniesSelectionAccessLevelCallback(undefined)) {
        setSelectedCompany({
          ...AllCompaniesSelection,
          label: getTranslationCallback(AllCompaniesSelection.label)
        });
      } else {
        setSelectedCompany(undefined);
      }
    } else {
      setSelectedCompany({
        value: userInitialCompanySelection.id,
        label: userInitialCompanySelection.companyDisplayName
      });
    }
    // todo translation missing because callback doesn't work for it here
    //eslint-disable-next-line
  }, [userInitialCompanySelection, getAllCompaniesSelectionAccessLevelCallback]);

  const getOptions = () => {
    const companiesList: NavMenuCompany[] = [];

    const allCompaniesAccess = getAllCompaniesSelectionAccessLevel(roleGroup);
    if (allCompaniesAccess) {
      companiesList.push({
        ...AllCompaniesSelection,
        isDisabled: allCompaniesAccess !== AllCompaniesAccess.SelectAccess
      });
    }

    const results: NavMenuCompany[] = companiesList.concat(
      userCompanies.map((c) => {
        return { label: c.companyDisplayName, value: c.id, isDisabled: !c.canSelect };
      })
    );
    //, isDisabled: true
    return results.map((c) => {
      return { label: c.label, value: c.value?.toString() ?? undefined, isDisabled: c.isDisabled };
    });
  };

  return (
    <DropdownWrapper isMobile={isMobile}>
      {!isSmallScreen && <DropdownLabel>{getTranslation("Company")}:</DropdownLabel>}
      <Box
        marginTop={0}
        sx={{ width: { xs: isMobile ? "100%" : companiesDropdownWidth + "px" } }}
        ref={companyDropdownRef}
      >
        <CompanyDropdown
          placeholder={getTranslation("Select a company")}
          classNamePrefix="ABB"
          menuIsOpen={isCompanyDropdownOpen}
          width={companySearchDropdownWidth}
          options={getOptions()}
          value={selectedCompany}
          isLoading={isLoadingCompanies}
          onMenuScrollToBottom={() => {
            handleLoadUserCompanies(false);
          }}
          filterOption={() => {
            return true;
          }}
          onMenuOpen={() => {
            if (companyDropdownRef.current?.offsetWidth !== companySearchDropdownWidth) {
              setCompanySearchDropdownWidth(companyDropdownRef.current?.offsetWidth);
            }
            if (!isCompanyDropdownOpen) {
              setIsCompanyDropdownOpen(true);
            }

            if (shouldGetCompaniesOnOpen) {
              // Load companies when the menu opens if the application has changed.
              handleLoadUserCompanies(true, undefined, undefined, true);
              setShouldGetCompaniesOnOpen(false);
            }
          }}
          onMenuClose={() => {
            handleLoadUserCompanies(true, undefined, true);
            setIsCompanyDropdownOpen(false);
          }}
          onInputChange={(value: string, actionMeta: InputActionMeta) => {
            if (value !== undefined && actionMeta.action === "input-change") {
              debouncedLoadMoreRows(true, value);
            }
          }}
          onChange={(selectedOption: OnChangeValue<any, false>) => {
            const option = selectedOption as OptionType;
            if (!option) {
              return;
            }
            const navMenuCompany: NavMenuCompany = {
              value: option?.value,
              label: option?.label ?? ""
            };
            setSelectedCompany(navMenuCompany);
            handleSelectCompany(navMenuCompany);
          }}
        />
      </Box>
    </DropdownWrapper>
  );
};
