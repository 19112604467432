/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Article,
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';

/**
 * 
 * @export
 * @interface ArticlePosition
 */
export interface ArticlePosition {
    /**
     * 
     * @type {Article}
     * @memberof ArticlePosition
     */
    article?: Article;
    /**
     * 
     * @type {number}
     * @memberof ArticlePosition
     */
    order: number;
}

export function ArticlePositionFromJSON(json: any): ArticlePosition {
    return ArticlePositionFromJSONTyped(json, false);
}

export function ArticlePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticlePosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'article': !exists(json, 'article') ? undefined : ArticleFromJSON(json['article']),
        'order': json['order'],
    };
}

export function ArticlePositionToJSON(value?: ArticlePosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'article': ArticleToJSON(value.article),
        'order': value.order,
    };
}

