/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateQuoteItemDto,
    CreateQuoteItemDtoFromJSON,
    CreateQuoteItemDtoFromJSONTyped,
    CreateQuoteItemDtoToJSON,
} from './CreateQuoteItemDto';
import {
    QuoteAttachmentDto,
    QuoteAttachmentDtoFromJSON,
    QuoteAttachmentDtoFromJSONTyped,
    QuoteAttachmentDtoToJSON,
} from './QuoteAttachmentDto';
import {
    QuoteStateTypes,
    QuoteStateTypesFromJSON,
    QuoteStateTypesFromJSONTyped,
    QuoteStateTypesToJSON,
} from './QuoteStateTypes';
import {
    ReviseQuoteItemDto,
    ReviseQuoteItemDtoFromJSON,
    ReviseQuoteItemDtoFromJSONTyped,
    ReviseQuoteItemDtoToJSON,
} from './ReviseQuoteItemDto';
import {
    SalesChannelTypes,
    SalesChannelTypesFromJSON,
    SalesChannelTypesFromJSONTyped,
    SalesChannelTypesToJSON,
} from './SalesChannelTypes';

/**
 * 
 * @export
 * @interface ReviseQuoteDto
 */
export interface ReviseQuoteDto {
    /**
     * 
     * @type {Array<CreateQuoteItemDto>}
     * @memberof ReviseQuoteDto
     */
    items: Array<CreateQuoteItemDto>;
    /**
     * 
     * @type {QuoteStateTypes}
     * @memberof ReviseQuoteDto
     */
    stateId: QuoteStateTypes;
    /**
     * 
     * @type {number}
     * @memberof ReviseQuoteDto
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    sfdcReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    quoteReceiverEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerCompanyStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerCompanyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerCompanyCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerCompanyCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    accountCustomerIcvCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    endCustomerCompanyGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    termsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    termsOfDeliveryDescription?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReviseQuoteDto
     */
    expectedOrderDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReviseQuoteDto
     */
    tenderValidityDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReviseQuoteDto
     */
    blanketWaiver?: boolean | null;
    /**
     * 
     * @type {SalesChannelTypes}
     * @memberof ReviseQuoteDto
     */
    salesChannelId?: SalesChannelTypes;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteDto
     */
    pgCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReviseQuoteDto
     */
    winningPercentage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReviseQuoteDto
     */
    totalSalesPrice?: number | null;
    /**
     * 
     * @type {Array<ReviseQuoteItemDto>}
     * @memberof ReviseQuoteDto
     */
    revisionItems: Array<ReviseQuoteItemDto>;
    /**
     * 
     * @type {Array<QuoteAttachmentDto>}
     * @memberof ReviseQuoteDto
     */
    quoteAttachmentsToBeCopied: Array<QuoteAttachmentDto>;
}

export function ReviseQuoteDtoFromJSON(json: any): ReviseQuoteDto {
    return ReviseQuoteDtoFromJSONTyped(json, false);
}

export function ReviseQuoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviseQuoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CreateQuoteItemDtoFromJSON)),
        'stateId': QuoteStateTypesFromJSON(json['stateId']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'sfdcReference': !exists(json, 'sfdcReference') ? undefined : json['sfdcReference'],
        'quoteReceiverEmail': !exists(json, 'quoteReceiverEmail') ? undefined : json['quoteReceiverEmail'],
        'accountCustomerCompanyName': !exists(json, 'accountCustomerCompanyName') ? undefined : json['accountCustomerCompanyName'],
        'accountCustomerCompanyStreetAddress': !exists(json, 'accountCustomerCompanyStreetAddress') ? undefined : json['accountCustomerCompanyStreetAddress'],
        'accountCustomerCompanyCity': !exists(json, 'accountCustomerCompanyCity') ? undefined : json['accountCustomerCompanyCity'],
        'accountCustomerCompanyCountry': !exists(json, 'accountCustomerCompanyCountry') ? undefined : json['accountCustomerCompanyCountry'],
        'accountCustomerCompanyCountryCode': !exists(json, 'accountCustomerCompanyCountryCode') ? undefined : json['accountCustomerCompanyCountryCode'],
        'accountCustomerIcvCode': !exists(json, 'accountCustomerIcvCode') ? undefined : json['accountCustomerIcvCode'],
        'endCustomerCompanyName': !exists(json, 'endCustomerCompanyName') ? undefined : json['endCustomerCompanyName'],
        'endCustomerCompanyStreetAddress': !exists(json, 'endCustomerCompanyStreetAddress') ? undefined : json['endCustomerCompanyStreetAddress'],
        'endCustomerCompanyCity': !exists(json, 'endCustomerCompanyCity') ? undefined : json['endCustomerCompanyCity'],
        'endCustomerCompanyCountryCode': !exists(json, 'endCustomerCompanyCountryCode') ? undefined : json['endCustomerCompanyCountryCode'],
        'endCustomerCompanyCountry': !exists(json, 'endCustomerCompanyCountry') ? undefined : json['endCustomerCompanyCountry'],
        'endCustomerCompanyGuid': !exists(json, 'endCustomerCompanyGuid') ? undefined : json['endCustomerCompanyGuid'],
        'termsOfDeliveryCode': !exists(json, 'termsOfDeliveryCode') ? undefined : json['termsOfDeliveryCode'],
        'termsOfDeliveryDescription': !exists(json, 'termsOfDeliveryDescription') ? undefined : json['termsOfDeliveryDescription'],
        'expectedOrderDate': !exists(json, 'expectedOrderDate') ? undefined : (json['expectedOrderDate'] === null ? null : new Date(json['expectedOrderDate'])),
        'tenderValidityDate': !exists(json, 'tenderValidityDate') ? undefined : (json['tenderValidityDate'] === null ? null : new Date(json['tenderValidityDate'])),
        'blanketWaiver': !exists(json, 'blanketWaiver') ? undefined : json['blanketWaiver'],
        'salesChannelId': !exists(json, 'salesChannelId') ? undefined : SalesChannelTypesFromJSON(json['salesChannelId']),
        'pgCode': !exists(json, 'pgCode') ? undefined : json['pgCode'],
        'winningPercentage': !exists(json, 'winningPercentage') ? undefined : json['winningPercentage'],
        'totalSalesPrice': !exists(json, 'totalSalesPrice') ? undefined : json['totalSalesPrice'],
        'revisionItems': ((json['revisionItems'] as Array<any>).map(ReviseQuoteItemDtoFromJSON)),
        'quoteAttachmentsToBeCopied': ((json['quoteAttachmentsToBeCopied'] as Array<any>).map(QuoteAttachmentDtoFromJSON)),
    };
}

export function ReviseQuoteDtoToJSON(value?: ReviseQuoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CreateQuoteItemDtoToJSON)),
        'stateId': QuoteStateTypesToJSON(value.stateId),
        'id': value.id,
        'projectName': value.projectName,
        'sfdcReference': value.sfdcReference,
        'quoteReceiverEmail': value.quoteReceiverEmail,
        'accountCustomerCompanyName': value.accountCustomerCompanyName,
        'accountCustomerCompanyStreetAddress': value.accountCustomerCompanyStreetAddress,
        'accountCustomerCompanyCity': value.accountCustomerCompanyCity,
        'accountCustomerCompanyCountry': value.accountCustomerCompanyCountry,
        'accountCustomerCompanyCountryCode': value.accountCustomerCompanyCountryCode,
        'accountCustomerIcvCode': value.accountCustomerIcvCode,
        'endCustomerCompanyName': value.endCustomerCompanyName,
        'endCustomerCompanyStreetAddress': value.endCustomerCompanyStreetAddress,
        'endCustomerCompanyCity': value.endCustomerCompanyCity,
        'endCustomerCompanyCountryCode': value.endCustomerCompanyCountryCode,
        'endCustomerCompanyCountry': value.endCustomerCompanyCountry,
        'endCustomerCompanyGuid': value.endCustomerCompanyGuid,
        'termsOfDeliveryCode': value.termsOfDeliveryCode,
        'termsOfDeliveryDescription': value.termsOfDeliveryDescription,
        'expectedOrderDate': value.expectedOrderDate === undefined ? undefined : (value.expectedOrderDate === null ? null : value.expectedOrderDate.toISOString()),
        'tenderValidityDate': value.tenderValidityDate === undefined ? undefined : (value.tenderValidityDate === null ? null : value.tenderValidityDate.toISOString()),
        'blanketWaiver': value.blanketWaiver,
        'salesChannelId': SalesChannelTypesToJSON(value.salesChannelId),
        'pgCode': value.pgCode,
        'winningPercentage': value.winningPercentage,
        'totalSalesPrice': value.totalSalesPrice,
        'revisionItems': ((value.revisionItems as Array<any>).map(ReviseQuoteItemDtoToJSON)),
        'quoteAttachmentsToBeCopied': ((value.quoteAttachmentsToBeCopied as Array<any>).map(QuoteAttachmentDtoToJSON)),
    };
}

