/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateQuoteItemDto,
    CreateQuoteItemDtoFromJSON,
    CreateQuoteItemDtoFromJSONTyped,
    CreateQuoteItemDtoToJSON,
} from './CreateQuoteItemDto';

/**
 * 
 * @export
 * @interface CreateQuoteDto
 */
export interface CreateQuoteDto {
    /**
     * 
     * @type {Array<CreateQuoteItemDto>}
     * @memberof CreateQuoteDto
     */
    items: Array<CreateQuoteItemDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateQuoteDto
     */
    matProjectId?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateQuoteDto
     */
    approvedBy?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateQuoteDto
     */
    tenderValidityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteDto
     */
    termsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteDto
     */
    termsOfApproval?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateQuoteDto
     */
    blanketWaiver?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteDto
     */
    bidApprovalReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateQuoteDto
     */
    quoteId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteDto
     */
    sfdcReference?: string | null;
}

export function CreateQuoteDtoFromJSON(json: any): CreateQuoteDto {
    return CreateQuoteDtoFromJSONTyped(json, false);
}

export function CreateQuoteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateQuoteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(CreateQuoteItemDtoFromJSON)),
        'matProjectId': !exists(json, 'matProjectId') ? undefined : json['matProjectId'],
        'approvedBy': !exists(json, 'approvedBy') ? undefined : json['approvedBy'],
        'tenderValidityDate': !exists(json, 'tenderValidityDate') ? undefined : (json['tenderValidityDate'] === null ? null : new Date(json['tenderValidityDate'])),
        'termsOfDeliveryCode': !exists(json, 'termsOfDeliveryCode') ? undefined : json['termsOfDeliveryCode'],
        'termsOfApproval': !exists(json, 'termsOfApproval') ? undefined : json['termsOfApproval'],
        'blanketWaiver': !exists(json, 'blanketWaiver') ? undefined : json['blanketWaiver'],
        'bidApprovalReference': !exists(json, 'bidApprovalReference') ? undefined : json['bidApprovalReference'],
        'quoteId': !exists(json, 'quoteId') ? undefined : json['quoteId'],
        'sfdcReference': !exists(json, 'sfdcReference') ? undefined : json['sfdcReference'],
    };
}

export function CreateQuoteDtoToJSON(value?: CreateQuoteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(CreateQuoteItemDtoToJSON)),
        'matProjectId': value.matProjectId,
        'approvedBy': value.approvedBy,
        'tenderValidityDate': value.tenderValidityDate === undefined ? undefined : (value.tenderValidityDate === null ? null : value.tenderValidityDate.toISOString()),
        'termsOfDeliveryCode': value.termsOfDeliveryCode,
        'termsOfApproval': value.termsOfApproval,
        'blanketWaiver': value.blanketWaiver,
        'bidApprovalReference': value.bidApprovalReference,
        'quoteId': value.quoteId,
        'sfdcReference': value.sfdcReference,
    };
}

