/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaceOrderFromWaitingForApprovalCommand
 */
export interface PlaceOrderFromWaitingForApprovalCommand {
    /**
     * 
     * @type {string}
     * @memberof PlaceOrderFromWaitingForApprovalCommand
     */
    shoppingCartIdentifier?: string;
}

export function PlaceOrderFromWaitingForApprovalCommandFromJSON(json: any): PlaceOrderFromWaitingForApprovalCommand {
    return PlaceOrderFromWaitingForApprovalCommandFromJSONTyped(json, false);
}

export function PlaceOrderFromWaitingForApprovalCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceOrderFromWaitingForApprovalCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartIdentifier': !exists(json, 'shoppingCartIdentifier') ? undefined : json['shoppingCartIdentifier'],
    };
}

export function PlaceOrderFromWaitingForApprovalCommandToJSON(value?: PlaceOrderFromWaitingForApprovalCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartIdentifier': value.shoppingCartIdentifier,
    };
}

