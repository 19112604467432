/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationNotificationVm,
    ApplicationNotificationVmFromJSON,
    ApplicationNotificationVmToJSON,
    ApplicationNotificationsVm,
    ApplicationNotificationsVmFromJSON,
    ApplicationNotificationsVmToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    RemoveApplicationNotificationsCommand,
    RemoveApplicationNotificationsCommandFromJSON,
    RemoveApplicationNotificationsCommandToJSON,
    UpdateApplicationNotificationIsReadStatusCommand,
    UpdateApplicationNotificationIsReadStatusCommandFromJSON,
    UpdateApplicationNotificationIsReadStatusCommandToJSON,
} from '../models';

export interface ApiApplicationNotificationsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaHeader?: string;
    criteriaCompany?: string;
    criteriaFrom?: string;
    criteriaReceivedFrom?: Date;
    criteriaReceivedTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiApplicationNotificationsIdGetRequest {
    id: number;
}

export interface ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest {
    removeApplicationNotificationsCommand?: RemoveApplicationNotificationsCommand;
}

export interface ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest {
    updateApplicationNotificationIsReadStatusCommand?: UpdateApplicationNotificationIsReadStatusCommand;
}

/**
 * 
 */
export class ApplicationNotificationsApi extends runtime.BaseAPI {

    /**
     */
    async apiApplicationNotificationsGetRaw(requestParameters: ApiApplicationNotificationsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApplicationNotificationsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiApplicationNotificationsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiApplicationNotificationsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaHeader !== undefined) {
            queryParameters['Criteria.Header'] = requestParameters.criteriaHeader;
        }

        if (requestParameters.criteriaCompany !== undefined) {
            queryParameters['Criteria.Company'] = requestParameters.criteriaCompany;
        }

        if (requestParameters.criteriaFrom !== undefined) {
            queryParameters['Criteria.From'] = requestParameters.criteriaFrom;
        }

        if (requestParameters.criteriaReceivedFrom !== undefined) {
            queryParameters['Criteria.ReceivedFrom'] = (requestParameters.criteriaReceivedFrom as any).toISOString();
        }

        if (requestParameters.criteriaReceivedTo !== undefined) {
            queryParameters['Criteria.ReceivedTo'] = (requestParameters.criteriaReceivedTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ApplicationNotifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationNotificationsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationNotificationsGet(requestParameters: ApiApplicationNotificationsGetRequest, initOverrides?: RequestInit): Promise<ApplicationNotificationsVm> {
        const response = await this.apiApplicationNotificationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiApplicationNotificationsIdGetRaw(requestParameters: ApiApplicationNotificationsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApplicationNotificationVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiApplicationNotificationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ApplicationNotifications/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationNotificationVmFromJSON(jsonValue));
    }

    /**
     */
    async apiApplicationNotificationsIdGet(requestParameters: ApiApplicationNotificationsIdGetRequest, initOverrides?: RequestInit): Promise<ApplicationNotificationVm> {
        const response = await this.apiApplicationNotificationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiApplicationNotificationsRemoveApplicationNotificationsDeleteRaw(requestParameters: ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ApplicationNotifications/RemoveApplicationNotifications`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveApplicationNotificationsCommandToJSON(requestParameters.removeApplicationNotificationsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiApplicationNotificationsRemoveApplicationNotificationsDelete(requestParameters: ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiApplicationNotificationsRemoveApplicationNotificationsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiApplicationNotificationsUpdateNotificationsIsReadStatusPostRaw(requestParameters: ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ApplicationNotifications/UpdateNotificationsIsReadStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateApplicationNotificationIsReadStatusCommandToJSON(requestParameters.updateApplicationNotificationIsReadStatusCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiApplicationNotificationsUpdateNotificationsIsReadStatusPost(requestParameters: ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiApplicationNotificationsUpdateNotificationsIsReadStatusPostRaw(requestParameters, initOverrides);
    }

}
