import { RequestState, createInitialRequest } from "framework/state/requestState";
import {
  ApiTrainingArticlePutRequest,
  ApiTrainingArticlesFreeAndAssignedGetRequest,
  ApiTrainingArticlesGetRequest,
  ApiTrainingNodesWithPathGetRequest,
  ApiTrainingUpdateArticleNodePutRequest,
  ArticleDto,
  FreeAndAssignedArticleDto,
  NodeWithPath
} from "api";
import { AppState } from "../../../../setup/appRootReducer";
import { produce } from "immer";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestStatus } from "framework/state/requestStatus";

import { manageTerminologyBankSagas } from "../sagas/manageTerminologyBankSagas";
import {
  ManageTerminologyBankAction,
  TerminologyBankActionType
} from "../actions/manageTerminologyBankActions";

interface Requests {
  getNodesWithPath: RequestState<NodeWithPath, ApiTrainingNodesWithPathGetRequest>;
  getArticlesFreeAndAssigned: RequestState<
    FreeAndAssignedArticleDto,
    ApiTrainingArticlesFreeAndAssignedGetRequest
  >;
  getArticles: RequestState<Array<ArticleDto>, ApiTrainingArticlesGetRequest>;
  updateArticleNode: RequestState<void, ApiTrainingUpdateArticleNodePutRequest>;
  updateArticle: RequestState<ArticleDto, ApiTrainingArticlePutRequest>;
  publish: RequestState<void, void>;
  retrieveArticles: RequestState<void, void>;
}

export interface ManageTerminologyBankState {
  requests: Requests;
  nodes: NodeWithPath[];
  nodeSelectedId: string | undefined;
  nodeSelectedName: string | undefined;
  freeArticles: ArticleDto[];
  assignedArticles: ArticleDto[];
  loadingArticles: boolean;
  articlesNode: ArticleDto[];
}
const defaultState: ManageTerminologyBankState = {
  requests: {
    getNodesWithPath: createInitialRequest(),
    getArticlesFreeAndAssigned: createInitialRequest(),
    getArticles: createInitialRequest(),
    updateArticleNode: createInitialRequest(),
    updateArticle: createInitialRequest(),
    publish: createInitialRequest(),
    retrieveArticles: createInitialRequest()
  },
  nodes: [],
  nodeSelectedId: undefined,
  nodeSelectedName: undefined,
  freeArticles: [],
  assignedArticles: [],
  loadingArticles: false,
  articlesNode: []
};

export function manageTerminologyBankReducer(
  state: ManageTerminologyBankState = defaultState,
  action: ManageTerminologyBankAction
): ManageTerminologyBankState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageTerminologyBankSagas.getNodesWithPath.actionTypes,
      key: "getNodesWithPath"
    },
    {
      actionTypes: manageTerminologyBankSagas.getArticlesFreeAndAssigned.actionTypes,
      key: "getArticlesFreeAndAssigned"
    },
    {
      actionTypes: manageTerminologyBankSagas.updateArticle.actionTypes,
      key: "updateArticle"
    },
    {
      actionTypes: manageTerminologyBankSagas.getArticles.actionTypes,
      key: "getArticles"
    },
    {
      actionTypes: manageTerminologyBankSagas.publish.actionTypes,
      key: "publish"
    },
    {
      actionTypes: manageTerminologyBankSagas.retrieveArticles.actionTypes,
      key: "retrieveArticles"
    }
  ]);
  if (manageTerminologyBankSagas.getNodesWithPath.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload;
      const nodeSelected = action.payload
        .filter((x) => x.parentId == null)
        .reduce((prev, curr) => (prev.order < curr.order ? prev : curr));
      draft.nodeSelectedName = nodeSelected.name;
      draft.nodeSelectedId = nodeSelected.id;
    });

  if (manageTerminologyBankSagas.getArticlesFreeAndAssigned.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.assignedArticles = action.payload.assignedArticles ?? [];
      draft.freeArticles = action.payload.freeArticles ?? [];
      draft.loadingArticles = false;
    });

  if (manageTerminologyBankSagas.getArticles.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.articlesNode = action.payload;
    });

  switch (action.type) {
    case TerminologyBankActionType.SetNodeSelected:
      state = produce(state, (draft) => {
        draft.nodeSelectedName = action.node?.name;
        draft.nodeSelectedId = action.node?.id;
      });
      break;
    case TerminologyBankActionType.SetNodeSelectedId:
      state = produce(state, (draft) => {
        const node = draft.nodes.find((x) => x.id == action.guid);
        draft.nodeSelectedName = node?.name;
        draft.nodeSelectedId = node?.id;
      });
      break;
    case TerminologyBankActionType.LoadSelectedFreeArticles:
      state = produce(state, (draft) => {
        draft.loadingArticles = true;
        draft.assignedArticles = [];
        draft.freeArticles = [];
      });
      break;
  }
  return state;
}

export const getNodesWithPathStatus = (state: AppState): RequestStatus =>
  state.manageTerminologyBankState.requests.getNodesWithPath.status;
export const getArticlesFreeAndAssignedRequestStatus = (state: AppState): RequestStatus =>
  state.manageTerminologyBankState.requests.getArticlesFreeAndAssigned.status;
export const getArticleNodeStatus = (state: AppState): RequestStatus =>
  state.manageTerminologyBankState.requests.getArticles.status;
export const getUpdateArticleStatus = (state: AppState): RequestStatus =>
  state.manageTerminologyBankState.requests.updateArticle.status;
export const getPublishStatus = (state: AppState): RequestStatus =>
  state.manageTerminologyBankState.requests.publish.status;
export const getNodesWithPath = (state: AppState): NodeWithPath[] =>
  state.manageTerminologyBankState.nodes;
export const getNodeSelectedId = (state: AppState): string | undefined =>
  state.manageTerminologyBankState.nodeSelectedId;
export const getNodeSelectedName = (state: AppState): string | undefined =>
  state.manageTerminologyBankState.nodeSelectedName;
export const getFreeArticles = (state: AppState): ArticleDto[] =>
  state.manageTerminologyBankState.freeArticles;
export const getAssignedArticles = (state: AppState): ArticleDto[] =>
  state.manageTerminologyBankState.assignedArticles;
export const getArticlesFreeAndAssignedLoading = (state: AppState): boolean =>
  state.manageTerminologyBankState.loadingArticles;
export const getArticleNode = (state: AppState): ArticleDto[] =>
  state.manageTerminologyBankState.articlesNode;
export const getRetrieveArticlesStatus = (state: AppState) =>
  state.manageTerminologyBankState.requests.retrieveArticles.status;
