/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleDtoFormatted,
    ArticleDtoFormattedFromJSON,
    ArticleDtoFormattedFromJSONTyped,
    ArticleDtoFormattedToJSON,
} from './ArticleDtoFormatted';

/**
 * 
 * @export
 * @interface SearchResponseDto
 */
export interface SearchResponseDto {
    /**
     * 
     * @type {Array<ArticleDtoFormatted>}
     * @memberof SearchResponseDto
     */
    articles?: Array<ArticleDtoFormatted> | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SearchResponseDto
     */
    facetDistribution?: { [key: string]: number; } | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResponseDto
     */
    estimatedTotalHits?: number;
}

export function SearchResponseDtoFromJSON(json: any): SearchResponseDto {
    return SearchResponseDtoFromJSONTyped(json, false);
}

export function SearchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articles': !exists(json, 'articles') ? undefined : (json['articles'] === null ? null : (json['articles'] as Array<any>).map(ArticleDtoFormattedFromJSON)),
        'facetDistribution': !exists(json, 'facetDistribution') ? undefined : json['facetDistribution'],
        'estimatedTotalHits': !exists(json, 'estimatedTotalHits') ? undefined : json['estimatedTotalHits'],
    };
}

export function SearchResponseDtoToJSON(value?: SearchResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articles': value.articles === undefined ? undefined : (value.articles === null ? null : (value.articles as Array<any>).map(ArticleDtoFormattedToJSON)),
        'facetDistribution': value.facetDistribution,
        'estimatedTotalHits': value.estimatedTotalHits,
    };
}

