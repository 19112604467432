/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SoftwareSubscriptionsDto
 */
export interface SoftwareSubscriptionsDto {
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    orderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    customerSoftwareUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    vendorOrderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareSubscriptionsDto
     */
    validityBeginDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareSubscriptionsDto
     */
    validityEndDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SoftwareSubscriptionsDto
     */
    renewalPeriodStart?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    guid: string;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareSubscriptionsDto
     */
    canRenew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SoftwareSubscriptionsDto
     */
    closingRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    statusLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SoftwareSubscriptionsDto
     */
    shoppingCartIdentifier?: string | null;
}

export function SoftwareSubscriptionsDtoFromJSON(json: any): SoftwareSubscriptionsDto {
    return SoftwareSubscriptionsDtoFromJSONTyped(json, false);
}

export function SoftwareSubscriptionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareSubscriptionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderingCode': !exists(json, 'orderingCode') ? undefined : json['orderingCode'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'customerSoftwareUserName': !exists(json, 'customerSoftwareUserName') ? undefined : json['customerSoftwareUserName'],
        'vendorOrderingCode': !exists(json, 'vendorOrderingCode') ? undefined : json['vendorOrderingCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'validityBeginDate': (new Date(json['validityBeginDate'])),
        'validityEndDate': (new Date(json['validityEndDate'])),
        'renewalPeriodStart': !exists(json, 'renewalPeriodStart') ? undefined : (json['renewalPeriodStart'] === null ? null : new Date(json['renewalPeriodStart'])),
        'guid': json['guid'],
        'canRenew': !exists(json, 'canRenew') ? undefined : json['canRenew'],
        'closingRequired': !exists(json, 'closingRequired') ? undefined : json['closingRequired'],
        'statusLabel': !exists(json, 'statusLabel') ? undefined : json['statusLabel'],
        'shoppingCartIdentifier': !exists(json, 'shoppingCartIdentifier') ? undefined : json['shoppingCartIdentifier'],
    };
}

export function SoftwareSubscriptionsDtoToJSON(value?: SoftwareSubscriptionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderingCode': value.orderingCode,
        'compositionCode': value.compositionCode,
        'customerSoftwareUserName': value.customerSoftwareUserName,
        'vendorOrderingCode': value.vendorOrderingCode,
        'description': value.description,
        'validityBeginDate': (value.validityBeginDate.toISOString()),
        'validityEndDate': (value.validityEndDate.toISOString()),
        'renewalPeriodStart': value.renewalPeriodStart === undefined ? undefined : (value.renewalPeriodStart === null ? null : value.renewalPeriodStart.toISOString()),
        'guid': value.guid,
        'canRenew': value.canRenew,
        'closingRequired': value.closingRequired,
        'statusLabel': value.statusLabel,
        'shoppingCartIdentifier': value.shoppingCartIdentifier,
    };
}

