/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlantDto,
    PlantDtoFromJSON,
    PlantDtoFromJSONTyped,
    PlantDtoToJSON,
} from './PlantDto';

/**
 * 
 * @export
 * @interface OrderMetaDataVm
 */
export interface OrderMetaDataVm {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderMetaDataVm
     */
    countryCodes: Array<string>;
    /**
     * 
     * @type {Array<PlantDto>}
     * @memberof OrderMetaDataVm
     */
    plants: Array<PlantDto>;
}

export function OrderMetaDataVmFromJSON(json: any): OrderMetaDataVm {
    return OrderMetaDataVmFromJSONTyped(json, false);
}

export function OrderMetaDataVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMetaDataVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCodes': json['countryCodes'],
        'plants': ((json['plants'] as Array<any>).map(PlantDtoFromJSON)),
    };
}

export function OrderMetaDataVmToJSON(value?: OrderMetaDataVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCodes': value.countryCodes,
        'plants': ((value.plants as Array<any>).map(PlantDtoToJSON)),
    };
}

