import {
  Column,
  ProcessRowGroupForExportParams,
  CsvExportParams,
  ExcelRow,
  ExcelExportParams,
  ExcelStyle,
  ExcelCell,
  GridApi,
  ColumnApi,
  ColDef
} from "ag-grid-community";
import moment from "moment";
import { DefaultTheme } from "styled-components";
import {
  GridToStringOptions,
  getHeaders,
  getRowValues,
  getVisibleColumns,
  gridToString
} from "./gridUtils";
import { clamp } from "lodash-es";
import { handleBlobFileDownload } from "./fileDownload";
import { Dispatch } from "redux";
import { TFunction } from "i18next";
import { ColumnDefinitionsDto, NotificationSeverity } from "api";
import { userActions } from "applications/common/actions/userActions";

type TExportCriteria<T> = Omit<T, "columnDefinitionsDto">;

/**
 * Transforms the criteria from a get request to a post request for exporting.
 * @param criteria Criteria for determining the data to export.
 * @param gridApi Grid API of the grid to export.
 * @returns Criteria used for the export, including the column definitions.
 */
const prepareExportCriteria = <T>(criteria: TExportCriteria<T>, gridApi: GridApi) => {
  return {
    ...criteria,
    criteriaPageSize: 2 ** 31 - 1, // Max value for signed 32 bit integer
    criteriaPage: 0,
    columnDefinitionsDto: {
      columnDefinitions: (gridApi?.getColumnDefs() as ColDef[])
        .filter((x) => x.field !== null && x.field !== undefined)
        .filter((x) => x.hide !== true)
    } as ColumnDefinitionsDto
  } as unknown as T;
};

/**
 * Exports a server side grid to CSV.
 * @param criteria Criteria for determining the data to export, excluding the column definitions.
 * @param requestCallback Callback to request the data from the server. Should be bound to the API registry.
 * @param gridApi Grid API of the grid to export.
 */
export const exportServerSideCsv = async <T>(
  criteria: TExportCriteria<T>,
  requestCallback: (params: T) => Promise<Blob>,
  gridApi: GridApi,
  dispatch?: Dispatch,
  t?: TFunction
) => {
  gridApi?.showLoadingOverlay();
  try {
    const blob = await requestCallback(prepareExportCriteria(criteria, gridApi));
    handleBlobFileDownload(blob, "export.csv");
    gridApi?.hideOverlay();
  } catch (error) {
    gridApi?.hideOverlay();
    if (dispatch && t) {
      dispatch(userActions.addNotification(NotificationSeverity.Error, t("Failed to export")));
    }
  }
};

/**
 * CSV Export params. Adds all visible detail grids to export.
 * @param fileName File name
 * @param addDateToFileName Adds the date at the end of the file
 * @param masterGridColumnKeys Column keys of the master grid to export, leave undefined to use all columns
 * @returns
 */
export const getCsvExportParams = (
  fileName: string,
  addDateToFileName = false,
  masterGridColumnKeys: string[] | Column[] | undefined = undefined
) => {
  const now = moment();
  return {
    getCustomContentBelowRow: (params: ProcessRowGroupForExportParams) =>
      gridToString({
        gridApi: params.api,
        columnApi: params.columnApi,
        colSeparator: ",",
        childIndent: ",",
        masterNode: params.node,
        includeMasterRow: false,
        includeMasterHeaders: false,
        ignoreMasterGridColumnIfHeaderUndefined: true,
        valueWrapper: '"'
      } as GridToStringOptions),
    fileName: addDateToFileName
      ? `${fileName}_${now.date()}_${now.month() + 1}_${now.year()}.csv`
      : fileName + ".csv",
    columnKeys: masterGridColumnKeys
  } as CsvExportParams;
};

/**
 * Excel export params. Adds all visible detail grids to export.
 * @param fileName File name
 * @param sheetName Sheet name, leave undefined to use fileName
 * @param addDateToFileName Adds the date at the end of the file
 * @param masterGridColumnKeys Column keys of the master grid to export, leave undefined to use all columns
 * @returns
 */
export const getExcelExportParams = (
  fileName: string,
  sheetName?: string,
  addDateToFileName = false,
  masterGridColumnKeys: string[] | Column[] | undefined = undefined
) => {
  const now = moment();
  return {
    getCustomContentBelowRow: (params: ProcessRowGroupForExportParams) =>
      getDetailGridAsExcelRows(params) as ExcelRow[],
    fileName: addDateToFileName
      ? `${fileName}_${now.date()}_${now.month() + 1}_${now.year()}.xlsx`
      : fileName + ".xlsx",
    sheetName: sheetName ?? fileName,
    columnKeys: masterGridColumnKeys
  } as ExcelExportParams;
};

export const getExcelStyles = (theme: DefaultTheme): ExcelStyle[] => {
  return [
    {
      id: "header",
      interior: {
        color: theme.colors.grey40,
        pattern: "Solid"
      }
    },
    {
      id: "header1",
      interior: {
        color: theme.colors.grey20,
        pattern: "Solid"
      }
    },
    {
      id: "header2",
      interior: {
        color: theme.colors.grey10,
        pattern: "Solid"
      }
    },
    {
      id: "body"
    },
    {
      // Default, if no other id is defined
      id: "cell"
    }
  ];
};

const getDetailGridAsExcelRows = (params: ProcessRowGroupForExportParams) => {
  const getExcellCell: (text: string, styleId?: string) => ExcelCell = (
    text: string,
    styleId?: string
  ) => {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(text) ? "Number" : "String",
        value: String(text)
      }
    };
  };

  const getExcelRowFromValues = (items: string[], childDepth: number, styleId: string) => {
    return {
      outlineLevel: 1,
      cells: Array(childDepth)
        .fill(getExcellCell(""))
        .concat(items.map((value) => getExcellCell(value, styleId)))
    } as ExcelRow;
  };

  const iterate = (gridApi: GridApi, columnApi: ColumnApi, childDepth = 0) => {
    const columns = getVisibleColumns(columnApi);
    const headers = getHeaders(gridApi, columns);
    if (!headers.every((header) => header === ""))
      excelRows.push(
        getExcelRowFromValues(headers, childDepth, `header${clamp(childDepth, 0, 2)}`)
      );

    gridApi.forEachNode((rowNode) => {
      const rowValues = getRowValues(rowNode, columns, gridApi);
      if (!rowValues.every((value) => value === "")) {
        excelRows.push(getExcelRowFromValues(rowValues, childDepth, "body"));
      }
      if (rowNode.master && rowNode.expanded) {
        const detailGrid = gridApi.getDetailGridInfo("detail_" + rowNode.id);
        if (detailGrid && detailGrid.api && detailGrid.columnApi) {
          iterate(detailGrid.api, detailGrid.columnApi, childDepth + 1);
        }
      }
    });
  };

  const excelRows: ExcelRow[] = [];
  const detailGrid = params.api.getDetailGridInfo("detail_" + params.node.id);
  if (detailGrid && detailGrid.api && detailGrid.columnApi) {
    iterate(detailGrid.api, detailGrid.columnApi, 1);
  }
  return excelRows;
};
