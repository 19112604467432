/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderingCodeSerialNumber,
    OrderingCodeSerialNumberFromJSON,
    OrderingCodeSerialNumberFromJSONTyped,
    OrderingCodeSerialNumberToJSON,
} from './OrderingCodeSerialNumber';

/**
 * 
 * @export
 * @interface FirmwareDetailsDto
 */
export interface FirmwareDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    hashAlgorithm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    hashValue?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FirmwareDetailsDto
     */
    firmwareId?: number;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    productType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FirmwareDetailsDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmwareDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FirmwareDetailsDto
     */
    serialNumbers?: Array<string> | null;
    /**
     * 
     * @type {Array<OrderingCodeSerialNumber>}
     * @memberof FirmwareDetailsDto
     */
    orderingCodesWithSerialNumbers?: Array<OrderingCodeSerialNumber> | null;
}

export function FirmwareDetailsDtoFromJSON(json: any): FirmwareDetailsDto {
    return FirmwareDetailsDtoFromJSONTyped(json, false);
}

export function FirmwareDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmwareDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hashAlgorithm': !exists(json, 'hashAlgorithm') ? undefined : json['hashAlgorithm'],
        'hashValue': !exists(json, 'hashValue') ? undefined : json['hashValue'],
        'firmwareId': !exists(json, 'firmwareId') ? undefined : json['firmwareId'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
        'orderingCodesWithSerialNumbers': !exists(json, 'orderingCodesWithSerialNumbers') ? undefined : (json['orderingCodesWithSerialNumbers'] === null ? null : (json['orderingCodesWithSerialNumbers'] as Array<any>).map(OrderingCodeSerialNumberFromJSON)),
    };
}

export function FirmwareDetailsDtoToJSON(value?: FirmwareDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hashAlgorithm': value.hashAlgorithm,
        'hashValue': value.hashValue,
        'firmwareId': value.firmwareId,
        'productType': value.productType,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'description': value.description,
        'fileName': value.fileName,
        'serialNumbers': value.serialNumbers,
        'orderingCodesWithSerialNumbers': value.orderingCodesWithSerialNumbers === undefined ? undefined : (value.orderingCodesWithSerialNumbers === null ? null : (value.orderingCodesWithSerialNumbers as Array<any>).map(OrderingCodeSerialNumberToJSON)),
    };
}

