/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceProductDto,
    PriceProductDtoFromJSON,
    PriceProductDtoFromJSONTyped,
    PriceProductDtoToJSON,
} from './PriceProductDto';

/**
 * 
 * @export
 * @interface PriceProductVm
 */
export interface PriceProductVm {
    /**
     * 
     * @type {Array<PriceProductDto>}
     * @memberof PriceProductVm
     */
    products: Array<PriceProductDto>;
    /**
     * 
     * @type {number}
     * @memberof PriceProductVm
     */
    priceListId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductVm
     */
    priceListCurrency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductVm
     */
    directMaterialCostPriceListCurrency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductVm
     */
    priceListName?: string | null;
}

export function PriceProductVmFromJSON(json: any): PriceProductVm {
    return PriceProductVmFromJSONTyped(json, false);
}

export function PriceProductVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceProductVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(PriceProductDtoFromJSON)),
        'priceListId': !exists(json, 'priceListId') ? undefined : json['priceListId'],
        'priceListCurrency': !exists(json, 'priceListCurrency') ? undefined : json['priceListCurrency'],
        'directMaterialCostPriceListCurrency': !exists(json, 'directMaterialCostPriceListCurrency') ? undefined : json['directMaterialCostPriceListCurrency'],
        'priceListName': !exists(json, 'priceListName') ? undefined : json['priceListName'],
    };
}

export function PriceProductVmToJSON(value?: PriceProductVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(PriceProductDtoToJSON)),
        'priceListId': value.priceListId,
        'priceListCurrency': value.priceListCurrency,
        'directMaterialCostPriceListCurrency': value.directMaterialCostPriceListCurrency,
        'priceListName': value.priceListName,
    };
}

