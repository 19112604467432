import { defaultSagaSuccessHandler, SagaBuilder } from "../../../framework/sagas/sagaUtilities";
import { createGenericSaga, defaultSagaErrorHandler } from "../../../framework/sagas/sagaUtilities";
import {
  getAdToken,
  getMsalClient,
  GetTokenParams
} from "../../../framework/components/auth/msalClient";
import { getApiRegistry } from "../../../framework/state/apiRegistry";
import { deliveriesHomeSagas } from "../../deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { userActions } from "../actions/userActions";
import { companySearchDefaultCriteria } from "../../deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import { canSeeAllCompaniesInCompanyDropdown, isAuthorizedToComponent } from "utilities/authUtils";
import { getUserAccessRights } from "../reducers/userReducer";
import { select, put } from "redux-saga/effects";
import { NotificationSeverity } from "api";
import { manageUsersSagas } from "applications/manage/manageUsers/sagas/manageUsersSagas";

export const userSagas = {
  getToken: createGenericSaga("userSagas/getToken", getAdToken, {
    takeEvery: false,
    onFail: (requestPayload: GetTokenParams) => {
      // If defined in the payload, redirect to the login page to fetch the token.
      // Doing it like this, you should not reach the lines after that and the app is reloaded after the redirecting process.
      if (requestPayload.redirectToLogInPageWhenRefreshFails) {
        const msalClient = getMsalClient(requestPayload.authSettings);
        if (msalClient) {
          msalClient.acquireTokenRedirect({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scopes: [requestPayload.authSettings.resourceScope, "offline_access"]
          });
        }
      }
      return new SagaBuilder().dispatch(userActions.changeIsLoggingIn(false)).build()();
    }
  }),
  getUserInformation: createGenericSaga(
    "userSagas/getUserInformation",
    getApiRegistry().usersApi.apiUsersCurrentGet.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: (_request, response) => {
        if (response.privacyConsentNeeded) {
          return new SagaBuilder().dispatch(userActions.changeIsLoggingIn(false)).build()();
        } else {
          return new SagaBuilder()
            .dispatch(
              deliveriesHomeSagas.getCompanies.createAction({
                ...companySearchDefaultCriteria,
                criteriaGetOnlyVisible: !isAuthorizedToComponent(
                  response.userAccessRights,
                  canSeeAllCompaniesInCompanyDropdown
                )
              })
            )
            .dispatch(userActions.changeIsLoggingIn(false))
            .build()();
        }
      },
      onFail: () => {
        return new SagaBuilder().dispatch(userActions.changeIsLoggingIn(false)).build()();
      }
    }
  ),
  updateUserInformation: createGenericSaga(
    "userSagas/updateUserInformation",
    getApiRegistry().usersApi.apiUsersPut.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Editing success"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateUserSelectedCompany: createGenericSaga(
    "userSagas/updateUserSelectedCompany",
    getApiRegistry().usersApi.apiUsersSelectedCompanyPatch.bind(getApiRegistry().usersApi),
    {
      takeEvery: false
    }
  ),
  updateUserHomeCompany: createGenericSaga(
    "userSagas/updateUserHomeCompany",
    getApiRegistry().usersApi.apiUsersHomeCompanyPatch.bind(getApiRegistry().usersApi),
    {
      onSuccess: defaultSagaSuccessHandler("Home company changed successfully"),
      takeEvery: false
    }
  ),
  getUsers: createGenericSaga(
    "userSagas/getUsers",
    getApiRegistry().usersApi.apiUsersGet.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteUser: createGenericSaga(
    "userSagas/deleteUser",
    getApiRegistry().usersApi.apiUsersIdDelete.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("User deleted successfully"),
      onFail: (requestPayload) => defaultSagaErrorHandler(requestPayload.id, "Deleting user failed")
    }
  ),
  updateUserCompanyVisible: createGenericSaga(
    "userSagas/updateUserCompanyVisible",
    getApiRegistry().usersApi.apiUsersCompanyUserVisiblePatch.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: () => {
        return new SagaBuilder()
          .generator(refreshCompanies)
          .dispatch(
            userActions.addNotification(
              NotificationSeverity.Success,
              "Updated visibility successfully"
            )
          )
          .build()();
      },
      onFail: () => defaultSagaErrorHandler("Update failed")
    }
  ),
  updateUserCompanySubscribedToOrderNotifications: createGenericSaga(
    "userSagas/updateUserCompanySubscribedToOrderNotifications",
    getApiRegistry().usersApi.apiUsersCompanyUserSubscribedToOrderNotificationsPatch.bind(
      getApiRegistry().usersApi
    ),
    {
      takeEvery: false,
      onSuccess: (requestPayload, responsePayload) => {
        return defaultSagaSuccessHandler(
          responsePayload?.subscribedToOrderNotifications
            ? "Subscribed to order notifications"
            : "Unsubscribed from order notifications"
        )();
      },
      onFail: () => defaultSagaErrorHandler("Subscribing to order notifications failed")
    }
  ),
  updateShowBanner: createGenericSaga(
    "userSagas/updateShowBanner",
    getApiRegistry().usersApi.apiUsersShowBannerPatch.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  setUserCountryContactRoles: createGenericSaga(
    "userSagas/setUserCountryContactRoles",
    getApiRegistry().usersApi.apiUsersUpdateUserCountryContactRolesPut.bind(
      getApiRegistry().usersApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      // If user added ASM role, then they will receive the M2 role, so we need to fetch that.
      onSuccess: (requestPayload) =>
        requestPayload.updateUserCountryContactRolesCommand?.userId
          ? new SagaBuilder()
              .dispatch(
                manageUsersSagas.getUserById.createAction({
                  id: requestPayload.updateUserCountryContactRolesCommand.userId
                })
              )
              .build()()
          : defaultSagaSuccessHandler("Updated user country roles")()
    }
  )
};

function* refreshCompanies(): Generator<any> {
  const accessRights: any = yield select(getUserAccessRights);
  const getOnlyVisible = !isAuthorizedToComponent(
    accessRights,
    canSeeAllCompaniesInCompanyDropdown
  );
  yield put(
    deliveriesHomeSagas.getCompanies.createAction({
      ...companySearchDefaultCriteria,
      criteriaGetOnlyVisible: getOnlyVisible
    })
  );
}
