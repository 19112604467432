import React, { memo } from "react";
import styled from "styled-components";

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.sizes.s};
  display: flex;
`;

const CodeContainer = styled.div`
  margin-block: ${(props) => props.theme.sizes.s};
`;

export interface AddressInfoProps {
  name?: string | null;
  department?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  postalCode?: string | number | null;
  country?: string | null;
  state?: string | null;
  contactPerson?: string | null;
  contactPersonEmail?: string | null;
  contactPersonTelephone?: string | null;
  code?: string | null;
  pgCode?: string | null;
}

export const AddressInfo = memo(
  ({
    name,
    streetAddress,
    city,
    postalCode,
    country,
    state,
    contactPerson,
    contactPersonEmail,
    contactPersonTelephone,
    department,
    code,
    pgCode
  }: AddressInfoProps): JSX.Element => {
    return (
      <Container>
        <div>
          <Text>{name ?? ""}</Text>
          <Text>{department ?? ""}</Text>
          <Text>{streetAddress ?? ""}</Text>
          <Text>
            {(city ? city : "") +
              (state ? ", " + state : "") +
              (postalCode ? ", " + postalCode : "")}
          </Text>
          <Text>{country}</Text>
          {code || pgCode ? (
            <CodeContainer>
              <Text>{code}</Text>
              <Text>{pgCode}</Text>
            </CodeContainer>
          ) : null}
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Text>{contactPerson}</Text>
          <Text>{contactPersonEmail}</Text>
          <Text>{contactPersonTelephone}</Text>
        </div>
      </Container>
    );
  }
);

AddressInfo.displayName = "AddressInfo";
