/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleGroups {
    Admin = 'Admin',
    Common = 'Common',
    DeliverIt = 'DeliverIT',
    Configurator = 'Configurator',
    RelaysOnline = 'RelaysOnline',
    MarginAnalysisTool = 'MarginAnalysisTool',
    Supportline = 'Supportline',
    Aftersales = 'Aftersales',
    VirtualTeam = 'VirtualTeam'
}

export function RoleGroupsFromJSON(json: any): RoleGroups {
    return RoleGroupsFromJSONTyped(json, false);
}

export function RoleGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleGroups {
    return json as RoleGroups;
}

export function RoleGroupsToJSON(value?: RoleGroups | null): any {
    return value as any;
}

