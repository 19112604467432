/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShoppingCartAttachmentTypes,
    ShoppingCartAttachmentTypesFromJSON,
    ShoppingCartAttachmentTypesFromJSONTyped,
    ShoppingCartAttachmentTypesToJSON,
} from './ShoppingCartAttachmentTypes';

/**
 * 
 * @export
 * @interface AttachmentCreatedDto
 */
export interface AttachmentCreatedDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentCreatedDto
     */
    identifier: string;
    /**
     * 
     * @type {ShoppingCartAttachmentTypes}
     * @memberof AttachmentCreatedDto
     */
    shoppingCartAttachmentTypeId: ShoppingCartAttachmentTypes;
    /**
     * 
     * @type {string}
     * @memberof AttachmentCreatedDto
     */
    name: string;
}

export function AttachmentCreatedDtoFromJSON(json: any): AttachmentCreatedDto {
    return AttachmentCreatedDtoFromJSONTyped(json, false);
}

export function AttachmentCreatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentCreatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': json['identifier'],
        'shoppingCartAttachmentTypeId': ShoppingCartAttachmentTypesFromJSON(json['shoppingCartAttachmentTypeId']),
        'name': json['name'],
    };
}

export function AttachmentCreatedDtoToJSON(value?: AttachmentCreatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'shoppingCartAttachmentTypeId': ShoppingCartAttachmentTypesToJSON(value.shoppingCartAttachmentTypeId),
        'name': value.name,
    };
}

