import { FormikProps } from "formik";
import { useEffect } from "react";

interface FormikListenerProps<T> {
  formik: FormikProps<T>;
  callback: (formik: FormikProps<T>) => void;
}

export const FormikListener = <T,>({ formik, callback }: FormikListenerProps<T>): null => {
  useEffect(() => {
    callback(formik);
  }, [callback, formik]);

  return null;
};
