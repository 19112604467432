import { NodeWithPath } from "api";
import { Action } from "redux";

export enum TerminologyBankActionType {
  SetNodeSelected = "TerminologyBankActionType/SetNodeSelected",
  SetNodeSelectedId = "TerminologyBankActionType/SetNodeSelectedId",
  LoadSelectedFreeArticles = "TerminologyBankActionType/LoadSelectedFreeArticles"
}

export interface SetNodeSelected extends Action {
  type: TerminologyBankActionType.SetNodeSelected;
  node: NodeWithPath | undefined;
}
export interface LoadSelectedFreeArticles extends Action {
  type: TerminologyBankActionType.LoadSelectedFreeArticles;
  loading: boolean;
}
export interface SetNodeSelectedId extends Action {
  type: TerminologyBankActionType.SetNodeSelectedId;
  guid: string;
}
export const ManageTerminologyBankActions = {
  SetNodeSelected: (node: NodeWithPath | undefined): SetNodeSelected => ({
    type: TerminologyBankActionType.SetNodeSelected,
    node
  }),
  SetNodeSelectedId: (guid: string): SetNodeSelectedId => ({
    type: TerminologyBankActionType.SetNodeSelectedId,
    guid
  }),
  LoadSelectedFreeArticles: (loading: boolean): LoadSelectedFreeArticles => ({
    type: TerminologyBankActionType.LoadSelectedFreeArticles,
    loading
  })
};

export type ManageTerminologyBankAction =
  | SetNodeSelected
  | LoadSelectedFreeArticles
  | SetNodeSelectedId;
