import { createGenericSaga, defaultSagaErrorHandler } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const applicationNotificationSagas = {
  updateNotificationsIsReadStatus: createGenericSaga(
    "applicationNotificationSagas/updateNotificationsIsReadStatus",
    getApiRegistry().applicationNotificationsApi.apiApplicationNotificationsUpdateNotificationsIsReadStatusPost.bind(
      getApiRegistry().applicationNotificationsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  removeApplicationNotifications: createGenericSaga(
    "applicationNotificationSagas/removeApplicationNotifications",
    getApiRegistry().applicationNotificationsApi.apiApplicationNotificationsRemoveApplicationNotificationsDelete.bind(
      getApiRegistry().applicationNotificationsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getApplicationNotificationById: createGenericSaga(
    "applicationNotificationSagas/getApplicationNotificationById",
    getApiRegistry().applicationNotificationsApi.apiApplicationNotificationsIdGet.bind(
      getApiRegistry().applicationNotificationsApi
    )
  )
};
