import React, { useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { ContentContainer } from "applications/shoppingCart/components/orderDetails/ContentContainer";
import { ResizableInput } from "framework/components/styled/ResizableInput";
import { Button } from "framework/components/Button";
import { AccessRights, QuoteBaseDto, QuoteStateTypes } from "api";
import { Icon } from "@abb/abb-common-ux-react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import {
  getGetQuoteByIdRequestStatus,
  getSaveMessageToRequestorStatus
} from "applications/common/reducers/shoppingCartReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { hasAnyAccessRight } from "utilities/authUtils";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: auto;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.s};
  margin-bottom: ${(props) => props.theme.sizes.xxs};
`;

const Container = styled.div``;

const MessageContainer = styled.div`
  display: flex;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const Message = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  word-break: break-word;
  white-space: pre-wrap;
  max-height: 7lh;
  overflow-y: auto;
`;

const IconWithPointer = styled(Icon)<{ hide?: boolean }>`
  cursor: pointer;
  visibility: ${(props) => (props.hide ? "hidden" : "inherit")};
  margin-left: ${(props) => props.theme.sizes.s};
  margin-top: 0;
`;

interface QuoteMessagesSectionProps {
  t: TFunction;
  quote: QuoteBaseDto | undefined;
}

const QuoteMessagesSection = ({ t, quote }: QuoteMessagesSectionProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const userInformation = useSelector(getUserInformation);
  const userAccessRights = useSelector(getUserAccessRights);
  const saveMessageToRequestorStatus = useSelector(getSaveMessageToRequestorStatus);
  const getQuoteByIdRequestStatus = useSelector(getGetQuoteByIdRequestStatus);

  const hasApproveQuoteRight =
    hasAnyAccessRight(quote?.companyId, userAccessRights, AccessRights.ApproveQuotes) ||
    hasAnyAccessRight(quote?.companyId, userAccessRights, AccessRights.RequestedQuoteApprover);
  const userHasAccessRightsToEdit =
    quote &&
    ((quote.stateId === QuoteStateTypes.QuoteWaitingForApproval && hasApproveQuoteRight) ||
      quote.quotedById === userInformation?.id);

  const [editMode, setEditMode] = useState(!quote?.messageToRequestor && userHasAccessRightsToEdit);
  const [message, setMessage] = useState("");

  // section is visible when either user has rights to edit or message is not empty
  // quoted by user can edit the message and user with approve quote rights if quote in state QuoteWaitingForApproval
  const isMessagesSectionVisible = useMemo((): boolean => {
    if (userHasAccessRightsToEdit) {
      return true;
    } else if (
      !(
        quote?.stateId === QuoteStateTypes.Draft ||
        quote?.stateId === QuoteStateTypes.WaitingForQuote ||
        quote?.stateId === QuoteStateTypes.WaitingForFrameAgreement ||
        quote?.stateId === QuoteStateTypes.WaitingForFrameAgreementApproval ||
        (quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval && !hasApproveQuoteRight)
      ) &&
      !!quote?.messageToRequestor
    ) {
      return true;
    }
    return false;
  }, [hasApproveQuoteRight, quote, userHasAccessRightsToEdit]);

  const saveMessage = (message: string) => {
    if (quote?.id) {
      dispatch(
        shoppingCartSagas.saveMessageToRequestor.createAction({
          id: quote?.id,
          saveMessageToRequestorDto: {
            message
          }
        })
      );
    }
  };

  useEffect(() => {
    if (
      getQuoteByIdRequestStatus === RequestStatus.Completed &&
      saveMessageToRequestorStatus === RequestStatus.Completed
    ) {
      setEditMode(false);
    }
  }, [dispatch, getQuoteByIdRequestStatus, saveMessageToRequestorStatus]);

  return isMessagesSectionVisible ? (
    <ContentContainer title={t("Message to requestor")}>
      <Container>
        {!editMode ? (
          <MessageContainer>
            <Message>{quote?.messageToRequestor}</Message>
            <IconWithPointer
              name="abb/edit"
              sizeClass="medium"
              onClick={() => {
                setEditMode(true);
                setMessage(quote?.messageToRequestor ?? "");
              }}
              hide={!userHasAccessRightsToEdit}
            />
          </MessageContainer>
        ) : (
          <>
            <SectionsContainer>
              <InputWrapper>
                <ResizableInput
                  dataType="textarea"
                  placeholder={t("Your message to quote requestor")}
                  value={message}
                  onValueChange={(v) => {
                    setMessage(v);
                  }}
                  inputAttributes={{
                    rows: 3
                  }}
                  required={false}
                  resizable={false}
                />
              </InputWrapper>
            </SectionsContainer>
            <ButtonContainer>
              {quote?.messageToRequestor ? (
                <Button
                  buttonType={"secondary"}
                  text={t("Cancel")}
                  onClick={() => {
                    setEditMode(false);
                  }}
                  isLoading={
                    saveMessageToRequestorStatus === RequestStatus.Pending ||
                    getQuoteByIdRequestStatus === RequestStatus.Pending
                  }
                  disabled={false}
                />
              ) : undefined}
              <Button
                buttonType={"primary"}
                text={t("Save")}
                onClick={() => saveMessage(message)}
                isLoading={
                  saveMessageToRequestorStatus === RequestStatus.Pending ||
                  getQuoteByIdRequestStatus === RequestStatus.Pending
                }
                disabled={!message}
              />
            </ButtonContainer>
          </>
        )}
      </Container>
    </ContentContainer>
  ) : null;
};

export default QuoteMessagesSection;
