import React from "react";
import styled, { useTheme } from "styled-components";
import { Icon, WithPopup, Popup, PopupPosition } from "@abb/abb-common-ux-react";
import { GlobalStyle } from "styles/commonUXVariables";

const InfoIcon = styled(Icon)`
  align-self: center;
  :hover {
    cursor: help;
  }
`;

const TooltipText = styled.p<{ fontSize?: string | null }>`
  font-size: ${(props) => props.fontSize ?? props.theme.fonts.sizes.fontSizeXs};
  margin: ${(props) => props.theme.sizes.s};
`;

const TextContainer = styled.div`
  max-width: 280px;
  padding: ${(props) => props.theme.sizes.xs};
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const positions: PopupPosition[] = ["top center"];

interface IconWithTooltip {
  tooltipText: string;
  icon: string;
  style?: React.CSSProperties | undefined;
  sizeClass?: "small" | "medium" | "large";
  trigger?: "hover" | "click" | "controlled";
  onClose?: () => void;
  isDefaultOpen?: boolean;
  color?: string;
  tooltipTextSize?: string;
}

const getWidthAndHeight = (sizeClass: "small" | "medium" | "large", theme: GlobalStyle) => {
  switch (sizeClass) {
    case "small":
      return theme.sizes.m;
    case "medium":
      return theme.sizes.lm;
    case "large":
      return theme.sizes.l;
  }
};

export const IconWithTooltip = React.memo(
  ({
    tooltipText,
    icon,
    style,
    onClose,
    sizeClass = "small",
    trigger = "hover",
    isDefaultOpen,
    color,
    tooltipTextSize
  }: IconWithTooltip): JSX.Element => {
    const theme = useTheme();
    const side = getWidthAndHeight(sizeClass, theme);
    return (
      <WithPopup>
        <div
          style={{
            width: side,
            height: side,
            alignSelf: "center",
            ...style
          }}
        >
          <InfoIcon name={icon} sizeClass={sizeClass} color={color}></InfoIcon>
        </div>
        <Popup
          trigger={trigger}
          position={positions}
          disablePaddings={true}
          defaultOpen={isDefaultOpen}
          onClose={onClose}
        >
          <TextContainer>
            <TooltipText fontSize={tooltipTextSize}>{tooltipText}</TooltipText>
          </TextContainer>
        </Popup>
      </WithPopup>
    );
  }
);

IconWithTooltip.displayName = "IconWithTooltip";
