/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticlePosition,
    ArticlePositionFromJSON,
    ArticlePositionFromJSONTyped,
    ArticlePositionToJSON,
} from './ArticlePosition';

/**
 * 
 * @export
 * @interface UpdateArticleNodeCommand
 */
export interface UpdateArticleNodeCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleNodeCommand
     */
    nodeId: string;
    /**
     * 
     * @type {Array<ArticlePosition>}
     * @memberof UpdateArticleNodeCommand
     */
    articlesId?: Array<ArticlePosition> | null;
}

export function UpdateArticleNodeCommandFromJSON(json: any): UpdateArticleNodeCommand {
    return UpdateArticleNodeCommandFromJSONTyped(json, false);
}

export function UpdateArticleNodeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateArticleNodeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'articlesId': !exists(json, 'articlesId') ? undefined : (json['articlesId'] === null ? null : (json['articlesId'] as Array<any>).map(ArticlePositionFromJSON)),
    };
}

export function UpdateArticleNodeCommandToJSON(value?: UpdateArticleNodeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'articlesId': value.articlesId === undefined ? undefined : (value.articlesId === null ? null : (value.articlesId as Array<any>).map(ArticlePositionToJSON)),
    };
}

