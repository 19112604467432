import {
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler,
  createGenericSaga
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const manageGlobalSettingsSagas = {
  getGlobalSettings: createGenericSaga(
    "manageGlobalSettingsSagas/getGlobalSettings",
    getApiRegistry().globalSettingsApi.apiGlobalSettingGet.bind(getApiRegistry().globalSettingsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateGlobalSettings: createGenericSaga(
    "manageGlobalSettingsSagas/updateGlobalSettings",
    getApiRegistry().globalSettingsApi.apiGlobalSettingPut.bind(getApiRegistry().globalSettingsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Settings successfully updated!")
    }
  )
};
