/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewSubscriptionsCommand
 */
export interface RenewSubscriptionsCommand {
    /**
     * 
     * @type {string}
     * @memberof RenewSubscriptionsCommand
     */
    shoppingCartGuid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RenewSubscriptionsCommand
     */
    subscriptionGuids: Array<string>;
}

export function RenewSubscriptionsCommandFromJSON(json: any): RenewSubscriptionsCommand {
    return RenewSubscriptionsCommandFromJSONTyped(json, false);
}

export function RenewSubscriptionsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewSubscriptionsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartGuid': json['shoppingCartGuid'],
        'subscriptionGuids': json['subscriptionGuids'],
    };
}

export function RenewSubscriptionsCommandToJSON(value?: RenewSubscriptionsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartGuid': value.shoppingCartGuid,
        'subscriptionGuids': value.subscriptionGuids,
    };
}

