/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MenuItemTypes {
    MenuItem = 'MenuItem',
    UserMenu = 'UserMenu',
    NotificationLink = 'NotificationLink',
    ShoppingCartMenu = 'ShoppingCartMenu',
    Comparison = 'Comparison'
}

export function MenuItemTypesFromJSON(json: any): MenuItemTypes {
    return MenuItemTypesFromJSONTyped(json, false);
}

export function MenuItemTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItemTypes {
    return json as MenuItemTypes;
}

export function MenuItemTypesToJSON(value?: MenuItemTypes | null): any {
    return value as any;
}

