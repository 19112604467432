/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    vendorOrderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    customerOrderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    customerPurchaseOrderNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    orderAcknowledgementNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    orderIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemDto
     */
    hasRoutineTestReports: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemDto
     */
    hasSoftwareDocuments: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItemDto
     */
    deliveringPlant?: string | null;
}

export function OrderItemDtoFromJSON(json: any): OrderItemDto {
    return OrderItemDtoFromJSONTyped(json, false);
}

export function OrderItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serialNumber'],
        'vendorOrderingCode': !exists(json, 'vendorOrderingCode') ? undefined : json['vendorOrderingCode'],
        'customerOrderingCode': !exists(json, 'customerOrderingCode') ? undefined : json['customerOrderingCode'],
        'customerPurchaseOrderNumber': !exists(json, 'customerPurchaseOrderNumber') ? undefined : json['customerPurchaseOrderNumber'],
        'orderAcknowledgementNumber': !exists(json, 'orderAcknowledgementNumber') ? undefined : json['orderAcknowledgementNumber'],
        'orderIdentifier': !exists(json, 'orderIdentifier') ? undefined : json['orderIdentifier'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'hasRoutineTestReports': json['hasRoutineTestReports'],
        'hasSoftwareDocuments': json['hasSoftwareDocuments'],
        'deliveringPlant': !exists(json, 'deliveringPlant') ? undefined : json['deliveringPlant'],
    };
}

export function OrderItemDtoToJSON(value?: OrderItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'vendorOrderingCode': value.vendorOrderingCode,
        'customerOrderingCode': value.customerOrderingCode,
        'customerPurchaseOrderNumber': value.customerPurchaseOrderNumber,
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'orderIdentifier': value.orderIdentifier,
        'companyId': value.companyId,
        'hasRoutineTestReports': value.hasRoutineTestReports,
        'hasSoftwareDocuments': value.hasSoftwareDocuments,
        'deliveringPlant': value.deliveringPlant,
    };
}

