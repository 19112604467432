import {
  ManageUserVm,
  ApiUsersIdGetRequest,
  UserDto,
  ApiUsersManageUserPutRequest,
  ApiUsersManageUserRolesPutRequest,
  ApiUsersManageUserCountryCodePutRequest,
  NotificationSeverity,
  UserPriceListVm,
  ApiUsersUpdateUserDisabledManagedPriceListPutRequest,
  UserDisabledManagedPriceListDto,
  ApiPricesUserPriceListsGetRequest,
  UserTeamRolesDto,
  ApiUsersRequestTeamAccessPostRequest
} from "api";
import { userActions } from "applications/common/actions/userActions";
import {
  createGenericSaga,
  CreateSagaOptions,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler,
  GenericSagaOptions,
  SagaBuilder
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

interface ManageUserSagas {
  // CreateSaga accepts only this, not sure how to type but this works
  [index: string]: CreateSagaOptions<any, any>;
  getUserById: GenericSagaOptions<ManageUserVm, ApiUsersIdGetRequest>;
  updateUser: GenericSagaOptions<UserDto, ApiUsersManageUserPutRequest>;
  updateUserCountryCode: GenericSagaOptions<UserDto, ApiUsersManageUserCountryCodePutRequest>;
  updateUserRoles: GenericSagaOptions<void, ApiUsersManageUserRolesPutRequest>;
  getUserPriceLists: GenericSagaOptions<UserPriceListVm, ApiPricesUserPriceListsGetRequest>;
  updateUserDisabledManagedPriceList: GenericSagaOptions<
    UserDisabledManagedPriceListDto,
    ApiUsersUpdateUserDisabledManagedPriceListPutRequest
  >;
  requestTeamAccess: GenericSagaOptions<UserTeamRolesDto, ApiUsersRequestTeamAccessPostRequest>;
}

export const manageUsersSagas: ManageUserSagas = {
  getUserById: createGenericSaga(
    "manageUserSagas/getUserById",
    getApiRegistry().usersApi.apiUsersIdGet.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateUser: createGenericSaga(
    "manageUserSagas/updateUser",
    getApiRegistry().usersApi.apiUsersManageUserPut.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("User updated successfully"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateUserCountryCode: createGenericSaga(
    "manageUserSagas/updateUserCountryCode",
    getApiRegistry().usersApi.apiUsersManageUserCountryCodePut.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("User updated successfully"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateUserRoles: createGenericSaga(
    "manageUserSagas/updateUserRoles",
    getApiRegistry().usersApi.apiUsersManageUserRolesPut.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: (requestPayload) =>
        requestPayload.updateUserRolesCommand?.userId
          ? new SagaBuilder()
            .dispatch(
              manageUsersSagas.getUserById.createAction({
                id: requestPayload.updateUserRolesCommand?.userId
              })
            )
            .dispatch(
              userActions.addNotification(NotificationSeverity.Success, "User roles updated")
            )
            .build()()
          : defaultSagaSuccessHandler("User roles updated")(),
      onFail: defaultSagaErrorHandler
    }
  ),
  getUserPriceLists: createGenericSaga(
    "manageUserSagas/getUserPriceLists",
    getApiRegistry().pricesApi.apiPricesUserPriceListsGet.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: (payload) => defaultSagaErrorHandler(payload, "Failed to fetch price lists")
    }
  ),
  updateUserDisabledManagedPriceList: createGenericSaga(
    "manageUserSagas/updateUserDisabledManagedPriceList",
    getApiRegistry().usersApi.apiUsersUpdateUserDisabledManagedPriceListPut.bind(
      getApiRegistry().usersApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  requestTeamAccess: createGenericSaga(
    "manageUserSagas/requestTeamAccess",
    getApiRegistry().usersApi.apiUsersRequestTeamAccessPost.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Requesting access to team success"),
      onFail: defaultSagaErrorHandler
    }
  )
};
