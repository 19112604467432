/**
 * Compares if the fraction a/b is less than or equal to c/d.
 * It's useful for comparing percentages where they are calculated based on fractions with whole number parts.
 */
export const isFractionSmallerThanOrEqualTo = (
  [a, b]: [number, number],
  [c, d]: [number, number]
) => {
  if (b === 0 || d === 0)
    throw new Error(`Can not divide by 0, attempting to compare ${a}/${b} and ${c}/${d}`);

  // We have the equality `a/b <= c/d`.
  // We can multiply both sides by `b` and get `a <= c*b/d`
  // Now let's multiply both sides by `d` and we get `a*d <= c*b`

  return a * d <= c * b;
};
