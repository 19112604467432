/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SSC600Settings,
    SSC600SettingsFromJSON,
    SSC600SettingsToJSON,
    SelectorApplicationFeatureDto,
    SelectorApplicationFeatureDtoFromJSON,
    SelectorApplicationFeatureDtoToJSON,
    SelectorMainApplicationDto,
    SelectorMainApplicationDtoFromJSON,
    SelectorMainApplicationDtoToJSON,
    SelectorProductDto,
    SelectorProductDtoFromJSON,
    SelectorProductDtoToJSON,
    SelectorSegmentDto,
    SelectorSegmentDtoFromJSON,
    SelectorSegmentDtoToJSON,
    SendContactUsCommand,
    SendContactUsCommandFromJSON,
    SendContactUsCommandToJSON,
} from '../models';

export interface ApiSelectorAdditionalFeaturesGetRequest {
    mainApplicationId?: number;
}

export interface ApiSelectorApplicationsGetRequest {
    segmentId?: number;
    subSegmentId?: number;
}

export interface ApiSelectorConnectWithUsPostRequest {
    sendContactUsCommand?: SendContactUsCommand;
}

export interface ApiSelectorProductsGetRequest {
    mainApplicationId?: number;
    additionalFeatureIds?: Array<number>;
}

/**
 * 
 */
export class SelectorApi extends runtime.BaseAPI {

    /**
     */
    async apiSelectorAdditionalFeaturesGetRaw(requestParameters: ApiSelectorAdditionalFeaturesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SelectorApplicationFeatureDto>>> {
        const queryParameters: any = {};

        if (requestParameters.mainApplicationId !== undefined) {
            queryParameters['MainApplicationId'] = requestParameters.mainApplicationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/additionalFeatures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectorApplicationFeatureDtoFromJSON));
    }

    /**
     */
    async apiSelectorAdditionalFeaturesGet(requestParameters: ApiSelectorAdditionalFeaturesGetRequest, initOverrides?: RequestInit): Promise<Array<SelectorApplicationFeatureDto>> {
        const response = await this.apiSelectorAdditionalFeaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSelectorApplicationsGetRaw(requestParameters: ApiSelectorApplicationsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SelectorMainApplicationDto>>> {
        const queryParameters: any = {};

        if (requestParameters.segmentId !== undefined) {
            queryParameters['SegmentId'] = requestParameters.segmentId;
        }

        if (requestParameters.subSegmentId !== undefined) {
            queryParameters['SubSegmentId'] = requestParameters.subSegmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/Applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectorMainApplicationDtoFromJSON));
    }

    /**
     */
    async apiSelectorApplicationsGet(requestParameters: ApiSelectorApplicationsGetRequest, initOverrides?: RequestInit): Promise<Array<SelectorMainApplicationDto>> {
        const response = await this.apiSelectorApplicationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSelectorConnectWithUsPostRaw(requestParameters: ApiSelectorConnectWithUsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/connectWithUs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendContactUsCommandToJSON(requestParameters.sendContactUsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSelectorConnectWithUsPost(requestParameters: ApiSelectorConnectWithUsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSelectorConnectWithUsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSelectorProductsGetRaw(requestParameters: ApiSelectorProductsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SelectorProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters.mainApplicationId !== undefined) {
            queryParameters['MainApplicationId'] = requestParameters.mainApplicationId;
        }

        if (requestParameters.additionalFeatureIds) {
            queryParameters['AdditionalFeatureIds'] = requestParameters.additionalFeatureIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectorProductDtoFromJSON));
    }

    /**
     */
    async apiSelectorProductsGet(requestParameters: ApiSelectorProductsGetRequest, initOverrides?: RequestInit): Promise<Array<SelectorProductDto>> {
        const response = await this.apiSelectorProductsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSelectorSSC600SettingsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SSC600Settings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/SSC600Settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SSC600SettingsFromJSON(jsonValue));
    }

    /**
     */
    async apiSelectorSSC600SettingsGet(initOverrides?: RequestInit): Promise<SSC600Settings> {
        const response = await this.apiSelectorSSC600SettingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSelectorSegmentsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SelectorSegmentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Selector/Segments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectorSegmentDtoFromJSON));
    }

    /**
     */
    async apiSelectorSegmentsGet(initOverrides?: RequestInit): Promise<Array<SelectorSegmentDto>> {
        const response = await this.apiSelectorSegmentsGetRaw(initOverrides);
        return await response.value();
    }

}
