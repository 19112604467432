import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { TFunction } from "i18next";
import { Icon, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { ShoppingCartDto, ShoppingCartStateTypes } from "api";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { formatDate } from "utilities/dateUtils";
import { manageTermsConditionsSagas } from "applications/manage/manageTermsConditions/sagas/manageTermsConditionsSagas";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { usePriceListsDropdown } from "framework/hooks/usePriceListsDropdown";
import TermsAndConditionsLink from "applications/common/components/TermsAndConditionsLink";
import { getCurrentUserTermsConditions } from "applications/manage/manageTermsConditions/reducers/manageTermsConditionsReducer";
import { QuoteInformation } from "./QuoteInformation";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";
import { FormikProps } from "formik";
import { getShoppingCartStateText } from "../helpers";
import { BudgetaryNote } from "../../common/components/BudgetaryNote";
import { ReviseMode } from "../containers/ShoppingCartView";

const Container = styled.div`
  min-height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.sizes.m};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.sm};
`;

const Wrapper = styled.div<{ isDetailedView?: boolean }>`
  background-color: ${(props) =>
    !props.isDetailedView ? props.theme.colors.whiteTertiary : "inherit"};
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const DetailTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.lm};
`;

const ContentWrapper = styled.div`
  margin-right: ${(props) => props.theme.sizes.xl};
`;

const Label = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  color: ${(props) => props.theme.colors.grey60};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const BoldText = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold};
  display: inline;
  margin-right: ${(props) => props.theme.sizes.s};
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  display: inline;
`;

const ValueWrapper = styled.div`
  margin: 0;
  height: ${(props) => props.theme.sizes.xl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomIcon = styled(Icon)`
  margin-top: 0;
  margin-right: ${(props) => props.theme.sizes.s};
`;

const CustomDropdown = styled(Dropdown)`
  [class$="-menu"] {
    width: max-content;
    min-width: 180px;
    right: 0;
  }
  [class$="-SingleValue"] {
    max-width: 100%;
  }
  min-width: 180px;
`;

const LinkContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  margin-top: ${(props) => props.theme.sizes.xs};
  :hover {
    cursor: pointer;
  }
`;

interface GridTopSectionProps {
  t: TFunction;
  icon: string;
  dispatch: Dispatch;
  shoppingCart: ShoppingCartDto | undefined;
  showPriceListDropdown: boolean;
  lockPriceListSelection?: boolean;
  orderDetailsSection?: JSX.Element | null;
  reviseMode: ReviseMode;
  formik?: FormikProps<ReviseQuoteFromCart>;
}

export const TopSection = ({
  t,
  shoppingCart,
  icon,
  dispatch,
  showPriceListDropdown,
  lockPriceListSelection,
  orderDetailsSection,
  reviseMode,
  formik
}: GridTopSectionProps): JSX.Element => {
  const userInformation = useSelector(getUserInformation);
  const isCompanySelected = userInformation && userInformation.selectedCompanyId;
  const { selectedPriceList, userPriceLists, changeSelectedPriceList } = usePriceListsDropdown(
    shoppingCart,
    !showPriceListDropdown || lockPriceListSelection
  );
  const currentUserTermsConditions = useSelector(getCurrentUserTermsConditions);
  const company = useMemo(
    () => shoppingCart?.company ?? userInformation?.selectedCompany,
    [shoppingCart, userInformation]
  );

  useEffect(() => {
    dispatch(manageTermsConditionsSagas.getCurrentUserTermsConditions.createAction({}));
  }, [userInformation?.selectedCompanyId, dispatch]);

  const handleDownloadTermsAndConditions = () => {
    dispatch(manageTermsConditionsSagas.downloadCurrentUserTermsConditions.createAction({}));
  };

  const isDetailedView =
    shoppingCart?.activeQuote != undefined && shoppingCart.stateId != ShoppingCartStateTypes.Draft;

  return (
    <Container>
      {orderDetailsSection ? (
        orderDetailsSection
      ) : isDetailedView ? (
        <QuoteInformation
          shoppingCart={shoppingCart}
          t={t}
          reviseMode={reviseMode}
          formik={formik}
          selectedCompany={company}
        />
      ) : (
        <Wrapper isDetailedView={isDetailedView}>
          <DetailTextWrapper>
            <ContentWrapper>
              <Label>{t("Status")}</Label>
              <ValueWrapper>
                <BoldText>{getShoppingCartStateText(shoppingCart?.stateId, t)}</BoldText>
              </ValueWrapper>
            </ContentWrapper>
            <ContentWrapper>
              <Label>{t("Last edited")}</Label>
              <ValueWrapper>
                <Text>
                  {shoppingCart?.lastModified ? formatDate(shoppingCart?.lastModified, true) : ""}
                </Text>
              </ValueWrapper>
            </ContentWrapper>
            <ContentWrapper>
              <Label>{t("Created by")}</Label>
              <ValueWrapper>
                <BoldText>
                  {shoppingCart?.ownerFirstNameAndLastName
                    ? shoppingCart?.ownerFirstNameAndLastName
                    : ""}
                </BoldText>
              </ValueWrapper>
            </ContentWrapper>
            <ContentWrapper>
              <Label>{t("Visibility")}</Label>
              <ValueWrapper>
                <div style={{ display: "flex" }}>
                  <CustomIcon name={icon} />
                  <BoldText>{"Private"}</BoldText>
                </div>
                {/*<CustomLink discreet>Share with...</CustomLink>*/}
              </ValueWrapper>
            </ContentWrapper>
          </DetailTextWrapper>
          {showPriceListDropdown && userPriceLists ? (
            <div>
              <CustomDropdown
                searchable={userPriceLists && userPriceLists.length > 14 ? true : false}
                monochrome
                label={t("Select price list")}
                value={selectedPriceList}
                onChange={(item) => changeSelectedPriceList(item[0].value)}
                disabled={lockPriceListSelection}
              >
                <DropdownOption label={t("No prices")} value="null" />
                {userPriceLists?.map((u) => (
                  <DropdownOption key={u.value} label={u.label} value={u.value} />
                ))}
              </CustomDropdown>
              {isCompanySelected && currentUserTermsConditions ? (
                <LinkContainer>
                  <TermsAndConditionsLink
                    onClick={() => handleDownloadTermsAndConditions()}
                  ></TermsAndConditionsLink>
                </LinkContainer>
              ) : null}
              <BudgetaryNote />
            </div>
          ) : null}
        </Wrapper>
      )}
    </Container>
  );
};
