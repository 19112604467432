import {
  ColDef,
  GridApi,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams
} from "ag-grid-community";
import { SoftwareSubscriptionsDto } from "api";
import { SubscriptionRenewalStatus } from "enums/grid/subscriptionRenewalStatus";
import {
  createHeaderAndHeaderTooltip,
  getDefaultColumnSort,
  Grid,
  GridExportParams
} from "framework/components/grid/Grid";
import moment from "moment";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { getValidDateFormat } from "utilities/dateUtils";
import { defaultSubscriptionsCriteria } from "../containers/SubscriptionsView";
import {
  GridIconRenderer,
  GridIconRendererProps
} from "framework/components/grid/GridIconRenderer";
import { routes } from "utilities/routes";
import { useNavigate } from "react-router-dom";

export interface SoftwareSubscriptionsGridProps {
  setGridApi: (ref: GridApi) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<SoftwareSubscriptionsDto[]>>;
  setIsSelectAllEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  onExportCsv?: (params: GridExportParams) => void;
}

const formatDateColumn = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return "";
  }
  return moment(params.value).format(getValidDateFormat());
};

export const toggleAllSubscriptionRows = (gridApi: GridApi | null, stateToSet: boolean): void => {
  if (gridApi) {
    gridApi.forEachNode((node) => {
      const orderItem = node.data as SoftwareSubscriptionsDto;
      if (orderItem?.canRenew) {
        node.setSelected(stateToSet);
      }
    });
  }
};

export const SoftwareSubscriptionsGrid = memo(
  ({
    setGridApi,
    setSelectedRows,
    setIsSelectAllEnabled,
    onExportCsv
  }: SoftwareSubscriptionsGridProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const colDefs: ColDef[] = [
      {
        ...createHeaderAndHeaderTooltip(t("Select")),
        headerClass: "grid-header-center-with-menu",
        checkboxSelection: true,
        cellStyle: { border: "none" },
        width: 90,
        sortable: false,
        suppressSizeToFit: true,
        cellClass: "grid-checkbox",
        onCellClicked: (event) => event.node.setSelected(!event.node.isSelected())
      },
      {
        ...createHeaderAndHeaderTooltip(t("Subscription ID")),
        field: "id",
        flex: 1
      },
      {
        ...createHeaderAndHeaderTooltip(t("Product code")),
        field: "compositionCode",
        flex: 1
      },
      {
        ...createHeaderAndHeaderTooltip(t("Description")),
        field: "description",
        flex: 1
      },
      {
        ...createHeaderAndHeaderTooltip(t("Email")),
        field: "customerSoftwareUserName"
      },
      {
        ...createHeaderAndHeaderTooltip(t("Valid from")),
        field: "validityBeginDate",
        flex: 1,
        valueFormatter: formatDateColumn
      },
      {
        ...createHeaderAndHeaderTooltip(t("Valid to")),
        field: "validityEndDate",
        flex: 1,
        valueFormatter: formatDateColumn
      },
      {
        ...createHeaderAndHeaderTooltip(t("Renewal period start")),
        field: "renewalPeriodStart",
        filter: "agSetColumnFilter",
        floatingFilter: true,
        filterParams: {
          values: [SubscriptionRenewalStatus.CanRenew, SubscriptionRenewalStatus.CannotRenew],
          suppressSelectAll: true,
          valueFormatter: (params: any) => t(params.value)
        },

        flex: 1,
        valueFormatter: formatDateColumn
      },
      {
        ...createHeaderAndHeaderTooltip(t("Status")),
        cellRenderer: GridIconRenderer,
        cellRendererParams: (params: ICellRendererParams) => {
          const softwareSubscriptionsDto = params.data as SoftwareSubscriptionsDto | undefined;
          const text = t(softwareSubscriptionsDto?.statusLabel ?? "");
          const isInactive =
            softwareSubscriptionsDto?.shoppingCartIdentifier === null ||
            softwareSubscriptionsDto?.shoppingCartIdentifier === undefined ||
            text === "";

          const cellRendererParams: GridIconRendererProps = {
            ...params,
            showIcon: !isInactive,
            onClick: () => {
              if (!isInactive) {
                navigate(
                  `${routes.shoppingCart}/${softwareSubscriptionsDto?.shoppingCartIdentifier}`
                );
              }
            },
            icon: "abb/link",
            text
          };
          return cellRendererParams;
        },

        flex: 1
      }
    ];

    return (
      <Grid
        defaultSort={getDefaultColumnSort(
          defaultSubscriptionsCriteria.criteriaIsAscendingOrder,
          defaultSubscriptionsCriteria.criteriaSortColumn
        )}
        statusBarOptions={{ showResetButton: true, showRowCount: true }}
        isOnlyResetSorting={true}
        columnDefs={colDefs}
        suppressRowClickSelection={true}
        rowModelType={"infinite"}
        cacheBlockSize={200}
        maxBlocksInCache={2}
        autosizeCols
        getRowId={(params) => {
          const data = params.data;
          if (data) {
            return (data as SoftwareSubscriptionsDto).guid;
          } else {
            return "";
          }
        }}
        onSelectionChanged={(event) => {
          const selectedRows: SoftwareSubscriptionsDto[] = [];
          let hasSelectableRow = false;
          event.api.forEachNode((node) => {
            const softwareSubscription = node.data as SoftwareSubscriptionsDto;
            if (softwareSubscription?.canRenew) {
              if (node.isSelected()) {
                selectedRows.push(softwareSubscription);
              } else if (!hasSelectableRow && !node.isSelected()) {
                hasSelectableRow = true;
                setIsSelectAllEnabled(true);
              }
            }
          });

          if (!hasSelectableRow) {
            setIsSelectAllEnabled(false);
          }

          setSelectedRows(selectedRows);
        }}
        isRowSelectable={(node: IRowNode<SoftwareSubscriptionsDto>) => {
          if (node.data) {
            return node.data.canRenew ?? false;
          } else {
            return false;
          }
        }}
        rowSelection="multiple"
        onGridReady={(params) => {
          setGridApi(params.api);
          params.columnApi.applyColumnState({
            state: defaultSubscriptionsCriteria.criteriaSortColumn
              ? [
                  {
                    colId: defaultSubscriptionsCriteria.criteriaSortColumn,
                    sort: defaultSubscriptionsCriteria.criteriaIsAscendingOrder ? "asc" : "desc"
                  }
                ]
              : undefined
          });
        }}
        t={t}
        onExportCsv={onExportCsv}
      ></Grid>
    );
  }
);
