import {
  Dropdown,
  DropdownOption,
  Input,
  LoadingIndicator,
  NumericInput
} from "@abb/abb-common-ux-react";
import { SalesChannelDto, ShoppingCartDto, CompanyDto } from "api";
import { Button } from "framework/components/Button";
import { LabelWithText } from "framework/components/LabelWithText";
import { TFunction } from "i18next";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { InputLabel } from "framework/components/InputLabel";
import { Dialog } from "framework/components/Dialog";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";
import { FormikProps } from "formik";
import { handleFormikValueChange } from "utilities/formikUtils";
import { constMaxWinPercentage } from "./RequestQuotationDialog";
import { useSelector } from "react-redux";
import { getCompanyPgCodesStatus } from "../reducers/shoppingCartCompanyReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { ReviseMode } from "../containers/ShoppingCartView";

const TextWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.s};
  margin-bottom: ${(props) => props.theme.sizes.m};
  width: 100%;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div``;

const SectionsContainer = styled.div<{ dropdownIsOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: ${(props) => (props.dropdownIsOpen ? 400 : 170)}px;
`;

const FlexChild = styled.div<{ width?: number }>`
  min-width: ${(props) => props.width}px;
  flex: 1;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  width: 100%;
  flex-wrap: wrap;
`;

const CustomDialog = styled(Dialog)`
  max-height: 95vh;
  overflow: auto;
`;

const LoadingIndicatorContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

interface ReviseQuotationDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  shoppingCart: ShoppingCartDto | undefined;
  isLoading: boolean;
  salesChannels: SalesChannelDto[] | undefined;
  pgCodes: string[] | null | undefined;
  selectedCompany: CompanyDto;
  formikRevise: FormikProps<ReviseQuoteFromCart>;
  createQuoteRevision: (values: ReviseQuoteFromCart) => void;
  reviseMode: ReviseMode;
}

export const ReviseQuotationDialog = ({
  isOpen,
  t,
  setIsOpen,
  isLoading,
  shoppingCart,
  salesChannels,
  createQuoteRevision,
  pgCodes,
  selectedCompany,
  formikRevise,
  reviseMode
}: ReviseQuotationDialogProps): JSX.Element => {
  const { isInternal, isMaintainedCustomer, defaultPgCode, isInternalBuyer } = selectedCompany;
  const sectionRef = useRef<HTMLDivElement>(null);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const companyPgCodesStatus = useSelector(getCompanyPgCodesStatus);

  const getDefaultItemTemplate = (o: string) => (
    <span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{o}</span>
        <span style={{ fontSize: "80%", opacity: ".7" }}>{t("Company's default")}</span>
      </div>
    </span>
  );

  return (
    <CustomDialog
      isOpen={isOpen}
      title={t("Create quote revision")}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
    >
      {shoppingCart && companyPgCodesStatus !== RequestStatus.Pending ? (
        <Container>
          <>
            <SectionsContainer dropdownIsOpen={dropdownIsOpen}>
              <TextWrapper>
                <LabelWithText
                  text={selectedCompany.companyDisplayName}
                  label={t("Company")}
                  fontSize="medium"
                />
                <LabelWithText
                  text={shoppingCart.projectName}
                  label={t("Cart")}
                  fontSize="medium"
                />
                <LabelWithText
                  text={shoppingCart.totalQuantity.toString()}
                  label={t("Products")}
                  fontSize="medium"
                />
              </TextWrapper>
              <InputWrapper>
                <SectionWrapper ref={sectionRef}>
                  {isInternal ? (
                    <FlexChild width={230}>
                      {reviseMode == ReviseMode.Partial ? (
                        <LabelWithText
                          noMarginBottom
                          label={t("Sales channel")}
                          text={formikRevise.values.salesChannelDescription}
                        />
                      ) : (
                        <>
                          <InputLabel label={t("Sales channel")} isRequired />
                          <div
                            onClick={() => {
                              setDropdownIsOpen(!dropdownIsOpen);
                            }}
                          >
                            <Dropdown
                              label=""
                              value={[
                                {
                                  label: formikRevise.values.salesChannelDescription ?? "",
                                  value: formikRevise.values.salesChannelId
                                }
                              ]}
                              monochrome
                              onChange={(v) => {
                                formikRevise.setValues({
                                  ...formikRevise.values,
                                  salesChannelId: v[0].value ?? undefined,
                                  salesChannelDescription: v[0].label
                                });
                              }}
                              validationState={{
                                valid:
                                  !!formikRevise.errors.salesChannelId &&
                                  !!formikRevise.touched.salesChannelId
                                    ? false
                                    : true,
                                message: formikRevise.errors.salesChannelId
                              }}
                              showValidationBarWhenInvalid={true}
                            >
                              {salesChannels?.map((c) => (
                                <DropdownOption
                                  key={c.id}
                                  value={c.id}
                                  label={c.description ?? ""}
                                />
                              ))}
                            </Dropdown>
                          </div>
                        </>
                      )}
                    </FlexChild>
                  ) : null}
                  {!isInternal ? null : reviseMode == ReviseMode.Partial ? (
                    <LabelWithText
                      noMarginBottom
                      label={t("Requesting PG-code")}
                      text={formikRevise.values.pgCode}
                    />
                  ) : (
                    <FlexChild width={100}>
                      <InputLabel label={t("Requesting PG-code")} isRequired />
                      {isInternal && pgCodes && pgCodes.length > 0 ? (
                        <>
                          <Dropdown
                            monochrome
                            value={[
                              {
                                label: formikRevise.values.pgCode ?? "",
                                value: formikRevise.values.pgCode
                              }
                            ]}
                            onChange={(v) =>
                              handleFormikValueChange(formikRevise, "pgCode", v[0].value, true)
                            }
                            onClose={() => formikRevise.setFieldTouched("pgCode", true, true)}
                            validationState={{
                              valid:
                                (!!formikRevise.errors.pgCode && !!formikRevise.touched.pgCode) ||
                                !(
                                  formikRevise.values.pgCode &&
                                  pgCodes.includes(formikRevise.values.pgCode)
                                )
                                  ? false
                                  : true,
                              message: formikRevise.errors.pgCode
                            }}
                            showValidationBarWhenInvalid
                          >
                            {pgCodes.map((x) =>
                              defaultPgCode != null && defaultPgCode == x ? (
                                <DropdownOption
                                  key={x}
                                  value={x}
                                  label={x}
                                  itemTemplate={getDefaultItemTemplate(x)}
                                />
                              ) : (
                                <DropdownOption key={x} value={x} label={x} />
                              )
                            )}
                          </Dropdown>
                        </>
                      ) : (
                        <Input
                          value={formikRevise.values.pgCode}
                          dataType={"text"}
                          validationResult={{
                            valid:
                              !!formikRevise.errors.pgCode && !!formikRevise.touched.pgCode
                                ? false
                                : true,
                            text: formikRevise.errors.pgCode
                          }}
                          showValidationBarWhenInvalid
                          showValidationIconWhenInvalid
                          inputAttributes={{
                            name: "pgCode",
                            onChange: formikRevise.handleChange
                          }}
                          onLostFocus={formikRevise.handleBlur}
                        />
                      )}
                    </FlexChild>
                  )}
                  {isInternal || isMaintainedCustomer ? (
                    <FlexChild>
                      {reviseMode == ReviseMode.Partial ? (
                        <LabelWithText
                          noMarginBottom
                          label={t("Win %")}
                          text={formikRevise.values.winningPercentage?.toString()}
                        />
                      ) : (
                        <>
                          <InputLabel label={t("Win %")} isRequired />
                          <NumericInput
                            value={formikRevise.values.winningPercentage}
                            decimals={2}
                            type="compact"
                            onChange={(v) => formikRevise.setFieldValue("winningPercentage", v)}
                            onLostFocus={() =>
                              formikRevise.setFieldTouched("winningPercentage", true)
                            }
                            step={0.1}
                            max={constMaxWinPercentage}
                            min={0}
                            validationBar={
                              formikRevise.touched.winningPercentage &&
                              formikRevise.errors.winningPercentage
                                ? "error"
                                : "none"
                            }
                          />
                        </>
                      )}
                    </FlexChild>
                  ) : null}
                  {isInternalBuyer ? (
                    <FlexChild>
                      {reviseMode == ReviseMode.Partial ? (
                        <LabelWithText
                          noMarginBottom
                          label={t("Total sales price")}
                          text={formikRevise.values.totalSalesPrice?.toString()}
                        />
                      ) : (
                        <>
                          <InputLabel label={t("Total sales price")} />
                          <NumericInput
                            value={formikRevise.values.totalSalesPrice}
                            decimals={2}
                            type="compact"
                            onChange={(v) => formikRevise.setFieldValue("totalSalesPrice", v)}
                            onLostFocus={() =>
                              formikRevise.setFieldTouched("totalSalesPrice", true)
                            }
                            step={0.1}
                            min={0}
                            validationBar={
                              formikRevise.touched.totalSalesPrice &&
                              formikRevise.errors.totalSalesPrice
                                ? "error"
                                : "none"
                            }
                          />
                        </>
                      )}
                    </FlexChild>
                  ) : null}
                </SectionWrapper>
              </InputWrapper>
            </SectionsContainer>
            <ButtonContainer>
              <Button
                buttonType={"secondary"}
                text={t("Cancel")}
                onClick={() => setIsOpen(false)}
              />
              <Button
                buttonType={"primary"}
                text={t("Create revision")}
                onClick={() => createQuoteRevision(formikRevise.values)}
                isLoading={isLoading}
              />
            </ButtonContainer>
          </>
        </Container>
      ) : (
        <LoadingIndicatorContainer>
          <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
        </LoadingIndicatorContainer>
      )}
    </CustomDialog>
  );
};
