import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Collapsible, CollapsibleContainer, Heading } from "@abb/abb-common-ux-react";
import {
  PreviewImage,
  ProductDetailsColumn,
  ProductFeature,
  ProductPreview,
  ProductShortText,
  ProductTitle,
  RecommendedTag
} from "./common/Containers";
import { commonUXTheme } from "styles/commonUXVariables";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Carousel, CarouselItem } from "framework/components/Carousel";
import { SelectorProductDto } from "api";
import { Stack } from "@mui/system";
import { SelectorApplicationFeature } from "./common/SelectorApplicationFeature";
import { StyledSwitch } from "./common/StyledSwitch";
import { IconWithTooltip } from "framework/components/IconWithTooltip";
import { getImageUrl } from "./common/ImageUrlHelper";

const FeatureDescription = styled.div(({ theme }) => ({
  paddingTop: theme.sizes.s,
  whiteSpace: "normal"
}));

const FeatureImg = styled.img(({ theme }) => ({
  paddingTop: theme.sizes.s,
  paddingBottom: theme.sizes.s
}));

const LeftTitleColumn = styled(Stack)({
  textTransform: "uppercase",
  ">div >div:nth-child(odd)": {
    borderLeft: `1px solid ${commonUXTheme.colors.grey30}`,
    borderLeftWidth: "1px !important"
  }
});

const StyledCollapsibleContainer = styled(CollapsibleContainer)({
  ".ABB_CommonUX_Collapsible__headerExpanded": {
    borderLeft: `${commonUXTheme.sizes.xs} solid ${commonUXTheme.colors.blackPrimary}`,
    boxSizing: "border-box"
  },
  ".ABB_CommonUX_Collapsible__root": {
    borderBottom: `${commonUXTheme.sizes.xxs} solid ${commonUXTheme.colors.grey10}`,
    paddingTop: commonUXTheme.sizes.xs,
    paddingBottom: commonUXTheme.sizes.xs
  },
  ".ABB_CommonUX_Collapsible__header,.ABB_CommonUX_Collapsible__customContent": {
    background: "inherit !important"
  },
  ".ABB_CommonUX_Collapsible__root>.ABB_CommonUX_Collapsible__header>.ABB_CommonUX_Collapsible__title":
    {
      whiteSpace: "normal",
      textAlign: "left"
    },
  ".ABB_CommonUX_Collapsible__root.ABB_CommonUX_Collapsible__light>.ABB_CommonUX_Collapsible__header":
    {
      height: "auto",
      alignItems: "center",
      paddingLeft: 0,
      ".ABB_CommonUX_Collapsible__expanderIcon": {
        position: "relative",
        left: 0,
        top: 0
      }
    }
});

interface FeatureStepProps {
  products?: SelectorProductDto[] | null;
  additionalFeatures?: SelectorApplicationFeature[] | null;
  onFeatureSelectionChange: (features: SelectorApplicationFeature[]) => void;
}

const FeaturesStep = ({
  products,
  additionalFeatures,
  onFeatureSelectionChange
}: FeatureStepProps): JSX.Element => {
  const { t } = useTranslation();
  const itemWidth = 250;
  const additionalFeatureColumnWidth = 220;
  const featureTitleColumnWidth = 160;

  const [features, setFeatures] = useState<SelectorApplicationFeature[] | null>();

  const setFeatureRulesAndProperties = useCallback((features: SelectorApplicationFeature[]) => {
    const allDisabledFeatureIds = features
      .filter((f) => f.isSelected)
      .flatMap((f) => f.disabledFeatureIds);

    for (const feature of features) {
      if (feature.id) {
        if (feature.image) feature.imageUrl = getImageUrl(feature.image);
        feature.isDisabled = allDisabledFeatureIds.indexOf(feature.id) >= 0;
        if (feature.isDisabled) feature.isSelected = false;
      }
    }

    return features;
  }, []);

  useEffect(() => {
    setFeatures(setFeatureRulesAndProperties(additionalFeatures ?? []));
  }, [additionalFeatures, setFeatureRulesAndProperties]);

  const onToggleSwitchClick = (feature: SelectorApplicationFeature) => {
    if (feature && features) {
      feature.isSelected = !feature.isSelected;

      onFeatureSelectionChange([...setFeatureRulesAndProperties(features)]);
    }
  };

  const onToggleSwitchWrapperClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onExpand = (id: string) => {
    const expandedFeature = features?.find((f) => f.id?.toString() === id);
    if (expandedFeature) {
      expandedFeature.isExpanded = true;
    }
  };

  const onCollapse = (id: string) => {
    const collapsingFeature = features?.find((f) => f.id?.toString() === id);
    if (collapsingFeature) collapsingFeature.isExpanded = false;
  };

  const hasFilters = features && features?.findIndex((f) => f.isSelected || f._default) >= 0;

  const defaultFeature = features?.find((f) => f._default);

  return (
    <Box width={"100%"}>
      <Grid container mt={commonUXTheme.sizes.l} gap={3}>
        <Grid item width={additionalFeatureColumnWidth} minHeight={"200px"}>
          {features && (
            <>
              <Stack
                minHeight={"220px"}
                direction={"column-reverse"}
                paddingBottom={commonUXTheme.sizes.xl}
              >
                <FeatureDescription>{defaultFeature?.description ?? ""}</FeatureDescription>
                <Heading text={defaultFeature?.name ?? ""} showCursor={false} level={4}></Heading>
              </Stack>
              <Heading
                text={
                  defaultFeature ? t("Select available features") : t("Select features to continue")
                }
                showCursor={false}
                level={4}
              ></Heading>
              <Grid>
                <StyledCollapsibleContainer expandOnTitleClick={true}>
                  {features
                    ?.filter((f) => f.id !== defaultFeature?.id)
                    .map((f) => (
                      <Collapsible
                        key={f.id}
                        itemId={f.id?.toString() ?? ""}
                        onExpand={onExpand}
                        onCollapse={onCollapse}
                        title={
                          <Grid
                            spacing={0}
                            flexWrap={"nowrap"}
                            width={"100%"}
                            container
                            display={"inline-flex"}
                          >
                            <Grid marginTop={"auto"} xs marginBottom={"auto"} item>
                              <Stack width={"100%"} fontWeight={f.isSelected ? "bold" : "normal"}>
                                {f.name}
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              width={"60px"}
                              alignItems={"right"}
                              alignSelf="center"
                              textAlign="right"
                            >
                              <Stack marginTop={commonUXTheme.sizes.xs} textAlign={"center"}>
                                <div onClick={(e) => onToggleSwitchWrapperClick(e)}>
                                  <StyledSwitch
                                    monochrome={true}
                                    disabled={!!f.isDisabled}
                                    value={f.isSelected}
                                    onChange={() => onToggleSwitchClick(f)}
                                  />
                                </div>
                              </Stack>
                            </Grid>
                          </Grid>
                        }
                      >
                        <Stack>
                          <FeatureDescription>{f.description}</FeatureDescription>
                          {f.imageUrl && <FeatureImg src={f.imageUrl} />}
                        </Stack>
                      </Collapsible>
                    ))}
                </StyledCollapsibleContainer>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs minWidth={itemWidth}>
          {!hasFilters ? (
            <></>
          ) : (
            <Grid container columnGap={1}>
              <Box display={"flex"} width={featureTitleColumnWidth}>
                {products && products[0] && (
                  <LeftTitleColumn>
                    <ProductPreview></ProductPreview>
                    <RecommendedTag $isRecommended={false}>
                      <div> {t("Recommended")}</div>
                    </RecommendedTag>
                    <ProductDetailsColumn
                      item
                      width={featureTitleColumnWidth}
                      minWidth={`${featureTitleColumnWidth}px !important`}
                      $isSelected={false}
                      mt={commonUXTheme.sizes.s}
                    >
                      <ProductTitle mt={0} container alignItems={"center"}>
                        <Stack width={"100%"}>{t("Relay")}</Stack>
                      </ProductTitle>
                      {products[0].selectorProductHighlights?.map((h, i) => (
                        <ProductFeature
                          key={h.id}
                          $lite={i % 2 === 0}
                          container
                          alignItems={"center"}
                        >
                          <span>{h.selectorSubCategory?.categoryName ?? ""}</span>
                        </ProductFeature>
                      ))}
                    </ProductDetailsColumn>
                  </LeftTitleColumn>
                )}
              </Box>

              <Box width={`calc(100% - ${featureTitleColumnWidth + 8}px)`} display={"flex"}>
                <Carousel gap={1}>
                  {products?.map((p, i) => {
                    return (
                      <CarouselItem minWidth={300} maxWidth={300} key={p.id}>
                        <Stack>
                          <ProductPreview>
                            <PreviewImage src={p.previewImageUrl ?? ""} />
                          </ProductPreview>
                          {
                            <RecommendedTag $isRecommended={i == 0}>
                              <div> {t("Recommended")}</div>
                            </RecommendedTag>
                          }
                          <ProductDetailsColumn mt={commonUXTheme.sizes.s} $isSelected={i == 0}>
                            <ProductTitle container alignItems={"center"}>
                              <Stack direction={"row"} width={"100%"}>
                                <b>{p.name}</b>
                                <IconWithTooltip
                                  icon="abb/information-circle-1"
                                  sizeClass="small"
                                  color={commonUXTheme.colors.brandBlack}
                                  style={{ marginLeft: commonUXTheme.sizes.s }}
                                  tooltipText={p.longText ?? ""}
                                />
                              </Stack>
                              <ProductShortText>
                                {p.shortText && p.shortText?.length > 70
                                  ? p.shortText?.substring(0, 70) + "..."
                                  : p.shortText}
                              </ProductShortText>
                            </ProductTitle>
                            {p.selectorProductHighlights?.map((h, i) => (
                              <ProductFeature
                                key={h.id}
                                $lite={i % 2 === 0}
                                container
                                alignItems={"center"}
                              >
                                <span>{h.selectorSubCategory?.name ?? ""}</span>
                              </ProductFeature>
                            ))}
                          </ProductDetailsColumn>
                        </Stack>
                      </CarouselItem>
                    );
                  })}
                </Carousel>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturesStep;
