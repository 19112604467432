import { Tooltip as MuiTooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";

export const Tooltip = styled(({ className, ...other }) => (
  <MuiTooltip classes={{ tooltip: className }} {...other} />
))`
  background-color: ${commonUXTheme.colors.grey60};
`;
