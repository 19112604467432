/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductDescription
 */
export interface ProductDescription {
    /**
     * 
     * @type {string}
     * @memberof ProductDescription
     */
    selectionToolName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDescription
     */
    selectionToolShortText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDescription
     */
    selectionToolLongText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDescription
     */
    selectionToolPageUrl?: string | null;
}

export function ProductDescriptionFromJSON(json: any): ProductDescription {
    return ProductDescriptionFromJSONTyped(json, false);
}

export function ProductDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selectionToolName': json['selectionToolName'],
        'selectionToolShortText': !exists(json, 'selectionToolShortText') ? undefined : json['selectionToolShortText'],
        'selectionToolLongText': !exists(json, 'selectionToolLongText') ? undefined : json['selectionToolLongText'],
        'selectionToolPageUrl': !exists(json, 'selectionToolPageUrl') ? undefined : json['selectionToolPageUrl'],
    };
}

export function ProductDescriptionToJSON(value?: ProductDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selectionToolName': value.selectionToolName,
        'selectionToolShortText': value.selectionToolShortText,
        'selectionToolLongText': value.selectionToolLongText,
        'selectionToolPageUrl': value.selectionToolPageUrl,
    };
}

