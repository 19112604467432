/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportCartViaEmailCommand
 */
export interface ExportCartViaEmailCommand {
    /**
     * 
     * @type {string}
     * @memberof ExportCartViaEmailCommand
     */
    shoppingCartIdentifier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExportCartViaEmailCommand
     */
    quoteId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCartViaEmailCommand
     */
    showPrices?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCartViaEmailCommand
     */
    showDetails?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportCartViaEmailCommand
     */
    orderingCodes?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ExportCartViaEmailCommand
     */
    priceListId?: number | null;
}

export function ExportCartViaEmailCommandFromJSON(json: any): ExportCartViaEmailCommand {
    return ExportCartViaEmailCommandFromJSONTyped(json, false);
}

export function ExportCartViaEmailCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCartViaEmailCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartIdentifier': !exists(json, 'shoppingCartIdentifier') ? undefined : json['shoppingCartIdentifier'],
        'quoteId': !exists(json, 'quoteId') ? undefined : json['quoteId'],
        'showPrices': !exists(json, 'showPrices') ? undefined : json['showPrices'],
        'showDetails': !exists(json, 'showDetails') ? undefined : json['showDetails'],
        'orderingCodes': !exists(json, 'orderingCodes') ? undefined : json['orderingCodes'],
        'priceListId': !exists(json, 'priceListId') ? undefined : json['priceListId'],
    };
}

export function ExportCartViaEmailCommandToJSON(value?: ExportCartViaEmailCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartIdentifier': value.shoppingCartIdentifier,
        'quoteId': value.quoteId,
        'showPrices': value.showPrices,
        'showDetails': value.showDetails,
        'orderingCodes': value.orderingCodes,
        'priceListId': value.priceListId,
    };
}

