/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PreviousOrderSuggestion,
    PreviousOrderSuggestionFromJSON,
    PreviousOrderSuggestionFromJSONTyped,
    PreviousOrderSuggestionToJSON,
} from './PreviousOrderSuggestion';

/**
 * 
 * @export
 * @interface PreviousOrderSuggestionDto
 */
export interface PreviousOrderSuggestionDto {
    /**
     * 
     * @type {Array<PreviousOrderSuggestion>}
     * @memberof PreviousOrderSuggestionDto
     */
    billingAddresses?: Array<PreviousOrderSuggestion> | null;
    /**
     * 
     * @type {Array<PreviousOrderSuggestion>}
     * @memberof PreviousOrderSuggestionDto
     */
    deliveryAddresses?: Array<PreviousOrderSuggestion> | null;
    /**
     * 
     * @type {Array<PreviousOrderSuggestion>}
     * @memberof PreviousOrderSuggestionDto
     */
    endCustomers?: Array<PreviousOrderSuggestion> | null;
    /**
     * 
     * @type {Array<PreviousOrderSuggestion>}
     * @memberof PreviousOrderSuggestionDto
     */
    consignees?: Array<PreviousOrderSuggestion> | null;
}

export function PreviousOrderSuggestionDtoFromJSON(json: any): PreviousOrderSuggestionDto {
    return PreviousOrderSuggestionDtoFromJSONTyped(json, false);
}

export function PreviousOrderSuggestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviousOrderSuggestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingAddresses': !exists(json, 'billingAddresses') ? undefined : (json['billingAddresses'] === null ? null : (json['billingAddresses'] as Array<any>).map(PreviousOrderSuggestionFromJSON)),
        'deliveryAddresses': !exists(json, 'deliveryAddresses') ? undefined : (json['deliveryAddresses'] === null ? null : (json['deliveryAddresses'] as Array<any>).map(PreviousOrderSuggestionFromJSON)),
        'endCustomers': !exists(json, 'endCustomers') ? undefined : (json['endCustomers'] === null ? null : (json['endCustomers'] as Array<any>).map(PreviousOrderSuggestionFromJSON)),
        'consignees': !exists(json, 'consignees') ? undefined : (json['consignees'] === null ? null : (json['consignees'] as Array<any>).map(PreviousOrderSuggestionFromJSON)),
    };
}

export function PreviousOrderSuggestionDtoToJSON(value?: PreviousOrderSuggestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingAddresses': value.billingAddresses === undefined ? undefined : (value.billingAddresses === null ? null : (value.billingAddresses as Array<any>).map(PreviousOrderSuggestionToJSON)),
        'deliveryAddresses': value.deliveryAddresses === undefined ? undefined : (value.deliveryAddresses === null ? null : (value.deliveryAddresses as Array<any>).map(PreviousOrderSuggestionToJSON)),
        'endCustomers': value.endCustomers === undefined ? undefined : (value.endCustomers === null ? null : (value.endCustomers as Array<any>).map(PreviousOrderSuggestionToJSON)),
        'consignees': value.consignees === undefined ? undefined : (value.consignees === null ? null : (value.consignees as Array<any>).map(PreviousOrderSuggestionToJSON)),
    };
}

