import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const manageReleaseNotesSagas = {
  getReleaseNotes: createGenericSaga(
    "manageReleaseNotesSagas/getReleaseNotes",
    getApiRegistry().releaseNotesApi.apiReleaseNotesGet.bind(getApiRegistry().releaseNotesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  createReleaseNote: createGenericSaga(
    "manageReleaseNotesSagas/createReleaseNote",
    getApiRegistry().releaseNotesApi.apiReleaseNotesPost.bind(getApiRegistry().releaseNotesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Release note created"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateReleaseNote: createGenericSaga(
    "manageReleaseNotesSagas/updateReleaseNote",
    getApiRegistry().releaseNotesApi.apiReleaseNotesVersionPut.bind(
      getApiRegistry().releaseNotesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Release note updated"),
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteReleaseNote: createGenericSaga(
    "manageReleaseNotesSagas/deleteReleaseNote",
    getApiRegistry().releaseNotesApi.apiReleaseNotesVersionDelete.bind(
      getApiRegistry().releaseNotesApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Release note deleted"),
      onFail: defaultSagaErrorHandler
    }
  )
};
