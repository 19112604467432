import { css } from "styled-components";
import { commonUXTheme } from "./commonUXVariables";

export const markdownStyles = css`
  .markdown-content {
    background-color: ${commonUXTheme.colors.whitePrimary};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeM};
    font-family: ${commonUXTheme.fonts.families.fontAbbRegular},
      ${commonUXTheme.fonts.families.fontAbbMedium}, ${commonUXTheme.fonts.families.fontAbbLight},
      ${commonUXTheme.fonts.families.fontAbbBold};
    color: ${commonUXTheme.colors.blackPrimary};
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightM};

    ol,
    ul {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: ${commonUXTheme.sizes.xl};
      font-family: ${commonUXTheme.fonts.families.fontAbbLight};
    }
    ul {
      list-style-type: disc;
    }
    ol {
      list-style-type: decimal;
    }
    a {
      color: ${commonUXTheme.colors.uiBlue60};
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    table {
      width: 100%;
      border: ${commonUXTheme.borders.widths.borderWidthS} solid
        ${commonUXTheme.colors.whiteSecondary};
      border-collapse: collapse;
    }

    td,
    th {
      padding: 0.25rem 0.5rem;
      border: ${commonUXTheme.borders.widths.borderWidthS} solid
        ${commonUXTheme.colors.whiteSecondary};
    }

    th {
      text-align: left;
    }

    tbody {
      tr {
        &:nth-of-type(odd) {
          td {
            background-color: ${commonUXTheme.colors.whiteTertiary};
          }
          tr {
            background-color: ${commonUXTheme.colors.whiteTertiary};
          }
        }
      }
    }
  }
`;
