import { Icon } from "@abb/abb-common-ux-react/components/Icon";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";

type LinkType = "primary" | "secondary" | "primary-black" | "discreet";
type LinkSize = "small" | "medium";

export interface PillLinkProps {
  type?: LinkType;
  disabled?: boolean;
  size?: LinkSize;
  icon?: string;
  /** Set as true to render \<a\> tag and set as false to render \<Link\> from react-router */
  useExternalLinks?: boolean;
}

const classes: Record<LinkType, string> = {
  primary: "ABB_CommonUX_Button__primaryred",
  "primary-black": "ABB_CommonUX_Button__primaryblack",
  discreet: "ABB_CommonUX_Link__underlined",
  secondary: "ABB_CommonUX_Button__ghost"
};

export const StyledLink = styled.a`
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: ${commonUXTheme.sizes.lm};
  gap: 0.5rem;
  span {
    // This is to override PST's "#relays-online-footer span" rule
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS} !important;
  }

  // Prevents the icon from being underlined on hover
  :hover span {
    text-decoration: underline;
  }
`;

export const DisabledLink = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: ${commonUXTheme.sizes.lm};
  cursor: not-allowed;
`;

export const PillLink = ({
  children,
  type = "primary",
  className,
  disabled,
  size = "medium",
  useExternalLinks = false,
  to,
  ...props
}: LinkProps & PillLinkProps) => {
  const baseClassName = [
    className,
    "ABB_CommonUX_Button__root",
    "ABB_CommonUX_Button__pill",
    "ABB_CommonUX_Button__light",
    `ABB_CommonUX_Button__${size}Size`
  ].join(" ");

  if (disabled) {
    return (
      <DisabledLink className={baseClassName + " ABB_CommonUX_Button__disabled"} {...props}>
        {children}
      </DisabledLink>
    );
  }

  const typeClass = classes[type];
  const finalClassName = [baseClassName, typeClass].join(" ");

  const Content = () => (
    <>
      {props.icon && <Icon name={props.icon} />}
      <span>{children}</span>
    </>
  );

  if (useExternalLinks) {
    return (
      <StyledLink className={finalClassName} {...props} href={to.toString()}>
        <Content />
      </StyledLink>
    );
  }

  return (
    <StyledLink className={finalClassName} {...props} as={Link} to={to}>
      <Content />
    </StyledLink>
  );
};
