/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactOrderHandlingReasons,
    ContactOrderHandlingReasonsFromJSON,
    ContactOrderHandlingReasonsFromJSONTyped,
    ContactOrderHandlingReasonsToJSON,
} from './ContactOrderHandlingReasons';

/**
 * 
 * @export
 * @interface ContactOrderHandlingCommand
 */
export interface ContactOrderHandlingCommand {
    /**
     * 
     * @type {string}
     * @memberof ContactOrderHandlingCommand
     */
    message: string;
    /**
     * 
     * @type {ContactOrderHandlingReasons}
     * @memberof ContactOrderHandlingCommand
     */
    reason: ContactOrderHandlingReasons;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactOrderHandlingCommand
     */
    serialNumbers?: Array<string> | null;
}

export function ContactOrderHandlingCommandFromJSON(json: any): ContactOrderHandlingCommand {
    return ContactOrderHandlingCommandFromJSONTyped(json, false);
}

export function ContactOrderHandlingCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactOrderHandlingCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'reason': ContactOrderHandlingReasonsFromJSON(json['reason']),
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
    };
}

export function ContactOrderHandlingCommandToJSON(value?: ContactOrderHandlingCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'reason': ContactOrderHandlingReasonsToJSON(value.reason),
        'serialNumbers': value.serialNumbers,
    };
}

