import {
  ApiCompaniesIdPgCodesGetRequest,
  ApiCompaniesIdTermsOfPaymentGetRequest,
  TermsOfPaymentDto
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { Action } from "redux";
import { AppState } from "setup/appRootReducer";
import { shoppingCartCompanySagas } from "../sagas/shoppingCartCompanySagas";

interface Requests {
  getCompanyPgCodes: RequestState<string[], ApiCompaniesIdPgCodesGetRequest>;
  getCompanyTermsOfPayment: RequestState<TermsOfPaymentDto, ApiCompaniesIdTermsOfPaymentGetRequest>;
}

export interface ShoppingCartCompanyState {
  companyPgCodes: string[] | undefined;
  companyTermsOfPayment: TermsOfPaymentDto | undefined;
  companyApprovers: string[] | undefined;
  requests: Requests;
}

const defaultState: ShoppingCartCompanyState = {
  companyPgCodes: undefined,
  companyTermsOfPayment: undefined,
  companyApprovers: undefined,
  requests: {
    getCompanyPgCodes: createInitialRequest(),
    getCompanyTermsOfPayment: createInitialRequest()
  }
};

export function shoppingCartCompanyReducer(
  state: ShoppingCartCompanyState = defaultState,
  action: Action
): ShoppingCartCompanyState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: shoppingCartCompanySagas.getCompanyPgCodes.actionTypes,
      key: "getCompanyPgCodes"
    },
    {
      actionTypes: shoppingCartCompanySagas.getCompanyTermsOfPayment.actionTypes,
      key: "getCompanyTermsOfPayment"
    },
    {
      actionTypes: shoppingCartCompanySagas.getCompanyApprovers.actionTypes,
      key: "getCompanyApprovers"
    }
  ]);

  if (shoppingCartCompanySagas.getCompanyPgCodes.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.companyPgCodes = action.payload;
    });
  } else if (shoppingCartCompanySagas.getCompanyTermsOfPayment.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.companyTermsOfPayment = action.payload;
    });
  } else if (shoppingCartCompanySagas.getCompanyApprovers.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.companyApprovers = action.payload;
    });
  }

  return state;
}

export const getCompanyPgCodes = (state: AppState): string[] | undefined =>
  state.shoppingCartCompany.companyPgCodes;

export const getCompanyPgCodesStatus = (state: AppState) =>
  state.shoppingCartCompany.requests.getCompanyPgCodes.status;

export const getCompanyTermsOfPayment = (state: AppState): TermsOfPaymentDto | undefined =>
  state.shoppingCartCompany.companyTermsOfPayment;

export const getCompanyApprovers = (state: AppState): string[] | undefined =>
  state.shoppingCartCompany.companyApprovers;
