import { LifecycleStatusesVm } from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { Action } from "redux";
import { AppState } from "setup/appRootReducer";
import { lifecycleManagementSagas } from "../sagas/lifecycleManagementSagas";

interface Requests {
  getProductLifeCycleStatuses: RequestState<LifecycleStatusesVm>;
}

export interface LifecycleManagementState {
  requests: Requests;
  productLifeCyclesVm: LifecycleStatusesVm | undefined;
}

const defaultState: LifecycleManagementState = {
  requests: {
    getProductLifeCycleStatuses: createInitialRequest()
  },
  productLifeCyclesVm: undefined
};

export function lifecycleManagementReducer(
  state: LifecycleManagementState = defaultState,
  action: Action
): LifecycleManagementState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: lifecycleManagementSagas.getProductLifeCycleStatuses.actionTypes,
      key: "getProductLifeCycleStatuses"
    }
  ]);

  if (lifecycleManagementSagas.getProductLifeCycleStatuses.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.productLifeCyclesVm = action.payload;
    });
  }

  return state;
}

export const getProductLifeCycleStatuses = (state: AppState) =>
  state.lifecycleManagement.productLifeCyclesVm;
export const getProductLifeCycleStatusesRequestState = (state: AppState) =>
  state.lifecycleManagement.requests.getProductLifeCycleStatuses.status;
