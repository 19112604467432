import {
  Dropdown,
  DropdownOption
} from "@abb/abb-common-ux-react";
import { TeamMemberDto, TeamDto } from "api";
import { Button } from "framework/components/Button";
import { LabelWithText } from "framework/components/LabelWithText";
import { TFunction } from "i18next";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { InputLabel } from "framework/components/InputLabel";
import { Dialog } from "framework/components/Dialog";
import { getRoleAbbreviationsForTeamType, roleAbbreviationToFriendlyName } from "utilities/teamUtils";

const TextWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.s};
  margin-bottom: ${(props) => props.theme.sizes.m};
  width: 100%;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div``;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 140px;
`;

const FlexChild = styled.div`
  min-width: 400px;
  flex: 1;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  width: 100%;
  flex-wrap: wrap;
`;

const CustomDialog = styled(Dialog)`
  max-height: 95vh;
  overflow: auto;
`;

interface ChangeTeamMemberRoleDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  team: TeamDto;
  teamMember: TeamMemberDto;
  changeTeamMemberRole: (teamMember: TeamMemberDto, role: string) => void;
}

export const ChangeTeamMemberRoleDialog = ({
  isOpen,
  t,
  setIsOpen,
  team,
  teamMember,
  changeTeamMemberRole
}: ChangeTeamMemberRoleDialogProps): JSX.Element => {
  const [role, setRole] = useState<string>(teamMember.roleAbbreviation?.toUpperCase() ?? "");

  const getTeamRoles = useCallback(
    (): {key: string, value: string}[] => {
      return team 
        ? getRoleAbbreviationsForTeamType(team.type)
            .map((role) => ({key: role, value: roleAbbreviationToFriendlyName(role)})) 
        : [];
    },
    [team]
  );

  return (
    <CustomDialog
      isOpen={isOpen}
      title={t("Change team member role")}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
    >
      <Container>
        <>
          <SectionsContainer>
            <TextWrapper>
              <LabelWithText
                text={`${teamMember.firstName} ${teamMember.lastName}`}
                label={t("User")}
                fontSize="medium"
              />
            </TextWrapper>
            <InputWrapper>
              <SectionWrapper>
                <FlexChild>
                  <InputLabel label={t("User role")} isRequired />
                  <Dropdown
                    label=""
                    monochrome
                    onChange={(v) => {
                      setRole(v[0].value);
                    }}
                    value={[
                      {
                        value: role,
                        label: roleAbbreviationToFriendlyName(role)
                      }
                    ]}
                    showValidationBarWhenInvalid={true}
                  >
                    {getTeamRoles().map((r) => (
                      <DropdownOption key={r.key} value={r.key} label={r.value ?? ""} />
                    ))}
                  </Dropdown>
                </FlexChild>
              </SectionWrapper>
            </InputWrapper>
          </SectionsContainer>
          <ButtonContainer>
            <Button
              buttonType={"secondary"}
              text={t("Cancel")}
              onClick={() => setIsOpen(false)}
            />
            <Button
              buttonType={"primary"}
              text={t("Save")}
              onClick={() => changeTeamMemberRole(teamMember, role)}
            />
          </ButtonContainer>
        </>
      </Container>
    </CustomDialog>
  );
};
