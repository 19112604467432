import React, { useEffect } from "react";
import styled from "styled-components";
import { Heading } from "@abb/abb-common-ux-react";
import { Link } from "framework/components/Link";
import { Button } from "framework/components/Button";
import useGoogleTagManager from "setup/googleTagManager";
import { useTranslation } from "react-i18next";
import { Dialog } from "framework/components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsCookiesConsentAccepted,
  getIsCookiesConsentDialogOpen
} from "applications/common/reducers/commonReducer";
import { commonActions } from "applications/common/actions/commonActions";
import { userActions } from "applications/common/actions/userActions";
import { NotificationSeverity } from "api";

const Container = styled.div`
  padding: ${({ theme }) => theme.sizes.sm};
  padding-bottom: ${({ theme }) => theme.sizes.lm};
  height: 100%;
`;

const ContentContainer = styled.div`
  padding-bottom: 12rem;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: white;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
  padding: 1.5rem;
`;
const ButtonResponsive = styled(Button)`
  @media screen and (max-width: ${({ theme }) => parseInt(theme.breakpoints.md) - 0.5}px) {
    width: 260px;
    text-align: center;
  }
`;

export const ResponsiveDialog = styled(Dialog)`
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    position: relative;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 93%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    width: 75%;
  }

  margin: auto;
  .ABB_CommonUX_Dialog__content {
    height: 100%;
    overflow-y: auto;
  }
`;
const CookiesDescription = styled.p`
  margin-top: 0;
  padding-left: ${({ theme }) => theme.sizes.m};
  font-size: ${({ theme }) => theme.fonts.sizes.fontSizeS};
`;

function deleteCookie(name: string) {
  document.cookie = name + "=; expires=" + new Date(0).toUTCString() + ";";
}

function deleteCookies(name: string) {
  const res = Array.from(document.cookie.matchAll(new RegExp("(" + name + "[^= ]*) *(?==)", "g")));
  res.forEach((r) => {
    deleteCookie(r[0]);
  });
}

const clearLocalStorage = (keyStartsWith: string) => {
  const keys = Object.keys(localStorage);
  const matchingKeys = keys.filter((key) => key.startsWith(keyStartsWith));
  matchingKeys.forEach((key) => localStorage.removeItem(key));
};

const clearSessionStorage = (keyStartsWith: string) => {
  const keys = Object.keys(sessionStorage);
  const matchingKeys = keys.filter((key) => key.startsWith(keyStartsWith));
  matchingKeys.forEach((key) => sessionStorage.removeItem(key));
};

export const CookiesDialog = (): JSX.Element => {
  const dispatch = useDispatch();

  const dialogIsOpen = useSelector(getIsCookiesConsentDialogOpen);
  const isCookiesConsentAccepted = useSelector(getIsCookiesConsentAccepted);

  const { t } = useTranslation();

  const onClose = () => {
    dispatch(commonActions.toggleCookiesConsentDialogVisibility(false));
  };
  const cookiesNotification = () => {
    dispatch(
      userActions.addNotification(
        NotificationSeverity.Success,
        t("Cookie settings updated"),
        false,
        8000
      )
    );
  };

  useEffect(() => {
    if (isCookiesConsentAccepted) {
      useGoogleTagManager();
    } else {
      deleteCookies("_ga");
      deleteCookies("_hj");
      clearLocalStorage("_hj");
      clearSessionStorage("_hj");
    }
  }, [isCookiesConsentAccepted]);

  return (
    <ResponsiveDialog isOpen={dialogIsOpen} onClose={() => onClose()}>
      <Container>
        <Heading showCursor level={3} text={t("Cookie settings")} />
        <ContentContainer>
          <p>
            {t(
              'Our website uses cookies which are necessary for running the website and for providing the services you request. We would also like to set the following optional cookies on your device. You can change these settings any time later by clicking "Change cookie settings" at the bottom of any page. For more information, please read our '
            )}
            <Link
              displayInline
              to="https://new.abb.com/privacy-policy?_gl=1*gvb6ld*_ga*MjM3NjM3ODYwLjE2NjA4ODM5NjE.*_ga_46ZFBRSZNM*MTY2NTcyMDUyOS44LjAuMTY2NTcyMDU0NS4wLjAuMA..#Cookies"
              target="_blank"
            >
              {t("Cookie Information.")}
            </Link>
          </p>
          <p>{t("Optional cookies we can set on your device:")}</p>
          <CookiesDescription>
            <b>{t("Analytics ")}</b> :
            {t(
              " We collect statistics in order to understand how our visitors interact with the website and how we can improve it. The cookies collect information in a way that does not directly identify anyone."
            )}
          </CookiesDescription>
        </ContentContainer>
        <ButtonContainer>
          <ButtonResponsive
            buttonType="secondary"
            text={t("Decline")}
            onClick={() => {
              dispatch(commonActions.acceptCookiesConsent(false));
              cookiesNotification();
              onClose();
            }}
          />
          <ButtonResponsive
            buttonType="primary"
            text={t("Accept and agree")}
            onClick={() => {
              dispatch(commonActions.acceptCookiesConsent(true));
              cookiesNotification();
              onClose();
            }}
          />
        </ButtonContainer>
      </Container>
    </ResponsiveDialog>
  );
};
