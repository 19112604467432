/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceProductDto
 */
export interface PriceProductDto {
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    orderingCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceProductDto
     */
    validationResult: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PriceProductDto
     */
    hasAccessories: boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    materialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    staticProductType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    plusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    originalCompositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    originalPlusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    productCompositionCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceProductDto
     */
    isSoftwareProduct?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    modificationCaseId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceProductDto
     */
    categoryName?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceProductDto
     */
    excludedModificationMaterials?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    displayPrice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceProductDto
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PriceProductDto
     */
    directMaterialCost?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceProductDto
     */
    isPriceValid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PriceProductDto
     */
    modificationItems?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PriceProductDto
     */
    isAddToCartHidden?: boolean;
}

export function PriceProductDtoFromJSON(json: any): PriceProductDto {
    return PriceProductDtoFromJSONTyped(json, false);
}

export function PriceProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCode': json['orderingCode'],
        'validationResult': json['validationResult'],
        'hasAccessories': json['hasAccessories'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'staticProductType': !exists(json, 'staticProductType') ? undefined : json['staticProductType'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'plusCode': !exists(json, 'plusCode') ? undefined : json['plusCode'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'originalCompositionCode': !exists(json, 'originalCompositionCode') ? undefined : json['originalCompositionCode'],
        'originalPlusCode': !exists(json, 'originalPlusCode') ? undefined : json['originalPlusCode'],
        'productCompositionCode': !exists(json, 'productCompositionCode') ? undefined : json['productCompositionCode'],
        'isSoftwareProduct': !exists(json, 'isSoftwareProduct') ? undefined : json['isSoftwareProduct'],
        'modificationCaseId': !exists(json, 'modificationCaseId') ? undefined : json['modificationCaseId'],
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'excludedModificationMaterials': !exists(json, 'excludedModificationMaterials') ? undefined : json['excludedModificationMaterials'],
        'displayPrice': !exists(json, 'displayPrice') ? undefined : json['displayPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'directMaterialCost': !exists(json, 'directMaterialCost') ? undefined : json['directMaterialCost'],
        'isPriceValid': !exists(json, 'isPriceValid') ? undefined : json['isPriceValid'],
        'modificationItems': !exists(json, 'modificationItems') ? undefined : json['modificationItems'],
        'isAddToCartHidden': !exists(json, 'isAddToCartHidden') ? undefined : json['isAddToCartHidden'],
    };
}

export function PriceProductDtoToJSON(value?: PriceProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCode': value.orderingCode,
        'validationResult': value.validationResult,
        'hasAccessories': value.hasAccessories,
        'materialCode': value.materialCode,
        'staticProductType': value.staticProductType,
        'imageUrl': value.imageUrl,
        'displayName': value.displayName,
        'plusCode': value.plusCode,
        'category': value.category,
        'compositionCode': value.compositionCode,
        'originalCompositionCode': value.originalCompositionCode,
        'originalPlusCode': value.originalPlusCode,
        'productCompositionCode': value.productCompositionCode,
        'isSoftwareProduct': value.isSoftwareProduct,
        'modificationCaseId': value.modificationCaseId,
        'categoryName': value.categoryName,
        'excludedModificationMaterials': value.excludedModificationMaterials,
        'displayPrice': value.displayPrice,
        'price': value.price,
        'directMaterialCost': value.directMaterialCost,
        'isPriceValid': value.isPriceValid,
        'modificationItems': value.modificationItems,
        'isAddToCartHidden': value.isAddToCartHidden,
    };
}

