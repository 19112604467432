/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    SoftwareSubscriptionsDto,
    SoftwareSubscriptionsDtoFromJSON,
    SoftwareSubscriptionsDtoFromJSONTyped,
    SoftwareSubscriptionsDtoToJSON,
} from './SoftwareSubscriptionsDto';

/**
 * 
 * @export
 * @interface SoftwareSubscriptionsVm
 */
export interface SoftwareSubscriptionsVm {
    /**
     * 
     * @type {Array<SoftwareSubscriptionsDto>}
     * @memberof SoftwareSubscriptionsVm
     */
    softwareSubscriptions: Array<SoftwareSubscriptionsDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof SoftwareSubscriptionsVm
     */
    criteria: PaginationReturnModel;
}

export function SoftwareSubscriptionsVmFromJSON(json: any): SoftwareSubscriptionsVm {
    return SoftwareSubscriptionsVmFromJSONTyped(json, false);
}

export function SoftwareSubscriptionsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareSubscriptionsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'softwareSubscriptions': ((json['softwareSubscriptions'] as Array<any>).map(SoftwareSubscriptionsDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function SoftwareSubscriptionsVmToJSON(value?: SoftwareSubscriptionsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'softwareSubscriptions': ((value.softwareSubscriptions as Array<any>).map(SoftwareSubscriptionsDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

