import { Heading } from "@abb/abb-common-ux-react";
import Grid from "@mui/material/Grid";
import React from "react";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { Card } from "./common/Card";
import { Box } from "@mui/system";
import { SelectorMainApplicationDto } from "api";
import { MainApplication } from "./common/MainApplication";
import DefaultImage from "assets/selector_general.jpg";

const CardIconContainer = styled(Grid)`
  margin-right: ${(props) => props.theme.sizes.m};
`;

const CardIcon = styled.img`
  width: ${(props) => props.theme.sizes.l};
  margin: auto;
`;

const CardTitleContainer = styled(Grid)`
  flex-grow: 3;
  padding: 5px;
`;

const Application = styled(Card)`
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.xs};
  padding: ${(props) => props.theme.sizes.m};
`;

const ApplicationImage = styled.img`
  width: 100%;
`;

interface ApplicationStepProps {
  applications: MainApplication[];
  onApplicationSelected: (application: SelectorMainApplicationDto) => void;
  selectedApplication?: MainApplication;
}

const ApplicationStep = ({
  onApplicationSelected,
  selectedApplication,
  applications
}: ApplicationStepProps): JSX.Element => {
  const onApplicationClicked = (application: MainApplication) => {
    onApplicationSelected(application);
  };

  const applicationsMidIndex = Math.ceil(applications.length / 2);

  return (
    <Box minHeight={650} mt={commonUXTheme.sizes.l} mb={commonUXTheme.sizes.xxl}>
      <Grid container spacing={{ xs: 0, md: 2 }}>
        <Grid item md={4} xs={12}>
          {applications.slice(0, applicationsMidIndex).map((s) => (
            <Application
              key={s.name}
              container
              onClick={() => onApplicationClicked(s)}
              selected={s.name === selectedApplication?.name}
            >
              <CardIconContainer item>
                <CardIcon src={s.pictogramUrl} />
              </CardIconContainer>
              <CardTitleContainer item>
                <h4>{s.name}</h4>{" "}
              </CardTitleContainer>
            </Application>
          ))}
        </Grid>
        <Grid item md={4} xs={12}>
          {applications.slice(applicationsMidIndex, applications.length).map((s) => (
            <Application
              key={s.name}
              container
              onClick={() => onApplicationClicked(s)}
              selected={s.name === selectedApplication?.name}
            >
              <CardIconContainer item>
                <CardIcon src={s.pictogramUrl} />
              </CardIconContainer>
              <CardTitleContainer item>
                <h4>{s.name}</h4>
              </CardTitleContainer>
            </Application>
          ))}
        </Grid>
        <Grid spacing={2} item container md={4} xs={12}>
          <Grid item xs={12}>
            <ApplicationImage
              src={selectedApplication ? selectedApplication.imageUrl : DefaultImage}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedApplication?.name && (
              <Heading showCursor={false} text={selectedApplication.name} level={4}></Heading>
            )}
            <p>{selectedApplication?.description}</p>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplicationStep;
