/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserManagedPriceListDto,
    UserManagedPriceListDtoFromJSON,
    UserManagedPriceListDtoFromJSONTyped,
    UserManagedPriceListDtoToJSON,
} from './UserManagedPriceListDto';

/**
 * 
 * @export
 * @interface UserPriceListVm
 */
export interface UserPriceListVm {
    /**
     * 
     * @type {Array<UserManagedPriceListDto>}
     * @memberof UserPriceListVm
     */
    userPriceLists: Array<UserManagedPriceListDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UserPriceListVm
     */
    hasAccessToAllPriceLists: boolean;
}

export function UserPriceListVmFromJSON(json: any): UserPriceListVm {
    return UserPriceListVmFromJSONTyped(json, false);
}

export function UserPriceListVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPriceListVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userPriceLists': ((json['userPriceLists'] as Array<any>).map(UserManagedPriceListDtoFromJSON)),
        'hasAccessToAllPriceLists': json['hasAccessToAllPriceLists'],
    };
}

export function UserPriceListVmToJSON(value?: UserPriceListVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userPriceLists': ((value.userPriceLists as Array<any>).map(UserManagedPriceListDtoToJSON)),
        'hasAccessToAllPriceLists': value.hasAccessToAllPriceLists,
    };
}

