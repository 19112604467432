import { AppState } from "../../../../setup/appRootReducer";
import {
  ProductComparisonActions,
  ProductComparisonActionTypes
} from "../actions/ProductComparisonActions";
import { produce } from "immer";
import { maxElementInCompare } from "../containers/productComparisonView";
import {
  ApiProductComparatorCompareProductsGetRequest,
  ProductsNamesDto,
  ProductVersionDetailDto,
  ResultComparisonVm
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { productComparisonSagas } from "../sagas/productComparisonSagas";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { compareProductsLocalStorageKey } from "constants/productComparatorConstants";
import { getFromLSVersioned, saveToLSVersioned } from "utilities/localStorageUtils";

// Version 1: Add a version to data, cleaning old comparator products
const currentComparatorProductVersion = 1;

interface Requests {
  compareProducts: RequestState<ApiProductComparatorCompareProductsGetRequest>;
  getNames: RequestState;
}

export interface ProductComparisonState {
  requests: Requests;
  products: ProductVersionDetailDto[];
  productsNames: ProductsNamesDto[];
  productFeatureComparison: ResultComparisonVm | undefined;
}

const getComparatorProducts = (): ProductVersionDetailDto[] => {
  // If there is no version yet, clear the data in LS
  if (!getFromLSVersioned(compareProductsLocalStorageKey, currentComparatorProductVersion)) {
    window.localStorage.removeItem(compareProductsLocalStorageKey);
  }

  return window.localStorage.getItem(compareProductsLocalStorageKey)
    ? JSON.parse(window.localStorage.getItem(compareProductsLocalStorageKey) ?? "").data ?? ""
    : [];
};

const defaultState: ProductComparisonState = {
  requests: {
    compareProducts: createInitialRequest(),
    getNames: createInitialRequest()
  },
  products: getComparatorProducts(),
  productFeatureComparison: undefined,
  productsNames: []
};

export function productComparisonReducer(
  state: ProductComparisonState = defaultState,
  action: ProductComparisonActions
): ProductComparisonState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: productComparisonSagas.compareProducts.actionTypes,
      key: "compareProducts"
    },
    {
      actionTypes: productComparisonSagas.getProductsNames.actionTypes,
      key: "getProductsNames"
    }
  ]);
  if (productComparisonSagas.compareProducts.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.productFeatureComparison = action.payload; // todo to split the two results??
    });
  } else if (productComparisonSagas.getProductsNames.isRequestAction(action)) {
    state = produce(state, (draft) => {
      draft.productsNames = [];
    });
  } else if (productComparisonSagas.getProductsNames.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.productsNames = action.payload;
    });
  }
  switch (action.type) {
    case ProductComparisonActionTypes.AddToProductToCompare:
      state = produce(state, (draft) => {
        // if there are already maxElement elements I can not proceed
        if (
          draft.products.length < maxElementInCompare &&
          draft.products.every((p) => p.id !== action.product.id)
        ) {
          draft.products = [...draft.products, action.product];

          saveToLSVersioned(
            compareProductsLocalStorageKey,
            currentComparatorProductVersion,
            draft.products
          );
        }
      });
      break;
    case ProductComparisonActionTypes.RemoveToProductToCompare:
      state = produce(state, (draft) => {
        // TODO to change and save just the info needed
        draft.products = draft.products.filter((p) => p.id !== action.product.id);

        saveToLSVersioned(
          compareProductsLocalStorageKey,
          currentComparatorProductVersion,
          draft.products
        );
      });
      break;
  }
  return state;
}
export const getProducts = (state: AppState): ProductVersionDetailDto[] => {
  return state.productComparison.products;
};
export const getComparisonProducts = (state: AppState): ResultComparisonVm | undefined => {
  return state.productComparison.productFeatureComparison;
};
export const getComparisonProductsStatus = (
  state: AppState
): RequestState<ApiProductComparatorCompareProductsGetRequest> => {
  return state.productComparison.requests.compareProducts;
};
export const getProductsNames = (state: AppState): ProductsNamesDto[] => {
  return state.productComparison.productsNames;
};
