import { ArticleDto, NodeDto, NodeFlattenedDto } from "api";
import { Action } from "redux";
export enum TrainingActionType {
  SetVisibleArticle = "Training/SetVisibleArticle",
  SetNodeOpen = "Training/SetNodeOpen",
  SetNodeOpenFlat = "Training/SetNodeOpenFlat",
  SetNodeOnScreen = "Training/SetNodeOnScreen",
  SetSearchText = "Training/SetSearchText",
  SetLoadingResults = "Training/SetLoadingResults",
  SetPageNumber = "Training/SetPageNumber",
  SetPageSize = "Training/SetPageSize",
  SetForceScroll = "Training/SetForceScroll"
}

export interface SetVisibleArticle extends Action {
  type: TrainingActionType.SetVisibleArticle;
  article: ArticleDto | undefined;
}
export interface SetNodeOpen extends Action {
  type: TrainingActionType.SetNodeOpen;
  node: NodeDto | undefined;
}
export interface SetNodeOpenFlat extends Action {
  type: TrainingActionType.SetNodeOpenFlat;
  node: NodeFlattenedDto | undefined;
}
export interface SetNodeOnScreen extends Action {
  type: TrainingActionType.SetNodeOnScreen;
  id: string | undefined;
}
export interface SetSearchText extends Action {
  type: TrainingActionType.SetSearchText;
  search: string | undefined;
}
export interface SetLoadingResults extends Action {
  type: TrainingActionType.SetLoadingResults;
  loading: boolean | undefined;
}
export interface SetPageSize extends Action {
  type: TrainingActionType.SetPageSize;
  size: number;
}
export interface SetPageNumber extends Action {
  type: TrainingActionType.SetPageNumber;
  page: number;
}
export interface SetForceScroll extends Action {
  type: TrainingActionType.SetForceScroll;
  force: boolean;
}
export const NewTrainingActions = {
  setVisibleArticle: (article: ArticleDto | undefined): SetVisibleArticle => ({
    type: TrainingActionType.SetVisibleArticle,
    article
  }),
  setNodeOpen: (node: NodeDto | undefined): SetNodeOpen => ({
    type: TrainingActionType.SetNodeOpen,
    node
  }),
  setNodeOpenFlat: (node: NodeFlattenedDto | undefined): SetNodeOpenFlat => ({
    type: TrainingActionType.SetNodeOpenFlat,
    node
  }),
  setNodeOnScreen: (id: string | undefined): SetNodeOnScreen => ({
    type: TrainingActionType.SetNodeOnScreen,
    id
  }),
  setSearchText: (search: string | undefined): SetSearchText => ({
    type: TrainingActionType.SetSearchText,
    search
  }),
  setLoading: (loading: boolean | undefined): SetLoadingResults => ({
    type: TrainingActionType.SetLoadingResults,
    loading
  }),
  setPageSize: (size: number): SetPageSize => ({
    type: TrainingActionType.SetPageSize,
    size
  }),
  setPageNumber: (page: number): SetPageNumber => ({
    type: TrainingActionType.SetPageNumber,
    page
  }),
  setForceScroll: (force: boolean): SetForceScroll => ({
    type: TrainingActionType.SetForceScroll,
    force
  })
};

export type NewTrainingActions =
  | SetVisibleArticle
  | SetNodeOpen
  | SetNodeOpenFlat
  | SetNodeOnScreen
  | SetSearchText
  | SetLoadingResults
  | SetPageNumber
  | SetPageSize
  | SetForceScroll;
