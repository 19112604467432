/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemSettingDto
 */
export interface SystemSettingDto {
    /**
     * 
     * @type {string}
     * @memberof SystemSettingDto
     */
    frontPageContent?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    ordersUpdated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    orderItemsUpdated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    notificationsUpdated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    documentsUpdated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    companiesUpdated?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    overrideStatusAuditCompleted?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemSettingDto
     */
    overrideStatusAuditDueTo?: Date | null;
}

export function SystemSettingDtoFromJSON(json: any): SystemSettingDto {
    return SystemSettingDtoFromJSONTyped(json, false);
}

export function SystemSettingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemSettingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frontPageContent': !exists(json, 'frontPageContent') ? undefined : json['frontPageContent'],
        'ordersUpdated': !exists(json, 'ordersUpdated') ? undefined : (json['ordersUpdated'] === null ? null : new Date(json['ordersUpdated'])),
        'orderItemsUpdated': !exists(json, 'orderItemsUpdated') ? undefined : (json['orderItemsUpdated'] === null ? null : new Date(json['orderItemsUpdated'])),
        'notificationsUpdated': !exists(json, 'notificationsUpdated') ? undefined : (json['notificationsUpdated'] === null ? null : new Date(json['notificationsUpdated'])),
        'documentsUpdated': !exists(json, 'documentsUpdated') ? undefined : (json['documentsUpdated'] === null ? null : new Date(json['documentsUpdated'])),
        'companiesUpdated': !exists(json, 'companiesUpdated') ? undefined : (json['companiesUpdated'] === null ? null : new Date(json['companiesUpdated'])),
        'overrideStatusAuditCompleted': !exists(json, 'overrideStatusAuditCompleted') ? undefined : (json['overrideStatusAuditCompleted'] === null ? null : new Date(json['overrideStatusAuditCompleted'])),
        'overrideStatusAuditDueTo': !exists(json, 'overrideStatusAuditDueTo') ? undefined : (json['overrideStatusAuditDueTo'] === null ? null : new Date(json['overrideStatusAuditDueTo'])),
    };
}

export function SystemSettingDtoToJSON(value?: SystemSettingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frontPageContent': value.frontPageContent,
        'ordersUpdated': value.ordersUpdated === undefined ? undefined : (value.ordersUpdated === null ? null : value.ordersUpdated.toISOString()),
        'orderItemsUpdated': value.orderItemsUpdated === undefined ? undefined : (value.orderItemsUpdated === null ? null : value.orderItemsUpdated.toISOString()),
        'notificationsUpdated': value.notificationsUpdated === undefined ? undefined : (value.notificationsUpdated === null ? null : value.notificationsUpdated.toISOString()),
        'documentsUpdated': value.documentsUpdated === undefined ? undefined : (value.documentsUpdated === null ? null : value.documentsUpdated.toISOString()),
        'companiesUpdated': value.companiesUpdated === undefined ? undefined : (value.companiesUpdated === null ? null : value.companiesUpdated.toISOString()),
        'overrideStatusAuditCompleted': value.overrideStatusAuditCompleted === undefined ? undefined : (value.overrideStatusAuditCompleted === null ? null : value.overrideStatusAuditCompleted.toISOString()),
        'overrideStatusAuditDueTo': value.overrideStatusAuditDueTo === undefined ? undefined : (value.overrideStatusAuditDueTo === null ? null : value.overrideStatusAuditDueTo.toISOString()),
    };
}

