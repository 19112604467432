/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CategoriesMenuDto,
    CategoriesMenuDtoFromJSON,
    CategoriesMenuDtoToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    ExplorerApplicationDto,
    ExplorerApplicationDtoFromJSON,
    ExplorerApplicationDtoToJSON,
    FeatureDto,
    FeatureDtoFromJSON,
    FeatureDtoToJSON,
    ProductLifecycleStatus,
    ProductLifecycleStatusFromJSON,
    ProductLifecycleStatusToJSON,
    ProductSeriesExplorerDto,
    ProductSeriesExplorerDtoFromJSON,
    ProductSeriesExplorerDtoToJSON,
    ProductVersionDetailDto,
    ProductVersionDetailDtoFromJSON,
    ProductVersionDetailDtoToJSON,
    ProductsVm,
    ProductsVmFromJSON,
    ProductsVmToJSON,
    Standards,
    StandardsFromJSON,
    StandardsToJSON,
} from '../models';

export interface ApiProductExplorerProductIdGetRequest {
    id: number;
}

export interface ApiProductExplorerProductsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaApplicationId?: number;
    criteriaFunctionalityCodeList?: Array<string>;
    criteriaStandard?: Standards;
    criteriaProductSeriesId?: number;
    criteriaLastVersionOnly?: boolean;
    criteriaLifeCycleStatus?: ProductLifecycleStatus;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    criteriaQuerySortByColumn?: string;
}

export interface ApiProductExplorerSearchFeaturesGetRequest {
    query?: string;
}

/**
 * 
 */
export class ProductExplorerApi extends runtime.BaseAPI {

    /**
     */
    async apiProductExplorerApplicationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExplorerApplicationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/applications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExplorerApplicationDtoFromJSON));
    }

    /**
     */
    async apiProductExplorerApplicationsGet(initOverrides?: RequestInit): Promise<Array<ExplorerApplicationDto>> {
        const response = await this.apiProductExplorerApplicationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductExplorerMenuFeatureGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CategoriesMenuDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/menu-feature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoriesMenuDtoFromJSON));
    }

    /**
     */
    async apiProductExplorerMenuFeatureGet(initOverrides?: RequestInit): Promise<Array<CategoriesMenuDto>> {
        const response = await this.apiProductExplorerMenuFeatureGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductExplorerProductIdGetRaw(requestParameters: ApiProductExplorerProductIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductVersionDetailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiProductExplorerProductIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/product/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductVersionDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiProductExplorerProductIdGet(requestParameters: ApiProductExplorerProductIdGetRequest, initOverrides?: RequestInit): Promise<ProductVersionDetailDto> {
        const response = await this.apiProductExplorerProductIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductExplorerProductsGetRaw(requestParameters: ApiProductExplorerProductsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiProductExplorerProductsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiProductExplorerProductsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaApplicationId !== undefined) {
            queryParameters['Criteria.ApplicationId'] = requestParameters.criteriaApplicationId;
        }

        if (requestParameters.criteriaFunctionalityCodeList) {
            queryParameters['Criteria.FunctionalityCodeList'] = requestParameters.criteriaFunctionalityCodeList;
        }

        if (requestParameters.criteriaStandard !== undefined) {
            queryParameters['Criteria.Standard'] = requestParameters.criteriaStandard;
        }

        if (requestParameters.criteriaProductSeriesId !== undefined) {
            queryParameters['Criteria.ProductSeriesId'] = requestParameters.criteriaProductSeriesId;
        }

        if (requestParameters.criteriaLastVersionOnly !== undefined) {
            queryParameters['Criteria.LastVersionOnly'] = requestParameters.criteriaLastVersionOnly;
        }

        if (requestParameters.criteriaLifeCycleStatus !== undefined) {
            queryParameters['Criteria.LifeCycleStatus'] = requestParameters.criteriaLifeCycleStatus;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiProductExplorerProductsGet(requestParameters: ApiProductExplorerProductsGetRequest, initOverrides?: RequestInit): Promise<ProductsVm> {
        const response = await this.apiProductExplorerProductsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductExplorerSearchFeaturesGetRaw(requestParameters: ApiProductExplorerSearchFeaturesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FeatureDto>>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/search-features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureDtoFromJSON));
    }

    /**
     */
    async apiProductExplorerSearchFeaturesGet(requestParameters: ApiProductExplorerSearchFeaturesGetRequest, initOverrides?: RequestInit): Promise<Array<FeatureDto>> {
        const response = await this.apiProductExplorerSearchFeaturesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductExplorerSeriesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProductSeriesExplorerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductExplorer/series`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductSeriesExplorerDtoFromJSON));
    }

    /**
     */
    async apiProductExplorerSeriesGet(initOverrides?: RequestInit): Promise<Array<ProductSeriesExplorerDto>> {
        const response = await this.apiProductExplorerSeriesGetRaw(initOverrides);
        return await response.value();
    }

}
