import { combineReducers } from "redux";
import {
  DeliveriesHomeViewState,
  deliveriesHomeViewReducer
} from "../deliveriesHome/reducers/deliveriesHomeViewReducer";
import { OrdersViewState, ordersViewReducer } from "../orders/reducers/ordersViewReducer";
import {
  OrdersDetailsViewState,
  ordersDetailsViewReducer
} from "../orders/reducers/orderDetailsViewReducer";
import {
  ProductInformationViewState,
  productInformationViewReducer
} from "../productInformation/reducers/productInformationViewReducer";
import {
  NotificationsViewState,
  notificationsViewReducer
} from "../notifications/reducers/notificationsViewReducer";

export interface DeliveriesState {
  deliveriesHomeView: DeliveriesHomeViewState;
  ordersView: OrdersViewState;
  orderDetailsView: OrdersDetailsViewState;
  productInformationView: ProductInformationViewState;
  notificationsView: NotificationsViewState;
}

// Combine the reducers used here
export const deliveriesRootReducer = combineReducers({
  deliveriesHomeView: deliveriesHomeViewReducer,
  ordersView: ordersViewReducer,
  orderDetailsView: ordersDetailsViewReducer,
  productInformationView: productInformationViewReducer,
  notificationsView: notificationsViewReducer
});
