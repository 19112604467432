/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GlobalSettingTypes {
    Disclaimer = 'Disclaimer',
    SelectorProductsNotInConfigurator = 'SelectorProductsNotInConfigurator',
    DeliveryTimesText = 'DeliveryTimesText',
    FirmwareUpdatesSubheading = 'FirmwareUpdatesSubheading'
}

export function GlobalSettingTypesFromJSON(json: any): GlobalSettingTypes {
    return GlobalSettingTypesFromJSONTyped(json, false);
}

export function GlobalSettingTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GlobalSettingTypes {
    return json as GlobalSettingTypes;
}

export function GlobalSettingTypesToJSON(value?: GlobalSettingTypes | null): any {
    return value as any;
}

