/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestAssigneeUserDto,
    AccessRequestAssigneeUserDtoFromJSON,
    AccessRequestAssigneeUserDtoFromJSONTyped,
    AccessRequestAssigneeUserDtoToJSON,
} from './AccessRequestAssigneeUserDto';

/**
 * 
 * @export
 * @interface AccessRequestAssigneeDto
 */
export interface AccessRequestAssigneeDto {
    /**
     * 
     * @type {number}
     * @memberof AccessRequestAssigneeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestAssigneeDto
     */
    email?: string | null;
    /**
     * 
     * @type {AccessRequestAssigneeUserDto}
     * @memberof AccessRequestAssigneeDto
     */
    user?: AccessRequestAssigneeUserDto;
}

export function AccessRequestAssigneeDtoFromJSON(json: any): AccessRequestAssigneeDto {
    return AccessRequestAssigneeDtoFromJSONTyped(json, false);
}

export function AccessRequestAssigneeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestAssigneeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'user': !exists(json, 'user') ? undefined : AccessRequestAssigneeUserDtoFromJSON(json['user']),
    };
}

export function AccessRequestAssigneeDtoToJSON(value?: AccessRequestAssigneeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'user': AccessRequestAssigneeUserDtoToJSON(value.user),
    };
}

