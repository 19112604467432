/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderItemsCriteria,
    OrderItemsCriteriaFromJSON,
    OrderItemsCriteriaFromJSONTyped,
    OrderItemsCriteriaToJSON,
} from './OrderItemsCriteria';

/**
 * 
 * @export
 * @interface GetOrderItemsQuery
 */
export interface GetOrderItemsQuery {
    /**
     * 
     * @type {OrderItemsCriteria}
     * @memberof GetOrderItemsQuery
     */
    criteria?: OrderItemsCriteria;
}

export function GetOrderItemsQueryFromJSON(json: any): GetOrderItemsQuery {
    return GetOrderItemsQueryFromJSONTyped(json, false);
}

export function GetOrderItemsQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrderItemsQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': !exists(json, 'criteria') ? undefined : OrderItemsCriteriaFromJSON(json['criteria']),
    };
}

export function GetOrderItemsQueryToJSON(value?: GetOrderItemsQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': OrderItemsCriteriaToJSON(value.criteria),
    };
}

