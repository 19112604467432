import { ApiCompaniesGetRequest, CompaniesApi } from "api/apis/CompaniesApi";
import { MenuApi } from "api/apis/MenuApi";
import { SystemSettingsApi } from "api/apis/SystemSettingsApi";
import { UsersApi } from "api/apis/UsersApi";
import { CompanyDto } from "api/models/CompanyDto";
import { GeneralApplicationSettingsDto } from "api/models/GeneralApplicationSettingsDto";
import { MenuBarVm } from "api/models/MenuBarVm";
import { UserVm } from "api/models/UserVm";
import { Configuration } from "api/runtime";
import { useEffect, useState } from "react";
import { NavMenuCompany } from "../components/NavItem";
import { AccessRights } from "api/models/AccessRights";
import { RoleGroups } from "api/models/RoleGroups";
import { SelectedCompanyUpdatedDto } from "api/models/SelectedCompanyUpdatedDto";
import { usePreMiddleware } from "shared/usePreMiddleware";
import { CompaniesVm } from "api/models/CompaniesVm";

interface UserCompanies {
  userCompanies: CompanyDto[];
  currentUser: UserVm | undefined;
  applicationSettings: GeneralApplicationSettingsDto | undefined;
  menuVm: MenuBarVm | undefined;
  userCompanySelection: CompanyDto | undefined;
  handleLoadUserCompanies: (
    reset: boolean,
    input?: string,
    isDefault?: boolean,
    resetDefault?: boolean
  ) => void;
  updateUserSelectedCompany: (
    company: NavMenuCompany
  ) => Promise<SelectedCompanyUpdatedDto | undefined>;
  updateMenuItems: () => void;
  updateCurrentUser: () => void;
  isLoadingCompanies: boolean;
}

export interface PromiseWithCancel<T> extends Promise<T> {
  cancel: () => void;
}

// This might not be valid for long. We might need to make this selection dynamic.
const orderDropdownAccessRights: AccessRights[] = [
  AccessRights.AdvancedOrderTracking,
  AccessRights.ViewCompanyOrders,
  AccessRights.ViewAllOrders,
  AccessRights.ViewCompanyPriceLists
];

const defaultCriteria: ApiCompaniesGetRequest = {
  criteriaPage: 0,
  criteriaPageSize: 20,
  criteriaSortColumn: "companyDisplayName",
  criteriaSkipTotalRowCount: true,
  criteriaIsAscendingOrder: true,
  criteriaRequireAnyOfAccessRight: orderDropdownAccessRights
};

export const useRelaysOnlineMenuBar = (
  accessToken: string | undefined,
  basePath: string,
  roleGroup?: RoleGroups,
  debug?: boolean,
  refreshToken?: () => void
): UserCompanies => {
  const preMiddleware = usePreMiddleware(accessToken, refreshToken, debug);
  // Api
  const companiesApi = new CompaniesApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  );

  const userApi = new UsersApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  );

  const systemSettingsApi = new SystemSettingsApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  );

  const menuApi = new MenuApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  );

  const getCompaniesWithCancel = (
    criteria: ApiCompaniesGetRequest
  ): PromiseWithCancel<CompaniesVm> => {
    const controller = new AbortController();
    const promise = companiesApi.apiCompaniesGet(criteria, {
      signal: controller.signal
    });

    (promise as PromiseWithCancel<CompaniesVm>).cancel = () => {
      controller.abort();
    };

    return promise as PromiseWithCancel<CompaniesVm>;
  };

  // State
  const [companiesList, setCompaniesList] = useState<CompanyDto[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);

  const [companiesCriteria, setCompaniesCriteria] =
    useState<ApiCompaniesGetRequest>(defaultCriteria);
  const [loggedInUser, setLoggedInUser] = useState<UserVm | undefined>(undefined);
  const [systemSettings, setSystemSettings] = useState<GeneralApplicationSettingsDto | undefined>(
    undefined
  );

  const [userCompanySelection, setUserCompanySelection] = useState<CompanyDto | undefined>(
    undefined
  );

  const [defaultCompaniesList, setDefaultCompaniesList] = useState<CompanyDto[] | undefined>(
    undefined
  );

  const [menuVm, setMenuVm] = useState<MenuBarVm | undefined>(undefined);

  const [companiesQuery, setCompaniesQuery] = useState<PromiseWithCancel<CompaniesVm> | undefined>(
    undefined
  );

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  const isAbortError = (e: any) => {
    if (e && e.name === "AbortError") {
      return true;
    }
    return false;
  };
  const handleError = (e: any) => {
    console.error(e);
  };

  // API calls
  const handleLoadCompanies = (
    reset: boolean,
    input?: string,
    isDefault?: boolean,
    resetDefault?: boolean
  ) => {
    if (!companiesCriteria) {
      return;
    }
    let criteria: ApiCompaniesGetRequest = defaultCriteria;

    if (isDefault && defaultCompaniesList) {
      setCompaniesList([...defaultCompaniesList]);
      setCompaniesCriteria({
        ...defaultCriteria,
        criteriaRoleGroup: roleGroup,
        criteriaDisplayName: input
      });
      setIsLastPage(false);
      return;
    }

    if (reset) {
      setIsLoadingCompanies(true);
      setCompaniesList([]);
      criteria = {
        ...defaultCriteria,
        criteriaRoleGroup: roleGroup,
        criteriaDisplayName: input
      };

      const companyQuery = getCompaniesWithCancel(criteria);

      if (companiesQuery) {
        companiesQuery.cancel();
      }

      setCompaniesQuery(companyQuery);

      companyQuery
        .then((res) => {
          setCompaniesList(res.companies);
          setIsLoadingCompanies(false);
          setIsLastPage(res.companies.length < defaultCriteria.criteriaPageSize);
          if (!defaultCompaniesList || resetDefault) {
            setDefaultCompaniesList(res.companies);
          }
        })
        .catch((e: any) => {
          if (!isAbortError(e)) {
            setIsLoadingCompanies(false);
            handleError(e);
          }
        });
      setCompaniesCriteria(criteria);
    } else {
      if (!isLastPage) {
        criteria = {
          ...companiesCriteria,
          criteriaPage: companiesCriteria.criteriaPage + 1,
          criteriaRoleGroup: roleGroup,
          criteriaDisplayName: companiesCriteria.criteriaDisplayName
        };
        setIsLoadingCompanies(true);
        const companyQuery = getCompaniesWithCancel(criteria);
        setCompaniesQuery(companyQuery);

        companyQuery
          .then((res) => {
            setIsLoadingCompanies(false);
            setCompaniesList((companiesList) => companiesList.concat(res.companies));
            setIsLastPage(res.companies.length < defaultCriteria.criteriaPageSize);
          })
          .catch((e: any) => {
            if (!isAbortError(e)) {
              setIsLoadingCompanies(false);
              handleError(e);
            }
          });
        setCompaniesCriteria(criteria);
      }
    }
  };

  const updateUserSelectedCompany = async (company: NavMenuCompany) => {
    try {
      const res = await userApi.apiUsersSelectedCompanyPatch({
        updateUserSelectedCompanyCommand: {
          selectedCompanyId: company.value ?? null
        }
      });
      setUserCompanySelection(res.company);
      return res;
    } catch (e) {
      updateCurrentUser();
      handleError(e);
    }
  };

  useEffect(() => {
    if (accessToken) {
      handleLoadCompanies(true);
    }
    // eslint-disable-next-line
  }, [roleGroup, accessToken]);

  const updateCurrentUser = () => {
    if (accessToken && userApi) {
      userApi
        .apiUsersCurrentGet()
        .then((res) => {
          setLoggedInUser(res);
          setUserCompanySelection(res?.userDetails?.selectedCompany);
        })
        .catch(handleError);
    }
  };
  const updateMenuItems = () => {
    if (menuApi) {
      menuApi
        .apiMenuGet()
        .then((res) => {
          setMenuVm(res);
        })
        .catch(handleError);
    }
  };

  useEffect(() => {
    updateCurrentUser();
    updateMenuItems();
    // eslint-disable-next-line
  }, [accessToken]);

  useEffect(() => {
    if (systemSettingsApi) {
      systemSettingsApi
        .apiSystemSettingsGet()
        .then((res) => {
          setSystemSettings(res.generalApplicationSettings);
        })
        .catch(handleError);
    }
    // eslint-disable-next-line
  }, []);

  return {
    userCompanies: companiesList,
    handleLoadUserCompanies: handleLoadCompanies,
    currentUser: loggedInUser,
    applicationSettings: systemSettings,
    updateUserSelectedCompany: updateUserSelectedCompany,
    menuVm,
    userCompanySelection,
    updateMenuItems,
    updateCurrentUser,
    isLoadingCompanies
  };
};
