import { Heading } from "@abb/abb-common-ux-react";
import Grid from "@mui/material/Grid";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { Card } from "./common/Card";
import { Box } from "@mui/system";
import { Button } from "framework/components/Button";
import { SelectorSegmentDto } from "api";
import { SubSegment } from "./common/SubSegment";
import DefaultImage from "assets/selector_general.jpg";
import { SelectorSubSegmentDto } from "api";

const CardIconContainer = styled(Grid)`
  margin-right: ${(props) => props.theme.sizes.m};
`;

const CardIcon = styled.img`
  margin: auto;
  width: ${(props) => props.theme.sizes.l};
`;

const CardTitleContainer = styled(Grid)`
  flex-grow: 3;
  padding: ${(props) => props.theme.sizes.s};
`;

const Segment = styled(Card)`
  margin-top: ${(props) => props.theme.sizes.xs};
  margin-bottom: ${(props) => props.theme.sizes.xs};
  padding: ${(props) => props.theme.sizes.m};
`;

const SegmentImage = styled.img`
  width: 100%;
`;

interface SegmentStepProps {
  segments: SelectorSegmentDto[];
  subSegments: SubSegment[];
  selectedSubSegment?: SubSegment;
  selectedSegment?: SelectorSegmentDto;
  onSegmentSelected: (segment?: SelectorSegmentDto, subSegment?: SelectorSubSegmentDto) => void;
}

const SegmentStep = ({
  selectedSubSegment,
  segments,
  subSegments: allSubSegments,
  onSegmentSelected,
  selectedSegment
}: SegmentStepProps): JSX.Element => {
  const { t } = useTranslation();

  const filteredSubSegments = useMemo(() => {
    let subSegments: SubSegment[] = [];
    if (selectedSegment && selectedSegment.selectorSubSegments)
      subSegments = allSubSegments.filter((s) => s.selectorSegmentId === selectedSegment.id);
    else {
      if (allSubSegments) subSegments = allSubSegments;
    }

    return subSegments.sort((a, b) => a.id - b.id);
  }, [selectedSegment, allSubSegments]);

  const onSubSegmentClick = (subSegment?: SubSegment) => {
    onSegmentSelected(selectedSegment, subSegment);
  };

  const segmentTypeButton = (segment: SelectorSegmentDto) => {
    const buttonType =
      segment && segment.id === selectedSegment?.id ? "primary-black" : "secondary";
    return (
      <Button
        onClick={() => onSegmentSelected(segment, undefined)}
        text={segment.name as string}
        buttonType={buttonType}
      />
    );
  };

  const segmentsMidIndex = filteredSubSegments ? Math.ceil(filteredSubSegments.length / 2) : 0;

  return (
    <Box minHeight={650}>
      <Grid
        mb={commonUXTheme.sizes.l}
        mt={commonUXTheme.sizes.l}
        container
        spacing={2}
        justifyContent={"center"}
      >
        <Grid item>
          <Button
            onClick={() => onSegmentSelected(undefined, undefined)}
            text={t("All Segments")}
            buttonType={selectedSegment?.id ? "secondary" : "primary-black"}
          />
        </Grid>
        {segments.map(
          (s) =>
            s.name && (
              <Grid key={s.id} item>
                {segmentTypeButton(s)}
              </Grid>
            )
        )}
      </Grid>
      <Grid container spacing={{ xs: 0, md: 2 }}>
        <Grid pt={0} item md={4} xs={12}>
          {filteredSubSegments?.slice(0, segmentsMidIndex).map((s) => (
            <Segment
              key={s.name}
              container
              onClick={() => onSubSegmentClick(s)}
              selected={s.id === selectedSubSegment?.id}
            >
              <CardIconContainer item>
                <CardIcon src={s.pictogramUrl} />
              </CardIconContainer>
              <CardTitleContainer item>
                <h4>{s.name}</h4>
              </CardTitleContainer>
            </Segment>
          ))}
        </Grid>
        <Grid pt={0} item md={4} xs={12}>
          {filteredSubSegments?.slice(segmentsMidIndex, filteredSubSegments?.length).map((s) => (
            <Segment
              key={s.name}
              container
              onClick={() => onSubSegmentClick(s)}
              selected={s.id === selectedSubSegment?.id}
            >
              <CardIconContainer item>
                <CardIcon src={s.pictogramUrl} />
              </CardIconContainer>
              <CardTitleContainer item>
                <h4>{s.name}</h4>
              </CardTitleContainer>
            </Segment>
          ))}
        </Grid>
        <Grid spacing={2} item md={4} xs={12} container height={"max-content"}>
          <Grid
            item
            md={12}
            minHeight={200}
            xs={6}
            mt={{ xs: commonUXTheme.sizes.m, sm: commonUXTheme.sizes.xs }}
          >
            <SegmentImage src={selectedSubSegment ? selectedSubSegment?.imageUrl : DefaultImage} />
          </Grid>
          <Grid item md={12} xs={6}>
            {selectedSubSegment?.name && (
              <Heading showCursor={false} text={selectedSubSegment.name} level={4}></Heading>
            )}
            <p>{selectedSubSegment?.description}</p>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SegmentStep;
