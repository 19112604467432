/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderSearchStatus {
    All = 'All',
    Open = 'Open',
    Dispatched = 'Dispatched'
}

export function OrderSearchStatusFromJSON(json: any): OrderSearchStatus {
    return OrderSearchStatusFromJSONTyped(json, false);
}

export function OrderSearchStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderSearchStatus {
    return json as OrderSearchStatus;
}

export function OrderSearchStatusToJSON(value?: OrderSearchStatus | null): any {
    return value as any;
}

