/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SalesChannelTypes {
    Distributor = 'Distributor',
    EndUser = 'EndUser',
    Epc = 'Epc',
    Oem = 'Oem',
    SystemIntegrator = 'SystemIntegrator',
    Other = '_other',
    Inst = 'Inst',
    Pbl = 'Pbl',
    Con = 'Con',
    AbbInt = 'AbbInt'
}

export function SalesChannelTypesFromJSON(json: any): SalesChannelTypes {
    return SalesChannelTypesFromJSONTyped(json, false);
}

export function SalesChannelTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesChannelTypes {
    return json as SalesChannelTypes;
}

export function SalesChannelTypesToJSON(value?: SalesChannelTypes | null): any {
    return value as any;
}

