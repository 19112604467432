import { Action } from "redux";
import { AppState } from "../../../setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "../../../framework/state/requestState";
import { modificationSaleSagas } from "../sagas/modificationSaleSagas";

interface Requests {
  createGmdModificationCase: RequestState<string | undefined>;
}

export interface ModificationSaleState {
  requests: Requests;
}

const defaultState: ModificationSaleState = {
  requests: {
    createGmdModificationCase: createInitialRequest()
  }
};

export function modificationSaleReducer(
  state: ModificationSaleState = defaultState,
  action: Action
): ModificationSaleState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: modificationSaleSagas.createGmdModificationCase.actionTypes,
      key: "createGmdModificationCase"
    }
  ]);

  return state;
}

export const createGmdModificationCaseRequest = (
  state: AppState
): RequestState<string | undefined> => state.modificationSale.requests.createGmdModificationCase;
