/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfiguratorProductDetails,
    ConfiguratorProductDetailsFromJSON,
    ConfiguratorProductDetailsToJSON,
    ProductDescription,
    ProductDescriptionFromJSON,
    ProductDescriptionToJSON,
} from '../models';

export interface ApiProductsDescriptionGetRequest {
    materialCode: string;
}

export interface ApiProductsDetailsGetRequest {
    orderingCode?: string;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async apiProductsDescriptionGetRaw(requestParameters: ApiProductsDescriptionGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProductDescription>> {
        if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
            throw new runtime.RequiredError('materialCode','Required parameter requestParameters.materialCode was null or undefined when calling apiProductsDescriptionGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.materialCode !== undefined) {
            queryParameters['MaterialCode'] = requestParameters.materialCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Products/Description`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDescriptionFromJSON(jsonValue));
    }

    /**
     */
    async apiProductsDescriptionGet(requestParameters: ApiProductsDescriptionGetRequest, initOverrides?: RequestInit): Promise<ProductDescription> {
        const response = await this.apiProductsDescriptionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductsDetailsGetRaw(requestParameters: ApiProductsDetailsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfiguratorProductDetails>> {
        const queryParameters: any = {};

        if (requestParameters.orderingCode !== undefined) {
            queryParameters['orderingCode'] = requestParameters.orderingCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Products/Details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfiguratorProductDetailsFromJSON(jsonValue));
    }

    /**
     */
    async apiProductsDetailsGet(requestParameters: ApiProductsDetailsGetRequest, initOverrides?: RequestInit): Promise<ConfiguratorProductDetails> {
        const response = await this.apiProductsDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
