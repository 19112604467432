import React, { FC } from "react";
import styled from "styled-components";
import { NavItem } from "./NavItem";
import { MenuItemSelection } from "models/menuItemSelection";
import { IconSuperscriptArea } from "./StyledComponents";

interface ShoppingCartAreaProps {
  activePath?: string;
  useExternalLinks?: boolean;
  menuItem: MenuItemSelection[];
  itemsCount: number | null | undefined;
  baseUrl: string;
  onClickNavigationItem?: (internalRoute: string, externalRoute?: string | null) => void;
  translateText?: (text: string) => string;
  isMobile: boolean;
  enableReactRouterLinks: boolean;
}

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const IconArea: FC<ShoppingCartAreaProps> = ({
  activePath,
  useExternalLinks,
  menuItem,
  itemsCount,
  baseUrl,
  onClickNavigationItem,
  isMobile,
  enableReactRouterLinks
}: ShoppingCartAreaProps) => {
  return (
    <>
      {menuItem.length > 0 ? (
        <Container>
          <NavItem
            isMobile={isMobile}
            onSelect={(internalRoute: string, externalRoute: string | null | undefined) => {
              onClickNavigationItem && onClickNavigationItem(internalRoute, externalRoute);
            }}
            activePath={activePath}
            baseUrl={baseUrl}
            useExternalLinks={useExternalLinks}
            menuItem={menuItem[0]}
            enableReactRouterLinks={enableReactRouterLinks}
          />
          {itemsCount && itemsCount > 0 ? (
            <IconSuperscriptArea $isMobile={isMobile}>
              {itemsCount > 99 ? "99+" : itemsCount}
            </IconSuperscriptArea>
          ) : null}
        </Container>
      ) : null}
    </>
  );
};
