import { Action } from "redux";

export enum TranslationActionTypes {
  UploadTranslationFile = "Translation/UploadTranslationFile",
  GetAvailableLanguages = "Translation/GetAvailableLanguages",
  SetImportErrors = "Translation/SetImportErrors"
}

export interface UploadTranslationFile extends Action {
  type: TranslationActionTypes.UploadTranslationFile;
  file: File | null;
}

export interface GetAvailableLanguages extends Action {
  type: TranslationActionTypes.GetAvailableLanguages;
}

export interface SetImportErrors extends Action {
  type: TranslationActionTypes.SetImportErrors;
  errors: string[] | null;
}

export const translationActions = {
  uploadTranslationFile: (file: File | null): UploadTranslationFile => ({
    type: TranslationActionTypes.UploadTranslationFile,
    file: file
  }),
  getAvailableLanguages: (): GetAvailableLanguages => ({
    type: TranslationActionTypes.GetAvailableLanguages
  }),
  setImportErrors: (errors: string[]): SetImportErrors => ({
    type: TranslationActionTypes.SetImportErrors,
    errors
  })
};

export type TranslationActions = UploadTranslationFile | GetAvailableLanguages | SetImportErrors;
