import { produce } from "immer";
import { QuoteViewActions } from "./../actions/quoteViewActions";
import {
  TermsAndConditionsExternalInfoDto,
  ApiTermsAndConditionsQuotesQuoteIdGetRequest
} from "api";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { AppState } from "setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { manageQuotesSagas } from "../sagas/ManageQuotesSagas";

interface Requests {
  getQuoteTermsAndCondition: RequestState<
    TermsAndConditionsExternalInfoDto,
    ApiTermsAndConditionsQuotesQuoteIdGetRequest
  >;
}

export interface QuoteViewState {
  requests: Requests;
  termsAndCondition?: TermsAndConditionsExternalInfoDto;
}

const defaultState: QuoteViewState = {
  requests: {
    getQuoteTermsAndCondition: createInitialRequest()
  }
};

export function quoteViewReducer(
  state: QuoteViewState = defaultState,
  action: QuoteViewActions
): QuoteViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageQuotesSagas.getQuoteTermsAndCondition.actionTypes,
      key: "getQuoteTermsAndCondition"
    }
  ]);
  if (manageQuotesSagas.getQuoteTermsAndCondition.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.termsAndCondition = action.payload;
    });
  } else if (manageQuotesSagas.getQuoteTermsAndCondition.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.termsAndCondition = undefined;
    });
  }
  return state;
}

export const getTermsAndConditions = (
  state: AppState
): TermsAndConditionsExternalInfoDto | undefined => state.quote.termsAndCondition;
