import React, { useState, useEffect } from "react";
import { Input, Checkbox, Link, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { Button } from "framework/components/Button";
import { Dialog } from "framework/components/Dialog";
import { useTranslation } from "react-i18next";
import { FormikProps, withFormik } from "formik";
import { Grid } from "@mui/material";
import { InputLabel } from "framework/components/InputLabel";
import * as Yup from "yup";
import { commonUXTheme } from "styles/commonUXVariables";
import { getApiRegistry } from "framework/state/apiRegistry";
import { CountryDto } from "api";
import { Stack } from "@mui/system";
import { ResizableInput } from "framework/components/styled/ResizableInput";

export const FormRow = styled(Grid)(({ theme }) => ({
  minWidth: "250px",
  marginBottom: theme.sizes.l
}));

export const StyledCheckbox = styled(Checkbox)({
  marginLeft: 0
});

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  overflow: "auto",
  maxHeight: `calc(100vh - ${theme.sizes.lm})`
}));

interface ConnectWithUsFormProps {
  formData: ConnectWithUsDetails;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onFormSubmit: (values: ConnectWithUsDetails) => void;
}

export interface ConnectWithUsDetails {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  businessType: string;
  company: string;
  industry: string;
  additionalInfo: string;
  consent: boolean;
}

const ConnectWithUsSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required").email(),
  country: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  businessType: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  industry: Yup.string().required("Required"),
  additionalInfo: Yup.string().required("Required")
});

export const ConnectWithUsForm: React.FunctionComponent<
  ConnectWithUsFormProps & FormikProps<ConnectWithUsDetails>
> = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryDto[]>([]);

  const industries = [
    { key: "BLD.8", value: "Residential Buildings" },
    { key: "BLD.9", value: "Commercial / Institutional & Industrial Buildings" },
    { key: "IND.1", value: "Automotive" },
    { key: "IND.10", value: "Mining and Minerals" },
    { key: "IND.11", value: "Cement and Glass" },
    { key: "IND.12", value: "Oil and Gas Industry" },
    { key: "IND.13", value: "Pharmaceutical and Cosmetics" },
    { key: "IND.14", value: "Pulp and Paper" },
    { key: "IND.15", value: "Rubber and Plastics Products" },
    { key: "IND.16", value: "Other Industries" },
    { key: "IND.17", value: "Agriculture and Forestry" },
    { key: "IND.18", value: "Data Centers" },
    { key: "IND.19", value: "Critical Power" },
    { key: "IND.2", value: "Chemical" },
    { key: "IND.3", value: "Petrochemicals" },
    { key: "IND.4", value: "Food and Beverage" },
    { key: "IND.5", value: "Printing" },
    { key: "IND.6", value: "Textile" },
    { key: "IND.7", value: "Metals" },
    { key: "IND.8", value: "Electronics and Semiconductors (3C)" },
    { key: "TRA.1", value: "Marine" },
    { key: "TRA.2", value: "Railway" },
    { key: "TRA.3", value: "Air Transportation" },
    { key: "TRA.4", value: "Cranes" },
    { key: "TRA.5", value: "Land Transport Infrastructure" },
    { key: "TRA.6", value: "eMobility" },
    { key: "TRA.7", value: "Logistics" },
    { key: "UT.1", value: "Electric Utility - Power Generation" },
    { key: "UT.2", value: "Electric Distribution (<60 kV)" },
    { key: "UT.3", value: "Electric Transmission (>170 kV)" },
    { key: "UT.4", value: "Water and Wastewater" },
    { key: "UT.5", value: "Gas Utilities" },
    { key: "UT.7", value: "Other Utilities" },
    { key: "UT.8", value: "Electric Sub Transmission (60-170 kV)" },
    { key: "UT.9", value: "Waste and Recycling utility" },
    { key: "XY.1", value: "End Usage Unknown" }
  ];

  const businessPartnerChanelTypes = [
    "Analyst",
    "Consultant",
    "Developer",
    "Direct Sales to End Customer / End User",
    "Distributor",
    "Engineering, Procurement and Construction",
    "Government / Military",
    "Installer",
    "Investor",
    "Media",
    "No current relation with ABB",
    "Original Equipment Manufacturer",
    "Panel Builder",
    "System Integrator",
    "Vendor"
  ];

  useEffect(() => {
    getApiRegistry()
      .countriesApi.apiCountriesGet({})
      .then((countries) => {
        setCountries(countries);
      });
  }, []);

  return (
    <StyledDialog isOpen={props.isOpen} onClose={props.onClose} title={t("Contact us")}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <FormRow>
            <InputLabel label={t("First name")} isRequired />
            <Input
              value={props.values.firstName}
              onValueChange={(value) => {
                props.setFieldValue("firstName", value);
              }}
              dataType="text"
            />
          </FormRow>
          <FormRow>
            <InputLabel label={t("Last name")} isRequired />
            <Input
              value={props.values.lastName}
              onValueChange={(value) => {
                props.setFieldValue("lastName", value);
              }}
              dataType="text"
            />
          </FormRow>
          <FormRow>
            <InputLabel label={t("Email")} isRequired />
            <Input
              value={props.values.email}
              onValueChange={(value) => {
                props.setFieldValue("email", value);
              }}
              dataType="email"
            />
          </FormRow>
          <FormRow>
            <InputLabel label={t("Country")} isRequired />
            <Dropdown
              required
              monochrome
              searchable
              clearable
              value={[
                {
                  value: props.values.country,
                  label: countries.find((c) => c?.name === props.values.country)?.name ?? ""
                }
              ]}
              onChange={(
                selection: Array<{
                  value: any;
                  label: string;
                  isNew: boolean;
                }>
              ) => {
                props.setFieldValue("country", selection[0]?.label);
              }}
            >
              {countries.map((country) => (
                <DropdownOption key={country.code} value={country.code} label={country.name} />
              ))}
            </Dropdown>
          </FormRow>
          <FormRow>
            <InputLabel label={t("City")} isRequired />
            <Input
              value={props.values.city}
              onValueChange={(value) => {
                props.setFieldValue("city", value);
              }}
              dataType="text"
            />
          </FormRow>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormRow>
            <InputLabel label={t("Business type in relation to ABB")} isRequired />
            <Dropdown
              required
              monochrome
              searchable
              clearable
              value={[
                {
                  value: props.values.businessType,
                  label: props.values.businessType
                }
              ]}
              onChange={(
                selection: Array<{
                  value: any;
                  label: string;
                  isNew: boolean;
                }>
              ) => {
                props.setFieldValue("businessType", selection[0]?.value);
              }}
            >
              {businessPartnerChanelTypes.map((businessType) => (
                <DropdownOption key={businessType} value={businessType} label={businessType} />
              ))}
            </Dropdown>
          </FormRow>
          <FormRow>
            <InputLabel label={t("Company")} isRequired />
            <Input
              value={props.values.company}
              onValueChange={(value) => {
                props.setFieldValue("company", value);
              }}
              dataType="text"
            />
          </FormRow>
          <FormRow>
            <InputLabel label={t("Industry")} isRequired />
            <Dropdown
              required
              monochrome
              searchable
              clearable
              value={[
                {
                  value: props.values.industry,
                  label: industries.find((c) => c?.key === props.values.industry)?.value ?? ""
                }
              ]}
              onChange={(
                selection: Array<{
                  value: any;
                  label: string;
                  isNew: boolean;
                }>
              ) => {
                props.setFieldValue("industry", selection[0]?.value);
              }}
            >
              {industries.map((industry) => (
                <DropdownOption key={industry.key} value={industry.key} label={industry.value} />
              ))}
            </Dropdown>
          </FormRow>
          <FormRow>
            <InputLabel label={t("Additional info")} isRequired />
            <ResizableInput
              value={props.values.additionalInfo}
              onValueChange={(value) => {
                props.setFieldValue("additionalInfo", value);
              }}
              dataType="textarea"
              inputAttributes={{
                rows: "6"
              }}
              defaultType="verticalS"
            />
          </FormRow>
        </Grid>
      </Grid>
      <FormRow>
        <Stack direction="row">
          <StyledCheckbox
            monochrome
            value={props.values.consent}
            data={props.values.consent}
            onChange={(value) => {
              props.setFieldValue("consent", !value);
            }}
          />
          <Grid maxWidth={600}>
            {t(
              "I consent to receive offers and updates on products, service and events from ABB and give consent based on ABB's "
            )}
            <Link
              target="blank"
              href="https://new.abb.com/privacy-notice/customer/rest-of-the-world"
            >
              {t("Privacy Policy")}
            </Link>
            {t(" statement on-line.")}
          </Grid>
        </Stack>
      </FormRow>
      <Stack direction={"row-reverse"} spacing={2} marginBottom={commonUXTheme.sizes.m}>
        <Button
          text={t("Submit")}
          isLoading={props.isLoading}
          buttonType="primary"
          disabled={!props.dirty || !props.isValid}
          onClick={() => {
            props.onFormSubmit(props.values);
          }}
        />
        <Button text={t("Close")} buttonType="secondary" onClick={props.onClose} />
      </Stack>
    </StyledDialog>
  );
};

export const ConnectWithUsDialog = withFormik<ConnectWithUsFormProps, ConnectWithUsDetails>({
  enableReinitialize: true,
  mapPropsToValues: ({ formData }: ConnectWithUsFormProps) => ({
    additionalInfo: formData.additionalInfo,
    businessType: formData.businessType,
    city: formData.city,
    company: formData.company,
    consent: formData.consent,
    country: formData.country,
    email: formData.email,
    firstName: formData.firstName,
    industry: formData.industry,
    lastName: formData.lastName
  }),
  validationSchema: ConnectWithUsSchema,

  handleSubmit: (values, formikBag) => {
    formikBag.props.onFormSubmit(values);
  }
})(ConnectWithUsForm);
