import styled from "styled-components";
import { UploadingFile } from "./DropZone";
import { Button as AbbButton } from "@abb/abb-common-ux-react";
import React, { Fragment } from "react";
import { humanFileSize } from "utilities/stringUtils";
import { CommonUXSize } from "styles/commonUXVariables";

const DropZoneText = styled.p`
  margin-right: auto;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  color: ${(props) => props.theme.colors.grey60};
`;

const DropZoneDeleteButton = styled(AbbButton)`
  background-color: ${(props) => props.theme.colors.whitePrimary} !important;
  width: ${(props) => props.theme.sizes.lm} !important;
  height: ${(props) => props.theme.sizes.lm} !important;
  .ABB_CommonUX_Button__icon {
    top: ${(props) => props.theme.sizes.xxs} !important;
    left: ${(props) => props.theme.sizes.xxs} !important;
  }
`;

const DropZoneDownloadButton = styled(AbbButton)`
  background-color: ${(props) => props.theme.colors.whitePrimary} !important;
  width: ${(props) => props.theme.sizes.lm} !important;
  height: ${(props) => props.theme.sizes.lm} !important;
  .ABB_CommonUX_Button__icon {
    top: ${(props) => props.theme.sizes.xxs} !important;
    left: ${(props) => props.theme.sizes.xxs} !important;
  }
`;

const DropZoneFileContentWrapper = styled.div<{ gap?: CommonUXSize | 0 }>`
  overflow-wrap: anywhere;
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: ${(props) => (props.gap === 0 ? "0px" : props.theme.sizes[props.gap ?? "s"])};
`;

const FileRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.s};
  margin-top: 0px;
`;

const Hr = styled.hr`
  border-color: ${(props) => props.theme.colors.grey10};
  width: 100%;
  margin: 0px;
`;

export interface DropZoneFileListProps {
  files: UploadingFile[] | undefined;
  onClickDownload?: (file: UploadingFile) => void;
  onClickDelete?: (file: UploadingFile, index: number) => void;
  gap?: CommonUXSize | 0;
}

export const DropZoneFileList = ({
  files,
  onClickDownload,
  onClickDelete,
  gap
}: DropZoneFileListProps) => {
  if (!files || files.length === 0) {
    return null;
  }

  return (
    <DropZoneFileContentWrapper gap={gap}>
      {files.map((file, index) => {
        const shouldShowDownloadButton = onClickDownload && file.identifier && !file.blob;
        const shouldShowDeleteButton = !!onClickDelete;

        return (
          <Fragment key={file.identifier}>
            <FileRow>
              <DropZoneText>
                {file.name}
                {file.size !== undefined && ` (${humanFileSize(file.size)})`}
              </DropZoneText>
              {(shouldShowDeleteButton || shouldShowDownloadButton) && (
                <ButtonsContainer>
                  {shouldShowDownloadButton && (
                    <DropZoneDownloadButton
                      icon="abb/download"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickDownload(file);
                      }}
                    />
                  )}
                  {shouldShowDeleteButton && (
                    <DropZoneDeleteButton
                      icon="abb/trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickDelete(file, index);
                      }}
                    />
                  )}
                </ButtonsContainer>
              )}
            </FileRow>
            {index !== files.length - 1 && <Hr />}
          </Fragment>
        );
      })}
    </DropZoneFileContentWrapper>
  );
};
