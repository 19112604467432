import { DeliveryTimeDto } from "api";
import { Action } from "redux";

export enum DeliveryTimesActionTypes {
  SetDeliveryTimes = "DeliveryTimes/SetDeliveryTimes"
}

export interface SetDeliveryTimes extends Action {
  type: DeliveryTimesActionTypes.SetDeliveryTimes;
  deliveryTimes: DeliveryTimeDto[];
}
export const deliveryTimesActions = {
  SetDeliveryTimes: (deliveryTimes: DeliveryTimeDto[]): SetDeliveryTimes => ({
    type: DeliveryTimesActionTypes.SetDeliveryTimes,
    deliveryTimes
  })
};

export type deliveryTimesActions = SetDeliveryTimes;
