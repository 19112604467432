/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProFormaDto,
    ProFormaDtoFromJSON,
    ProFormaDtoFromJSONTyped,
    ProFormaDtoToJSON,
} from './ProFormaDto';
import {
    SalesChannelTypes,
    SalesChannelTypesFromJSON,
    SalesChannelTypesFromJSONTyped,
    SalesChannelTypesToJSON,
} from './SalesChannelTypes';
import {
    ShoppingCartAccountCustomerDto,
    ShoppingCartAccountCustomerDtoFromJSON,
    ShoppingCartAccountCustomerDtoFromJSONTyped,
    ShoppingCartAccountCustomerDtoToJSON,
} from './ShoppingCartAccountCustomerDto';
import {
    ShoppingCartBillingAddressDto,
    ShoppingCartBillingAddressDtoFromJSON,
    ShoppingCartBillingAddressDtoFromJSONTyped,
    ShoppingCartBillingAddressDtoToJSON,
} from './ShoppingCartBillingAddressDto';
import {
    ShoppingCartConsigneeDto,
    ShoppingCartConsigneeDtoFromJSON,
    ShoppingCartConsigneeDtoFromJSONTyped,
    ShoppingCartConsigneeDtoToJSON,
} from './ShoppingCartConsigneeDto';
import {
    ShoppingCartDeliveryAddressDto,
    ShoppingCartDeliveryAddressDtoFromJSON,
    ShoppingCartDeliveryAddressDtoFromJSONTyped,
    ShoppingCartDeliveryAddressDtoToJSON,
} from './ShoppingCartDeliveryAddressDto';
import {
    ShoppingCartEndCustomerDto,
    ShoppingCartEndCustomerDtoFromJSON,
    ShoppingCartEndCustomerDtoFromJSONTyped,
    ShoppingCartEndCustomerDtoToJSON,
} from './ShoppingCartEndCustomerDto';

/**
 * 
 * @export
 * @interface RequestOrderApprovalCommand
 */
export interface RequestOrderApprovalCommand {
    /**
     * 
     * @type {ShoppingCartDeliveryAddressDto}
     * @memberof RequestOrderApprovalCommand
     */
    shoppingCartDeliveryAddress?: ShoppingCartDeliveryAddressDto;
    /**
     * 
     * @type {ShoppingCartEndCustomerDto}
     * @memberof RequestOrderApprovalCommand
     */
    shoppingCartEndCustomer?: ShoppingCartEndCustomerDto;
    /**
     * 
     * @type {ShoppingCartAccountCustomerDto}
     * @memberof RequestOrderApprovalCommand
     */
    shoppingCartAccountCustomer?: ShoppingCartAccountCustomerDto;
    /**
     * 
     * @type {ShoppingCartBillingAddressDto}
     * @memberof RequestOrderApprovalCommand
     */
    shoppingCartBillingAddress?: ShoppingCartBillingAddressDto;
    /**
     * 
     * @type {ShoppingCartConsigneeDto}
     * @memberof RequestOrderApprovalCommand
     */
    shoppingCartConsignee?: ShoppingCartConsigneeDto;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    shippingMarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    specialInstructions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    forwarderContactInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {SalesChannelTypes}
     * @memberof RequestOrderApprovalCommand
     */
    salesChannelId?: SalesChannelTypes;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    sfdcReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    documentationRequirements?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    termsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    termsOfDeliveryDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    requestedTermsOfDeliveryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    requestedTermsOfDeliveryDescription?: string | null;
    /**
     * 
     * @type {Array<ProFormaDto>}
     * @memberof RequestOrderApprovalCommand
     */
    proFormaItems?: Array<ProFormaDto> | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    customerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    endCustomerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    companyId: string;
    /**
     * 
     * @type {Date}
     * @memberof RequestOrderApprovalCommand
     */
    requestedDeliveryDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOrderApprovalCommand
     */
    acceptEarlierDelivery?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOrderApprovalCommand
     */
    hasRiskReviewBeenDone?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOrderApprovalCommand
     */
    isExternal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOrderApprovalCommand
     */
    acceptPartialDelivery: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    pgCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RequestOrderApprovalCommand
     */
    requestedOrderApproverEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestOrderApprovalCommand
     */
    isOnlyAddedApprover?: boolean;
}

export function RequestOrderApprovalCommandFromJSON(json: any): RequestOrderApprovalCommand {
    return RequestOrderApprovalCommandFromJSONTyped(json, false);
}

export function RequestOrderApprovalCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestOrderApprovalCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartDeliveryAddress': !exists(json, 'shoppingCartDeliveryAddress') ? undefined : ShoppingCartDeliveryAddressDtoFromJSON(json['shoppingCartDeliveryAddress']),
        'shoppingCartEndCustomer': !exists(json, 'shoppingCartEndCustomer') ? undefined : ShoppingCartEndCustomerDtoFromJSON(json['shoppingCartEndCustomer']),
        'shoppingCartAccountCustomer': !exists(json, 'shoppingCartAccountCustomer') ? undefined : ShoppingCartAccountCustomerDtoFromJSON(json['shoppingCartAccountCustomer']),
        'shoppingCartBillingAddress': !exists(json, 'shoppingCartBillingAddress') ? undefined : ShoppingCartBillingAddressDtoFromJSON(json['shoppingCartBillingAddress']),
        'shoppingCartConsignee': !exists(json, 'shoppingCartConsignee') ? undefined : ShoppingCartConsigneeDtoFromJSON(json['shoppingCartConsignee']),
        'shippingMarks': !exists(json, 'shippingMarks') ? undefined : json['shippingMarks'],
        'specialInstructions': !exists(json, 'specialInstructions') ? undefined : json['specialInstructions'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'forwarderContactInformation': !exists(json, 'forwarderContactInformation') ? undefined : json['forwarderContactInformation'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'salesChannelId': !exists(json, 'salesChannelId') ? undefined : SalesChannelTypesFromJSON(json['salesChannelId']),
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'sfdcReference': !exists(json, 'sfdcReference') ? undefined : json['sfdcReference'],
        'documentationRequirements': !exists(json, 'documentationRequirements') ? undefined : json['documentationRequirements'],
        'termsOfDeliveryCode': !exists(json, 'termsOfDeliveryCode') ? undefined : json['termsOfDeliveryCode'],
        'termsOfDeliveryDescription': !exists(json, 'termsOfDeliveryDescription') ? undefined : json['termsOfDeliveryDescription'],
        'requestedTermsOfDeliveryCode': !exists(json, 'requestedTermsOfDeliveryCode') ? undefined : json['requestedTermsOfDeliveryCode'],
        'requestedTermsOfDeliveryDescription': !exists(json, 'requestedTermsOfDeliveryDescription') ? undefined : json['requestedTermsOfDeliveryDescription'],
        'proFormaItems': !exists(json, 'proFormaItems') ? undefined : (json['proFormaItems'] === null ? null : (json['proFormaItems'] as Array<any>).map(ProFormaDtoFromJSON)),
        'identifier': json['identifier'],
        'customerReference': !exists(json, 'customerReference') ? undefined : json['customerReference'],
        'endCustomerReference': !exists(json, 'endCustomerReference') ? undefined : json['endCustomerReference'],
        'companyId': json['companyId'],
        'requestedDeliveryDate': !exists(json, 'requestedDeliveryDate') ? undefined : (json['requestedDeliveryDate'] === null ? null : new Date(json['requestedDeliveryDate'])),
        'acceptEarlierDelivery': !exists(json, 'acceptEarlierDelivery') ? undefined : json['acceptEarlierDelivery'],
        'hasRiskReviewBeenDone': !exists(json, 'hasRiskReviewBeenDone') ? undefined : json['hasRiskReviewBeenDone'],
        'isExternal': json['isExternal'],
        'acceptPartialDelivery': json['acceptPartialDelivery'],
        'pgCode': !exists(json, 'pgCode') ? undefined : json['pgCode'],
        'requestedOrderApproverEmail': !exists(json, 'requestedOrderApproverEmail') ? undefined : json['requestedOrderApproverEmail'],
        'isOnlyAddedApprover': !exists(json, 'isOnlyAddedApprover') ? undefined : json['isOnlyAddedApprover'],
    };
}

export function RequestOrderApprovalCommandToJSON(value?: RequestOrderApprovalCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartDeliveryAddress': ShoppingCartDeliveryAddressDtoToJSON(value.shoppingCartDeliveryAddress),
        'shoppingCartEndCustomer': ShoppingCartEndCustomerDtoToJSON(value.shoppingCartEndCustomer),
        'shoppingCartAccountCustomer': ShoppingCartAccountCustomerDtoToJSON(value.shoppingCartAccountCustomer),
        'shoppingCartBillingAddress': ShoppingCartBillingAddressDtoToJSON(value.shoppingCartBillingAddress),
        'shoppingCartConsignee': ShoppingCartConsigneeDtoToJSON(value.shoppingCartConsignee),
        'shippingMarks': value.shippingMarks,
        'specialInstructions': value.specialInstructions,
        'vatNumber': value.vatNumber,
        'forwarderContactInformation': value.forwarderContactInformation,
        'additionalInformation': value.additionalInformation,
        'salesChannelId': SalesChannelTypesToJSON(value.salesChannelId),
        'projectName': value.projectName,
        'sfdcReference': value.sfdcReference,
        'documentationRequirements': value.documentationRequirements,
        'termsOfDeliveryCode': value.termsOfDeliveryCode,
        'termsOfDeliveryDescription': value.termsOfDeliveryDescription,
        'requestedTermsOfDeliveryCode': value.requestedTermsOfDeliveryCode,
        'requestedTermsOfDeliveryDescription': value.requestedTermsOfDeliveryDescription,
        'proFormaItems': value.proFormaItems === undefined ? undefined : (value.proFormaItems === null ? null : (value.proFormaItems as Array<any>).map(ProFormaDtoToJSON)),
        'identifier': value.identifier,
        'customerReference': value.customerReference,
        'endCustomerReference': value.endCustomerReference,
        'companyId': value.companyId,
        'requestedDeliveryDate': value.requestedDeliveryDate === undefined ? undefined : (value.requestedDeliveryDate === null ? null : value.requestedDeliveryDate.toISOString()),
        'acceptEarlierDelivery': value.acceptEarlierDelivery,
        'hasRiskReviewBeenDone': value.hasRiskReviewBeenDone,
        'isExternal': value.isExternal,
        'acceptPartialDelivery': value.acceptPartialDelivery,
        'pgCode': value.pgCode,
        'requestedOrderApproverEmail': value.requestedOrderApproverEmail,
        'isOnlyAddedApprover': value.isOnlyAddedApprover,
    };
}

