import { AsyncRequest, RequestCompleted, RequestFailed } from "../actions/asyncActions";
import { RequestStatus } from "./requestStatus";

export interface InitialRequestState {
  status: RequestStatus.Initial;
  startTime: undefined;
  endTime: undefined;
  requestPayload: undefined;
  responsePayload: undefined;
  error: undefined;
}
export function createInitialRequest(): InitialRequestState {
  return {
    status: RequestStatus.Initial,
    startTime: undefined,
    endTime: undefined,
    requestPayload: undefined,
    responsePayload: undefined,
    error: undefined
  };
}
export interface PendingRequestState<TRequestPayload = undefined> {
  status: RequestStatus.Pending;
  startTime: string;
  endTime: undefined;
  requestPayload: TRequestPayload;
  responsePayload: undefined;
  error: undefined;
}
export function updateWithPendingRequest<TRequestPayload = undefined>(
  action: AsyncRequest<TRequestPayload>
): PendingRequestState<TRequestPayload> {
  return {
    status: RequestStatus.Pending,
    startTime: action.startTime,
    endTime: undefined,
    requestPayload: action.payload,
    responsePayload: undefined,
    error: undefined
  };
}
export interface CompletedRequestState<TResponsePayload = undefined, TRequestPayload = undefined> {
  status: RequestStatus.Completed;
  startTime: string;
  endTime: string;
  requestPayload: TRequestPayload;
  responsePayload: TResponsePayload;
  error: undefined;
}
export function updateWithCompletedRequest<
  TResponsePayload = undefined,
  TRequestPayload = undefined
>(
  state: RequestState<any, any>,
  action: RequestCompleted<TResponsePayload>
): CompletedRequestState<TResponsePayload, TRequestPayload> {
  return {
    status: RequestStatus.Completed,
    startTime: (state.startTime as string) || "Error, start time is not defined",
    endTime: action.endTime,
    requestPayload: state.requestPayload,
    responsePayload: action.payload,
    error: undefined
  };
}
export interface FailedRequestState<TRequestPayload = undefined> {
  status: RequestStatus.Failed;
  startTime: string;
  endTime: string;
  requestPayload: TRequestPayload;
  responsePayload: undefined;
  error: string;
}
export function updateWithFailedRequest<TRequestPayload = undefined>(
  state: RequestState<any, any>,
  action: RequestFailed<TRequestPayload>
): FailedRequestState<TRequestPayload> {
  return {
    status: RequestStatus.Failed,
    startTime: (state.startTime as string) || "Error, start time is not defined",
    endTime: action.endTime,
    requestPayload: state.requestPayload,
    responsePayload: undefined,
    error: action.error
  };
}

export type RequestState<TResponsePayload = undefined, TRequestPayload = undefined> =
  | InitialRequestState
  | PendingRequestState<TRequestPayload>
  | CompletedRequestState<TResponsePayload, TRequestPayload>
  | FailedRequestState<TRequestPayload>;
