/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderingCodeSerialNumber
 */
export interface OrderingCodeSerialNumber {
    /**
     * 
     * @type {string}
     * @memberof OrderingCodeSerialNumber
     */
    orderingCode?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderingCodeSerialNumber
     */
    serialNumber?: Array<string> | null;
}

export function OrderingCodeSerialNumberFromJSON(json: any): OrderingCodeSerialNumber {
    return OrderingCodeSerialNumberFromJSONTyped(json, false);
}

export function OrderingCodeSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderingCodeSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCode': !exists(json, 'orderingCode') ? undefined : json['orderingCode'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
    };
}

export function OrderingCodeSerialNumberToJSON(value?: OrderingCodeSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCode': value.orderingCode,
        'serialNumber': value.serialNumber,
    };
}

