/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AdditionalQuoteRequesterStatus {
    Active = 'Active',
    WaitingForConnectingToCompany = 'WaitingForConnectingToCompany',
    WaitingForRegistration = 'WaitingForRegistration'
}

export function AdditionalQuoteRequesterStatusFromJSON(json: any): AdditionalQuoteRequesterStatus {
    return AdditionalQuoteRequesterStatusFromJSONTyped(json, false);
}

export function AdditionalQuoteRequesterStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalQuoteRequesterStatus {
    return json as AdditionalQuoteRequesterStatus;
}

export function AdditionalQuoteRequesterStatusToJSON(value?: AdditionalQuoteRequesterStatus | null): any {
    return value as any;
}

