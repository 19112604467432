/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShoppingCartItemAdditionalInformationUpdatedDto
 */
export interface ShoppingCartItemAdditionalInformationUpdatedDto {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemAdditionalInformationUpdatedDto
     */
    guid: string;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartItemAdditionalInformationUpdatedDto
     */
    lastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartItemAdditionalInformationUpdatedDto
     */
    shoppingCartItemId: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemAdditionalInformationUpdatedDto
     */
    additionalInformation?: string | null;
}

export function ShoppingCartItemAdditionalInformationUpdatedDtoFromJSON(json: any): ShoppingCartItemAdditionalInformationUpdatedDto {
    return ShoppingCartItemAdditionalInformationUpdatedDtoFromJSONTyped(json, false);
}

export function ShoppingCartItemAdditionalInformationUpdatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartItemAdditionalInformationUpdatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'lastModified': (new Date(json['lastModified'])),
        'shoppingCartItemId': json['shoppingCartItemId'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function ShoppingCartItemAdditionalInformationUpdatedDtoToJSON(value?: ShoppingCartItemAdditionalInformationUpdatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'lastModified': (value.lastModified.toISOString()),
        'shoppingCartItemId': value.shoppingCartItemId,
        'additionalInformation': value.additionalInformation,
    };
}

