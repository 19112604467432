import { roleSagas } from "../applications/common/sagas/roleSagas";
import { accessRightSagas } from "../applications/common/sagas/accessRightSagas";
import { manageAccessRequestsSagas } from "../applications/manage/manageAccessRequests/sagas/manageAccessRequestsSagas";
import { deliveriesHomeSagas } from "../applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { setupState } from "../framework/setup/store";
import { appRootReducer } from "./appRootReducer";
import { createSagas } from "../framework/sagas/sagaUtilities";
import { userSagas } from "../applications/common/sagas/userSagas";
import { orderSagas } from "../applications/deliveries/orders/sagas/orderSagas";
import { manageUsersSagas } from "../applications/manage/manageUsers/sagas/manageUsersSagas";
import { manageCompaniesSagas } from "../applications/manage/manageCompanies/sagas/manageCompaniesSagas";
import { managePriceListsSagas } from "applications/manage/managePriceList/sagas/managePriceListsSagas";
import { notificationViewSagas } from "../applications/deliveries/notifications/sagas/notificationSagas";
import { accessRequestsSagas } from "applications/accessRequests/sagas/accessRequestsSagas";
import { productSelectionMatrixSagas } from "applications/products/productSelectionMatrix/sagas/productSelectionMatrixSagas";
import { translationSagas } from "applications/manage/manageTranslations/sagas/translationSagas";
import { consentSagas } from "applications/common/sagas/consentSagas";
import { dashboardSagas } from "../applications/dashboard/sagas/dashboardSagas";
import { quickPriceCheckSagas } from "../applications/quickPriceCheck/sagas/quickPriceCheckSagas";
import { applicationNotificationSagas } from "applications/applicationNotifications/sagas/applicationNotificationSagas";
import { shoppingCartSagas } from "../applications/common/sagas/shoppingCartSagas";
import { trainingManagementSagas } from "applications/manage/training/sagas/trainingManagementSagas";
import { manageQuotesSagas } from "applications/manage/manageQuotes/sagas/ManageQuotesSagas";
import { productInformationSagas } from "applications/deliveries/productInformation/sagas/productInformationSagas";
import { manageTermsConditionsSagas } from "applications/manage/manageTermsConditions/sagas/manageTermsConditionsSagas";
import { shoppingCartCompanySagas } from "applications/shoppingCart/sagas/shoppingCartCompanySagas";
import { emailTemplateSagas } from "applications/manage/manageEmailTemplates/sagas/emailTemplateSagas";
import { shoppingCartsViewSagas } from "applications/shoppingCarts/sagas/shoppingCartsViewSagas";
import { modificationSaleSagas } from "applications/common/sagas/modificationSaleSagas";
import { productExplorerSagas } from "applications/products/productExplorer/sagas/productExplorerSagas";
import { productComparisonSagas } from "applications/products/productComparison/sagas/productComparisonSagas";
import { manageReleaseNotesSagas } from "applications/manage/manageReleaseNotes/sagas/manageReleaseNotesSagas";
import { manageGlobalSettingsSagas } from "applications/manage/globalSettings/sagas/manageGlobalSettingsSagas";
import { deliveryTimesSagas } from "applications/deliveries/deliveryTimes/sagas/deliveryTimesSagas";
import { supportSagas } from "applications/support/sagas/supportSagas";
import { trainingSagas } from "applications/training/sagas/trainingSagas";
import { knowledgeWarehouseSagas } from "applications/knowledgeWarehouse/sagas/knowledgeWarehouseSagas";
import { lifecycleManagementSagas } from "applications/deliveries/lifecycleManagement/sagas/lifecycleManagementSagas";
import { manageTerminologyBankSagas } from "applications/manage/terminologyBank/sagas/manageTerminologyBankSagas";
import { terminologyBankSagas } from "applications/terminologyBank/sagas/terminologyBankSagas";
import { manageTeamsSagas } from "applications/manage/manageTeams/sagas/manageTeamsSagas";
import { myKnowledgeAiSagas } from "applications/myKnowledgeAi/sagas/myKnowledgeAiSagas";

//Set the sagas to use here. appRootReducer should contain all the reducers required for the application.
const state = setupState(appRootReducer, {}, (store, sagaMiddleware) => {
  createSagas(sagaMiddleware, deliveriesHomeSagas);
  createSagas(sagaMiddleware, manageAccessRequestsSagas);
  createSagas(sagaMiddleware, userSagas);
  createSagas(sagaMiddleware, orderSagas);
  createSagas(sagaMiddleware, accessRequestsSagas);
  createSagas(sagaMiddleware, manageUsersSagas);
  createSagas(sagaMiddleware, manageTeamsSagas);
  createSagas(sagaMiddleware, manageCompaniesSagas);
  createSagas(sagaMiddleware, managePriceListsSagas);
  createSagas(sagaMiddleware, manageTermsConditionsSagas);
  createSagas(sagaMiddleware, notificationViewSagas);
  createSagas(sagaMiddleware, roleSagas);
  createSagas(sagaMiddleware, accessRightSagas);
  createSagas(sagaMiddleware, productSelectionMatrixSagas);
  createSagas(sagaMiddleware, translationSagas);
  createSagas(sagaMiddleware, consentSagas);
  createSagas(sagaMiddleware, dashboardSagas);
  createSagas(sagaMiddleware, quickPriceCheckSagas);
  createSagas(sagaMiddleware, applicationNotificationSagas);
  createSagas(sagaMiddleware, shoppingCartSagas);
  createSagas(sagaMiddleware, manageQuotesSagas);
  createSagas(sagaMiddleware, productInformationSagas);
  createSagas(sagaMiddleware, trainingManagementSagas);
  createSagas(sagaMiddleware, knowledgeWarehouseSagas);
  createSagas(sagaMiddleware, trainingSagas);
  createSagas(sagaMiddleware, shoppingCartCompanySagas);
  createSagas(sagaMiddleware, emailTemplateSagas);
  createSagas(sagaMiddleware, shoppingCartsViewSagas);
  createSagas(sagaMiddleware, modificationSaleSagas);
  createSagas(sagaMiddleware, productExplorerSagas);
  createSagas(sagaMiddleware, productComparisonSagas);
  createSagas(sagaMiddleware, manageReleaseNotesSagas);
  createSagas(sagaMiddleware, manageGlobalSettingsSagas);
  createSagas(sagaMiddleware, deliveryTimesSagas);
  createSagas(sagaMiddleware, supportSagas);
  createSagas(sagaMiddleware, lifecycleManagementSagas);
  createSagas(sagaMiddleware, manageTerminologyBankSagas);
  createSagas(sagaMiddleware, terminologyBankSagas);
  createSagas(sagaMiddleware, myKnowledgeAiSagas);
});

export const appStore = state.store;
