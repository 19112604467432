import { createGenericSaga, defaultSagaErrorHandler } from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";

export const roleSagas = {
  getRoles: createGenericSaga(
    "roleSagas/getRoles",
    getApiRegistry().rolesApi.apiRolesGet.bind(getApiRegistry().rolesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
