/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SoftwareSubscriptionEmailRegistrationSentDto,
    SoftwareSubscriptionEmailRegistrationSentDtoFromJSON,
    SoftwareSubscriptionEmailRegistrationSentDtoFromJSONTyped,
    SoftwareSubscriptionEmailRegistrationSentDtoToJSON,
} from './SoftwareSubscriptionEmailRegistrationSentDto';

/**
 * 
 * @export
 * @interface ShoppingCartItemSoftwarePurchaseEmailUpdatedDto
 */
export interface ShoppingCartItemSoftwarePurchaseEmailUpdatedDto {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemSoftwarePurchaseEmailUpdatedDto
     */
    guid: string;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartItemSoftwarePurchaseEmailUpdatedDto
     */
    lastModified: Date;
    /**
     * 
     * @type {SoftwareSubscriptionEmailRegistrationSentDto}
     * @memberof ShoppingCartItemSoftwarePurchaseEmailUpdatedDto
     */
    softwareSubscriptionEmailRegistrationSent?: SoftwareSubscriptionEmailRegistrationSentDto;
}

export function ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoFromJSON(json: any): ShoppingCartItemSoftwarePurchaseEmailUpdatedDto {
    return ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoFromJSONTyped(json, false);
}

export function ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartItemSoftwarePurchaseEmailUpdatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'lastModified': (new Date(json['lastModified'])),
        'softwareSubscriptionEmailRegistrationSent': !exists(json, 'softwareSubscriptionEmailRegistrationSent') ? undefined : SoftwareSubscriptionEmailRegistrationSentDtoFromJSON(json['softwareSubscriptionEmailRegistrationSent']),
    };
}

export function ShoppingCartItemSoftwarePurchaseEmailUpdatedDtoToJSON(value?: ShoppingCartItemSoftwarePurchaseEmailUpdatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'lastModified': (value.lastModified.toISOString()),
        'softwareSubscriptionEmailRegistrationSent': SoftwareSubscriptionEmailRegistrationSentDtoToJSON(value.softwareSubscriptionEmailRegistrationSent),
    };
}

