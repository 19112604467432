import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import {
  ApiPricesManagedPriceListsIdDeleteRequest,
  ApiPricesManagedPriceListsPostRequest,
  ApiPricesManagedPriceListsGetRequest
} from "../../../../api";
import { Action } from "redux";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { AppState } from "../../../../setup/appRootReducer";
import { managePriceListsSagas } from "../sagas/managePriceListsSagas";

interface Requests {
  getManagedPriceLists: RequestState<ApiPricesManagedPriceListsGetRequest>;
  deleteManagedPriceList: RequestState<ApiPricesManagedPriceListsIdDeleteRequest>;
  addOrUpdateManagedPriceList: RequestState<ApiPricesManagedPriceListsPostRequest>;
}

export interface ManagePriceListsState {
  requests: Requests;
}

const defaultState: ManagePriceListsState = {
  requests: {
    getManagedPriceLists: createInitialRequest(),
    deleteManagedPriceList: createInitialRequest(),
    addOrUpdateManagedPriceList: createInitialRequest()
  }
};

export function managePriceListsReducer(
  state: ManagePriceListsState = defaultState,
  action: Action
): ManagePriceListsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: managePriceListsSagas.getManagedPriceLists.actionTypes,
      key: "getManagedPriceLists"
    },
    {
      actionTypes: managePriceListsSagas.deleteManagedPriceList.actionTypes,
      key: "deleteManagedPriceList"
    },
    {
      actionTypes: managePriceListsSagas.addOrUpdateManagedPriceList.actionTypes,
      key: "addOrUpdateManagedPriceList"
    }
  ]);

  return state;
}
export const getGetPriceListsRequest = (
  state: AppState
): RequestState<ApiPricesManagedPriceListsGetRequest> =>
  state.managePriceLists.requests.getManagedPriceLists;

export const getDeletePriceListRequest = (
  state: AppState
): RequestState<ApiPricesManagedPriceListsIdDeleteRequest> =>
  state.managePriceLists.requests.deleteManagedPriceList;

export const getAddOrUpdatePriceListRequest = (
  state: AppState
): RequestState<ApiPricesManagedPriceListsPostRequest> =>
  state.managePriceLists.requests.addOrUpdateManagedPriceList;
