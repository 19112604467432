import React, { useMemo, memo, useCallback } from "react";
import { createHeaderAndHeaderTooltip } from "../../../framework/components/grid/Grid";
import { TFunction } from "i18next";
import {
  ColDef,
  ICellRendererParams,
  GridApi,
  GetRowIdParams,
  ValueGetterParams,
  GetContextMenuItemsParams,
  IRowNode
} from "ag-grid-community";
import { ShoppingCartItemDto, ShoppingCartStateTypes, ShoppingCartModificationItemDto } from "api";
import { GridProductRenderer, GridProductRendererProps } from "./GridProductRenderer";
import { getConfiguratorBaseUrl } from "../../common/reducers/commonReducer";
import {
  GridPriceAndActionsRenderer,
  GridPriceAndActionsRendererProps
} from "./GridPriceAndActionsRenderer";
import {
  GridNumericInputRenderer,
  GridNumericInputRendererProps,
  NumericInputColorStatus
} from "./GridNumericInputRenderer";
import { getConfiguratorLink } from "utilities/urlUtils";
import { useDispatch, useSelector } from "react-redux";
import { getShowDetails, isDetailProduct, isStaticProduct } from "utilities/productUtils";
import { detailsGrid, getPopupParentDocumentBody } from "../../../utilities/gridUtils";
import { DetailGrid } from "styles/detailGrid";
import { GridLoadingOverlay } from "framework/components/grid/GridLoadingOverlay";
import { useTheme } from "styled-components";
import { getContextMenuItemsShoppingCartItem } from "utilities/gridContextMenuItemUtils";
import {
  getCsvExportParams,
  getExcelExportParams,
  getExcelStyles
} from "utilities/gridExportUtils";
import { AgGridReactProps } from "ag-grid-react";
import { isFractionSmallerThanOrEqualTo } from "utilities/mathUtils";
import {
  GridIconRenderer,
  GridIconRendererProps
} from "framework/components/grid/GridIconRenderer";
import { commonUXTheme } from "styles/commonUXVariables";
import { getProductQuantityRunningLowWarningPercentage } from "applications/common/reducers/shoppingCartReducer";
import {
  QuoteGridNumericInputRenderer,
  QuoteGridNumericInputRendererProps
} from "applications/manage/manageQuotes/components/QuoteGridNumericInputRenderer";
import { QuoteShoppingCartItemDto } from "models/quote/quoteDto";
import { infoTypeConstants } from "applications/manage/manageQuotes/containers/QuoteView";
import { priceStringToFloatNumber } from "utilities/currencyUtils";

interface ShoppingCartGridProps {
  t: TFunction;
  updateShoppingCartItem: (
    orderingCode: string,
    infoType: string,
    discountRate: number | null | undefined,
    unitQuotedPrice: number | null | undefined,
    quantity: number,
    additionalInformation: string
  ) => void;
  deleteShoppingCartItem: (id: number, orderingCode?: string) => void;
  deleteShoppingCartModificationItem: (modificationItemId: number) => void;
  deleteShoppingCartModificationItemSerialNumber: (
    modificationItemId: number,
    serialNumber: string
  ) => void;
  openEditDialogAndSetSelectedShoppingCartItem: (shoppingCartItem: ShoppingCartItemDto) => void;
  isReadOnly: boolean;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  shoppingCartState: ShoppingCartStateTypes | null | undefined;
  shoppingCartReference: string | undefined;
  createModificationCase: (id: number, description: string) => void;
  hidePrices?: boolean;
  openInvalidModificationProductDialog: () => void;
  rowData: ShoppingCartItemDto[];
  isBasedOnFrameAgreement: boolean;
  reviseMode?: boolean;
  companyIsInternalOrMaintained: boolean;
  shoppingCartIdentifier: string;
}

const compareStringsAsNumbers = (valueA: any, valueB: any) => {
  if (!valueA) return -1;
  if (!valueB) return 1;
  return Number(valueA.replace(/\D/g, "")) - Number(valueB.replace(/\D/g, ""));
};

const getItemError = (
  shoppingCartState: ShoppingCartStateTypes | null | undefined,
  shoppingCartItem: ShoppingCartItemDto,
  t: TFunction
): string | undefined => {
  if (
    shoppingCartItem.quotedPrice == null &&
    shoppingCartState !== ShoppingCartStateTypes.OrderPlaced &&
    shoppingCartState !== ShoppingCartStateTypes.OrderPlacedExternally
  ) {
    if (shoppingCartItem.isPriceValid === false) {
      return t("Invalid price or no price found");
    }

    if (shoppingCartItem.isValidProduct === false) {
      return t("Product is not valid");
    }
  }
};

export const getProductColumnValue = (node: IRowNode | null, t: TFunction) => {
  if (node === null) return "";
  const shoppingCartItem = node.data as ShoppingCartItemDto;

  if (isDetailProduct(shoppingCartItem.displayName)) {
    return shoppingCartItem.orderingCode;
  }

  const compCodeOrOrderingCode =
    shoppingCartItem.compositionCode && !shoppingCartItem.isModification
      ? shoppingCartItem.compositionCode
      : shoppingCartItem.orderingCode;
  const orderingCode =
    shoppingCartItem.compositionCode &&
    shoppingCartItem.orderingCode !== shoppingCartItem.compositionCode
      ? shoppingCartItem.orderingCode
      : null;
  const serialNumbers = node.data.serialNumbers
    ? t("Serial number: {{serialNumbers}}", { serialNumbers: node.data.serialNumbers })
    : null;
  const productColumnString = shoppingCartItem.isModification
    ? [
        compCodeOrOrderingCode,
        t("Email address for software delivery: {{emailAddress}}", {
          emailAddress: shoppingCartItem.softwarePurchaseEmail
        })
      ].join(", ")
    : (isStaticProduct(shoppingCartItem.category)
        ? [compCodeOrOrderingCode, shoppingCartItem.displayName, serialNumbers]
        : [shoppingCartItem.displayName, compCodeOrOrderingCode, orderingCode, serialNumbers]
      )
        .filter(Boolean)
        .map((s) => s?.trim())
        .join(", ")
        .replace(/\s{2,}/g, ", ");
  return productColumnString;
};

export const ShoppingCartGrid = memo(
  ({
    t,
    updateShoppingCartItem,
    deleteShoppingCartItem,
    deleteShoppingCartModificationItem,
    deleteShoppingCartModificationItemSerialNumber,
    openEditDialogAndSetSelectedShoppingCartItem,
    openInvalidModificationProductDialog,
    isReadOnly,
    setGridApi,
    shoppingCartState,
    shoppingCartReference,
    createModificationCase,
    hidePrices,
    rowData,
    isBasedOnFrameAgreement,
    reviseMode,
    companyIsInternalOrMaintained,
    shoppingCartIdentifier
  }: ShoppingCartGridProps) => {
    const configuratorBaseUrl = useSelector(getConfiguratorBaseUrl);
    const lowQuantityPercentage = useSelector(getProductQuantityRunningLowWarningPercentage);

    const theme = useTheme();
    const dispatch = useDispatch();

    const colDefs: ColDef[] = useMemo(
      () => [
        {
          ...createHeaderAndHeaderTooltip("#", t("Position")),
          field: "position",
          maxWidth: 50,
          cellRenderer: "agGroupCellRenderer"
        },
        {
          ...createHeaderAndHeaderTooltip(t("Ordering code")),
          field: "orderingCode",
          hide: true
        },
        {
          ...createHeaderAndHeaderTooltip(t("Product")),
          colId: "product",
          valueGetter: (params: ValueGetterParams) => getProductColumnValue(params.node, t),
          autoHeight: true,
          cellRenderer: GridProductRenderer,
          cellRendererParams: (params: ICellRendererParams): GridProductRendererProps => {
            const shoppingCartItem = params.data as ShoppingCartItemDto;
            return {
              ...params,
              t: t,
              displayName: isDetailProduct(shoppingCartItem.displayName)
                ? shoppingCartItem.orderingCode
                : shoppingCartItem.displayName,
              imgUrl: shoppingCartItem.imageUrl,
              compCodeOrOrderingCode:
                shoppingCartItem.compositionCode && !shoppingCartItem.isModification
                  ? shoppingCartItem.compositionCode
                  : shoppingCartItem.orderingCode,
              orderingCode:
                shoppingCartItem.compositionCode &&
                shoppingCartItem.orderingCode !== shoppingCartItem.compositionCode
                  ? shoppingCartItem.orderingCode
                  : null,
              vendorOrderingCode: shoppingCartItem.vendorOrderingCode ?? null,
              additionalInformation: shoppingCartItem.additionalInformation,
              onClick: () => {
                params.node.setExpanded(!params.node.expanded);
                params.api.refreshCells({ force: true });
              },
              isModification: shoppingCartItem.isModification,
              isStaticProduct: isStaticProduct(shoppingCartItem.category),
              isRenewalProduct: shoppingCartItem.isRenewalProduct,
              isSoftwareProduct: shoppingCartItem.isSoftwareProduct,
              softwarePurchaseEmail: shoppingCartItem.softwarePurchaseEmail,
              showDetailsText: params.node.expanded ? t("Hide details") : t("Show details"),
              showDetails: getShowDetails(shoppingCartItem),
              icon: params.node.expanded ? "abb/caret-up" : "abb/caret-down",
              isSimpleRenderer: isReadOnly,
              errorText: getItemError(shoppingCartState, shoppingCartItem, t),
              onlineDeliveryProductEmailHelpText:
                shoppingCartItem.onlineDeliveryProductEmailHelpText,
              emailsSoftwareVerification:
                shoppingCartItem.isSoftwareProductEmailRegistration ?? null,
              reviseMode
            };
          },
          wrapText: true,
          flex: 1,
          minWidth: 220
        },
        {
          ...createHeaderAndHeaderTooltip(t("Unit price")),
          cellRenderer: GridPriceAndActionsRenderer,
          cellRendererParams: (params: ICellRendererParams): GridPriceAndActionsRendererProps => {
            const shoppingCartItem = params.data as ShoppingCartItemDto;
            return {
              ...params,
              price: shoppingCartItem.unitPrice,
              quotedPrice: shoppingCartItem.unitQuotedPrice,
              isBold: false
            };
          },
          valueGetter: (params) =>
            hidePrices
              ? t("(Hidden)")
              : parseFloat(params.data?.unitPrice || "0") !== 0
              ? params.data?.unitQuotedPrice ?? params.data?.unitPrice
              : "",
          comparator: compareStringsAsNumbers,
          minWidth: 140,
          maxWidth: 140,
          autoHeight: true
        },
        {
          ...createHeaderAndHeaderTooltip(t("Discount rate")),
          field: "discountRate",
          valueGetter: (params) =>
            params.data?.discountRate === Number.NEGATIVE_INFINITY
              ? ""
              : params.data?.discountRate + " %",
          cellRenderer: QuoteGridNumericInputRenderer,
          cellRendererParams: (params: ICellRendererParams): QuoteGridNumericInputRendererProps => {
            const shoppingCartItem = params.data as QuoteShoppingCartItemDto;
            return {
              ...params,
              min: 0,
              max: 100,
              value: shoppingCartItem.discountRate,
              suffix: "%",
              infoType: infoTypeConstants.discountRate,
              onChange: (orderingCode, discountRate, unitQuotedPrice) =>
                updateShoppingCartItem(
                  orderingCode,
                  infoTypeConstants.discountRate,
                  discountRate,
                  unitQuotedPrice,
                  shoppingCartItem.quantity,
                  shoppingCartItem.additionalInformation ?? ""
                ),
              type: "compact",
              isSimpleRenderer:
                priceStringToFloatNumber(shoppingCartItem.price) === 0
                  ? true
                  : (isReadOnly && !reviseMode) || (reviseMode && companyIsInternalOrMaintained),
              t: t,
              hide: shoppingCartItem.discountRate === Number.NEGATIVE_INFINITY
            };
          },
          minWidth: 150,
          hide: shoppingCartState === ShoppingCartStateTypes.Draft
        },
        {
          ...createHeaderAndHeaderTooltip(t("Quantity")),
          field: "quantity",
          cellRenderer: GridNumericInputRenderer,
          cellRendererParams: (
            params: ICellRendererParams<ShoppingCartItemDto>
          ): GridNumericInputRendererProps => {
            const shoppingCartItem = params.data!;
            const frameAgreementLimit = params.data?.frameAgreementDetails?.limit;
            const frameAgreementCurrentCount = params.data?.frameAgreementDetails?.currentCount;

            let status: NumericInputColorStatus = "normal";

            if (frameAgreementCurrentCount != undefined && frameAgreementLimit != undefined) {
              const currentCount = shoppingCartItem.quantity + frameAgreementCurrentCount;
              const remainingCount = frameAgreementLimit - currentCount;

              if (currentCount > frameAgreementLimit) {
                // Over the frame agreement limit -> show error
                status = "error";
              } else if (
                lowQuantityPercentage !== undefined &&
                isFractionSmallerThanOrEqualTo(
                  [remainingCount, frameAgreementLimit],
                  [lowQuantityPercentage.numerator, lowQuantityPercentage.denominator]
                )
              ) {
                // The math utility function is needed to get rid of floating point errors.
                // Less than or exactly 10% of frame agreement quota remaining -> show warning
                status = "warning";
              } else {
                // More than 10% of frame agreement quota remaining -> show normal status
                status = "good";
              }
            }

            return {
              ...params,
              min: 1,
              value: shoppingCartItem.quantity,
              onChange: (orderingCode, quantity, additionalInformation) =>
                updateShoppingCartItem(
                  orderingCode,
                  infoTypeConstants.quantity,
                  0,
                  0,
                  quantity,
                  additionalInformation
                ),
              type: "compact",
              isSimpleRenderer:
                ((isReadOnly ||
                  shoppingCartItem.isSoftwareProduct ||
                  !!getItemError(shoppingCartState, shoppingCartItem, t)) &&
                  !reviseMode) ||
                shoppingCartItem.isModification,
              status: !reviseMode ? status : undefined,
              tooltip: !reviseMode
                ? {
                    normal: undefined,
                    warning: t(
                      "The quantity of this product is running low - please contact your sales representative for assistance"
                    ),
                    error: t(
                      "The quantity of this product must not exceed the frame agreement limit - please contact your sales representative for assistance"
                    ),
                    good: undefined
                  }[status]
                : undefined
            };
          },
          minWidth: 110,
          maxWidth: 110
        },
        {
          ...createHeaderAndHeaderTooltip(t("Available")),
          cellRenderer: GridIconRenderer,
          cellRendererParams: (params: ICellRendererParams) => {
            const data = params.data as ShoppingCartItemDto | undefined;
            if (
              data?.frameAgreementDetails?.currentCount != undefined &&
              data.frameAgreementDetails.limit != undefined &&
              lowQuantityPercentage !== undefined
            ) {
              const productsRemaining =
                data.frameAgreementDetails.limit - data.frameAgreementDetails.currentCount;

              const showWarning = isFractionSmallerThanOrEqualTo(
                [productsRemaining, data.frameAgreementDetails.limit],
                [lowQuantityPercentage.numerator, lowQuantityPercentage.denominator]
              );

              const percentageApproximation = (
                (lowQuantityPercentage.numerator / lowQuantityPercentage.denominator) *
                100
              ).toFixed(0);

              const p: GridIconRendererProps = {
                ...params,
                showIcon:
                  isReadOnly && // If the user can edit the quantity, then the warning will be shown on the numeric input
                  showWarning,
                icon: "abb/warning-circle-1",
                text: productsRemaining.toString(),
                color: commonUXTheme.colors.statusOrange,
                iconPosition: "left",
                alignItems: "flex-start",
                iconTooltip:
                  productsRemaining === 0
                    ? t("No products are remaining")
                    : t("Less than {{percentage}}% of products remaining", {
                        percentage: percentageApproximation
                      })
              };
              return p;
            } else {
              const p: GridIconRendererProps = {
                ...params,
                showIcon: false,
                icon: ""
              };
              return p;
            }
          },
          hide: !isBasedOnFrameAgreement,
          maxWidth: 170
        },
        {
          ...createHeaderAndHeaderTooltip(t("Price")),
          cellRenderer: GridPriceAndActionsRenderer,
          cellRendererParams: (params: ICellRendererParams): GridPriceAndActionsRendererProps => {
            const shoppingCartItem = params.data as ShoppingCartItemDto;
            return {
              ...params,
              t: t,
              price: shoppingCartItem.price,
              quotedPrice: shoppingCartItem.quotedPrice,
              onDelete:
                !isReadOnly || getItemError(shoppingCartState, shoppingCartItem, t) || reviseMode
                  ? deleteShoppingCartItem
                  : undefined,
              accessoriesLink:
                !isReadOnly && !shoppingCartItem.isModification
                  ? shoppingCartItem.hasAccessories
                    ? getConfiguratorLink(
                        configuratorBaseUrl,
                        shoppingCartItem.materialCode ?? "",
                        !isStaticProduct(shoppingCartItem.category)
                      ) + "?anchor=accessories"
                    : undefined
                  : undefined,
              openEditDialogAndSetSelectedShoppingCartItem:
                isReadOnly && !reviseMode
                  ? undefined
                  : openEditDialogAndSetSelectedShoppingCartItem,
              onCreateModificationCase: (description: string) => {
                if (shoppingCartItem.id && createModificationCase) {
                  createModificationCase(shoppingCartItem.id, description);
                }
              }
            };
          },
          comparator: compareStringsAsNumbers,
          valueGetter: (params) =>
            hidePrices
              ? t("(Hidden)")
              : parseFloat(params.data?.price || "0") !== 0
              ? params.data?.quotedPrice ?? params.data?.price
              : undefined,
          autoHeight: true,
          cellStyle: { border: "none" },
          flex: 1,
          minWidth: isReadOnly ? 140 : 290
        }
      ],
      [
        t,
        isBasedOnFrameAgreement,
        isReadOnly,
        shoppingCartState,
        reviseMode,
        hidePrices,
        companyIsInternalOrMaintained,
        updateShoppingCartItem,
        lowQuantityPercentage,
        deleteShoppingCartItem,
        configuratorBaseUrl,
        openEditDialogAndSetSelectedShoppingCartItem,
        createModificationCase
      ]
    );

    const getRowId = useCallback(
      (params: GetRowIdParams<ShoppingCartItemDto | ShoppingCartModificationItemDto>) =>
        `${params.data.position}_${params.data.id}_${params.data.orderingCode}`,
      []
    );

    return (
      <DetailGrid
        masterDetail
        keepDetailRows
        detailRowAutoHeight
        embedFullWidthRows
        detailCellRendererParams={(params: any) => {
          const shoppingCartItem = params?.data as ShoppingCartItemDto;
          if (shoppingCartItem?.isModification == true) {
            const isQuotedShoppingCartItem = !!shoppingCartItem.quotedPrice;
            return {
              // provide the Grid Options to use on the Detail Grid
              detailGridOptions: {
                columnDefs: [
                  {
                    ...createHeaderAndHeaderTooltip("#", t("Position")),
                    field: "position",
                    width: 50
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Ordering code")),
                    field: "orderingCode",
                    hide: true
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Content of modification")),
                    field: "serialNumbers",
                    colId: "product",
                    autoHeight: true,
                    cellRenderer: GridProductRenderer,
                    valueGetter: (params: ValueGetterParams) =>
                      getProductColumnValue(params.node, t),
                    cellRendererParams: (params: ICellRendererParams): GridProductRendererProps => {
                      const shoppingCartModificationItem =
                        params.data as ShoppingCartModificationItemDto;
                      return {
                        ...params,
                        t: t,
                        displayName: isDetailProduct(shoppingCartModificationItem.displayName)
                          ? shoppingCartModificationItem.orderingCode
                          : shoppingCartModificationItem.displayName,
                        imgUrl: shoppingCartModificationItem.imageUrl,
                        compCodeOrOrderingCode:
                          shoppingCartModificationItem.compositionCode ??
                          shoppingCartModificationItem.orderingCode,
                        orderingCode:
                          shoppingCartModificationItem.compositionCode &&
                          shoppingCartModificationItem.orderingCode !==
                            shoppingCartModificationItem.compositionCode
                            ? shoppingCartModificationItem.orderingCode
                            : null,
                        additionalInformation: shoppingCartModificationItem.additionalInformation,
                        serialNumbers: shoppingCartModificationItem.serialNumbers,
                        onClick: () => {
                          params.node.setExpanded(!params.node.expanded);
                          params.api.refreshCells({ force: true });
                        },
                        showDetailsText: params.node.expanded
                          ? t("Hide details")
                          : t("Show details"),
                        showDetails: getShowDetails(shoppingCartModificationItem),
                        icon: params.node.expanded ? "abb/caret-up" : "abb/caret-down",
                        isSimpleRenderer: isReadOnly,
                        onRemoveSerialNumber:
                          isReadOnly || params.data.isHideDelete
                            ? undefined
                            : (id: number, serialNumber: string) => {
                                if (shoppingCartModificationItem.shoppingCartItemId) {
                                  deleteShoppingCartModificationItemSerialNumber(id, serialNumber);
                                }
                              },
                        reviseMode
                      };
                    },
                    wrapText: true,
                    flex: 1,
                    minWidth: 520,
                    maxWidth: 700
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Quantity")),
                    field: "quantity",
                    minWidth: 110,
                    maxWidth: 110
                  },
                  {
                    ...createHeaderAndHeaderTooltip(t("Price")),
                    cellRenderer: memo(GridPriceAndActionsRenderer),
                    cellRendererParams: (
                      params: ICellRendererParams
                    ): GridPriceAndActionsRendererProps => {
                      const shoppingCartModificationItem =
                        params.data as ShoppingCartModificationItemDto;
                      return {
                        ...params,
                        price: shoppingCartModificationItem.price,
                        quotedPrice: shoppingCartModificationItem.quotedPrice,
                        isModificationItem:
                          !shoppingCartModificationItem.hasOwnProperty("isModification"),
                        onDelete:
                          isReadOnly || params.data.isHideDelete
                            ? undefined
                            : deleteShoppingCartModificationItem,
                        onShowInvalidModificationProductDialog:
                          shoppingCartModificationItem.price != null || hidePrices
                            ? undefined
                            : openInvalidModificationProductDialog
                      };
                    },
                    valueGetter: (params: ValueGetterParams) =>
                      params.data.quotedPrice ?? params.data.price,
                    comparator: compareStringsAsNumbers,
                    cellClass: "grid-react-container-flex ag-mod-sales-cell",
                    cellStyle: { border: "none" },
                    flex: 1,
                    width: 400,
                    hide: isQuotedShoppingCartItem
                  }
                ],
                defaultColDef: {
                  cellClass: "ag-mod-sales-cell"
                },
                masterDetail: true,
                keepDetailRows: true,
                detailRowAutoHeight: true,
                detailCellRendererParams: detailsGrid(t),
                suppressCellFocus: true,
                enableCellTextSelection: true,
                loadingOverlayComponent: GridLoadingOverlay,
                getContextMenuItems: (params: GetContextMenuItemsParams) =>
                  getContextMenuItemsShoppingCartItem({
                    params: params,
                    t: t,
                    dispatch: dispatch,
                    shoppingCartIdentifier: shoppingCartIdentifier
                  }),
                getRowClass: (): string | string[] => {
                  return "ag-details-mod-sales-row";
                },
                popupParent: getPopupParentDocumentBody(),
                context: { hidePrices }
              } as AgGridReactProps,
              // get the rows for each Detail Grid
              getDetailRowData: (params: any) => {
                const modificationItems = params.data.shoppingCartModificationItems.map(
                  (item: any) => {
                    return {
                      ...item,
                      isHideDelete: !params.data.hasPendingModification
                    };
                  }
                );
                params.successCallback(modificationItems);
              },
              getRowId,
              context: { hidePrices }
            } as AgGridReactProps;
          } else {
            return {
              ...detailsGrid(t),
              context: { hidePrices }
            };
          }
        }}
        isRowMaster={(item) => {
          return (
            !isStaticProduct(item.category) ||
            isDetailProduct(item.displayName) ||
            item.isModification
          );
        }}
        t={t}
        rowData={rowData}
        getRowId={getRowId}
        columnDefs={colDefs}
        suppressRowClickSelection={true}
        animateRows
        rowClassRules={{
          "grid-white-background": (params) =>
            params.data.isPriceValid === true && params.data.isValidProduct === true,
          "grid-red-background": (params) =>
            params.data.isPriceValid === false || params.data.isValidProduct === false
        }}
        disableResize={false}
        disableColumnAutoSize
        domLayout="autoHeight"
        defaultCsvExportParams={getCsvExportParams(
          `${t("ShoppingCartItems")}_${shoppingCartReference || "SC"}`,
          true
        )}
        defaultExcelExportParams={getExcelExportParams(
          `${t("ShoppingCartItems")}_${shoppingCartReference || "SC"}`,
          undefined,
          true
        )}
        excelStyles={getExcelStyles(theme)}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit();
          setGridApi(params.api);
        }}
        rowClass={"grid-row-padding"}
        colDefDefault={{
          tooltipValueGetter: undefined,
          cellStyle: { display: "flex", alignItems: "center" },
          cellClass: "start-from-top"
        }}
        suppressCellFocus={true}
        enableCellTextSelection
        getContextMenuItems={(params) =>
          getContextMenuItemsShoppingCartItem({
            params: params,
            t: t,
            dispatch: dispatch,
            shoppingCartIdentifier: shoppingCartIdentifier
          })
        }
        context={{ hidePrices }}
      ></DetailGrid>
    );
  }
);
