import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Input } from "@abb/abb-common-ux-react";
import { TFunction } from "i18next";
import { handlePressEnter } from "utilities/formikUtils";
import * as Yup from "yup";

const Container = styled.div`
  display: flex;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  width: 100%;
  flex-direction: column;
  margin-top: 0;
`;

const SmallHeader = styled.h5`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 15px;
`;

const NoteText = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.theme.sizes.s};
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 15px;
  color: ${(props) => props.theme.colors.grey60};
`;

const validationSchema = Yup.string().email("Invalid email format").required("Email is required");

interface EmailInputProps {
  t: TFunction;
  emailHeader?: string | null;
  isSimpleRenderer?: boolean;
  emailHelpText?: string | null;
  isSoftwareProduct?: boolean;
  isModification?: boolean;
  softwarePurchaseEmail?: string | null;
  note?: string | null;
  onUpdateSoftwarePurchaseEmail?: (email: string) => void;
}

export const EmailInput = ({
  t,
  emailHeader,
  isSimpleRenderer,
  emailHelpText,
  isSoftwareProduct,
  isModification,
  softwarePurchaseEmail,
  note,
  onUpdateSoftwarePurchaseEmail
}: EmailInputProps): JSX.Element => {
  const [email, setEmail] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(
    isSoftwareProduct || (isSoftwareProduct && !!softwarePurchaseEmail) || isModification
  );
  const [isEmailValidMessage, setIsEmailValidMessage] = useState<string>();

  useEffect(() => {
    setEmail(softwarePurchaseEmail ?? "");
  }, [setEmail, softwarePurchaseEmail]);

  const handleSubmitEmail = useCallback(() => {
    if (!isEmailValid || email.trim() === softwarePurchaseEmail?.trim()) {
      return;
    }
    if (onUpdateSoftwarePurchaseEmail) {
      onUpdateSoftwarePurchaseEmail(email);
    }
  }, [email, isEmailValid, softwarePurchaseEmail, onUpdateSoftwarePurchaseEmail]);

  return (
    <Container>
      <SmallHeader>
        {emailHeader ? (
          <>
            <span>{emailHeader}</span>
            {isSoftwareProduct ? (
              <span className={"ABB_CommonUX_Input__requiredAsterisk"}>&nbsp;*</span>
            ) : null}
          </>
        ) : null}
      </SmallHeader>
      {isSimpleRenderer ? (
        <Text>{email}</Text>
      ) : (
        <div>
          <Input
            type="normal"
            dataType="email"
            description={emailHelpText ? t(emailHelpText) : ""}
            placeholder={t("Enter email")}
            showClearIcon
            instantValidation
            showValidationBarWhenInvalid
            showValidationIconWhenInvalid
            validationResult={{ valid: isEmailValid ?? false, text: isEmailValidMessage }}
            value={email}
            onValueChange={(email: string) => {
              setEmail(email);
              try {
                const isEmailValid =
                  validationSchema.isValidSync(email) || (isModification && !email);
                setIsEmailValid(isEmailValid);
                if (isEmailValid) {
                  setIsEmailValidMessage(undefined);
                } else {
                  setIsEmailValidMessage(validationSchema.validateSync(email));
                }
              } catch (e) {
                setIsEmailValid(false);
                if (e instanceof Yup.ValidationError) {
                  setIsEmailValidMessage(e.message);
                }
              }
            }}
            onKeyUp={(event) => {
              handlePressEnter(event, handleSubmitEmail);
            }}
            onLostFocus={() => {
              handleSubmitEmail();
            }}
            style={{ lineHeight: "normal" }}
          />
        </div>
      )}

      {note ? <NoteText>{note}</NoteText> : null}
    </Container>
  );
};
