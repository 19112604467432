import React from "react";
import {
  Button as AbbButton,
  ButtonProps as AbbButtonProps
} from "@abb/abb-common-ux-react/components/Button";
import styled from "styled-components";

const CustomButton = styled(AbbButton)<{ hasIcon?: boolean }>`
  padding-inline: ${(props) => (props.hasIcon ? props.theme.sizes.s : undefined)};
  :hover {
    text-decoration: none;
  }
`;

const getButtonType = (type: ButtonType): AbbButtonProps["type"] => {
  switch (type) {
    case "primary":
      return "primary-red";
    case "secondary":
      return "ghost";
    case "primary-black":
      return "primary-black";
    case "discreet":
      return "discreet-black";
  }
};

type ButtonType = "primary" | "secondary" | "primary-black" | "discreet";

interface ButtonProps
  extends Omit<
    AbbButtonProps,
    "type" | "shape" | "text" | "icon" | "sizeClass" | "download" | "isLoading"
  > {
  buttonType: ButtonType;
  /** Text to display on button. Note, button does not take any children, so this plus icon are the only ways to set the button content. */
  text?: string;
  /** Optional icon shown on the left before the text. */
  icon?: string;
  /** Three size classes */
  sizeClass?: "small" | "medium" | "large";
  /** Set to true, to inject a spinner in place of the icon, and at the same time disable the button */
  isLoading?: boolean;
  /** Standard HTML link attribute. Only used when 'href' is given. */
  download?: boolean | string;
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => (
  <CustomButton
    type={getButtonType(props.buttonType)}
    hasIcon={!!props.icon && !!props.text}
    shape={"pill"}
    {...props}
  />
);
