import {
  createGenericSaga,
  defaultSagaErrorHandler,
  SagaBuilder
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";
import { userActions } from "../actions/userActions";
import { userSagas } from "./userSagas";

export const consentSagas = {
  privacyConsent: createGenericSaga(
    "consentSagas/privacyConsent",
    getApiRegistry().usersApi.apiUsersPrivacyConsentPost.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onSuccess: (_request, response) => {
        if (response.userAccountCreated) {
          return new SagaBuilder()
            .dispatch(userSagas.getUserInformation.createAction(undefined))
            .dispatch(userActions.togglePrivacyConsentDialogVisibility(false))
            .build()();
        } else {
          return undefined;
        }
      },
      onFail: defaultSagaErrorHandler
    }
  )
};
