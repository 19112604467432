import React from "react";
import styled from "styled-components";
import { Icon } from "@abb/abb-common-ux-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { commonUXTheme } from "styles/commonUXVariables";
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from "react-router-dom";

const LinkWrapper = styled(ReactRouterLink)<{ $displayInline?: boolean; isLoading?: boolean }>`
  display: ${(props) => (props.$displayInline ? "inline" : "flex")};
  align-items: center;
  text-decoration: none;
  color: unset;
  :hover {
    cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
    text-decoration: none !important;
    .LinkText {
      text-decoration: underline;
    }
  }
`;
const FakeLinkWrapper = styled.span<{ displayInline?: boolean; isLoading?: boolean }>`
  display: ${(props) => (props.displayInline ? "inline" : "flex")};
  align-items: center;
  text-decoration: none;
  color: unset;
  :hover {
    cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
    text-decoration: none !important;
    .LinkText {
      text-decoration: underline;
    }
  }
`;
const LinkText = styled.span<{ isLoading?: boolean; underlined: boolean }>`
  opacity: ${(props) => (props.isLoading ? "0.5" : "1")};
  ${(props) => (props.underlined ? "text-decoration: underline;" : "")}
`;
const CustomBeforeIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.sizes.s};
`;
const CustomAfterIcon = styled(Icon)`
  margin-left: ${(props) => props.theme.sizes.s};
`;

interface LinkProps extends ReactRouterLinkProps {
  /** Pixel sizes for 'small', 'medium' and 'large' are 16, 24 and 32, respectively. */
  sizeClass?: "small" | "medium" | "large";

  isLoading?: boolean;
  /**
   * The value of the progress indicator.
   * Value between 0 and 100.
   * If not set loading type indeterminate
   */
  loadingValue?: number;

  displayInline?: boolean;
  /** Standard HTML link attribute. */
  underlined?: boolean;
  iconBefore?: string;
  iconAfter?: string;
  /** Have only link-like visuals, but no hyperlink */
  onlyOnClick?: boolean;
}
export const Link: React.FunctionComponent<LinkProps> = ({
  iconBefore,
  iconAfter,
  sizeClass,
  onClick,
  displayInline,
  underlined = false,
  isLoading,
  loadingValue,
  onlyOnClick,
  ...props
}: React.PropsWithChildren<LinkProps>) => {
  const contents = (
    <>
      {isLoading ? (
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
            marginRight: commonUXTheme.sizes.m
          }}
        >
          <CircularProgress
            variant={loadingValue ? "determinate" : "indeterminate"}
            value={loadingValue}
            size={
              sizeClass === "large"
                ? parseInt(commonUXTheme.sizes.lm) * 4
                : sizeClass === "medium"
                ? parseInt(commonUXTheme.sizes.lm) * 2
                : commonUXTheme.sizes.lm
            }
            sx={{ color: commonUXTheme.colors.grey40 }}
          />
          {loadingValue ? (
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                fontSize={"0.5rem"}
              >{`${loadingValue}%`}</Typography>
            </Box>
          ) : undefined}
        </Box>
      ) : iconBefore ? (
        <CustomBeforeIcon name={iconBefore} sizeClass={sizeClass} />
      ) : undefined}
      <LinkText
        className="LinkText"
        {...props}
        isLoading={isLoading}
        underlined={underlined}
        onClick={isLoading ? undefined : onClick}
      >
        {props.children}
      </LinkText>
      {iconAfter ? <CustomAfterIcon name={iconAfter} sizeClass={sizeClass} /> : undefined}
    </>
  );
  return !onlyOnClick ? (
    <LinkWrapper {...props} $displayInline={displayInline} isLoading={isLoading}>
      {contents}
    </LinkWrapper>
  ) : (
    <FakeLinkWrapper {...props} displayInline={displayInline} isLoading={isLoading}>
      {contents}
    </FakeLinkWrapper>
  );
};
