/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetGmdProductHistoryCommand
 */
export interface GetGmdProductHistoryCommand {
    /**
     * 
     * @type {string}
     * @memberof GetGmdProductHistoryCommand
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof GetGmdProductHistoryCommand
     */
    materialCode: string;
}

export function GetGmdProductHistoryCommandFromJSON(json: any): GetGmdProductHistoryCommand {
    return GetGmdProductHistoryCommandFromJSONTyped(json, false);
}

export function GetGmdProductHistoryCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGmdProductHistoryCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serialNumber'],
        'materialCode': json['materialCode'],
    };
}

export function GetGmdProductHistoryCommandToJSON(value?: GetGmdProductHistoryCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'materialCode': value.materialCode,
    };
}

