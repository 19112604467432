/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NodeFlattenedDto
 */
export interface NodeFlattenedDto {
    /**
     * 
     * @type {string}
     * @memberof NodeFlattenedDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NodeFlattenedDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof NodeFlattenedDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof NodeFlattenedDto
     */
    parentId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof NodeFlattenedDto
     */
    readonly articlesId?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NodeFlattenedDto
     */
    readonly subNodesId?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NodeFlattenedDto
     */
    prevId?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeFlattenedDto
     */
    nextId?: string;
}

export function NodeFlattenedDtoFromJSON(json: any): NodeFlattenedDto {
    return NodeFlattenedDtoFromJSONTyped(json, false);
}

export function NodeFlattenedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeFlattenedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'order': json['order'],
        'parentId': json['parentId'],
        'articlesId': !exists(json, 'articlesId') ? undefined : json['articlesId'],
        'subNodesId': !exists(json, 'subNodesId') ? undefined : json['subNodesId'],
        'prevId': !exists(json, 'prevId') ? undefined : json['prevId'],
        'nextId': !exists(json, 'nextId') ? undefined : json['nextId'],
    };
}

export function NodeFlattenedDtoToJSON(value?: NodeFlattenedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'parentId': value.parentId,
        'prevId': value.prevId,
        'nextId': value.nextId,
    };
}

