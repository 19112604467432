/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderNotificationDto
 */
export interface OrderNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof OrderNotificationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderNotificationDto
     */
    orderAcknowledgementNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderNotificationDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderNotificationDto
     */
    notificationEmail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderNotificationDto
     */
    notified?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderNotificationDto
     */
    queued?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderNotificationDto
     */
    created?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderNotificationDto
     */
    rowCreated?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderNotificationDto
     */
    orderExists?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderNotificationDto
     */
    notificationWillBeQueued?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderNotificationDto
     */
    hangFireJobFailed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderNotificationDto
     */
    hangFireJobFailedDescription?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderNotificationDto
     */
    notificationAdditionalEmails?: Array<string> | null;
}

export function OrderNotificationDtoFromJSON(json: any): OrderNotificationDto {
    return OrderNotificationDtoFromJSONTyped(json, false);
}

export function OrderNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderAcknowledgementNumber': !exists(json, 'orderAcknowledgementNumber') ? undefined : json['orderAcknowledgementNumber'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'notificationEmail': !exists(json, 'notificationEmail') ? undefined : json['notificationEmail'],
        'notified': !exists(json, 'notified') ? undefined : (json['notified'] === null ? null : new Date(json['notified'])),
        'queued': !exists(json, 'queued') ? undefined : (json['queued'] === null ? null : new Date(json['queued'])),
        'created': !exists(json, 'created') ? undefined : (json['created'] === null ? null : new Date(json['created'])),
        'rowCreated': !exists(json, 'rowCreated') ? undefined : (json['rowCreated'] === null ? null : new Date(json['rowCreated'])),
        'orderExists': !exists(json, 'orderExists') ? undefined : json['orderExists'],
        'notificationWillBeQueued': !exists(json, 'notificationWillBeQueued') ? undefined : json['notificationWillBeQueued'],
        'hangFireJobFailed': !exists(json, 'hangFireJobFailed') ? undefined : json['hangFireJobFailed'],
        'hangFireJobFailedDescription': !exists(json, 'hangFireJobFailedDescription') ? undefined : json['hangFireJobFailedDescription'],
        'notificationAdditionalEmails': !exists(json, 'notificationAdditionalEmails') ? undefined : json['notificationAdditionalEmails'],
    };
}

export function OrderNotificationDtoToJSON(value?: OrderNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'type': value.type,
        'notificationEmail': value.notificationEmail,
        'notified': value.notified === undefined ? undefined : (value.notified === null ? null : value.notified.toISOString()),
        'queued': value.queued === undefined ? undefined : (value.queued === null ? null : value.queued.toISOString()),
        'created': value.created === undefined ? undefined : (value.created === null ? null : value.created.toISOString()),
        'rowCreated': value.rowCreated === undefined ? undefined : (value.rowCreated === null ? null : value.rowCreated.toISOString()),
        'orderExists': value.orderExists,
        'notificationWillBeQueued': value.notificationWillBeQueued,
        'hangFireJobFailed': value.hangFireJobFailed,
        'hangFireJobFailedDescription': value.hangFireJobFailedDescription,
        'notificationAdditionalEmails': value.notificationAdditionalEmails,
    };
}

