import MarkdownParser from "framework/classes/markdownParser";
import React from "react";
import styled from "styled-components";
import { clean } from "utilities/sanitization";

export interface MarkdownViewerProps {
  markdownContent: string;
  applyCommonUxClassName?: boolean;
}

export const MarkdownContainer = styled.div`
  width: 100%;
`;

const MarkdownViewer = ({
  markdownContent,
  applyCommonUxClassName
}: MarkdownViewerProps): JSX.Element => {
  const parser = new MarkdownParser(applyCommonUxClassName);

  if (!markdownContent) {
    return <></>;
  }
  return (
    <MarkdownContainer
      className="markdown-content"
      dangerouslySetInnerHTML={{
        __html: clean(parser.getParser().render(markdownContent)).cleaned
      }}
    />
  );
};

export default MarkdownViewer;
