import moment, { unitOfTime } from "moment";
import { ValueFormatterParams } from "ag-grid-community";

const validDatetimeFormat = "DD/MMM/YYYY";
const validDatetimeFormatWithTime = "DD/MMM/YYYY HH:mm:ss";

export function getValidDateFormat(includeTime?: boolean): string {
  return !includeTime ? validDatetimeFormat : validDatetimeFormatWithTime;
}

type StartOrEndOf = "endOf" | "startOf";

export function getDateTime(
  dateString: string | undefined,
  format?: string,
  startOrEndOf?: StartOrEndOf
): Date | undefined {
  if (dateString) {
    const date = moment(dateString, format ?? getDefaultAgGridDateFormat()).utc(true);
    if (startOrEndOf === "endOf") {
      return date.endOf("day").toDate();
    } else if (startOrEndOf === "startOf") {
      return date.startOf("day").toDate();
    }
    return date.toDate();
  }
}

export function getDefaultAgGridDateFormat(): string {
  return "YYYY-MM-DD HH:mm:ss";
}

export function isDateInThePast(
  date: string | moment.Moment,
  granularity?: unitOfTime.StartOf
): boolean {
  const dateTime = moment(date, getValidDateFormat());
  return dateTime.isValid() && dateTime.isBefore(moment(), granularity ?? "day");
}

export function isDate1beforeDate2(
  date1: string | moment.Moment,
  date2: string | moment.Moment,
  granularity?: unitOfTime.StartOf
): boolean {
  const dateTime1 = moment(date1, getValidDateFormat());
  const dateTime2 = moment(date2, getValidDateFormat());
  return (
    dateTime1.isValid() &&
    dateTime2.isValid() &&
    dateTime1.isBefore(dateTime2, granularity ?? "day")
  );
}

export function formGridDatetime(params: ValueFormatterParams): string {
  if (!params.value) {
    return "";
  }
  return moment(params.value).format(getValidDateFormat());
}

export function formatDate(date: Date, includeTime?: boolean): string {
  return moment(date).format(getValidDateFormat(includeTime));
}

export function daysUntil(date: moment.Moment) {
  // Math.round is used to fix the following example:
  // Currently is Monday 9AM and `date` is on Wednesday 8AM
  // Intuitively it feels like 2 days, but the actual day count is around 1.96,
  // and the moment library would round it to 1 day
  return Math.round(date.diff(moment(), "days", true));
}
