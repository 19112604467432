/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    ContactSalesSupportReasonDto,
    ContactSalesSupportReasonDtoFromJSON,
    ContactSalesSupportReasonDtoFromJSONTyped,
    ContactSalesSupportReasonDtoToJSON,
} from './ContactSalesSupportReasonDto';
import {
    ManageQuoteDto,
    ManageQuoteDtoFromJSON,
    ManageQuoteDtoFromJSONTyped,
    ManageQuoteDtoToJSON,
} from './ManageQuoteDto';
import {
    OrderDto,
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';
import {
    OrderLineDto,
    OrderLineDtoFromJSON,
    OrderLineDtoFromJSONTyped,
    OrderLineDtoToJSON,
} from './OrderLineDto';
import {
    ShippingDetailsDto,
    ShippingDetailsDtoFromJSON,
    ShippingDetailsDtoFromJSONTyped,
    ShippingDetailsDtoToJSON,
} from './ShippingDetailsDto';

/**
 * 
 * @export
 * @interface OrderDetailsVm
 */
export interface OrderDetailsVm {
    /**
     * 
     * @type {OrderDto}
     * @memberof OrderDetailsVm
     */
    order: OrderDto;
    /**
     * 
     * @type {Array<OrderLineDto>}
     * @memberof OrderDetailsVm
     */
    orderLines: Array<OrderLineDto>;
    /**
     * 
     * @type {Array<ShippingDetailsDto>}
     * @memberof OrderDetailsVm
     */
    shippingDetails: Array<ShippingDetailsDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDetailsVm
     */
    offerReferences?: Array<string> | null;
    /**
     * 
     * @type {Array<ManageQuoteDto>}
     * @memberof OrderDetailsVm
     */
    quotes?: Array<ManageQuoteDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailsVm
     */
    marginAnalysisToolUrl?: string | null;
    /**
     * 
     * @type {Array<ContactSalesSupportReasonDto>}
     * @memberof OrderDetailsVm
     */
    contactSalesSupportReasons: Array<ContactSalesSupportReasonDto>;
    /**
     * 
     * @type {CompanyDto}
     * @memberof OrderDetailsVm
     */
    company?: CompanyDto;
}

export function OrderDetailsVmFromJSON(json: any): OrderDetailsVm {
    return OrderDetailsVmFromJSONTyped(json, false);
}

export function OrderDetailsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': OrderDtoFromJSON(json['order']),
        'orderLines': ((json['orderLines'] as Array<any>).map(OrderLineDtoFromJSON)),
        'shippingDetails': ((json['shippingDetails'] as Array<any>).map(ShippingDetailsDtoFromJSON)),
        'offerReferences': !exists(json, 'offerReferences') ? undefined : json['offerReferences'],
        'quotes': !exists(json, 'quotes') ? undefined : (json['quotes'] === null ? null : (json['quotes'] as Array<any>).map(ManageQuoteDtoFromJSON)),
        'marginAnalysisToolUrl': !exists(json, 'marginAnalysisToolUrl') ? undefined : json['marginAnalysisToolUrl'],
        'contactSalesSupportReasons': ((json['contactSalesSupportReasons'] as Array<any>).map(ContactSalesSupportReasonDtoFromJSON)),
        'company': !exists(json, 'company') ? undefined : CompanyDtoFromJSON(json['company']),
    };
}

export function OrderDetailsVmToJSON(value?: OrderDetailsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': OrderDtoToJSON(value.order),
        'orderLines': ((value.orderLines as Array<any>).map(OrderLineDtoToJSON)),
        'shippingDetails': ((value.shippingDetails as Array<any>).map(ShippingDetailsDtoToJSON)),
        'offerReferences': value.offerReferences,
        'quotes': value.quotes === undefined ? undefined : (value.quotes === null ? null : (value.quotes as Array<any>).map(ManageQuoteDtoToJSON)),
        'marginAnalysisToolUrl': value.marginAnalysisToolUrl,
        'contactSalesSupportReasons': ((value.contactSalesSupportReasons as Array<any>).map(ContactSalesSupportReasonDtoToJSON)),
        'company': CompanyDtoToJSON(value.company),
    };
}

