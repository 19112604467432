import { deliveriesHomeSagas } from "../sagas/deliveriesHomeSagas";
import { CompanyDto } from "../../../../api/models/CompanyDto";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import {
  DeliveriesHomeViewActions,
  DeliveriesHomeViewActionTypes
} from "../actions/deliveriesHomeViewActions";
import {
  SystemSettingsVm,
  OrdersVm,
  ApiOrderGetRequest,
  CompaniesVm,
  ApiCompaniesGetRequest,
  GeneralApplicationSettingsDto,
  UserVm,
  CurrentUserDto,
  AccessRights
} from "../../../../api";
import { orderSagas } from "../../orders/sagas/orderSagas";
import { userSagas } from "../../../common/sagas/userSagas";
import { criteriaConstants } from "../../../../constants/criteriaConstants";
import { canSeeAllCompaniesInCompanyDropdown, isAuthorizedToComponent } from "utilities/authUtils";
import { NavMenuCompany } from "shared/menubar/components/NavItem";

interface Requests {
  getCompanies: RequestState<CompaniesVm, ApiCompaniesGetRequest>;
  getSystemSettings: RequestState<SystemSettingsVm>;
  updateFrontPageContent: RequestState<SystemSettingsVm>;
  getOrders: RequestState<OrdersVm, ApiOrderGetRequest>;
  getUserInformation: RequestState<CurrentUserDto>;
  getHelpRequest: RequestState<void, undefined>;
}

export const AllCompaniesSelectionIdentifier = undefined;

export const AllCompaniesSelection: NavMenuCompany = {
  label: "All companies",
  value: AllCompaniesSelectionIdentifier
};

export interface DeliveriesHomeViewState {
  requests: Requests;
  companies: CompanyDto[];
  selectedCompany: NavMenuCompany | undefined;
  markdownContent: string;
  companiesCriteria: ApiCompaniesGetRequest | undefined;
  generalApplicationSettings: GeneralApplicationSettingsDto;
  companyAccessAuditIntervalInDays: number;
}

const orderDropdownAccessRights: AccessRights[] = [
  AccessRights.AdvancedOrderTracking,
  AccessRights.ViewCompanyOrders,
  AccessRights.ViewAllOrders
];

export const companySearchDefaultCriteria: ApiCompaniesGetRequest = {
  criteriaPageSize: criteriaConstants.COMPANY_DROPDOWN,
  criteriaPage: 0,
  criteriaSortColumn: "companyDisplayName",
  criteriaIsAscendingOrder: true,
  criteriaRequireAnyOfAccessRight: orderDropdownAccessRights
};

const defaultState: DeliveriesHomeViewState = {
  requests: {
    getCompanies: createInitialRequest(),
    getSystemSettings: createInitialRequest(),
    updateFrontPageContent: createInitialRequest(),
    getOrders: createInitialRequest(),
    getUserInformation: createInitialRequest(),
    getHelpRequest: createInitialRequest()
  },
  companies: [],
  selectedCompany: undefined,
  markdownContent: "",
  companyAccessAuditIntervalInDays: 0,
  companiesCriteria: undefined,
  generalApplicationSettings: {
    emailDeliverIT: "",
    environment: "",
    applicationName: "",
    showEnvironment: false
  }
};

export function deliveriesHomeViewReducer(
  state: DeliveriesHomeViewState = defaultState,
  action: DeliveriesHomeViewActions
): DeliveriesHomeViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: deliveriesHomeSagas.getCompanies.actionTypes,
      key: "getCompanies"
    },
    {
      actionTypes: deliveriesHomeSagas.getSystemSettings.actionTypes,
      key: "getSystemSettings"
    },
    {
      actionTypes: deliveriesHomeSagas.updateFrontPageContent.actionTypes,
      key: "updateFrontPageContent"
    },
    {
      actionTypes: orderSagas.getOrders.actionTypes,
      key: "getOrders"
    },
    {
      actionTypes: userSagas.getUserInformation.actionTypes,
      key: "getUserInformation"
    },
    {
      actionTypes: userSagas.updateUserCompanyVisible.actionTypes,
      key: "updateUserCompanyVisible"
    },
    {
      actionTypes: deliveriesHomeSagas.sendHelpRequest.actionTypes,
      key: "getHelpRequest"
    }
  ]);

  if (deliveriesHomeSagas.getCompanies.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.companiesCriteria = state.requests.getCompanies.requestPayload;
      if (state.requests.getCompanies.requestPayload?.criteriaPage === 0) {
        draft.companies = action.payload.companies;
      } else {
        const newCompanies: CompanyDto[] = draft.companies;
        action.payload.companies.forEach((c) => newCompanies.push(c));
        draft.companies = newCompanies;
      }
    });
  } else if (deliveriesHomeSagas.getCompanies.isRequestAction(action)) {
    if (state.requests.getCompanies.requestPayload?.criteriaPage === 0) {
      state = produce(state, (draft) => {
        draft.companies = [];
      });
    }
  } else if (deliveriesHomeSagas.getSystemSettings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.markdownContent = action.payload.systemSetting.frontPageContent ?? "";
      draft.generalApplicationSettings = action.payload.generalApplicationSettings;
      draft.companyAccessAuditIntervalInDays = action.payload.companyAccessAuditIntervalInDays;
    });
  } else if (deliveriesHomeSagas.updateFrontPageContent.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.markdownContent = action.payload;
    });
  } else if (userSagas.updateUserCompanyVisible.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (
        action.payload.visible === false &&
        state.selectedCompany?.value === action.payload.companyId
      ) {
        draft.selectedCompany = undefined;
      }
    });
  } else if (userSagas.getUserInformation.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const selectedCompany = action.payload.userDetails?.selectedCompany;
      if (
        selectedCompany &&
        action.payload.userDetails &&
        validateSelectedCompany(action.payload)
      ) {
        draft.selectedCompany = {
          label: selectedCompany.companyDisplayName,
          value: selectedCompany.id
        };
      } else {
        if (
          isAuthorizedToComponent(action.payload.userAccessRights, {
            accessRights: [
              AccessRights.SystemAdmin,
              AccessRights.ManageDeliveriesCompanies,
              AccessRights.ManageDeliveriesUsers,
              AccessRights.ManageDeliveriesUsersLimited
            ],
            operation: "any"
          })
        ) {
          draft.selectedCompany = AllCompaniesSelection;
        } else {
          draft.selectedCompany = undefined;
        }
      }
    });
  }

  switch (action.type) {
    case DeliveriesHomeViewActionTypes.ChangeSelectedCompany:
      state = produce(state, (draft) => {
        if (!action.company) {
          draft.selectedCompany = AllCompaniesSelection;
        } else {
          draft.selectedCompany = {
            label: action.company.companyDisplayName,
            value: action.company.id
          };
        }
      });
      break;
    case DeliveriesHomeViewActionTypes.SetMarkdownContent:
      state = produce(state, (draft) => {
        draft.markdownContent = action.content;
      });
      break;

    default:
      break;
  }
  return state;
}

export const getCompanies = (state: AppState): CompanyDto[] =>
  state.deliveries.deliveriesHomeView.companies;

export const getCompaniesCriteria = (state: AppState): ApiCompaniesGetRequest | undefined =>
  state.deliveries.deliveriesHomeView.companiesCriteria;

export const getSelectedCompany = (state: AppState): NavMenuCompany | undefined =>
  state.deliveries.deliveriesHomeView.selectedCompany;

export const getMarkdownContent = (state: AppState): string =>
  state.deliveries.deliveriesHomeView.markdownContent;

export const getUpdateFrontPageContentRequest = (state: AppState): RequestState<SystemSettingsVm> =>
  state.deliveries.deliveriesHomeView.requests.updateFrontPageContent;

export const getGeneralApplicationSettings = (state: AppState): GeneralApplicationSettingsDto =>
  state.deliveries.deliveriesHomeView.generalApplicationSettings;

export const getCompanyAccessAuditInterval = (state: AppState): number =>
  state.deliveries.deliveriesHomeView.companyAccessAuditIntervalInDays;

export const getNavMenuCompanies = (state: AppState): NavMenuCompany[] => {
  const navMenuItems: NavMenuCompany[] = [];

  if (
    isAuthorizedToComponent(state.user.userAccessRights, {
      operation: "any",
      accessRights: [AccessRights.ManageDeliveriesCompanies, AccessRights.ViewAllOrders]
    })
  ) {
    navMenuItems.push(AllCompaniesSelection);
  }

  state.deliveries.deliveriesHomeView.companies.forEach((company) => {
    navMenuItems.push({
      value: company.id,
      label: company.companyDisplayName ?? ""
    });
  });
  return navMenuItems;
};

export const getGetOrdersRequest = (state: AppState): RequestState<OrdersVm, ApiOrderGetRequest> =>
  state.deliveries.deliveriesHomeView.requests.getOrders;

export const getCompaniesRequest = (
  state: AppState
): RequestState<CompaniesVm, ApiCompaniesGetRequest> =>
  state.deliveries.deliveriesHomeView.requests.getCompanies;

const validateSelectedCompany = (user: UserVm): boolean => {
  const selectedCompanyUser = user.userCompanyRoles.find(
    (cu) => cu.companyUser.companyId === user.userDetails?.selectedCompanyId
  )?.companyUser;

  const hasAllCompaniesVisible = isAuthorizedToComponent(
    user.userAccessRights,
    canSeeAllCompaniesInCompanyDropdown
  );

  if (hasAllCompaniesVisible) {
    return true;
  }
  // if company not visible and not 'admin' role
  if (!selectedCompanyUser?.visible) {
    return false;
  }

  if (
    (selectedCompanyUser?.company.active || selectedCompanyUser?.company.overrideStatusActive) &&
    user.userCompanyRoles?.some(
      (c) =>
        c.companyUser?.active === true &&
        c.companyUser.companyId === selectedCompanyUser?.company.id
    )
  ) {
    return true;
  }
  return false;
};

export const getHelpRequest = (state: AppState): RequestState<void, undefined> =>
  state.deliveries.deliveriesHomeView.requests.getHelpRequest;
