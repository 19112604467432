/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoriesMenuDto,
    CategoriesMenuDtoFromJSON,
    CategoriesMenuDtoFromJSONTyped,
    CategoriesMenuDtoToJSON,
} from './CategoriesMenuDto';
import {
    FeatureValuesProductVersionDetailDto,
    FeatureValuesProductVersionDetailDtoFromJSON,
    FeatureValuesProductVersionDetailDtoFromJSONTyped,
    FeatureValuesProductVersionDetailDtoToJSON,
} from './FeatureValuesProductVersionDetailDto';

/**
 * 
 * @export
 * @interface ResultComparisonVm
 */
export interface ResultComparisonVm {
    /**
     * 
     * @type {{ [key: string]: Array<FeatureValuesProductVersionDetailDto>; }}
     * @memberof ResultComparisonVm
     */
    productsFeatures: { [key: string]: Array<FeatureValuesProductVersionDetailDto>; };
    /**
     * 
     * @type {Array<CategoriesMenuDto>}
     * @memberof ResultComparisonVm
     */
    activeCategories: Array<CategoriesMenuDto>;
}

export function ResultComparisonVmFromJSON(json: any): ResultComparisonVm {
    return ResultComparisonVmFromJSONTyped(json, false);
}

export function ResultComparisonVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultComparisonVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productsFeatures': json['productsFeatures'],
        'activeCategories': ((json['activeCategories'] as Array<any>).map(CategoriesMenuDtoFromJSON)),
    };
}

export function ResultComparisonVmToJSON(value?: ResultComparisonVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productsFeatures': value.productsFeatures,
        'activeCategories': ((value.activeCategories as Array<any>).map(CategoriesMenuDtoToJSON)),
    };
}

