/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalesChannelTypes,
    SalesChannelTypesFromJSON,
    SalesChannelTypesFromJSONTyped,
    SalesChannelTypesToJSON,
} from './SalesChannelTypes';

/**
 * 
 * @export
 * @interface QuoteRequestedCommand
 */
export interface QuoteRequestedCommand {
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    shoppingCartGuid: string;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    quotationRequestMessage?: string | null;
    /**
     * 
     * @type {SalesChannelTypes}
     * @memberof QuoteRequestedCommand
     */
    salesChannelId?: SalesChannelTypes;
    /**
     * 
     * @type {Date}
     * @memberof QuoteRequestedCommand
     */
    expectedOrderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    projectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    sfdcReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerCompanyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerCompanyCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerCompanyCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    accountCustomerIcvCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerCompanyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerCompanyCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerCompanyCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    endCustomerCompanyGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    pgCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteRequestedCommand
     */
    frameAgreement?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof QuoteRequestedCommand
     */
    winningPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuoteRequestedCommand
     */
    quoteReceiverEmail?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuoteRequestedCommand
     */
    additionalQuoteReceivers?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuoteRequestedCommand
     */
    removedDefaultQuoteReceivers?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteRequestedCommand
     */
    isBuildQuote?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuoteRequestedCommand
     */
    isDraft?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuoteRequestedCommand
     */
    totalSalesPrice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof QuoteRequestedCommand
     */
    requestedEndDate?: Date | null;
}

export function QuoteRequestedCommandFromJSON(json: any): QuoteRequestedCommand {
    return QuoteRequestedCommandFromJSONTyped(json, false);
}

export function QuoteRequestedCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuoteRequestedCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shoppingCartGuid': json['shoppingCartGuid'],
        'quotationRequestMessage': !exists(json, 'quotationRequestMessage') ? undefined : json['quotationRequestMessage'],
        'salesChannelId': !exists(json, 'salesChannelId') ? undefined : SalesChannelTypesFromJSON(json['salesChannelId']),
        'expectedOrderDate': !exists(json, 'expectedOrderDate') ? undefined : (new Date(json['expectedOrderDate'])),
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'sfdcReference': !exists(json, 'sfdcReference') ? undefined : json['sfdcReference'],
        'accountCustomerCompanyName': !exists(json, 'accountCustomerCompanyName') ? undefined : json['accountCustomerCompanyName'],
        'accountCustomerStreetAddress': !exists(json, 'accountCustomerStreetAddress') ? undefined : json['accountCustomerStreetAddress'],
        'accountCustomerCompanyCity': !exists(json, 'accountCustomerCompanyCity') ? undefined : json['accountCustomerCompanyCity'],
        'accountCustomerCompanyCountry': !exists(json, 'accountCustomerCompanyCountry') ? undefined : json['accountCustomerCompanyCountry'],
        'accountCustomerCompanyCountryCode': !exists(json, 'accountCustomerCompanyCountryCode') ? undefined : json['accountCustomerCompanyCountryCode'],
        'accountCustomerIcvCode': !exists(json, 'accountCustomerIcvCode') ? undefined : json['accountCustomerIcvCode'],
        'endCustomerCompanyName': !exists(json, 'endCustomerCompanyName') ? undefined : json['endCustomerCompanyName'],
        'endCustomerStreetAddress': !exists(json, 'endCustomerStreetAddress') ? undefined : json['endCustomerStreetAddress'],
        'endCustomerCompanyCity': !exists(json, 'endCustomerCompanyCity') ? undefined : json['endCustomerCompanyCity'],
        'endCustomerCompanyCountryCode': !exists(json, 'endCustomerCompanyCountryCode') ? undefined : json['endCustomerCompanyCountryCode'],
        'endCustomerCompanyCountry': !exists(json, 'endCustomerCompanyCountry') ? undefined : json['endCustomerCompanyCountry'],
        'endCustomerCompanyGuid': !exists(json, 'endCustomerCompanyGuid') ? undefined : json['endCustomerCompanyGuid'],
        'pgCode': !exists(json, 'pgCode') ? undefined : json['pgCode'],
        'frameAgreement': !exists(json, 'frameAgreement') ? undefined : json['frameAgreement'],
        'winningPercentage': !exists(json, 'winningPercentage') ? undefined : json['winningPercentage'],
        'quoteReceiverEmail': !exists(json, 'quoteReceiverEmail') ? undefined : json['quoteReceiverEmail'],
        'additionalQuoteReceivers': !exists(json, 'additionalQuoteReceivers') ? undefined : json['additionalQuoteReceivers'],
        'removedDefaultQuoteReceivers': !exists(json, 'removedDefaultQuoteReceivers') ? undefined : json['removedDefaultQuoteReceivers'],
        'isBuildQuote': !exists(json, 'isBuildQuote') ? undefined : json['isBuildQuote'],
        'isDraft': !exists(json, 'isDraft') ? undefined : json['isDraft'],
        'totalSalesPrice': !exists(json, 'totalSalesPrice') ? undefined : json['totalSalesPrice'],
        'requestedEndDate': !exists(json, 'requestedEndDate') ? undefined : (json['requestedEndDate'] === null ? null : new Date(json['requestedEndDate'])),
    };
}

export function QuoteRequestedCommandToJSON(value?: QuoteRequestedCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shoppingCartGuid': value.shoppingCartGuid,
        'quotationRequestMessage': value.quotationRequestMessage,
        'salesChannelId': SalesChannelTypesToJSON(value.salesChannelId),
        'expectedOrderDate': value.expectedOrderDate === undefined ? undefined : (value.expectedOrderDate.toISOString()),
        'projectName': value.projectName,
        'sfdcReference': value.sfdcReference,
        'accountCustomerCompanyName': value.accountCustomerCompanyName,
        'accountCustomerStreetAddress': value.accountCustomerStreetAddress,
        'accountCustomerCompanyCity': value.accountCustomerCompanyCity,
        'accountCustomerCompanyCountry': value.accountCustomerCompanyCountry,
        'accountCustomerCompanyCountryCode': value.accountCustomerCompanyCountryCode,
        'accountCustomerIcvCode': value.accountCustomerIcvCode,
        'endCustomerCompanyName': value.endCustomerCompanyName,
        'endCustomerStreetAddress': value.endCustomerStreetAddress,
        'endCustomerCompanyCity': value.endCustomerCompanyCity,
        'endCustomerCompanyCountryCode': value.endCustomerCompanyCountryCode,
        'endCustomerCompanyCountry': value.endCustomerCompanyCountry,
        'endCustomerCompanyGuid': value.endCustomerCompanyGuid,
        'pgCode': value.pgCode,
        'frameAgreement': value.frameAgreement,
        'winningPercentage': value.winningPercentage,
        'quoteReceiverEmail': value.quoteReceiverEmail,
        'additionalQuoteReceivers': value.additionalQuoteReceivers,
        'removedDefaultQuoteReceivers': value.removedDefaultQuoteReceivers,
        'isBuildQuote': value.isBuildQuote,
        'isDraft': value.isDraft,
        'totalSalesPrice': value.totalSalesPrice,
        'requestedEndDate': value.requestedEndDate === undefined ? undefined : (value.requestedEndDate === null ? null : value.requestedEndDate.toISOString()),
    };
}

