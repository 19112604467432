/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralApplicationSettingsDto
 */
export interface GeneralApplicationSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralApplicationSettingsDto
     */
    applicationName: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralApplicationSettingsDto
     */
    environment: string;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    showEnvironment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    hideTermsAndConditionsLinks?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    disableOrdering?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    disableQuoting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    disableCountryContactPersonSimulation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralApplicationSettingsDto
     */
    disableIssuesMdr?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralApplicationSettingsDto
     */
    emailDeliverIT: string;
}

export function GeneralApplicationSettingsDtoFromJSON(json: any): GeneralApplicationSettingsDto {
    return GeneralApplicationSettingsDtoFromJSONTyped(json, false);
}

export function GeneralApplicationSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralApplicationSettingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationName': json['applicationName'],
        'environment': json['environment'],
        'showEnvironment': json['showEnvironment'],
        'hideTermsAndConditionsLinks': !exists(json, 'hideTermsAndConditionsLinks') ? undefined : json['hideTermsAndConditionsLinks'],
        'disableOrdering': !exists(json, 'disableOrdering') ? undefined : json['disableOrdering'],
        'disableQuoting': !exists(json, 'disableQuoting') ? undefined : json['disableQuoting'],
        'disableCountryContactPersonSimulation': !exists(json, 'disableCountryContactPersonSimulation') ? undefined : json['disableCountryContactPersonSimulation'],
        'disableIssuesMdr': !exists(json, 'disableIssuesMdr') ? undefined : json['disableIssuesMdr'],
        'emailDeliverIT': json['emailDeliverIT'],
    };
}

export function GeneralApplicationSettingsDtoToJSON(value?: GeneralApplicationSettingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationName': value.applicationName,
        'environment': value.environment,
        'showEnvironment': value.showEnvironment,
        'hideTermsAndConditionsLinks': value.hideTermsAndConditionsLinks,
        'disableOrdering': value.disableOrdering,
        'disableQuoting': value.disableQuoting,
        'disableCountryContactPersonSimulation': value.disableCountryContactPersonSimulation,
        'disableIssuesMdr': value.disableIssuesMdr,
        'emailDeliverIT': value.emailDeliverIT,
    };
}

