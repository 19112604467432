/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUserDto
 */
export interface CompanyUserDto {
    /**
     * 
     * @type {number}
     * @memberof CompanyUserDto
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserDto
     */
    systemMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDto
     */
    userActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUserDto
     */
    active: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUserDto
     */
    lastActive?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    userCompanyRoles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    userCompanyRoleAbbreviationAndName: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    userGlobalRoles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    userGlobalRoleAbbreviationAndName: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    businessUnits?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserDto
     */
    businessUnitsCodeAndName?: Array<string> | null;
}

export function CompanyUserDtoFromJSON(json: any): CompanyUserDto {
    return CompanyUserDtoFromJSONTyped(json, false);
}

export function CompanyUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'companyId': json['companyId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': json['email'],
        'systemMessage': !exists(json, 'systemMessage') ? undefined : json['systemMessage'],
        'userActive': json['userActive'],
        'active': json['active'],
        'lastActive': !exists(json, 'lastActive') ? undefined : (json['lastActive'] === null ? null : new Date(json['lastActive'])),
        'userCompanyRoles': json['userCompanyRoles'],
        'userCompanyRoleAbbreviationAndName': json['userCompanyRoleAbbreviationAndName'],
        'userGlobalRoles': json['userGlobalRoles'],
        'userGlobalRoleAbbreviationAndName': json['userGlobalRoleAbbreviationAndName'],
        'businessUnits': !exists(json, 'businessUnits') ? undefined : json['businessUnits'],
        'businessUnitsCodeAndName': !exists(json, 'businessUnitsCodeAndName') ? undefined : json['businessUnitsCodeAndName'],
    };
}

export function CompanyUserDtoToJSON(value?: CompanyUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'companyId': value.companyId,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'systemMessage': value.systemMessage,
        'userActive': value.userActive,
        'active': value.active,
        'lastActive': value.lastActive === undefined ? undefined : (value.lastActive === null ? null : value.lastActive.toISOString()),
        'userCompanyRoles': value.userCompanyRoles,
        'userCompanyRoleAbbreviationAndName': value.userCompanyRoleAbbreviationAndName,
        'userGlobalRoles': value.userGlobalRoles,
        'userGlobalRoleAbbreviationAndName': value.userGlobalRoleAbbreviationAndName,
        'businessUnits': value.businessUnits,
        'businessUnitsCodeAndName': value.businessUnitsCodeAndName,
    };
}

