import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const shoppingCartsViewSagas = {
  archiveShoppingCart: createGenericSaga(
    "shoppingCartsViewSagas/archiveShoppingCart",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsArchivePatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  orderPlacedExternally: createGenericSaga(
    "shoppingCartsViewSagas/orderPlacedExternally",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsOrderPlacedExternallyPatch.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  clone: createGenericSaga(
    "shoppingCartsViewSagas/clone",
    getApiRegistry().shoppingCartsApi.apiShoppingCartsClonePost.bind(
      getApiRegistry().shoppingCartsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Shopping cart cloned"),
      onFail: defaultSagaErrorHandler
    }
  )
};
