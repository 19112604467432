/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    ProductSeriesExplorerDto,
    ProductSeriesExplorerDtoFromJSON,
    ProductSeriesExplorerDtoFromJSONTyped,
    ProductSeriesExplorerDtoToJSON,
} from './ProductSeriesExplorerDto';
import {
    ProductVersionDetailDto,
    ProductVersionDetailDtoFromJSON,
    ProductVersionDetailDtoFromJSONTyped,
    ProductVersionDetailDtoToJSON,
} from './ProductVersionDetailDto';

/**
 * 
 * @export
 * @interface ProductsVm
 */
export interface ProductsVm {
    /**
     * 
     * @type {Array<ProductVersionDetailDto>}
     * @memberof ProductsVm
     */
    products: Array<ProductVersionDetailDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof ProductsVm
     */
    criteria: PaginationReturnModel;
    /**
     * 
     * @type {Array<ProductSeriesExplorerDto>}
     * @memberof ProductsVm
     */
    activeSeries: Array<ProductSeriesExplorerDto>;
}

export function ProductsVmFromJSON(json: any): ProductsVm {
    return ProductsVmFromJSONTyped(json, false);
}

export function ProductsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': ((json['products'] as Array<any>).map(ProductVersionDetailDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
        'activeSeries': ((json['activeSeries'] as Array<any>).map(ProductSeriesExplorerDtoFromJSON)),
    };
}

export function ProductsVmToJSON(value?: ProductsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': ((value.products as Array<any>).map(ProductVersionDetailDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
        'activeSeries': ((value.activeSeries as Array<any>).map(ProductSeriesExplorerDtoToJSON)),
    };
}

