import { Action } from "redux";
import { NotificationButton } from "../../../models/notificationInformation";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { AuthSettings } from "../../../configuration/authConfig";
import { NotificationSeverity } from "api";

export enum UserActionTypes {
  AddNotification = "User/AddNotification",
  RemoveNotification = "User/RemoveNotification",
  StoreUserInfo = "User/StoreUserInfo",
  ClearUserInfo = "User/ClearUserInfo",
  AddConfirmEvent = "User/AddConfirmEvent",
  RemoveConfirmEvent = "User/RemoveConfirmEvent",
  RefreshToken = "User/StoreToken",
  StoreAuthSettings = "User/StoreAuthSettings",
  ChangeIsLoggingIn = "User/ChangeIsLoggingIn",
  TogglePrivacyConsentDialogVisibility = "User/TogglePrivacyConsentDialogVisibility",
  UpdateSelectedShoppingCartGuid = "User/UpdateSelectedShoppingCartGuid"
}

export interface AddNotification extends Action {
  type: UserActionTypes.AddNotification;
  severity: NotificationSeverity;
  message: string;
  isConfirmation?: boolean;
  timeout?: number;
  buttons?: NotificationButton[];
  messageDetail?: string;
  skipTitleTranslation?: boolean;
  skipDetailTranslation?: boolean;
  customContent?: JSX.Element;
}

export interface RemoveNotification extends Action {
  type: UserActionTypes.RemoveNotification;
  id: string;
}

export interface AddConfirmEvent extends Action {
  type: UserActionTypes.AddConfirmEvent;
  onConfirm: () => void;
  title: string;
  message: string;
}

export interface RemoveConfirmEvent extends Action {
  type: UserActionTypes.RemoveConfirmEvent;
  id: string;
}

export interface StoreUserInfo extends Action {
  type: UserActionTypes.StoreUserInfo;
  accountInfo: AccountInfo | null | undefined;
}

export interface ClearUserInfo extends Action {
  type: UserActionTypes.ClearUserInfo;
}

export interface RefreshToken extends Action {
  type: UserActionTypes.RefreshToken;
  authenticationResult: AuthenticationResult;
}

export interface StoreAuthSettings extends Action {
  type: UserActionTypes.StoreAuthSettings;
  authSettings: AuthSettings;
}

export interface ChangeIsLoggingIn extends Action {
  type: UserActionTypes.ChangeIsLoggingIn;
  isLoggingIn: boolean;
}

export interface TogglePrivacyConsentDialogVisibility extends Action {
  type: UserActionTypes.TogglePrivacyConsentDialogVisibility;
  showDialog: boolean;
}

export interface UpdateSelectedShoppingCartId extends Action {
  type: UserActionTypes.UpdateSelectedShoppingCartGuid;
  shoppingCartGuid: string | null;
}

export const userActions = {
  addNotification: (
    severity: NotificationSeverity,
    message: string,
    isConfirmation?: boolean,
    timeout?: number,
    buttons?: NotificationButton[],
    messageDetail?: string,
    skipTitleTranslation?: boolean,
    skipDetailTranslation?: boolean,
    customContent?: JSX.Element
  ): AddNotification => ({
    type: UserActionTypes.AddNotification,
    severity,
    message,
    isConfirmation,
    timeout,
    buttons,
    messageDetail,
    skipTitleTranslation,
    skipDetailTranslation,
    customContent
  }),
  removeNotification: (id: string): RemoveNotification => ({
    type: UserActionTypes.RemoveNotification,
    id
  }),
  storeUserInfo: (accountInfo: AccountInfo | null | undefined): StoreUserInfo => ({
    type: UserActionTypes.StoreUserInfo,
    accountInfo
  }),
  clearUserInfo: (): ClearUserInfo => ({
    type: UserActionTypes.ClearUserInfo
  }),
  addConfirmEvent: (onConfirm: () => void, title: string, message: string): AddConfirmEvent => ({
    type: UserActionTypes.AddConfirmEvent,
    onConfirm,
    title,
    message
  }),
  removeConfirmEvent: (id: string): RemoveConfirmEvent => ({
    type: UserActionTypes.RemoveConfirmEvent,
    id
  }),
  refreshToken: (authenticationResult: AuthenticationResult): RefreshToken => ({
    type: UserActionTypes.RefreshToken,
    authenticationResult
  }),
  storeAuthSettings: (authSettings: AuthSettings): StoreAuthSettings => ({
    type: UserActionTypes.StoreAuthSettings,
    authSettings
  }),
  changeIsLoggingIn: (isLoggingIn: boolean): ChangeIsLoggingIn => ({
    type: UserActionTypes.ChangeIsLoggingIn,
    isLoggingIn
  }),
  togglePrivacyConsentDialogVisibility: (
    showDialog: boolean
  ): TogglePrivacyConsentDialogVisibility => ({
    type: UserActionTypes.TogglePrivacyConsentDialogVisibility,
    showDialog: showDialog
  }),
  updateSelectedShoppingCartGuid: (
    shoppingCartGuid: string | null
  ): UpdateSelectedShoppingCartId => ({
    type: UserActionTypes.UpdateSelectedShoppingCartGuid,
    shoppingCartGuid
  })
};

export type UserActions =
  | AddNotification
  | RemoveNotification
  | StoreUserInfo
  | ClearUserInfo
  | AddConfirmEvent
  | RemoveConfirmEvent
  | RefreshToken
  | StoreAuthSettings
  | ChangeIsLoggingIn
  | TogglePrivacyConsentDialogVisibility
  | UpdateSelectedShoppingCartId;
