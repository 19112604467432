import { createGenericSaga, defaultSagaErrorHandler } from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";

export const dashboardSagas = {
  updateUserWidget: createGenericSaga(
    "dashboardSagas/updateUserWidget",
    getApiRegistry().usersApi.apiUsersIdUserWidgetsWidgetIdPatch.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getCurrentUserWidgets: createGenericSaga(
    "userSagas/getCurrentUserWidgets",
    getApiRegistry().usersApi.apiUsersCurrentUserWidgetsGet.bind(getApiRegistry().usersApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getPricesFromOrderingCodes: createGenericSaga(
    "dashboardSagas/getPricesFromOrderingCodes",
    getApiRegistry().pricesApi.apiPricesPost.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
