import React, { PureComponent } from "react";
import { IStatusPanelParams, ColumnState } from "ag-grid-community";
import styled from "styled-components";
import { DefaultFilterModel } from "./Grid";
import { Button } from "framework/components/Button";

interface GridStatusBarResetButtonProps extends IStatusPanelParams {
  isOnlyResetSorting: boolean;
  disableResize: boolean | undefined;
  t: (text: string) => string;
}

interface GridStatusBarResetButtonState {
  defaultSortModel: ColumnState[] | undefined;
  defaultFilterModel: DefaultFilterModel | undefined;
  isEnabled: boolean;
}

const ButtonContainer = styled.div`
  height: 42px;
  display: flex;
  align-items: flex-end;
`;

export default class GridStatusBarResetButton extends PureComponent<
  GridStatusBarResetButtonProps,
  GridStatusBarResetButtonState
> {
  state: GridStatusBarResetButtonState = {
    defaultFilterModel: undefined,
    defaultSortModel: undefined,
    isEnabled: false
  };

  resetGrid(): void {
    if (this.props.isOnlyResetSorting && this.state.defaultSortModel) {
      this.props.columnApi.applyColumnState({ state: this.state.defaultSortModel });
    } else {
      this.props.api.setFilterModel(this.state.defaultFilterModel);
      this.props.columnApi.applyColumnState({ state: this.state.defaultSortModel });
    }
    if (!this.props.disableResize) {
      this.props.api.sizeColumnsToFit();
    }
  }

  setDefaultSortModel(sortModel: ColumnState[]): void {
    this.setState({ defaultSortModel: sortModel });
  }

  setIsEnabled(isEnabled: boolean): void {
    this.setState({ isEnabled });
  }

  setDefaultFilterModel(filterModel: DefaultFilterModel): void {
    this.setState({ defaultFilterModel: filterModel });
  }

  render(): React.ReactNode {
    return (
      <ButtonContainer>
        <Button
          sizeClass="small"
          buttonType="secondary"
          disabled={!this.state.isEnabled}
          onClick={() => {
            this.resetGrid();
          }}
          text={
            this.props.isOnlyResetSorting
              ? this.props.t("Reset sorting")
              : this.props.t("Reset sorting & filters")
          }
        />
      </ButtonContainer>
    );
  }
}
