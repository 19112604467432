import { createStore, Reducer, Store, applyMiddleware, compose, PreloadedState } from "redux";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";

interface StoreAndSagaMiddleware {
  store: Store;
  sagaMiddleware: SagaMiddleware;
}

export function setupState<TState>(
  reducer: Reducer<TState, any>,
  preloadedState: PreloadedState<TState>,
  onStoreReady?: (
    store: Store<TState>,
    sagaMiddleware: SagaMiddleware<Record<string, unknown>>
  ) => void
): StoreAndSagaMiddleware {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  if (onStoreReady) {
    onStoreReady(store, sagaMiddleware);
  }

  return {
    store: store,
    sagaMiddleware: sagaMiddleware
  };
}
