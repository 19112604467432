import {
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler,
  createGenericSaga
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const deliveryTimesSagas = {
  updateDescription: createGenericSaga(
    "deliveryTimesSagas/updateDescription",
    getApiRegistry().deliveryTimesApi.apiDeliveryTimesUpdateDescriptionDeliveryTimesPut.bind(
      getApiRegistry().deliveryTimesApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Settings successfully updated!")
    }
  ),
  getDescription: createGenericSaga(
    "deliveryTimesSagas/getDescription",
    getApiRegistry().globalSettingsApi.apiGlobalSettingGet.bind(getApiRegistry().globalSettingsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getDeliveryTimes: createGenericSaga(
    "deliveryTimesSagas/getDeliveryTimes",
    getApiRegistry().deliveryTimesApi.apiDeliveryTimesDeliveryTimesGet.bind(
      getApiRegistry().deliveryTimesApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
