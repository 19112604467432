export const routes = {
  index: "/",
  registrationCompleted: "/registration-completed",
  orders: {
    home: "/deliveries",
    index: "/orders",
    ordersBacklog: "/orders/backlog",
    orderIdentifier: "/orders/:orderIdentifier",
    subscriptions: "/orders/subscriptions",
    productInformation: {
      index: "/orders/product-information"
    },
    requestAccess: "/orders/request-access",
    deliveryTimes: "/orders/delivery-times",
    lifecycleManagement: "/orders/lifecycle-management"
  },
  manage: {
    users: "/manage/users",
    usersEdit: "/manage/users/:userId",
    companies: "/manage/companies",
    company: "/manage/companies/:companyId",
    teams: "/manage/teams",
    team: "/manage/teams/:teamId",
    statistics: "/manage/statistics",
    accessRequests: "/manage/access-requests",
    accessRequestsEmailFilter: "/manage/access-requests/:emailFilter",
    orderNotifications: "/manage/notifications",
    orderDownloadNotifications: "/manage/order-download-notifications",
    translations: "/manage/translations",
    priceLists: "/manage/price-lists",
    termsConditions: "/manage/terms-and-conditions",
    quotes: "/manage/quotes",
    quote: "/manage/quote",
    emailTemplates: "/manage/email-templates",
    quoteById: "/manage/quote/:quoteId",
    ownQuotes: "/manage/quotes/own",
    releaseNotes: "/manage/release-notes",
    globalSettings: "/manage/global-settings",
    terminologyBank: "/manage/terminology-bank"
  },
  downloads: {
    firmwareUpdates: "/downloads/firmware-updates",
    documents: "/downloads/documents",
    software: "/downloads/software",
    index: "/downloads/product-information",
    indexSerialNumber: "/downloads/product-information/:productSerialNumber",
    productSerialNumber: "/downloads/documents/:productSerialNumber",
    licenses: "/downloads/licenses/:serialNumber" // When this path need to be changed also the address in ContactSupportTeamCommand needs to be updated
  },
  user: {
    information: "/user/information",
    requestAccess: "/user/request-access",
    notifications: "/user/notifications",
    notificationDetails: "/user/notifications/:notificationId"
  },
  training: {
    managementView: "/manage/training-center",
    trainingView: "/products/training-center",
    knowledgeWarehouseView: "/knowledge-warehouse"
  },
  products: {
    productSelectionMatrix: "/products/product-configurator",
    productExplorer: "/products/product-explorer",
    productComparator: "/products/product-comparator",
    productSelector: "/products/product-selector"
  },
  quickPriceCheck: "/quick-price-check",
  shoppingCart: "/shopping-cart",
  shoppingCartByGuid: "/shopping-cart/:shoppingCartGuid",
  shoppingCarts: "/shopping-carts",
  support: {
    home: "/support",
    techSupport: "/support/tech-support",
    salesSupport: "/support/sales-support",
    trainingSupport: "/support/training-support",
    platformSupport: "/support/platform-support",
    terminologyBank: "/support/terminology-bank",
    myKnowledgeAi: "/support/myknowledge-ai"
  }
} as const;
