/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddTrainingNodeCommand
 */
export interface AddTrainingNodeCommand {
    /**
     * 
     * @type {string}
     * @memberof AddTrainingNodeCommand
     */
    nodeId: string;
    /**
     * 
     * @type {string}
     * @memberof AddTrainingNodeCommand
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddTrainingNodeCommand
     */
    parentNodeId?: string | null;
}

export function AddTrainingNodeCommandFromJSON(json: any): AddTrainingNodeCommand {
    return AddTrainingNodeCommandFromJSONTyped(json, false);
}

export function AddTrainingNodeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddTrainingNodeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'name': json['name'],
        'parentNodeId': !exists(json, 'parentNodeId') ? undefined : json['parentNodeId'],
    };
}

export function AddTrainingNodeCommandToJSON(value?: AddTrainingNodeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'name': value.name,
        'parentNodeId': value.parentNodeId,
    };
}

