/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderItemDto,
    OrderItemDtoFromJSON,
    OrderItemDtoFromJSONTyped,
    OrderItemDtoToJSON,
} from './OrderItemDto';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';

/**
 * 
 * @export
 * @interface OrderItemsVm
 */
export interface OrderItemsVm {
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderItemsVm
     */
    orderItems: Array<OrderItemDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof OrderItemsVm
     */
    criteria: PaginationReturnModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderItemsVm
     */
    missingSerialNumbers: Array<string>;
}

export function OrderItemsVmFromJSON(json: any): OrderItemsVm {
    return OrderItemsVmFromJSONTyped(json, false);
}

export function OrderItemsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderItems': ((json['orderItems'] as Array<any>).map(OrderItemDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
        'missingSerialNumbers': json['missingSerialNumbers'],
    };
}

export function OrderItemsVmToJSON(value?: OrderItemsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderItems': ((value.orderItems as Array<any>).map(OrderItemDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
        'missingSerialNumbers': value.missingSerialNumbers,
    };
}

