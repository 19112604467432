import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon } from "@abb/abb-common-ux-react";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.sizes.lm};
`;

const CustomIcon = styled(Icon)`
  :hover {
    cursor: pointer;
  }
  margin-top: 0;
  margin-left: ${(props) => props.theme.sizes.sm};
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
  line-height: 15px;
`;

interface SerialNumberEditInputProps {
  serialNumber?: string;
  isReadOnly?: boolean;
  handleRemoveSerialNumber: () => void;
}

export const SerialNumberEditInput = ({
  serialNumber,
  isReadOnly,
  handleRemoveSerialNumber
}: SerialNumberEditInputProps): JSX.Element => {
  const [serialNumberText, setSerialNumberText] = useState<string | undefined>();

  useEffect(() => {
    setSerialNumberText(serialNumber);
  }, [serialNumber]);

  return (
    <Container>
      <Text>{serialNumberText ?? ""}</Text>
      {!isReadOnly ? (
        <>
          <CustomIcon name={"abb/trash"} onClick={() => handleRemoveSerialNumber()} />
        </>
      ) : null}
    </Container>
  );
};
