/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StatisticsPreDefinedTimePeriods {
    All = 'All',
    Last30Days = 'Last30Days',
    Last24Hours = 'Last24Hours',
    Custom = 'Custom'
}

export function StatisticsPreDefinedTimePeriodsFromJSON(json: any): StatisticsPreDefinedTimePeriods {
    return StatisticsPreDefinedTimePeriodsFromJSONTyped(json, false);
}

export function StatisticsPreDefinedTimePeriodsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticsPreDefinedTimePeriods {
    return json as StatisticsPreDefinedTimePeriods;
}

export function StatisticsPreDefinedTimePeriodsToJSON(value?: StatisticsPreDefinedTimePeriods | null): any {
    return value as any;
}

