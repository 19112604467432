/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationProduct,
    ModificationProductFromJSON,
    ModificationProductFromJSONTyped,
    ModificationProductToJSON,
} from './ModificationProduct';
import {
    ModificationsWithMultipleLatestProducedDeviceRevisions,
    ModificationsWithMultipleLatestProducedDeviceRevisionsFromJSON,
    ModificationsWithMultipleLatestProducedDeviceRevisionsFromJSONTyped,
    ModificationsWithMultipleLatestProducedDeviceRevisionsToJSON,
} from './ModificationsWithMultipleLatestProducedDeviceRevisions';

/**
 * 
 * @export
 * @interface GmdProductModificationHistory
 */
export interface GmdProductModificationHistory {
    /**
     * 
     * @type {ModificationProduct}
     * @memberof GmdProductModificationHistory
     */
    modificationProduct?: ModificationProduct;
    /**
     * 
     * @type {ModificationsWithMultipleLatestProducedDeviceRevisions}
     * @memberof GmdProductModificationHistory
     */
    productModification?: ModificationsWithMultipleLatestProducedDeviceRevisions;
}

export function GmdProductModificationHistoryFromJSON(json: any): GmdProductModificationHistory {
    return GmdProductModificationHistoryFromJSONTyped(json, false);
}

export function GmdProductModificationHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GmdProductModificationHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modificationProduct': !exists(json, 'modificationProduct') ? undefined : ModificationProductFromJSON(json['modificationProduct']),
        'productModification': !exists(json, 'productModification') ? undefined : ModificationsWithMultipleLatestProducedDeviceRevisionsFromJSON(json['productModification']),
    };
}

export function GmdProductModificationHistoryToJSON(value?: GmdProductModificationHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modificationProduct': ModificationProductToJSON(value.modificationProduct),
        'productModification': ModificationsWithMultipleLatestProducedDeviceRevisionsToJSON(value.productModification),
    };
}

