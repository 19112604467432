/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationNotificationTypes {
    General = 'General',
    UserAccessRightsChanged = 'UserAccessRightsChanged',
    JustInTimeProductAvailableForDownload = 'JustInTimeProductAvailableForDownload',
    QuoteApprovalRequested = 'QuoteApprovalRequested',
    JustInTimeProductPipelineFaulted = 'JustInTimeProductPipelineFaulted',
    JustInTimeProductInActivated = 'JustInTimeProductInActivated'
}

export function ApplicationNotificationTypesFromJSON(json: any): ApplicationNotificationTypes {
    return ApplicationNotificationTypesFromJSONTyped(json, false);
}

export function ApplicationNotificationTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationNotificationTypes {
    return json as ApplicationNotificationTypes;
}

export function ApplicationNotificationTypesToJSON(value?: ApplicationNotificationTypes | null): any {
    return value as any;
}

