/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArticleAttachment,
    ArticleAttachmentFromJSON,
    ArticleAttachmentFromJSONTyped,
    ArticleAttachmentToJSON,
} from './ArticleAttachment';
import {
    ArticleNode,
    ArticleNodeFromJSON,
    ArticleNodeFromJSONTyped,
    ArticleNodeToJSON,
} from './ArticleNode';
import {
    ArticleStatus,
    ArticleStatusFromJSON,
    ArticleStatusFromJSONTyped,
    ArticleStatusToJSON,
} from './ArticleStatus';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    nameHTML?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    isTitleVisible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    keywords?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    textHTML?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    updatedTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    summary?: string | null;
    /**
     * 
     * @type {ArticleStatus}
     * @memberof Article
     */
    status?: ArticleStatus;
    /**
     * 
     * @type {Array<ArticleNode>}
     * @memberof Article
     */
    readonly parentNodes?: Array<ArticleNode> | null;
    /**
     * 
     * @type {Array<ArticleAttachment>}
     * @memberof Article
     */
    readonly attachments?: Array<ArticleAttachment> | null;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameHTML': !exists(json, 'nameHTML') ? undefined : json['nameHTML'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isTitleVisible': !exists(json, 'isTitleVisible') ? undefined : json['isTitleVisible'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'textHTML': !exists(json, 'textHTML') ? undefined : json['textHTML'],
        'updatedTime': !exists(json, 'updatedTime') ? undefined : (new Date(json['updatedTime'])),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'status': !exists(json, 'status') ? undefined : ArticleStatusFromJSON(json['status']),
        'parentNodes': !exists(json, 'parentNodes') ? undefined : (json['parentNodes'] === null ? null : (json['parentNodes'] as Array<any>).map(ArticleNodeFromJSON)),
        'attachments': !exists(json, 'attachments') ? undefined : (json['attachments'] === null ? null : (json['attachments'] as Array<any>).map(ArticleAttachmentFromJSON)),
    };
}

export function ArticleToJSON(value?: Article | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'nameHTML': value.nameHTML,
        'title': value.title,
        'isTitleVisible': value.isTitleVisible,
        'keywords': value.keywords,
        'text': value.text,
        'textHTML': value.textHTML,
        'updatedTime': value.updatedTime === undefined ? undefined : (value.updatedTime.toISOString()),
        'summary': value.summary,
        'status': ArticleStatusToJSON(value.status),
    };
}

