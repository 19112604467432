/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAccountTypes,
    UserAccountTypesFromJSON,
    UserAccountTypesFromJSONTyped,
    UserAccountTypesToJSON,
} from './UserAccountTypes';

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userPrincipalName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userId: string;
    /**
     * 
     * @type {UserAccountTypes}
     * @memberof UserInfo
     */
    userAccountType: UserAccountTypes;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    businessUnitCode?: string | null;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'givenName': json['givenName'],
        'surname': json['surname'],
        'email': json['email'],
        'userPrincipalName': json['userPrincipalName'],
        'userId': json['userId'],
        'userAccountType': UserAccountTypesFromJSON(json['userAccountType']),
        'countryCode': json['countryCode'],
        'businessUnitCode': !exists(json, 'businessUnitCode') ? undefined : json['businessUnitCode'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'givenName': value.givenName,
        'surname': value.surname,
        'email': value.email,
        'userPrincipalName': value.userPrincipalName,
        'userId': value.userId,
        'userAccountType': UserAccountTypesToJSON(value.userAccountType),
        'countryCode': value.countryCode,
        'businessUnitCode': value.businessUnitCode,
    };
}

