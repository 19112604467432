/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactSupportTeamCommand
 */
export interface ContactSupportTeamCommand {
    /**
     * 
     * @type {string}
     * @memberof ContactSupportTeamCommand
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportTeamCommand
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSupportTeamCommand
     */
    serialNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSupportTeamCommand
     */
    showNotification?: boolean;
}

export function ContactSupportTeamCommandFromJSON(json: any): ContactSupportTeamCommand {
    return ContactSupportTeamCommandFromJSONTyped(json, false);
}

export function ContactSupportTeamCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSupportTeamCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'reference': json['reference'],
        'serialNumber': json['serialNumber'],
        'showNotification': !exists(json, 'showNotification') ? undefined : json['showNotification'],
    };
}

export function ContactSupportTeamCommandToJSON(value?: ContactSupportTeamCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'reference': value.reference,
        'serialNumber': value.serialNumber,
        'showNotification': value.showNotification,
    };
}

