import { manageAccessRequestsSagas } from "../sagas/manageAccessRequestsSagas";
import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import {
  AccessRequestDto,
  RoleGroupRolesDto,
  UpdateAccessRequestCommand,
  UpdateAccessRequestCompanyCommand
} from "../../../../api/models";
import { RequestStatus } from "../../../../framework/state/requestStatus";
import { AccessRequestsActions } from "../../../accessRequests/actions/accessRequestsActions";
import { roleSagas } from "applications/common/sagas/roleSagas";

interface Requests {
  updateAccessRequest: RequestState<AccessRequestDto, UpdateAccessRequestCommand>;
  updateAccessRequestCompany: RequestState<AccessRequestDto, UpdateAccessRequestCompanyCommand>;
  getRoles: RequestState<Array<RoleGroupRolesDto>>;
}

export interface ManageAccessRequestsState {
  requests: Requests;
  updatedAccessRequest: AccessRequestDto | null;
  roles: RoleGroupRolesDto[];
}

const defaultState: ManageAccessRequestsState = {
  requests: {
    updateAccessRequest: createInitialRequest(),
    updateAccessRequestCompany: createInitialRequest(),
    getRoles: createInitialRequest()
  },
  updatedAccessRequest: null,
  roles: []
};

export function manageAccessRequestsReducer(
  state: ManageAccessRequestsState = defaultState,
  action: AccessRequestsActions
): ManageAccessRequestsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageAccessRequestsSagas.updateAccessRequest.actionTypes,
      key: "updateAccessRequest"
    },
    {
      actionTypes: manageAccessRequestsSagas.updateAccessRequestCompany.actionTypes,
      key: "updateAccessRequestCompany"
    },
    {
      actionTypes: roleSagas.getRoles.actionTypes,
      key: "getRoles"
    }
  ]);
  if (manageAccessRequestsSagas.updateAccessRequest.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.updatedAccessRequest = action.payload;
    });
  } else if (manageAccessRequestsSagas.getRoles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.roles = action.payload;
    });
  }

  return state;
}

export const getUpdateAccessRequestStatus = (state: AppState): RequestStatus =>
  state.manageAccessRequests.requests.updateAccessRequest.status;

export const getUpdatedAccessRequest = (state: AppState): AccessRequestDto | null =>
  state.manageAccessRequests.updatedAccessRequest;

export const getManageAccessRequestRoles = (state: AppState): RoleGroupRolesDto[] =>
  state.manageAccessRequests.roles;

export const getUpdateAccessRequestCompanyRequest = (
  state: AppState
): RequestState<AccessRequestDto, UpdateAccessRequestCompanyCommand> =>
  state.manageAccessRequests.requests.updateAccessRequestCompany;
