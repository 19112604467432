/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectorApplicationFeatureDto,
    SelectorApplicationFeatureDtoFromJSON,
    SelectorApplicationFeatureDtoFromJSONTyped,
    SelectorApplicationFeatureDtoToJSON,
} from './SelectorApplicationFeatureDto';

/**
 * 
 * @export
 * @interface SelectorApplicationFeatureProductValueDto
 */
export interface SelectorApplicationFeatureProductValueDto {
    /**
     * 
     * @type {number}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    visibility?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    selectorApplicationFeatureId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    isSelectedFeature?: boolean;
    /**
     * 
     * @type {SelectorApplicationFeatureDto}
     * @memberof SelectorApplicationFeatureProductValueDto
     */
    selectorApplicationFeature?: SelectorApplicationFeatureDto;
}

export function SelectorApplicationFeatureProductValueDtoFromJSON(json: any): SelectorApplicationFeatureProductValueDto {
    return SelectorApplicationFeatureProductValueDtoFromJSONTyped(json, false);
}

export function SelectorApplicationFeatureProductValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorApplicationFeatureProductValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'selectorApplicationFeatureId': !exists(json, 'selectorApplicationFeatureId') ? undefined : json['selectorApplicationFeatureId'],
        'isSelectedFeature': !exists(json, 'isSelectedFeature') ? undefined : json['isSelectedFeature'],
        'selectorApplicationFeature': !exists(json, 'selectorApplicationFeature') ? undefined : SelectorApplicationFeatureDtoFromJSON(json['selectorApplicationFeature']),
    };
}

export function SelectorApplicationFeatureProductValueDtoToJSON(value?: SelectorApplicationFeatureProductValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'value': value.value,
        'note': value.note,
        'visibility': value.visibility,
        'selectorApplicationFeatureId': value.selectorApplicationFeatureId,
        'isSelectedFeature': value.isSelectedFeature,
        'selectorApplicationFeature': SelectorApplicationFeatureDtoToJSON(value.selectorApplicationFeature),
    };
}

