/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderDto,
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';

/**
 * 
 * @export
 * @interface OrdersVm
 */
export interface OrdersVm {
    /**
     * 
     * @type {Array<OrderDto>}
     * @memberof OrdersVm
     */
    orders: Array<OrderDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof OrdersVm
     */
    criteria: PaginationReturnModel;
}

export function OrdersVmFromJSON(json: any): OrdersVm {
    return OrdersVmFromJSONTyped(json, false);
}

export function OrdersVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrdersVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orders': ((json['orders'] as Array<any>).map(OrderDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function OrdersVmToJSON(value?: OrdersVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orders': ((value.orders as Array<any>).map(OrderDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

