import React, { memo } from "react";
import {
  Grid,
  createHeaderAndHeaderTooltip,
} from "../../../../framework/components/grid/Grid";
import {
  GridApi,
  CellClickedEvent,
  GridReadyEvent,
  ColDef
} from "ag-grid-community";
import { ApiTeamsGetRequest, TeamTypes, TeamDto } from "../../../../api";
import { getDefaultColumnSort } from "../../../../framework/components/grid/Grid";
import { TFunction } from "react-i18next";
import { useCallback } from "react";
import styled from "styled-components";
import { defaultTeamsCriteria } from "../reducers/manageTeamsViewReducer";

export enum ManageTeamsGridUsage {
  ViewSingle,
  Listing
}

const StyledGrid = styled(Grid)`
  .row-not-clickable,
  .row-not-clickable div {
    cursor: auto;
  }
`;
interface TeamsGridProps {
  t: TFunction;
  rowData?: TeamDto[];
  onSelectTeam?: (teamId: number) => void;
  setGridApi?: ((ref: GridApi) => void) | null;
  usageType: ManageTeamsGridUsage;
  criteria?: React.MutableRefObject<ApiTeamsGetRequest | undefined>;
}

const getTeamTypeText = (
  type: TeamTypes | undefined,
  t: TFunction
): string => {
  switch (type) {
    case TeamTypes.BusinessUnit:
      return t("Business unit");
    default:
      return "";
  }
};

export const TeamsGrid = memo(
  (props: TeamsGridProps): JSX.Element => {
    const t = props.t;
    const listingMode = props.usageType === ManageTeamsGridUsage.Listing;

    const colDefs: ColDef[] = [
      {
        ...createHeaderAndHeaderTooltip(t("Name")),
        field: "name"
      },
      {
        ...createHeaderAndHeaderTooltip(t("Description")),
        field: "description"
      },
      {
        ...createHeaderAndHeaderTooltip(t("Type")),
        field: "type",
        valueFormatter: (params: any) =>
          getTeamTypeText(params.value as TeamTypes, t),
        filter: "agSetColumnFilter",
        filterParams: {
          values: [...Object.keys(TeamTypes)],
          valueFormatter: (params: any) => getTeamTypeText(params.value as TeamTypes, t)
        }
      },
    ];

    const onGridReady = useCallback(
      (event: GridReadyEvent) => {
        if(props.setGridApi) {
          props.setGridApi(event.api);
        }
      },
      [props.setGridApi, props.criteria]
    );

    return (
      <StyledGrid
        t={t}
        rowData={props.rowData}
        isSortPersisted={true}
        defaultSort={getDefaultColumnSort(
          defaultTeamsCriteria.criteriaIsAscendingOrder,
          defaultTeamsCriteria.criteriaSortColumn
        )}
        columnDefs={colDefs}
        rowModelType={listingMode ? "infinite" : "clientSide"}
        rowSelection="single"
        cacheBlockSize={100}
        maxBlocksInCache={2}
        onCellClicked={(event: CellClickedEvent) => {
          const team = event.data as TeamDto;
          if(team?.id && props.onSelectTeam) {
            props.onSelectTeam(team.id);
          }
        }}
        showPointerOnRowHover={listingMode ? true : false}
        autosizeCols
        keepEditingIfFocusIsLost
        colDefDefault={
          listingMode
            ? {
                filter: "agTextColumnFilter",
                filterParams: {
                  filterOptions: ["contains"],
                  suppressAndOrCondition: true
                },
                floatingFilter: true
              }
            : {
                sortable: false
              }
        }
        getRowId={(params) => params.data.id.toString()}
        statusBarOptions={listingMode ? { showResetButton: true, showRowCount: true } : undefined}
        suppressRowClickSelection={true}
        suppressCellFocus={true}
        onGridReady={onGridReady}
        disableColumnAutoSize={listingMode ? undefined : true}
        domLayout={listingMode ? undefined : "autoHeight"}
      />
    );
  }
);
