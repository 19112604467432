import { trainingManagementSagas } from "../sagas/trainingManagementSagas";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import {
  ApiResponse,
  ApiTrainingArticlePutRequest,
  ApiTrainingArticlesFreeAndAssignedGetRequest,
  ApiTrainingAttachmentGetRequest,
  ApiTrainingNodePutRequest,
  ArticleDto,
  FreeAndAssignedArticleDto,
  NodeDto,
  NodeWithPath
} from "api";
import { AppState } from "../../../../setup/appRootReducer";
import { produce } from "immer";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { RequestStatus } from "framework/state/requestStatus";
import {
  SupportAction,
  TrainingActions,
  TrainingActionType
} from "../actions/manageTrainingActions";

interface Requests {
  updateArticle: RequestState<ApiTrainingArticlePutRequest>;
  updateNode: RequestState<ApiTrainingNodePutRequest>;

  getAttachment: RequestState<ApiResponse<Blob>, ApiTrainingAttachmentGetRequest>;
  retrieveArticles: RequestState<void, void>;
  getArticlesFreeAndAssigned: RequestState<
    FreeAndAssignedArticleDto,
    ApiTrainingArticlesFreeAndAssignedGetRequest
  >;
}

export interface ManageTrainingManagementState {
  requests: Requests;
  nodes: NodeWithPath[];
  roots: NodeDto[];
  articles: ArticleDto[];
  currentAction: SupportAction | undefined;
  attachmentLoadingList: number[];
  freeArticles: ArticleDto[];
  assignedArticles: ArticleDto[];
  loadingArticles: boolean;
}
const defaultState: ManageTrainingManagementState = {
  requests: {
    updateArticle: createInitialRequest(),
    updateNode: createInitialRequest(),
    getAttachment: createInitialRequest(),
    retrieveArticles: createInitialRequest(),
    getArticlesFreeAndAssigned: createInitialRequest()
  },
  nodes: [],
  roots: [],
  articles: [],
  currentAction: undefined,
  attachmentLoadingList: [],
  freeArticles: [],
  assignedArticles: [],
  loadingArticles: false
};

export function manageTrainingManagementReducer(
  state: ManageTrainingManagementState = defaultState,
  action: TrainingActions
): ManageTrainingManagementState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: trainingManagementSagas.getNodes.actionTypes,
      key: "getNodes"
    },
    {
      actionTypes: trainingManagementSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: trainingManagementSagas.getArticles.actionTypes,
      key: "getArticles"
    },
    {
      actionTypes: trainingManagementSagas.updateArticle.actionTypes,
      key: "updateArticle"
    },
    {
      actionTypes: trainingManagementSagas.updateNode.actionTypes,
      key: "updateNode"
    },
    {
      actionTypes: trainingManagementSagas.deleteNode.actionTypes,
      key: "deleteNode"
    },
    {
      actionTypes: trainingManagementSagas.getAttachment.actionTypes,
      key: "getAttachment"
    },
    {
      actionTypes: trainingManagementSagas.retrieveArticles.actionTypes,
      key: "retrieveArticles"
    },
    {
      actionTypes: trainingManagementSagas.getArticlesFreeAndAssigned.actionTypes,
      key: "getArticlesFreeAndAssigned"
    }
  ]);
  if (trainingManagementSagas.getNodes.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload;
    });

  if (trainingManagementSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.roots = action.payload;
    });
  if (trainingManagementSagas.getArticles.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.articles = action.payload;
    });
  if (trainingManagementSagas.updateNode.isCompletedAction(action))
    state = produce(state, (draft) => {
      // when I add a node I execute the update to get the path
      draft.nodes = draft.nodes.filter((n) => !action.payload.some((x) => x.id === n.id));
      action.payload.forEach((node) => draft.nodes.push(node));
    });
  if (trainingManagementSagas.updateArticle.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.articles = [...draft.articles, action.payload];
    });
  if (trainingManagementSagas.deleteNode.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = draft.nodes.filter((n) => !action.payload.some((id) => n.id === id));
    });
  if (trainingManagementSagas.getAttachment.isRequestAction(action)) {
    state = produce(state, (draft) => {
      if (!action.payload.attachmentId) return state;
      draft.attachmentLoadingList.push(action.payload.attachmentId);
    });
  } else if (trainingManagementSagas.getAttachment.isCompletedAction(action)) {
    const b = action.payload;
    const url = window.URL.createObjectURL(b);
    const a = document.createElement("a");
    a.href = url;
    const fileName = state.requests.getAttachment.requestPayload?.fileName;
    fileName ? (a.download = fileName) : (a.target = "_blank");
    a.click();
    state = produce(state, (draft) => {
      draft.attachmentLoadingList = draft.attachmentLoadingList.filter(
        (n) => n !== state.requests.getAttachment.requestPayload?.attachmentId
      );
    });
  } else if (trainingManagementSagas.getAttachment.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.attachmentLoadingList = draft.attachmentLoadingList.filter(
        (n) => n !== state.requests.getAttachment.requestPayload?.attachmentId
      );
    });
  }
  if (trainingManagementSagas.getArticlesFreeAndAssigned.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.assignedArticles = action.payload.assignedArticles ?? [];
      draft.freeArticles = action.payload.freeArticles ?? [];
      draft.loadingArticles = false;
    });
  switch (action.type) {
    case TrainingActionType.CurrentAction:
      state = produce(state, (draft) => {
        draft.currentAction = action.action;
      });
      break;
    case TrainingActionType.LoadSelectedFreeArticles:
      state = produce(state, (draft) => {
        draft.loadingArticles = true;
        draft.assignedArticles = [];
        draft.freeArticles = [];
      });
      break;
  }
  return state;
}

export const getCurrentAction = (state: AppState): SupportAction | undefined =>
  state.trainingManage.currentAction;
export const getUpdateNodeStatus = (state: AppState): RequestStatus =>
  state.trainingManage.requests.updateNode.status;
export const getUpdateArticleStatus = (state: AppState): RequestStatus =>
  state.trainingManage.requests.updateArticle.status;
export const getNodes = (state: AppState): NodeWithPath[] => state.trainingManage.nodes;
export const getArticles = (state: AppState): ArticleDto[] => state.trainingManage.articles;
export const getAttachmentLoadingList = (state: AppState) =>
  state.trainingManage.attachmentLoadingList;
export const getRetrieveArticlesStatus = (state: AppState) =>
  state.trainingManage.requests.retrieveArticles.status;
export const getArticlesFreeAndAssignedRequestStatus = (state: AppState): RequestStatus =>
  state.trainingManage.requests.getArticlesFreeAndAssigned.status;
export const getFreeArticles = (state: AppState): ArticleDto[] => state.trainingManage.freeArticles;
export const getAssignedArticles = (state: AppState): ArticleDto[] =>
  state.trainingManage.assignedArticles;
export const getArticlesFreeAndAssignedLoading = (state: AppState): boolean =>
  state.trainingManage.loadingArticles;
