import { Action } from "redux";
import { ProductVersionDetailDto } from "api";

export enum ProductComparisonActionTypes {
  AddToProductToCompare = "ProductComparison/AddProduct",
  RemoveToProductToCompare = "ProductComparison/RemoveProduct"
}

export interface AddToProductToCompare extends Action {
  type: ProductComparisonActionTypes.AddToProductToCompare;
  product: ProductVersionDetailDto;
}
export interface RemoveToProductToCompare extends Action {
  type: ProductComparisonActionTypes.RemoveToProductToCompare;
  product: ProductVersionDetailDto;
}

export const productComparisonActions = {
  addToProductToCompare: (product: ProductVersionDetailDto): AddToProductToCompare => ({
    type: ProductComparisonActionTypes.AddToProductToCompare,
    product
  }),
  removeToProductToCompare: (product: ProductVersionDetailDto): RemoveToProductToCompare => ({
    type: ProductComparisonActionTypes.RemoveToProductToCompare,
    product
  })
};

export type ProductComparisonActions = AddToProductToCompare | RemoveToProductToCompare;
