import React, { useEffect } from "react";
import { ProductVersionDetailDto } from "api";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { productComparisonSagas } from "../sagas/productComparisonSagas";
import {
  getComparisonProducts,
  getComparisonProductsStatus
} from "../reducers/productComparisonReducers";
import {
  Legend,
  LoadingIndicatorContainer,
  SizedTableRow,
  StyledTableCell,
  TableContainerStickyHeader,
  ToggleSwitchContainer
} from "./StyledComponents";
import { GridRow } from "./GridRow";
import { useTranslation } from "react-i18next";
import { LoadingIndicator, ToggleSwitch } from "@abb/abb-common-ux-react";
import { RequestStatus } from "framework/state/requestStatus";

interface Props {
  products: ProductVersionDetailDto[];
  maxElementInCompare: number;
}

export const CompareProductGrid = ({ products, maxElementInCompare }: Props) => {
  // State
  const [showAllFeatures, setShowAllFeatures] = React.useState(true);
  const [expandAll, setExpandAll] = React.useState(false);
  // Selector
  const resultComparison = useSelector(getComparisonProducts);
  const comparisonProductsStatus = useSelector(getComparisonProductsStatus);

  // const
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      productComparisonSagas.compareProducts.createAction({
        productVersionIdList: products.map((p) => p.id),
        showAllFeature: showAllFeatures
      })
    );
  }, [products, showAllFeatures, dispatch]);
  return (
    <Paper>
      {resultComparison != null ? (
        <>
          <TableContainerStickyHeader>
            <Table sx={{ border: "0" }} stickyHeader>
              <TableHead>
                <SizedTableRow $withGap>
                  <TableCell className="largeCard">
                    <ToggleSwitchContainer>
                      <ToggleSwitch
                        monochrome
                        label={t("Show only differences")}
                        value={products.length < 2 ? true : !showAllFeatures}
                        onChange={(newState: boolean) => {
                          setShowAllFeatures(!newState);
                        }}
                        disabled={products.length < 2}
                      />
                      <ToggleSwitch
                        monochrome
                        label={t("Expand all features")}
                        value={expandAll}
                        onChange={(newState: boolean) => {
                          setExpandAll(newState);
                        }}
                        disabled={resultComparison.activeCategories.length === 0}
                      />
                    </ToggleSwitchContainer>
                  </TableCell>
                  {products.map((p) => (
                    <TableCell key={p.id} className="smallCard ">
                      <h4>{p.name}</h4>
                    </TableCell>
                  ))}
                  {Array(maxElementInCompare - products.length)
                    .fill(1)
                    .map((n, i) => (
                      <TableCell key={-maxElementInCompare + i} className="smallCard ">
                        <h4> </h4>
                      </TableCell>
                    ))}
                </SizedTableRow>
                <TableRow>
                  <StyledTableCell colSpan={maxElementInCompare + 1}>
                    <Legend>
                      <b>{t("number")}</b> = {t("Number of stages, shots, inputs or outputs")}
                    </Legend>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* TODO: <div> cannot appear as a child of <tbody>, we should move the loading spinner up in the DOM tree */}
                {/* this loading is used when we switch the value of showAllFeatures to avoid hiding header */}
                {comparisonProductsStatus.status === RequestStatus.Pending ? (
                  <LoadingIndicatorContainer>
                    <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
                  </LoadingIndicatorContainer>
                ) : (
                  resultComparison.activeCategories.map((cat, i) => (
                    <GridRow
                      cat={cat}
                      products={products}
                      productsFeatures={resultComparison.productsFeatures}
                      maxElementInCompare={maxElementInCompare}
                      level={1}
                      expand={expandAll}
                      key={i}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainerStickyHeader>
        </>
      ) : comparisonProductsStatus.status === RequestStatus.Pending ? (
        <LoadingIndicatorContainer>
          <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
        </LoadingIndicatorContainer>
      ) : (
        <></>
      )}
    </Paper>
  );
};
