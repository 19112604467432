/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectorSubSegmentDto,
    SelectorSubSegmentDtoFromJSON,
    SelectorSubSegmentDtoFromJSONTyped,
    SelectorSubSegmentDtoToJSON,
} from './SelectorSubSegmentDto';

/**
 * 
 * @export
 * @interface SelectorApplicationFeatureSubSegmentValueDto
 */
export interface SelectorApplicationFeatureSubSegmentValueDto {
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureSubSegmentValueDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureSubSegmentValueDto
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SelectorApplicationFeatureSubSegmentValueDto
     */
    selectorApplicationFeatureId?: number;
    /**
     * 
     * @type {SelectorSubSegmentDto}
     * @memberof SelectorApplicationFeatureSubSegmentValueDto
     */
    selectorSubSegment?: SelectorSubSegmentDto;
}

export function SelectorApplicationFeatureSubSegmentValueDtoFromJSON(json: any): SelectorApplicationFeatureSubSegmentValueDto {
    return SelectorApplicationFeatureSubSegmentValueDtoFromJSONTyped(json, false);
}

export function SelectorApplicationFeatureSubSegmentValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorApplicationFeatureSubSegmentValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'selectorApplicationFeatureId': !exists(json, 'selectorApplicationFeatureId') ? undefined : json['selectorApplicationFeatureId'],
        'selectorSubSegment': !exists(json, 'selectorSubSegment') ? undefined : SelectorSubSegmentDtoFromJSON(json['selectorSubSegment']),
    };
}

export function SelectorApplicationFeatureSubSegmentValueDtoToJSON(value?: SelectorApplicationFeatureSubSegmentValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'note': value.note,
        'selectorApplicationFeatureId': value.selectorApplicationFeatureId,
        'selectorSubSegment': SelectorSubSegmentDtoToJSON(value.selectorSubSegment),
    };
}

