/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetTypes,
    WidgetTypesFromJSON,
    WidgetTypesFromJSONTyped,
    WidgetTypesToJSON,
} from './WidgetTypes';

/**
 * 
 * @export
 * @interface UserWidgetDto
 */
export interface UserWidgetDto {
    /**
     * 
     * @type {WidgetTypes}
     * @memberof UserWidgetDto
     */
    id: WidgetTypes;
    /**
     * 
     * @type {boolean}
     * @memberof UserWidgetDto
     */
    visible: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserWidgetDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserWidgetDto
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof UserWidgetDto
     */
    width: number;
}

export function UserWidgetDtoFromJSON(json: any): UserWidgetDto {
    return UserWidgetDtoFromJSONTyped(json, false);
}

export function UserWidgetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWidgetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': WidgetTypesFromJSON(json['id']),
        'visible': json['visible'],
        'name': json['name'],
        'height': json['height'],
        'width': json['width'],
    };
}

export function UserWidgetDtoToJSON(value?: UserWidgetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': WidgetTypesToJSON(value.id),
        'visible': value.visible,
        'name': value.name,
        'height': value.height,
        'width': value.width,
    };
}

