/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsAndConditionsExternalInfoDto
 */
export interface TermsAndConditionsExternalInfoDto {
    /**
     * 
     * @type {number}
     * @memberof TermsAndConditionsExternalInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsAndConditionsExternalInfoDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof TermsAndConditionsExternalInfoDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsAndConditionsExternalInfoDto
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsAndConditionsExternalInfoDto
     */
    description?: string | null;
}

export function TermsAndConditionsExternalInfoDtoFromJSON(json: any): TermsAndConditionsExternalInfoDto {
    return TermsAndConditionsExternalInfoDtoFromJSONTyped(json, false);
}

export function TermsAndConditionsExternalInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsAndConditionsExternalInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function TermsAndConditionsExternalInfoDtoToJSON(value?: TermsAndConditionsExternalInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'fileName': value.fileName,
        'extension': value.extension,
        'description': value.description,
    };
}

