import { Input } from "@abb/abb-common-ux-react/components/Input";
import React, { useState } from "react";
import backgroundImage from "../../../assets/footer-background.png";
import backgroundImageWebP from "../../../assets/footer-background.webp";
import {
  DisclaimerText,
  FooterBackground,
  FooterBottom,
  FooterButton,
  FooterContainer,
  FooterContent,
  FooterLink,
  FooterSplitter,
  FooterTop,
  FooterTopItem,
  FooterHeader,
  MaxWidthContainer
} from "./StyledComponents";
import { ReleaseNotesDialog } from "./ReleaseNotesDialog";
import { querystring } from "api/runtime";
import { DisclaimerDialog } from "./DisclaimerDialog";
import { useTranslation } from "react-i18next";
import { useRelaysOnlineFooter } from "../hooks/useRelaysOnlineFooter";

export interface FooterProps {
  /** User's first name used to pre fill sign up to news letter form */
  firstName?: string;
  /** User's last name used to pre fill sign up to news letter form */
  lastName?: string;
  /** User's email used to pre fill sign up to news letter form */
  email?: string;
  /** Provide texts for the footer, english texts are used as a fallback if nothing is provided */
  footerTexts?: FooterTexts;
  /** Provide maximum width for the footer content */
  maxWidth?: number;
  /** Application version number */
  version?: string;
  /** Function to open the consent cookie dialog */
  openCookieDialog?: () => void;
  /** Basepath for the api */
  basePath: string;
}

export interface FooterTexts {
  frequentlyAskedQuestionsTitle?: string;
  frequentlyAskedQuestionsText?: string;
  learnMoreButtonText?: string;
  contactAndSupportTitle?: string;
  contactAndSupportText?: string;
  contactUsButtonText?: string;
  latestNewsTitle?: string;
  emailAddressPlaceholder?: string;
  disclaimerText?: string;
  signUpButtonText?: string;
  providerInformationText?: string;
  termsConditionsText?: string;
  cookieAndPrivacyPolicyText?: string;
  changeCookieSettings?: string;
}

const contactEmail = "fi-elds-relaysonlinesupport@abb.com";

export const Footer = ({
  footerTexts,
  email,
  firstName,
  lastName,
  maxWidth,
  version,
  openCookieDialog,
  basePath
}: FooterProps): JSX.Element => {
  const texts = footerTexts || {};
  const [userEmail, setUserEmail] = useState(email ?? "");
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const { t } = useTranslation();

  const urlParameters = new Map<string, string>();
  if (userEmail) urlParameters.set("email", userEmail);
  if (firstName) urlParameters.set("first_name", firstName);
  if (lastName) urlParameters.set("last_name", lastName);

  const baseUrl = "https://campaign-el.abb.com/l/961032/2024-01-31/58fx8";
  const newsSignUpUrl =
    baseUrl + (urlParameters.size > 0 ? `?${querystring(Object.fromEntries(urlParameters))}` : "");

  const { releaseNotes, disclaimerGlobalSetting } = useRelaysOnlineFooter(basePath);

  const drawFooterContent = () => {
    return (
      <FooterContent>
        <ReleaseNotesDialog
          isOpen={isReleaseNotesOpen}
          setIsOpen={setIsReleaseNotesOpen}
          releaseNotes={releaseNotes}
        />
        <DisclaimerDialog
          isOpen={isDisclaimerOpen}
          setIsOpen={setIsDisclaimerOpen}
          disclaimerSetting={disclaimerGlobalSetting}
        />
        <FooterTop>
          <FooterTopItem>
            <FooterHeader
              level={4}
              blackAndWhite={true}
              showCursor={false}
              text={texts.frequentlyAskedQuestionsTitle || "Ask a question"}
            />
            <p>
              {texts.frequentlyAskedQuestionsText ||
                `If you have any questions or comments regarding any aspect of the platform.`}
            </p>
            <FooterButton type="secondary" to={`mailto:${contactEmail}`}>
              {texts.learnMoreButtonText || "Send email"}
            </FooterButton>
          </FooterTopItem>
          <FooterTopItem>
            <FooterHeader
              level={4}
              blackAndWhite={true}
              showCursor={false}
              text={texts.contactAndSupportTitle || "Contact and support"}
            />
            <p>
              {texts.contactAndSupportText ||
                `If you have a question that you can't find the answer to or you are just interested in
      one of ABB's products, feel free to contact us.`}
            </p>
            <FooterButton type="secondary" to="https://new.abb.com/contact-centers" target="_blank">
              {texts.contactUsButtonText || "Contact us"}
            </FooterButton>
          </FooterTopItem>
          <FooterTopItem>
            <FooterHeader
              level={4}
              blackAndWhite={true}
              showCursor={false}
              text={texts.latestNewsTitle || "Subscribe to the latest news"}
            />
            <Input
              dataType="email"
              placeholder={texts.emailAddressPlaceholder || "Your e-mail address..."}
              value={userEmail}
              onValueChange={(newState) => {
                setUserEmail(newState);
              }}
              // Don't validate email, because it will be validated in the final form where the sign up button leads to
              validationResult={true}
            />
            <DisclaimerText>
              {texts.disclaimerText ||
                `By clicking "Sign up", I agree on ABB Privacy Policy and receiving commercial info from
      ABB Ability.`}
            </DisclaimerText>
            <FooterButton type="primary-black" to={newsSignUpUrl} target="_blank">
              {texts.signUpButtonText || "Sign up"}
            </FooterButton>
          </FooterTopItem>
        </FooterTop>
        <FooterSplitter />
        <FooterBottom>
          <span>&copy; Copyright ABB {new Date().getUTCFullYear()}</span>
          <FooterLink href="https://new.abb.com/provider-information">
            {texts.providerInformationText || "Provider information"}
          </FooterLink>
          <FooterLink href="https://new.abb.com/privacy-policy">
            {texts.cookieAndPrivacyPolicyText || "Cookie and privacy policy"}
          </FooterLink>
          {openCookieDialog && (
            <FooterLink onClick={openCookieDialog}>
              {texts.changeCookieSettings || "Change cookie settings"}
            </FooterLink>
          )}
          <FooterLink onClick={() => setIsDisclaimerOpen(true)}>{t("Disclaimer")}</FooterLink>
          {!!version && (
            <FooterLink onClick={() => setIsReleaseNotesOpen(true)}>Version {version}</FooterLink>
          )}
        </FooterBottom>
      </FooterContent>
    );
  };
  return (
    <FooterContainer>
      <FooterBackground backgroundImage={backgroundImage} backgroundImageWebP={backgroundImageWebP}>
        {maxWidth ? (
          <MaxWidthContainer maxWidth={maxWidth}>{drawFooterContent()}</MaxWidthContainer>
        ) : (
          <>{drawFooterContent()}</>
        )}
      </FooterBackground>
    </FooterContainer>
  );
};
