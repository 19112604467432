/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModularProductsVm,
    ModularProductsVmFromJSON,
    ModularProductsVmToJSON,
    StaticProductsVm,
    StaticProductsVmFromJSON,
    StaticProductsVmToJSON,
} from '../models';

/**
 * 
 */
export class ProductSelectionMatrixApi extends runtime.BaseAPI {

    /**
     */
    async apiProductSelectionMatrixModularProductsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ModularProductsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductSelectionMatrix/modularProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModularProductsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiProductSelectionMatrixModularProductsGet(initOverrides?: RequestInit): Promise<ModularProductsVm> {
        const response = await this.apiProductSelectionMatrixModularProductsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductSelectionMatrixStaticProductsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StaticProductsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductSelectionMatrix/staticProducts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaticProductsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiProductSelectionMatrixStaticProductsGet(initOverrides?: RequestInit): Promise<StaticProductsVm> {
        const response = await this.apiProductSelectionMatrixStaticProductsGetRaw(initOverrides);
        return await response.value();
    }

}
