/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestFunctionalitySelections,
    AccessRequestFunctionalitySelectionsFromJSON,
    AccessRequestFunctionalitySelectionsFromJSONTyped,
    AccessRequestFunctionalitySelectionsToJSON,
} from './AccessRequestFunctionalitySelections';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';

/**
 * 
 * @export
 * @interface CreateAccessRequestCommand
 */
export interface CreateAccessRequestCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    userCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    position?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    addressStreet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    addressCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    addressPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    sapCustomerReferenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    pgCodes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    etcdIcvCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    guidCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccessRequestCommand
     */
    timeZone?: string | null;
    /**
     * 
     * @type {Array<AccessRequestFunctionalitySelections>}
     * @memberof CreateAccessRequestCommand
     */
    applicationFunctionalitySelections?: Array<AccessRequestFunctionalitySelections> | null;
    /**
     * 
     * @type {Array<RoleGroups>}
     * @memberof CreateAccessRequestCommand
     */
    roleGroupIds?: Array<RoleGroups> | null;
}

export function CreateAccessRequestCommandFromJSON(json: any): CreateAccessRequestCommand {
    return CreateAccessRequestCommandFromJSONTyped(json, false);
}

export function CreateAccessRequestCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccessRequestCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userCompanyName': !exists(json, 'userCompanyName') ? undefined : json['userCompanyName'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'addressStreet': !exists(json, 'addressStreet') ? undefined : json['addressStreet'],
        'addressCity': !exists(json, 'addressCity') ? undefined : json['addressCity'],
        'addressPostalCode': !exists(json, 'addressPostalCode') ? undefined : json['addressPostalCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'sapCustomerReferenceNumber': !exists(json, 'sapCustomerReferenceNumber') ? undefined : json['sapCustomerReferenceNumber'],
        'pgCodes': !exists(json, 'pgCodes') ? undefined : json['pgCodes'],
        'etcdIcvCode': !exists(json, 'etcdIcvCode') ? undefined : json['etcdIcvCode'],
        'guidCode': !exists(json, 'guidCode') ? undefined : json['guidCode'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'timeZone': !exists(json, 'timeZone') ? undefined : json['timeZone'],
        'applicationFunctionalitySelections': !exists(json, 'applicationFunctionalitySelections') ? undefined : (json['applicationFunctionalitySelections'] === null ? null : (json['applicationFunctionalitySelections'] as Array<any>).map(AccessRequestFunctionalitySelectionsFromJSON)),
        'roleGroupIds': !exists(json, 'roleGroupIds') ? undefined : (json['roleGroupIds'] === null ? null : (json['roleGroupIds'] as Array<any>).map(RoleGroupsFromJSON)),
    };
}

export function CreateAccessRequestCommandToJSON(value?: CreateAccessRequestCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userCompanyName': value.userCompanyName,
        'position': value.position,
        'addressStreet': value.addressStreet,
        'addressCity': value.addressCity,
        'addressPostalCode': value.addressPostalCode,
        'countryCode': value.countryCode,
        'sapCustomerReferenceNumber': value.sapCustomerReferenceNumber,
        'pgCodes': value.pgCodes,
        'etcdIcvCode': value.etcdIcvCode,
        'guidCode': value.guidCode,
        'comment': value.comment,
        'timeZone': value.timeZone,
        'applicationFunctionalitySelections': value.applicationFunctionalitySelections === undefined ? undefined : (value.applicationFunctionalitySelections === null ? null : (value.applicationFunctionalitySelections as Array<any>).map(AccessRequestFunctionalitySelectionsToJSON)),
        'roleGroupIds': value.roleGroupIds === undefined ? undefined : (value.roleGroupIds === null ? null : (value.roleGroupIds as Array<any>).map(RoleGroupsToJSON)),
    };
}

