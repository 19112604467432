import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import "ag-grid-enterprise";
import * as React from "react";
import styled from "styled-components";
import { GridLoadingOverlay } from "./GridLoadingOverlay";
import { GridStatusBarSelectedRowsCount } from "./GridStatusBarSelectedRowsCount";
import {
  GridApi,
  Column,
  ColDef,
  ColumnState,
  StatusPanelDef,
  ITooltipParams,
  ColumnApi,
  ColGroupDef,
  CellClassParams,
  EditableCallbackParams,
  GridReadyEvent,
  GetContextMenuItems,
  MenuItemDef
} from "ag-grid-community";
import { GridTooltip } from "./GridTooltip";
import GridStatusBarResetButton from "./GridStatusBarResetButton";
import { isEqual } from "lodash-es";
import { SubHeader } from "../styled/SubHeader";
import { getGridTranslations, getPopupParentDocumentBody } from "../../../utilities/gridUtils";
import "styles/agGridStyles.scss";
import { LicenseManager } from "ag-grid-enterprise";
import { agGridConfiguration } from "configuration/agGrid";
import GridStatusBarTotalRowsCount, {
  IGridStatusBarTotalRowsCount
} from "./GridStatusBarTotalRowsCount";

if (agGridConfiguration.licenseKey) {
  LicenseManager.setLicenseKey(agGridConfiguration.licenseKey);
}

interface ShowStatusBar {
  showRowCount?: boolean;
  showResetButton?: boolean;
  showSelectedRowsCount?: boolean;
  controlRowCountFromOutside?: boolean;
}

/**
 * Params for custom export callbacks
 */
export interface GridExportParams {
  api: GridApi;
  columnApi: ColumnApi;
}

export interface DefaultFilterModel {
  [key: string]: { filterType: string; values: any[] };
}

export interface GridProps extends Omit<AgGridReactProps, "defaultColDef"> {
  title?: string;
  titleHeadingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  autosizeCols?: boolean;
  disableColumnAutoSize?: boolean;
  keepEditingIfFocusIsLost?: boolean;
  showPointerOnRowHover?: boolean;
  statusBarOptions?: ShowStatusBar;
  gridCalculatedMaxWidth?: number;
  colDefDefault?: ColDef;
  disableResize: boolean;
  defaultSort?: ColumnState;
  defaultFilterModel?: DefaultFilterModel;
  isSortPersisted?: boolean;
  isOnlyResetSorting?: boolean;
  wrapHeaderText?: boolean;
  customRowCount?: number | "loading";
  t: (text: string) => string;
  gridRef?: React.LegacyRef<AgGridReact>;
  onExportCsv?: (params: GridExportParams) => void;
}

const GridContainer = styled.div<{ showPointerOnRowHover?: boolean; maxWidth?: number }>`
  display: flex !important;
  flex-grow: 1 !important;
  flex-direction: column !important;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : undefined)};
  .ag-row-hover {
    cursor: ${(props) => (props.showPointerOnRowHover ? "pointer !important" : "inherited")};
  }
`;

const GridInnerContainer = styled.div`
  display: flex !important;
  flex-grow: 1 !important;
  margin-top: ${(props) => props.theme.sizes.s};
`;

export class Grid extends React.PureComponent<GridProps> {
  private api: GridApi | undefined;
  private columnApi: ColumnApi | undefined;

  constructor(props: GridProps, ctx: any) {
    super(props, ctx);
    this.api = undefined;
    this.columnApi = undefined;
  }

  static defaultProps: Partial<GridProps> = {
    disableResize: false,
    isOnlyResetSorting: false,
    isSortPersisted: false
  };

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.resizeGrid);
  }

  componentDidUpdate(prevProps: GridProps): void {
    if (
      this.props.customRowCount !== prevProps.customRowCount &&
      this.props.statusBarOptions?.controlRowCountFromOutside === true
    ) {
      const panel = this.api?.getStatusPanel("statusBarTotalRowsCount");
      if (panel) {
        (panel as IGridStatusBarTotalRowsCount).setCount(this.props.customRowCount);
      }
    }
  }

  render(): React.ReactNode {
    const { title, colDefDefault, gridRef, ...agGridProps } = this.props;

    const columnDefs = this.applyGlobalStyleRules(agGridProps.columnDefs ?? null);

    return (
      <GridContainer
        showPointerOnRowHover={this.props.showPointerOnRowHover}
        maxWidth={this.props.gridCalculatedMaxWidth}
      >
        {this.renderOptionalTitle(title)}
        <GridInnerContainer>
          <div className="ag-theme-balham" style={{ flexGrow: 1 }}>
            <AgGridReact
              rowHeight={36}
              tooltipShowDelay={0}
              localeText={getGridTranslations(this.props.t)}
              defaultColDef={{
                resizable: true,
                sortable: true,
                minWidth: 100,
                wrapHeaderText: this.props.wrapHeaderText,
                autoHeaderHeight: this.props.wrapHeaderText,
                tooltipComponent: "customTooltip",
                tooltipValueGetter: (params) => {
                  if (params.valueFormatted) {
                    return params.valueFormatted;
                  }
                  return params.value;
                },
                ...colDefDefault
              }}
              enableBrowserTooltips
              {...agGridProps}
              ref={gridRef}
              columnDefs={columnDefs}
              loadingOverlayComponent={GridLoadingOverlay}
              onGridReady={(params: GridReadyEvent) => {
                this.api = params.api;
                this.columnApi = params.columnApi;

                if (this.props.autosizeCols) {
                  params.api.sizeColumnsToFit();
                }

                if (agGridProps.onGridReady) {
                  agGridProps.onGridReady(params);
                }
                if (!this.props.disableResize) {
                  window.addEventListener("resize", this.resizeGrid);
                }
                if (this.props.defaultFilterModel) {
                  params.api.setFilterModel(this.props.defaultFilterModel);
                  params.api.onFilterChanged();
                }
              }}
              onFirstDataRendered={(params) => {
                if (agGridProps.onFirstDataRendered) {
                  agGridProps.onFirstDataRendered(params);
                }

                if (this.props.defaultSort) {
                  const sortModel = params.columnApi.getColumnState();
                  if (this.props.isSortPersisted) {
                    const defaultSortModel = sortModel.map((model) => {
                      if (model.colId === this.props.defaultSort?.colId) {
                        return {
                          ...model,
                          sort: this.props.defaultSort?.sort
                        };
                      }
                      return {
                        ...model,
                        sort: null
                      };
                    });
                    setDefaultSortCol(params.api, defaultSortModel);
                  } else {
                    setDefaultSortCol(params.api, sortModel);
                  }
                }

                if (this.props.defaultFilterModel) {
                  setDefaultFilterModel(params.api, this.props.defaultFilterModel);
                }

                this.handleResetButtonStateRegardingFilters(params.api);
                this.handleResetButtonStateRegardingSorting(params.columnApi, params.api);
              }}
              onFilterChanged={(params) => {
                this.handleResetButtonStateRegardingFilters(params.api);
              }}
              onSortChanged={(params) => {
                this.handleResetButtonStateRegardingSorting(params.columnApi, params.api);
              }}
              stopEditingWhenCellsLoseFocus={!this.props.keepEditingIfFocusIsLost}
              onRowDataChanged={(params) => {
                if (!this.props.disableColumnAutoSize) {
                  params.columnApi.autoSizeAllColumns();
                }
              }}
              onRowDataUpdated={(params) => {
                if (!this.props.disableColumnAutoSize) {
                  params.columnApi.autoSizeAllColumns();
                }
              }}
              components={{
                statusBarTotalRowsCount: GridStatusBarTotalRowsCount,
                statusBarResetButton: GridStatusBarResetButton,
                statusBarSelectedRowsCount: GridStatusBarSelectedRowsCount,
                customTooltip: GridTooltip
              }}
              statusBar={
                this.props.statusBarOptions
                  ? {
                      statusPanels: getStatusPanels(this.props)
                    }
                  : undefined
              }
              popupParent={getPopupParentDocumentBody()}
              getContextMenuItems={this.props.getContextMenuItems ?? this.getContextMenuItems}
            />
          </div>
        </GridInnerContainer>
      </GridContainer>
    );
  }

  private resizeGrid = (): any => {
    if (this.api) {
      setTimeout(() => {
        this.api?.sizeColumnsToFit();
      });
    }
  };

  private applyGlobalStyleRules(
    columnDefs: (ColGroupDef | ColDef)[] | null
  ): (ColDef | ColGroupDef)[] | null {
    if (!columnDefs) {
      return columnDefs;
    }

    return columnDefs.map((def: ColDef) => ({
      ...def,
      cellClassRules: {
        ...def.cellClassRules,
        readonly: (params: CellClassParams) => {
          const editable = params?.colDef?.editable;

          if (typeof editable === "boolean") {
            return !editable;
          }

          if (typeof editable === "function") {
            return !editable(params as unknown as EditableCallbackParams);
          }

          return true;
        }
      }
    }));
  }

  private renderOptionalTitle(title: string | undefined): React.ReactNode {
    if (!title) {
      return null;
    }

    return <SubHeader text={title} />;
  }

  private handleResetButtonStateRegardingSorting(columnApi: ColumnApi, gridApi: GridApi) {
    const columnState = columnApi.getColumnState();
    let hasChanged = false;
    for (const column of columnState) {
      if (
        column.colId === this.props.defaultSort?.colId &&
        column.sort !== this.props.defaultSort?.sort
      ) {
        hasChanged = true;
        this.enableResetButton(gridApi, true);
        break;
      }
    }

    if (!hasChanged) {
      this.enableResetButton(gridApi, false);
    }
  }

  private handleResetButtonStateRegardingFilters(gridApi: GridApi) {
    const filterModel = gridApi.getFilterModel();
    if (this.props.defaultFilterModel && !isEqual(filterModel, this.props.defaultFilterModel)) {
      this.enableResetButton(gridApi, true);
    } else if (!this.props.defaultFilterModel && Object.keys(filterModel).length > 0) {
      this.enableResetButton(gridApi, true);
    } else {
      this.enableResetButton(gridApi, false);
    }
  }

  private enableResetButton(gridApi: GridApi, isEnabled: boolean) {
    const resetButtonComponent = getStatusPanelComponent<GridStatusBarResetButton>(
      gridApi,
      "statusBarResetButton"
    );
    resetButtonComponent?.setIsEnabled(isEnabled);
  }

  private getContextMenuItems: GetContextMenuItems = () => {
    const { onExportCsv: onCsvExport } = this.props;
    const items: (string | MenuItemDef)[] = [
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator"
    ];

    if (onCsvExport) {
      items.push({
        name: "Export",
        icon: '<span class="ag-icon ag-icon-export" unselectable="on" role="presentation"></span>',
        subMenu: [
          {
            name: "CSV Export",
            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
            action: () => {
              if (this.api && this.columnApi) {
                onCsvExport({ api: this.api, columnApi: this.columnApi });
              }
            }
          }
        ]
      });
    } else {
      // Use AgGrid default export implementation
      items.push("export");
    }

    return items;
  };
}

export interface AgGridSortModel {
  sortColumn: string;
  isAsc: boolean;
}
export function getSortModel(sortModel: ColumnState[]): AgGridSortModel | undefined {
  if (sortModel.length > 0 && sortModel[0].colId) {
    const sortColumn = sortModel.find((x) => x.sort !== null) ?? sortModel[0];
    return {
      sortColumn: sortColumn.colId,
      isAsc: sortColumn.sort === "asc" ? true : false
    };
  }
  return undefined;
}

const setDefaultSortCol = (gridApi: GridApi, sortModel: ColumnState[]) => {
  const resetButtonComponent = getStatusPanelComponent<GridStatusBarResetButton>(
    gridApi,
    "statusBarResetButton"
  );
  resetButtonComponent?.setDefaultSortModel(sortModel);
};

const setDefaultFilterModel = (
  gridApi: GridApi,
  filterModel: Record<string, { filterType: string; values: any[] }>
) => {
  const resetButtonComponent = getStatusPanelComponent<GridStatusBarResetButton>(
    gridApi,
    "statusBarResetButton"
  );
  resetButtonComponent?.setDefaultFilterModel(filterModel);
};

const getStatusPanelComponent = <T,>(gridApi: GridApi, key: string): T | undefined => {
  const statusBarComponent = gridApi.getStatusPanel(key) as any;
  return statusBarComponent as unknown as T;
};

export const getTotalWidth = (columns: Column[]): number => {
  let total = 0;
  columns.forEach((column) => {
    total += column.getActualWidth();
  });
  return total;
};

interface ColHeaderAndHeaderTooltip {
  headerName: string;
  headerTooltip: string;
}

export const createHeaderAndHeaderTooltip = (
  headerName: string,
  headerTooltip?: string
): ColHeaderAndHeaderTooltip => {
  return {
    headerName: headerName,
    headerTooltip: headerTooltip ?? headerName
  };
};

export const getDefaultColumnSort = (
  isAsc: boolean | undefined,
  sortColumn: string | null | undefined
): ColumnState => {
  return {
    colId: sortColumn ? sortColumn : "",
    sort: isAsc ? "asc" : "desc"
  };
};

const getStatusPanels = (props: GridProps): StatusPanelDef[] => {
  const statusPanels: StatusPanelDef[] = [];

  if (props.statusBarOptions?.showSelectedRowsCount !== false) {
    statusPanels.push({
      statusPanel: "statusBarSelectedRowsCount",
      key: "statusBarSelectedRowsCount",
      statusPanelParams: {
        t: props.t
      }
    });
  }

  if (props.statusBarOptions?.showRowCount) {
    statusPanels.push({
      statusPanel: "statusBarTotalRowsCount",
      key: "statusBarTotalRowsCount",
      statusPanelParams: {
        t: props.t,
        controlRowCountFromOutside: props.statusBarOptions?.controlRowCountFromOutside
      }
    });
  }

  if (props.statusBarOptions?.showResetButton) {
    statusPanels.push({
      statusPanel: "statusBarResetButton",
      key: "statusBarResetButton",
      statusPanelParams: {
        isOnlyResetSorting: props.isOnlyResetSorting,
        disableResize: props.disableResize,
        t: props.t
      },
      align: "left"
    });
  }
  return statusPanels;
};

type CreateCellRendererField = {
  field: string;
  tooltipValueGetter: ((params: ITooltipParams) => string) | undefined;
};

export const createCellRendererField = (field: string): CreateCellRendererField => {
  return { field: field, tooltipValueGetter: () => "" };
};
