/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OverrideStatusAuditVm,
    OverrideStatusAuditVmFromJSON,
    OverrideStatusAuditVmToJSON,
    SystemSettingsVm,
    SystemSettingsVmFromJSON,
    SystemSettingsVmToJSON,
    UpdateFrontPageContentCommand,
    UpdateFrontPageContentCommandFromJSON,
    UpdateFrontPageContentCommandToJSON,
    UpdateSystemSettingsCommand,
    UpdateSystemSettingsCommandFromJSON,
    UpdateSystemSettingsCommandToJSON,
} from '../models';

export interface ApiSystemSettingsFrontpagePutRequest {
    updateFrontPageContentCommand?: UpdateFrontPageContentCommand;
}

export interface ApiSystemSettingsPutRequest {
    updateSystemSettingsCommand?: UpdateSystemSettingsCommand;
}

/**
 * 
 */
export class SystemSettingsApi extends runtime.BaseAPI {

    /**
     */
    async apiSystemSettingsFrontpagePutRaw(requestParameters: ApiSystemSettingsFrontpagePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/SystemSettings/frontpage`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFrontPageContentCommandToJSON(requestParameters.updateFrontPageContentCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiSystemSettingsFrontpagePut(requestParameters: ApiSystemSettingsFrontpagePutRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiSystemSettingsFrontpagePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SystemSettingsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/SystemSettings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiSystemSettingsGet(initOverrides?: RequestInit): Promise<SystemSettingsVm> {
        const response = await this.apiSystemSettingsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsOverrideStatusAuditPatchRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OverrideStatusAuditVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/SystemSettings/overrideStatusAudit`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverrideStatusAuditVmFromJSON(jsonValue));
    }

    /**
     */
    async apiSystemSettingsOverrideStatusAuditPatch(initOverrides?: RequestInit): Promise<OverrideStatusAuditVm> {
        const response = await this.apiSystemSettingsOverrideStatusAuditPatchRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSystemSettingsPutRaw(requestParameters: ApiSystemSettingsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/SystemSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSystemSettingsCommandToJSON(requestParameters.updateSystemSettingsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSystemSettingsPut(requestParameters: ApiSystemSettingsPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiSystemSettingsPutRaw(requestParameters, initOverrides);
    }

}
