import { Action } from "redux";
import { ApiTeamsGetRequest } from "api";

export enum ManageTeamsViewActionTypes {
  ChangeManageTeamsCriteria = "ManageTeams/ChangeManageTeamsCriteria"
}

export interface ChangeManageTeamsCriteria extends Action {
  type: ManageTeamsViewActionTypes.ChangeManageTeamsCriteria;
  criteria: ApiTeamsGetRequest;
}

export const manageTeamsViewActions = {
  changeManageTeamsCriteria: (criteria: ApiTeamsGetRequest): ChangeManageTeamsCriteria => ({
    type: ManageTeamsViewActionTypes.ChangeManageTeamsCriteria,
    criteria
  })
};

export type ManageTeamsViewActions =
  | ChangeManageTeamsCriteria;
