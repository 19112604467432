/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryTermsAndConditionsDto
 */
export interface CountryTermsAndConditionsDto {
    /**
     * 
     * @type {number}
     * @memberof CountryTermsAndConditionsDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CountryTermsAndConditionsDto
     */
    attachmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CountryTermsAndConditionsDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CountryTermsAndConditionsDto
     */
    lastModified?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CountryTermsAndConditionsDto
     */
    countryName?: string | null;
}

export function CountryTermsAndConditionsDtoFromJSON(json: any): CountryTermsAndConditionsDto {
    return CountryTermsAndConditionsDtoFromJSONTyped(json, false);
}

export function CountryTermsAndConditionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryTermsAndConditionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'attachmentId': !exists(json, 'attachmentId') ? undefined : json['attachmentId'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModified': !exists(json, 'lastModified') ? undefined : (json['lastModified'] === null ? null : new Date(json['lastModified'])),
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
    };
}

export function CountryTermsAndConditionsDtoToJSON(value?: CountryTermsAndConditionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attachmentId': value.attachmentId,
        'identifier': value.identifier,
        'fileName': value.fileName,
        'extension': value.extension,
        'description': value.description,
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified === null ? null : value.lastModified.toISOString()),
        'countryCode': value.countryCode,
        'countryName': value.countryName,
    };
}

