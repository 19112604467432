/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ManagedPriceListDto,
    ManagedPriceListDtoFromJSON,
    ManagedPriceListDtoFromJSONTyped,
    ManagedPriceListDtoToJSON,
} from './ManagedPriceListDto';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';

/**
 * 
 * @export
 * @interface ManagedPriceListsVm
 */
export interface ManagedPriceListsVm {
    /**
     * 
     * @type {Array<ManagedPriceListDto>}
     * @memberof ManagedPriceListsVm
     */
    managedPriceLists: Array<ManagedPriceListDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof ManagedPriceListsVm
     */
    criteria: PaginationReturnModel;
}

export function ManagedPriceListsVmFromJSON(json: any): ManagedPriceListsVm {
    return ManagedPriceListsVmFromJSONTyped(json, false);
}

export function ManagedPriceListsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedPriceListsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'managedPriceLists': ((json['managedPriceLists'] as Array<any>).map(ManagedPriceListDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function ManagedPriceListsVmToJSON(value?: ManagedPriceListsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'managedPriceLists': ((value.managedPriceLists as Array<any>).map(ManagedPriceListDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

