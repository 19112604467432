/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveApplicationNotificationsCommand
 */
export interface RemoveApplicationNotificationsCommand {
    /**
     * 
     * @type {Array<number>}
     * @memberof RemoveApplicationNotificationsCommand
     */
    applicationNotificationIds?: Array<number> | null;
}

export function RemoveApplicationNotificationsCommandFromJSON(json: any): RemoveApplicationNotificationsCommand {
    return RemoveApplicationNotificationsCommandFromJSONTyped(json, false);
}

export function RemoveApplicationNotificationsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveApplicationNotificationsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationNotificationIds': !exists(json, 'applicationNotificationIds') ? undefined : json['applicationNotificationIds'],
    };
}

export function RemoveApplicationNotificationsCommandToJSON(value?: RemoveApplicationNotificationsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationNotificationIds': value.applicationNotificationIds,
    };
}

