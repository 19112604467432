import { createGenericSaga, defaultSagaErrorHandler } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const myKnowledgeAiSagas = {
  getMyKnowledgeAiJwt: createGenericSaga(
    "myKnowledgeAiSagas/getMyKnowledgeAiJwt",
    getApiRegistry().myKnowledgeAiApi.apiMyKnowledgeAiTokenGet.bind(
      getApiRegistry().myKnowledgeAiApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
