import { Action } from "redux";
import { PriceProductDto } from "../../../api/models/PriceProductDto";

export enum QuickPriceCheckActionTypes {
  SetPriceProducts = "QuickPriceCheck/SetPriceProducts"
}

export interface SetPriceProducts extends Action {
  type: QuickPriceCheckActionTypes.SetPriceProducts;
  priceProducts: PriceProductDto[];
}

export const quickPriceCheckActions = {
  setPriceProducts: (priceProducts: PriceProductDto[]): SetPriceProducts => ({
    type: QuickPriceCheckActionTypes.SetPriceProducts,
    priceProducts
  })
};

export type QuickpriceCheckActions = SetPriceProducts;
