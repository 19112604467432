import {
  createGenericSaga,
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const accessRequestsSagas = {
  createAccessRequest: createGenericSaga(
    "accessRequestsSagas/createAccessRequest",
    getApiRegistry().accessRequestsApi.apiAccessRequestsPost.bind(
      getApiRegistry().accessRequestsApi
    ),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Access request created"),
      onFail: defaultSagaErrorHandler
    }
  ),
  getMetadata: createGenericSaga(
    "accessRequestsSagas/getMetadata",
    getApiRegistry().accessRequestsApi.apiAccessRequestsMetadataGet.bind(
      getApiRegistry().accessRequestsApi
    )
  ),
  getCountries: createGenericSaga(
    "accessRequestSagas/getCountries",
    getApiRegistry().countriesApi.apiCountriesGet.bind(getApiRegistry().countriesApi)
  ),
  getTimeZones: createGenericSaga(
    "accessRequestSagas/getTimeZones",
    getApiRegistry().timeZonesApi.apiTimeZonesGet.bind(getApiRegistry().timeZonesApi)
  )
};
