/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureDto,
    FeatureDtoFromJSON,
    FeatureDtoFromJSONTyped,
    FeatureDtoToJSON,
} from './FeatureDto';

/**
 * 
 * @export
 * @interface ProductVersionDetailDto
 */
export interface ProductVersionDetailDto {
    /**
     * 
     * @type {number}
     * @memberof ProductVersionDetailDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    name: string;
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof ProductVersionDetailDto
     */
    features: Array<FeatureDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    shortDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    previewImage: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    pageUrl: string;
    /**
     * 
     * @type {number}
     * @memberof ProductVersionDetailDto
     */
    seriesId: number;
    /**
     * 
     * @type {string}
     * @memberof ProductVersionDetailDto
     */
    materialCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductVersionDetailDto
     */
    hasActiveStatus?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductVersionDetailDto
     */
    materialCodeMasks?: Array<string> | null;
}

export function ProductVersionDetailDtoFromJSON(json: any): ProductVersionDetailDto {
    return ProductVersionDetailDtoFromJSONTyped(json, false);
}

export function ProductVersionDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductVersionDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'features': ((json['features'] as Array<any>).map(FeatureDtoFromJSON)),
        'description': json['description'],
        'shortDescription': json['shortDescription'],
        'previewImage': json['previewImage'],
        'pageUrl': json['pageUrl'],
        'seriesId': json['seriesId'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'hasActiveStatus': !exists(json, 'hasActiveStatus') ? undefined : json['hasActiveStatus'],
        'materialCodeMasks': !exists(json, 'materialCodeMasks') ? undefined : json['materialCodeMasks'],
    };
}

export function ProductVersionDetailDtoToJSON(value?: ProductVersionDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'features': ((value.features as Array<any>).map(FeatureDtoToJSON)),
        'description': value.description,
        'shortDescription': value.shortDescription,
        'previewImage': value.previewImage,
        'pageUrl': value.pageUrl,
        'seriesId': value.seriesId,
        'materialCode': value.materialCode,
        'hasActiveStatus': value.hasActiveStatus,
        'materialCodeMasks': value.materialCodeMasks,
    };
}

