import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const fallbackLanguage = "en_US";

i18n.on("failedLoading", () => {
  i18n.changeLanguage(fallbackLanguage);
  localStorage.setItem("language", fallbackLanguage);
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("language") || fallbackLanguage,
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false,
    nsSeparator: "////",
    backend: {
      loadPath: process.env.REACT_APP_BASEPATH + "/api/translation/language/{{lng}}"
    }
  });

export default i18n;
