import {
  Checkbox,
  Dropdown,
  DropdownOption,
  Input,
  LoadingIndicator,
  NumericInput
} from "@abb/abb-common-ux-react";
import {
  CountryDto,
  SalesChannelDto,
  ShoppingCartDto,
  CompanyDto,
  AdditionalQuoteReceiver
} from "api";
import { QuoteRequestedCommand } from "api";
import { ErrorMessage, Formik } from "formik";
import { Button } from "framework/components/Button";
import { LabelWithText } from "framework/components/LabelWithText";
import { TFunction } from "i18next";
import React, { useEffect, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { DetailsRadioGroup } from "./orderDetails/DetailsRadioGroup";
import { Error } from "framework/components/Error";
import { InputLabel } from "framework/components/InputLabel";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { DatePickerInput } from "framework/components/DatePickerInput";
import { getDateTime, getValidDateFormat } from "utilities/dateUtils";
import * as Yup from "yup";
import { handleFormikValueChange, parseDateStringForYupValidation } from "utilities/formikUtils";
import { getDaysFromNow } from "../validationSchema";
import { Dispatch } from "redux";
import { Dialog } from "framework/components/Dialog";
import { emailValidationSchema } from "./ApprovalDialog";
import { ResizableInput } from "framework/components/styled/ResizableInput";
import { QuoteAttachmentsUploader } from "applications/manage/manageQuotes/components/QuoteAttachmentsUploader";
import {
  getDefaultValidityMonths,
  getDraftQuoteFiles,
  getQuoteIdAfterRequestQuotation,
  getRequestQuoteStatus
} from "applications/common/reducers/shoppingCartReducer";
import { useSelector } from "react-redux";
import { RequestStatus } from "framework/state/requestStatus";
import moment from "moment";
import { usePrevious } from "framework/hooks/usePrevious";
import { getCompanyPgCodesStatus } from "../reducers/shoppingCartCompanyReducer";

const TextWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.s};
  margin-bottom: ${(props) => props.theme.sizes.m};
  width: 100%;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.l};
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const Container = styled.div``;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const FlexChild = styled.div<{ width?: number }>`
  min-width: ${(props) => props.width}px;
  flex: 1;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  width: 100%;
  flex-wrap: wrap;
`;

const DropdownContainer = styled.div`
  .ABB_CommonUX_Dropdown__comp {
    div[class$="-menu"] > div {
      max-height: 200px !important;
    }
  }
`;

const StyledMultiDropdown = styled(Dropdown)`
  [class$="-option"] {
    background-color: ${(props) => props.theme.colors.whitePrimary};
  }
`;

const CustomDialog = styled(Dialog)`
  max-height: 95vh;
  overflow: auto;
`;

const LoadingIndicatorContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface RequestQuotationForm
  extends Omit<
    QuoteRequestedCommand,
    | "shoppingCartGuid"
    | "isExternal"
    | "expectedOrderDate"
    | "isMaintainedCustomer"
    | "requestedEndDate"
  > {
  isEndCustomer?: boolean | null;
  salesChannelDescription: undefined | string;
  expectedOrderDate: string | undefined;
  requestedEndDate: string | undefined;
  isDraft: boolean;
}

const values = (
  shoppingCart: ShoppingCartDto | undefined,
  defaultPgCode: string | null | undefined,
  company: CompanyDto | undefined,
  defaultValidityMonths: number
): RequestQuotationForm => {
  const isInternal = company && company.isInternal;

  // for internal
  const isAccountCustomerEndCustomer =
    isInternal &&
    shoppingCart?.shoppingCartAccountCustomer?.name ===
      shoppingCart?.shoppingCartEndCustomer?.name &&
    shoppingCart?.shoppingCartAccountCustomer?.streetAddress ===
      shoppingCart?.shoppingCartEndCustomer?.streetAddress &&
    shoppingCart?.shoppingCartAccountCustomer?.city ===
      shoppingCart?.shoppingCartEndCustomer?.city &&
    shoppingCart?.shoppingCartAccountCustomer?.country ===
      shoppingCart?.shoppingCartEndCustomer?.country &&
    shoppingCart?.shoppingCartAccountCustomer?.countryCode ===
      shoppingCart?.shoppingCartEndCustomer?.countryCode;

  // external
  const isEndCustomer =
    !isInternal &&
    company &&
    shoppingCart?.shoppingCartEndCustomer &&
    shoppingCart?.shoppingCartEndCustomer?.name?.startsWith(company?.name || "") &&
    company.addressStreet === shoppingCart?.shoppingCartEndCustomer?.streetAddress &&
    company.addressCity === shoppingCart?.shoppingCartEndCustomer?.city &&
    company.countryName === shoppingCart?.shoppingCartEndCustomer?.country &&
    company.countryCode === shoppingCart?.shoppingCartEndCustomer?.countryCode;

  return {
    quotationRequestMessage: shoppingCart?.draftQuote?.requestMessage,
    salesChannelId: shoppingCart?.salesChannelId,
    salesChannelDescription: shoppingCart?.salesChannelDescription ?? undefined,
    expectedOrderDate: shoppingCart?.draftQuote?.expectedOrderDate
      ? moment(shoppingCart.draftQuote?.expectedOrderDate).format(getValidDateFormat())
      : undefined,
    requestedEndDate: moment()
      .endOf("day")
      .add(defaultValidityMonths, "months")
      .format(getValidDateFormat()),
    projectName: shoppingCart?.projectName,
    sfdcReference: shoppingCart?.sfdcReference,
    accountCustomerCompanyName: shoppingCart?.shoppingCartAccountCustomer?.name,
    accountCustomerStreetAddress: shoppingCart?.shoppingCartAccountCustomer?.streetAddress,
    accountCustomerCompanyCity: shoppingCart?.shoppingCartAccountCustomer?.city,
    accountCustomerCompanyCountry: shoppingCart?.shoppingCartAccountCustomer?.country,
    accountCustomerCompanyCountryCode: shoppingCart?.shoppingCartAccountCustomer?.countryCode,
    accountCustomerIcvCode: shoppingCart?.shoppingCartAccountCustomer?.etcdIcvCode,
    endCustomerCompanyCountryCode: shoppingCart?.shoppingCartEndCustomer?.countryCode,
    endCustomerStreetAddress: shoppingCart?.shoppingCartEndCustomer?.streetAddress,
    endCustomerCompanyCountry: shoppingCart?.shoppingCartEndCustomer?.country,
    endCustomerCompanyName: shoppingCart?.shoppingCartEndCustomer?.name,
    endCustomerCompanyCity: shoppingCart?.shoppingCartEndCustomer?.city,
    endCustomerCompanyGuid: shoppingCart?.shoppingCartEndCustomer?.guidCode,
    isEndCustomer: shoppingCart?.shoppingCartEndCustomer
      ? isInternal
        ? isAccountCustomerEndCustomer
        : isEndCustomer
      : undefined,
    winningPercentage: shoppingCart?.draftQuote?.winningPercentage,
    frameAgreement: shoppingCart?.draftQuote?.frameAgreement,
    pgCode: shoppingCart?.pgCode ?? defaultPgCode,
    quoteReceiverEmail: shoppingCart?.draftQuote?.quoteReceiverEmail,
    additionalQuoteReceivers: shoppingCart?.draftQuote?.additionalQuoteReceivers,
    removedDefaultQuoteReceivers: shoppingCart?.draftQuote?.removedDefaultQuoteReceivers,
    isDraft: false,
    totalSalesPrice: shoppingCart?.draftQuote?.totalSalesPrice
  };
};

const errorMessages = {
  maxChar: (max = 256): string => `Maximum of ${max} characters`,
  required: "Required"
};

export const getDateSchema = (t: TFunction) =>
  Yup.date()
    .transform(parseDateStringForYupValidation)
    .required(errorMessages.required)
    .min(getDaysFromNow(-1), t("Must not be in the past"))
    .typeError(t("Must be a valid date"));

export const getRequestedEndDateSchema = (t: TFunction, defaultValidityMonths: number) =>
  Yup.date()
    .optional()
    .when("frameAgreement", {
      is: (frameAgreement) => frameAgreement === true,
      then: Yup.date()
        .transform(parseDateStringForYupValidation)
        .required(errorMessages.required)
        .min(
          moment().endOf("day").add(defaultValidityMonths, "months").format(getValidDateFormat()),
          t("Minimal validity date is ") +
            moment().endOf("day").add(defaultValidityMonths, "months").format(getValidDateFormat())
        )
        .typeError(t("Must be a valid date"))
    });

export const getQuotationRequestValidationSchema = (
  t: TFunction,
  isExternal: boolean,
  isMaintainedCustomer: boolean,
  isBuildQuote: boolean,
  defaultValidityMonths: number,
  defaultQuoteReceivers: number
): Yup.ObjectSchema => {
  return Yup.object().shape({
    projectName: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isDraft === false,
        then: Yup.string()
          .required(errorMessages.required)
          .max(256, errorMessages.maxChar())
          .typeError(errorMessages.required)
      }),
    sfdcReference: Yup.string().optional().max(256, errorMessages.maxChar()),
    salesChannelId: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    salesChannelDescription: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    isEndCustomer: Yup.bool()
      .nullable()
      .when("isDraft", {
        is: (isDraft) => isDraft === false,
        then: Yup.bool().required().typeError(errorMessages.required)
      }),
    accountCustomerCompanyName: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerStreetAddress: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyCity: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyCountry: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyCountryCode: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when("isDraft", {
        is: (isDraft) => isExternal === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerIcvCode: Yup.string().optional().max(256, errorMessages.maxChar()),
    endCustomerCompanyCountryCode: Yup.string()
      .max(256, errorMessages.maxChar())
      .optional()
      .when(["isEndCustomer", "isDraft"], {
        is: (isEndCustomer, isDraft) => isEndCustomer === false && isDraft === false,
        then: Yup.string().max(256, errorMessages.maxChar()).required()
      }),
    endCustomerStreetAddress: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when(["isEndCustomer", "isDraft"], {
        is: (isEndCustomer, isDraft) => isEndCustomer === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyCountry: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when(["isEndCustomer", "isDraft"], {
        is: (isEndCustomer, isDraft) => isEndCustomer === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyName: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when(["isEndCustomer", "isDraft"], {
        is: (isEndCustomer, isDraft) => isEndCustomer === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyCity: Yup.string()
      .optional()
      .max(256, errorMessages.maxChar())
      .when(["isEndCustomer", "isDraft"], {
        is: (isEndCustomer, isDraft) => isEndCustomer === false && isDraft === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyGuid: Yup.string().optional().max(256, errorMessages.maxChar()),
    expectedOrderDate: getDateSchema(t),
    requestedEndDate: getRequestedEndDateSchema(t, defaultValidityMonths),
    quotationRequestMessage: Yup.string().optional().nullable(),
    pgCode: Yup.string()
      .nullable()
      .when("isDraft", {
        is: (isDraft) => !isExternal && !isDraft,
        then: Yup.string()
          .max(4, t(t("Max 4 characters")))
          .matches(/^\d+$/, t("Only numbers are allowed"))
          .required(t("PG code is required"))
      }),
    winningPercentage: Yup.number()
      .nullable()
      .when("isDraft", {
        is: (isDraft) => (!isExternal || isMaintainedCustomer) && !isDraft,
        then: Yup.number()
          .min(0, t("Minimum 0%"))
          .max(constMaxWinPercentage, t("Maximum 100%"))
          .required(t("Win % is required"))
          .typeError(t("Win % is required"))
      }),
    frameAgreement: Yup.boolean().nullable(),
    quoteReceiverEmail: Yup.string()
      .nullable()
      .when("isDraft", {
        is: (isDraft) => isBuildQuote && !isDraft,
        then: emailValidationSchema()
      }),
    additionalQuoteReceivers: Yup.array(Yup.string()).nullable(),
    removedDefaultQuoteReceivers: Yup.array(Yup.string())
      .nullable()
      .when("isDraft", {
        is: (isDraft) => !isDraft,
        then: Yup.array(Yup.string())
          .nullable()
          .max(
            Math.max(defaultQuoteReceivers - 1, 0),
            "At least one default quote receiver is required"
          )
      }),
    totalSalesPrice: Yup.number().nullable()
  });
};

interface RequestQuotationDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  shoppingCart: ShoppingCartDto | undefined;
  isLoading: boolean;
  isQuoteFilesLoading: boolean;
  countries: CountryDto[] | undefined;
  salesChannels: SalesChannelDto[] | undefined;
  dispatch: Dispatch;
  pgCodes: string[] | null | undefined;
  selectedCompany: CompanyDto;
  isBuildQuote: boolean;
  additionalQuoteReceivers: AdditionalQuoteReceiver[] | undefined;
  defaultQuoteReceivers: AdditionalQuoteReceiver[] | undefined;
}

export const constMaxWinPercentage = 100;

export const RequestQuotationDialog = ({
  isOpen,
  t,
  setIsOpen,
  isLoading,
  isQuoteFilesLoading,
  shoppingCart,
  countries,
  salesChannels,
  dispatch,
  pgCodes,
  selectedCompany,
  isBuildQuote,
  additionalQuoteReceivers: additionalQuoteHandlers,
  defaultQuoteReceivers
}: RequestQuotationDialogProps): JSX.Element => {
  const theme = useTheme();
  const { isInternal, isMaintainedCustomer, defaultPgCode, isInternalBuyer } = selectedCompany;
  const requestQuotationStatus = useSelector(getRequestQuoteStatus);
  const previousRequestQuotationStatus = usePrevious(requestQuotationStatus);
  const quoteIdAfterRequestQuotation = useSelector(getQuoteIdAfterRequestQuotation);
  const draftQuoteFiles = useSelector(getDraftQuoteFiles);
  const defaultValidityMonths = useSelector(getDefaultValidityMonths);
  const companyPgCodesStatus = useSelector(getCompanyPgCodesStatus);

  const validationSchema = useMemo(
    () =>
      getQuotationRequestValidationSchema(
        t,
        !isInternal,
        !!isMaintainedCustomer,
        isBuildQuote,
        defaultValidityMonths,
        defaultQuoteReceivers?.length || 0
      ),
    [
      isInternal,
      t,
      isMaintainedCustomer,
      isBuildQuote,
      defaultValidityMonths,
      defaultQuoteReceivers
    ]
  );

  const requestQuotation = (values: RequestQuotationForm) => {
    if (shoppingCart?.guid) {
      const accountCustomer = {
        accountCustomerCompanyName: isInternal
          ? values.accountCustomerCompanyName
          : selectedCompany.companyDisplayName,
        accountCustomerStreetAddress: isInternal
          ? values.accountCustomerStreetAddress
          : selectedCompany.addressStreet,
        accountCustomerCompanyCity: isInternal
          ? values.accountCustomerCompanyCity
          : selectedCompany.addressCity,
        accountCustomerCompanyCountry: isInternal
          ? values.accountCustomerCompanyCountry
          : selectedCompany.countryName,
        accountCustomerCompanyCountryCode: isInternal
          ? values.accountCustomerCompanyCountryCode
          : selectedCompany.countryCode,
        accountCustomerIcvCode: isInternal
          ? values.accountCustomerIcvCode
          : selectedCompany.globalCustomerNumber
      };
      const quoteRequestedCommand: QuoteRequestedCommand = {
        shoppingCartGuid: shoppingCart.guid,
        quotationRequestMessage: values.quotationRequestMessage,
        projectName: values.projectName,
        sfdcReference: values.sfdcReference,
        salesChannelId: isInternal ? values.salesChannelId : undefined,
        ...accountCustomer,
        endCustomerCompanyName: !values.isEndCustomer
          ? values.endCustomerCompanyName
          : accountCustomer.accountCustomerCompanyName,
        endCustomerStreetAddress: !values.isEndCustomer
          ? values.endCustomerStreetAddress
          : accountCustomer.accountCustomerStreetAddress,
        endCustomerCompanyCity: !values.isEndCustomer
          ? values.endCustomerCompanyCity
          : accountCustomer.accountCustomerCompanyCity,
        endCustomerCompanyCountry: !values.isEndCustomer
          ? values.endCustomerCompanyCountry
          : accountCustomer.accountCustomerCompanyCountry,
        endCustomerCompanyCountryCode: !values.isEndCustomer
          ? values.endCustomerCompanyCountryCode
          : accountCustomer.accountCustomerCompanyCountryCode,
        endCustomerCompanyGuid: !values.isEndCustomer
          ? values.endCustomerCompanyGuid
          : accountCustomer.accountCustomerIcvCode,
        expectedOrderDate: getDateTime(values.expectedOrderDate, getValidDateFormat()),
        requestedEndDate:
          values.frameAgreement === true
            ? getDateTime(values.requestedEndDate, getValidDateFormat())
            : undefined,
        pgCode: isInternal ? values.pgCode : undefined,
        frameAgreement: isInternal || isMaintainedCustomer ? !!values.frameAgreement : undefined,
        winningPercentage:
          isInternal || isMaintainedCustomer ? values.winningPercentage : undefined,
        quoteReceiverEmail: isBuildQuote ? values.quoteReceiverEmail : null,
        isBuildQuote: isBuildQuote,
        additionalQuoteReceivers: values.additionalQuoteReceivers,
        removedDefaultQuoteReceivers: isInternal ? values.removedDefaultQuoteReceivers : undefined,
        isDraft: values.isDraft,
        totalSalesPrice: isInternalBuyer && values.totalSalesPrice ? values.totalSalesPrice : null
      };
      dispatch(shoppingCartSagas.requestQuote.createAction({ quoteRequestedCommand }));
    }
  };
  const getDefaultItemTemplate = (o: string) => (
    <span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{o}</span>
        <span style={{ fontSize: "80%", opacity: ".7" }}>{t("Company's default")}</span>
      </div>
    </span>
  );

  useEffect(() => {
    if (
      // When working with draft request, if cart is just activated requestQuotationStatus can be
      // Completed when opening the dialog which brakes attachemnt adding -> check also previous value before upload
      previousRequestQuotationStatus === RequestStatus.Pending &&
      requestQuotationStatus === RequestStatus.Completed &&
      draftQuoteFiles.length > 0 &&
      quoteIdAfterRequestQuotation
    ) {
      dispatch(
        shoppingCartSagas.uploadQuoteAttachments.createAction({
          id: quoteIdAfterRequestQuotation,
          files: draftQuoteFiles
        })
      );
    }
  }, [
    dispatch,
    draftQuoteFiles,
    previousRequestQuotationStatus,
    quoteIdAfterRequestQuotation,
    requestQuotationStatus
  ]);

  return (
    <CustomDialog
      isOpen={isOpen}
      title={isBuildQuote ? t("Build quote in MAT") : t("Request for quote")}
      onClose={() => setIsOpen(false)}
      closeOnLostFocus={false}
    >
      {isQuoteFilesLoading || companyPgCodesStatus === RequestStatus.Pending ? (
        <LoadingIndicatorContainer>
          <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
        </LoadingIndicatorContainer>
      ) : null}

      {shoppingCart ? (
        <Container>
          <Formik
            initialValues={{
              ...values(shoppingCart, defaultPgCode, selectedCompany, defaultValidityMonths)
            }}
            onSubmit={(values) => requestQuotation(values)}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
          >
            {(formik) => {
              return (
                <>
                  <SectionsContainer>
                    <TextWrapper>
                      <LabelWithText
                        text={selectedCompany.companyDisplayName}
                        label={t("Company")}
                        fontSize="medium"
                      />
                      <LabelWithText
                        text={shoppingCart.projectName}
                        label={t("Cart")}
                        fontSize="medium"
                      />
                      <LabelWithText
                        text={shoppingCart.totalQuantity.toString()}
                        label={t("Products")}
                        fontSize="medium"
                      />
                    </TextWrapper>
                    <InputWrapper>
                      <SectionWrapper>
                        <FlexChild width={305}>
                          <InputLabel label={t("Project name")} isRequired />
                          <Input
                            dataType="text"
                            label=""
                            inputAttributes={{
                              name: "projectName",
                              onChange: formik.handleChange
                            }}
                            value={formik.values.projectName}
                            validationResult={{
                              valid:
                                !!formik.errors.projectName && !!formik.touched.projectName
                                  ? false
                                  : true,
                              text: formik.errors.projectName
                            }}
                            instantValidation={true}
                            showValidationBarWhenInvalid={true}
                            onLostFocus={formik.handleBlur}
                          />
                        </FlexChild>
                        {isBuildQuote ? (
                          <FlexChild width={305}>
                            <InputLabel label={t("Quote receiver email")} isRequired />
                            <Input
                              dataType="text"
                              label=""
                              inputAttributes={{
                                name: "quoteReceiverEmail",
                                onChange: formik.handleChange
                              }}
                              value={formik.values.quoteReceiverEmail}
                              validationResult={{
                                valid:
                                  !!formik.errors.quoteReceiverEmail &&
                                  !!formik.touched.quoteReceiverEmail
                                    ? false
                                    : true,
                                text: formik.errors.quoteReceiverEmail
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </FlexChild>
                        ) : null}
                        {isInternal || isMaintainedCustomer ? (
                          <Checkbox
                            style={{ marginTop: "auto" }}
                            monochrome
                            label={t("Frame agreement")}
                            value={!!formik.values.frameAgreement}
                            onChange={() =>
                              formik.setFieldValue("frameAgreement", !formik.values.frameAgreement)
                            }
                          />
                        ) : null}
                      </SectionWrapper>
                      <SectionWrapper>
                        {isInternal ? (
                          <FlexChild width={305}>
                            <InputLabel label={t("SFDC opportunity reference")} />
                            <Input
                              dataType="text"
                              label=""
                              inputAttributes={{
                                name: "sfdcReference",
                                onChange: formik.handleChange
                              }}
                              value={formik.values.sfdcReference}
                              validationResult={{
                                valid:
                                  !!formik.errors.sfdcReference && !!formik.touched.sfdcReference
                                    ? false
                                    : true,
                                text: formik.errors.sfdcReference
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </FlexChild>
                        ) : null}
                        <FlexChild width={180}>
                          <InputLabel
                            label={
                              formik.values.frameAgreement
                                ? t("Expected award date")
                                : t("Expected order date")
                            }
                            isRequired
                          />
                          <DatePickerInput
                            label=""
                            value={formik.values.expectedOrderDate}
                            type="normal"
                            validationResult={{
                              valid:
                                !!formik.errors.expectedOrderDate &&
                                !!formik.touched.expectedOrderDate
                                  ? false
                                  : true,
                              text: formik.errors.expectedOrderDate
                            }}
                            showValidationIconWhenInvalid
                            onValueChange={(v: string) => {
                              handleFormikValueChange(formik, "expectedOrderDate", v, true);
                            }}
                          />
                        </FlexChild>
                        {isInternal ? (
                          <FlexChild width={230}>
                            <InputLabel label={t("Sales channel")} isRequired />
                            <Dropdown
                              label=""
                              value={[
                                {
                                  label: formik.values.salesChannelDescription ?? "",
                                  value: formik.values.salesChannelId
                                }
                              ]}
                              monochrome
                              onChange={(v) => {
                                formik.setValues({
                                  ...formik.values,
                                  salesChannelId: v[0].value,
                                  salesChannelDescription: v[0].label
                                });
                              }}
                              validationState={{
                                valid:
                                  !!formik.errors.salesChannelId && !!formik.touched.salesChannelId
                                    ? false
                                    : true,
                                message: formik.errors.salesChannelId
                              }}
                              showValidationBarWhenInvalid={true}
                            >
                              {salesChannels?.map((c) => (
                                <DropdownOption
                                  key={c.id}
                                  value={c.id}
                                  label={c.description ?? ""}
                                />
                              ))}
                            </Dropdown>
                          </FlexChild>
                        ) : null}
                        {!isInternal ? null : (
                          <FlexChild width={100}>
                            <InputLabel label={t("Requesting PG-code")} isRequired />
                            {isInternal && pgCodes && pgCodes.length > 0 ? (
                              <>
                                <Dropdown
                                  monochrome
                                  value={[
                                    {
                                      label: formik.values.pgCode ?? "",
                                      value: formik.values.pgCode
                                    }
                                  ]}
                                  onChange={(v) =>
                                    handleFormikValueChange(formik, "pgCode", v[0].value, true)
                                  }
                                  onClose={() => formik.setFieldTouched("pgCode", true, true)}
                                  validationState={{
                                    valid:
                                      !!formik.errors.pgCode && !!formik.touched.pgCode
                                        ? false
                                        : true,
                                    message: formik.errors.pgCode
                                  }}
                                  showValidationBarWhenInvalid
                                >
                                  {pgCodes.map((x) =>
                                    defaultPgCode != null && defaultPgCode == x ? (
                                      <DropdownOption
                                        key={x}
                                        value={x}
                                        label={x}
                                        itemTemplate={getDefaultItemTemplate(x)}
                                      />
                                    ) : (
                                      <DropdownOption key={x} value={x} label={x} />
                                    )
                                  )}
                                </Dropdown>
                              </>
                            ) : (
                              <Input
                                value={formik.values.pgCode}
                                dataType={"text"}
                                validationResult={{
                                  valid:
                                    !!formik.errors.pgCode && !!formik.touched.pgCode
                                      ? false
                                      : true,
                                  text: formik.errors.pgCode
                                }}
                                showValidationBarWhenInvalid
                                showValidationIconWhenInvalid
                                inputAttributes={{
                                  name: "pgCode",
                                  onChange: formik.handleChange
                                }}
                                onLostFocus={formik.handleBlur}
                              />
                            )}
                          </FlexChild>
                        )}
                        {formik.values.frameAgreement === true ? (
                          <FlexChild width={180}>
                            <InputLabel label={t("Requested FA end-date")} isRequired />
                            <DatePickerInput
                              label=""
                              value={formik.values.requestedEndDate}
                              type="normal"
                              validationResult={{
                                valid:
                                  !!formik.errors.requestedEndDate &&
                                  !!formik.touched.requestedEndDate
                                    ? false
                                    : true,
                                text: formik.errors.requestedEndDate
                              }}
                              showValidationIconWhenInvalid
                              onValueChange={(v: string) => {
                                handleFormikValueChange(formik, "requestedEndDate", v, true);
                              }}
                            />
                          </FlexChild>
                        ) : undefined}
                        {(isInternal || isMaintainedCustomer) && !isInternalBuyer ? (
                          <FlexChild>
                            <InputLabel label={t("Win %")} isRequired />
                            <NumericInput
                              value={formik.values.winningPercentage}
                              decimals={2}
                              type="compact"
                              onChange={(v) => formik.setFieldValue("winningPercentage", v)}
                              onLostFocus={() => formik.setFieldTouched("winningPercentage", true)}
                              step={0.1}
                              max={constMaxWinPercentage}
                              min={0}
                              validationBar={
                                formik.touched.winningPercentage && formik.errors.winningPercentage
                                  ? "error"
                                  : "none"
                              }
                            />
                          </FlexChild>
                        ) : null}
                      </SectionWrapper>
                      {isInternalBuyer ? (
                        <SectionWrapper>
                          <FlexChild>
                            <InputLabel label={t("Win %")} isRequired />
                            <NumericInput
                              value={formik.values.winningPercentage}
                              decimals={2}
                              type="compact"
                              onChange={(v) => formik.setFieldValue("winningPercentage", v)}
                              onLostFocus={() => formik.setFieldTouched("winningPercentage", true)}
                              step={0.1}
                              max={constMaxWinPercentage}
                              min={0}
                              validationBar={
                                formik.touched.winningPercentage && formik.errors.winningPercentage
                                  ? "error"
                                  : "none"
                              }
                            />
                          </FlexChild>
                          <FlexChild>
                            <InputLabel label={t("Total sales price")} />
                            <NumericInput
                              value={formik.values.totalSalesPrice}
                              decimals={2}
                              type="compact"
                              onChange={(v) => formik.setFieldValue("totalSalesPrice", v)}
                              onLostFocus={() => formik.setFieldTouched("totalSalesPrice", true)}
                              step={0.1}
                              min={0}
                              validationBar={
                                formik.touched.totalSalesPrice && formik.errors.totalSalesPrice
                                  ? "error"
                                  : "none"
                              }
                            />
                          </FlexChild>
                        </SectionWrapper>
                      ) : null}
                      {isInternal ? (
                        <>
                          <div>
                            <InputLabel label={t("Account customer company name")} isRequired />
                            <Input
                              dataType="text"
                              label=""
                              inputAttributes={{
                                name: "accountCustomerCompanyName",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.accountCustomerCompanyName}
                              validationResult={{
                                valid:
                                  formik.errors.accountCustomerCompanyName &&
                                  formik.touched.accountCustomerCompanyName
                                    ? false
                                    : true,
                                text: formik.errors.accountCustomerCompanyName
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("Account customer street address")} isRequired />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "accountCustomerStreetAddress",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.accountCustomerStreetAddress}
                              validationResult={{
                                valid:
                                  formik.errors.accountCustomerStreetAddress &&
                                  formik.touched.accountCustomerStreetAddress
                                    ? false
                                    : true,
                                text: formik.errors.accountCustomerStreetAddress
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("Account customer city")} isRequired />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "accountCustomerCompanyCity",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.accountCustomerCompanyCity}
                              validationResult={{
                                valid:
                                  formik.errors.accountCustomerCompanyCity &&
                                  formik.touched.accountCustomerCompanyCity
                                    ? false
                                    : true,
                                text: formik.errors.accountCustomerCompanyCity
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("Account customer country")} isRequired />
                            <DropdownContainer>
                              <Dropdown
                                value={[
                                  {
                                    value: formik.values.accountCustomerCompanyCountryCode,
                                    label: formik.values.accountCustomerCompanyCountry ?? ""
                                  }
                                ]}
                                onChange={(v) => {
                                  formik.setValues({
                                    ...formik.values,
                                    accountCustomerCompanyCountryCode: v[0].value,
                                    accountCustomerCompanyCountry: v[0].label
                                  });
                                }}
                                searchable
                                monochrome
                                validationState={{
                                  valid:
                                    formik.errors.accountCustomerCompanyCountryCode &&
                                    formik.touched.accountCustomerCompanyCountryCode
                                      ? false
                                      : true,
                                  message: formik.errors.accountCustomerCompanyCountryCode
                                }}
                                showValidationIconWhenInvalid={false}
                                showValidationBarWhenInvalid={true}
                              >
                                {countries?.map((c) => (
                                  <DropdownOption key={c.code} value={c.code} label={c.name} />
                                ))}
                              </Dropdown>
                            </DropdownContainer>
                          </div>
                          <div>
                            <InputLabel label={t("Account customer GUID")} />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "accountCustomerIcvCode",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.accountCustomerIcvCode}
                              validationResult={{
                                valid:
                                  formik.errors.accountCustomerIcvCode &&
                                  formik.touched.accountCustomerIcvCode
                                    ? false
                                    : true,
                                text: formik.errors.accountCustomerIcvCode
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                        </>
                      ) : null}
                      <>
                        <DetailsRadioGroup
                          t={t}
                          isRequired
                          title={
                            isInternal
                              ? t("Is account customer the end customer?")
                              : t("Are you the end customer?")
                          }
                          value={formik.values.isEndCustomer?.toString()}
                          setValue={(v) =>
                            handleFormikValueChange(formik, "isEndCustomer", v === "true")
                          }
                        />
                        <ErrorMessage name={"isEndCustomer"}>
                          {(err) => <Error msg={err} />}
                        </ErrorMessage>
                      </>
                      {formik.values.isEndCustomer === false ? (
                        <>
                          <div>
                            <InputLabel label={t("End customer company name")} isRequired={true} />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "endCustomerCompanyName",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.endCustomerCompanyName}
                              validationResult={{
                                valid:
                                  formik.errors.endCustomerCompanyName &&
                                  formik.touched.endCustomerCompanyName
                                    ? false
                                    : true,
                                text: formik.errors.endCustomerCompanyName
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("End customer street address")} isRequired />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "endCustomerStreetAddress",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.endCustomerStreetAddress}
                              validationResult={{
                                valid:
                                  formik.errors.endCustomerStreetAddress &&
                                  formik.touched.endCustomerStreetAddress
                                    ? false
                                    : true,
                                text: formik.errors.endCustomerStreetAddress
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("End customer city")} isRequired={true} />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "endCustomerCompanyCity",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.endCustomerCompanyCity}
                              validationResult={{
                                valid:
                                  formik.errors.endCustomerCompanyCity &&
                                  formik.touched.endCustomerCompanyCity
                                    ? false
                                    : true,
                                text: formik.errors.endCustomerCompanyCity
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                          <div>
                            <InputLabel label={t("End customer country")} isRequired={true} />
                            <DropdownContainer>
                              <Dropdown
                                value={[
                                  {
                                    value: formik.values.endCustomerCompanyCountryCode,
                                    label: formik.values.endCustomerCompanyCountry ?? ""
                                  }
                                ]}
                                onChange={(v) => {
                                  formik.setValues({
                                    ...formik.values,
                                    endCustomerCompanyCountryCode: v[0].value,
                                    endCustomerCompanyCountry: v[0].label
                                  });
                                }}
                                searchable
                                monochrome
                                validationState={{
                                  valid:
                                    formik.errors.endCustomerCompanyCountryCode &&
                                    formik.touched.endCustomerCompanyCountryCode
                                      ? false
                                      : true,
                                  message: formik.errors.endCustomerCompanyCountryCode
                                }}
                                showValidationIconWhenInvalid={false}
                                showValidationBarWhenInvalid={true}
                              >
                                {countries?.map((c) => (
                                  <DropdownOption key={c.code} value={c.code} label={c.name} />
                                ))}
                              </Dropdown>
                            </DropdownContainer>
                          </div>
                          <div>
                            <InputLabel label={t("End customer GUID")} />
                            <Input
                              dataType="text"
                              inputAttributes={{
                                name: "endCustomerCompanyGuid",
                                onChange: formik.handleChange,
                                rows: 8
                              }}
                              value={formik.values.endCustomerCompanyGuid}
                              validationResult={{
                                valid:
                                  formik.errors.endCustomerCompanyGuid &&
                                  formik.touched.endCustomerCompanyGuid
                                    ? false
                                    : true,
                                text: formik.errors.endCustomerCompanyGuid
                              }}
                              instantValidation={true}
                              showValidationBarWhenInvalid={true}
                              onLostFocus={formik.handleBlur}
                            />
                          </div>
                        </>
                      ) : null}
                      {isInternal ? (
                        <div>
                          <InputLabel
                            label={t("Default quote request receiver(s) - at least one is needed")}
                            isRequired
                          />
                          <DropdownContainer>
                            <StyledMultiDropdown
                              multiselect
                              value={(defaultQuoteReceivers ?? [])
                                .filter(
                                  (r) =>
                                    r.email &&
                                    !formik.values.removedDefaultQuoteReceivers?.includes(r.email)
                                )
                                .map((r) => ({
                                  value: r.email as string,
                                  label: r.email as string
                                }))}
                              onChange={(v) => {
                                formik.setValues({
                                  ...formik.values,
                                  removedDefaultQuoteReceivers: defaultQuoteReceivers
                                    ?.map((r) => r.email as string)
                                    .filter((r) => !v.map((r) => r.value).includes(r))
                                });
                              }}
                              searchable
                              monochrome
                              showValidationIconWhenInvalid={false}
                              showValidationBarWhenInvalid={true}
                              clearable
                              notFoundText={t("No quote receivers")}
                              validationState={{
                                valid:
                                  !!formik.errors.removedDefaultQuoteReceivers &&
                                  !!formik.touched.removedDefaultQuoteReceivers
                                    ? false
                                    : true,
                                message: formik.errors.removedDefaultQuoteReceivers
                              }}
                            >
                              {defaultQuoteReceivers?.map((c) => (
                                <DropdownOption
                                  key={c.email}
                                  value={c.email}
                                  label={c.email ?? ""}
                                />
                              ))}
                            </StyledMultiDropdown>
                          </DropdownContainer>
                        </div>
                      ) : (
                        <div>
                          <InputLabel label={t("Quote request receiver(s)")} />
                          <div>
                            {defaultQuoteReceivers
                              ?.filter((r) => r.email !== undefined)
                              .map((r) => r.email)
                              .join(", ")}
                          </div>
                        </div>
                      )}
                      {isInternal && (
                        <div>
                          <InputLabel label={t("Select additional quote request receiver(s)")} />
                          <DropdownContainer>
                            <StyledMultiDropdown
                              multiselect
                              value={(formik.values.additionalQuoteReceivers ?? []).map((r) => ({
                                value: r,
                                label: r
                              }))}
                              onChange={(v) => {
                                formik.setValues({
                                  ...formik.values,
                                  additionalQuoteReceivers: v.map((r) => r.value)
                                });
                              }}
                              searchable
                              monochrome
                              showValidationIconWhenInvalid={false}
                              showValidationBarWhenInvalid={true}
                              clearable
                              notFoundText={t("No additional quote receivers")}
                            >
                              {additionalQuoteHandlers?.map((c) => (
                                <DropdownOption
                                  key={c.email}
                                  value={c.email}
                                  label={c.email ?? ""}
                                />
                              ))}
                            </StyledMultiDropdown>
                          </DropdownContainer>
                        </div>
                      )}
                      <ResizableInput
                        dataType="textarea"
                        label={t("Message")}
                        inputAttributes={{
                          name: "quotationRequestMessage",
                          onChange: formik.handleChange,
                          rows: 8
                        }}
                        value={formik.values.quotationRequestMessage}
                        validationResult={{
                          valid:
                            formik.errors.quotationRequestMessage &&
                            formik.touched.quotationRequestMessage
                              ? false
                              : true,
                          text: formik.errors.quotationRequestMessage
                        }}
                        instantValidation={true}
                        showValidationBarWhenInvalid={true}
                        onLostFocus={formik.handleBlur}
                        resizeParams={{ maxHeight: "15lh", maxWidth: theme.breakpoints.xxl }}
                      />
                      {isInternal ? (
                        <div>
                          <QuoteAttachmentsUploader
                            isUploadingEnabled={true}
                            isRemovingEnabled={true}
                            isRequestQuotationDialogMode={true}
                            isPrinting={false}
                            reviseMode={false}
                          />
                        </div>
                      ) : null}
                    </InputWrapper>
                  </SectionsContainer>
                  <ButtonContainer>
                    <Button
                      buttonType={"secondary"}
                      text={t("Save quote as draft")}
                      onClick={() => {
                        formik.values.isDraft = true;
                        formik.handleSubmit();
                      }}
                      isLoading={isLoading}
                    />
                    <Button
                      buttonType={"secondary"}
                      text={t("Cancel")}
                      onClick={() => setIsOpen(false)}
                    />
                    <Button
                      buttonType={"primary"}
                      text={t("Send")}
                      onClick={() => {
                        formik.values.isDraft = false;
                        formik.handleSubmit();
                      }}
                      isLoading={isLoading}
                    />
                  </ButtonContainer>
                </>
              );
            }}
          </Formik>
        </Container>
      ) : null}
    </CustomDialog>
  );
};
