import {
  PriceProductVm,
  ApiPricesPostRequest,
  ApiPricesPriceListPostRequest,
  CurrentUserPriceListDto
} from "api";
import { dashboardSagas } from "applications/dashboard/sagas/dashboardSagas";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";
import { quickPriceCheckSagas } from "../sagas/quickPriceCheckSagas";
import { RequestStatus } from "../../../framework/state/requestStatus";
import {
  QuickpriceCheckActions,
  QuickPriceCheckActionTypes
} from "../actions/quickPriceCheckActions";
import { createSelector } from "reselect";

interface Requests {
  getPricesFromOrderingCodes: RequestState<PriceProductVm, ApiPricesPostRequest>;
  getPriceProducts: RequestState<PriceProductVm, ApiPricesPriceListPostRequest>;
  getUserPriceLists: RequestState<CurrentUserPriceListDto[], undefined>;
}

export interface QuickPriceCheckViewState {
  priceProducts: PriceProductVm | undefined;
  userPriceLists: CurrentUserPriceListDto[] | undefined;
  requests: Requests;
}

const defaultState: QuickPriceCheckViewState = {
  priceProducts: undefined,
  userPriceLists: undefined,
  requests: {
    getPricesFromOrderingCodes: createInitialRequest(),
    getPriceProducts: createInitialRequest(),
    getUserPriceLists: createInitialRequest()
  }
};

export function quickPriceCheckViewReducer(
  state: QuickPriceCheckViewState = defaultState,
  action: QuickpriceCheckActions
): QuickPriceCheckViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: dashboardSagas.getPricesFromOrderingCodes.actionTypes,
      key: "getPricesFromOrderingCodes"
    },
    {
      actionTypes: quickPriceCheckSagas.getPricesFromOrderingCodes.actionTypes,
      key: "getPriceProducts"
    },
    {
      actionTypes: quickPriceCheckSagas.getUserPriceLists.actionTypes,
      key: "getUserPriceLists"
    }
  ]);

  if (dashboardSagas.getPricesFromOrderingCodes.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.priceProducts = action.payload;
    });
  } else if (dashboardSagas.getPricesFromOrderingCodes.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.priceProducts = undefined;
    });
  } else if (quickPriceCheckSagas.getPricesFromOrderingCodes.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.priceProducts = action.payload;
    });
  } else if (quickPriceCheckSagas.getUserPriceLists.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.userPriceLists = action.payload;
    });
  } else if (quickPriceCheckSagas.getPricesFromOrderingCodes.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.priceProducts = undefined;
    });
  }

  switch (action.type) {
    case QuickPriceCheckActionTypes.SetPriceProducts:
      state = produce(state, (draft) => {
        if (draft.priceProducts) {
          draft.priceProducts.products = draft.priceProducts.products.filter(
            (x) => !action.priceProducts.some((product) => product.orderingCode === x.orderingCode)
          );
        }
      });
      break;
  }

  return state;
}

export const getPriceProductsVm = (state: AppState): PriceProductVm | undefined =>
  state.quickPriceCheck.priceProducts;

export const getPriceProductsStatus = (state: AppState): RequestStatus =>
  state.quickPriceCheck.requests.getPriceProducts.status;

export const getUserPriceLists = (state: AppState): CurrentUserPriceListDto[] | undefined =>
  state.quickPriceCheck.userPriceLists;

export const getUserPriceListForDropdown = createSelector(
  getUserPriceLists,
  (priceLists) =>
    priceLists?.map((x) => {
      return {
        value: x.id,
        label: x.name
      };
    })
);
