import {
  CompanyDto,
  CountryDto,
  ShoppingCartAccountCustomerDto,
  ShoppingCartBillingAddressDto,
  ShoppingCartConsigneeDto,
  ShoppingCartDeliveryAddressDto,
  ShoppingCartEndCustomerDto
} from "api";
import { termsOfDeliveriesConstants } from "constants/termsOfDeliveryConstant";
import { TFunction } from "i18next";
import { priceStringToNumber } from "utilities/currencyUtils";
import { parseDateStringForYupValidation } from "utilities/formikUtils";
import * as Yup from "yup";
import { isInEu } from "./helpers";

export const getDaysFromNow = (days: number): Date => {
  const today = new Date();
  return new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
};

export const isPriorityCharge = (
  requestedDeliveryDate: string | undefined,
  isExternal: boolean
): boolean => {
  const minDateForPriority = isExternal ? 14 : 7;

  if (
    requestedDeliveryDate &&
    parseDateStringForYupValidation(requestedDeliveryDate) < getDaysFromNow(minDateForPriority)
  ) {
    return true;
  }

  return false;
};

const errorMessages = {
  maxChar: (max = 256): string => `Maximum of ${max} characters`,
  required: "Required"
};

export type ShoppingCartEndCustomerForm = Omit<ShoppingCartEndCustomerDto, "id">;

export type ShoppingCartDeliveryAddressForm = Omit<ShoppingCartDeliveryAddressDto, "id">;

export type ShoppingCartAccountCustomerForm = Omit<ShoppingCartAccountCustomerDto, "id">;

export type ShoppingCartBillingAddressForm = Omit<ShoppingCartBillingAddressDto, "id">;

export type ShoppingCarConsigneeForm = Omit<ShoppingCartConsigneeDto, "id">;

export type Addresses = ShoppingCartAccountCustomerForm &
  (ShoppingCartEndCustomerForm &
    ShoppingCartDeliveryAddressForm &
    ShoppingCartBillingAddressForm &
    ShoppingCarConsigneeForm);

const BaseInformationForm: Yup.ObjectSchema = Yup.object().shape({
  name: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
  reference: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  streetAddress: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
  postalCode: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  city: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
  state: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  country: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
  contactPerson: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
  contactPersonTelephone: Yup.string()
    .max(256, errorMessages.maxChar(256))
    .required(errorMessages.required),
  contactPersonEmail: Yup.string()
    .max(256, errorMessages.maxChar(256))
    .email("Must be a valid email format")
    .required("Must be a valid email format")
});

const EndCustomerForm: Yup.ObjectSchema<ShoppingCartEndCustomerForm | undefined> =
  Yup.object().shape({
    name: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    reference: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    streetAddress: Yup.string()
      .max(256, errorMessages.maxChar(256))
      .required(errorMessages.required),
    postalCode: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    city: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    state: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    country: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    contactPerson: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    contactPersonEmail: Yup.string()
      .max(256, errorMessages.maxChar(256))
      .email("Must be a valid email format")
      .optional(),
    contactPersonTelephone: Yup.string().max(256, errorMessages.maxChar(256)).optional()
  });

const BillingAddressForm: Yup.ObjectSchema<ShoppingCartBillingAddressForm | undefined> =
  Yup.object().shape({
    name: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    reference: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    streetAddress: Yup.string().max(256, errorMessages.maxChar(256)),
    postalCode: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    city: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    state: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    country: Yup.string().max(256, errorMessages.maxChar(256)).required(errorMessages.required),
    contactPerson: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
    contactPersonEmail: Yup.string()
      .max(256, errorMessages.maxChar(256))
      .email("Must be a valid email format")
      .optional(),
    contactPersonTelephone: Yup.string().max(256, errorMessages.maxChar(256)).optional()
  });

const ConsigneeForm: Yup.ObjectSchema<ShoppingCarConsigneeForm | undefined> = Yup.object().shape({
  name: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  reference: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  streetAddress: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  postalCode: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  city: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  state: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  country: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  contactPerson: Yup.string().max(256, errorMessages.maxChar(256)).optional(),
  contactPersonEmail: Yup.string()
    .max(256, errorMessages.maxChar(256))
    .email("Must be a valid email format")
    .optional(),
  contactPersonTelephone: Yup.string().max(256, errorMessages.maxChar(256)).optional()
});

const getDateSchema = (t: TFunction) =>
  Yup.date()
    .transform(parseDateStringForYupValidation)
    .required(errorMessages.required)
    .min(getDaysFromNow(6), t("Date must be at minimum 1 week from now"))
    .typeError(t("Must be a valid date"));

export const getOrderValidationSchema = (
  t: TFunction,
  company: CompanyDto | undefined,
  countries: CountryDto[] | undefined
): Yup.ObjectSchema => {
  return Yup.object().shape({
    isEndCustomer: company?.isInternal
      ? Yup.bool().required(errorMessages.required).typeError(errorMessages.required)
      : Yup.bool().nullable().notRequired(),
    endCustomer: EndCustomerForm.notRequired()
      .default(undefined)
      .when("isEndCustomer", {
        is: (isEndCustomer: boolean | null | undefined) => isEndCustomer === false,
        then: EndCustomerForm.required(),
        else: EndCustomerForm.notRequired().default(undefined)
      }),
    riskReviews: Yup.array().when("hasRiskReviewBeenDone", {
      is: (hasRiskReviewBeenDone: boolean | null | undefined) => hasRiskReviewBeenDone === true,
      then: Yup.array()
        .of(Yup.object().required())
        .required(t("At least 1 risk review file is required"))
        .test({
          message: t("At least 1 risk review file is required"),
          test: (arr: any) => arr?.length > 0
        }),
      else: Yup.array().notRequired()
    }),
    commercialInvoices: Yup.array().when("hasCommercialInvoice", {
      is: (hasCommercialInvoice: boolean | null) => hasCommercialInvoice === true,
      then: Yup.array()
        .of(Yup.object().required())
        .required(t("At least 1 commercial invoice is required"))
        .test({
          message: t("At least 1 commercial invoice is required"),
          test: (arr: any) => arr?.length > 0
        }),
      else: Yup.array().notRequired()
    }),
    isPriorityConfirmed: Yup.bool()
      .optional()
      .when("requestedDeliveryDate", {
        is: (requestedDeliveryDate: string | undefined) =>
          isPriorityCharge(requestedDeliveryDate, !company?.isInternal),
        then: Yup.bool()
          .required(t("Date must be confirmed"))
          .oneOf([true], t("Date must be confirmed")),
        else: Yup.bool().optional()
      }),
    projectName: Yup.string().notRequired(),
    customerReference: Yup.string()
      .required(errorMessages.required)
      .typeError(errorMessages.required),
    vatNumber: Yup.string().when("deliveryAddress.countryCode", {
      is: (countryCode: string | undefined) => isInEu(countries, countryCode),
      then: Yup.string().required(t("VAT number is required when delivery to EU")),
      else: Yup.string().nullable().notRequired()
    }),
    salesChannel: !company?.isInternal
      ? Yup.object().notRequired()
      : Yup.object()
          .shape({
            id: Yup.string().required(errorMessages.required),
            description: Yup.string().required(errorMessages.required)
          })
          .required(errorMessages.required),
    hasRiskReviewBeenDone: !company?.isInternal
      ? Yup.bool().notRequired().nullable()
      : Yup.bool().required(errorMessages.required).typeError(errorMessages.required),
    deliveryAddress: BaseInformationForm.required(),
    billingAddress: BillingAddressForm.required(),
    consignee: ConsigneeForm.notRequired().default(undefined),
    accountCustomer: !company?.isInternal
      ? EndCustomerForm.notRequired().default(undefined)
      : BaseInformationForm.required(),
    isCustomDeliveryDate: Yup.bool()
      .required(errorMessages.required)
      .typeError(errorMessages.required),
    requestedDeliveryDate: Yup.date()
      .notRequired()
      .nullable()
      .when("isCustomDeliveryDate", {
        is: (isCustomDeliveryDate: boolean) => isCustomDeliveryDate === true,
        then: getDateSchema(t),
        else: Yup.date().notRequired().nullable()
      }),
    acceptPartialDelivery: Yup.bool().required(errorMessages.required),
    acceptEarlierDelivery: Yup.bool()
      .notRequired()
      .nullable()
      .when("isCustomDeliveryDate", {
        is: (isCustomDeliveryDate: boolean) => isCustomDeliveryDate === true,
        then: Yup.bool().required(errorMessages.required),
        else: Yup.bool().notRequired().nullable()
      }),
    forwarderInfo: Yup.string()
      .notRequired()
      .when(["termsOfDeliveryCode", "requestedTermsOfDeliveryCode"], {
        is: (tod, rtod) =>
          tod === termsOfDeliveriesConstants.FCA || rtod === termsOfDeliveriesConstants.FCA,
        then: Yup.string().required(t("Forwarder info is required for FCA")),
        else: Yup.string().notRequired()
      }),
    additionalInfo: Yup.string()
      .notRequired()
      .when(["termsOfDeliveryCode", "requestedTermsOfDeliveryCode"], {
        is: (termsOfDeliveryCode, requestedTermsOfDeliveryCode) =>
          termsOfDeliveryCode === "Other" || requestedTermsOfDeliveryCode === "Other",
        then: Yup.string().required(
          t('Additional information is required for delivery terms of "Other"')
        ),
        otherwise: Yup.string().optional()
      }),
    shippingMarks: Yup.string().notRequired(),
    specialInstructions: Yup.string().notRequired(),
    hasCommercialInvoice: Yup.bool()
      .notRequired()
      .nullable()
      .when(["deliveryAddress.countryCode", "useProFormaOrCommercialInvoice"], {
        is: (countryCode: string | undefined, use: boolean) =>
          use && !isInEu(countries, countryCode),
        then: Yup.bool().required(
          t("Delivery address is not in EU, provide commercial invoice or pro forma")
        ),
        else: Yup.bool().nullable().notRequired()
      }),
    proFormaItems: Yup.array()
      .when(
        ["hasCommercialInvoice", "deliveryAddress.countryCode", "useProFormaOrCommercialInvoice"],
        {
          is: (
            hasCommercialInvoice: boolean | null | undefined,
            countryCode: string | undefined,
            use: boolean
          ) =>
            hasCommercialInvoice === false && use && countryCode && !isInEu(countries, countryCode),
          then: Yup.array().of(
            Yup.object()
              .shape({
                orderingCode: Yup.string().required(),
                displayName: Yup.string().required(),
                unitPrice: Yup.string().required(),
                price: Yup.string().required(),
                quantity: Yup.number().required(),
                proFormaPrice: Yup.number()
                  .required()
                  .when(["unitQuotedPrice", "unitPrice"], (quotedPrice, unitPrice, schema) => {
                    const minPrice = quotedPrice || unitPrice;
                    return schema.min(priceStringToNumber(minPrice));
                  })
              })
              .required()
          ),
          else: Yup.array().notRequired()
        }
      )
      .required(),
    requestedOrderApproverEmail: Yup.string().email().notRequired().nullable(),
    requestedTermsOfDeliveryCode: Yup.string()
      .notRequired()
      .nullable()
      .when("useDefaultTermsOfDelivery", {
        is: (useDefaultTermsOfDelivery: boolean) => useDefaultTermsOfDelivery === false,
        then: Yup.string().nullable(false).required(errorMessages.required),
        else: Yup.string().nullable().notRequired()
      }),
    pgCode: company?.isInternal
      ? Yup.string()
          .max(4, t(t("Max 4 characters")))
          .matches(/^\d+$/, t("Only numbers are allowed"))
          .required(t("PG code is required"))
      : Yup.string().nullable()
  });
};

export const getCreateQuoteValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    quoteReceiverEmail: Yup.string()
      .email(t("Invalid email format"))
      .required(t("Email is required")),
    projectName: Yup.string().required(t("Project name is required")),
    sfdcReference: Yup.string().optional().max(256, errorMessages.maxChar()),
    expectedOrderDate: getDateSchema(t),
    tenderValidityDate: getDateSchema(t),
    endCustomerCompanyStreetAddress: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isEndCustomer", {
        is: (isEndCustomer) => isEndCustomer === false,
        then: Yup.string().max(256, errorMessages.maxChar()).required()
      }),
    endCustomerCompanyCountryCode: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isEndCustomer", {
        is: (isEndCustomer) => isEndCustomer === false,
        then: Yup.string().max(256, errorMessages.maxChar()).required()
      }),
    endCustomerCompanyCountry: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isEndCustomer", {
        is: (isEndCustomer) => isEndCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyName: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isEndCustomer", {
        is: (isEndCustomer) => isEndCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerCompanyCity: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isEndCustomer", {
        is: (isEndCustomer) => isEndCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    endCustomerGuid: Yup.string().max(256, errorMessages.maxChar()).optional(),
    isEndCustomer: Yup.bool().required().typeError(errorMessages.required),
    accountCustomerCompanyCountryCode: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isAccountCustomer", {
        is: (isAccountCustomer) => isAccountCustomer === false,
        then: Yup.string().max(256, errorMessages.maxChar()).required()
      }),
    accountCustomerCompanyCountry: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isAccountCustomer", {
        is: (isAccountCustomer) => isAccountCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyName: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isAccountCustomer", {
        is: (isAccountCustomer) => isAccountCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyStreetAddress: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isAccountCustomer", {
        is: (isAccountCustomer) => isAccountCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerCompanyCity: Yup.string()
      .max(256, errorMessages.maxChar())
      .when("isAccountCustomer", {
        is: (isAccountCustomer) => isAccountCustomer === false,
        then: Yup.string().required().max(256, errorMessages.maxChar())
      }),
    accountCustomerIcvCode: Yup.string().max(256, errorMessages.maxChar()).optional(),
    isAccountCustomer: Yup.bool().required().typeError(errorMessages.required)
  });

export const getAddRequestersValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    requesters: Yup.array()
      .of(Yup.string().email(t("Invalid email format")).required(t("Email is required")))
      .min(1, t("At least one requester is required"))
  });
