/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureDto,
    FeatureDtoFromJSON,
    FeatureDtoFromJSONTyped,
    FeatureDtoToJSON,
} from './FeatureDto';

/**
 * 
 * @export
 * @interface SubCategoryDto
 */
export interface SubCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof SubCategoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryDto
     */
    name: string;
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof SubCategoryDto
     */
    features: Array<FeatureDto>;
}

export function SubCategoryDtoFromJSON(json: any): SubCategoryDto {
    return SubCategoryDtoFromJSONTyped(json, false);
}

export function SubCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'features': ((json['features'] as Array<any>).map(FeatureDtoFromJSON)),
    };
}

export function SubCategoryDtoToJSON(value?: SubCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'features': ((value.features as Array<any>).map(FeatureDtoToJSON)),
    };
}

