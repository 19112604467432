import { ApplicationNotificationOperations } from "api/models/ApplicationNotificationOperations";
import { Action } from "redux";

export enum ApplicationNotificationViewActionTypes {
  SetNotificationState = "ApplicationNotificationView/SetNotificationState"
}

export interface SetNotificationState extends Action {
  type: ApplicationNotificationViewActionTypes.SetNotificationState;
  unreadNotificationCount: number;
  operation?: ApplicationNotificationOperations;
  notificationIds?: number[];
}

export const applicationNotificationViewActions = {
  setNotificationState: (
    unreadNotificationCount: number,
    operation?: ApplicationNotificationOperations,
    notificationIds?: number[]
  ): SetNotificationState => ({
    type: ApplicationNotificationViewActionTypes.SetNotificationState,
    unreadNotificationCount,
    operation,
    notificationIds
  })
};

export type ApplicationNotificationViewActions = SetNotificationState;
