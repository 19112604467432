import { createGenericSaga, defaultSagaErrorHandler } from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const shoppingCartCompanySagas = {
  getCompanyPgCodes: createGenericSaga(
    "shoppingCartCompanySagas/getCompanyPgCodes",
    getApiRegistry().companiesApi.apiCompaniesIdPgCodesGet.bind(getApiRegistry().companiesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getCompanyTermsOfPayment: createGenericSaga(
    "shoppingCartCompanySagas/getCompanyTermsOfPayment",
    getApiRegistry().companiesApi.apiCompaniesIdTermsOfPaymentGet.bind(
      getApiRegistry().companiesApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getCompanyApprovers: createGenericSaga(
    "shoppingCartCompanySagas/getCompanyApprovers",
    getApiRegistry().companiesApi.apiCompaniesIdCompanyApproversGet.bind(
      getApiRegistry().companiesApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
