import styled from "styled-components";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import { CollapsibleContainer } from "@abb/abb-common-ux-react/components/Collapsible";
import { Grid, TableCell, TableContainer, TableRow } from "@mui/material";

export const CardActionsFlex = styled(CardActions)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.sizes.s};
  button {
    margin-left: unset !important;
  }
`;
export const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding 0 2rem;
`;
export const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  min-height: 25rem;
`;
export const ProductCard = styled(Card)`
  display: flex;
  flex-direction: column;
  min-height: 25rem;

  .MuiCardHeader-root {
    flex: 0 1 auto;
  }
  .MuiCardMedia-media {
    flex: 0 2 40%;
    object-fit: scale-down;
    padding: 0% 30% !important;
  }
  .MuiCardContent-root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    div {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .MuiCardActions-root {
    flex: 0 1 auto;
  }
`;
export const CollapsibleContainerCustom = styled(CollapsibleContainer)`
  background-color: ${(props) => props.theme.colors.grey20};
  .ABB_CommonUX_Collapsible__root > .ABB_CommonUX_Collapsible__header {
    height: unset;
    min-height: 32px;
  }
  .ABB_CommonUX_Collapsible__root
    > .ABB_CommonUX_Collapsible__header
    > .ABB_CommonUX_Collapsible__title {
    white-space: normal;
  }
`;
export const CardContainer = styled(Grid)``;
export const TextCardContainer = styled(Grid)`
  flex: 0 1 calc(25% + 76px);
`;
export const ProductCardsContainer = styled(Grid)`
  flex: 0 1 calc(75% - 76px);
  margin-left: unset;
`;
export const CardsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
`;
// table elements
export const TableContainerStickyHeader = styled(TableContainer)`
  overflow-x: visible;
  table thead {
    position: sticky;
    background-color: ${(props) => props.theme.colors.whitePrimary};
    z-index: ${(props) => props.theme.zIndices.productComparisonTableHead};
  }
  table thead {
    inset-block-start: 0;
  }
`;
export const SizedTableRow = styled(TableRow)<{ $withGap?: boolean }>`
  display: flex;
  ${(props) => (props.$withGap ? "gap : " + props.theme.sizes.m + ";" : "")}

  td,
  th {
    border: 0;
  }
`;

export const StyledTableRow = styled(SizedTableRow)<{ $dark?: boolean }>`
  td {
    :not(:last-child) {
      border-right: ${(props) => props.theme.borders.widths.borderWidthM} solid
        ${(props) => props.theme.colors.whitePrimary};
    }
    background-color: unset !important;
  }
  .smallCard {
    text-align: center;
  }
  ${(props) =>
    props.$dark
      ? "background-color:  " + props.theme.colors.grey20 + "; "
      : "\
  &:nth-of-type(odd) {\
    background-color: " +
        props.theme.colors.grey10 +
        ";\
  }\
  &:nth-of-type(even) {\
    background-color:  " +
        props.theme.colors.grey20 +
        ";\
  }"}
`;
export const StyledTableCell = styled(TableCell)`
  padding: ${(props) => props.theme.sizes.s};
`;
export const Legend = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  text-align: end;
`;
export const ValueNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${(props) => props.theme.sizes.s};
  span {
    margin: 0;
  }
`;
export const ValueContainer = styled.p`
  margin: 0;
`;
export const LoadingIndicatorContainer = styled.div`
  margin: auto;
  width: fit-content;
  padding: ${(props) => props.theme.sizes.s};
`;
export const ToggleSwitchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.sizes.s};
`;
export const DropDownItems = styled.div`
  word-break: break-all;
  overflow: hidden;
  white-space: pre-wrap;
  padding: ${(props) => props.theme.sizes.xxs};
`;
export const DropDownItemsSelected = styled(DropDownItems)`
  display: flex;
  align-items: center;
`;
