import { Dispatch } from "redux";
import { userActions } from "../applications/common/actions/userActions";
import { NotificationSeverity } from "api";
import { titleCaseToSentenceCase } from "./stringUtils";

export function joinErrors(errorDetails: { [id: string]: string[] }): string {
  let errorMessage = "";
  let index = 0;
  const length = Object.keys(errorDetails).length;
  for (const key in errorDetails) {
    errorMessage += `${titleCaseToSentenceCase(key)}: ${errorDetails[key]?.join(", ")}`;
    if (index + 1 != length) {
      errorMessage += "\n";
    }
    index++;
  }
  return errorMessage;
}

export const apiGridErrorHandler = async (err: any, dispatch: Dispatch): Promise<void> => {
  let error = undefined;
  let errorMessage = "";
  try {
    error = await err.json();
  } catch {
    error = undefined;
  }

  if (error?.title) {
    errorMessage = error.title;
  }
  if (error?.detail) {
    errorMessage += "\n" + error.detail;
  }
  if (error?.errors) {
    errorMessage += "\n" + joinErrors(error.errors);
  }

  dispatch(
    userActions.addNotification(
      NotificationSeverity.Error,
      `Loading or rows failed: \n${errorMessage || "Error occured"}`
    )
  );
};
