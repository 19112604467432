/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserHomeCompanyCommand
 */
export interface UpdateUserHomeCompanyCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserHomeCompanyCommand
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserHomeCompanyCommand
     */
    homeCompanyId?: string | null;
}

export function UpdateUserHomeCompanyCommandFromJSON(json: any): UpdateUserHomeCompanyCommand {
    return UpdateUserHomeCompanyCommandFromJSONTyped(json, false);
}

export function UpdateUserHomeCompanyCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserHomeCompanyCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'homeCompanyId': !exists(json, 'homeCompanyId') ? undefined : json['homeCompanyId'],
    };
}

export function UpdateUserHomeCompanyCommandToJSON(value?: UpdateUserHomeCompanyCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'homeCompanyId': value.homeCompanyId,
    };
}

