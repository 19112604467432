import { Icon, IconButton, Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { Collapse, Table, TableBody } from "@mui/material";
import { ProductVersionDetailDto, CategoriesMenuDto, ResultComparisonVm } from "api";
import { FeatureNameAndCode } from "applications/products/productExplorer/components/RefineByFunctionality";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import {
  StyledTableCell,
  StyledTableRow,
  ValueContainer,
  ValueNoteContainer
} from "./StyledComponents";

interface Props {
  cat: CategoriesMenuDto;
  products: ProductVersionDetailDto[];
  productsFeatures: ResultComparisonVm["productsFeatures"];
  maxElementInCompare: number;
  level: number;
  expand: boolean;
}

export const FeatureContainer = styled.span`
  .FeatureClass {
    font-size: ${(props) => props.theme.fonts.sizes.fontSizeXxs};
    font-weight: ${(props) => props.theme.fonts.weights.heavy};
  }
`;

export const GridRow = ({
  cat,
  products,
  productsFeatures,
  maxElementInCompare,
  level,
  expand
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setOpen(expand);
  }, [expand]);

  return (
    <React.Fragment>
      <StyledTableRow $dark>
        <StyledTableCell
          key={cat.id}
          className="largeCard"
          style={{
            paddingLeft: level * parseInt(theme.sizes.m) + "px"
          }}
          onClick={() => setOpen(!open)}
        >
          <IconButton aria-label={t("expand row")} sizeClass="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon sizeClass="small" name="abb/caret-up" />
            ) : (
              <Icon sizeClass="small" name="abb/caret-down" />
            )}
          </IconButton>
          {" " + cat.name}
        </StyledTableCell>
        {Array(maxElementInCompare)
          .fill({ name: "", id: 0 })
          .map((p, i) => (
            <StyledTableCell className="smallCard" key={i} onClick={() => setOpen(!open)} />
          ))}
      </StyledTableRow>
      <StyledTableRow sx={{ display: open ? "contents" : "none" }}>
        <StyledTableCell
          style={{ padding: 0, border: 0, width: "100%" }}
          colSpan={maxElementInCompare + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table sx={{ border: "0" }}>
              <TableBody>
                {cat.subCategories.length > 0
                  ? cat.subCategories.map((sub) => (
                      <GridRow
                        key={sub.id}
                        cat={{ ...sub, subCategories: [] }}
                        products={products}
                        productsFeatures={productsFeatures}
                        maxElementInCompare={maxElementInCompare}
                        level={level + 1}
                        expand={expand}
                      />
                    ))
                  : cat.features.map((feat) => {
                      return (
                        <StyledTableRow key={feat.id}>
                          <StyledTableCell
                            key={-1}
                            className="largeCard"
                            style={{
                              paddingLeft: (level + 1) * parseInt(theme.sizes.m) + 20 + "px"
                            }} // 20 for the arrow
                          >
                            <FeatureContainer>
                              <FeatureNameAndCode features={feat} />
                            </FeatureContainer>
                          </StyledTableCell>
                          {[
                            ...products,
                            ...Array(maxElementInCompare - products.length).fill({
                              name: "",
                              id: -1
                            })
                          ].map((product: ProductVersionDetailDto, i) => {
                            const productFeatureValue = productsFeatures[feat.id].find(
                              (id) => id.productVersionDetailId === product.id
                            );
                            return (
                              <StyledTableCell className="smallCard" key={i}>
                                {productFeatureValue ? (
                                  <ValueNoteContainer>
                                    <ValueContainer>
                                      {productFeatureValue.value == "X"
                                        ? t("Supported")
                                        : productFeatureValue.value == "O"
                                        ? t("Option")
                                        : productFeatureValue.value == "S"
                                        ? t("Alternative")
                                        : productFeatureValue.value}
                                    </ValueContainer>
                                    {productFeatureValue.note ? (
                                      <WithTooltip>
                                        <Icon
                                          name={"abb/information-circle-2"}
                                          style={{ color: "gray" }}
                                        />
                                        <Tooltip>
                                          <p>{productFeatureValue.note}</p>
                                        </Tooltip>
                                      </WithTooltip>
                                    ) : undefined}
                                  </ValueNoteContainer>
                                ) : undefined}
                              </StyledTableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};
