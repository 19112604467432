/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentUserDto,
    CurrentUserDtoFromJSON,
    CurrentUserDtoFromJSONTyped,
    CurrentUserDtoToJSON,
} from './CurrentUserDto';
import {
    TimeZoneDto,
    TimeZoneDtoFromJSON,
    TimeZoneDtoFromJSONTyped,
    TimeZoneDtoToJSON,
} from './TimeZoneDto';

/**
 * 
 * @export
 * @interface UpdateUserCommandVm
 */
export interface UpdateUserCommandVm {
    /**
     * 
     * @type {CurrentUserDto}
     * @memberof UpdateUserCommandVm
     */
    user?: CurrentUserDto;
    /**
     * 
     * @type {TimeZoneDto}
     * @memberof UpdateUserCommandVm
     */
    timeZone?: TimeZoneDto;
}

export function UpdateUserCommandVmFromJSON(json: any): UpdateUserCommandVm {
    return UpdateUserCommandVmFromJSONTyped(json, false);
}

export function UpdateUserCommandVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserCommandVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : CurrentUserDtoFromJSON(json['user']),
        'timeZone': !exists(json, 'timeZone') ? undefined : TimeZoneDtoFromJSON(json['timeZone']),
    };
}

export function UpdateUserCommandVmToJSON(value?: UpdateUserCommandVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': CurrentUserDtoToJSON(value.user),
        'timeZone': TimeZoneDtoToJSON(value.timeZone),
    };
}

