/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MatrixItemDto,
    MatrixItemDtoFromJSON,
    MatrixItemDtoFromJSONTyped,
    MatrixItemDtoToJSON,
} from './MatrixItemDto';
import {
    ProductApplicationDto,
    ProductApplicationDtoFromJSON,
    ProductApplicationDtoFromJSONTyped,
    ProductApplicationDtoToJSON,
} from './ProductApplicationDto';
import {
    ProductSeriesDto,
    ProductSeriesDtoFromJSON,
    ProductSeriesDtoFromJSONTyped,
    ProductSeriesDtoToJSON,
} from './ProductSeriesDto';

/**
 * 
 * @export
 * @interface ModularProductsVm
 */
export interface ModularProductsVm {
    /**
     * 
     * @type {Array<ProductSeriesDto>}
     * @memberof ModularProductsVm
     */
    productSeriesCollection: Array<ProductSeriesDto>;
    /**
     * 
     * @type {Array<ProductApplicationDto>}
     * @memberof ModularProductsVm
     */
    productApplications: Array<ProductApplicationDto>;
    /**
     * 
     * @type {Array<MatrixItemDto>}
     * @memberof ModularProductsVm
     */
    modularProducts: Array<MatrixItemDto>;
}

export function ModularProductsVmFromJSON(json: any): ModularProductsVm {
    return ModularProductsVmFromJSONTyped(json, false);
}

export function ModularProductsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModularProductsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productSeriesCollection': ((json['productSeriesCollection'] as Array<any>).map(ProductSeriesDtoFromJSON)),
        'productApplications': ((json['productApplications'] as Array<any>).map(ProductApplicationDtoFromJSON)),
        'modularProducts': ((json['modularProducts'] as Array<any>).map(MatrixItemDtoFromJSON)),
    };
}

export function ModularProductsVmToJSON(value?: ModularProductsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productSeriesCollection': ((value.productSeriesCollection as Array<any>).map(ProductSeriesDtoToJSON)),
        'productApplications': ((value.productApplications as Array<any>).map(ProductApplicationDtoToJSON)),
        'modularProducts': ((value.modularProducts as Array<any>).map(MatrixItemDtoToJSON)),
    };
}

