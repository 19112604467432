/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SystemSettingDto,
    SystemSettingDtoFromJSON,
    SystemSettingDtoFromJSONTyped,
    SystemSettingDtoToJSON,
} from './SystemSettingDto';

/**
 * 
 * @export
 * @interface UpdateSystemSettingsCommand
 */
export interface UpdateSystemSettingsCommand {
    /**
     * 
     * @type {SystemSettingDto}
     * @memberof UpdateSystemSettingsCommand
     */
    inputValue?: SystemSettingDto;
}

export function UpdateSystemSettingsCommandFromJSON(json: any): UpdateSystemSettingsCommand {
    return UpdateSystemSettingsCommandFromJSONTyped(json, false);
}

export function UpdateSystemSettingsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSystemSettingsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inputValue': !exists(json, 'inputValue') ? undefined : SystemSettingDtoFromJSON(json['inputValue']),
    };
}

export function UpdateSystemSettingsCommandToJSON(value?: UpdateSystemSettingsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inputValue': SystemSettingDtoToJSON(value.inputValue),
    };
}

