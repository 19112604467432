import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { DeliveryTimeDto, GlobalSettingDto, GlobalSettingTypes } from "api/models";
import { deliveryTimesSagas } from "../sagas/deliveryTimesSagas";
import {
  ApiDeliveryTimesDeliveryTimesGetRequest,
  ApiDeliveryTimesUpdateDescriptionDeliveryTimesPutRequest
} from "api";
import { DeliveryTimesActionTypes, deliveryTimesActions } from "../actions/DeliveryTimesActions";

interface Requests {
  getGlobalSettings: RequestState<Array<GlobalSettingDto>>;
  deliveryTimesDescription: RequestState<ApiDeliveryTimesUpdateDescriptionDeliveryTimesPutRequest>;
  getDeliveryTimes: RequestState<
    ApiDeliveryTimesDeliveryTimesGetRequest,
    ApiDeliveryTimesDeliveryTimesGetRequest
  >;
}

export interface DeliveryTimesState {
  requests: Requests;
  description: string;
  deliveryTimes: DeliveryTimeDto[];
}

const defaultState: DeliveryTimesState = {
  requests: {
    getGlobalSettings: createInitialRequest(),
    deliveryTimesDescription: createInitialRequest(),
    getDeliveryTimes: createInitialRequest()
  },
  description: "",
  deliveryTimes: []
};

export function deliveryTimesReducer(
  state: DeliveryTimesState = defaultState,
  action: deliveryTimesActions
): DeliveryTimesState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: deliveryTimesSagas.updateDescription.actionTypes,
      key: "updateDescription"
    },
    {
      actionTypes: deliveryTimesSagas.getDescription.actionTypes,
      key: "getDescription"
    },
    {
      actionTypes: deliveryTimesSagas.getDeliveryTimes.actionTypes,
      key: "getDeliveryTimes"
    }
  ]);
  if (deliveryTimesSagas.getDescription.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.description =
        action.payload.find((s) => s.name == GlobalSettingTypes.DeliveryTimesText)?.value ?? "";
    });
  }
  if (deliveryTimesSagas.updateDescription.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.description = action.payload;
    });
  }
  if (deliveryTimesSagas.getDeliveryTimes.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.deliveryTimes = action.payload;
    });
  }
  switch (action.type) {
    case DeliveryTimesActionTypes.SetDeliveryTimes:
      state = produce(state, (draft) => {
        draft.deliveryTimes = action.deliveryTimes;
      });
      break;
  }
  return state;
}

export const getDescription = (state: AppState): string => state.deliveryTimes.description;
export const getDeliveryTimes = (state: AppState): DeliveryTimeDto[] =>
  state.deliveryTimes.deliveryTimes;
export const getDeliveryTimesStatus = (
  state: AppState
): RequestState<ApiDeliveryTimesDeliveryTimesGetRequest, ApiDeliveryTimesDeliveryTimesGetRequest> =>
  state.deliveryTimes.requests.getDeliveryTimes;

export const getUpdateDescriptionRequest = (state: AppState) =>
  state.deliveryTimes.requests.deliveryTimesDescription;
