import React, { FC, useState } from "react";
import styled, { useTheme } from "styled-components";
import { MainHeader } from "./styled/MainHeader";
import { getUserInformation } from "../../applications/common/reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { Footer, FooterTexts } from "shared/footer/components/Footer";
import { useTranslation } from "react-i18next";
import { MaxWidthContainer } from "shared/footer/components/StyledComponents";
import { AppMainContent } from "@abb/abb-common-ux-react/components/AppMainContent/AppMainContent";
import { AppContent } from "@abb/abb-common-ux-react/components/AppContent/AppContent";
import { Icon } from "@abb/abb-common-ux-react/components/Icon/Icon";
import { AppLeftPane } from "@abb/abb-common-ux-react/components/AppLeftPane/AppLeftPane";
import { Link } from "@abb/abb-common-ux-react/components/Link/Link";
import { LoadingIndicator } from "@abb/abb-common-ux-react/components/LoadingIndicator/LoadingIndicator";
import { getApplicationVersionNumber } from "applications/common/reducers/commonReducer";
import { commonActions } from "applications/common/actions/commonActions";

const MainContent = styled(AppMainContent)<{ flex?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  ${(props) => (props.flex ? "flex: 1 1 70%;" : undefined)}
  ${(props) => (props.flex ? "overflow: hidden;" : undefined)}
`;

const CustomLeftPane = styled(AppLeftPane)<{ flex?: boolean }>`
  .ABB_CommonUX_AppLeftPane__title {
    margin-left: ${(props) => props.theme.sizes.lm} !important;
  }
  .ABB_CommonUX_AppLeftPane__leftPaneContent {
    ${(props) => (props.flex ? undefined : "width: 300px !important;")}
  }
`;

// "overflow: auto" is marked as !important because otherwise AppContent would hide it with "overflow: hidden"
const Content = styled(AppContent)<{ flex?: boolean; sticky?: boolean }>`
  width: 100%;
  .ABB_CommonUX_AppLeftPane__collapserIcon {
    cursor: pointer;
  }
  display: flex;
  overflow: auto !important;
  flex-direction: column;
  .ABB_CommonUX_AppLeftPane__expanded {
    ${(props) => (props.flex ? "flex: 1 1 300px;" : undefined)}
  }
  ${(props) =>
    props.sticky
      ? " .ABB_CommonUX_AppLeftPane__root { position: sticky; height: 90vh; /* to fix with calc of top menu bar*/ top: 0; }"
      : undefined}
  .ABB_CommonUX_AppLeftPane__hidden {
    min-width: 40px !important; // The open/close arrow has a width of 32px, and a left-margin of 8px, so 32+8 = 40px
  }
`;

export interface AppContentWrapperProps {
  sideBar?: ((isOpen: boolean) => React.ReactNode) | React.ReactNode;
  sideBarTitle?: string;
  pageTitle?: string | React.ReactNode;
  titleHeadingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  isMainView?: boolean;
  isMainFlex?: boolean;
  showFooter?: boolean;
  stickyBar?: boolean;
  maxWidth?: number;
  isLoading?: boolean;
  topSection?: {
    previousLocation?: {
      text: string;
      onClick: () => void;
    };
    rightSectionContent?: React.ReactNode;
  };
}

const Container = styled.div<{ isMainView?: boolean }>`
  // 60px from menu and 12+12 from top + bottom margin
  min-height: calc(100vh - 84px);
  background-color: ${(props) => props.theme.colors.whitePrimary};
  height: 100%;
  margin: ${(props) => props.theme.sizes.sm};
  padding: ${(props) => props.theme.sizes.lm};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${(props) => (props.isMainView ? props.theme.breakpoints.xxl : "100%")};
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.xxl}) {
    width: 100%;
  }
`;

const LeftPanelContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;

export const StyledPageHeading = styled(MainHeader)`
  margin-bottom: ${(props) => props.theme.sizes.xl};
  white-space: break-spaces;
`;

const LoadingIndicatorContainer = styled.div`
  margin: auto;
`;

const PreviousLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 140px;
  justify-content: space-between;
  cursor: pointer;
`;

const TopSectionContainer = styled.div<{
  previousLocationDefined?: boolean;
  customContentDefined?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: ${(props) => {
    if (props.previousLocationDefined && props.customContentDefined) {
      return "space-between";
    } else {
      return props.previousLocationDefined ? "flex-start" : "flex-end";
    }
  }};
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

const basePath = process.env.REACT_APP_BASEPATH;

export const AppContentWrapper: FC<AppContentWrapperProps> = (props) => {
  const [menuVisible, setMenuVisibility] = useState(true);
  const userInformation = useSelector(getUserInformation);
  const applicationVersionNumber = useSelector(getApplicationVersionNumber);
  const { t } = useTranslation();
  const theme = useTheme();

  const renderOptionalTitle = () => {
    if (!props.pageTitle) {
      return null;
    }
    if (typeof props.pageTitle === "string") {
      return <StyledPageHeading text={props.pageTitle} />;
    }

    return props.pageTitle;
  };

  const footerTexts: FooterTexts = {
    frequentlyAskedQuestionsTitle: t("Ask a question"),
    frequentlyAskedQuestionsText: t(
      "If you have any questions or comments regarding any aspect of the platform."
    ),
    learnMoreButtonText: t("Send email"),
    contactAndSupportTitle: t("Contact and support"),
    contactAndSupportText: t(
      "If you have a question that you can't find the answer to or you are just interested in one of ABB's products, feel free to contact us."
    ),
    contactUsButtonText: t("Contact us"),
    latestNewsTitle: t("Subscribe to the latest news"),
    emailAddressPlaceholder: t("Your e-mail address..."),
    disclaimerText: t(
      'By clicking "Sign up", I agree on ABB Privacy Policy and receiving commercial info from ABB.'
    ),
    signUpButtonText: t("Sign up"),
    providerInformationText: t("Provider information"),
    termsConditionsText: t("Terms & Conditions"),
    cookieAndPrivacyPolicyText: t("Cookie and privacy policy"),
    changeCookieSettings: t("Change cookie settings")
  };

  const renderOptionalTopSection = () => {
    if (!props.topSection) {
      return;
    }
    return (
      <TopSectionContainer
        previousLocationDefined={props.topSection?.previousLocation !== undefined}
        customContentDefined={props.topSection?.rightSectionContent !== undefined}
      >
        <PreviousLocationContainer onClick={props.topSection.previousLocation?.onClick}>
          <Icon name="abb/left-arrow" />
          <Link underlined={false} discreet>
            {props.topSection?.previousLocation?.text}
          </Link>
        </PreviousLocationContainer>
        {props.topSection?.rightSectionContent ? props.topSection?.rightSectionContent : null}
      </TopSectionContainer>
    );
  };
  const drawContent = () => {
    return (
      <>
        {renderOptionalTopSection()}
        {renderOptionalTitle()}
        {props.isLoading ? (
          <LoadingIndicatorContainer>
            <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
          </LoadingIndicatorContainer>
        ) : (
          props.children
        )}
      </>
    );
  };
  const dispatch = useDispatch();
  const openCookieDialog = () => dispatch(commonActions.toggleCookiesConsentDialogVisibility(true));
  return (
    <Content flex={props.isMainFlex} sticky={props.stickyBar}>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          width: "100%"
        }}
      >
        {props.sideBar ? (
          <CustomLeftPane
            flex={props.isMainFlex}
            visibility={menuVisible ? "full" : "hidden"}
            onClickToggleVisibility={() => {
              setMenuVisibility(!menuVisible);
            }}
            title={props.sideBarTitle}
            style={{ minWidth: theme.sizes.l }}
          >
            <LeftPanelContainer>
              {props.sideBar && typeof props.sideBar === "function"
                ? props.sideBar(menuVisible)
                : props.sideBar}
            </LeftPanelContainer>
          </CustomLeftPane>
        ) : null}

        <MainContent flex={props.isMainFlex}>
          <Container isMainView={props.isMainView}>
            {props.maxWidth ? (
              <MaxWidthContainer maxWidth={props.maxWidth}>{drawContent()}</MaxWidthContainer>
            ) : (
              <>{drawContent()}</>
            )}
          </Container>
        </MainContent>
      </div>
      {props.showFooter ? (
        <Footer
          firstName={userInformation?.firstName}
          lastName={userInformation?.lastName}
          email={userInformation?.email}
          footerTexts={footerTexts}
          maxWidth={props.maxWidth}
          version={applicationVersionNumber}
          openCookieDialog={openCookieDialog}
          basePath={basePath ?? ""}
        />
      ) : null}
    </Content>
  );
};
