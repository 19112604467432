import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { Standards } from "api";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useStateParams } from "utilities/useStateParams";
import { productExplorerActions } from "../actions/ProductExplorerActions";

export const SelectStandard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fromStrToStd = useCallback((str: string | null) => {
    let std: Standards | undefined = undefined;
    if (str) {
      const stdLower = str.toLowerCase();
      switch (stdLower) {
        case "ansi":
          std = Standards.Ansi;
          break;
        case "iec":
          std = Standards.Iec;
          break;
        default:
          std = undefined;
          break;
      }
    }
    return std;
  }, []);

  const [selectedStandardStr, setSelectedStandardStr] = useStateParams<Standards | undefined>(
    undefined,
    "std",
    (s) => s?.toString() ?? "",
    fromStrToStd
  );

  useEffect(() => {
    dispatch(productExplorerActions.SetFilterStandard(selectedStandardStr));
  }, [dispatch, selectedStandardStr]);

  return (
    <Dropdown
      placeholder={t("Select standard")}
      monochrome
      value={
        selectedStandardStr
          ? [
              {
                value: selectedStandardStr,
                label: selectedStandardStr
              }
            ]
          : []
      }
      style={{ paddingTop: 8 }}
      onChange={(item) => {
        item.length > 0
          ? setSelectedStandardStr(fromStrToStd(item[0].label))
          : setSelectedStandardStr(undefined);
      }}
      clearable
      sizeClass={"small"}
    >
      {Object.keys(Standards).map((key) => (
        <DropdownOption key={key} label={key.toUpperCase()} value={key} />
      ))}
    </Dropdown>
  );
};
