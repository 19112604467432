import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const trainingManagementSagas = {
  getNodes: createGenericSaga(
    "trainingManagementSagas/getNodes",
    getApiRegistry().trainingApi.apiTrainingNodesWithPathGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getNodesRoots: createGenericSaga(
    "trainingManagementSagas/getNodesRoots",
    getApiRegistry().trainingApi.apiTrainingNodesRootsGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getArticles: createGenericSaga(
    "trainingManagementSagas/getArticles",
    getApiRegistry().trainingApi.apiTrainingArticlesGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateNode: createGenericSaga(
    "trainingManagementSagas/updateNode",
    getApiRegistry().trainingApi.apiTrainingNodePut.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Updated"),
      onFail: defaultSagaErrorHandler
    }
  ),
  updateArticle: createGenericSaga(
    "trainingManagementSagas/updateArticle",
    getApiRegistry().trainingApi.apiTrainingArticlePut.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Updated"),
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteNode: createGenericSaga(
    "trainingManagementSagas/deleteNode",
    getApiRegistry().trainingApi.apiTrainingNodeDelete.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Node deleted"),
      onFail: defaultSagaErrorHandler
    }
  ),
  getAttachment: createGenericSaga(
    "trainingManagementSagas/getAttachment",
    getApiRegistry().trainingApi.apiTrainingAttachmentGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  retrieveArticles: createGenericSaga(
    "trainingManagementSagas/retrieveArticles",
    getApiRegistry().trainingApi.apiTrainingRetrieveArticlesGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateArticleNode: createGenericSaga(
    "trainingManagementSagas/updateArticleNode",
    getApiRegistry().trainingApi.apiTrainingUpdateArticleNodePut.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getArticlesFreeAndAssigned: createGenericSaga(
    "trainingManagementSagas/getArticlesFreeAndAssigned",
    getApiRegistry().trainingApi.apiTrainingArticlesFreeAndAssignedGet.bind(
      getApiRegistry().trainingApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
