import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useState } from "react";
import styled from "styled-components";
import { Dialog } from "framework/components/Dialog";
import {
  DetailsRadioGroup,
  TrueOrFalse
} from "applications/shoppingCart/components/orderDetails/DetailsRadioGroup";

const CustomButton = styled(Button)`
  margin-left: auto;
  display: block;
  margin-bottom: ${(props) => props.theme.sizes.lm};
`;

const DialogContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface DialogText {
  title: string;
  radioGroupTitle: string;
  firstOptionLabel: string;
  secondOptionLabel: string;
  buttonName: string;
}

interface RadioGroupSelectionDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  t: TFunction;
  submitForm: (value: boolean) => void;
  text: DialogText;
  isLoading?: boolean;
}

export const RadioGroupSelectionDialog = ({
  isOpen,
  closeDialog,
  t,
  submitForm,
  text,
  isLoading
}: RadioGroupSelectionDialogProps): JSX.Element => {
  const [value, setValue] = useState<TrueOrFalse | undefined>();

  return (
    <Dialog isOpen={isOpen} onClose={() => closeDialog()} showCloseButton title={t(text.title)}>
      <DialogContent>
        <DetailsRadioGroup
          t={t}
          title={t(text.radioGroupTitle)}
          value={value}
          setValue={setValue}
          firstOptionLabel={text.firstOptionLabel}
          secondOptionLabel={text.secondOptionLabel}
        />
        <CustomButton
          text={t(text.buttonName)}
          buttonType="primary"
          onClick={() => submitForm(value === "true")}
          disabled={value === undefined}
          isLoading={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
};
