/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrivacyConsentVm
 */
export interface PrivacyConsentVm {
    /**
     * 
     * @type {boolean}
     * @memberof PrivacyConsentVm
     */
    userAccountCreated?: boolean;
}

export function PrivacyConsentVmFromJSON(json: any): PrivacyConsentVm {
    return PrivacyConsentVmFromJSONTyped(json, false);
}

export function PrivacyConsentVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrivacyConsentVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAccountCreated': !exists(json, 'userAccountCreated') ? undefined : json['userAccountCreated'],
    };
}

export function PrivacyConsentVmToJSON(value?: PrivacyConsentVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userAccountCreated': value.userAccountCreated,
    };
}

