/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationProduct,
    ModificationProductFromJSON,
    ModificationProductFromJSONTyped,
    ModificationProductToJSON,
} from './ModificationProduct';
import {
    Modifications,
    ModificationsFromJSON,
    ModificationsFromJSONTyped,
    ModificationsToJSON,
} from './Modifications';

/**
 * 
 * @export
 * @interface GmdProductModification
 */
export interface GmdProductModification {
    /**
     * 
     * @type {ModificationProduct}
     * @memberof GmdProductModification
     */
    modificationProduct?: ModificationProduct;
    /**
     * 
     * @type {Modifications}
     * @memberof GmdProductModification
     */
    productModification?: Modifications;
}

export function GmdProductModificationFromJSON(json: any): GmdProductModification {
    return GmdProductModificationFromJSONTyped(json, false);
}

export function GmdProductModificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GmdProductModification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modificationProduct': !exists(json, 'modificationProduct') ? undefined : ModificationProductFromJSON(json['modificationProduct']),
        'productModification': !exists(json, 'productModification') ? undefined : ModificationsFromJSON(json['productModification']),
    };
}

export function GmdProductModificationToJSON(value?: GmdProductModification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modificationProduct': ModificationProductToJSON(value.modificationProduct),
        'productModification': ModificationsToJSON(value.productModification),
    };
}

