/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';

/**
 * 
 * @export
 * @interface SelectedCompanyUpdatedDto
 */
export interface SelectedCompanyUpdatedDto {
    /**
     * 
     * @type {CompanyDto}
     * @memberof SelectedCompanyUpdatedDto
     */
    company?: CompanyDto;
}

export function SelectedCompanyUpdatedDtoFromJSON(json: any): SelectedCompanyUpdatedDto {
    return SelectedCompanyUpdatedDtoFromJSONTyped(json, false);
}

export function SelectedCompanyUpdatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedCompanyUpdatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': !exists(json, 'company') ? undefined : CompanyDtoFromJSON(json['company']),
    };
}

export function SelectedCompanyUpdatedDtoToJSON(value?: SelectedCompanyUpdatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': CompanyDtoToJSON(value.company),
    };
}

