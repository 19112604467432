import {
  PriceProductDto,
  ShoppingCartItemDto,
  ShoppingCartModificationItemDto
} from "../api/models";
import { productCategoryConstants } from "../constants/productCategoryConstants";

export const isStaticProduct = (category: string | null | undefined): boolean => {
  if (category === productCategoryConstants.static) {
    return true;
  }
  return false;
};

export const isDetailProduct = (displayName: string | null | undefined): boolean => {
  if (displayName?.includes(":") && displayName.includes("\r\n") && displayName.length > 40) {
    return true;
  }
  return false;
};

export const getShowDetails = (
  product:
    | PriceProductDto
    | ShoppingCartItemDto
    | ShoppingCartModificationItemDto
    | null
    | undefined
): boolean => {
  if (!isStaticProduct(product?.category)) {
    return true;
  }

  if (isDetailProduct(product?.displayName)) {
    return true;
  }

  return false;
};
