/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductLifecycleStatus {
    Active = 'Active',
    Classic = 'Classic',
    Limited = 'Limited',
    Obsolete = 'Obsolete'
}

export function ProductLifecycleStatusFromJSON(json: any): ProductLifecycleStatus {
    return ProductLifecycleStatusFromJSONTyped(json, false);
}

export function ProductLifecycleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductLifecycleStatus {
    return json as ProductLifecycleStatus;
}

export function ProductLifecycleStatusToJSON(value?: ProductLifecycleStatus | null): any {
    return value as any;
}

