import { TFunction } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import { DatePickerInput } from "framework/components/DatePickerInput";
import { formatDate, getDateTime, getValidDateFormat } from "utilities/dateUtils";
import { ShoppingCartForm } from "applications/shoppingCart/containers/ShoppingCartView";
import { ErrorMessage, FormikProps } from "formik";
import { ContentContainer } from "./ContentContainer";
import { DetailsRadioGroup } from "./DetailsRadioGroup";
import { LabelWithText } from "framework/components/LabelWithText";
import { Error } from "framework/components/Error";
import { InputLabel } from "framework/components/InputLabel";
import { IconWithTooltip } from "framework/components/IconWithTooltip";
import { isPriorityCharge } from "applications/shoppingCart/validationSchema";
import { Button } from "framework/components/Button";
import { useSelector } from "react-redux";
import { getTermsOfDeliveries } from "applications/common/reducers/shoppingCartReducer";
import { Dispatch } from "redux";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { termsOfDeliveriesConstants } from "constants/termsOfDeliveryConstant";
import { handleFormikValueChange } from "utilities/formikUtils";
import styled, { useTheme } from "styled-components";
import { CompanyDto, TermsOfDeliveryDto } from "api";
import { ResizableInput } from "framework/components/styled/ResizableInput";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.s};
  margin-top: ${(props) => props.theme.sizes.sm};
`;

interface ShipmentDetailsProps {
  isReadOnly: boolean;
  t: TFunction;
  formik: FormikProps<ShoppingCartForm>;
  selectedCompany: CompanyDto | undefined;
  dispatch: Dispatch;
}

const isTrue = "true";

const getPriorityTooltip = (isExternal: boolean, t: TFunction) => {
  if (!isExternal) {
    return t(
      "Please note!\n\nYour requested delivery date falls within the limit for priority handling of deliveries.\n\nThe priority delivery handling service is available for limited quantities of products depending on availability in production.\n\nFor an order to be ready for shipment within five (5) working days an additional charge of 25% per item will be added to the order.\n\nAvailable capacity will be checked and the priority charge will be added if available. If production does not have adequite capacity the soonest available delivery date will be confirmed with order confirmation."
    );
  }
  return t(
    "Please note!\n\nYour requested delivery date falls within the limit for priority handling of deliveries.\n\nThe priority delivery handling service is available for limited quantities of products depending on availability in production and is subject to an additional charge.\n\nPlease contact your ABB sales representative to discuss the possibility of priority delivery handling of your order.\n\nPlease re-enter your requested delivery date or click 'Confirm' to continue with entered date."
  );
};

const getTermsOfDeliveryDestination = (
  termsOfDeliveryCode: string | null | undefined,
  deliveryCity: string | null | undefined,
  t: TFunction
): string | null | undefined => {
  switch (termsOfDeliveryCode) {
    case termsOfDeliveriesConstants.FCA:
      return "(Vaasa)";
    case "DAP":
      return `${deliveryCity ?? ""}`;
    case termsOfDeliveriesConstants.Other:
      return "";
    case null:
      return "";
    case undefined:
      return "";
    default:
      return t("(TBA on delivery)");
  }
};

const getTermsOfDeliveryToDisplay = (
  termsOfDeliveryCode: string | null | undefined,
  destination: string | null | undefined,
  description: string
) => {
  if (!termsOfDeliveryCode) {
    return termsOfDeliveriesConstants.Other;
  }
  if (termsOfDeliveryCode === termsOfDeliveriesConstants.Other) {
    return termsOfDeliveriesConstants.Other;
  }

  return `${termsOfDeliveryCode} - ${description} ${destination}`;
};

export const ShipmentDetails = ({
  isReadOnly,
  t,
  formik,
  selectedCompany,
  dispatch
}: ShipmentDetailsProps): JSX.Element => {
  const hasShownTooltip = useRef(false);
  const termsOfDeliveries = useSelector(getTermsOfDeliveries);
  const [availableTermsOfDeliveries, setAvailableTermsOfDeliveries] = useState<
    TermsOfDeliveryDto[]
  >([]);
  const [showDefault, setShowDefault] = useState<boolean>(true);
  useEffect(() => {
    dispatch(shoppingCartSagas.getTermsOfDeliveries.createAction({ canUse: true }));
  }, [dispatch, selectedCompany]);
  useEffect(() => {
    if (
      termsOfDeliveries &&
      !selectedCompany?.isInternal &&
      !selectedCompany?.isMaintainedCustomer
    ) {
      const priority = ["CIP", "CPT", "FCA", "Other"];
      const companyTermOfDelivery =
        selectedCompany?.defaultTermsOfDeliveryCode ?? termsOfDeliveriesConstants.Other;
      const defaultIdx = priority.indexOf(companyTermOfDelivery);
      setAvailableTermsOfDeliveries(
        termsOfDeliveries?.filter((t) => t.code && priority.indexOf(t.code) >= defaultIdx) ?? []
      );
    } else {
      setAvailableTermsOfDeliveries(
        termsOfDeliveries?.filter((x) => x.code != termsOfDeliveriesConstants.Other) ?? []
      );
    }
  }, [
    selectedCompany?.defaultTermsOfDeliveryCode,
    selectedCompany?.isInternal,
    selectedCompany?.isMaintainedCustomer,
    termsOfDeliveries
  ]);
  useEffect(() => {
    if (
      selectedCompany &&
      (selectedCompany.isInternal || selectedCompany.isMaintainedCustomer) &&
      (selectedCompany.defaultTermsOfDeliveryCode == null ||
        selectedCompany.defaultTermsOfDeliveryCode === termsOfDeliveriesConstants.Other)
    ) {
      setShowDefault(false);
      handleFormikValueChange(formik, "useDefaultTermsOfDelivery", false);
      if (formik.values.requestedTermsOfDeliveryCode === termsOfDeliveriesConstants.Other) {
        handleFormikValueChange(formik, "requestedTermsOfDeliveryCode", "");
      }
    } else {
      setShowDefault(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  const formatTermsOfDelivery = (code: string | null | undefined) => {
    const description =
      termsOfDeliveries?.find((x) => x.code === code)?.description ??
      termsOfDeliveriesConstants.Other;

    const destination = getTermsOfDeliveryDestination(code, formik.values.deliveryAddress?.city, t);

    return getTermsOfDeliveryToDisplay(code, destination, description);
  };

  const theme = useTheme();

  return (
    <ContentContainer title={t("Shipment details")}>
      {showDefault ? (
        <LabelWithText
          noMarginBottom
          text={formatTermsOfDelivery(formik.values.termsOfDeliveryCode)}
          label={t("Default terms of delivery")}
        />
      ) : (
        <></>
      )}
      {isReadOnly ? (
        <>
          <LabelWithText
            text={formatTermsOfDelivery(
              formik.values.useDefaultTermsOfDelivery
                ? formik.values.termsOfDeliveryCode
                : formik.values.requestedTermsOfDeliveryCode
            )}
            label={t("Requested terms of delivery")}
          />
        </>
      ) : (
        <>
          {showDefault ? (
            <DetailsRadioGroup
              t={t}
              isRequired
              title={t("Use default terms of delivery")}
              value={formik.values.useDefaultTermsOfDelivery?.toString()}
              setValue={(v) =>
                handleFormikValueChange(formik, "useDefaultTermsOfDelivery", v === isTrue)
              }
            />
          ) : (
            <></>
          )}
          {!formik.values.useDefaultTermsOfDelivery ? (
            <>
              <InputLabel
                label={t("Requested terms of delivery")}
                isRequired={true}
                tooltipText={t(
                  "Terms of delivery are based on Incoterms 2020. The shown Incoterm is the default used, but another can be requested. Incoterms and destination will be confirmed in Order Acknowledgement during order booking phase."
                )}
              />

              <Dropdown
                monochrome
                value={[
                  {
                    value: formik.values.requestedTermsOfDeliveryCode,
                    label: formik.values.requestedTermsOfDeliveryCode
                      ? formatTermsOfDelivery(formik.values.requestedTermsOfDeliveryCode)
                      : ""
                  }
                ]}
                onChange={(v) =>
                  handleFormikValueChange(formik, "requestedTermsOfDeliveryCode", v[0].value)
                }
                onClose={() => formik.setFieldTouched("requestedTermsOfDeliveryCode", true, true)}
                validationState={{
                  valid: formik.errors.requestedTermsOfDeliveryCode ? false : true,
                  message: formik.errors.requestedTermsOfDeliveryCode
                }}
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
              >
                {availableTermsOfDeliveries.map((x) => (
                  <DropdownOption value={x.code} label={x.displayName ?? ""} key={x.code ?? ""} />
                ))}
              </Dropdown>
            </>
          ) : null}
        </>
      )}
      {!isReadOnly ? (
        <>
          <DetailsRadioGroup
            t={t}
            isRequired
            tooltipText={t(
              "Standard lead times for specific products are defined by the factory production and refer to EXW Vaasa deliveries.\n\nFor more information please refer to the Delivery Time Tracker"
            )}
            firstOptionLabel={t("Standard lead time")}
            secondOptionLabel={t("Custom date")}
            isReversed
            title={"Requested delivery date"}
            value={formik.values.isCustomDeliveryDate?.toString()}
            setValue={(v) => handleFormikValueChange(formik, "isCustomDeliveryDate", v === isTrue)}
          />
          <ErrorMessage name={"isCustomDeliveryDate"}>{(err) => <Error msg={err} />}</ErrorMessage>
        </>
      ) : (
        <LabelWithText
          text={
            formik.values.requestedDeliveryDate
              ? formatDate(
                  getDateTime(formik.values.requestedDeliveryDate, getValidDateFormat()) ??
                    new Date()
                )
              : t("Standard lead time")
          }
          label={t("Requested delivery date")}
        />
      )}
      {formik.values.isCustomDeliveryDate && !isReadOnly ? (
        <>
          <div style={{ marginTop: theme.sizes.s, display: "flex" }}>
            <div style={{ width: "50%" }}>
              <DatePickerInput
                label={""}
                value={formik.values.requestedDeliveryDate}
                type="normal"
                instantValidation
                validationResult={{
                  valid:
                    !!formik.errors.requestedDeliveryDate && !!formik.touched.requestedDeliveryDate
                      ? false
                      : true,
                  text: formik.errors.requestedDeliveryDate
                }}
                showValidationIconWhenInvalid
                showValidationBarWhenValid={formik.values.isPriorityConfirmed}
                onValueChange={(v: string) => {
                  handleFormikValueChange(formik, "isPriorityConfirmed", undefined);
                  handleFormikValueChange(formik, "requestedDeliveryDate", v, true);
                }}
              />
            </div>
            {!formik.errors.requestedDeliveryDate &&
            formik.touched.requestedDeliveryDate &&
            isPriorityCharge(formik.values.requestedDeliveryDate, !selectedCompany?.isInternal) ? (
              <IconWithTooltip
                icon="abb/warning-circle-1"
                sizeClass="medium"
                tooltipText={getPriorityTooltip(!selectedCompany?.isInternal, t)}
                style={{ marginLeft: theme.sizes.s, color: "red" }}
                isDefaultOpen={!hasShownTooltip.current}
                onClose={() => (hasShownTooltip.current = true)}
              />
            ) : null}
            {!formik.errors.requestedDeliveryDate &&
            formik.touched.requestedDeliveryDate &&
            isPriorityCharge(formik.values.requestedDeliveryDate, !selectedCompany?.isInternal) &&
            !formik.values.isPriorityConfirmed ? (
              <Button
                style={{ marginLeft: theme.sizes.s }}
                buttonType={"secondary"}
                sizeClass="small"
                text={t("Confirm")}
                onClick={() => handleFormikValueChange(formik, "isPriorityConfirmed", true)}
              />
            ) : null}
          </div>
          <DetailsRadioGroup
            t={t}
            isRequired
            title={t("Allow delivery before requested delivery date")}
            value={formik.values.acceptEarlierDelivery?.toString()}
            setValue={(v) => handleFormikValueChange(formik, "acceptEarlierDelivery", v === isTrue)}
          />
          <ErrorMessage name={"acceptEarlierDelivery"}>{(err) => <Error msg={err} />}</ErrorMessage>
        </>
      ) : null}
      {isReadOnly && formik.values.requestedDeliveryDate ? (
        <LabelWithText
          text={formik.values.acceptEarlierDelivery ? t("Yes") : t("No")}
          label={t("Allow delivery before requested delivery date")}
        />
      ) : null}

      {!isReadOnly ? (
        <>
          <DetailsRadioGroup
            t={t}
            isRequired
            title={t("I will accept partial delivery")}
            value={formik.values.acceptPartialDelivery?.toString()}
            setValue={(v) => handleFormikValueChange(formik, "acceptPartialDelivery", v === isTrue)}
          />
          <ErrorMessage name={"acceptPartialDelivery"}>{(err) => <Error msg={err} />}</ErrorMessage>
        </>
      ) : (
        <LabelWithText
          text={formik.values.acceptPartialDelivery ? t("Yes") : t("No")}
          label={t("I will accept partial delivery")}
        />
      )}
      <Wrapper>
        {isReadOnly ? (
          <LabelWithText
            text={formik.values.forwarderInfo}
            label={t("Forwarder contact information and account number")}
          />
        ) : (
          <div>
            <InputLabel
              isRequired={formik.values.termsOfDeliveryCode === termsOfDeliveriesConstants.FCA}
              label={t("Forwarder contact information and account number")}
            />
            <ResizableInput
              dataType="textarea"
              inputAttributes={{
                name: "forwarderInfo",
                onChange: formik.handleChange
              }}
              value={formik.values.forwarderInfo}
              validationResult={{
                valid:
                  !!formik.errors.forwarderInfo && !!formik.touched.forwarderInfo ? false : true,
                text: formik.errors.forwarderInfo
              }}
              instantValidation={true}
              showValidationIconWhenInvalid={true}
              onLostFocus={formik.handleBlur}
              defaultType="verticalS"
            />
          </div>
        )}
        {isReadOnly ? (
          <LabelWithText text={formik.values.additionalInfo} label={t("Additional information")} />
        ) : (
          <ResizableInput
            dataType="textarea"
            label={t("Additional information")}
            inputAttributes={{
              name: "additionalInfo",
              onChange: formik.handleChange
            }}
            value={formik.values.additionalInfo}
            validationResult={{
              valid: formik.errors.additionalInfo && formik.touched.additionalInfo ? false : true,
              text: formik.errors.additionalInfo
            }}
            instantValidation={true}
            showValidationBarWhenInvalid={true}
            showValidationIconWhenInvalid
            onLostFocus={formik.handleBlur}
            defaultType="verticalS"
          />
        )}
        {isReadOnly ? (
          <LabelWithText text={formik.values.shippingMarks} label={t("Shipping marks")} />
        ) : (
          <ResizableInput
            dataType="textarea"
            label={t("Shipping marks")}
            inputAttributes={{
              name: "shippingMarks",
              onChange: formik.handleChange
            }}
            value={formik.values.shippingMarks}
            validationResult={{
              valid: formik.errors.shippingMarks && formik.touched.shippingMarks ? false : true,
              text: formik.errors.shippingMarks
            }}
            instantValidation={true}
            showValidationBarWhenInvalid={true}
            onLostFocus={formik.handleBlur}
            defaultType="verticalS"
          />
        )}

        {isReadOnly ? (
          <LabelWithText
            text={formik.values.specialInstructions}
            label={t("Special instructions")}
          />
        ) : (
          <ResizableInput
            dataType="textarea"
            label={t("Special instructions")}
            inputAttributes={{
              name: "specialInstructions",
              onChange: formik.handleChange
            }}
            value={formik.values.specialInstructions}
            validationResult={{
              valid:
                formik.errors.specialInstructions && formik.touched.specialInstructions
                  ? false
                  : true,
              text: formik.errors.specialInstructions
            }}
            instantValidation={true}
            showValidationBarWhenInvalid={true}
            onLostFocus={formik.handleBlur}
            defaultType="verticalS"
          />
        )}
      </Wrapper>
    </ContentContainer>
  );
};
