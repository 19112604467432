import React, { ReactNode } from "react";
import styled from "styled-components";
import { IconWithTooltip } from "./IconWithTooltip";

const Label = styled.p<{ fontSize: "small" | "medium" | "large" }>`
  margin: 0;
  font-size: ${(props) => {
    switch (props.fontSize) {
      case "small":
        return props.theme.fonts.sizes.fontSizeXs;
      case "medium":
        return props.theme.fonts.sizes.fontSizeS;
      case "large":
        return props.theme.fonts.sizes.fontSizeLM;
    }
  }};
  color: ${(props) => props.theme.colors.grey60};
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fonts.families.fontAbbRegular};
  width: fit-content;
`;

const Text = styled.p<{ fontSize: "small" | "medium" | "large"; noMarginBottom: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.sizes.xs};
  margin: 0;
  margin-bottom: ${(props) => {
    return props.noMarginBottom ? "0px" : props.theme.sizes.m;
  }};
  font-size: ${(props) => {
    switch (props.fontSize) {
      case "small":
        return props.theme.fonts.sizes.fontSizeS;
      case "medium":
        return props.theme.fonts.sizes.fontSizeM;
      case "large":
        return props.theme.fonts.sizes.fontSizeL;
    }
  }};
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  min-width: ${(props) => props.theme.sizes.lm};
  white-space: pre-line;
  &:after {
    content: "";
    display: inline-block;
  }
`;

type LabelWithTextProps = {
  label: string | undefined;
  text: ReactNode | undefined | null;
  fontSize?: "small" | "medium" | "large";
  noMarginBottom?: boolean;
} & (
  | {
      valueIcon: string;
      valueTooltipText: string;
      valueIconColor: string;
    }
  | object
) &
  (
    | {
        labelIcon: string;
        labelTooltipText: string;
        labelIconColor: string;
      }
    | object
  );

const IconLabelContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.xs};
  gap: ${(props) => props.theme.sizes.xs};
`;

export const LabelWithText = ({
  label,
  text,
  fontSize = "small",
  noMarginBottom = false,
  ...props
}: LabelWithTextProps): JSX.Element => {
  return (
    <div>
      <IconLabelContainer>
        <Label fontSize={fontSize}>{label}</Label>
        {"labelIcon" in props && props.labelIcon ? (
          <IconWithTooltip
            color={props.labelIconColor}
            tooltipText={props.labelTooltipText}
            icon={props.labelIcon}
            style={{ marginTop: 0 }}
          />
        ) : null}
      </IconLabelContainer>
      <Text fontSize={fontSize} noMarginBottom={noMarginBottom}>
        {text}
        {"valueIcon" in props && props.valueIcon ? (
          <IconWithTooltip
            color={props.valueIconColor}
            tooltipText={props.valueTooltipText}
            icon={props.valueIcon}
            style={{ display: "inline" }}
          />
        ) : null}
      </Text>
    </div>
  );
};
