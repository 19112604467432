import {
  ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest,
  ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest,
  ApplicationNotificationOperations
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";
import {
  ApplicationNotificationViewActions,
  ApplicationNotificationViewActionTypes
} from "../actions/applicationNotitficationActions";
import { applicationNotificationSagas } from "../sagas/applicationNotificationSagas";

interface Requests {
  updateNotificationsIsReadStatus: RequestState<
    unknown,
    ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest
  >;

  removeApplicationNotifications: RequestState<
    unknown,
    ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest
  >;
}

interface NotificationState {
  unreadNotificationCount: number | undefined;
  lastOperation?: ApplicationNotificationOperations;
  notificationIds?: number[];
}

export interface ApplicationNotificationsViewState {
  requests: Requests;
  notificationState: NotificationState;
}

const defaultState: ApplicationNotificationsViewState = {
  requests: {
    updateNotificationsIsReadStatus: createInitialRequest(),
    removeApplicationNotifications: createInitialRequest()
  },
  notificationState: {
    unreadNotificationCount: undefined,
    lastOperation: undefined
  }
};

export function applicationNotificationsViewReducer(
  state: ApplicationNotificationsViewState = defaultState,
  action: ApplicationNotificationViewActions
): ApplicationNotificationsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: applicationNotificationSagas.updateNotificationsIsReadStatus.actionTypes,
      key: "updateNotificationsIsReadStatus"
    },
    {
      actionTypes: applicationNotificationSagas.removeApplicationNotifications.actionTypes,
      key: "removeApplicationNotifications"
    }
  ]);

  switch (action.type) {
    case ApplicationNotificationViewActionTypes.SetNotificationState:
      state = produce(state, (draft) => {
        draft.notificationState = {
          unreadNotificationCount: action.unreadNotificationCount,
          lastOperation: action.operation,
          notificationIds: action.notificationIds
        };
      });
      break;
  }

  return state;
}

export function getUpdateNotificationsIsReadStatusRequest(
  state: AppState
): RequestState<unknown, ApiApplicationNotificationsUpdateNotificationsIsReadStatusPostRequest> {
  return state.applicationNotificationsView.requests.updateNotificationsIsReadStatus;
}

export function getRemoveApplicationNotificationsRequest(
  state: AppState
): RequestState<unknown, ApiApplicationNotificationsRemoveApplicationNotificationsDeleteRequest> {
  return state.applicationNotificationsView.requests.removeApplicationNotifications;
}

export function getNotificationState(state: AppState): NotificationState {
  return state.applicationNotificationsView.notificationState;
}
