/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamDto,
    TeamDtoFromJSON,
    TeamDtoFromJSONTyped,
    TeamDtoToJSON,
} from './TeamDto';
import {
    TeamMemberSources,
    TeamMemberSourcesFromJSON,
    TeamMemberSourcesFromJSONTyped,
    TeamMemberSourcesToJSON,
} from './TeamMemberSources';
import {
    TeamMemberStatus,
    TeamMemberStatusFromJSON,
    TeamMemberStatusFromJSONTyped,
    TeamMemberStatusToJSON,
} from './TeamMemberStatus';

/**
 * 
 * @export
 * @interface TeamMemberDto
 */
export interface TeamMemberDto {
    /**
     * 
     * @type {boolean}
     * @memberof TeamMemberDto
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamMemberDto
     */
    isInUse?: boolean;
    /**
     * 
     * @type {TeamMemberStatus}
     * @memberof TeamMemberDto
     */
    status?: TeamMemberStatus;
    /**
     * 
     * @type {TeamMemberSources}
     * @memberof TeamMemberDto
     */
    source?: TeamMemberSources;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberDto
     */
    userId?: number;
    /**
     * 
     * @type {TeamDto}
     * @memberof TeamMemberDto
     */
    team?: TeamDto;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberDto
     */
    roleAbbreviation?: string | null;
}

export function TeamMemberDtoFromJSON(json: any): TeamMemberDto {
    return TeamMemberDtoFromJSONTyped(json, false);
}

export function TeamMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isInUse': !exists(json, 'isInUse') ? undefined : json['isInUse'],
        'status': !exists(json, 'status') ? undefined : TeamMemberStatusFromJSON(json['status']),
        'source': !exists(json, 'source') ? undefined : TeamMemberSourcesFromJSON(json['source']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'team': !exists(json, 'team') ? undefined : TeamDtoFromJSON(json['team']),
        'roleAbbreviation': !exists(json, 'roleAbbreviation') ? undefined : json['roleAbbreviation'],
    };
}

export function TeamMemberDtoToJSON(value?: TeamMemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isActive': value.isActive,
        'isInUse': value.isInUse,
        'status': TeamMemberStatusToJSON(value.status),
        'source': TeamMemberSourcesToJSON(value.source),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'userId': value.userId,
        'team': TeamDtoToJSON(value.team),
        'roleAbbreviation': value.roleAbbreviation,
    };
}

