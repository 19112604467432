import React, { useEffect, useMemo } from "react";
import { TFunction } from "i18next";
import styled from "styled-components";
import { daysUntil, formatDate } from "utilities/dateUtils";
import { LabelWithText } from "framework/components/LabelWithText";
import { AccessRights, CompanyDto, QuoteBaseDto, ShoppingCartDto, UserBaseDto } from "api";
import { Input } from "@abb/abb-common-ux-react";
import { InputLabel } from "framework/components/InputLabel";
import { ReviseQuoteFromCart } from "../containers/QuoteView";
import { FormikProps } from "formik";
import { DatePickerInput } from "framework/components/DatePickerInput";
import { handleFormikValueChange } from "utilities/formikUtils";
import { ContentContainer } from "applications/shoppingCart/components/orderDetails/ContentContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddAdditionalQuoteRequestersStatus,
  getAdditionalQuoteRequesterCandidates,
  getAdditionalQuoteRequesterCandidatesStatus,
  getFrameAgreementExpirationWarningDaysBefore
} from "applications/common/reducers/shoppingCartReducer";
import {
  currentUserIsQuoteRequester,
  getQuoteStateText,
  getShoppingCartStateText,
  isQuoteExpired
} from "applications/shoppingCart/helpers";
import { commonUXTheme } from "styles/commonUXVariables";
import { LabelWithLink } from "framework/components/LabelWithLink";
import { routes } from "utilities/routes";
import { RequestStatus } from "framework/state/requestStatus";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getUserAccessRights, getUserInformation } from "applications/common/reducers/userReducer";
import { QuoteDetailsRequesters } from "./QuoteDetailsRequesters";
import { shoppingCartCompanySagas } from "applications/shoppingCart/sagas/shoppingCartCompanySagas";
import { getCompanyApprovers } from "applications/shoppingCart/reducers/shoppingCartCompanyReducer";
import { hasAnyAccessRight } from "utilities/authUtils";
import { ReviseMode } from "applications/shoppingCart/containers/ShoppingCartView";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 1fr));
  row-gap: ${(props) => props.theme.sizes.m};
  column-gap: ${(props) => props.theme.sizes.m};
`;

const FlexColumn = styled.div`
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.m};
  flex-grow: 1;
`;

const FlexRow = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.sizes.lm};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

const FlexChild = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

/**
 * @param isEditable Override internal logic to decide if component should be in edit mode.
 */
interface QuoteDetailsInfoProps {
  quote: QuoteBaseDto | undefined;
  t: TFunction;
  selectedCompany: CompanyDto | undefined;
  shoppingCart: ShoppingCartDto | undefined;
  formik: FormikProps<ReviseQuoteFromCart> | undefined;
  showVisibility?: boolean;
  reviseMode?: ReviseMode;
  isEditable?: boolean;
}

const QuoteDetailsInfo = ({
  quote,
  t,
  selectedCompany,
  shoppingCart,
  formik,
  showVisibility,
  reviseMode,
  isEditable
}: QuoteDetailsInfoProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const expirationDays = useSelector(getFrameAgreementExpirationWarningDaysBefore) ?? 60;
  const daysUntilExpiration = quote ? daysUntil(moment(quote.tenderValidityDate)) : 0;
  const additionalQuoteRequesterCandidates = useSelector(getAdditionalQuoteRequesterCandidates);
  const userInformation = useSelector(getUserInformation);
  const companyApprovers = useSelector(getCompanyApprovers);
  const userAccessRights = useSelector(getUserAccessRights);

  const additionalQuoteRequesterCandidatesStatus = useSelector(
    getAdditionalQuoteRequesterCandidatesStatus
  );
  const addAdditionalQuoteRequestersStatus = useSelector(getAddAdditionalQuoteRequestersStatus);

  useEffect(() => {
    if (quote?.companyId) {
      dispatch(shoppingCartCompanySagas.getCompanyApprovers.createAction({ id: quote.companyId }));
    }
  }, [dispatch, quote]);

  const getStatusText = (
    quote: QuoteBaseDto,
    shoppingCart: ShoppingCartDto | undefined
  ): string => {
    return (
      getQuoteStateText(quote.stateId, t) ?? getShoppingCartStateText(shoppingCart?.stateId, t)
    );
  };

  const addQuoteRequesters = (requesters: string[]) => {
    if (quote?.id) {
      dispatch(
        shoppingCartSagas.addAdditionalQuoteRequesters.createAction({
          id: quote.id,
          addRequesters: { additionalRequesters: requesters }
        })
      );
    }
  };

  const isASM = useMemo(
    () =>
      hasAnyAccessRight(
        undefined,
        userAccessRights,
        AccessRights.ManageMarginAnalysisToolQuotesAsAsm
      ),
    [userAccessRights]
  );

  const isAddingRequestorsAllowed = useMemo(() => {
    return (
      (quote &&
        userInformation &&
        (quote.quotedById === userInformation.id ||
          companyApprovers?.includes(userInformation.email) ||
          isASM ||
          currentUserIsQuoteRequester(quote, userInformation))) ??
      false
    );
  }, [quote, userInformation, companyApprovers, isASM]);

  const renderQuoteReference = (reference: string) => (
    <FlexChild>
      <LabelWithText noMarginBottom label={t("Quote reference")} text={reference} />
    </FlexChild>
  );

  const renderCompanyLabelWithText = (companyName: string) => (
    <FlexColumn>
      <LabelWithText label={t("Company")} text={companyName} />
    </FlexColumn>
  );

  const renderRequesterDetails = (quote: QuoteBaseDto, editable: boolean) => {
    const label = t("Requested by");
    const text =
      quote.requestedBy && quote.company
        ? quote.requestedBy +
          ",\n" +
          quote.company +
          (shoppingCart?.pgCode ? `\n PG code: ${shoppingCart.pgCode}` : "")
        : "";

    return (
      <FlexChild>
        {editable ? (
          <QuoteDetailsRequesters
            noMarginBottom
            t={t}
            requesterCandidates={additionalQuoteRequesterCandidates ?? []}
            isAddingRequestorsAllowed={isAddingRequestorsAllowed}
            isLoading={
              additionalQuoteRequesterCandidatesStatus === RequestStatus.Pending ||
              addAdditionalQuoteRequestersStatus === RequestStatus.Pending
            }
            action={addQuoteRequesters}
            getRequesterCandidates={() =>
              dispatch(
                shoppingCartSagas.getAdditionalQuoteRequesterCandidates.createAction({
                  id: quote.id
                })
              )
            }
            labelIcon={quote?.requestingUserRemoved ?? false ? "abb/warning-circle-1" : undefined}
            labelTooltipText={
              quote?.requestingUserRemoved
                ? "Requesting user has been removed from the system"
                : undefined
            }
            actionText={t("+ Add")}
            labelIconColor={commonUXTheme.colors.statusOrange}
            label={label}
            text={text}
            additionalRequesters={quote.additionalQuoteRequesters}
          />
        ) : (
          <LabelWithText label={label} text={text} />
        )}
      </FlexChild>
    );
  };

  const renderCartStatus = (quote: QuoteBaseDto) => (
    <FlexChild>
      <LabelWithText
        noMarginBottom
        label={t("Cart Status")}
        text={`${getStatusText(quote, shoppingCart)} - ${
          shoppingCart?.company?.isMaintainedCustomer || shoppingCart?.company?.isInternal
            ? t("Internal")
            : t("External")
        }`}
      />
    </FlexChild>
  );

  const renderCreatorDetails = (quotedBy: UserBaseDto | undefined) => (
    <FlexChild>
      <LabelWithText
        noMarginBottom
        label={t("Creator of the quotation")}
        labelIcon={quotedBy?.isDeleted ?? false ? "abb/warning-circle-1" : undefined}
        labelTooltipText={
          quotedBy?.isDeleted ?? false
            ? t("Quote creator has been removed from the system")
            : undefined
        }
        labelIconColor={commonUXTheme.colors.statusOrange}
        text={quotedBy ? `${quotedBy.firstName} ${quotedBy.lastName}\n${quotedBy.email}` : " - "}
      />
    </FlexChild>
  );

  const renderRequestDate = (requestedDate: Date | undefined) => (
    <FlexChild>
      <LabelWithText
        noMarginBottom
        label={t("Request date")}
        text={requestedDate ? formatDate(requestedDate, false) : ""}
      />
    </FlexChild>
  );
  const renderExpectedOrderDate = (
    isframeAgreement: boolean,
    expectedOrderDate: string | null | undefined
  ) => (
    <FlexChild>
      <LabelWithText
        noMarginBottom
        label={isframeAgreement ? t("Expected award date") : t("Expected order date")}
        text={expectedOrderDate}
      />
    </FlexChild>
  );

  const renderValidityDate = (
    tenderValidityDate: string | null | undefined,
    showWarning: boolean
  ) => (
    <FlexChild>
      <LabelWithText
        label={t("Validity date")}
        text={tenderValidityDate}
        valueIcon={
          showWarning && daysUntilExpiration <= expirationDays ? "abb/warning-circle-1" : undefined
        }
        valueTooltipText={
          showWarning // TODO make sure it is not showing empty tooltip
            ? isQuoteExpired(shoppingCart)
              ? t("Frame agreement is expired")
              : daysUntilExpiration === 1
              ? t("{{count}} day remaining", { count: daysUntilExpiration })
              : t("{{count}} days remaining", { count: daysUntilExpiration })
            : ""
        }
        valueIconColor={commonUXTheme.colors.statusOrange}
      />
    </FlexChild>
  );
  const renderValidityDateEditable = (formikProp: FormikProps<ReviseQuoteFromCart>) => (
    <div>
      <InputLabel label={t("Validity date")} isRequired />
      <DatePickerInput
        label=""
        value={formikProp.values.tenderValidityDate}
        type="normal"
        validationResult={{
          valid:
            !!formikProp.errors.tenderValidityDate && !!formikProp.touched.tenderValidityDate
              ? false
              : true,
          text: formikProp.errors.tenderValidityDate
        }}
        showValidationIconWhenInvalid
        onValueChange={(v: string) => {
          handleFormikValueChange(formikProp, "tenderValidityDate", v, true);
        }}
        instantValidation
      />
    </div>
  );

  const renderVisibility = () =>
    showVisibility && (
      <FlexChild>
        <LabelWithText label={t("Cart visibility")} text={t("Private")} />
      </FlexChild>
    );

  const renderBaseQuote = (quote: QuoteBaseDto) =>
    quote.baseQuote && (
      <LabelWithLink
        label={t("Revised quote reference")}
        text={quote.baseQuote.reference}
        link={
          quote.frameAgreement === true
            ? routes.shoppingCart + "/" + quote.baseQuote.guid
            : routes.manage.quote + "/" + quote.baseQuoteId
        }
      />
    );

  const renderRevisionRequester = (requestedRevisionBy?: UserBaseDto) =>
    requestedRevisionBy && (
      <LabelWithText
        noMarginBottom
        label={t("Revision requested by")}
        labelIcon={requestedRevisionBy.isDeleted ?? false ? "abb/warning-circle-1" : undefined}
        labelTooltipText={
          requestedRevisionBy.isDeleted ?? false
            ? t("Revision requestor has been removed from the system")
            : undefined
        }
        labelIconColor={commonUXTheme.colors.statusOrange}
        text={
          requestedRevisionBy
            ? `${requestedRevisionBy.firstName} ${requestedRevisionBy.lastName}\n${requestedRevisionBy.email}`
            : " - "
        }
      />
    );
  return (
    <ContentContainer title={t("Quote details")}>
      {!!quote && reviseMode == ReviseMode.None && !isEditable && (
        <GridContainer>
          {renderQuoteReference(quote.reference)}
          {renderRequesterDetails(quote, true)}
          {renderCartStatus(quote)}
          {renderCreatorDetails(quote.quotedBy)}
          {renderRequestDate(quote.requestedDate)}

          {renderExpectedOrderDate(
            quote.frameAgreement ?? false,
            quote.expectedOrderDate ? formatDate(quote.expectedOrderDate, false) : ""
          )}
          {renderValidityDate(
            quote.tenderValidityDate ? formatDate(quote.tenderValidityDate, false) : "",
            quote.frameAgreement ?? false
          )}
          {renderVisibility()}
          {renderBaseQuote(quote)}

          {renderRevisionRequester(quote.requestedRevisionBy)}
        </GridContainer>
      )}
      {/* Case of creation */}
      {formik && reviseMode == ReviseMode.None && (!quote || isEditable) && !!shoppingCart && (
        <div>
          <FlexRow>{renderCompanyLabelWithText(selectedCompany?.companyDisplayName ?? "")}</FlexRow>
          <FlexRow>
            <FlexColumn>
              <div>
                <InputLabel label={t("Quote receiver email")} isRequired />
                <Input
                  value={formik.values.quoteReceiverEmail}
                  dataType={"text"}
                  validationResult={{
                    valid:
                      !!formik.errors.quoteReceiverEmail && !!formik.touched.quoteReceiverEmail
                        ? false
                        : true,
                    text: formik.errors.quoteReceiverEmail
                  }}
                  showValidationBarWhenInvalid
                  showValidationIconWhenInvalid
                  inputAttributes={{
                    name: "quoteReceiverEmail",
                    onChange: formik.handleChange
                  }}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={t("Expected order date")} isRequired />
                <DatePickerInput
                  label=""
                  value={formik.values.expectedOrderDate}
                  type="normal"
                  validationResult={{
                    valid:
                      !!formik.errors.expectedOrderDate && !!formik.touched.expectedOrderDate
                        ? false
                        : true,
                    text: formik.errors.expectedOrderDate
                  }}
                  showValidationIconWhenInvalid
                  onValueChange={(v: string) => {
                    handleFormikValueChange(formik, "expectedOrderDate", v, true);
                  }}
                  instantValidation
                />
              </div>
              <div>{renderValidityDate(formik.values.tenderValidityDate, false)}</div>
            </FlexColumn>
          </FlexRow>
        </div>
      )}
      {formik && reviseMode != ReviseMode.None && !!quote && !!shoppingCart && (
        <GridContainer>
          {renderQuoteReference(quote.reference)}
          {renderRequesterDetails(quote, true)}
          {renderCartStatus(quote)}
          {renderCreatorDetails(quote.quotedBy)}
          {renderRequestDate(quote.requestedDate)}

          {renderExpectedOrderDate(
            quote.frameAgreement ?? false,
            quote.expectedOrderDate ? formatDate(quote.expectedOrderDate, false) : ""
          )}
          <FlexRow>
            <FlexColumn>{renderValidityDateEditable(formik)}</FlexColumn>
          </FlexRow>
          {renderVisibility()}
          {renderBaseQuote(quote)}

          {renderRevisionRequester(quote.requestedRevisionBy)}
        </GridContainer>
      )}
    </ContentContainer>
  );
};

export default QuoteDetailsInfo;
