/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactSalesSupportReasons {
    Address = 'Address',
    Cancellation = 'Cancellation',
    CertificateRequest = 'CertificateRequest',
    DeliveryTimeChange = 'DeliveryTimeChange',
    Freeze = 'Freeze',
    HighPriority = 'HighPriority',
    Unfreeze = 'Unfreeze',
    Price = 'Price',
    ExportControl = 'ExportControl',
    Other = 'Other'
}

export function ContactSalesSupportReasonsFromJSON(json: any): ContactSalesSupportReasons {
    return ContactSalesSupportReasonsFromJSONTyped(json, false);
}

export function ContactSalesSupportReasonsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSalesSupportReasons {
    return json as ContactSalesSupportReasons;
}

export function ContactSalesSupportReasonsToJSON(value?: ContactSalesSupportReasons | null): any {
    return value as any;
}

