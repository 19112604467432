const colors = {
  blue100: "#031324",
  blue90: "#00284a",
  blue80: "#004480",
  blue70: "#005ca3",
  blue60: "#1b7ac2",
  blue50: "#00acec",
  blue40: "#2fc8f7",
  blue30: "#6cd5ff",
  blue20: "#b3e6ff",
  blue10: "#def4fa",
  purple100: "#240024",
  purple90: "#4a014a",
  purple80: "#7a097a",
  purple70: "#8f188f",
  purple60: "#a13fa1",
  purple50: "#cc50cc",
  purple40: "#e670e6",
  purple30: "#f291f2",
  purple20: "#fac5fa",
  purple10: "#fae3fa",
  magenta100: "#2e0016",
  magenta90: "#52032a",
  magenta80: "#820d48",
  magenta70: "#a8135d",
  magenta60: "#c2347b",
  magenta50: "#e64997",
  magenta40: "#ff6bb5",
  magenta30: "#ff94c9",
  magenta20: "#ffc7e4",
  magenta10: "#ffe3f0",
  teal100: "#001612",
  teal90: "#082823",
  teal80: "#16453d",
  teal70: "#195c50",
  teal60: "#3b786e",
  teal50: "#3da090",
  teal40: "#5abfae",
  teal30: "#7ed6c6",
  teal20: "#b4eae1",
  teal10: "#d7f7f3",
  green100: "#011900",
  green90: "#10290e",
  green80: "#1a4717",
  green70: "#1e6119",
  green60: "#477a43",
  green50: "#51a34b",
  green40: "#75ba70",
  green30: "#98d694",
  green20: "#bfedbb",
  green10: "#e2f7df",
  yellow100: "#111100",
  yellow90: "#232101",
  yellow80: "#3a3813",
  yellow70: "#524c00",
  yellow60: "#756e09",
  yellow50: "#968d0a",
  yellow40: "#b5aa0d",
  yellow30: "#d6c918",
  yellow20: "#ede56f",
  yellow10: "#faf5b6",
  gold100: "#1a0e00",
  gold90: "#331a02",
  gold80: "#542c04",
  gold70: "#753b00",
  gold60: "#a65503",
  gold50: "#c17120",
  gold40: "#d98f45",
  gold30: "#f2ac66",
  gold20: "#ffd3a8",
  gold10: "#ffeddb",
  orange100: "#290700",
  orange90: "#4d0d00",
  orange80: "#751a08",
  orange70: "#a82005",
  orange60: "#d93616",
  orange50: "#ed5739",
  orange40: "#f57c64",
  orange30: "#ff9f8c",
  orange20: "#ffcec4",
  orange10: "#ffeae8",
  red100: "#3e0000",
  red90: "#5c0006",
  red80: "#a30005",
  red70: "#cc0815",
  red60: "#e12e2e",
  red50: "#ff4754",
  red40: "#ff757e",
  red30: "#ff99a0",
  red20: "#ffc9cd",
  red10: "#ffe8e9",
  // colorgrey:
  grey100: "#0f0f0f",
  grey90: "#1f1f1f",
  grey80: "#333333",
  grey70: "#464646",
  grey60: "#696969",
  grey50: "#868686",
  grey40: "#9f9f9f",
  grey30: "#bababa",
  grey20: "#dbdbdb",
  grey10: "#ebebeb",
  // colorstatus:
  statusRed: "#f03040",
  statusMagenta: "#cb2bd5",
  statusOrange: "#ff7300",
  statusBlue: "#0052ff",
  statusYellow: "#ffd800",
  statusGreen: "#0ca919",
  // colorbrand:
  brandBlack: "#000000",
  brandRed: "#ff000f",
  brandWhite: "#ffffff",
  // colorwhite:
  whitePrimary: "#ffffff",
  whiteSecondary: "#fafafa",
  whiteTertiary: "#f5f5f5",
  // colorblack:
  blackPrimary: "#000000",
  blackSecondary: "#050505",
  blackTertiary: "#0a0a0a",
  // coloruiblue:
  uiBlue100: "#080261",
  uiBlue90: "#150c9b",
  uiBlue80: "#2c20d4",
  uiBlue70: "#2a35ff",
  uiBlue60: "#3366ff",
  uiBlue50: "#4c85ff",
  uiBlue40: "#77a3fc",
  uiBlue30: "#9ebef7",
  uiBlue20: "#c5dff9",
  uiBlue10: "#e0f0ff",
  opacities: {
    halfOpacityOnLight: "rgba(#333333, 0.5)",
    halfOpacityOnDark: "rgba(#0f0f0f, 0.5)"
  }
} as const;

const fonts = {
  families: {
    fontAbbRegular: "ABBVoice",
    fontAbbLight: "ABBVoice-light",
    fontAbbMedium: "ABBVoice-medium",
    fontAbbBold: "ABBVoice-bold",
    fontIconS: "ABB-UI-Icons-16x16",
    fontIconM: "ABB-UI-Icons-24x24",
    fontIconL: "ABB-UI-Icons-32x32"
  },
  lineHeights: {
    lineHeightXxs: "12px",
    lineHeightS: "16px",
    lineHeightXs: "14px",
    lineHeightM: "20px",
    lineHeightMl: "24px",
    lineHeightL: "34px",
    lineHeightXl: "42px",
    lineHeightXxl: "56px"
  },
  sizes: {
    fontSizeXxs: "10px",
    fontSizeXs: "12px",
    fontSizeS: "14px",
    fontSizeM: "16px",
    fontSizeLM: "20px",
    fontSizeL: "24px",
    fontSizeXl: "32px",
    fontSizeXxl: "40px",
    fontSize3xl: "54px"
  },
  weights: {
    normal: "400",
    heavy: "800"
  }
} as const;

const sizes = {
  xxs: "2px",
  xs: "4px",
  s: "8px",
  sm: "12px",
  m: "16px",
  lm: "24px",
  l: "32px",
  xl: "40px",
  xxl: "48px"
} as const;

const borders = {
  widths: {
    borderWidthS: "1px",
    borderWidthM: "2px",
    borderWidthL: "4px"
  },
  radiuses: {
    borderRadiusXxs: "2px",
    borderRadiusXs: "4px",
    borderRadiusS: "8px",
    borderRadiusM: "12px",
    borderRadiusL: "16px",
    borderRadiusXl: "20px",
    borderRadiusXxl: "24px"
  }
} as const;

const breakpoints = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1440px"
} as const;

const heights = {
  elementSmallHeight: "32px",
  elementMediumHeight: "40px",
  elementLargeHeight: "48px"
} as const;

const zIndices = {
  footerContent: 1,
  productComparisonTableHead: 10,
  productExplorerTabControlStickyHeader: 10,
  shoppingCartStickyContainer: 10,
  regularDropdown: 30,
  footerDisclaimer: 50,
  appHeader: 500,
  dialog: 1000,
  tooltip: 1050,
  notificationViewer: 9999
} as const;

const shadows = {
  shadowS: "0px 2px 2px",
  shadowM: "0px 2px 6px",
  shadowL: "0px 6px 12px",
  shadowXl: "0px 6px 18px"
} as const;

const indications = {
  indicationlineS: "1px",
  indicationlineM: "2px",
  indicationlineL: "4px"
} as const;

const fixedHeights = {
  notificationTop: "86px"
} as const;

export type CommonUXSize = keyof typeof sizes;

export interface GlobalStyle {
  colors: typeof colors;
  fonts: typeof fonts;
  sizes: typeof sizes;
  borders: typeof borders;
  breakpoints: typeof breakpoints;
  heights: typeof heights;
  zIndices: typeof zIndices;
  shadows: typeof shadows;
  indications: typeof indications;
  fixedHeights: typeof fixedHeights;
}

export const commonUXTheme: GlobalStyle = {
  colors: colors,
  fonts: fonts,
  sizes: sizes,
  borders: borders,
  breakpoints: breakpoints,
  heights: heights,
  zIndices: zIndices,
  shadows: shadows,
  indications: indications,
  fixedHeights: fixedHeights
};
