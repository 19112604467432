import { commonUXTheme } from "./commonUXVariables";
import { css } from "styled-components";

export const headerStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${commonUXTheme.colors.grey90};
    font-weight: 600;
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightMl};
    margin: 0;
  }

  h1 {
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeXl};
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightXl};
  }

  h2 {
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeL};
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightMl};
  }

  h3 {
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeLM};
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightM};
  }

  h4 {
    font-size: ${commonUXTheme.fonts.sizes.fontSizeM};
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
  }
  h5 {
    font-size: ${commonUXTheme.fonts.sizes.fontSizeS};
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
  }

  h6 {
    font-size: ${commonUXTheme.fonts.sizes.fontSizeXs};
    font-family: ${commonUXTheme.fonts.families.fontAbbBold};
  }

  strong {
    color: ${commonUXTheme.colors.blackPrimary};
    font-family: ${commonUXTheme.fonts.families.fontAbbMedium};
  }
`;
