import { css } from "styled-components";

export const popUpstyles = css`
  .popup-arrow {
    border: 0 transparent !important;
  }

  .popup-content {
    background-color: ${(props) => props.theme.colors.whitePrimary};
  }
`;
