/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TechDocArticleDto,
    TechDocArticleDtoFromJSON,
    TechDocArticleDtoFromJSONTyped,
    TechDocArticleDtoToJSON,
} from './TechDocArticleDto';

/**
 * 
 * @export
 * @interface TechDocSearchResponse
 */
export interface TechDocSearchResponse {
    /**
     * 
     * @type {Array<TechDocArticleDto>}
     * @memberof TechDocSearchResponse
     */
    response?: Array<TechDocArticleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof TechDocSearchResponse
     */
    totalResultsCount?: number;
}

export function TechDocSearchResponseFromJSON(json: any): TechDocSearchResponse {
    return TechDocSearchResponseFromJSONTyped(json, false);
}

export function TechDocSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechDocSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': !exists(json, 'response') ? undefined : (json['response'] === null ? null : (json['response'] as Array<any>).map(TechDocArticleDtoFromJSON)),
        'totalResultsCount': !exists(json, 'totalResultsCount') ? undefined : json['totalResultsCount'],
    };
}

export function TechDocSearchResponseToJSON(value?: TechDocSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response === undefined ? undefined : (value.response === null ? null : (value.response as Array<any>).map(TechDocArticleDtoToJSON)),
        'totalResultsCount': value.totalResultsCount,
    };
}

