import { RequestState, createInitialRequest } from "framework/state/requestState";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { orderSagas } from "applications/deliveries/orders/sagas/orderSagas";
import { produce } from "immer";
import { OrdersVm } from "../../../api/models/OrdersVm";
import { AppState } from "setup/appRootReducer";
import { RequestStatus } from "framework/state/requestStatus";
import {
  UserWidgetDto,
  ApiUsersIdUserWidgetsWidgetIdPatchRequest,
  ApiPricesPostRequest,
  PriceProductVm
} from "api";
import { dashboardSagas } from "../sagas/dashboardSagas";
import { DashboardActions, DashboardActionTypes } from "../actions/dashboardActions";
import { unionBy } from "lodash-es";

interface Requests {
  getPricesFromOrderingCodes: RequestState<PriceProductVm, ApiPricesPostRequest>;
  getRecentOrders: RequestState<OrdersVm>;
  updateUserWidget: RequestState<UserWidgetDto, ApiUsersIdUserWidgetsWidgetIdPatchRequest>;
  getCurrentUserWidgets: RequestState<UserWidgetDto[]>;
}

export interface DashboardViewState {
  recentOrders: undefined | OrdersVm;
  requests: Requests;
  widgets: UserWidgetDto[] | undefined;
}

const defaultState: DashboardViewState = {
  recentOrders: undefined,
  widgets: undefined,
  requests: {
    getPricesFromOrderingCodes: createInitialRequest(),
    getRecentOrders: createInitialRequest(),
    updateUserWidget: createInitialRequest(),
    getCurrentUserWidgets: createInitialRequest()
  }
};

export function dashboardViewReducer(
  state: DashboardViewState = defaultState,
  action: DashboardActions
): DashboardViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: orderSagas.getRecentOrders.actionTypes,
      key: "getRecentOrders"
    },
    {
      actionTypes: dashboardSagas.getCurrentUserWidgets.actionTypes,
      key: "getCurrentUserWidgets"
    },
    {
      actionTypes: dashboardSagas.updateUserWidget.actionTypes,
      key: "updateUserWidget"
    },
    {
      actionTypes: dashboardSagas.getPricesFromOrderingCodes.actionTypes,
      key: "getPricesFromOrderingCodes"
    }
  ]);

  if (orderSagas.getRecentOrders.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.recentOrders = action.payload;
    });
  } else if (dashboardSagas.getCurrentUserWidgets.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.widgets = action.payload;
    });
  } else if (dashboardSagas.updateUserWidget.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      const index = state.widgets?.findIndex((w) => w.id === action.payload.id);
      if (index !== undefined && index !== -1 && draft.widgets !== undefined) {
        draft.widgets[index] = action.payload;
      }
    });
  }

  switch (action.type) {
    case DashboardActionTypes.ToggleWidgetVisibility:
      state = produce(state, (draft) => {
        const index = state.widgets?.findIndex((w) => w.id === action.widgetId);
        if (index !== undefined && index !== -1 && draft.widgets !== undefined) {
          draft.widgets[index].visible = action.isVisible;
        }
      });
      break;
    case DashboardActionTypes.UpdateUserWidgets:
      state = produce(state, (draft) => {
        draft.widgets = unionBy(action.widgets, draft.widgets, "id");
      });
      break;
  }

  return state;
}

export const getRecentOrders = (state: AppState): OrdersVm | undefined =>
  state.dashboard.recentOrders;

export const getRecentOrdersStatus = (state: AppState): RequestStatus =>
  state.dashboard.requests.getRecentOrders.status;

export const getPricesFromOrderingCodesStatus = (state: AppState): RequestStatus =>
  state.dashboard.requests.getPricesFromOrderingCodes.status;

export const getWidgets = (state: AppState) =>
  state.dashboard.widgets?.map((w) => ({
    i: w.id,
    h: w.height,
    w: w.width,
    name: w.name,
    visible: w.visible
  }));
