import { useState, useEffect } from "react";
import { debounce } from "lodash-es";

export function useWindowWidth() {
  const [dimension, setDimension] = useState(window.innerWidth);

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setDimension(window.innerWidth);
    }, 100);
    window.addEventListener("resize", debouncedResizeHandler);
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, []);

  return dimension;
}
