import { AppContentWrapper } from "framework/components/AppContentWrapper";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductsCards } from "../components/productsCards";
import { CompareProductGrid } from "../components/CompareProductGrid";
import { getProducts } from "../reducers/productComparisonReducers";
import { useSelector } from "react-redux";
import { MainContainerComparator, ProductsCardsContainer } from "./StyledComponents";

export const maxElementInCompare = 3;
const ProductComparisonView = () => {
  const productsToCompare = useSelector(getProducts);
  const { t } = useTranslation();

  return (
    <AppContentWrapper isMainView showFooter pageTitle={t("Product comparator")}>
      <MainContainerComparator>
        <ProductsCardsContainer>
          <ProductsCards products={productsToCompare} maxElementInCompare={maxElementInCompare} />
        </ProductsCardsContainer>
        <CompareProductGrid
          products={productsToCompare}
          maxElementInCompare={maxElementInCompare}
        />
      </MainContainerComparator>
    </AppContentWrapper>
  );
};
export default ProductComparisonView;
