/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CountryContactInformation
 */
export interface CountryContactInformation {
    /**
     * 
     * @type {boolean}
     * @memberof CountryContactInformation
     */
    isCountryContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CountryContactInformation
     */
    isVisibleInSupportPages?: boolean | null;
}

export function CountryContactInformationFromJSON(json: any): CountryContactInformation {
    return CountryContactInformationFromJSONTyped(json, false);
}

export function CountryContactInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryContactInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isCountryContact': !exists(json, 'isCountryContact') ? undefined : json['isCountryContact'],
        'isVisibleInSupportPages': !exists(json, 'isVisibleInSupportPages') ? undefined : json['isVisibleInSupportPages'],
    };
}

export function CountryContactInformationToJSON(value?: CountryContactInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isCountryContact': value.isCountryContact,
        'isVisibleInSupportPages': value.isVisibleInSupportPages,
    };
}

