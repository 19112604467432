import React, { memo } from "react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";
import { Menu, MenuItem } from "@abb/abb-common-ux-react/components/Menu";
import { Icon, Popup, WithPopup } from "@abb/abb-common-ux-react";
import { useTranslation } from "react-i18next";
import { QuoteAttachmentDto } from "api";

export interface GridQuoteFilesActionsRendererProps extends ICellRendererParams {
  onDownload: (attachment: QuoteAttachmentDto) => void;
  onRemove: (attachment: QuoteAttachmentDto) => void;
  isRemovingEnabled: boolean;
  attachment: QuoteAttachmentDto;
}

const Container = styled.div<{ rowHeight: number | null | undefined }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  height: ${(props) => (props.rowHeight ? `${props.rowHeight}px` : "auto")};
`;

const CustomMenu = styled(Menu)`
  right: -10px !important;
  top: -10px !important;
  .ABB_CommonUX_Menu__menuItemTitle {
    margin-right: 16px;
  }
`;

export const GridQuoteFilesActionsRenderer = memo((props: GridQuoteFilesActionsRendererProps) => {
  const { t } = useTranslation();

  const menuItems: JSX.Element[] = [
    <MenuItem
      key={"abb/view"}
      text={t("View")}
      icon="abb/view"
      onSelect={() => {
        props.onDownload(props.attachment);
      }}
    ></MenuItem>
  ];

  if (props.isRemovingEnabled) {
    menuItems.push(
      <MenuItem
        key={"abb/trash"}
        text={t("Remove")}
        icon="abb/trash"
        onSelect={() => props.onRemove(props.attachment)}
      ></MenuItem>
    );
  }

  return (
    <WithPopup>
      <div>
        <Container rowHeight={props.node.rowHeight}>
          <Icon name={"abb/menu-narrow"} sizeClass="small" />
        </Container>
      </div>
      <Popup trigger="click" position={["left center"]} disablePaddings disableDefaultStyle>
        <CustomMenu isOpen={true} alignToParent="right">
          {menuItems}
        </CustomMenu>
      </Popup>
    </WithPopup>
  );
});
