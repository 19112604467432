import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const knowledgeWarehouseSagas = {
  getNodesRoots: createGenericSaga(
    "knowledgeWarehouseSagas/getNodesRoots",
    getApiRegistry().trainingApi.apiTrainingNodesRootsGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getNodesWithPath: createGenericSaga(
    "knowledgeWarehouseSagas/getNodesWithPath",
    getApiRegistry().trainingApi.apiTrainingNodesWithPathGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: true,
      onFail: defaultSagaErrorHandler
    }
  ),
  getArticleById: createGenericSaga(
    "knowledgeWarehouseSagas/getArticleById",
    getApiRegistry().trainingApi.apiTrainingArticleIdGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  sendFeedback: createGenericSaga(
    "knowledgeWarehouseSagas/sendFeedback",
    getApiRegistry().trainingApi.apiTrainingFeedbackPost.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Feedback sent"),
      onFail: defaultSagaErrorHandler
    }
  ),
  getAttachment: createGenericSaga(
    "knowledgeWarehouseSagas/getAttachment",
    getApiRegistry().trainingApi.apiTrainingAttachmentGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSearchInTechDoc: createGenericSaga(
    "knowledgeWarehouseSagas/getSearchInTechDoc",
    getApiRegistry().techDocApi.apiTechDocSearchGet.bind(getApiRegistry().techDocApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSearchArticles: createGenericSaga(
    "knowledgeWarehouseSagas/getSearchArticles",
    getApiRegistry().trainingApi.apiTrainingSearchArticlePost.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
