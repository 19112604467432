import { Action } from "redux";
import { AsyncRequest, RequestCompleted, RequestFailed } from "../actions/asyncActions";
import {
  updateWithPendingRequest,
  updateWithCompletedRequest,
  updateWithFailedRequest,
  createInitialRequest
} from "./requestState";

interface AsyncActionHandler<TRequestsState> {
  actionTypes: [string, string, string, string];
  key: keyof TRequestsState;
}

function handle<TRequests>(
  state: TRequests,
  action: Action,
  actions: [string, string, string, string],
  key: keyof TRequests
): TRequests {
  switch (action.type) {
    case actions[0]:
      return {
        ...(state as any),
        [key]: updateWithPendingRequest(action as AsyncRequest)
      };
    case actions[1]:
      return {
        ...(state as any),
        [key]: updateWithCompletedRequest(state[key] as any, action as RequestCompleted)
      };
    case actions[2]:
      return {
        ...(state as any),
        [key]: updateWithFailedRequest(state[key] as any, action as RequestFailed)
      };
    case actions[3]:
      return {
        ...(state as any),
        [key]: createInitialRequest()
      };
  }
  return state;
}

export function handleRequestActions<TState, TRequests>(
  state: TState,
  stateKey: keyof TState,
  action: Action,
  handlers: Array<AsyncActionHandler<TRequests>>
): TState {
  handlers.forEach((h) => {
    state = {
      ...(state as any),
      [stateKey]: handle(state[stateKey], action, h.actionTypes, h.key as any)
    };
  });

  return state;
}
