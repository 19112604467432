import {
  createGenericSaga,
  defaultSagaSuccessHandler,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const managePriceListsSagas = {
  getManagedPriceLists: createGenericSaga(
    "managePriceListsSagas/getManagedPriceLists",
    getApiRegistry().pricesApi.apiPricesPriceListsGet.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  deleteManagedPriceList: createGenericSaga(
    "managePriceListsSagas/deleteManagedPriceList",
    getApiRegistry().pricesApi.apiPricesManagedPriceListsIdDelete.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Price list removed"),
      onFail: (requestPayload) =>
        defaultSagaErrorHandler(requestPayload.id, "Deleting price list failed")
    }
  ),
  addOrUpdateManagedPriceList: createGenericSaga(
    "managePriceListsSagas/addOrUpdateManagedPriceList",
    getApiRegistry().pricesApi.apiPricesManagedPriceListsPost.bind(getApiRegistry().pricesApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Price list updated"),
      onFail: defaultSagaErrorHandler
    }
  )
};
