import { Icon } from "@abb/abb-common-ux-react/components/Icon";
import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { QuoteFilesDetailsGrid } from "./QuoteFilesDetailsGrid";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import { Button } from "framework/components/Button";
import { QuoteAttachmentDto } from "api";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeleteQuoteAttachmentStatus,
  getMaxQuoteFileCountUploaded,
  getMaxQuoteFileSizeInMb,
  getQuote,
  getQuoteAttachments,
  getUploadQuoteAttachmentsStatus,
  getGetQuoteByIdRequestStatus,
  getDraftQuoteFiles,
  getDraftQuoteAttachments
} from "applications/common/reducers/shoppingCartReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { shoppingCartActions } from "applications/common/actions/shoppingCartActions";
import { handleBlobFileDownload } from "utilities/fileDownload";
import { userActions } from "applications/common/actions/userActions";
import { RequestStatus } from "framework/state/requestStatus";
import { QuoteAttachmentTooltip } from "./QuoteAttachmentTooltip";
import { GridApi } from "ag-grid-community";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => props.theme.sizes.sm};
`;

const DropZone = styled.div<{ isDragActive?: boolean }>`
  background-color: ${(props) =>
    props.isDragActive ? props.theme.colors.grey20 : props.theme.colors.whitePrimary};
  flex-shrink: 0;
  width: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.theme.borders.widths.borderWidthS} dashed
    ${(props) => props.theme.colors.grey30} !important;
  padding: ${(props) => props.theme.sizes.m};
`;

const DropZoneContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.sizes.s};
`;

const DropZoneText = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  color: ${(props) => props.theme.colors.grey60};
`;

const CustomSectionHeader = styled(SectionHeader)`
  margin-bottom: ${(props) => props.theme.sizes.m};
  margin-top: ${(props) => props.theme.sizes.m};
`;

const ButtonPanelContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.sizes.m};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.sizes.xs};
  min-height: 20px;
  gap: ${(props) => props.theme.sizes.xs};
`;

const ErrorIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.statusRed};
`;

const ErrorText = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeM};
  color: ${(props) => props.theme.colors.brandRed};
`;

export interface QuoteAttachmentsUploaderProps {
  isUploadingEnabled: boolean;
  isRemovingEnabled: boolean;
  isRequestQuotationDialogMode: boolean; //means uploading files from RequestQuotationDialog where quote doesn't exist yet. Uploading quote attachments request will be initiated after RequestQuotation request
  isPrinting: boolean;
  reviseMode: boolean;
}

export const QuoteAttachmentsUploader = ({
  isUploadingEnabled,
  isRemovingEnabled,
  isPrinting,
  isRequestQuotationDialogMode,
  reviseMode
}: QuoteAttachmentsUploaderProps): JSX.Element => {
  //QuoteAttachments - objects which exist/will be exist in db, also is used as data for grid
  //QuoteFiles - Blob files that should be sent to server to save
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const quote = useSelector(getQuote);
  const quoteAttachments = useSelector(getQuoteAttachments);
  const uploadQuoteAttachmentsStatus = useSelector(getUploadQuoteAttachmentsStatus);
  const deleteQuoteAttachmentStatus = useSelector(getDeleteQuoteAttachmentStatus);
  const getQuoteByIdRequestStatus = useSelector(getGetQuoteByIdRequestStatus);
  const draftQuoteFiles = useSelector(getDraftQuoteFiles);
  const draftQuoteAttachments = useSelector(getDraftQuoteAttachments);
  const maxQuoteFileCountUploaded = useSelector(getMaxQuoteFileCountUploaded);
  const maxQuoteFileSizeInMb = useSelector(getMaxQuoteFileSizeInMb);
  const [newDraftQuoteAttachments, setNewDraftQuoteAttachments] = useState<QuoteAttachmentDto[]>([]); // is used while isRequestQuotationDialogMode === true, to add new attachments or if quote doesn't exist
  const [error, setError] = useState<string | null>();

  const [gridApi, setGridApi] = useState<GridApi>();

  const acceptedFileTypes = ["application/pdf"];

  useEffect(() => {
    if (getQuoteByIdRequestStatus === RequestStatus.Pending) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [getQuoteByIdRequestStatus, gridApi]);

  useEffect(() => {
    dispatch(shoppingCartActions.setDraftQuoteFiles([]));
  }, [dispatch]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (
        quoteAttachments.length + acceptedFiles.length > maxQuoteFileCountUploaded ||
        newDraftQuoteAttachments.length > maxQuoteFileCountUploaded
      ) {
        setError(
          t(
            "You cannot upload more than {{maxQuoteFileCountUploaded}} files with maximum size of {{maxQuoteFileSizeInMb}} MB.",
            { maxQuoteFileCountUploaded, maxQuoteFileSizeInMb }
          )
        );
      } else if (
        acceptedFiles.some(
          (f) =>
            quoteAttachments.some((qa) => qa.name === f.name) ||
            newDraftQuoteAttachments.some((qa) => qa.name === f.name)
        )
      ) {
        setError(t("You cannot upload files with the same name."));
      } else {
        if (acceptedFiles && acceptedFiles.length > 0) {
          const uploadedAttachments = acceptedFiles.map((f) => getQuoteAttachmentTemplate(f));
          if (isRequestQuotationDialogMode) {
            setNewDraftQuoteAttachments(newDraftQuoteAttachments.concat(uploadedAttachments));
          } else {
            dispatch(
              shoppingCartActions.setQuoteAttachments(quoteAttachments.concat(uploadedAttachments))
            );
          }

          dispatch(shoppingCartActions.setDraftQuoteFiles(draftQuoteFiles.concat(acceptedFiles)));
          setError(null);
        }

        if (fileRejections && fileRejections.length > 0) {
          const errors: string[] = [];
          fileRejections.forEach((fileRejection) => {
            if (fileRejection.errors.some((e) => e.code === "file-invalid-type")) {
              errors.push(t("Not valid file type."));
            }
          });
          const errorsStart = errors.length === 1 ? t("Error") : t("Errors");
          setError(errorsStart + ": " + errors.join(", "));
        }
      }
    },
    [
      dispatch,
      newDraftQuoteAttachments,
      draftQuoteFiles,
      isRequestQuotationDialogMode,
      maxQuoteFileCountUploaded,
      maxQuoteFileSizeInMb,
      quoteAttachments,
      t
    ]
  );

  const getQuoteAttachmentTemplate = (file: File): QuoteAttachmentDto => {
    return {
      identifier: "",
      name: file.name
    };
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: true,
    maxSize: maxQuoteFileSizeInMb * 1000000,
    disabled:
      quoteAttachments.length >= maxQuoteFileCountUploaded ||
      newDraftQuoteAttachments.length >= maxQuoteFileCountUploaded
  });

  const removeFile = (attachment: QuoteAttachmentDto) => {
    dispatch(
      userActions.addConfirmEvent(
        () => {
          if (attachment.identifier !== "" && quote && !reviseMode) {
            dispatch(
              shoppingCartSagas.deleteQuoteAttachment.createAction({
                id: quote.id,
                attachmentId: attachment.identifier
              })
            );
          } else {
            if (isRequestQuotationDialogMode) {
              setNewDraftQuoteAttachments(
                newDraftQuoteAttachments.filter((f) => f.name !== attachment.name)
              );
            } else {
              dispatch(
                shoppingCartActions.setQuoteAttachments(
                  quoteAttachments.filter((qa) => qa.name !== attachment.name)
                )
              );
            }
            dispatch(
              shoppingCartActions.setDraftQuoteFiles(
                draftQuoteFiles.filter((f) => f.name !== attachment.name)
              )
            );
          }
        },
        t("Are you sure you want to delete this file?"),
        attachment.name
      )
    );
  };

  const handleFileDownload = useCallback(
    (attachment: QuoteAttachmentDto) => {
      if (quote && !isRequestQuotationDialogMode) {
        if (attachment.identifier !== "") {
          dispatch(
            shoppingCartSagas.downloadQuoteAttachment.createAction({
              attachmentId: attachment.identifier,
              id: quote.id
            })
          );
        } else {
          const file = draftQuoteFiles.find((f) => f.name === attachment.name);
          if (file) {
            handleBlobFileDownload(file, file.name);
          } else {
            setError(`File ${attachment.name} was not uploaded.`);
          }
        }
      }
    },
    [dispatch, isRequestQuotationDialogMode, quote, draftQuoteFiles]
  );

  const saveAttachments = () => {
    if (quote) {
      dispatch(
        shoppingCartSagas.uploadQuoteAttachments.createAction({
          id: quote.id,
          files: draftQuoteFiles
        })
      );
    }
  };

  return isPrinting ? (
    quoteAttachments.length > 0 ? (
      <>
        <CustomSectionHeader text={t("Attachments:")}></CustomSectionHeader>
        <ul>
          {quoteAttachments.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      </>
    ) : (
      <></>
    )
  ) : (
    <Container>
      <CustomSectionHeader text={t("Attachments:")}></CustomSectionHeader>
      <QuoteFilesDetailsGrid
        t={t}
        isRemovingEnabled={isRemovingEnabled}
        attachments={isRequestQuotationDialogMode ? draftQuoteAttachments.concat(newDraftQuoteAttachments) : quoteAttachments}
        files={draftQuoteFiles}
        handleFileDownload={handleFileDownload}
        removeFile={removeFile}
        setGridApi={setGridApi}
        isPrinting={isPrinting}
      />

      {!isPrinting && isUploadingEnabled ? (
        <>
          <ButtonPanelContainer>
            <div>
              <div style={{ display: "flex", width: "auto" }}>
                <DropZone
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  id={"dropzone"}
                  className="DropZone_Container"
                  style={
                    quoteAttachments.length >= maxQuoteFileCountUploaded ||
                    newDraftQuoteAttachments.length >= maxQuoteFileCountUploaded
                      ? { backgroundColor: theme.colors.grey10 }
                      : {}
                  }
                >
                  <input {...getInputProps()} />
                  <DropZoneContentWrapper>
                    <Icon name="abb/upload" sizeClass="small" />
                    <DropZoneText>
                      {t("Drop PDF files here to upload, or click to select")}
                    </DropZoneText>
                    <QuoteAttachmentTooltip
                      t={t}
                      tooltipText={t(
                        "You can upload up to {{maxQuoteFileCountUploaded}} unique files with maximum size of {{maxQuoteFileSizeInMb}} MB. Please convert files to PDF format before uploading.",
                        { maxQuoteFileCountUploaded, maxQuoteFileSizeInMb }
                      )}
                    >
                      <div style={{ marginLeft: theme.sizes.xs, marginTop: 0 }}>
                        <Icon name="abb/information-circle-1" />
                      </div>
                    </QuoteAttachmentTooltip>
                  </DropZoneContentWrapper>
                </DropZone>
              </div>
            </div>
            {!isRequestQuotationDialogMode && !reviseMode ? (
              <Button
                text={t("Upload")}
                buttonType="primary"
                icon="abb/upload"
                onClick={saveAttachments}
                disabled={draftQuoteFiles.length === 0}
                isLoading={
                  uploadQuoteAttachmentsStatus === RequestStatus.Pending ||
                  deleteQuoteAttachmentStatus === RequestStatus.Pending
                }
              />
            ) : null}
          </ButtonPanelContainer>
          <ErrorWrapper>
            {error ? (
              <>
                <ErrorIcon name="abb/error-circle-1" />
                <ErrorText>{error}</ErrorText>
              </>
            ) : null}
          </ErrorWrapper>
        </>
      ) : null}
    </Container>
  );
};
