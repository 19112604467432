import React from "react";
import styled from "styled-components";
import { NotificationInformation, NotificationButton } from "../../models/notificationInformation";
import { NotificationContainer } from "@abb/abb-common-ux-react/components/Notification";
import { commonUXTheme } from "styles/commonUXVariables";

export interface NotificationViewerProps {
  notifications: NotificationInformation[];
  onRemoveNotification(id: string): void;
  translateText?: (text: string) => string;
}
// The NotificationContainer component from the Common UX library has to be placed "manually" which is done in this div.
const NotificationArea = styled.div`
  position: fixed;
  top: ${(props) => props.theme.fixedHeights.notificationTop};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  z-index: ${commonUXTheme.zIndices.notificationViewer};
  width: 100%;
  pointer-events: none;
  .ABB_CommonUX_Notification__text {
    margin-right: ${(props) => props.theme.sizes.s} !important;
    white-space: pre-wrap;
  }
  .ABB_CommonUX_Notification__root {
    max-width: 700px;
  }
  .ABB_CommonUX_NotificationContainer__root {
    position: relative !important;
  }
  .ABB_CommonUX_Notification__root.ABB_CommonUX_Notification__banner > div:first-child {
    text-align: start;
  }
`;

const NotificationViewer: React.FunctionComponent<NotificationViewerProps> = (props) => {
  const handleTranslation = (text: string) => {
    return props.translateText ? props.translateText(text) : text;
  };

  return (
    <NotificationArea>
      <NotificationContainer
        notifications={props.notifications.map((notification) => {
          return {
            id: notification.id,
            type: notification.isConfirmation ? "confirmation" : "banner",
            discreet: false,
            severity: notification.severity,
            timeout: notification.timeout ? notification.timeout : 60000,
            text: notification.customContent ? (
              <>{notification.customContent}</>
            ) : notification.messageDetail ? (
              <div>
                <b>
                  {notification.skipTitleTranslation
                    ? notification.message
                    : handleTranslation(notification.message)}
                </b>
                <p>
                  {notification.skipDetailTranslation
                    ? notification.messageDetail
                    : handleTranslation(notification.messageDetail)}
                </p>
              </div>
            ) : (
              handleTranslation(notification.message)
            ),
            buttons: formButtons(
              props.onRemoveNotification,
              props.translateText,
              notification.buttons
            )
          };
        })}
        actionHandler={{
          remove: props.onRemoveNotification
        }}
      />
    </NotificationArea>
  );
};

// Button array in CommonUX is defined as a tupple. Therefore, the hack below.
// This makes it possible to add extra buttons. Close button is always defined and is always primary.
function formButtons(
  closeNotificationHandler: (id: string) => void,
  translate?: (text: string) => string,
  extraButtons?: NotificationButton[]
) {
  const getTranslation = (text: string) => (translate ? translate(text) : text);
  const buttonTuple: [
    {
      text: string;
      primary?: boolean;
      handler: (notificationId: string) => void;
    }
  ] = [
    {
      text: getTranslation("Close"),
      primary: true,
      handler: closeNotificationHandler
    }
  ];

  if (extraButtons) {
    extraButtons.forEach((b) => {
      buttonTuple.unshift({
        text: b.text,
        primary: false,
        handler: b.onClick
      });
    });
  }
  return buttonTuple;
}

export default NotificationViewer;
