/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleDto,
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';

/**
 * 
 * @export
 * @interface RoleGroupRolesDto
 */
export interface RoleGroupRolesDto {
    /**
     * 
     * @type {RoleGroups}
     * @memberof RoleGroupRolesDto
     */
    id: RoleGroups;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupRolesDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupRolesDto
     */
    friendlyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGroupRolesDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleGroupRolesDto
     */
    globalRoles: Array<RoleDto>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleGroupRolesDto
     */
    companyRoles: Array<RoleDto>;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof RoleGroupRolesDto
     */
    teamRoles: Array<RoleDto>;
    /**
     * 
     * @type {boolean}
     * @memberof RoleGroupRolesDto
     */
    canEdit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleGroupRolesDto
     */
    isAccessRequestTarget: boolean;
}

export function RoleGroupRolesDtoFromJSON(json: any): RoleGroupRolesDto {
    return RoleGroupRolesDtoFromJSONTyped(json, false);
}

export function RoleGroupRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleGroupRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': RoleGroupsFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'friendlyName': !exists(json, 'friendlyName') ? undefined : json['friendlyName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'globalRoles': ((json['globalRoles'] as Array<any>).map(RoleDtoFromJSON)),
        'companyRoles': ((json['companyRoles'] as Array<any>).map(RoleDtoFromJSON)),
        'teamRoles': ((json['teamRoles'] as Array<any>).map(RoleDtoFromJSON)),
        'canEdit': json['canEdit'],
        'isAccessRequestTarget': json['isAccessRequestTarget'],
    };
}

export function RoleGroupRolesDtoToJSON(value?: RoleGroupRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': RoleGroupsToJSON(value.id),
        'name': value.name,
        'friendlyName': value.friendlyName,
        'description': value.description,
        'globalRoles': ((value.globalRoles as Array<any>).map(RoleDtoToJSON)),
        'companyRoles': ((value.companyRoles as Array<any>).map(RoleDtoToJSON)),
        'teamRoles': ((value.teamRoles as Array<any>).map(RoleDtoToJSON)),
        'canEdit': value.canEdit,
        'isAccessRequestTarget': value.isAccessRequestTarget,
    };
}

