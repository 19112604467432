import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const orderSagas = {
  getOrders: createGenericSaga(
    "orderSagas/getOrders",
    getApiRegistry().orderApi.apiOrderGet.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getRecentOrders: createGenericSaga(
    "orderSagas/getRecentOrders",
    getApiRegistry().orderApi.apiOrderGet.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getOrderdetails: createGenericSaga(
    "orderSagas/getOrderDtails",
    getApiRegistry().orderApi.apiOrderDetailsGet.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  sendSalesSupport: createGenericSaga(
    "orderSagas/sendSalesSupport",
    getApiRegistry().orderApi.apiOrderSalesSupportRequestPost.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onSuccess: defaultSagaSuccessHandler("Message sent"),
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadShippingDetailFiles: createGenericSaga(
    "orderSagas/downloadShippingDetailFiles",
    getApiRegistry().orderApi.apiOrderShippingDetailFilesGetRaw.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  downloadOrderItemFiles: createGenericSaga(
    "orderSagas/downloadOrderItemFiles",
    getApiRegistry().orderApi.apiOrderOrderItemFilesPostRaw.bind(getApiRegistry().orderApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getOrderMetaData: createGenericSaga(
    "orderSagas/getOrderMetaData",
    getApiRegistry().orderApi.apiOrderMetaDataGet.bind(getApiRegistry().orderApi)
  )
};
