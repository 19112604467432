import { getConfiguratorBaseUrl } from "applications/common/reducers/commonReducer";
import { RadioGroupSelectionDialog } from "framework/components/RadioGroupSelectionDialog";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getConfiguratorLink } from "utilities/urlUtils";
import { getSsc600Settings } from "../productSelectionMatrix/reducers/productSelectionMatrixReducer";
import { productSelectionMatrixSagas } from "../productSelectionMatrix/sagas/productSelectionMatrixSagas";
interface SSC600SelectionDialogProps {
  modularProductsMode: boolean;
  isOpen: boolean;
  onDialogClose: () => void;
}

export const SSC600SelectionDialog = (props: SSC600SelectionDialogProps) => {
  const configuratorBaseUrl = useSelector(getConfiguratorBaseUrl);
  const ssc600Settings = useSelector(getSsc600Settings);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productSelectionMatrixSagas.getSSC600Settings.createAction(undefined));
  }, [dispatch]);

  return ssc600Settings ? (
    <RadioGroupSelectionDialog
      submitForm={(includeHardware: boolean) => {
        window.location.href = getConfiguratorLink(
          configuratorBaseUrl,
          (includeHardware
            ? ssc600Settings?.hardwareIncludedCode
            : ssc600Settings?.softwareOnlyCode) ?? "",
          props.modularProductsMode
        );
      }}
      isOpen={props.isOpen}
      closeDialog={() => props.onDialogClose()}
      t={t}
      text={{
        title: t("Confirm configuration"),
        radioGroupTitle: t(
          "Configure with hardware included (SSC600) or software only (SSC600 SW)?"
        ),
        firstOptionLabel: ssc600Settings?.hardwareIncludedLabel ?? "",
        secondOptionLabel: ssc600Settings?.softwareOnlyLabel ?? "",
        buttonName: t("Submit")
      }}
    />
  ) : (
    <></>
  );
};
