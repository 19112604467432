import { utilStyles } from "./utilStyles";
import { popUpstyles } from "./popUpStyles";
import { dialogStyles } from "./dialogStyles";
import { linkStyles } from "./linkStyles";
import { commonUXStyles } from "./commonUXStyles";
import { createGlobalStyle } from "styled-components";
import { markdownStyles } from "./markdownStyles";

export const CssStyles = createGlobalStyle`
${utilStyles}
${popUpstyles}
${dialogStyles}
${linkStyles}
${commonUXStyles}
${markdownStyles}
`;
