/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';
import {
    ManageUserRoleDto,
    ManageUserRoleDtoFromJSON,
    ManageUserRoleDtoFromJSONTyped,
    ManageUserRoleDtoToJSON,
} from './ManageUserRoleDto';

/**
 * 
 * @export
 * @interface UserCompanyRolesDto
 */
export interface UserCompanyRolesDto {
    /**
     * 
     * @type {CompanyDto}
     * @memberof UserCompanyRolesDto
     */
    company: CompanyDto;
    /**
     * 
     * @type {Array<ManageUserRoleDto>}
     * @memberof UserCompanyRolesDto
     */
    roles: Array<ManageUserRoleDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UserCompanyRolesDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCompanyRolesDto
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyRolesDto
     */
    systemMessage?: string | null;
}

export function UserCompanyRolesDtoFromJSON(json: any): UserCompanyRolesDto {
    return UserCompanyRolesDtoFromJSONTyped(json, false);
}

export function UserCompanyRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompanyRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'company': CompanyDtoFromJSON(json['company']),
        'roles': ((json['roles'] as Array<any>).map(ManageUserRoleDtoFromJSON)),
        'active': json['active'],
        'userId': json['userId'],
        'systemMessage': !exists(json, 'systemMessage') ? undefined : json['systemMessage'],
    };
}

export function UserCompanyRolesDtoToJSON(value?: UserCompanyRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company': CompanyDtoToJSON(value.company),
        'roles': ((value.roles as Array<any>).map(ManageUserRoleDtoToJSON)),
        'active': value.active,
        'userId': value.userId,
        'systemMessage': value.systemMessage,
    };
}

