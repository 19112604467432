import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useStateParams<T>(
  initialState: T,
  paramsName: string,
  serialize: (state: T) => string,
  deserialize: (state: string | null) => T,
  delay = 1000,
  comparator?: (v1: T, v2: T) => boolean
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const location = useLocation();
  const navigate = useNavigate();

  const search = new URLSearchParams(location.search);

  const existingValue = search.get(paramsName);
  const [state, setState] = useState<T>(
    existingValue != null ? deserialize(existingValue) : initialState
  );

  // from url to state
  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    const value = deserialize(existingValue);
    if ((comparator && !comparator(value, state)) || (!comparator && value != state)) {
      setState(value);
    }
    // eslint-disable-next-line
  }, [existingValue, deserialize]);

  // from state to url
  useEffect(() => {
    if (
      (comparator && !comparator(deserialize(existingValue ?? ""), state)) ||
      (!comparator && deserialize(existingValue ?? "") != state)
    ) {
      // to reduce the number of req
      const delayDebounceFn = setTimeout(() => {
        const searchParams = new URLSearchParams(location.search);
        const ser = serialize(state);
        ser ? searchParams.set(paramsName, ser) : searchParams.delete(paramsName);
        const pathname = location.pathname;
        navigate({ pathname, search: searchParams.toString() });
      }, delay);
      return () => clearTimeout(delayDebounceFn);
    }

    // eslint-disable-next-line
  }, [state]);

  return [state, setState];
}
