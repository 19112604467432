import { Input, Checkbox } from "@abb/abb-common-ux-react";
import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { DetailsRadioGroup, TrueOrFalse } from "./orderDetails/DetailsRadioGroup";
import * as Yup from "yup";
import { Dialog } from "framework/components/Dialog";

const CustomButton = styled(Button)`
  margin-left: auto;
  display: block;
  margin-bottom: ${(props) => props.theme.sizes.lm};
`;

const DialogContent = styled.div`
  min-height: 240px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  margin-block: ${(props) => props.theme.sizes.sm};
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

interface DialogText {
  title: string;
  radioGroupTitle: string;
  firstOptionLabel?: string;
  secondOptionLabel?: string;
  inputDescription: string;
  textDescription: string;
  buttonName1: string;
  buttonName2: string;
  isReversed?: boolean;
  checkboxLabel?: string;
}

interface ApprovalDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  t: TFunction;
  submitForm: (
    email: string | undefined,
    isBoth?: boolean,
    sendQuoteWithoutApproval?: boolean
  ) => void;
  isLoading: boolean;
  text: DialogText;
  forceApproval?: boolean;
  invalidApproverEmails?: string[];
}

export const emailValidationSchema = (invalidEmails: string[] = []) =>
  Yup.string()
    .email("Invalid email format")
    .notOneOf(invalidEmails, "This email is not allowed")
    .required("Mail is required");

export const ApprovalDialog = ({
  isOpen,
  closeDialog,
  t,
  submitForm,
  isLoading,
  text,
  forceApproval,
  invalidApproverEmails
}: ApprovalDialogProps): JSX.Element => {
  const [isApprovalRequired, setIsApprovalRequired] = useState<TrueOrFalse | undefined>();
  const [approverEmail, setApproverEmail] = useState<undefined | string>();
  const [checkboxValue, setCheckboxValue] = useState<false | boolean>();
  const [isValid, setIsValid] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (forceApproval) {
      setIsApprovalRequired("true");
    }
  }, [forceApproval]);

  return (
    <Dialog isOpen={isOpen} onClose={() => closeDialog()} showCloseButton title={t(text.title)}>
      <DialogContent>
        <DetailsRadioGroup
          t={t}
          title={t(text.radioGroupTitle)}
          value={isApprovalRequired}
          setValue={setIsApprovalRequired}
          firstOptionLabel={text.firstOptionLabel}
          secondOptionLabel={text.secondOptionLabel}
          isReversed={text.isReversed}
          forceApproval={forceApproval}
        />
        <div
          style={{
            marginBottom: theme.sizes.sm,
            marginTop: theme.sizes.sm,
            flex: 1,
            width: "100%",
            minHeight: "80px"
          }}
        >
          {isApprovalRequired === "true" ? (
            <FormRow>
              <Input
                style={{ width: "100%" }}
                value={approverEmail}
                dataType="text"
                label={t("Email")}
                description={t(text.inputDescription)}
                onValueChange={(v) => {
                  setApproverEmail(v);
                  try {
                    const isValid = emailValidationSchema(invalidApproverEmails ?? []).isValidSync(
                      v
                    );
                    setIsValid(isValid);
                  } catch (e) {
                    setIsValid(false);
                  }
                }}
                showValidationBarWhenInvalid
                instantValidation
                validationResult={isValid}
              />
            </FormRow>
          ) : null}
          {isApprovalRequired === "false" ? (
            // <SmallHeader text={t(text.description)} />
            <FormRow>
              <Text style={{ whiteSpace: "pre" }}>{t(text.textDescription)}</Text>
            </FormRow>
          ) : null}
          {isApprovalRequired === "true" && text.checkboxLabel ? (
            <FormRow>
              <Checkbox
                value={checkboxValue}
                label={t(text.checkboxLabel)}
                disabled={!isValid}
                onChange={() => {
                  setCheckboxValue(!checkboxValue);
                }}
                monochrome
              />
            </FormRow>
          ) : null}
        </div>
        {isApprovalRequired ? (
          <CustomButton
            text={isApprovalRequired === "true" ? t(text.buttonName1) : t(text.buttonName2)}
            buttonType="primary"
            onClick={() =>
              submitForm(
                isApprovalRequired === "true" ? approverEmail : "",
                isApprovalRequired === "true" && checkboxValue !== true,
                isApprovalRequired === "false"
              )
            }
            isLoading={isLoading}
            disabled={isApprovalRequired === "true" && !isValid}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
