/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRights,
    AccessRightsFromJSON,
    AccessRightsFromJSONTyped,
    AccessRightsToJSON,
} from './AccessRights';

/**
 * 
 * @export
 * @interface AccessRightDto
 */
export interface AccessRightDto {
    /**
     * 
     * @type {AccessRights}
     * @memberof AccessRightDto
     */
    id: AccessRights;
    /**
     * 
     * @type {string}
     * @memberof AccessRightDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRightDto
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof AccessRightDto
     */
    description?: string | null;
}

export function AccessRightDtoFromJSON(json: any): AccessRightDto {
    return AccessRightDtoFromJSONTyped(json, false);
}

export function AccessRightDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRightDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': AccessRightsFromJSON(json['id']),
        'name': json['name'],
        'friendlyName': json['friendlyName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AccessRightDtoToJSON(value?: AccessRightDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': AccessRightsToJSON(value.id),
        'name': value.name,
        'friendlyName': value.friendlyName,
        'description': value.description,
    };
}

