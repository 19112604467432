/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductSeriesDto
 */
export interface ProductSeriesDto {
    /**
     * 
     * @type {number}
     * @memberof ProductSeriesDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSeriesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSeriesDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductSeriesDto
     */
    sortOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSeriesDto
     */
    hasAccessibleProducts: boolean;
}

export function ProductSeriesDtoFromJSON(json: any): ProductSeriesDto {
    return ProductSeriesDtoFromJSONTyped(json, false);
}

export function ProductSeriesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSeriesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'sortOrder': !exists(json, 'sortOrder') ? undefined : json['sortOrder'],
        'hasAccessibleProducts': json['hasAccessibleProducts'],
    };
}

export function ProductSeriesDtoToJSON(value?: ProductSeriesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'imageUrl': value.imageUrl,
        'sortOrder': value.sortOrder,
        'hasAccessibleProducts': value.hasAccessibleProducts,
    };
}

