/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MenuItemDto,
    MenuItemDtoFromJSON,
    MenuItemDtoFromJSONTyped,
    MenuItemDtoToJSON,
} from './MenuItemDto';

/**
 * 
 * @export
 * @interface MenuBarVm
 */
export interface MenuBarVm {
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuBarVm
     */
    menuItems?: Array<MenuItemDto> | null;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuBarVm
     */
    userMenuItems?: Array<MenuItemDto> | null;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuBarVm
     */
    notificationItems?: Array<MenuItemDto> | null;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuBarVm
     */
    comparisonItems?: Array<MenuItemDto> | null;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuBarVm
     */
    shoppingCartItems?: Array<MenuItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof MenuBarVm
     */
    baseUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuBarVm
     */
    unreadNotificationsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuBarVm
     */
    shoppingCartItemsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuBarVm
     */
    logOutIdentifier: string;
}

export function MenuBarVmFromJSON(json: any): MenuBarVm {
    return MenuBarVmFromJSONTyped(json, false);
}

export function MenuBarVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuBarVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'menuItems': !exists(json, 'menuItems') ? undefined : (json['menuItems'] === null ? null : (json['menuItems'] as Array<any>).map(MenuItemDtoFromJSON)),
        'userMenuItems': !exists(json, 'userMenuItems') ? undefined : (json['userMenuItems'] === null ? null : (json['userMenuItems'] as Array<any>).map(MenuItemDtoFromJSON)),
        'notificationItems': !exists(json, 'notificationItems') ? undefined : (json['notificationItems'] === null ? null : (json['notificationItems'] as Array<any>).map(MenuItemDtoFromJSON)),
        'comparisonItems': !exists(json, 'comparisonItems') ? undefined : (json['comparisonItems'] === null ? null : (json['comparisonItems'] as Array<any>).map(MenuItemDtoFromJSON)),
        'shoppingCartItems': !exists(json, 'shoppingCartItems') ? undefined : (json['shoppingCartItems'] === null ? null : (json['shoppingCartItems'] as Array<any>).map(MenuItemDtoFromJSON)),
        'baseUrl': !exists(json, 'baseUrl') ? undefined : json['baseUrl'],
        'unreadNotificationsCount': !exists(json, 'unreadNotificationsCount') ? undefined : json['unreadNotificationsCount'],
        'shoppingCartItemsCount': !exists(json, 'shoppingCartItemsCount') ? undefined : json['shoppingCartItemsCount'],
        'logOutIdentifier': json['logOutIdentifier'],
    };
}

export function MenuBarVmToJSON(value?: MenuBarVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'menuItems': value.menuItems === undefined ? undefined : (value.menuItems === null ? null : (value.menuItems as Array<any>).map(MenuItemDtoToJSON)),
        'userMenuItems': value.userMenuItems === undefined ? undefined : (value.userMenuItems === null ? null : (value.userMenuItems as Array<any>).map(MenuItemDtoToJSON)),
        'notificationItems': value.notificationItems === undefined ? undefined : (value.notificationItems === null ? null : (value.notificationItems as Array<any>).map(MenuItemDtoToJSON)),
        'comparisonItems': value.comparisonItems === undefined ? undefined : (value.comparisonItems === null ? null : (value.comparisonItems as Array<any>).map(MenuItemDtoToJSON)),
        'shoppingCartItems': value.shoppingCartItems === undefined ? undefined : (value.shoppingCartItems === null ? null : (value.shoppingCartItems as Array<any>).map(MenuItemDtoToJSON)),
        'baseUrl': value.baseUrl,
        'unreadNotificationsCount': value.unreadNotificationsCount,
        'shoppingCartItemsCount': value.shoppingCartItemsCount,
        'logOutIdentifier': value.logOutIdentifier,
    };
}

