export function formatCurrency(price: number, currency: string): string {
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${currency}`;
}

export function priceStringToNumber(price: string | null | undefined): number {
  return Number(price?.replace(/\D/g, ""));
}
export function priceStringToFloatNumber(price: string | null | undefined): number {
  return parseFloat(price?.replace(/\s/g, "") ?? "");
}
