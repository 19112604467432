/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUpdateUserCommand
 */
export interface AdminUpdateUserCommand {
    /**
     * 
     * @type {number}
     * @memberof AdminUpdateUserCommand
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateUserCommand
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUserCommand
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateUserCommand
     */
    lastName?: string | null;
}

export function AdminUpdateUserCommandFromJSON(json: any): AdminUpdateUserCommand {
    return AdminUpdateUserCommandFromJSONTyped(json, false);
}

export function AdminUpdateUserCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateUserCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function AdminUpdateUserCommandToJSON(value?: AdminUpdateUserCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'active': value.active,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}

