/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemFromJSONTyped,
    CriteriaSortItemToJSON,
} from './CriteriaSortItem';

/**
 * 
 * @export
 * @interface PaginationReturnModel
 */
export interface PaginationReturnModel {
    /**
     * 
     * @type {string}
     * @memberof PaginationReturnModel
     */
    sortColumn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationReturnModel
     */
    isAscendingOrder?: boolean;
    /**
     * 
     * @type {Array<CriteriaSortItem>}
     * @memberof PaginationReturnModel
     */
    sortItems?: Array<CriteriaSortItem> | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationReturnModel
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationReturnModel
     */
    pageSize: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationReturnModel
     */
    skipTotalRowCount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaginationReturnModel
     */
    readonly querySortByColumn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationReturnModel
     */
    fromRow: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationReturnModel
     */
    toRow: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginationReturnModel
     */
    isLastPage: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginationReturnModel
     */
    totalCount: number;
}

export function PaginationReturnModelFromJSON(json: any): PaginationReturnModel {
    return PaginationReturnModelFromJSONTyped(json, false);
}

export function PaginationReturnModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationReturnModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sortColumn': !exists(json, 'sortColumn') ? undefined : json['sortColumn'],
        'isAscendingOrder': !exists(json, 'isAscendingOrder') ? undefined : json['isAscendingOrder'],
        'sortItems': !exists(json, 'sortItems') ? undefined : (json['sortItems'] === null ? null : (json['sortItems'] as Array<any>).map(CriteriaSortItemFromJSON)),
        'page': json['page'],
        'pageSize': json['pageSize'],
        'skipTotalRowCount': !exists(json, 'skipTotalRowCount') ? undefined : json['skipTotalRowCount'],
        'querySortByColumn': !exists(json, 'querySortByColumn') ? undefined : json['querySortByColumn'],
        'fromRow': json['fromRow'],
        'toRow': json['toRow'],
        'isLastPage': json['isLastPage'],
        'totalCount': json['totalCount'],
    };
}

export function PaginationReturnModelToJSON(value?: PaginationReturnModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sortColumn': value.sortColumn,
        'isAscendingOrder': value.isAscendingOrder,
        'sortItems': value.sortItems === undefined ? undefined : (value.sortItems === null ? null : (value.sortItems as Array<any>).map(CriteriaSortItemToJSON)),
        'page': value.page,
        'pageSize': value.pageSize,
        'skipTotalRowCount': value.skipTotalRowCount,
        'fromRow': value.fromRow,
        'toRow': value.toRow,
        'isLastPage': value.isLastPage,
        'totalCount': value.totalCount,
    };
}

