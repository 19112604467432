/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentUserCompanyRoleDto,
    CurrentUserCompanyRoleDtoFromJSON,
    CurrentUserCompanyRoleDtoFromJSONTyped,
    CurrentUserCompanyRoleDtoToJSON,
} from './CurrentUserCompanyRoleDto';
import {
    CurrentUserDto,
    CurrentUserDtoFromJSON,
    CurrentUserDtoFromJSONTyped,
    CurrentUserDtoToJSON,
} from './CurrentUserDto';
import {
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsFromJSONTyped,
    RoleGroupsToJSON,
} from './RoleGroups';
import {
    TimeZoneDto,
    TimeZoneDtoFromJSON,
    TimeZoneDtoFromJSONTyped,
    TimeZoneDtoToJSON,
} from './TimeZoneDto';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoFromJSONTyped,
    UserInfoToJSON,
} from './UserInfo';

/**
 * 
 * @export
 * @interface UserVm
 */
export interface UserVm {
    /**
     * 
     * @type {CurrentUserDto}
     * @memberof UserVm
     */
    userDetails?: CurrentUserDto;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserVm
     */
    userAccessRights: { [key: string]: Array<string>; };
    /**
     * 
     * @type {UserInfo}
     * @memberof UserVm
     */
    userInfo: UserInfo;
    /**
     * 
     * @type {Array<CurrentUserCompanyRoleDto>}
     * @memberof UserVm
     */
    userCompanyRoles: Array<CurrentUserCompanyRoleDto>;
    /**
     * 
     * @type {boolean}
     * @memberof UserVm
     */
    privacyConsentNeeded: boolean;
    /**
     * 
     * @type {TimeZoneDto}
     * @memberof UserVm
     */
    userTimeZone: TimeZoneDto;
    /**
     * 
     * @type {boolean}
     * @memberof UserVm
     */
    isExternalUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserVm
     */
    canAskDeliverItAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserVm
     */
    isCountryContact: boolean;
    /**
     * 
     * @type {Array<RoleGroups>}
     * @memberof UserVm
     */
    roleGroupsPendingAccessRequest: Array<RoleGroups>;
    /**
     * 
     * @type {boolean}
     * @memberof UserVm
     */
    isPricesBudgetary: boolean;
}

export function UserVmFromJSON(json: any): UserVm {
    return UserVmFromJSONTyped(json, false);
}

export function UserVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userDetails': !exists(json, 'userDetails') ? undefined : CurrentUserDtoFromJSON(json['userDetails']),
        'userAccessRights': json['userAccessRights'],
        'userInfo': UserInfoFromJSON(json['userInfo']),
        'userCompanyRoles': ((json['userCompanyRoles'] as Array<any>).map(CurrentUserCompanyRoleDtoFromJSON)),
        'privacyConsentNeeded': json['privacyConsentNeeded'],
        'userTimeZone': TimeZoneDtoFromJSON(json['userTimeZone']),
        'isExternalUser': json['isExternalUser'],
        'canAskDeliverItAccess': json['canAskDeliverItAccess'],
        'isCountryContact': json['isCountryContact'],
        'roleGroupsPendingAccessRequest': ((json['roleGroupsPendingAccessRequest'] as Array<any>).map(RoleGroupsFromJSON)),
        'isPricesBudgetary': json['isPricesBudgetary'],
    };
}

export function UserVmToJSON(value?: UserVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userDetails': CurrentUserDtoToJSON(value.userDetails),
        'userAccessRights': value.userAccessRights,
        'userInfo': UserInfoToJSON(value.userInfo),
        'userCompanyRoles': ((value.userCompanyRoles as Array<any>).map(CurrentUserCompanyRoleDtoToJSON)),
        'privacyConsentNeeded': value.privacyConsentNeeded,
        'userTimeZone': TimeZoneDtoToJSON(value.userTimeZone),
        'isExternalUser': value.isExternalUser,
        'canAskDeliverItAccess': value.canAskDeliverItAccess,
        'isCountryContact': value.isCountryContact,
        'roleGroupsPendingAccessRequest': ((value.roleGroupsPendingAccessRequest as Array<any>).map(RoleGroupsToJSON)),
        'isPricesBudgetary': value.isPricesBudgetary,
    };
}

