import {
  TeamsVm,
  ApiTeamsGetRequest,
  TeamDto,
  ApiTeamsIdGetRequest,
  TeamMemberDto,
  ApiTeamsChangeMemberStatusPostRequest,
  ApiTeamsChangeMemberRolePostRequest,
  ApiTeamsChangeMemberActivityPostRequest,
  ApiTeamsChangeMemberTeamInUsePostRequest,
  ApiTeamsUserTeamsGetRequest
} from "api";
import {
  createGenericSaga,
  CreateSagaOptions,
  defaultSagaErrorHandler,
  GenericSagaOptions,
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

interface ManageTeamsSagas {
  [index: string]: CreateSagaOptions<any, any>;
  getTeams: GenericSagaOptions<TeamsVm, ApiTeamsGetRequest>;
  getTeam: GenericSagaOptions<TeamDto, ApiTeamsIdGetRequest>;
  getUserTeams: GenericSagaOptions<Array<TeamDto>, ApiTeamsUserTeamsGetRequest>;
  changeTeamMemberStatus: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberStatusPostRequest>;
  changeTeamMemberRole: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberRolePostRequest>;
  changeTeamMemberActivity: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberActivityPostRequest>;
  changeTeamMemberTeamInUse: GenericSagaOptions<TeamMemberDto, ApiTeamsChangeMemberTeamInUsePostRequest>;
}

export const manageTeamsSagas: ManageTeamsSagas = {
  getTeam: createGenericSaga(
    "manageTeamsSagas/getTeam",
    getApiRegistry().teamsApi.apiTeamsIdGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getTeams: createGenericSaga(
    "manageTeamsSagas/getTeams",
    getApiRegistry().teamsApi.apiTeamsGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getUserTeams: createGenericSaga(
    "teamSagas/getUserTeams",
    getApiRegistry().teamsApi.apiTeamsUserTeamsGet.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberStatus: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberStatus",
    getApiRegistry().teamsApi.apiTeamsChangeMemberStatusPost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberRole: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberRole",
    getApiRegistry().teamsApi.apiTeamsChangeMemberRolePost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberActivity: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberActivity",
    getApiRegistry().teamsApi.apiTeamsChangeMemberActivityPost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  changeTeamMemberTeamInUse: createGenericSaga(
    "manageTeamsSagas/changeTeamMemberTeamInUse",
    getApiRegistry().teamsApi.apiTeamsChangeMemberTeamInUsePost.bind(getApiRegistry().teamsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
