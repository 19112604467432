/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplate
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmailTemplate
     */
    lastModified?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof EmailTemplate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    templateContent?: string | null;
}

export function EmailTemplateFromJSON(json: any): EmailTemplate {
    return EmailTemplateFromJSONTyped(json, false);
}

export function EmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModified': !exists(json, 'lastModified') ? undefined : (json['lastModified'] === null ? null : new Date(json['lastModified'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'templateContent': !exists(json, 'templateContent') ? undefined : json['templateContent'],
    };
}

export function EmailTemplateToJSON(value?: EmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified === null ? null : value.lastModified.toISOString()),
        'id': value.id,
        'name': value.name,
        'language': value.language,
        'templateContent': value.templateContent,
    };
}

