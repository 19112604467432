/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FrameAgreementDetails,
    FrameAgreementDetailsFromJSON,
    FrameAgreementDetailsFromJSONTyped,
    FrameAgreementDetailsToJSON,
} from './FrameAgreementDetails';
import {
    ProFormaItemDto,
    ProFormaItemDtoFromJSON,
    ProFormaItemDtoFromJSONTyped,
    ProFormaItemDtoToJSON,
} from './ProFormaItemDto';
import {
    ShoppingCartModificationItemDto,
    ShoppingCartModificationItemDtoFromJSON,
    ShoppingCartModificationItemDtoFromJSONTyped,
    ShoppingCartModificationItemDtoToJSON,
} from './ShoppingCartModificationItemDto';
import {
    SoftwareSubscriptionEmailRegistrationSentDto,
    SoftwareSubscriptionEmailRegistrationSentDtoFromJSON,
    SoftwareSubscriptionEmailRegistrationSentDtoFromJSONTyped,
    SoftwareSubscriptionEmailRegistrationSentDtoToJSON,
} from './SoftwareSubscriptionEmailRegistrationSentDto';

/**
 * 
 * @export
 * @interface ShoppingCartItemDto
 */
export interface ShoppingCartItemDto {
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartItemDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    orderingCode: string;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartItemDto
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    price?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    quotedPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    unitPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    unitQuotedPrice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartItemDto
     */
    unitQuotedPriceDecimal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    materialCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    plusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartItemDto
     */
    position?: number;
    /**
     * 
     * @type {ProFormaItemDto}
     * @memberof ShoppingCartItemDto
     */
    proFormaItem?: ProFormaItemDto;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    hasAccessories: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    softwarePurchaseEmail?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    isPriceValid?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    isValidProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    isModification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    hasPendingModification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    isSoftwareProduct?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingCartItemDto
     */
    isRenewalProduct?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    vendorOrderingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartItemDto
     */
    onlineDeliveryProductEmailHelpText?: string | null;
    /**
     * 
     * @type {Array<ShoppingCartModificationItemDto>}
     * @memberof ShoppingCartItemDto
     */
    shoppingCartModificationItems: Array<ShoppingCartModificationItemDto>;
    /**
     * 
     * @type {FrameAgreementDetails}
     * @memberof ShoppingCartItemDto
     */
    frameAgreementDetails?: FrameAgreementDetails;
    /**
     * 
     * @type {Array<SoftwareSubscriptionEmailRegistrationSentDto>}
     * @memberof ShoppingCartItemDto
     */
    isSoftwareProductEmailRegistration?: Array<SoftwareSubscriptionEmailRegistrationSentDto> | null;
}

export function ShoppingCartItemDtoFromJSON(json: any): ShoppingCartItemDto {
    return ShoppingCartItemDtoFromJSONTyped(json, false);
}

export function ShoppingCartItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'orderingCode': json['orderingCode'],
        'quantity': json['quantity'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'quotedPrice': !exists(json, 'quotedPrice') ? undefined : json['quotedPrice'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
        'unitQuotedPrice': !exists(json, 'unitQuotedPrice') ? undefined : json['unitQuotedPrice'],
        'unitQuotedPriceDecimal': !exists(json, 'unitQuotedPriceDecimal') ? undefined : json['unitQuotedPriceDecimal'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'plusCode': !exists(json, 'plusCode') ? undefined : json['plusCode'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'proFormaItem': !exists(json, 'proFormaItem') ? undefined : ProFormaItemDtoFromJSON(json['proFormaItem']),
        'hasAccessories': json['hasAccessories'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'softwarePurchaseEmail': !exists(json, 'softwarePurchaseEmail') ? undefined : json['softwarePurchaseEmail'],
        'isPriceValid': !exists(json, 'isPriceValid') ? undefined : json['isPriceValid'],
        'isValidProduct': !exists(json, 'isValidProduct') ? undefined : json['isValidProduct'],
        'isModification': !exists(json, 'isModification') ? undefined : json['isModification'],
        'hasPendingModification': !exists(json, 'hasPendingModification') ? undefined : json['hasPendingModification'],
        'isSoftwareProduct': !exists(json, 'isSoftwareProduct') ? undefined : json['isSoftwareProduct'],
        'isRenewalProduct': !exists(json, 'isRenewalProduct') ? undefined : json['isRenewalProduct'],
        'vendorOrderingCode': !exists(json, 'vendorOrderingCode') ? undefined : json['vendorOrderingCode'],
        'onlineDeliveryProductEmailHelpText': !exists(json, 'onlineDeliveryProductEmailHelpText') ? undefined : json['onlineDeliveryProductEmailHelpText'],
        'shoppingCartModificationItems': ((json['shoppingCartModificationItems'] as Array<any>).map(ShoppingCartModificationItemDtoFromJSON)),
        'frameAgreementDetails': !exists(json, 'frameAgreementDetails') ? undefined : FrameAgreementDetailsFromJSON(json['frameAgreementDetails']),
        'isSoftwareProductEmailRegistration': !exists(json, 'isSoftwareProductEmailRegistration') ? undefined : (json['isSoftwareProductEmailRegistration'] === null ? null : (json['isSoftwareProductEmailRegistration'] as Array<any>).map(SoftwareSubscriptionEmailRegistrationSentDtoFromJSON)),
    };
}

export function ShoppingCartItemDtoToJSON(value?: ShoppingCartItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orderingCode': value.orderingCode,
        'quantity': value.quantity,
        'price': value.price,
        'quotedPrice': value.quotedPrice,
        'unitPrice': value.unitPrice,
        'unitQuotedPrice': value.unitQuotedPrice,
        'unitQuotedPriceDecimal': value.unitQuotedPriceDecimal,
        'displayName': value.displayName,
        'materialCode': value.materialCode,
        'imageUrl': value.imageUrl,
        'plusCode': value.plusCode,
        'category': value.category,
        'compositionCode': value.compositionCode,
        'position': value.position,
        'proFormaItem': ProFormaItemDtoToJSON(value.proFormaItem),
        'hasAccessories': value.hasAccessories,
        'additionalInformation': value.additionalInformation,
        'softwarePurchaseEmail': value.softwarePurchaseEmail,
        'isPriceValid': value.isPriceValid,
        'isValidProduct': value.isValidProduct,
        'isModification': value.isModification,
        'hasPendingModification': value.hasPendingModification,
        'isSoftwareProduct': value.isSoftwareProduct,
        'isRenewalProduct': value.isRenewalProduct,
        'vendorOrderingCode': value.vendorOrderingCode,
        'onlineDeliveryProductEmailHelpText': value.onlineDeliveryProductEmailHelpText,
        'shoppingCartModificationItems': ((value.shoppingCartModificationItems as Array<any>).map(ShoppingCartModificationItemDtoToJSON)),
        'frameAgreementDetails': FrameAgreementDetailsToJSON(value.frameAgreementDetails),
        'isSoftwareProductEmailRegistration': value.isSoftwareProductEmailRegistration === undefined ? undefined : (value.isSoftwareProductEmailRegistration === null ? null : (value.isSoftwareProductEmailRegistration as Array<any>).map(SoftwareSubscriptionEmailRegistrationSentDtoToJSON)),
    };
}

