import { Link } from "@abb/abb-common-ux-react";
import { AppContentWrapper, StyledPageHeading } from "framework/components/AppContentWrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import LifecycleImageWebp from "assets/life-cycle-management-process.webp";
import LifecycleImageJpeg from "assets/life-cycle-management-process.jpg";
import {
  defaultLifecycleStatusCriteria,
  ProductLifecycleStatusGrid
} from "../components/ProductLifecycleStatusGrid";
import { useQuery } from "framework/hooks/useQuery";
import { ApiLifecycleManagementProductLifecycleStatusesGetRequest } from "api";
import { GridApi, IDatasource } from "ag-grid-community";
import { getApiRegistry } from "framework/state/apiRegistry";
import { apiGridErrorHandler } from "utilities/errorUtils";
import { SectionHeader } from "framework/components/styled/SectionHeader";
import { commonUXTheme } from "styles/commonUXVariables";
import { useNavigate } from "react-router-dom";
import { getSortModel } from "framework/components/grid/Grid";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { LifecycleProductSearchForm } from "../components/LifecycleProductSearchForm";
import { StatusBanner } from "framework/components/StatusBanner";

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-template-columns: 1fr;
  }
`;

const ImageContainer = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const LifecycleManagementView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const [keywordsWithoutResults, setKeywordsWithoutResults] = useState<string[] | null>(null);

  const queryParams = useQuery();
  const urlKeywords = useMemo(() => queryParams.getAll("keywords"), [queryParams]);

  const userInformation = useSelector(getUserInformation);

  const dataSource = useCallback(
    (criteria: ApiLifecycleManagementProductLifecycleStatusesGetRequest) => {
      let rowCount: number | undefined = undefined;
      return {
        getRows: (params) => {
          const page = Math.floor(params.startRow / criteria.criteriaPageSize);

          const api = getApiRegistry().lifecycleManagementApi;
          const sortModel = getSortModel(params.sortModel);

          const newCriteria = {
            ...criteria,
            criteriaSortColumn: sortModel?.sortColumn,
            criteriaIsAscendingOrder: sortModel?.isAsc,
            criteriaPage: page,
            criteriaSkipTotalRowCount: page !== 0 && rowCount !== undefined
          };

          gridApi?.showLoadingOverlay();

          api
            .apiLifecycleManagementProductLifecycleStatusesGet(newCriteria)
            .then((res) => {
              if (res.lifecycleStatuses.length === 0) {
                gridApi?.showNoRowsOverlay();
              } else {
                gridApi?.hideOverlay();
              }
              setKeywordsWithoutResults(res.keywordsWithoutResults);

              if (newCriteria.criteriaSkipTotalRowCount !== true) {
                rowCount = res.criteria.totalCount;
              }

              params.successCallback(res.lifecycleStatuses, res.criteria.totalCount);
            })
            .catch((err) => {
              gridApi?.hideOverlay();
              apiGridErrorHandler(err, dispatch);
              setKeywordsWithoutResults(null);
              params.failCallback();
            });
        }
      } as IDatasource;
    },
    [gridApi, dispatch]
  );

  const navigate = useNavigate();

  const onClickSearch = (keywords: string[]) => {
    navigate({
      search: keywords.length
        ? // Creates this: ?keywords=first&keywords=second&keywords=third
          "?" + new URLSearchParams(keywords.map((k) => ["keywords", k])).toString()
        : undefined
    });
  };

  useEffect(() => {
    // The data is (re)fetched on
    // - page load
    // - clicking the Search button (which calls `navigate`)
    // - changing company
    const newCriteria = {
      ...defaultLifecycleStatusCriteria,
      criteriaKeywords: urlKeywords
    };
    gridApi?.setDatasource(dataSource(newCriteria));
  }, [dataSource, gridApi, urlKeywords, userInformation?.selectedCompanyId]);

  const theme = useTheme();

  return (
    <AppContentWrapper
      sideBar={
        <LifecycleProductSearchForm
          onSearch={onClickSearch}
          initialKeywords={urlKeywords}
          showClearButton
          style={{
            padding: theme.sizes.lm,
            flexDirection: "column"
          }}
        />
      }
      sideBarTitle={t("Search form")}
    >
      <TopContainer>
        <div>
          <StyledPageHeading text={t("Life-cycle Management")} />
          <p>
            {t(
              "Life-cycle management (LCM) is the process that enables ABB to innovate and manage products and related services throughout the entire business life-cycle - effectively and efficiently. It is ABB's goal to protect our customers' investments beyond the life-cycle of the underlying platform products."
            )}
          </p>
          <Link href="https://new.abb.com/medium-voltage/service/medium-voltage-product-life-cycle-management">
            {t("Learn more about LCM (ABB.com)")}
          </Link>
        </div>
        <ImageContainer>
          <source srcSet={LifecycleImageWebp} type="image/webp" />
          <source srcSet={LifecycleImageJpeg} type="image/jpeg" />
          <img
            src={LifecycleImageJpeg}
            alt={t("The 4 life-cycle steps; Active, classic, limited and obsolete.")}
          />
        </ImageContainer>
      </TopContainer>
      {keywordsWithoutResults && keywordsWithoutResults.length > 0 && (
        <div>
          <StatusBanner
            headerComponent={<h3>{t("Life-cycle status not available")}</h3>}
            text={keywordsWithoutResults.join(", ")}
            status="warning"
            shape="box"
          />
        </div>
      )}
      <SectionHeader
        text={t("Product life-cycle status")}
        style={{ marginTop: commonUXTheme.sizes.m }}
      />
      <ProductLifecycleStatusGrid setGridApi={setGridApi} />
    </AppContentWrapper>
  );
};
