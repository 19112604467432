/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KnowledgeWarehouseTab,
    KnowledgeWarehouseTabFromJSON,
    KnowledgeWarehouseTabFromJSONTyped,
    KnowledgeWarehouseTabToJSON,
} from './KnowledgeWarehouseTab';

/**
 * 
 * @export
 * @interface GetSearchArticlesQuery
 */
export interface GetSearchArticlesQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSearchArticlesQuery
     */
    nodeIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetSearchArticlesQuery
     */
    query?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSearchArticlesQuery
     */
    loadNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSearchArticlesQuery
     */
    searchTraining?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSearchArticlesQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSearchArticlesQuery
     */
    pageNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSearchArticlesQuery
     */
    isSearch?: boolean;
    /**
     * 
     * @type {KnowledgeWarehouseTab}
     * @memberof GetSearchArticlesQuery
     */
    activeTab?: KnowledgeWarehouseTab;
}

export function GetSearchArticlesQueryFromJSON(json: any): GetSearchArticlesQuery {
    return GetSearchArticlesQueryFromJSONTyped(json, false);
}

export function GetSearchArticlesQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSearchArticlesQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeIds': !exists(json, 'nodeIds') ? undefined : json['nodeIds'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'loadNew': !exists(json, 'loadNew') ? undefined : json['loadNew'],
        'searchTraining': !exists(json, 'searchTraining') ? undefined : json['searchTraining'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'isSearch': !exists(json, 'isSearch') ? undefined : json['isSearch'],
        'activeTab': !exists(json, 'activeTab') ? undefined : KnowledgeWarehouseTabFromJSON(json['activeTab']),
    };
}

export function GetSearchArticlesQueryToJSON(value?: GetSearchArticlesQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeIds': value.nodeIds,
        'query': value.query,
        'loadNew': value.loadNew,
        'searchTraining': value.searchTraining,
        'pageSize': value.pageSize,
        'pageNumber': value.pageNumber,
        'isSearch': value.isSearch,
        'activeTab': KnowledgeWarehouseTabToJSON(value.activeTab),
    };
}

