/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    TeamMemberDto,
    TeamMemberDtoFromJSON,
    TeamMemberDtoFromJSONTyped,
    TeamMemberDtoToJSON,
} from './TeamMemberDto';

/**
 * 
 * @export
 * @interface TeamMembersVm
 */
export interface TeamMembersVm {
    /**
     * 
     * @type {Array<TeamMemberDto>}
     * @memberof TeamMembersVm
     */
    teamMembers: Array<TeamMemberDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof TeamMembersVm
     */
    criteria: PaginationReturnModel;
}

export function TeamMembersVmFromJSON(json: any): TeamMembersVm {
    return TeamMembersVmFromJSONTyped(json, false);
}

export function TeamMembersVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMembersVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamMembers': ((json['teamMembers'] as Array<any>).map(TeamMemberDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function TeamMembersVmToJSON(value?: TeamMembersVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamMembers': ((value.teamMembers as Array<any>).map(TeamMemberDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

