import {
  createGenericSaga,
  defaultSagaErrorHandler,
  SagaBuilder
} from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";
import { shoppingCartSagas } from "./shoppingCartSagas";

export const modificationSaleSagas = {
  createGmdModificationCase: createGenericSaga(
    "modificationSaleSagas/createGmdModificationCase",
    getApiRegistry().modificationSalesApi.apiModificationSalesCreateGmdModificationCasePost.bind(
      getApiRegistry().modificationSalesApi
    ),
    {
      takeEvery: false,
      onSuccess: (request, response) =>
        request.createGmdModificationCaseCommand
          ? new SagaBuilder()
              .dispatch(
                shoppingCartSagas.getShoppingCartById.createAction({
                  guid: response
                })
              )
              .build()()
          : undefined,
      onFail: defaultSagaErrorHandler
    }
  )
};
