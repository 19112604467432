import React from "react";
import { Button as AbbButton } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { Button } from "../../../framework/components/Button";
import { Dialog } from "framework/components/Dialog";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  min-height: 90px;
  height: 100%;
  width: 100%;
  min-width: 540px;
`;

// HorizontalElementContainer doesn't work inside the dialog for some reason(?)
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.sm};
  margin-block: ${(props) => props.theme.sizes.m};
`;

interface SoftwarePurchaseEmailDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  email: string;
  onConfirm: () => void;
}

export const SoftwarePurchaseEmailDialog = ({
  isOpen,
  setIsOpen,
  email,
  onConfirm
}: SoftwarePurchaseEmailDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog
      contentClassName="dialog-content overflow-auto"
      title={t("Email not registered")}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Container>
        <div>
          {t(
            "The email must be registered in myABB portal due to authentication requirement before proceeding to order.\n Do you want to send a request to register to the email: <email> ?"
          ).replace("<email>", email)}
        </div>
        <ButtonContainer>
          <AbbButton
            type="discreet-black"
            text={t("Cancel")}
            shape="pill"
            onClick={() => setIsOpen(false)}
          />
          <Button buttonType="primary" text={t("Send registration invite")} onClick={onConfirm} />
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};
