/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    FirmwareDetailsResponseDto,
    FirmwareDetailsResponseDtoFromJSON,
    FirmwareDetailsResponseDtoToJSON,
} from '../models';

export interface ApiFirmwareUpdatesExportPostRequest {
    serialNumbers?: Array<string>;
    fromSerialNumber?: string;
    toSerialNumber?: string;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiFirmwareUpdatesFirmwareDetailsGetRequest {
    serialNumbers?: Array<string>;
    fromSerialNumber?: string;
    toSerialNumber?: string;
}

export interface ApiFirmwareUpdatesFirmwareDownloadGetRequest {
    id: number;
}

/**
 * 
 */
export class FirmwareUpdatesApi extends runtime.BaseAPI {

    /**
     */
    async apiFirmwareUpdatesExportPostRaw(requestParameters: ApiFirmwareUpdatesExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.serialNumbers) {
            queryParameters['SerialNumbers'] = requestParameters.serialNumbers;
        }

        if (requestParameters.fromSerialNumber !== undefined) {
            queryParameters['FromSerialNumber'] = requestParameters.fromSerialNumber;
        }

        if (requestParameters.toSerialNumber !== undefined) {
            queryParameters['ToSerialNumber'] = requestParameters.toSerialNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFirmwareUpdatesExportPost(requestParameters: ApiFirmwareUpdatesExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFirmwareUpdatesExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDetailsGetRaw(requestParameters: ApiFirmwareUpdatesFirmwareDetailsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FirmwareDetailsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.serialNumbers) {
            queryParameters['SerialNumbers'] = requestParameters.serialNumbers;
        }

        if (requestParameters.fromSerialNumber !== undefined) {
            queryParameters['FromSerialNumber'] = requestParameters.fromSerialNumber;
        }

        if (requestParameters.toSerialNumber !== undefined) {
            queryParameters['ToSerialNumber'] = requestParameters.toSerialNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/firmware-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmwareDetailsResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDetailsGet(requestParameters: ApiFirmwareUpdatesFirmwareDetailsGetRequest, initOverrides?: RequestInit): Promise<FirmwareDetailsResponseDto> {
        const response = await this.apiFirmwareUpdatesFirmwareDetailsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDownloadGetRaw(requestParameters: ApiFirmwareUpdatesFirmwareDownloadGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFirmwareUpdatesFirmwareDownloadGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/FirmwareUpdates/firmware-download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFirmwareUpdatesFirmwareDownloadGet(requestParameters: ApiFirmwareUpdatesFirmwareDownloadGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFirmwareUpdatesFirmwareDownloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
