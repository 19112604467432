/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderLineDto
 */
export interface OrderLineDto {
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    orderAcknowledgementNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    item: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    deliveringPlant: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    itemCustomer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    vendorOrderingCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    customerOrderingCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineDto
     */
    quantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderLineDto
     */
    openQuantity?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderLineDto
     */
    shipped?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderLineDto
     */
    shippedPlanned?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    delayReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderLineDto
     */
    status?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLineDto
     */
    hasRoutineTestReports?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLineDto
     */
    hasSoftwareDocuments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLineDto
     */
    hasSerialNumbers?: boolean;
}

export function OrderLineDtoFromJSON(json: any): OrderLineDto {
    return OrderLineDtoFromJSONTyped(json, false);
}

export function OrderLineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLineDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderAcknowledgementNumber': json['orderAcknowledgementNumber'],
        'item': json['item'],
        'deliveringPlant': json['deliveringPlant'],
        'itemCustomer': !exists(json, 'itemCustomer') ? undefined : json['itemCustomer'],
        'vendorOrderingCode': json['vendorOrderingCode'],
        'customerOrderingCode': json['customerOrderingCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'openQuantity': !exists(json, 'openQuantity') ? undefined : json['openQuantity'],
        'shipped': !exists(json, 'shipped') ? undefined : (json['shipped'] === null ? null : new Date(json['shipped'])),
        'shippedPlanned': !exists(json, 'shippedPlanned') ? undefined : (json['shippedPlanned'] === null ? null : new Date(json['shippedPlanned'])),
        'delayReason': !exists(json, 'delayReason') ? undefined : json['delayReason'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'hasRoutineTestReports': !exists(json, 'hasRoutineTestReports') ? undefined : json['hasRoutineTestReports'],
        'hasSoftwareDocuments': !exists(json, 'hasSoftwareDocuments') ? undefined : json['hasSoftwareDocuments'],
        'hasSerialNumbers': !exists(json, 'hasSerialNumbers') ? undefined : json['hasSerialNumbers'],
    };
}

export function OrderLineDtoToJSON(value?: OrderLineDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'item': value.item,
        'deliveringPlant': value.deliveringPlant,
        'itemCustomer': value.itemCustomer,
        'vendorOrderingCode': value.vendorOrderingCode,
        'customerOrderingCode': value.customerOrderingCode,
        'description': value.description,
        'quantity': value.quantity,
        'openQuantity': value.openQuantity,
        'shipped': value.shipped === undefined ? undefined : (value.shipped === null ? null : value.shipped.toISOString()),
        'shippedPlanned': value.shippedPlanned === undefined ? undefined : (value.shippedPlanned === null ? null : value.shippedPlanned.toISOString()),
        'delayReason': value.delayReason,
        'status': value.status,
        'hasRoutineTestReports': value.hasRoutineTestReports,
        'hasSoftwareDocuments': value.hasSoftwareDocuments,
        'hasSerialNumbers': value.hasSerialNumbers,
    };
}

