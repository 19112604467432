/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RoleGroupAccessRightsDto,
    RoleGroupAccessRightsDtoFromJSON,
    RoleGroupAccessRightsDtoToJSON,
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsToJSON,
} from '../models';

export interface ApiAccessRightsGetRequest {
    criteriaRoleGroups?: Array<RoleGroups>;
    criteriaIncludeManageOnlyRoles?: boolean;
    criteriaUserIdToEdit?: string;
}

/**
 * 
 */
export class AccessRightsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccessRightsGetRaw(requestParameters: ApiAccessRightsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RoleGroupAccessRightsDto>>> {
        const queryParameters: any = {};

        if (requestParameters.criteriaRoleGroups) {
            queryParameters['Criteria.RoleGroups'] = requestParameters.criteriaRoleGroups;
        }

        if (requestParameters.criteriaIncludeManageOnlyRoles !== undefined) {
            queryParameters['Criteria.IncludeManageOnlyRoles'] = requestParameters.criteriaIncludeManageOnlyRoles;
        }

        if (requestParameters.criteriaUserIdToEdit !== undefined) {
            queryParameters['Criteria.UserIdToEdit'] = requestParameters.criteriaUserIdToEdit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/AccessRights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleGroupAccessRightsDtoFromJSON));
    }

    /**
     */
    async apiAccessRightsGet(requestParameters: ApiAccessRightsGetRequest, initOverrides?: RequestInit): Promise<Array<RoleGroupAccessRightsDto>> {
        const response = await this.apiAccessRightsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
