import MarkdownIt from "markdown-it";

const headingStyleClasses =
  "ABB_CommonUX_Heading__root ABB_CommonUX_Heading__light ABB_CommonUX_Heading__withCursor";
class MarkdownParser {
  parser: MarkdownIt;

  constructor(injectCommonUxClassForHeadings?: boolean) {
    this.parser = new MarkdownIt();
    if (injectCommonUxClassForHeadings) {
      this.parser.renderer.rules.heading_open = (tokens, i) => {
        const token = tokens[i];
        const str = `<${token.tag} class='${headingStyleClasses}'>`;
        return str;
      };
    }
  }

  getParser(): MarkdownIt {
    return this.parser;
  }
}

export default MarkdownParser;
