/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationNotificationOperations {
    Added = 'Added',
    Removed = 'Removed',
    MarkedIsRead = 'MarkedIsRead',
    MarkedUnread = 'MarkedUnread'
}

export function ApplicationNotificationOperationsFromJSON(json: any): ApplicationNotificationOperations {
    return ApplicationNotificationOperationsFromJSONTyped(json, false);
}

export function ApplicationNotificationOperationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationNotificationOperations {
    return json as ApplicationNotificationOperations;
}

export function ApplicationNotificationOperationsToJSON(value?: ApplicationNotificationOperations | null): any {
    return value as any;
}

