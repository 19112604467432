/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureDto,
    FeatureDtoFromJSON,
    FeatureDtoFromJSONTyped,
    FeatureDtoToJSON,
} from './FeatureDto';
import {
    SubCategoryDto,
    SubCategoryDtoFromJSON,
    SubCategoryDtoFromJSONTyped,
    SubCategoryDtoToJSON,
} from './SubCategoryDto';

/**
 * 
 * @export
 * @interface CategoriesMenuDto
 */
export interface CategoriesMenuDto {
    /**
     * 
     * @type {string}
     * @memberof CategoriesMenuDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesMenuDto
     */
    name: string;
    /**
     * 
     * @type {Array<FeatureDto>}
     * @memberof CategoriesMenuDto
     */
    features: Array<FeatureDto>;
    /**
     * 
     * @type {Array<SubCategoryDto>}
     * @memberof CategoriesMenuDto
     */
    subCategories: Array<SubCategoryDto>;
}

export function CategoriesMenuDtoFromJSON(json: any): CategoriesMenuDto {
    return CategoriesMenuDtoFromJSONTyped(json, false);
}

export function CategoriesMenuDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoriesMenuDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'features': ((json['features'] as Array<any>).map(FeatureDtoFromJSON)),
        'subCategories': ((json['subCategories'] as Array<any>).map(SubCategoryDtoFromJSON)),
    };
}

export function CategoriesMenuDtoToJSON(value?: CategoriesMenuDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'features': ((value.features as Array<any>).map(FeatureDtoToJSON)),
        'subCategories': ((value.subCategories as Array<any>).map(SubCategoryDtoToJSON)),
    };
}

