import { Action } from "redux";

export enum TrainingActionType {
  CurrentAction = "TrainingActionType/CurrentAction",
  LoadSelectedFreeArticles = "TrainingActionType/LoadSelectedFreeArticles"
}

export enum SupportAction {
  showingNode,
  showingArticle,
  showingSearch,
  showingNew
}

export interface CurrentAction extends Action {
  type: TrainingActionType.CurrentAction;
  action: SupportAction | undefined;
}
export interface LoadSelectedFreeArticles extends Action {
  type: TrainingActionType.LoadSelectedFreeArticles;
  loading: boolean;
}
export const TrainingActions = {
  action: (action: SupportAction | undefined): CurrentAction => ({
    type: TrainingActionType.CurrentAction,
    action
  }),
  LoadSelectedFreeArticles: (loading: boolean): LoadSelectedFreeArticles => ({
    type: TrainingActionType.LoadSelectedFreeArticles,
    loading
  })
};

export type TrainingActions = CurrentAction | LoadSelectedFreeArticles;
