/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserManagedPriceListDto
 */
export interface UserManagedPriceListDto {
    /**
     * 
     * @type {number}
     * @memberof UserManagedPriceListDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserManagedPriceListDto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof UserManagedPriceListDto
     */
    countryName: string;
    /**
     * 
     * @type {string}
     * @memberof UserManagedPriceListDto
     */
    companyDisplayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserManagedPriceListDto
     */
    isDisabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserManagedPriceListDto
     */
    source: string;
}

export function UserManagedPriceListDtoFromJSON(json: any): UserManagedPriceListDto {
    return UserManagedPriceListDtoFromJSONTyped(json, false);
}

export function UserManagedPriceListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserManagedPriceListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'countryName': json['countryName'],
        'companyDisplayName': json['companyDisplayName'],
        'isDisabled': json['isDisabled'],
        'source': json['source'],
    };
}

export function UserManagedPriceListDtoToJSON(value?: UserManagedPriceListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'countryName': value.countryName,
        'companyDisplayName': value.companyDisplayName,
        'isDisabled': value.isDisabled,
        'source': value.source,
    };
}

