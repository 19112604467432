/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetStatusDeliveryFailedJustInTimeFileCommand
 */
export interface ResetStatusDeliveryFailedJustInTimeFileCommand {
    /**
     * 
     * @type {string}
     * @memberof ResetStatusDeliveryFailedJustInTimeFileCommand
     */
    identifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetStatusDeliveryFailedJustInTimeFileCommand
     */
    reloadLicense?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetStatusDeliveryFailedJustInTimeFileCommand
     */
    serialNumber?: string | null;
}

export function ResetStatusDeliveryFailedJustInTimeFileCommandFromJSON(json: any): ResetStatusDeliveryFailedJustInTimeFileCommand {
    return ResetStatusDeliveryFailedJustInTimeFileCommandFromJSONTyped(json, false);
}

export function ResetStatusDeliveryFailedJustInTimeFileCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetStatusDeliveryFailedJustInTimeFileCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'reloadLicense': !exists(json, 'reloadLicense') ? undefined : json['reloadLicense'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
    };
}

export function ResetStatusDeliveryFailedJustInTimeFileCommandToJSON(value?: ResetStatusDeliveryFailedJustInTimeFileCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'reloadLicense': value.reloadLicense,
        'serialNumber': value.serialNumber,
    };
}

