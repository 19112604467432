/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManagedPriceListDto
 */
export interface ManagedPriceListDto {
    /**
     * 
     * @type {number}
     * @memberof ManagedPriceListDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedPriceListDto
     */
    multiplier?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    priceListId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    priceListDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    countryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    companyDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagedPriceListDto
     */
    countryName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedPriceListDto
     */
    isFallback?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedPriceListDto
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ManagedPriceListDto
     */
    validityDate?: Date | null;
}

export function ManagedPriceListDtoFromJSON(json: any): ManagedPriceListDto {
    return ManagedPriceListDtoFromJSONTyped(json, false);
}

export function ManagedPriceListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManagedPriceListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'multiplier': !exists(json, 'multiplier') ? undefined : json['multiplier'],
        'priceListId': !exists(json, 'priceListId') ? undefined : json['priceListId'],
        'priceListDisplayName': !exists(json, 'priceListDisplayName') ? undefined : json['priceListDisplayName'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'companyDisplayName': !exists(json, 'companyDisplayName') ? undefined : json['companyDisplayName'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'isFallback': !exists(json, 'isFallback') ? undefined : json['isFallback'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'validityDate': !exists(json, 'validityDate') ? undefined : (json['validityDate'] === null ? null : new Date(json['validityDate'])),
    };
}

export function ManagedPriceListDtoToJSON(value?: ManagedPriceListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'multiplier': value.multiplier,
        'priceListId': value.priceListId,
        'priceListDisplayName': value.priceListDisplayName,
        'companyId': value.companyId,
        'countryCode': value.countryCode,
        'companyDisplayName': value.companyDisplayName,
        'countryName': value.countryName,
        'isFallback': value.isFallback,
        'active': value.active,
        'validityDate': value.validityDate === undefined ? undefined : (value.validityDate === null ? null : value.validityDate.toISOString()),
    };
}

