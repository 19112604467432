import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";

export const supportSagas = {
  getSupportPersons: createGenericSaga(
    "supportSagas/getSupportPersons",
    getApiRegistry().supportApi.apiSupportSupportPersonsGet.bind(getApiRegistry().supportApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getSupportPersonProfilePictures: createGenericSaga(
    "supportSagas/getSupportPersonProfilePictures",
    getApiRegistry().supportApi.apiSupportSupportPersonsProfilePicturesGet.bind(
      getApiRegistry().supportApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  createSupportRequest: createGenericSaga(
    "supportSagas/createSupportRequest",
    getApiRegistry().supportApi.apiSupportPost.bind(getApiRegistry().supportApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Support request created")
    }
  ),
  setSupportPersonVisibility: createGenericSaga(
    "supportSagas/setSupportPersonVisibility",
    getApiRegistry().supportApi.apiSupportSupportPersonsSetVisibilityPost.bind(
      getApiRegistry().supportApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler,
      onSuccess: defaultSagaSuccessHandler("Country contact visibility updated")
    }
  )
};
