import { ApiApplicationNotificationsIdGetRequest, ApplicationNotificationVm } from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { createInitialRequest, RequestState } from "framework/state/requestState";
import { produce } from "immer";
import { Action } from "redux";
import { AppState } from "setup/appRootReducer";
import { applicationNotificationSagas } from "../sagas/applicationNotificationSagas";

interface Requests {
  getApplicationNotificationById: RequestState<
    ApplicationNotificationVm,
    ApiApplicationNotificationsIdGetRequest
  >;
}

export interface ApplicationNotificationDetailsViewState {
  unreadNotificationCount: number | undefined;
  requests: Requests;
  notificationDetails: ApplicationNotificationVm | undefined;
}

const defaultState: ApplicationNotificationDetailsViewState = {
  unreadNotificationCount: undefined,
  notificationDetails: undefined,
  requests: {
    getApplicationNotificationById: createInitialRequest()
  }
};

export function applicationNotificationDetailsViewReducer(
  state: ApplicationNotificationDetailsViewState = defaultState,
  action: Action
): ApplicationNotificationDetailsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: applicationNotificationSagas.getApplicationNotificationById.actionTypes,
      key: "getApplicationNotificationById"
    }
  ]);

  if (applicationNotificationSagas.getApplicationNotificationById.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.notificationDetails = action.payload;
    });
  } else if (applicationNotificationSagas.getApplicationNotificationById.isRequestAction(action)) {
    state = produce(state, (draft) => {
      draft.notificationDetails = undefined;
    });
  }

  return state;
}

export function getApplicationNotificationDetails(
  state: AppState
): ApplicationNotificationVm | undefined {
  return state.applicationNotificationDetailsView.notificationDetails;
}

export function getGetApplicationNotificationByIdRequest(
  state: AppState
): RequestState<ApplicationNotificationVm, ApiApplicationNotificationsIdGetRequest> {
  return state.applicationNotificationDetailsView.requests.getApplicationNotificationById;
}
