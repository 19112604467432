import React, { useEffect, useRef, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { App } from "../App";
import { userActions } from "../../../applications/common/actions/userActions";
import { getAuthSettings, getIsLoggingIn } from "../../../applications/common/reducers/userReducer";
import { AuthSettings } from "../../../configuration/authConfig";
import { getMsalClient } from "./msalClient";
import styled from "styled-components";
import { LoadingIndicator } from "@abb/abb-common-ux-react/components/LoadingIndicator";
import { deliveriesHomeSagas } from "../../../applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { MsalProvider } from "@azure/msal-react";
import { HotjarSettings } from "configuration/hotJarConfig";
import { hotjar } from "react-hotjar";
import { commonActions } from "applications/common/actions/commonActions";
import {
  getHotjarSettings,
  getIsCookiesConsentAccepted
} from "applications/common/reducers/commonReducer";

const authConfigFile = process.env.PUBLIC_URL + "/config.json";

const hotjarConfigFile = process.env.PUBLIC_URL + "/hotjarConfig.json";

const LoadingIndicatorContainer = styled.div`
  // menu bar is 60px + 12px for the grey padding which is always showing
  height: calc(100vh - 72px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const waitToken = (children: React.ReactNode, isLoggingIn: boolean) => {
  if (!isLoggingIn) {
    return children;
  }
  return (
    <LoadingIndicatorContainer>
      <LoadingIndicator type="radial" determinate={false} sizeClass="large" />
    </LoadingIndicatorContainer>
  );
};

export const AppAuthWrapper: React.FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const authSettings = useSelector(getAuthSettings);
  const isLoggingIn = useSelector(getIsLoggingIn);
  const isCookiesConsentAccepted = useSelector(getIsCookiesConsentAccepted);
  const storedHotjarSettings = useSelector(getHotjarSettings);

  const [msalInterface, setMsalInterface] = useState<PublicClientApplication | undefined>(
    undefined
  );

  const authConfigFetchInitiated = useRef<boolean>(false);
  const hotjarConfigFetchInitiated = useRef<boolean>(false);

  useEffect(() => {
    if (authSettings && msalInterface === undefined) {
      console.log("Setting interface");
      setMsalInterface(getMsalClient(authSettings));
    }
  }, [authSettings, msalInterface]);

  // Use fetch to get the config file from the public folder.
  // Ref is used to disable another fetch for strict mode in development.
  useEffect(() => {
    if (!authConfigFetchInitiated.current) {
      authConfigFetchInitiated.current = true;
      fetch(authConfigFile).then((res) => {
        res.json().then((result) => {
          const authSettings: AuthSettings = result;
          dispatch(userActions.storeAuthSettings(authSettings));
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!hotjarConfigFetchInitiated.current) {
      hotjarConfigFetchInitiated.current = true;
      fetch(hotjarConfigFile).then((res) => {
        res.json().then((result) => {
          const hotjarSettings: HotjarSettings = result;
          if (hotjarSettings.hjid != null && hotjarSettings.hjsv != null) {
            dispatch(commonActions.storeHotjarSettings(hotjarSettings));
          }
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      storedHotjarSettings &&
      isCookiesConsentAccepted &&
      storedHotjarSettings.hjid &&
      storedHotjarSettings.hjsv
    ) {
      hotjar.initialize(storedHotjarSettings.hjid, storedHotjarSettings.hjsv);
      dispatch(commonActions.setIsHotjarInitialized(true));
    }
  }, [storedHotjarSettings, isCookiesConsentAccepted, dispatch]);

  // Get system settings
  useEffect(() => {
    dispatch(deliveriesHomeSagas.getSystemSettings.createAction(undefined));
  }, [dispatch]);

  return msalInterface ? (
    <MsalProvider instance={msalInterface}>
      <App>{waitToken(children, isLoggingIn)}</App>
    </MsalProvider>
  ) : null;
};
