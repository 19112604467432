import styled from "styled-components";
import { Heading, HeadingProps } from "@abb/abb-common-ux-react/components/Heading";
import React from "react";

const StyledMainHeader = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold} !important;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXl} !important;
  line-height: ${(props) => props.theme.fonts.lineHeights.lineHeightXl} !important;
`;

export const MainHeader = (
  props: Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level">
): JSX.Element => <StyledMainHeader {...props} level={1} blackAndWhite={true} showCursor={false} />;
