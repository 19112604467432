/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessRequestFunctionalitySelections {
    ViewingOrderInformation = 'ViewingOrderInformation',
    ProductInformation = 'ProductInformation',
    AdvancedOrderTracking = 'AdvancedOrderTracking',
    ViewCompanyPriceLists = 'ViewCompanyPriceLists',
    CompanyApprover = 'CompanyApprover',
    CompanyPurchaser = 'CompanyPurchaser',
    SupportlineAccess = 'SupportlineAccess'
}

export function AccessRequestFunctionalitySelectionsFromJSON(json: any): AccessRequestFunctionalitySelections {
    return AccessRequestFunctionalitySelectionsFromJSONTyped(json, false);
}

export function AccessRequestFunctionalitySelectionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestFunctionalitySelections {
    return json as AccessRequestFunctionalitySelections;
}

export function AccessRequestFunctionalitySelectionsToJSON(value?: AccessRequestFunctionalitySelections | null): any {
    return value as any;
}

