/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmailRegistrationStatus {
    NotRegistered = 'NotRegistered',
    Pending = 'Pending',
    Registered = 'Registered'
}

export function EmailRegistrationStatusFromJSON(json: any): EmailRegistrationStatus {
    return EmailRegistrationStatusFromJSONTyped(json, false);
}

export function EmailRegistrationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailRegistrationStatus {
    return json as EmailRegistrationStatus;
}

export function EmailRegistrationStatusToJSON(value?: EmailRegistrationStatus | null): any {
    return value as any;
}

