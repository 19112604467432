/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRolesCommand
 */
export interface UpdateUserRolesCommand {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRolesCommand
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRolesCommand
     */
    companyId?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserRolesCommand
     */
    userRolesToAdd: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateUserRolesCommand
     */
    userRolesToRemove: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRolesCommand
     */
    disableNotification: boolean;
}

export function UpdateUserRolesCommandFromJSON(json: any): UpdateUserRolesCommand {
    return UpdateUserRolesCommandFromJSONTyped(json, false);
}

export function UpdateUserRolesCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRolesCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'userRolesToAdd': json['userRolesToAdd'],
        'userRolesToRemove': json['userRolesToRemove'],
        'disableNotification': json['disableNotification'],
    };
}

export function UpdateUserRolesCommandToJSON(value?: UpdateUserRolesCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'companyId': value.companyId,
        'userRolesToAdd': value.userRolesToAdd,
        'userRolesToRemove': value.userRolesToRemove,
        'disableNotification': value.disableNotification,
    };
}

