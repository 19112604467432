import styled from "styled-components";
import React, { CSSProperties, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@abb/abb-common-ux-react";
import { Button } from "framework/components/Button";
import { useSelector } from "react-redux";
import { getProductLifeCycleStatusesRequestState } from "../reducers/lifecycleManagementReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { InputLabel } from "framework/components/InputLabel";

const FormContainer = styled.form`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  align-items: flex-end;
`;

const TextArea = styled(Input)`
  textarea {
    min-height: 150px !important;
    resize: none;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.m};
`;

interface LifecycleProductSearchFormProps {
  onSearch: (keywords: string[]) => void;
  initialKeywords?: string[];
  showClearButton?: boolean;
  singleLine?: boolean;
  style?: CSSProperties;
}

export const LifecycleProductSearchForm = ({
  onSearch,
  initialKeywords,
  showClearButton = true,
  singleLine = false,
  style
}: LifecycleProductSearchFormProps) => {
  const { t } = useTranslation();

  const [keywordString, setKeywordString] = useState(
    initialKeywords ? initialKeywords.join("\n") : ""
  );

  const isLoading = useSelector(getProductLifeCycleStatusesRequestState) === RequestStatus.Pending;

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    const keywords = keywordString
      .split("\n")
      .map((l) => l.trim())
      .filter((l) => l.length >= 1);
    onSearch(keywords);
    setKeywordString(keywords.join("\n"));
  };

  return (
    <FormContainer style={style} onSubmit={handleSubmit}>
      <div style={{ width: "100%" }}>
        <InputLabel
          label={t("Product identifier")}
          tooltipText={t(
            "Enter product name, material code or a serial number to view life-cycle status"
          )}
        />
        <TextArea
          dataType={singleLine ? "text" : "textarea"}
          placeholder={singleLine ? undefined : t("REF615 5.0 FP1 IEC")}
          value={keywordString}
          onValueChange={setKeywordString}
        />
      </div>
      <ButtonRow>
        {showClearButton && (
          <Button
            buttonType="secondary"
            text={t("Clear")}
            onClick={() => {
              onSearch([]);
              setKeywordString("");
            }}
          />
        )}
        <Button
          buttonType="primary-black"
          text={t("Search")}
          onClick={(e) => handleSubmit(e)}
          isLoading={isLoading}
        />
      </ButtonRow>
    </FormContainer>
  );
};
