import createDOMPurify from "dompurify";

// Individual configs
const trainingPagesSanitizationConfig: DOMPurify.Config = {
  ADD_TAGS: ["iframe"],
  ADD_ATTR: [
    "allow",
    "allowfullscreen",
    "frameborder",
    "scrolling",
    "autoplay",
    "clipboard-write",
    "picture-in-picture",
    "gyroscope",
    "encrypted-media"
  ]
};
const notificationSanitizationConfig: DOMPurify.Config = {
  ADD_TAGS: ["style"],
  FORCE_BODY: true
};

// Global tag specific block and allow lists *NOTE: block list takes priority
const tagSpecificAttributesBlocklist: { [key: string]: string[] } = {
  a: ["style"]
};
const tagSpecificAttributesAllowlist: { [key: string]: string[] } = {};

// Separate sanitizers
export const cleanTrainingPages = (dirtyHTML: string) => {
  return clean(dirtyHTML, false, trainingPagesSanitizationConfig).cleaned;
};
export const cleanNotification = (dirtyHTML: string) => {
  return clean(dirtyHTML, true, notificationSanitizationConfig);
};

// Intefaces
export interface SanitizationLog {
  cleaned: string;
  removedAnything: boolean;
  removed: any[];
  configuration: {
    useTagSpecificLists: boolean;
    tagSpecificAttributesAllowlist: { [key: string]: string[] } | undefined;
    tagSpecificAttributesBlocklist: { [key: string]: string[] } | undefined;
    config: DOMPurify.Config | undefined;
  };
}

// Main sanitization function
export const clean = (dirtyHTML: string, useTagSpecificLists = true, config?: DOMPurify.Config) => {
  if (dirtyHTML == "") {
    // To counter bug when sanitizing emtpy string where it adds a comment element to the removed array
    return {
      cleaned: "",
      removedAnything: false,
      removed: [],
      configuration: {
        useTagSpecificLists: useTagSpecificLists,
        tagSpecificAttributesAllowlist: tagSpecificAttributesAllowlist,
        tagSpecificAttributesBlocklist: tagSpecificAttributesBlocklist,
        config: config
      }
    } as SanitizationLog;
  }

  const domPurifyInstance = createDOMPurify();

  if (useTagSpecificLists) {
    domPurifyInstance.addHook("uponSanitizeAttribute", (node, data) => {
      // Keep tag specific allowed attributes
      const allowedListAttrs = tagSpecificAttributesAllowlist[node.tagName.toLowerCase()];
      if (allowedListAttrs) {
        if (allowedListAttrs?.includes(data.attrName)) {
          data.forceKeepAttr = true;
        } else {
          data.forceKeepAttr = false;
          data.keepAttr = false;
        }
      }
    });
    domPurifyInstance.addHook("afterSanitizeAttributes", (node) => {
      // Remove tag specific blocked attributes
      const blockedListAttrs = tagSpecificAttributesBlocklist[node.tagName.toLowerCase()];
      blockedListAttrs?.forEach((attr) => {
        if (node.hasAttribute(attr)) {
          domPurifyInstance.removed.push({
            attribute: node.getAttributeNode(attr),
            from: node,
            reason: "Attribute on tag-specific blocklist",
            tip: "Set parameter 'useTagSpecificLists' false to not use global allow/block-lists"
          });
          node.removeAttribute(attr);
        }
      });
    });
  }

  const clean = domPurifyInstance.sanitize(dirtyHTML, { ...config, RETURN_DOM: false }) as string;

  const removedList = domPurifyInstance.removed.slice(config?.FORCE_BODY ? 1 : 0);
  if (process.env.NODE_ENV == "development" && removedList.length > 0) {
    console.log("DOMPurify removed some page content", {
      removed: removedList,
      configuration: {
        useTagSpecificLists: useTagSpecificLists,
        tagSpecificAttributesAllowlist: tagSpecificAttributesAllowlist,
        tagSpecificAttributesBlocklist: tagSpecificAttributesBlocklist,
        config: config
      }
    });
  }

  return {
    cleaned: clean,
    removedAnything: removedList.length > 0,
    removed: removedList,
    configuration: {
      useTagSpecificLists: useTagSpecificLists,
      tagSpecificAttributesAllowlist: tagSpecificAttributesAllowlist,
      tagSpecificAttributesBlocklist: tagSpecificAttributesBlocklist,
      config: config
    }
  } as SanitizationLog;
};
