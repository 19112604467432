/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutomaticDownloadModelForLicenses
 */
export interface AutomaticDownloadModelForLicenses {
    /**
     * 
     * @type {string}
     * @memberof AutomaticDownloadModelForLicenses
     */
    licenseIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomaticDownloadModelForLicenses
     */
    fileName?: string | null;
}

export function AutomaticDownloadModelForLicensesFromJSON(json: any): AutomaticDownloadModelForLicenses {
    return AutomaticDownloadModelForLicensesFromJSONTyped(json, false);
}

export function AutomaticDownloadModelForLicensesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomaticDownloadModelForLicenses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenseIdentifier': !exists(json, 'licenseIdentifier') ? undefined : json['licenseIdentifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function AutomaticDownloadModelForLicensesToJSON(value?: AutomaticDownloadModelForLicenses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenseIdentifier': value.licenseIdentifier,
        'fileName': value.fileName,
    };
}

