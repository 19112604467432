import { CompanyDto } from "api/models/CompanyDto";
import { Action } from "redux";

export enum DeliveriesHomeViewActionTypes {
  ChangeSelectedCompany = "DeliveriesHomeView/ChangeSelectedCompany",
  SetMarkdownContent = "DeliveriesHomeView/SetMarkdownContent"
}

export interface ChangeSelectedCompany extends Action {
  type: DeliveriesHomeViewActionTypes.ChangeSelectedCompany;
  company: CompanyDto | undefined;
  companyHasApprover: boolean;
}

export interface SetMarkdownContent extends Action {
  type: DeliveriesHomeViewActionTypes.SetMarkdownContent;
  content: string;
}

export const deliveriesHomeViewActions = {
  changeSelectedCompany: (
    company: CompanyDto | undefined,
    companyHasApprover: boolean
  ): ChangeSelectedCompany => ({
    type: DeliveriesHomeViewActionTypes.ChangeSelectedCompany,
    company,
    companyHasApprover
  }),
  setMarkdownContent: (content: string): SetMarkdownContent => ({
    type: DeliveriesHomeViewActionTypes.SetMarkdownContent,
    content
  })
};

export type DeliveriesHomeViewActions = ChangeSelectedCompany | SetMarkdownContent;
