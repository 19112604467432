/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsOfDeliveryDto
 */
export interface TermsOfDeliveryDto {
    /**
     * 
     * @type {string}
     * @memberof TermsOfDeliveryDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsOfDeliveryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsOfDeliveryDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfDeliveryDto
     */
    canUse?: boolean;
}

export function TermsOfDeliveryDtoFromJSON(json: any): TermsOfDeliveryDto {
    return TermsOfDeliveryDtoFromJSONTyped(json, false);
}

export function TermsOfDeliveryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfDeliveryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'canUse': !exists(json, 'canUse') ? undefined : json['canUse'],
    };
}

export function TermsOfDeliveryDtoToJSON(value?: TermsOfDeliveryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'description': value.description,
        'displayName': value.displayName,
        'canUse': value.canUse,
    };
}

