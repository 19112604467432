import {
  AccessRequestMetadataVm,
  CountryDto,
  CreateAccessRequestCommand,
  TimeZoneDto,
  TimeZonesVm
} from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { RequestStatus } from "framework/state/requestStatus";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";
import { AccessRequestsActions } from "../actions/accessRequestsActions";
import { accessRequestsSagas } from "../sagas/accessRequestsSagas";

interface Requests {
  createAccessRequest: RequestState<void, CreateAccessRequestCommand>;
  getMetadata: RequestState<AccessRequestMetadataVm>;
  getCountries: RequestState<CountryDto[]>;
  getTimeZones: RequestState<TimeZonesVm>;
}

export interface AccessRequestsState {
  requests: Requests;
  metadata: AccessRequestMetadataVm | undefined;
  countries: CountryDto[] | undefined;
  timeZones: TimeZoneDto[] | undefined;
}

const defaultState: AccessRequestsState = {
  requests: {
    createAccessRequest: createInitialRequest(),
    getMetadata: createInitialRequest(),
    getTimeZones: createInitialRequest(),
    getCountries: createInitialRequest()
  },
  metadata: undefined,
  countries: undefined,
  timeZones: undefined
};

export function accessRequestsReducer(
  state: AccessRequestsState = defaultState,
  action: AccessRequestsActions
): AccessRequestsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: accessRequestsSagas.createAccessRequest.actionTypes,
      key: "createAccessRequest"
    },
    {
      actionTypes: accessRequestsSagas.getMetadata.actionTypes,
      key: "getMetadata"
    },
    {
      actionTypes: accessRequestsSagas.getCountries.actionTypes,
      key: "getCountries"
    },
    {
      actionTypes: accessRequestsSagas.getTimeZones.actionTypes,
      key: "getTimeZones"
    }
  ]);

  if (accessRequestsSagas.getMetadata.isRequestAction(action)) {
    state = produce(state, (draft) => {
      draft.metadata = undefined;
    });
  } else if (accessRequestsSagas.getMetadata.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.metadata = action.payload;
    });
  } else if (accessRequestsSagas.getCountries.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.countries = action.payload;
    });
  } else if (accessRequestsSagas.getTimeZones.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.timeZones = action.payload.selectableTimeZones ?? [];
    });
  }
  return state;
}

export const getCreateAccessRequestStatus = (state: AppState): RequestStatus =>
  state.accessRequests.requests.createAccessRequest.status;

export const getAccessRequestMetadata = (state: AppState): AccessRequestMetadataVm | undefined =>
  state.accessRequests.metadata;

export const getCountries = (state: AppState): CountryDto[] | undefined =>
  state.accessRequests.countries;

export const getTimeZones = (state: AppState): TimeZoneDto[] | undefined =>
  state.accessRequests.timeZones;
