/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KnowledgeWarehouseTab {
    Faq = 'FAQ',
    Training = 'Training',
    TerminologyBank = 'TerminologyBank',
    TechDoc = 'TechDoc'
}

export function KnowledgeWarehouseTabFromJSON(json: any): KnowledgeWarehouseTab {
    return KnowledgeWarehouseTabFromJSONTyped(json, false);
}

export function KnowledgeWarehouseTabFromJSONTyped(json: any, ignoreDiscriminator: boolean): KnowledgeWarehouseTab {
    return json as KnowledgeWarehouseTab;
}

export function KnowledgeWarehouseTabToJSON(value?: KnowledgeWarehouseTab | null): any {
    return value as any;
}

