import { Collapsible, CollapsibleContainer, LoadingIndicator } from "@abb/abb-common-ux-react";
import { CategoriesMenuDto, FeatureDto, SubCategoryDto } from "api";
import { Link } from "framework/components/Link";
import { RequestStatus } from "framework/state/requestStatus";
import { isEqual } from "lodash-es";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonUXTheme } from "styles/commonUXVariables";
import { useStateParams } from "utilities/useStateParams";
import { productExplorerActions } from "../actions/ProductExplorerActions";
import { getMenuFeatures, getMenuFeaturesStatus } from "../reducers/productExplorerReducer";
import { SelectApplication } from "./SelectApplication";
import { SelectStandard } from "./SelectStandard";
import {
  ClearFilterContainer,
  LoadingIndicatorContainer,
  RefineByFunctionalityContainer
} from "./StyledComponents";
import { SelectLifeCycleStatus } from "./SelectLifeCycleStatus";

export interface NodeCollapsible {
  name: string;
  id: string;
  features: Array<FeatureDto>;
  subCategories: Array<SubCategoryDto>;
  ansiCode?: string | null;
  iec61850LnClass?: string | null;
}
export const FeatureNameAndCode = ({
  features: feat
}: {
  features: { name: string; ansiCode?: string | null; iec61850LnClass?: string | null };
}) => (
  <>
    <div>{feat.name}</div>
    {feat.ansiCode || feat.iec61850LnClass ? (
      <div className="FeatureClass">
        {feat.iec61850LnClass && feat.ansiCode
          ? feat.iec61850LnClass.concat(" | ", feat.ansiCode)
          : !feat.iec61850LnClass
          ? feat.ansiCode
          : feat.iec61850LnClass}
      </div>
    ) : undefined}
  </>
);
export const RefineByFunctionality = () => {
  // State
  //state params
  const fromStrToFunctionality = useCallback((s: string | null) => {
    const res = s?.split(",") ?? [];
    return res.filter((f) => f.trim().length > 0);
  }, []);
  const [functionalityIdList, setFunctionalityIdList] = useStateParams<string[]>(
    [],
    "fun",
    (s) => s.toString(),
    fromStrToFunctionality,
    0,
    (v1, v2) => isEqual(v1, v2)
  );
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [nodeCollapsible, setNodeCollapsible] = React.useState<CategoriesMenuDto[]>([]);
  const [nodeElementsCollapsible, setNodeElementsCollapsible] = React.useState<JSX.Element[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // Selector
  const categories = useSelector(getMenuFeatures);
  const categoriesStatus = useSelector(getMenuFeaturesStatus);

  // Const
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // aux function to get element in recursive way
  const getChildCollapsible = useCallback(
    (node: NodeCollapsible) => {
      return (
        <Collapsible
          key={node.id}
          itemId={node.id}
          title={
            <span>
              <FeatureNameAndCode features={node} />
            </span>
          }
          onClick={() => {
            if (node.subCategories.length === 0 && node.features.length === 0) {
              const id = node.id;
              if (id == null) return;
              const strId = id.toString();

              functionalityIdList.find((f) => f == strId) == null
                ? setFunctionalityIdList([strId, ...(functionalityIdList ?? [])])
                : setFunctionalityIdList(functionalityIdList.filter((f) => f != strId));
            }
          }}
          onClickCheckbox={(id) => {
            if (id == null) return;
            const strId = id.toString();

            functionalityIdList.find((f) => f === strId) == null
              ? setFunctionalityIdList([strId, ...(functionalityIdList ?? [])])
              : setFunctionalityIdList(functionalityIdList.filter((f) => f !== strId));
          }}
          checked={
            node.subCategories.length === 0 && node.features.length === 0
              ? functionalityIdList.some((f) => f === node.id)
              : undefined
          }
          alarmState={
            functionalityIdList.some((f) => node.features.some((nf) => nf.code === f))
              ? "alarm"
              : "normal"
          }
        >
          {node.subCategories.length > 0
            ? node?.subCategories.map((n) => getChildCollapsible({ ...n, subCategories: [] }))
            : node?.features.map((n) =>
                getChildCollapsible({ ...n, id: n.code, features: [], subCategories: [] })
              )}
        </Collapsible>
      );
    },
    [functionalityIdList, setFunctionalityIdList]
  );
  // effects
  useEffect(() => {
    setIsLoading(true);
    setNodeCollapsible(categories);
  }, [categories]);

  useEffect(() => {
    const el = nodeCollapsible.map<JSX.Element>((node) => {
      return getChildCollapsible(node);
    });
    setNodeElementsCollapsible(el);
    setIsLoading(false);
  }, [getChildCollapsible, nodeCollapsible]);
  // update filter
  useEffect(() => {
    dispatch(productExplorerActions.SetFilterFunctionalities(functionalityIdList));
  }, [dispatch, functionalityIdList]);

  return (
    <>
      {categoriesStatus == RequestStatus.Pending || isLoading ? (
        <LoadingIndicatorContainer>
          <LoadingIndicator type="radial" sizeClass="large" determinate={false} />
        </LoadingIndicatorContainer>
      ) : (
        <RefineByFunctionalityContainer>
          <h4>{t("Refine by functionality")}</h4>
          <p>{t("You can restrict products according to their functions")}</p>

          <SelectApplication />
          <SelectStandard />
          <SelectLifeCycleStatus />

          <ClearFilterContainer
            style={{
              visibility: functionalityIdList.length > 0 ? "visible" : "hidden",
              marginTop: commonUXTheme.sizes.s
            }} // to avoid layout shift
          >
            <Link to="#" iconAfter={"abb/close"} onClick={() => setFunctionalityIdList([])}>
              {t("Clear all filters")}
            </Link>
          </ClearFilterContainer>
          <CollapsibleContainer
            expandedCollapsible={expanded}
            singleOpenMode={false}
            onExpanding={(items) => setExpanded(Array.from(new Set([...expanded, ...items])))}
            onCollapsing={(item) => setExpanded(expanded.filter((i) => i !== item))}
            monochrome
            expandOnTitleClick
          >
            {nodeElementsCollapsible}
          </CollapsibleContainer>
        </RefineByFunctionalityContainer>
      )}{" "}
    </>
  );
};
