import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getIsPricesBudgetary } from "../reducers/userReducer";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  color: ${(props) => props.theme.colors.statusRed};
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

export const BudgetaryNote = (): JSX.Element => {
  const { t } = useTranslation();
  const isPricesBudgetary = useSelector(getIsPricesBudgetary);

  return <>
    {isPricesBudgetary && (
      <Container>
        {t("All prices are budgetary")}
      </Container>
    )}
  </>;
};