/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatedItem
 */
export interface CreatedItem {
    /**
     * 
     * @type {string}
     * @memberof CreatedItem
     */
    orderingCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatedItem
     */
    quotedPrice?: number | null;
}

export function CreatedItemFromJSON(json: any): CreatedItem {
    return CreatedItemFromJSONTyped(json, false);
}

export function CreatedItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCode': !exists(json, 'orderingCode') ? undefined : json['orderingCode'],
        'quotedPrice': !exists(json, 'quotedPrice') ? undefined : json['quotedPrice'],
    };
}

export function CreatedItemToJSON(value?: CreatedItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCode': value.orderingCode,
        'quotedPrice': value.quotedPrice,
    };
}

