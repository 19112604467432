import React from "react";
import { useTranslation } from "react-i18next";
import { StatusBanner } from "./StatusBanner";
export enum SectionToDisable {
  Quoting,
  Ordering,
  Both
}
interface Props {
  disableSection: SectionToDisable;
}
export const MaintenanceBanner = ({ disableSection }: Props) => {
  const { t } = useTranslation();
  const text =
    disableSection == SectionToDisable.Ordering
      ? t("Ordering ")
      : disableSection == SectionToDisable.Quoting
      ? t("Quoting ")
      : t("Ordering and quoting ");
  return (
    <StatusBanner
      icon={"abb/wrench"}
      headerText={t("Under maintenance")}
      text={
        text +
        t(
          "via the Relays-Online service platform has been temporally suspended until further notice. Apologies for the inconvenient. "
        )
      }
      status="error"
      shape={"long"}
    />
  );
};
