/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlusCodePriceDto
 */
export interface PlusCodePriceDto {
    /**
     * 
     * @type {string}
     * @memberof PlusCodePriceDto
     */
    plusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PlusCodePriceDto
     */
    displayPrice?: string | null;
}

export function PlusCodePriceDtoFromJSON(json: any): PlusCodePriceDto {
    return PlusCodePriceDtoFromJSONTyped(json, false);
}

export function PlusCodePriceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlusCodePriceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plusCode': !exists(json, 'plusCode') ? undefined : json['plusCode'],
        'displayPrice': !exists(json, 'displayPrice') ? undefined : json['displayPrice'],
    };
}

export function PlusCodePriceDtoToJSON(value?: PlusCodePriceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plusCode': value.plusCode,
        'displayPrice': value.displayPrice,
    };
}

