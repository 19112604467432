/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NodeDto
 */
export interface NodeDto {
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof NodeDto
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof NodeDto
     */
    parentId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof NodeDto
     */
    readonly articlesId?: Array<number> | null;
    /**
     * 
     * @type {Array<NodeDto>}
     * @memberof NodeDto
     */
    readonly subNodes?: Array<NodeDto> | null;
}

export function NodeDtoFromJSON(json: any): NodeDto {
    return NodeDtoFromJSONTyped(json, false);
}

export function NodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'order': json['order'],
        'parentId': json['parentId'],
        'articlesId': !exists(json, 'articlesId') ? undefined : json['articlesId'],
        'subNodes': !exists(json, 'subNodes') ? undefined : (json['subNodes'] === null ? null : (json['subNodes'] as Array<any>).map(NodeDtoFromJSON)),
    };
}

export function NodeDtoToJSON(value?: NodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'parentId': value.parentId,
    };
}

