import { createGenericSaga, defaultSagaErrorHandler } from "../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../framework/state/apiRegistry";

export const accessRightSagas = {
  getAccessRights: createGenericSaga(
    "accessRightSagas/getAccessRights",
    getApiRegistry().accessRightsApi.apiAccessRightsGet.bind(getApiRegistry().accessRightsApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
