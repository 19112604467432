import { Dialog as CommonUXDialog, DialogProps } from "@abb/abb-common-ux-react/components/Dialog";
import React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const Inner = ({
  dimBackground = true,
  showCloseButton = true,
  children,
  ...rest
}: PropsWithChildren<Partial<DialogProps>>) => {
  return (
    <CommonUXDialog {...rest} dimBackground={dimBackground} showCloseButton={showCloseButton}>
      {children}
    </CommonUXDialog>
  );
};
export const Dialog = styled(Inner)`
  .ABB_CommonUX_Dialog__title {
    white-space: normal !important;
  }
`;
