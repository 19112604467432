/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyTermsConditionListVm,
    CompanyTermsConditionListVmFromJSON,
    CompanyTermsConditionListVmToJSON,
    CountryTermsConditionListVm,
    CountryTermsConditionListVmFromJSON,
    CountryTermsConditionListVmToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    SupplierTermsAndConditionType,
    SupplierTermsAndConditionTypeFromJSON,
    SupplierTermsAndConditionTypeToJSON,
    SupplierTermsConditionListVm,
    SupplierTermsConditionListVmFromJSON,
    SupplierTermsConditionListVmToJSON,
    TermsAndConditionsExternalInfoDto,
    TermsAndConditionsExternalInfoDtoFromJSON,
    TermsAndConditionsExternalInfoDtoToJSON,
} from '../models';

export interface ApiTermsAndConditionsCompaniesCompanyIdGetRequest {
    companyId: string;
}

export interface ApiTermsAndConditionsCompaniesCompanyIdPostRequest {
    companyId: string;
    file?: Blob;
    description?: string;
    id?: number;
}

export interface ApiTermsAndConditionsCompaniesGetRequest {
    page: number;
    pageSize: number;
    companyName?: string;
    fileName?: string;
    sortColumn?: string;
    isAscendingOrder?: boolean;
    sortItems?: Array<CriteriaSortItem>;
    skipTotalRowCount?: boolean;
    querySortByColumn?: string;
}

export interface ApiTermsAndConditionsCountriesCountryCodeGetRequest {
    countryCode: string;
}

export interface ApiTermsAndConditionsCountriesCountryCodePostRequest {
    countryCode: string;
    file?: Blob;
    description?: string;
    id?: number;
}

export interface ApiTermsAndConditionsCountriesGetRequest {
    page: number;
    pageSize: number;
    country?: string;
    fileName?: string;
    sortColumn?: string;
    isAscendingOrder?: boolean;
    sortItems?: Array<CriteriaSortItem>;
    skipTotalRowCount?: boolean;
    querySortByColumn?: string;
}

export interface ApiTermsAndConditionsIdDeleteRequest {
    id: number;
}

export interface ApiTermsAndConditionsQuotesQuoteIdDownloadGetRequest {
    quoteId: number;
}

export interface ApiTermsAndConditionsQuotesQuoteIdGetRequest {
    quoteId: number;
}

export interface ApiTermsAndConditionsSupplierGetRequest {
    page: number;
    pageSize: number;
    sortColumn?: string;
    isAscendingOrder?: boolean;
    sortItems?: Array<CriteriaSortItem>;
    skipTotalRowCount?: boolean;
    querySortByColumn?: string;
}

export interface ApiTermsAndConditionsSupplierTypeGetRequest {
    type: SupplierTermsAndConditionType;
}

export interface ApiTermsAndConditionsSupplierTypePostRequest {
    type: SupplierTermsAndConditionType;
    file?: Blob;
    description?: string;
    id?: number;
}

/**
 * 
 */
export class TermsAndConditionsApi extends runtime.BaseAPI {

    /**
     */
    async apiTermsAndConditionsCompaniesCompanyIdGetRaw(requestParameters: ApiTermsAndConditionsCompaniesCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiTermsAndConditionsCompaniesCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsCompaniesCompanyIdGet(requestParameters: ApiTermsAndConditionsCompaniesCompanyIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTermsAndConditionsCompaniesCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsCompaniesCompanyIdPostRaw(requestParameters: ApiTermsAndConditionsCompaniesCompanyIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling apiTermsAndConditionsCompaniesCompanyIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsCompaniesCompanyIdPost(requestParameters: ApiTermsAndConditionsCompaniesCompanyIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTermsAndConditionsCompaniesCompanyIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTermsAndConditionsCompaniesGetRaw(requestParameters: ApiTermsAndConditionsCompaniesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyTermsConditionListVm>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling apiTermsAndConditionsCompaniesGet.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling apiTermsAndConditionsCompaniesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyName !== undefined) {
            queryParameters['CompanyName'] = requestParameters.companyName;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['FileName'] = requestParameters.fileName;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['SortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.isAscendingOrder !== undefined) {
            queryParameters['IsAscendingOrder'] = requestParameters.isAscendingOrder;
        }

        if (requestParameters.sortItems) {
            queryParameters['SortItems'] = requestParameters.sortItems;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.skipTotalRowCount !== undefined) {
            queryParameters['SkipTotalRowCount'] = requestParameters.skipTotalRowCount;
        }

        if (requestParameters.querySortByColumn !== undefined) {
            queryParameters['QuerySortByColumn'] = requestParameters.querySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyTermsConditionListVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTermsAndConditionsCompaniesGet(requestParameters: ApiTermsAndConditionsCompaniesGetRequest, initOverrides?: RequestInit): Promise<CompanyTermsConditionListVm> {
        const response = await this.apiTermsAndConditionsCompaniesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsCountriesCountryCodeGetRaw(requestParameters: ApiTermsAndConditionsCountriesCountryCodeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling apiTermsAndConditionsCountriesCountryCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/countries/{countryCode}`.replace(`{${"countryCode"}}`, encodeURIComponent(String(requestParameters.countryCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsCountriesCountryCodeGet(requestParameters: ApiTermsAndConditionsCountriesCountryCodeGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTermsAndConditionsCountriesCountryCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsCountriesCountryCodePostRaw(requestParameters: ApiTermsAndConditionsCountriesCountryCodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling apiTermsAndConditionsCountriesCountryCodePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/countries/{countryCode}`.replace(`{${"countryCode"}}`, encodeURIComponent(String(requestParameters.countryCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsCountriesCountryCodePost(requestParameters: ApiTermsAndConditionsCountriesCountryCodePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTermsAndConditionsCountriesCountryCodePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTermsAndConditionsCountriesGetRaw(requestParameters: ApiTermsAndConditionsCountriesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CountryTermsConditionListVm>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling apiTermsAndConditionsCountriesGet.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling apiTermsAndConditionsCountriesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.country !== undefined) {
            queryParameters['Country'] = requestParameters.country;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['FileName'] = requestParameters.fileName;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['SortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.isAscendingOrder !== undefined) {
            queryParameters['IsAscendingOrder'] = requestParameters.isAscendingOrder;
        }

        if (requestParameters.sortItems) {
            queryParameters['SortItems'] = requestParameters.sortItems;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.skipTotalRowCount !== undefined) {
            queryParameters['SkipTotalRowCount'] = requestParameters.skipTotalRowCount;
        }

        if (requestParameters.querySortByColumn !== undefined) {
            queryParameters['QuerySortByColumn'] = requestParameters.querySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountryTermsConditionListVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTermsAndConditionsCountriesGet(requestParameters: ApiTermsAndConditionsCountriesGetRequest, initOverrides?: RequestInit): Promise<CountryTermsConditionListVm> {
        const response = await this.apiTermsAndConditionsCountriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsCurrentUserDownloadGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/currentUser/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsCurrentUserDownloadGet(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTermsAndConditionsCurrentUserDownloadGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsCurrentUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TermsAndConditionsExternalInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/currentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsAndConditionsExternalInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTermsAndConditionsCurrentUserGet(initOverrides?: RequestInit): Promise<TermsAndConditionsExternalInfoDto> {
        const response = await this.apiTermsAndConditionsCurrentUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsIdDeleteRaw(requestParameters: ApiTermsAndConditionsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTermsAndConditionsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsIdDelete(requestParameters: ApiTermsAndConditionsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTermsAndConditionsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTermsAndConditionsQuotesQuoteIdDownloadGetRaw(requestParameters: ApiTermsAndConditionsQuotesQuoteIdDownloadGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.quoteId === null || requestParameters.quoteId === undefined) {
            throw new runtime.RequiredError('quoteId','Required parameter requestParameters.quoteId was null or undefined when calling apiTermsAndConditionsQuotesQuoteIdDownloadGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/quotes/{quoteId}/download`.replace(`{${"quoteId"}}`, encodeURIComponent(String(requestParameters.quoteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsQuotesQuoteIdDownloadGet(requestParameters: ApiTermsAndConditionsQuotesQuoteIdDownloadGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTermsAndConditionsQuotesQuoteIdDownloadGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsQuotesQuoteIdGetRaw(requestParameters: ApiTermsAndConditionsQuotesQuoteIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TermsAndConditionsExternalInfoDto>> {
        if (requestParameters.quoteId === null || requestParameters.quoteId === undefined) {
            throw new runtime.RequiredError('quoteId','Required parameter requestParameters.quoteId was null or undefined when calling apiTermsAndConditionsQuotesQuoteIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/quotes/{quoteId}`.replace(`{${"quoteId"}}`, encodeURIComponent(String(requestParameters.quoteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsAndConditionsExternalInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTermsAndConditionsQuotesQuoteIdGet(requestParameters: ApiTermsAndConditionsQuotesQuoteIdGetRequest, initOverrides?: RequestInit): Promise<TermsAndConditionsExternalInfoDto> {
        const response = await this.apiTermsAndConditionsQuotesQuoteIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsSupplierGetRaw(requestParameters: ApiTermsAndConditionsSupplierGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SupplierTermsConditionListVm>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling apiTermsAndConditionsSupplierGet.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling apiTermsAndConditionsSupplierGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['SortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.isAscendingOrder !== undefined) {
            queryParameters['IsAscendingOrder'] = requestParameters.isAscendingOrder;
        }

        if (requestParameters.sortItems) {
            queryParameters['SortItems'] = requestParameters.sortItems;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.skipTotalRowCount !== undefined) {
            queryParameters['SkipTotalRowCount'] = requestParameters.skipTotalRowCount;
        }

        if (requestParameters.querySortByColumn !== undefined) {
            queryParameters['QuerySortByColumn'] = requestParameters.querySortByColumn;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/supplier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupplierTermsConditionListVmFromJSON(jsonValue));
    }

    /**
     */
    async apiTermsAndConditionsSupplierGet(requestParameters: ApiTermsAndConditionsSupplierGetRequest, initOverrides?: RequestInit): Promise<SupplierTermsConditionListVm> {
        const response = await this.apiTermsAndConditionsSupplierGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsSupplierTypeGetRaw(requestParameters: ApiTermsAndConditionsSupplierTypeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiTermsAndConditionsSupplierTypeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/supplier/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsSupplierTypeGet(requestParameters: ApiTermsAndConditionsSupplierTypeGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiTermsAndConditionsSupplierTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTermsAndConditionsSupplierTypePostRaw(requestParameters: ApiTermsAndConditionsSupplierTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiTermsAndConditionsSupplierTypePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.id !== undefined) {
            formParams.append('Id', requestParameters.id as any);
        }

        const response = await this.request({
            path: `/api/TermsAndConditions/supplier/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTermsAndConditionsSupplierTypePost(requestParameters: ApiTermsAndConditionsSupplierTypePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTermsAndConditionsSupplierTypePostRaw(requestParameters, initOverrides);
    }

}
