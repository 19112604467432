import React, { memo } from "react";
import { TFunction } from "i18next";
import { Input, Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import styled from "styled-components";
import { Button } from "framework/components/Button";
import { ShoppingCartDeliveryAddressDto } from "../../../../api/models/ShoppingCartDeliveryAddressDto";
import { ShoppingCartEndCustomerDto } from "../../../../api/models/ShoppingCartEndCustomerDto";
import {
  CountryDto,
  ShoppingCartAccountCustomerDto,
  ShoppingCartBillingAddressDto,
  ShoppingCartConsigneeDto
} from "api";
import { InputLabel } from "framework/components/InputLabel";
import { FormikProps, getIn } from "formik";
import {
  NestedShoppingCartFormKeys,
  ShoppingCartForm
} from "applications/shoppingCart/containers/ShoppingCartView";
import { handleFormikValueChange } from "utilities/formikUtils";
import { Dialog } from "framework/components/Dialog";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.sm};
  margin-block: ${(props) => props.theme.sizes.m};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.s};
`;

const DropdownContainer = styled.div`
  .ABB_CommonUX_Dropdown__comp {
    div[class$="-menu"] > div {
      max-height: 200px !important;
      overflow-x: hidden;
    }
  }
`;

const DropdownOptionTemplate = styled.div`
  word-break: break-all;
  overflow: hidden;
  white-space: pre-wrap;
  padding: ${(props) => props.theme.sizes.xxs};
`;

const NameText = styled.p`
  margin: 0;
`;

const Text = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

export type OptionType = {
  label: string;
  value: string;
};

export type InformationFormKeys =
  | keyof Omit<ShoppingCartDeliveryAddressDto, "id">
  | keyof Omit<ShoppingCartEndCustomerDto, "id">
  | keyof Omit<ShoppingCartBillingAddressDto, "id">
  | keyof Omit<ShoppingCartAccountCustomerDto, "id">
  | keyof Omit<ShoppingCartConsigneeDto, "id">;

const getRequiredFields = (
  requiredFields: InformationFormKeys[] | undefined,
  key: InformationFormKeys
) => {
  if (requiredFields?.some((field) => field === key)) {
    return true;
  }
  return false;
};

const getCommonLabels = (t: TFunction): { [key in InformationFormKeys]?: string } => {
  return {
    name: t("Company name"),
    reference: t("Department / reference"),
    streetAddress: t("Street address"),
    postalCode: t("Postal / Zip code"),
    city: t("City"),
    state: t("State / Province / Region"),
    country: t("Country")
  };
};

const getContactPersonLabels = (t: TFunction): { [key in InformationFormKeys]?: string } => {
  return {
    contactPerson: t("Contact person"),
    contactPersonEmail: t("Contact person email"),
    contactPersonTelephone: t("Contact person telephone")
  };
};

const getLabels = (parentObjName: NestedShoppingCartFormKeys, t: TFunction) => {
  switch (parentObjName) {
    case "accountCustomer":
      return {
        ...getCommonLabels(t),
        ...getContactPersonLabels(t),
        pgCode: t("PG code")
      };
    case "endCustomer":
      return {
        ...getCommonLabels(t),
        guidCode: t("GUID code")
      };
    case "billingAddress":
      return {
        ...getCommonLabels(t)
      };
    case "deliveryAddress":
      return {
        ...getCommonLabels(t),
        ...getContactPersonLabels(t)
      };
    case "consignee":
      return {
        ...getCommonLabels(t)
      };
  }
};

interface InformationFormDialogProps {
  t: TFunction;
  isOpen: boolean;
  onDialogClose: () => void;
  title: string;
  values:
    | ShoppingCartEndCustomerDto
    | ShoppingCartDeliveryAddressDto
    | ShoppingCartAccountCustomerDto
    | ShoppingCartBillingAddressDto
    | ShoppingCartConsigneeDto
    | undefined;
  countries: CountryDto[] | undefined;
  formik: FormikProps<ShoppingCartForm>;
  parentObjName: NestedShoppingCartFormKeys;
  requiredFields?: InformationFormKeys[];
  suggestions?: any[] | undefined | null;
  onSetSuggestion?: (suggestionId: number) => void;
}

export const InformationFormDialog = ({
  t,
  isOpen,
  onDialogClose,
  title,
  values,
  countries,
  parentObjName,
  formik,
  requiredFields,
  suggestions,
  onSetSuggestion
}: InformationFormDialogProps): JSX.Element | null => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      onClose={() => onDialogClose()}
      contentClassName="dialog-content overflow-auto"
      closeOnLostFocus={true}
    >
      <Form>
        {Object.entries(getLabels(parentObjName, t)).map(([key, label]) => {
          const keyOf = key as
            | InformationFormKeys
            | keyof Omit<ShoppingCartAccountCustomerDto, "id" | "shoppingCartId">;
          if (keyOf === "name" && suggestions && suggestions?.length > 0) {
            return (
              <div key={keyOf}>
                <InputLabel
                  label={label ?? ""}
                  isRequired={getRequiredFields(requiredFields, keyOf)}
                />
                <DropdownContainer>
                  <Dropdown
                    value={[{ value: values?.[keyOf], label: values?.[keyOf] ?? "" }]}
                    onChange={(v) => {
                      handleFormikValueChange(formik, `${parentObjName}.${keyOf}`, v[0].label);
                      if (!v[0].isNew && onSetSuggestion != undefined) {
                        onSetSuggestion(v[0].value);
                      }
                    }}
                    onClose={() => {
                      formik.setFieldTouched(`${parentObjName}.${keyOf}`, true);
                    }}
                    searchable
                    monochrome
                    validationState={{
                      valid:
                        getIn(formik.errors, parentObjName ? `${parentObjName}.${keyOf}` : keyOf) &&
                        getIn(formik.touched, parentObjName ? `${parentObjName}.${keyOf}` : keyOf)
                          ? false
                          : true,
                      message: getIn(
                        formik.errors,
                        parentObjName ? `${parentObjName}.${keyOf}` : keyOf
                      )
                    }}
                    allowAdd
                    createOptionText={t("Create")}
                    showValidationIconWhenInvalid={false}
                    showValidationBarWhenInvalid={true}
                  >
                    {suggestions?.map((s) => (
                      <DropdownOption
                        key={s.id}
                        value={s.id}
                        label={s.name}
                        menuItemTemplate={
                          <DropdownOptionTemplate>
                            <NameText>{s.name ?? ""}</NameText>
                            <div>
                              <Text>{s.department ?? ""}</Text>
                              <Text>{s.streetAddress ?? ""}</Text>
                              <Text>
                                {(s.city ? s.city : "") +
                                  (s.state ? ", " + s.state : "") +
                                  (s.postalCode ? ", " + s.postalCode : "")}
                              </Text>
                              <Text>{s.country}</Text>
                            </div>
                          </DropdownOptionTemplate>
                        }
                      />
                    ))}
                  </Dropdown>
                </DropdownContainer>
              </div>
            );
          }
          if (keyOf === "country") {
            return (
              <div key={keyOf}>
                <InputLabel
                  label={label ?? ""}
                  isRequired={getRequiredFields(requiredFields, keyOf)}
                />
                <DropdownContainer>
                  <Dropdown
                    value={[{ value: values?.[keyOf], label: values?.[keyOf] ?? "" }]}
                    onChange={(v) => {
                      handleFormikValueChange(formik, `${parentObjName}.country`, v[0].label);
                      handleFormikValueChange(formik, `${parentObjName}.countryCode`, v[0].value);
                    }}
                    onClose={() => {
                      formik.setFieldTouched(`${parentObjName}.country`, true);
                      formik.setFieldTouched(`${parentObjName}.countryCode`, true);
                    }}
                    searchable
                    monochrome
                    validationState={{
                      valid:
                        getIn(formik.errors, parentObjName ? `${parentObjName}.${keyOf}` : keyOf) &&
                        getIn(formik.touched, parentObjName ? `${parentObjName}.${keyOf}` : keyOf)
                          ? false
                          : true,
                      message: getIn(
                        formik.errors,
                        parentObjName ? `${parentObjName}.${keyOf}` : keyOf
                      )
                    }}
                    showValidationIconWhenInvalid={false}
                    showValidationBarWhenInvalid={true}
                  >
                    {countries?.map((c) => (
                      <DropdownOption key={c.code} value={c.code} label={c.name} />
                    ))}
                  </Dropdown>
                </DropdownContainer>
              </div>
            );
          }
          return (
            <FastInput
              key={keyOf}
              keyOf={keyOf}
              label={label ?? ""}
              isRequired={getRequiredFields(requiredFields, keyOf)}
              value={(values as ShoppingCartAccountCustomerDto)?.[keyOf]}
              isError={
                getIn(formik.errors, parentObjName ? `${parentObjName}.${keyOf}` : keyOf) &&
                getIn(formik.touched, parentObjName ? `${parentObjName}.${keyOf}` : keyOf)
                  ? false
                  : true
              }
              errorMessage={getIn(
                formik.errors,
                parentObjName ? `${parentObjName}.${keyOf}` : keyOf
              )}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              name={parentObjName ? `${parentObjName}.${keyOf}` : keyOf}
              showValidationIconWhenInvalid={keyOf === "contactPersonEmail"}
            />
          );
        })}
      </Form>
      <ButtonContainer>
        {parentObjName ? (
          <Button
            buttonType="secondary"
            text={t("Clear")}
            onClick={() => formik.setFieldValue(parentObjName, undefined)}
          />
        ) : null}
        <Button buttonType="primary" text={t("Save")} onClick={() => onDialogClose()} />
      </ButtonContainer>
    </Dialog>
  );
};

interface FastInputProps {
  keyOf: string;
  label: string;
  isRequired: boolean;
  name: string;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(
      field: T
    ): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  value: string | null | undefined;
  isError: boolean;
  errorMessage: string;
  showValidationIconWhenInvalid: boolean;
}

const FastInput = memo(
  ({
    isRequired,
    label,
    name,
    handleBlur,
    handleChange,
    value,
    isError,
    errorMessage,
    showValidationIconWhenInvalid
  }: FastInputProps): JSX.Element => {
    return (
      <div>
        <InputLabel label={label} isRequired={isRequired} />
        <Input
          inputAttributes={{
            name: name,
            onChange: handleChange
          }}
          dataType="text"
          value={value}
          validationResult={{
            valid: isError,
            text: errorMessage
          }}
          instantValidation={true}
          showValidationIconWhenInvalid={showValidationIconWhenInvalid}
          showValidationBarWhenInvalid={true}
          onLostFocus={handleBlur}
        />
      </div>
    );
  }
);
