import i18n from "i18n";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserInformation } from "../reducers/userReducer";

export const LanguageProvider: React.FunctionComponent = (props) => {
  const user = useSelector(getUserInformation);
  const language = user?.language;

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", language);
      i18n.changeLanguage(language);
    }

    // Get the selected language from localStorage to prevent text flicker (=language changing) when loading page for first time
    // The upper if-statement still ensures that the correct language is loaded
    // The language selection in localStorage and the database are assumed to always match, so this will speed up the loading process
    else if (localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language")!);
    }
  }, [language]);

  return <div>{props.children}</div>;
};
