import React, { memo } from "react";
import { Link, Icon } from "@abb/abb-common-ux-react";
import { ICellRendererParams } from "ag-grid-community";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  word-break: break-word;
  white-space: pre-wrap;
  min-height: 58px;
`;

export const DetailsFlexWrapper = styled.div`
  display: inline-flex;
  width: max-content;
  align-items: center;
  margin-top: ${(props) => props.theme.sizes.s};
  :hover {
    cursor: pointer;
  }
`;

export const DetailsCustomLink = styled(Link)`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeXs};
`;

const DisplayName = styled.p`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
`;

export interface GridProductRendererProps extends ICellRendererParams {
  displayName: string | undefined | null;
  orderingCode: string;
  showDetailsText: string;
  onClick: () => void;
  icon: string;
  isValid: boolean;
  showDetails: boolean;
}

export const GridProductRenderer = memo((props: GridProductRendererProps) => {
  if (!props.isValid) {
    return null;
  }

  return (
    <Container>
      <DisplayName>{props.displayName}</DisplayName>
      {props.showDetails ? (
        <DetailsFlexWrapper onClick={() => props.onClick()}>
          <DetailsCustomLink discreet>{props.showDetailsText}</DetailsCustomLink>
          <Icon name={props.icon} sizeClass={"medium"} />
        </DetailsFlexWrapper>
      ) : null}
    </Container>
  );
});
