import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { AppContentWrapper } from "../../../framework/components/AppContentWrapper";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getUpdateUserSelectedCompanyRequest,
  getUserAccessRights,
  getUserInformation
} from "../../common/reducers/userReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import {
  getShoppingCart,
  getGetShoppingCartByIdStatus,
  getRequestQuoteStatus,
  getActivateShoppingCartStatus,
  getUploadAttachmentStatus,
  getDeleteAttachmentStatus,
  getSalesChannels,
  getPlaceOrderWithoutApprovalStatus,
  getRejectApprovalStatus,
  getApproveOrderOrQuoteStatus,
  getPlaceOrderStatus,
  getDeleteShoppingCartModificationItemSerialNumberStatus,
  getDeleteShoppingCartModificationItemStatus,
  getDisableOrdering,
  getDisableQuoting,
  getRedirectToOrderDetails,
  getAdditionalQuoteReceivers,
  getDefaultQuoteReceivers,
  getUploadQuoteAttachmentsStatus,
  getDeleteQuoteAttachmentStatus,
  getDraftQuoteFiles,
  getEmailSoftwareVerification,
  getQuote,
  getCreateQuoteRevisionRequestState,
  getRequestAcknowledgeRevisionState,
  getQuoteAttachments,
  getRequestQuoteApprovalRequestState,
  getSendQuoteWithoutApprovalRequestState,
  getRequestQuoteRevisionRequestState,
  getSaveMessageToRequestorStatus,
  getAddAdditionalQuoteRequestersStatus
} from "../../common/reducers/shoppingCartReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { getDeleteShoppingCartItemStatus } from "../../common/reducers/shoppingCartReducer";
import { AddProductDialog } from "../components/AddProductDialog";
import {
  ShoppingCartStateTypes,
  ShoppingCartEndCustomerDto,
  ShoppingCartDeliveryAddressDto,
  ShoppingCartBillingAddressDto,
  ShoppingCartAccountCustomerDto,
  ShoppingCartAttachmentDto,
  SalesChannelDto,
  AccessRights,
  ReviseQuoteDto,
  ReviseQuoteItemDto,
  QuoteStateTypes,
  ShoppingCartConsigneeDto
} from "api";
import { EditProductDialog } from "../components/EditProductDialog";
import { ShoppingCartItemDto } from "../../../api/models/ShoppingCartItemDto";
import { usePrevious } from "../../../framework/hooks/usePrevious";
import styled, { useTheme } from "styled-components";
import { BottomSectionShoppingCart } from "../components/BottomSection";
import { ShoppingCartTabs } from "../../../enums/shoppingCartTabs";
import { getRequestOrderApprovalStatus } from "../../common/reducers/shoppingCartReducer";
import { TabContentContainer } from "./TabContentContainer";
import { quickPriceCheckSagas } from "applications/quickPriceCheck/sagas/quickPriceCheckSagas";
import { RequestQuotationDialog } from "../components/RequestQuotationDialog";
import { scrollToTop } from "utilities/domUtils";
import { userActions } from "applications/common/actions/userActions";
import {
  currentUserIsQuoteRequester,
  getActiveTab,
  getShoppingCartStateText,
  getTabAndButtonStates,
  isInEu,
  isQuoteExpired,
  updateAttachments,
  updateProFormaItems
} from "../helpers";
import { shoppingCartActions } from "applications/common/actions/shoppingCartActions";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "utilities/routes";
import { OrderDetailsSection } from "../components/OrderDetailsSection";
import { RedirectDialog } from "../../../framework/components/RedirectDialog";
import { Formik, FormikProps, setNestedObjectValues } from "formik";
import { getOrderValidationSchema, getCreateQuoteValidationSchema } from "../validationSchema";
import { FormikListener } from "../../../framework/components/FormikListener";
import {
  mapQuoteToReviseQuote,
  mapShoppingCartFormToRequestOrderApprovalOrPlaceWithoutApprovalCommand,
  mapShoppingCartToShoppingCartForm
} from "utilities/mappings";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { ApprovalDialog } from "../components/ApprovalDialog";
import {
  getCompanyApprovers,
  getCompanyPgCodes,
  getCompanyTermsOfPayment
} from "../reducers/shoppingCartCompanyReducer";
import { handleFormikValueChange } from "utilities/formikUtils";
import Stepper, { Step } from "framework/components/Stepper";
import { createGmdModificationCaseRequest } from "applications/common/reducers/modificationSaleReducer";
import { shoppingCartCompanySagas } from "../sagas/shoppingCartCompanySagas";
import { RejectQuoteDialog } from "../components/RejectQuoteDialog";
import { commonUXTheme } from "styles/commonUXVariables";
import { MaintenanceBanner, SectionToDisable } from "framework/components/MaintenanceBanner";
import { deliveriesHomeSagas } from "applications/deliveries/deliveriesHome/sagas/deliveriesHomeSagas";
import { Button } from "framework/components/Button";
import { hasAnyAccessRight } from "utilities/authUtils";
import { getGeneralApplicationSettings } from "applications/deliveries/deliveriesHome/reducers/deliveriesHomeViewReducer";
import { ShoppingCartStatusBanner } from "../components/ShoppingCartStatusBanner";
import { getApiRegistry } from "framework/state/apiRegistry";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { SoftwarePurchaseEmailDialog } from "../components/SoftwarePurchaseEmailDialog";
import {
  ReviseQuoteFromCart,
  initialReviseValues
} from "applications/manage/manageQuotes/containers/QuoteView";
import { ReviseQuotationDialog } from "../components/ReviseQuotationDialog";
import { getDateTime, getValidDateFormat } from "utilities/dateUtils";
import { userSagas } from "applications/common/sagas/userSagas";
import { priceStringToFloatNumber } from "utilities/currencyUtils";
import { RequestRevisionDialog } from "../components/RequestRevisionDialog";

const FlexRowWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  width: 100%;
  height: 100%;
`;

const GridInformationWrapper = styled.div<{ isDetailedView?: boolean }>`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
  flex-direction: ${(props) => (props.isDetailedView ? "column" : "row")};
`;

const CustomStepper = styled(Stepper)`
  margin-bottom: ${(props) => props.theme.sizes.l};
  width: 100%;

  /* Smaller devices */
  @media only screen and (max-width: 1400px) {
    width: 95%;
  }

  /* Large devices (large laptops and desktops, 1400px and up) */
  @media only screen and (min-width: 1400px) {
    width: 55%;
  }
`;

export interface ShoppingCartForm {
  identifier: string;
  projectName: string | undefined;
  customerReference: string | undefined | null;
  endCustomerReference: string | undefined | null;
  salesChannel: SalesChannelDto | undefined;
  hasRiskReviewBeenDone: boolean | null | undefined;
  isEndCustomer: boolean | null | undefined;
  endCustomer?: ShoppingCartEndCustomerDto;
  deliveryAddress?: ShoppingCartDeliveryAddressDto;
  billingAddress?: ShoppingCartBillingAddressDto;
  accountCustomer?: ShoppingCartAccountCustomerDto;
  consignee?: ShoppingCartConsigneeDto;
  requestedDeliveryDate: string | undefined;
  acceptPartialDelivery: boolean | null | undefined;
  acceptEarlierDelivery?: boolean | null | undefined;
  forwarderInfo: string | undefined;
  additionalInfo: string | undefined;
  shippingMarks: string | undefined;
  specialInstructions: string | undefined;
  hasCommercialInvoice: boolean | null | undefined;
  proFormaItems?: ProFormaItem[];
  vatNumber: string | undefined;
  isCustomDeliveryDate: boolean | null | undefined;
  riskReviews: ShoppingCartAttachmentDto[];
  commercialInvoices: ShoppingCartAttachmentDto[];
  isPriorityConfirmed: boolean | undefined;
  termsOfDeliveryCode: string | null | undefined;
  termsOfDeliveryDescription: string | null | undefined;
  useDefaultTermsOfDelivery: boolean | null | undefined;
  requestedTermsOfDeliveryCode: string | null | undefined;
  requestedTermsOfDeliveryDescription: string | null | undefined;
  requestedOrderApproverEmail: string | null | undefined;
  termsOfPaymentDescription: string | null | undefined;
  pgCode: string | null | undefined;
  useProFormaOrCommercialInvoice: boolean | undefined;
}

export interface ProFormaItem {
  shoppingCartItemId: number;
  orderingCode: string;
  displayName?: string | null | undefined;
  unitPrice?: string | null | undefined;
  unitQuotedPrice?: string | null | undefined;
  price?: string | null | undefined;
  quotedPrice?: string | null | undefined;
  quantity: number;
  proFormaPrice: number | undefined;
}

const allowedToChangeTermsStates = [ShoppingCartStateTypes.Draft, ShoppingCartStateTypes.Quote];

// isReviseMode: Complete, Partial, None
export enum ReviseMode {
  Complete = "Complete",
  Partial = "Partial",
  None = "None"
}

export type NestedShoppingCartFormKeys =
  | "endCustomer"
  | "deliveryAddress"
  | "billingAddress"
  | "accountCustomer"
  | "consignee";

const ShoppingCartView = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { shoppingCartGuid } = useParams<{ shoppingCartGuid?: string }>();

  // State
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isRequestQuotationDialogOpen, setIsRequestQuotationDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isReviseQuotationDialogOpen, setIsReviseQuotationDialogOpen] = useState(false);
  const [isRequestRevisionDialogOpen, setIsRequestRevisionDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(ShoppingCartTabs.BuildCart);
  const [selectedShoppingCartItem, setSelectedShoppingCartItem] = useState<
    ShoppingCartItemDto | undefined
  >();
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [isRejectQuoteDialogOpen, setIsRejectQuoteDialogOpen] = useState(false);
  const [isSelfQuote, setIsSelfQuote] = useState(false);
  const [openRegistrationRequired, setOpenRegistrationRequired] = useState(false);
  const [isReviseMode, setIsReviseMode] = useState(ReviseMode.None);
  const [updateSelectedCompanyRedirect, setUpdateSelectedCompanyRedirect] = useState(false);
  const [isApprovalRevisionDialogOpen, setIsApprovalRevisionDialogOpen] = useState(false);

  // Selector
  const userInformation = useSelector(getUserInformation);
  const userAccessRights = useSelector(getUserAccessRights);
  const requestOrderApprovalStatus = useSelector(getRequestOrderApprovalStatus);
  const deleteShopingCartItemStatus = useSelector(getDeleteShoppingCartItemStatus);
  const deleteShopingCartModificationItemStatus = useSelector(
    getDeleteShoppingCartModificationItemStatus
  );
  const deleteShopingCartModificationItemSerialNumberStatus = useSelector(
    getDeleteShoppingCartModificationItemSerialNumberStatus
  );
  const getShoppingCartByIdStatus = useSelector(getGetShoppingCartByIdStatus);
  const requestQuotationStatus = useSelector(getRequestQuoteStatus);
  const activateShoppingCartStatus = useSelector(getActivateShoppingCartStatus);
  const uploadAttachmentStatus = useSelector(getUploadAttachmentStatus);
  const deletedAttachmentStatus = useSelector(getDeleteAttachmentStatus);
  const updateUserSelectedCompanyRequest = useSelector(getUpdateUserSelectedCompanyRequest);
  const requestQuoteApprovalRequestState = useSelector(getRequestQuoteApprovalRequestState);
  const sendQuoteWithoutApprovalRequestState = useSelector(getSendQuoteWithoutApprovalRequestState);
  const countries = useSelector(getCountries);
  const salesChannels = useSelector(getSalesChannels);
  const placeOrderWithoutApprovalStatus = useSelector(getPlaceOrderWithoutApprovalStatus);
  const rejectApprovalStatus = useSelector(getRejectApprovalStatus);
  const approveOrderOrQuoteStatus = useSelector(getApproveOrderOrQuoteStatus);
  const placeOrderStatus = useSelector(getPlaceOrderStatus);
  const pgCodes = useSelector(getCompanyPgCodes);
  const termsOfPayment = useSelector(getCompanyTermsOfPayment);
  const modificationSaleRequest = useSelector(createGmdModificationCaseRequest);
  const companyApprovers = useSelector(getCompanyApprovers);
  const shoppingCart = useSelector(getShoppingCart);
  const disableOrdering = useSelector(getDisableOrdering);
  const disableQuoting = useSelector(getDisableQuoting);
  const applicationSettings = useSelector(getGeneralApplicationSettings);
  const redirectToOrderDetails = useSelector(getRedirectToOrderDetails);
  const additionalQuoteReceivers = useSelector(getAdditionalQuoteReceivers);
  const defaultQuoteReceivers = useSelector(getDefaultQuoteReceivers);
  const deleteQuoteAttachmentStatus = useSelector(getDeleteQuoteAttachmentStatus);
  const requestQuoteRevisionRequestState = useSelector(getRequestQuoteRevisionRequestState);
  const quoteRevisionRequestState = useSelector(getCreateQuoteRevisionRequestState);
  const uploadQuoteAttachmentsStatus = useSelector(getUploadQuoteAttachmentsStatus);
  const acknowledgeRevisionState = useSelector(getRequestAcknowledgeRevisionState);
  const addAdditionalQuoteRequestersStatus = useSelector(getAddAdditionalQuoteRequestersStatus);
  const saveMessageToRequestorStatus = useSelector(getSaveMessageToRequestorStatus);
  const draftQuoteFiles = useSelector(getDraftQuoteFiles);
  const emailSoftwareVerification = useSelector(getEmailSoftwareVerification);
  const quote = useSelector(getQuote);
  const quoteAttachments = useSelector(getQuoteAttachments);

  // Previous
  const prevActivateShoppingCartStatus = usePrevious(activateShoppingCartStatus);
  const prevUploadAttachmentStatus = usePrevious(uploadAttachmentStatus);
  const prevDeletedAttachmentStatus = usePrevious(deletedAttachmentStatus);
  const prevRequestQuotationStatus = usePrevious(requestQuotationStatus);
  const prevRequestOrderApprovalStatus = usePrevious(requestOrderApprovalStatus);
  const prevDeleteShopingCartItemStatus = usePrevious(deleteShopingCartItemStatus);
  const prevDeleteShopingCartModificationItemStatus = usePrevious(
    deleteShopingCartModificationItemStatus
  );
  const prevDeleteShopingCartModificationItemSerialNumberStatus = usePrevious(
    deleteShopingCartModificationItemSerialNumberStatus
  );
  const prevPlaceOrderWithoutApprovalStatus = usePrevious(placeOrderWithoutApprovalStatus);
  const prevRejectApprovalStatus = usePrevious(rejectApprovalStatus);
  const prevApproveOrderOrQuoteStatus = usePrevious(approveOrderOrQuoteStatus);
  const prevUploadQuoteAttachmentsStatus = usePrevious(uploadQuoteAttachmentsStatus);
  const prevRequestQuoteRevisionRequestState = usePrevious(requestQuoteRevisionRequestState);
  const prevQuoteRevisionRequestState = usePrevious(quoteRevisionRequestState);
  const prevAcknowledgeRevisionState = usePrevious(acknowledgeRevisionState);
  const prevUpdateUserSelectedCompanyRequest = usePrevious(updateUserSelectedCompanyRequest);
  const prevSaveMessageToRequestorStatus = usePrevious(saveMessageToRequestorStatus);
  const prevAddAdditionalQuoteRequestersStatus = usePrevious(addAdditionalQuoteRequestersStatus);

  // Ref
  // Value to compare current to avoid fetching price lists when not needed.
  const prevSelectedCompanyId = useRef<string | null | undefined>("-1");
  const formikRef = useRef<FormikProps<ShoppingCartForm>>();
  const formikReviseRef = useRef<FormikProps<ReviseQuoteFromCart>>();

  const selectedShoppingCartGuid = useMemo(
    () => shoppingCartGuid ?? userInformation?.selectedShoppingCartGuid,
    [shoppingCartGuid, userInformation]
  );

  const company = useMemo(
    () => shoppingCart?.company ?? userInformation?.selectedCompany,
    [shoppingCart, userInformation]
  );

  const isActiveCart = useMemo(
    () =>
      !!shoppingCart &&
      !!userInformation &&
      shoppingCart.guid === userInformation.selectedShoppingCartGuid,
    [shoppingCart, userInformation]
  );
  const isPMS = useMemo(
    () => hasAnyAccessRight(quote?.baseQuote?.companyCountry, userAccessRights, AccessRights.Pms),
    [quote?.baseQuote?.companyCountry, userAccessRights]
  );

  const currentUserCanAcknowledgeRevision = useMemo(
    () =>
      (quote?.baseQuote &&
        (currentUserIsQuoteRequester(quote.baseQuote, userInformation) || isPMS)) ??
      false,
    [isPMS, quote?.baseQuote, userInformation]
  );

  const { tabs, isSecondaryButtonDisabled, isPrimaryButtonDisabled } = useMemo(
    () =>
      getTabAndButtonStates(
        shoppingCart,
        userInformation,
        activeTab,
        isValidForm,
        isActiveCart,
        userAccessRights,
        draftQuoteFiles.length > 0,
        isReviseMode,
        currentUserCanAcknowledgeRevision,
        t,
        isPMS,
        quote
      ),
    [
      shoppingCart,
      userInformation,
      activeTab,
      isValidForm,
      isActiveCart,
      userAccessRights,
      draftQuoteFiles.length,
      isReviseMode,
      currentUserCanAcknowledgeRevision,
      t,
      isPMS,
      quote
    ]
  );

  const validationSchema = useMemo(
    () => getOrderValidationSchema(t, company, countries),
    [t, company, countries]
  );

  const validationReviseSchema = useMemo(() => getCreateQuoteValidationSchema(t), [t]);

  const hasOrderApprover = useMemo(
    () =>
      (companyApprovers && companyApprovers?.length > 0) ||
      !!shoppingCart?.requestedOrderApproverEmail,

    [companyApprovers, shoppingCart]
  );

  const companyIsInternalOrMaintained = useMemo(
    () =>
      shoppingCart?.company?.isMaintainedCustomer === true ||
      shoppingCart?.company?.isInternal === true,
    [shoppingCart]
  );

  const shouldForceAnotherUserAsApprover = () => {
    return (
      (!companyApprovers || companyApprovers.length === 0) &&
      (quote?.currentUserCanBeQuoteApprover === false || quote === undefined)
    );
  };

  const isASM = useMemo(
    () =>
      hasAnyAccessRight(
        undefined,
        userAccessRights,
        AccessRights.ManageMarginAnalysisToolQuotesAsAsm
      ),
    [userAccessRights]
  );

  const isProcurist = useMemo(
    () =>
      hasAnyAccessRight(
        undefined,
        userAccessRights,
        AccessRights.ManageMarginAnalysisToolQuotesProcurist
      ),
    [userAccessRights]
  );

  const countryToCheck = useMemo(() => company?.countryCode, [company]);
  const hasAccessToViewQuote = hasAnyAccessRight(
    countryToCheck,
    userAccessRights,
    AccessRights.LocalQuoteHandler,
    AccessRights.LocalContactRegionalSales,
    AccessRights.Pms,
    AccessRights.ManagePriceLists,
    AccessRights.ViewAllPriceLists,
    AccessRights.ApproveQuotes,
    AccessRights.RequestedQuoteApprover,
    AccessRights.ViewCartsAndQuotes
  );

  useEffect(() => {
    // refetch the shopping cart when returning to this view
    return () => {
      dispatch(shoppingCartActions.setShoppingCart(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      (uploadAttachmentStatus === RequestStatus.Completed &&
        prevUploadAttachmentStatus === RequestStatus.Pending) ||
      (deletedAttachmentStatus === RequestStatus.Completed &&
        prevDeletedAttachmentStatus === RequestStatus.Pending)
    ) {
      if (formikRef.current) {
        updateAttachments(
          formikRef.current,
          shoppingCart?.riskReviews,
          shoppingCart?.commercialInvoices
        );
      }
    }
    // eslint-disable-next-line
  }, [
    uploadAttachmentStatus,
    prevUploadAttachmentStatus,
    deletedAttachmentStatus,
    prevDeletedAttachmentStatus
  ]);

  useEffect(() => {
    if (!salesChannels) {
      dispatch(shoppingCartSagas.getSalesChannels.createAction(undefined));
    }
  }, [salesChannels, dispatch]);

  useEffect(() => {
    if (
      shoppingCart?.stateId === ShoppingCartStateTypes.Draft &&
      (company?.isInternal || company?.isMaintainedCustomer)
    ) {
      if (userInformation?.selectedCompanyId) {
        dispatch(shoppingCartSagas.getAdditionalQuoteReceivers.createAction(undefined));
      }

      // Only fetch default quote receivers if the user is the owner of the shopping cart
      if (shoppingCart?.ownerEmail === userInformation?.email) {
        dispatch(
          shoppingCartSagas.getDefaultQuoteReceivers.createAction({
            guid: shoppingCart?.guid
          })
        );
      }
    }
  }, [
    dispatch,
    company,
    shoppingCart?.stateId,
    shoppingCart?.guid,
    userInformation?.selectedCompanyId,
    shoppingCart?.ownerEmail,
    userInformation?.email
  ]);

  useEffect(() => {
    if (
      (prevRejectApprovalStatus === RequestStatus.Pending &&
        rejectApprovalStatus === RequestStatus.Completed) ||
      (prevApproveOrderOrQuoteStatus === RequestStatus.Pending &&
        approveOrderOrQuoteStatus === RequestStatus.Completed)
    ) {
      navigate(
        {
          pathname: routes.shoppingCarts
        },
        {
          replace: true
        }
      );
    }
  }, [
    prevRejectApprovalStatus,
    rejectApprovalStatus,
    prevApproveOrderOrQuoteStatus,
    approveOrderOrQuoteStatus,
    navigate
  ]);

  // Get shopping cart
  useEffect(() => {
    if (selectedShoppingCartGuid) {
      dispatch(
        shoppingCartSagas.getShoppingCartById.createAction({
          guid: selectedShoppingCartGuid
        })
      );
    }
  }, [dispatch, selectedShoppingCartGuid]);

  useEffect(() => {
    if (company?.id && company.id !== prevSelectedCompanyId.current) {
      // fetch price lists when company changes
      prevSelectedCompanyId.current = company.id;
      // fetch price lists when company changes
      dispatch(quickPriceCheckSagas.getUserPriceLists.createAction(undefined));

      // set new terms of delivery and terms of payment
      if (
        formikRef.current &&
        shoppingCart &&
        allowedToChangeTermsStates.includes(shoppingCart.stateId)
      ) {
        // set default terms of delivery on company change
        if (formikRef.current) {
          handleFormikValueChange(
            formikRef.current,
            "termsOfDeliveryCode",
            company.defaultTermsOfDeliveryCode
          );

          handleFormikValueChange(
            formikRef.current,
            "requestedTermsOfDeliveryCode",
            company.defaultTermsOfDeliveryCode
          );

          if (!shoppingCart?.pgCode) {
            handleFormikValueChange(formikRef.current, "pgCode", company.defaultPgCode);
          }
        }
      }

      // get company users for new company
      if (
        shoppingCart?.stateId === ShoppingCartStateTypes.Draft ||
        shoppingCart?.stateId === ShoppingCartStateTypes.Quote
      ) {
        dispatch(shoppingCartCompanySagas.getCompanyApprovers.createAction({ id: company.id }));
      }
    }
  }, [dispatch, company, shoppingCart]);

  useEffect(() => {
    if (
      formikRef.current &&
      shoppingCart &&
      allowedToChangeTermsStates.includes(shoppingCart.stateId) &&
      termsOfPayment
    ) {
      handleFormikValueChange(
        formikRef.current,
        "termsOfPaymentDescription",
        termsOfPayment.description
      );
    }
    // eslint-disable-next-line
  }, [termsOfPayment]);

  useEffect(() => {
    if (
      (requestOrderApprovalStatus === RequestStatus.Completed &&
        prevRequestOrderApprovalStatus === RequestStatus.Pending) ||
      (requestQuotationStatus === RequestStatus.Completed &&
        prevRequestQuotationStatus === RequestStatus.Pending &&
        draftQuoteFiles.length === 0) ||
      (placeOrderWithoutApprovalStatus === RequestStatus.Completed &&
        prevPlaceOrderWithoutApprovalStatus === RequestStatus.Pending) ||
      (uploadQuoteAttachmentsStatus === RequestStatus.Completed &&
        prevUploadQuoteAttachmentsStatus === RequestStatus.Pending &&
        isRequestQuotationDialogOpen)
    ) {
      setIsRequestQuotationDialogOpen(false);
      setIsApprovalDialogOpen(false);
      if (selectedShoppingCartGuid) {
        navigate(
          {
            pathname: `${routes.shoppingCart}/${selectedShoppingCartGuid}`
          },
          {
            replace: true
          }
        );
      }
      if (shoppingCart) {
        formikRef.current?.setValues(
          mapShoppingCartToShoppingCartForm(
            shoppingCart,
            company?.defaultTermsOfDeliveryCode,
            termsOfPayment?.description,
            company?.defaultPgCode
          )
        );
      }
      scrollToTop(200);
    }
    // eslint-disable-next-line
  }, [
    requestOrderApprovalStatus,
    prevRequestOrderApprovalStatus,
    requestQuotationStatus,
    prevRequestQuotationStatus,
    placeOrderWithoutApprovalStatus,
    prevPlaceOrderWithoutApprovalStatus,
    uploadQuoteAttachmentsStatus,
    prevUploadQuoteAttachmentsStatus,
    company,
    dispatch,
    termsOfPayment,
    draftQuoteFiles,
    isRequestQuotationDialogOpen
  ]);

  // Could move some of this to sagas
  useEffect(() => {
    if (
      activateShoppingCartStatus === RequestStatus.Completed &&
      prevActivateShoppingCartStatus === RequestStatus.Pending &&
      selectedShoppingCartGuid
    ) {
      navigate(routes.shoppingCart, {
        replace: true
      });
      dispatch(
        shoppingCartSagas.getShoppingCartById.createAction({
          guid: selectedShoppingCartGuid
        })
      );
      // trigger to refresh the page to apply changes of current user(changing selected company)
      navigate(0);
    }
  }, [
    activateShoppingCartStatus,
    prevActivateShoppingCartStatus,
    navigate,
    dispatch,
    selectedShoppingCartGuid
  ]);

  useEffect(() => {
    if (isActiveCart && !shoppingCartGuid) {
      setActiveTab(ShoppingCartTabs.BuildCart);
    }
  }, [shoppingCartGuid, isActiveCart]);

  useEffect(() => {
    // Don't change / reset tab with active cart
    if (isActiveCart) {
      if (activeTab === ShoppingCartTabs.OrderInProcess) {
        setActiveTab(ShoppingCartTabs.BuildCart);
      }
    } else {
      if (isReviseMode == ReviseMode.None) {
        const redirectingToOrderDetails = redirectToOrderDetails && !!shoppingCart;

        const activeTab = redirectingToOrderDetails
          ? ShoppingCartTabs.OrderDetails
          : getActiveTab(shoppingCart?.stateId);
        setActiveTab(activeTab);

        if (redirectingToOrderDetails) {
          dispatch(shoppingCartActions.setRedirectToOrderDetails(false));
        }
      }
    }
    // eslint-disable-next-line
  }, [shoppingCart, isActiveCart]);

  useEffect(() => {
    if (company && shoppingCart && formikRef.current?.values.identifier !== shoppingCart?.guid) {
      formikRef.current?.setValues(
        mapShoppingCartToShoppingCartForm(
          shoppingCart,
          company.defaultTermsOfDeliveryCode,
          termsOfPayment?.description,
          company.defaultPgCode
        )
      );
    }
    updateProFormaItems(formikRef.current, shoppingCart?.shoppingCartItems);
  }, [termsOfPayment, shoppingCart, company]);

  // Fetch shopping cart after deleting a product
  useEffect(() => {
    if (
      ((deleteShopingCartItemStatus === RequestStatus.Completed &&
        prevDeleteShopingCartItemStatus === RequestStatus.Pending) ||
        (deleteShopingCartModificationItemStatus === RequestStatus.Completed &&
          prevDeleteShopingCartModificationItemStatus === RequestStatus.Pending) ||
        (deleteShopingCartModificationItemSerialNumberStatus === RequestStatus.Completed &&
          prevDeleteShopingCartModificationItemSerialNumberStatus === RequestStatus.Pending) ||
        (prevAcknowledgeRevisionState?.status === RequestStatus.Pending &&
          acknowledgeRevisionState?.status === RequestStatus.Completed) ||
        (prevSaveMessageToRequestorStatus === RequestStatus.Pending &&
          saveMessageToRequestorStatus === RequestStatus.Completed) ||
        (prevAddAdditionalQuoteRequestersStatus === RequestStatus.Pending &&
          addAdditionalQuoteRequestersStatus === RequestStatus.Completed)) &&
      selectedShoppingCartGuid
    ) {
      dispatch(
        shoppingCartSagas.getShoppingCartById.createAction({
          guid: selectedShoppingCartGuid
        })
      );
    }
  }, [
    deleteShopingCartItemStatus,
    prevDeleteShopingCartItemStatus,
    deleteShopingCartModificationItemStatus,
    prevDeleteShopingCartModificationItemStatus,
    deleteShopingCartModificationItemSerialNumberStatus,
    prevDeleteShopingCartModificationItemSerialNumberStatus,
    dispatch,
    selectedShoppingCartGuid,
    prevAcknowledgeRevisionState?.status,
    acknowledgeRevisionState?.status,
    prevSaveMessageToRequestorStatus,
    saveMessageToRequestorStatus,
    prevAddAdditionalQuoteRequestersStatus,
    addAdditionalQuoteRequestersStatus
  ]);

  useEffect(() => {
    if (
      updateUserSelectedCompanyRequest.status === RequestStatus.Completed &&
      prevUpdateUserSelectedCompanyRequest.status === RequestStatus.Pending
    ) {
      navigate(0);
    }
  }, [updateUserSelectedCompanyRequest, prevUpdateUserSelectedCompanyRequest, navigate]);

  useEffect(() => {
    const quoteId = shoppingCart?.activeQuote?.id ?? shoppingCart?.draftQuote?.id;
    if (quoteId && isReviseMode == ReviseMode.None && hasAccessToViewQuote)
      dispatch(
        shoppingCartSagas.getQuoteById.createAction({
          id: quoteId
        })
      );
    //no need to trigger on switching reviseMode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shoppingCart]);

  useEffect(() => {
    if (company && company?.id) {
      if (
        !(company?.isDeliverITRelevant && (company?.isInternal || company?.isMaintainedCustomer))
      ) {
        dispatch(
          shoppingCartSagas.getPreviousOrderSuggestion.createAction({
            companyId: company.id
          })
        );
      } else {
        dispatch(
          shoppingCartSagas.getCompanyPartnersInfo.createAction({
            companyId: company.id
          })
        );
      }
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (emailSoftwareVerification?.isOpenDialog) {
      setOpenRegistrationRequired(true);
    }
  }, [emailSoftwareVerification?.isOpenDialog]);

  useEffect(() => {
    if (quote && shoppingCart && company && isReviseMode !== ReviseMode.None) {
      formikReviseRef.current?.setValues(mapQuoteToReviseQuote(shoppingCart, quote, company));
    }
    // disable because of need to be applied only on enabling revise mode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isReviseMode]);

  const closeEmailSoftwareVerification = useCallback(
    (isOpen: boolean) => {
      dispatch(shoppingCartActions.setOpenRegistrationRequired(isOpen));
      setOpenRegistrationRequired(isOpen);
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      (prevQuoteRevisionRequestState?.status === RequestStatus.Pending &&
        quoteRevisionRequestState?.status === RequestStatus.Completed) ||
      (prevUploadQuoteAttachmentsStatus === RequestStatus.Pending &&
        uploadQuoteAttachmentsStatus === RequestStatus.Completed)
    ) {
      const id = quoteRevisionRequestState.responsePayload;
      if (id) {
        if (draftQuoteFiles.length === 0) {
          setIsReviseMode(ReviseMode.None);
          navigate(routes.shoppingCarts);
        } else {
          dispatch(
            shoppingCartSagas.uploadQuoteAttachments.createAction({
              id: id,
              files: draftQuoteFiles
            })
          );
        }
      }
    }
  }, [
    dispatch,
    draftQuoteFiles,
    navigate,
    prevQuoteRevisionRequestState,
    prevUploadQuoteAttachmentsStatus,
    quoteRevisionRequestState,
    uploadQuoteAttachmentsStatus
  ]);

  useEffect(() => {
    if (
      prevRequestQuoteRevisionRequestState?.status === RequestStatus.Pending &&
      requestQuoteRevisionRequestState?.status === RequestStatus.Completed
    ) {
      setIsRequestRevisionDialogOpen(false);
      if (selectedShoppingCartGuid) {
        dispatch(
          shoppingCartSagas.getShoppingCartById.createAction({
            guid: selectedShoppingCartGuid
          })
        );
      }
    }
    // trigger only on requestQuoteRevisionRequestState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prevRequestQuoteRevisionRequestState, requestQuoteRevisionRequestState]);

  useEffect(() => {
    if (
      userInformation?.selectedCompany &&
      (isReviseQuotationDialogOpen || isRequestQuotationDialogOpen)
    ) {
      dispatch(
        shoppingCartCompanySagas.getCompanyPgCodes.createAction({
          id: userInformation.selectedCompany.id
        })
      );
    }
  }, [
    userInformation?.selectedCompany,
    isReviseQuotationDialogOpen,
    isRequestQuotationDialogOpen,
    dispatch
  ]);

  const validateFormOrProceed = async () => {
    const validationErrors = await formikRef.current?.validateForm();
    if (validationErrors && Object.keys(validationErrors).length > 0) {
      formikRef.current?.setTouched(setNestedObjectValues(validationErrors, true));
    } else {
      setActiveTab(ShoppingCartTabs.ConfirmOrder);
    }
  };

  const openEditDialogAndSetSelectedShoppingCartItem = useCallback(
    (shoppingCartItem: ShoppingCartItemDto) => {
      setSelectedShoppingCartItem(shoppingCartItem);
      setIsEditDialogOpen((isOpen) => !isOpen);
    },
    []
  );

  const secondaryAction = () => {
    switch (activeTab) {
      case ShoppingCartTabs.BuildCart:
        if (shoppingCart?.stateId === ShoppingCartStateTypes.Draft) {
          openRequestQuotationDialog(false);
        } else if (shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote) {
          dispatch(
            userActions.addConfirmEvent(
              () =>
                dispatch(
                  shoppingCartSagas.cancelQuoteRequest.createAction({
                    quoteRequestCancelledCommand: { shoppingCartGuid: shoppingCart.guid }
                  })
                ),
              t("Cancel quotation request"),
              t("Are you sure you want to cancel quotation request?")
            )
          );
        } else if (shoppingCart?.stateId === ShoppingCartStateTypes.Quote) {
          if (isQuoteExpired(shoppingCart)) {
            dispatch(shoppingCartSagas.renewQuote.createAction({ guid: shoppingCart.guid }));
          } else {
            setIsRejectQuoteDialogOpen(true);
          }
        } else if (
          shoppingCart?.stateId === ShoppingCartStateTypes.FrameAgreement ||
          shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision
        ) {
          setIsRejectQuoteDialogOpen(true);
        }
        break;
      case ShoppingCartTabs.OrderDetails:
        setActiveTab(ShoppingCartTabs.BuildCart);
        break;
      case ShoppingCartTabs.ConfirmOrder:
        if (shoppingCart?.stateId === ShoppingCartStateTypes.OrderWaitingForApproval) {
          dispatch(
            userActions.addConfirmEvent(
              () =>
                dispatch(
                  shoppingCartSagas.rejectApproval.createAction({
                    rejectApprovalCommand: { shoppingCartIdentifier: shoppingCart.guid }
                  })
                ),
              t("Reject order"),
              t("Are you sure you want to reject this order?")
            )
          );
        } else {
          setActiveTab(ShoppingCartTabs.OrderDetails);
        }

        break;
    }
  };

  const isFrameAgreement = shoppingCart?.stateId === ShoppingCartStateTypes.FrameAgreement;

  const primaryAction = () => {
    if (isReviseMode !== ReviseMode.None) {
      if (companyIsInternalOrMaintained) {
        setIsReviseQuotationDialogOpen(true);
      } else {
        setIsApprovalRevisionDialogOpen(true);
      }
    } else {
      switch (activeTab) {
        case ShoppingCartTabs.BuildCart:
          if (shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForRevision) {
            if (quote) {
              const id = quote.id;
              dispatch(
                shoppingCartSagas.acknowledgeRevision.createAction({
                  acknowledgeRevisionCommand: { quoteId: id }
                })
              );
            }
          } else {
            if (isFrameAgreement) {
              // For frame agreements, create a new shopping cart based on this FA and edit that
              const frameAgreementId = shoppingCart.activeQuote?.id;
              if (frameAgreementId !== undefined) {
                getApiRegistry()
                  .shoppingCartsApi.apiShoppingCartsCreateShoppingCartFromFrameAgreementPost({
                    createShoppingCartFromFrameAgreementCommand: {
                      frameAgreementId: frameAgreementId
                    }
                  })
                  .then((res) => {
                    dispatch(userActions.updateSelectedShoppingCartGuid(res.guid));
                    navigate(routes.shoppingCart);
                  })
                  .catch((err) => {
                    console.error("Creating shopping cart from frame agreement failed:", err);
                  });
              } else {
                // TODO: Show proper error? This should never be reached
                console.error(
                  "Active quote id was undefined when trying to create a shopping cart from frame agreement"
                );
              }
            } else {
              setActiveTab(ShoppingCartTabs.OrderDetails);
            }
          }
          break;
        case ShoppingCartTabs.OrderDetails:
          validateFormOrProceed();
          break;
        case ShoppingCartTabs.ConfirmOrder:
          if (shoppingCart?.stateId === ShoppingCartStateTypes.OrderWaitingForApproval) {
            dispatch(
              userActions.addConfirmEvent(
                () =>
                  dispatch(
                    shoppingCartSagas.placeOrder.createAction({
                      placeOrderFromWaitingForApprovalCommand: {
                        shoppingCartIdentifier: shoppingCart.guid
                      }
                    })
                  ),
                t("Approve order"),
                t("Are you sure you want to approve this order?")
              )
            );
          } else {
            setIsApprovalDialogOpen(true);
          }
          break;
      }
    }
  };

  const submitForm = async (values: ShoppingCartForm, isOnlyAddedApprover: boolean) => {
    if (company && formikRef.current) {
      const validationErrors = await formikRef.current.validateForm();
      if (validationErrors && Object.keys(validationErrors).length > 0) {
        formikRef.current.setTouched(setNestedObjectValues(validationErrors, true));
        setActiveTab(ShoppingCartTabs.OrderDetails);
      }
      const command = mapShoppingCartFormToRequestOrderApprovalOrPlaceWithoutApprovalCommand(
        values,
        company.id,
        !company.isInternal,
        !!values.deliveryAddress?.countryCode &&
          isInEu(countries, values.deliveryAddress?.countryCode),
        isOnlyAddedApprover
      );
      if (hasOrderApprover || values.requestedOrderApproverEmail) {
        dispatch(
          shoppingCartSagas.requestOrderApproval.createAction({
            requestOrderApprovalCommand: command
          })
        );
      } else {
        dispatch(
          shoppingCartSagas.placeOrderWithoutApproval.createAction({
            placeOrderWithoutApprovalCommand: command
          })
        );
      }
    }
  };

  const getEmailsList = () => {
    if (companyApprovers && companyApprovers.length > 0) {
      return companyApprovers.join("\r\n");
    }
    return "";
  };

  const requestQuoteRevision = (revisionMessage: string) => {
    const quoteId = quote?.id;
    if (quoteId) {
      dispatch(
        shoppingCartSagas.requestQuoteRevision.createAction({
          requestQuoteRevisionCommand: {
            id: quoteId,
            revisionMessage: revisionMessage
          }
        })
      );
    }
  };

  const quoteCanBeTransferredToMat = () => {
    return (
      isASM &&
      shoppingCart?.quoteIdWaitingToBeProcessed &&
      (shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote ||
        shoppingCart.stateId === ShoppingCartStateTypes.WaitingForFrameAgreement) &&
      (shoppingCart?.company?.isMaintainedCustomer || shoppingCart?.company?.isInternal)
    );
  };

  const quoteAttachmentsPanelIsVisible =
    (isASM || isProcurist) &&
    (!!shoppingCart?.company?.isMaintainedCustomer || !!shoppingCart?.company?.isInternal) &&
    (!!shoppingCart.draftQuote || !!shoppingCart.activeQuote);

  const showReviseButton = useMemo((): boolean => {
    return (
      !!shoppingCart &&
      (shoppingCart.stateId === ShoppingCartStateTypes.Quote ||
        shoppingCart.stateId === ShoppingCartStateTypes.FrameAgreement ||
        shoppingCart.stateId === ShoppingCartStateTypes.OrderPlaced ||
        shoppingCart.stateId === ShoppingCartStateTypes.OrderPlacedExternally ||
        shoppingCart.stateId === ShoppingCartStateTypes.Archived ||
        (shoppingCart.stateId === ShoppingCartStateTypes.Rejected &&
          !!shoppingCart.baseShoppingCartId)) &&
      !!userInformation &&
      !!quote &&
      (quote.quotedById === userInformation.id || isASM) &&
      activeTab === ShoppingCartTabs.BuildCart &&
      !disableQuoting
    );
  }, [activeTab, disableQuoting, isASM, quote, shoppingCart, userInformation]);

  const showRequestRevisionButton = useMemo((): boolean => {
    return (
      !!shoppingCart &&
      (shoppingCart.stateId === ShoppingCartStateTypes.Quote ||
        shoppingCart.stateId === ShoppingCartStateTypes.FrameAgreement ||
        shoppingCart.stateId === ShoppingCartStateTypes.OrderPlaced ||
        shoppingCart.stateId === ShoppingCartStateTypes.OrderPlacedExternally ||
        (shoppingCart.stateId === ShoppingCartStateTypes.Rejected &&
          !!shoppingCart.baseShoppingCartId)) &&
      !!quote &&
      !quote.requestedRevisionById &&
      shoppingCart.baseShoppingCart?.stateId !== ShoppingCartStateTypes.Revised &&
      isReviseMode == ReviseMode.None &&
      !(isASM || isProcurist) &&
      !!userInformation &&
      quote.quotedById !== userInformation?.id
    );
  }, [isASM, isProcurist, isReviseMode, quote, shoppingCart, userInformation]);

  const createQuoteRevision = (
    values: ReviseQuoteFromCart,
    approverEmail?: string,
    isOnlyAddedApprover?: boolean,
    sendQuoteWithoutApproval?: boolean
  ) => {
    if (shoppingCart) {
      const quoteId = quote?.id;

      const accountCustomerDetails = values?.isAccountCustomer
        ? {
            accountCustomerCompanyName: company?.name,
            accountCustomerCompanyStreetAddress: company?.addressStreet,
            accountCustomerCompanyCity: company?.addressCity,
            accountCustomerCompanyCountry: company?.countryName,
            accountCustomerCompanyCountryCode: company?.countryCode,
            accountCustomerIcvCode: company?.globalCustomerNumber
          }
        : {
            accountCustomerCompanyName: values?.accountCustomerCompanyName,
            accountCustomerCompanyStreetAddress: values?.accountCustomerCompanyStreetAddress,
            accountCustomerCompanyCity: values?.accountCustomerCompanyCity,
            accountCustomerCompanyCountry: values?.accountCustomerCompanyCountry,
            accountCustomerCompanyCountryCode: values?.accountCustomerCompanyCountryCode,
            accountCustomerIcvCode: values?.accountCustomerIcvCode
          };

      const endCustomerDetails = values?.isEndCustomer
        ? {
            endCustomerCompanyName: company?.name,
            endCustomerCompanyStreetAddress: company?.addressStreet,
            endCustomerCompanyCity: company?.addressCity,
            endCustomerCompanyCountry: company?.countryName,
            endCustomerCompanyCountryCode: company?.countryCode,
            endCustomerCompanyGuid: company?.globalCustomerNumber
          }
        : {
            endCustomerCompanyName: values?.endCustomerCompanyName,
            endCustomerCompanyStreetAddress: values?.endCustomerCompanyStreetAddress,
            endCustomerCompanyCity: values?.endCustomerCompanyCity,
            endCustomerCompanyCountry: values?.endCustomerCompanyCountry,
            endCustomerCompanyCountryCode: values?.endCustomerCompanyCountryCode,
            endCustomerCompanyGuid: values?.endCustomerGuid
          };

      const reviseQuoteItems: ReviseQuoteItemDto[] = [];

      shoppingCart.shoppingCartItems.forEach((item) => {
        const reviseQuoteItemDto: ReviseQuoteItemDto = {
          orderingCode: item.orderingCode,
          quantity: item.quantity,
          additionalInformation: item.additionalInformation,
          quotedPrice: !companyIsInternalOrMaintained
            ? priceStringToFloatNumber(item.unitQuotedPrice)
            : undefined
        };
        reviseQuoteItems.push(reviseQuoteItemDto);
      });

      const reviseQuoteDto: ReviseQuoteDto = {
        stateId: QuoteStateTypes.Draft,
        ...accountCustomerDetails,
        ...endCustomerDetails,
        winningPercentage: companyIsInternalOrMaintained ? values.winningPercentage : undefined,
        salesChannelId: companyIsInternalOrMaintained ? values.salesChannelId : undefined,
        pgCode: companyIsInternalOrMaintained ? values.pgCode : undefined,
        projectName: values?.projectName,
        sfdcReference: values?.sfdcReference,
        expectedOrderDate: values?.expectedOrderDate
          ? getDateTime(values?.expectedOrderDate, getValidDateFormat())
          : undefined,
        tenderValidityDate: values?.tenderValidityDate
          ? getDateTime(values?.tenderValidityDate, getValidDateFormat())
          : undefined,
        items: [],
        revisionItems: reviseQuoteItems,
        quoteAttachmentsToBeCopied: quoteAttachments.filter((qa) => qa.identifier !== ""),
        totalSalesPrice: shoppingCart?.company?.isInternalBuyer
          ? values?.totalSalesPrice
          : undefined
      };

      if (quoteId) {
        dispatch(
          shoppingCartSagas.createQuoteRevision.createAction({
            id: quoteId,
            reviseQuoteDto,
            approverEmail,
            isOnlyAddedApprover,
            sendQuoteWithoutApproval,
            redirectToMAT: companyIsInternalOrMaintained
          })
        );
      }
    }
  };

  const drawAsmFunctionalityButton = () => {
    if (!isASM) {
      return null;
    }
    if (quoteCanBeTransferredToMat()) {
      return (
        <WithTooltip>
          <Button
            text={t("Transfer request to MAT and create quote")}
            buttonType="primary"
            href={`${shoppingCart?.matBasePath}?quote=${shoppingCart?.quoteIdWaitingToBeProcessed}`}
            target="_blank"
            disabled={draftQuoteFiles.length > 0}
          />
          <Tooltip disabled={draftQuoteFiles.length === 0}>
            {t("Please finish uploading attachments")}
          </Tooltip>
        </WithTooltip>
      );
    } else if (
      shoppingCart &&
      shoppingCart.company &&
      !shoppingCart.company.isMaintainedCustomer &&
      !shoppingCart.company.isInternal &&
      shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote &&
      shoppingCart.ownerEmail
    ) {
      return (
        <Button
          href={`mailto:${shoppingCart?.ownerEmail}?subject=${t("<appName> quote").replace(
            "<appName>",
            applicationSettings.applicationName
          )}`}
          text={t("Contact quote requestor")}
          icon="abb/envelope"
          buttonType="secondary"
        />
      );
    }
  };

  useEffect(() => {
    const companySelectionMismatchAndNotOrderApprover =
      !!userInformation?.selectedCompanyId &&
      !!shoppingCart?.company?.id &&
      userInformation.selectedCompanyId !== shoppingCart?.company?.id &&
      !shoppingCart.isOrderApprover;

    const isAsmAndShoppingCartIsWaitingForQuoteAndCompanyIsInternalOrMaintained =
      (isASM || isProcurist) &&
      shoppingCart?.stateId === ShoppingCartStateTypes.WaitingForQuote &&
      (shoppingCart?.company?.isInternal || shoppingCart.company?.isMaintainedCustomer);

    setUpdateSelectedCompanyRedirect(
      companySelectionMismatchAndNotOrderApprover &&
        !isAsmAndShoppingCartIsWaitingForQuoteAndCompanyIsInternalOrMaintained
    );
  }, [dispatch, isASM, isProcurist, shoppingCart, userInformation]);

  const openRequestQuotationDialog = useCallback(
    (isSelfQuote: boolean) => {
      setIsSelfQuote(isSelfQuote);
      setIsRequestQuotationDialogOpen(true);
    },
    [setIsSelfQuote, setIsRequestQuotationDialogOpen]
  );
  // Get system settings
  useEffect(() => {
    dispatch(deliveriesHomeSagas.getSystemSettings.createAction(undefined));
  }, [dispatch]);

  const getPageTitle = () => {
    if (shoppingCart && shoppingCart.stateId !== ShoppingCartStateTypes.Draft) {
      return getShoppingCartStateText(shoppingCart.stateId, t);
    } else {
      return t("Shopping cart");
    }
  };

  return (
    <AppContentWrapper
      isMainView
      showFooter
      isLoading={getShoppingCartByIdStatus === RequestStatus.Pending}
      pageTitle={
        shoppingCart?.isModificationSale ? t("Modification sales products") : getPageTitle()
      }
    >
      {isRejectQuoteDialogOpen && shoppingCart && (
        <RejectQuoteDialog
          t={t}
          setIsOpen={setIsRejectQuoteDialogOpen}
          isOpen={isRejectQuoteDialogOpen}
          shoppingCartGuid={shoppingCart.guid}
          isFrameAgreement={shoppingCart?.isBasedOnFrameAgreement}
        />
      )}
      {isProductDialogOpen && (
        <AddProductDialog
          t={t}
          isOpen={isProductDialogOpen}
          setIsOpen={setIsProductDialogOpen}
          shoppingCart={shoppingCart}
          dispatch={dispatch}
          hasUser={!!userInformation}
          reviseMode={isReviseMode == ReviseMode.Complete}
          isQuoteView={false}
        />
      )}
      {isEditDialogOpen && (
        <EditProductDialog
          selectedShoppingCartItem={selectedShoppingCartItem}
          dispatch={dispatch}
          t={t}
          isOpen={isEditDialogOpen}
          setIsOpen={setIsEditDialogOpen}
          reviseMode={isReviseMode == ReviseMode.Complete}
          isQuoteView={false}
        />
      )}
      {isApprovalDialogOpen && (
        <ApprovalDialog
          submitForm={(email, onlyAddedApprover) => {
            if (formikRef.current) {
              handleFormikValueChange(formikRef.current, "requestedOrderApproverEmail", email);
              // wait for React reconciliation until submitting the form
              setTimeout(() => {
                if (formikRef.current) {
                  submitForm(formikRef?.current?.values, !!onlyAddedApprover);
                }
              });
            }
          }}
          isOpen={isApprovalDialogOpen}
          closeDialog={() => setIsApprovalDialogOpen(false)}
          t={t}
          isLoading={
            requestOrderApprovalStatus === RequestStatus.Pending ||
            placeOrderWithoutApprovalStatus === RequestStatus.Pending
          }
          text={
            companyApprovers?.length
              ? {
                  title: "Confirm approver",
                  radioGroupTitle: "Approval will be sent to",
                  firstOptionLabel: "Default",
                  secondOptionLabel: "Other",
                  inputDescription: "Add a new approver",
                  textDescription: getEmailsList(),
                  buttonName1: "Request approval",
                  buttonName2: "Request approval",
                  isReversed: true,
                  checkboxLabel: "Also send to default approver(s)"
                }
              : {
                  title: "No order approver found in company",
                  radioGroupTitle: "Is approval required for this order?",
                  inputDescription: "Add an approver for order",
                  textDescription: "You can proceed to place the order",
                  buttonName1: "Request approval",
                  buttonName2: "Order"
                }
          }
        />
      )}
      {isRequestQuotationDialogOpen && !!userInformation?.selectedCompany && (
        <RequestQuotationDialog
          isBuildQuote={isSelfQuote}
          isOpen={isRequestQuotationDialogOpen}
          setIsOpen={setIsRequestQuotationDialogOpen}
          shoppingCart={shoppingCart}
          isLoading={requestQuotationStatus === RequestStatus.Pending}
          isQuoteFilesLoading={uploadQuoteAttachmentsStatus === RequestStatus.Pending}
          t={t}
          countries={countries}
          salesChannels={salesChannels}
          dispatch={dispatch}
          pgCodes={pgCodes}
          selectedCompany={userInformation?.selectedCompany}
          additionalQuoteReceivers={additionalQuoteReceivers}
          defaultQuoteReceivers={defaultQuoteReceivers}
        />
      )}
      {(disableOrdering || disableQuoting) && (
        <div style={{ marginBottom: parseInt(commonUXTheme.sizes.l) + 6, width: "100%" }}>
          <MaintenanceBanner
            disableSection={
              disableOrdering && disableQuoting
                ? SectionToDisable.Both
                : disableOrdering
                ? SectionToDisable.Ordering
                : SectionToDisable.Quoting
            }
          />
        </div>
      )}

      {openRegistrationRequired &&
        emailSoftwareVerification &&
        emailSoftwareVerification.shoppingCartItemId && (
          <SoftwarePurchaseEmailDialog
            isOpen={openRegistrationRequired}
            setIsOpen={closeEmailSoftwareVerification}
            email={emailSoftwareVerification.email}
            onConfirm={() => {
              dispatch(
                shoppingCartSagas.updateShoppingCartItemSoftwarePurchaseEmail.createAction({
                  id: emailSoftwareVerification.shoppingCartItemId,
                  updateShoppingCartItemSoftwarePurchaseEmailDto: {
                    softwarePurchaseEmail: emailSoftwareVerification.email,
                    sendEmail: true
                  }
                })
              );
              closeEmailSoftwareVerification(false);
            }}
          />
        )}
      {updateSelectedCompanyRedirect ? (
        <RedirectDialog
          showDialog={true}
          text={t(
            "Your active company will be changed automatically to shopping cart's company in order to view or edit this shopping cart.\n\n"
          )}
          t={t}
          redirectCallback={() => {
            dispatch(
              userSagas.updateUserSelectedCompany.createAction({
                updateUserSelectedCompanyCommand: {
                  selectedCompanyId: shoppingCart?.company?.id
                }
              })
            );
            setUpdateSelectedCompanyRedirect(false);
          }}
          countdownFrom={5}
          icon={"abb/warning-circle-1"}
          title={t("Changing selected company...")}
          hideBackground={false}
        />
      ) : undefined}

      <CustomStepper activeStep={activeTab} handleStep={(newIndex) => setActiveTab(newIndex)}>
        <Step
          key={ShoppingCartTabs.BuildCart}
          title={t("1. Build cart")}
          disabled={activeTab === ShoppingCartTabs.OrderInProcess || tabs.isBuildCartDisabled}
        >
          <Formik
            initialValues={initialReviseValues}
            //eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            validationSchema={validationReviseSchema}
            validateOnBlur
            validateOnChange
            validateOnMount
            innerRef={(ref) => {
              if (ref) {
                formikReviseRef.current = ref;
              }
            }}
          >
            {(formikRevise) => (
              <>
                {isReviseQuotationDialogOpen && userInformation?.selectedCompany ? (
                  <ReviseQuotationDialog
                    isOpen={isReviseQuotationDialogOpen}
                    setIsOpen={setIsReviseQuotationDialogOpen}
                    shoppingCart={shoppingCart}
                    isLoading={
                      quoteRevisionRequestState?.status === RequestStatus.Pending ||
                      uploadQuoteAttachmentsStatus === RequestStatus.Pending
                    }
                    t={t}
                    salesChannels={salesChannels}
                    createQuoteRevision={createQuoteRevision}
                    pgCodes={pgCodes}
                    formikRevise={formikRevise}
                    selectedCompany={userInformation?.selectedCompany}
                    reviseMode={isReviseMode}
                  />
                ) : null}
                {isRequestRevisionDialogOpen && userInformation?.selectedCompany ? (
                  <RequestRevisionDialog
                    isOpen={isRequestRevisionDialogOpen}
                    setIsOpen={setIsRequestRevisionDialogOpen}
                    isLoading={requestQuoteRevisionRequestState.status === RequestStatus.Pending}
                    t={t}
                    requestQuoteRevision={requestQuoteRevision}
                    onlyDateRevision={shoppingCart?.isFrameAgreementWithPartialOrder ? true : false}
                    validityDate={quote?.tenderValidityDate}
                  />
                ) : null}
                {isApprovalRevisionDialogOpen ? (
                  <ApprovalDialog
                    submitForm={(approverEmail, onlyAddedApprover, sendQuoteWithoutApproval) => {
                      if (shoppingCart) {
                        createQuoteRevision(
                          formikRevise.values,
                          approverEmail,
                          onlyAddedApprover,
                          sendQuoteWithoutApproval
                        );
                      }
                    }}
                    isOpen={isApprovalRevisionDialogOpen}
                    closeDialog={() => setIsApprovalRevisionDialogOpen(false)}
                    t={t}
                    isLoading={
                      approveOrderOrQuoteStatus === RequestStatus.Pending ||
                      rejectApprovalStatus === RequestStatus.Pending ||
                      sendQuoteWithoutApprovalRequestState?.status === RequestStatus.Pending ||
                      requestQuoteApprovalRequestState?.status === RequestStatus.Pending ||
                      quoteRevisionRequestState?.status === RequestStatus.Pending ||
                      uploadQuoteAttachmentsStatus === RequestStatus.Pending
                    }
                    forceApproval={shouldForceAnotherUserAsApprover()}
                    invalidApproverEmails={
                      shouldForceAnotherUserAsApprover() ? [userInformation?.email ?? ""] : []
                    }
                    text={
                      companyApprovers?.length
                        ? {
                            title: "Confirm approver",
                            radioGroupTitle: "Approval will be sent to",
                            firstOptionLabel: "Default",
                            secondOptionLabel: "Other",
                            inputDescription: "Add a new approver",
                            textDescription: getEmailsList(),
                            buttonName1: "Request approval",
                            buttonName2: "Request approval",
                            isReversed: true,
                            checkboxLabel: "Also send to default approver(s)"
                          }
                        : {
                            title: "No quote approver found in company",
                            radioGroupTitle: "Is approval required for this quote?",
                            inputDescription: shouldForceAnotherUserAsApprover()
                              ? "Define another user as quote approver"
                              : "Add an approver for quote",
                            textDescription: "You can proceed to place the quote",
                            buttonName1: "Request approval",
                            buttonName2:
                              isReviseMode !== ReviseMode.None
                                ? "Create revision"
                                : "Send the quote"
                          }
                    }
                  />
                ) : null}
                <GridInformationWrapper
                  isDetailedView={
                    shoppingCart?.stateId === ShoppingCartStateTypes.Quote &&
                    activeTab === ShoppingCartTabs.BuildCart
                  }
                >
                  <TabContentContainer
                    countryCodeToCheck={company?.countryCode}
                    isLoading={getShoppingCartByIdStatus === RequestStatus.Pending}
                    shoppingCart={shoppingCart}
                    selectedCompany={company}
                    activeTab={activeTab}
                    t={t}
                    dispatch={dispatch}
                    openEditDialogAndSetSelectedShoppingCartItem={
                      openEditDialogAndSetSelectedShoppingCartItem
                    }
                    isReadOnly={
                      shoppingCart?.stateId !== ShoppingCartStateTypes.Draft ||
                      activeTab !== ShoppingCartTabs.BuildCart ||
                      !isActiveCart
                    }
                    openRequestQuotation={openRequestQuotationDialog}
                    asmFunctionalityButton={drawAsmFunctionalityButton()}
                    quoteAttachmentsPanelIsVisible={quoteAttachmentsPanelIsVisible}
                    reviseMode={isReviseMode}
                    setIsReviseMode={
                      showReviseButton
                        ? (value: boolean) => {
                            if (userInformation?.selectedCompanyId !== shoppingCart?.company?.id) {
                              setUpdateSelectedCompanyRedirect(true);
                            } else {
                              if (value)
                                setIsReviseMode(
                                  shoppingCart?.isFrameAgreementWithPartialOrder
                                    ? ReviseMode.Partial
                                    : ReviseMode.Complete
                                );
                              else setIsReviseMode(ReviseMode.None);
                            }
                          }
                        : undefined
                    }
                    formikRevise={formikRevise}
                    showRequestRevisionButton={showRequestRevisionButton}
                    setIsRequestRevisionDialogOpen={(value) =>
                      setIsRequestRevisionDialogOpen(value)
                    }
                  />
                </GridInformationWrapper>
              </>
            )}
          </Formik>
        </Step>
        <Step
          key={ShoppingCartTabs.OrderDetails}
          title={t("2. Order details")}
          // `${url}?quote=${quote?.id}`;
          disabled={tabs.isOrderDetailsDisabled}
        >
          {!shoppingCart ? null : (
            <Formik
              initialValues={mapShoppingCartToShoppingCartForm(
                shoppingCart,
                userInformation?.selectedCompany?.defaultTermsOfDeliveryCode,
                termsOfPayment?.description,
                userInformation?.selectedCompany?.defaultPgCode
              )}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onSubmit={() => {}}
              validateOnBlur={false}
              validateOnChange
              // Do not add validateOnMount, it will break validation when changing tabs
              validationSchema={validationSchema}
              isInitialValid={false}
              innerRef={(ref) => {
                if (ref) {
                  formikRef.current = ref;
                }
              }}
            >
              {(formik: FormikProps<ShoppingCartForm>) => (
                <>
                  <FormikListener
                    formik={formik}
                    callback={(v) => {
                      if (activeTab == ShoppingCartTabs.OrderDetails) setIsValidForm(v.isValid);
                    }}
                  />
                  <div style={{ marginBottom: theme.sizes.m }}>
                    <OrderDetailsSection
                      t={t}
                      formik={formik}
                      userInformation={userInformation}
                      shoppingCart={shoppingCart}
                      activeTab={activeTab}
                      dispatch={dispatch}
                      salesChannels={salesChannels}
                      pgCodes={pgCodes}
                    />
                  </div>
                </>
              )}
            </Formik>
          )}
        </Step>
        <Step
          key={ShoppingCartTabs.ConfirmOrder}
          title={t("3. Confirm order")}
          disabled={tabs.isConfirmOrderDisabled}
        >
          {activeTab === ShoppingCartTabs.ConfirmOrder && formikRef.current && shoppingCart ? (
            <GridInformationWrapper>
              <div style={{ width: "100%", height: "100%" }}>
                <TabContentContainer
                  countryCodeToCheck={company?.countryCode}
                  selectedCompany={company}
                  isLoading={getShoppingCartByIdStatus === RequestStatus.Pending}
                  shoppingCart={shoppingCart}
                  activeTab={activeTab}
                  t={t}
                  dispatch={dispatch}
                  openEditDialogAndSetSelectedShoppingCartItem={
                    openEditDialogAndSetSelectedShoppingCartItem
                  }
                  isReadOnly={true}
                  orderDetailsSection={
                    <OrderDetailsSection
                      t={t}
                      formik={formikRef.current}
                      userInformation={userInformation}
                      shoppingCart={shoppingCart}
                      activeTab={activeTab}
                      dispatch={dispatch}
                      salesChannels={salesChannels}
                      pgCodes={pgCodes}
                    />
                  }
                  quoteAttachmentsPanelIsVisible={quoteAttachmentsPanelIsVisible}
                  reviseMode={ReviseMode.None}
                />
              </div>
            </GridInformationWrapper>
          ) : null}
        </Step>
        <Step
          key={ShoppingCartTabs.OrderInProcess}
          title={t("4. Order in process")}
          disabled={activeTab !== ShoppingCartTabs.OrderInProcess}
        >
          {activeTab === ShoppingCartTabs.OrderInProcess && shoppingCart && formikRef.current ? (
            <>
              <TabContentContainer
                countryCodeToCheck={company?.countryCode}
                selectedCompany={company}
                isLoading={getShoppingCartByIdStatus === RequestStatus.Pending}
                shoppingCart={shoppingCart}
                activeTab={activeTab}
                t={t}
                dispatch={dispatch}
                openEditDialogAndSetSelectedShoppingCartItem={
                  openEditDialogAndSetSelectedShoppingCartItem
                }
                isReadOnly={true}
                orderDetailsSection={
                  <OrderDetailsSection
                    t={t}
                    formik={formikRef.current}
                    userInformation={userInformation}
                    shoppingCart={shoppingCart}
                    activeTab={activeTab}
                    dispatch={dispatch}
                    salesChannels={salesChannels}
                    pgCodes={pgCodes}
                  />
                }
                quoteAttachmentsPanelIsVisible={quoteAttachmentsPanelIsVisible}
                reviseMode={ReviseMode.None}
              />
            </>
          ) : null}
        </Step>
      </CustomStepper>
      <FlexRowWrapper></FlexRowWrapper>
      <BottomSectionShoppingCart
        t={t}
        activeTab={activeTab}
        shoppingCartDto={shoppingCart}
        isPrimaryDisabled={isPrimaryButtonDisabled}
        primaryAction={primaryAction}
        isPrimaryLoading={
          requestOrderApprovalStatus === RequestStatus.Pending ||
          placeOrderStatus === RequestStatus.Pending ||
          modificationSaleRequest.status === RequestStatus.Pending ||
          uploadQuoteAttachmentsStatus === RequestStatus.Pending ||
          deleteQuoteAttachmentStatus === RequestStatus.Pending ||
          quoteRevisionRequestState?.status === RequestStatus.Pending ||
          acknowledgeRevisionState?.status === RequestStatus.Pending ||
          addAdditionalQuoteRequestersStatus === RequestStatus.Pending
        }
        setIsProductDialogOpen={setIsProductDialogOpen}
        isSecondaryDisabled={isSecondaryButtonDisabled}
        secondaryAction={secondaryAction}
        isQuoteExpired={isQuoteExpired(shoppingCart)}
        areButtonsHidden={
          activeTab === ShoppingCartTabs.OrderInProcess ||
          shoppingCart?.stateId === ShoppingCartStateTypes.Cancelled ||
          (shoppingCart?.stateId === ShoppingCartStateTypes.Draft && !isActiveCart) ||
          (shoppingCart?.stateId === ShoppingCartStateTypes.Rejected && !isReviseMode) ||
          (!shoppingCart?.isOrderApprover &&
            shoppingCart?.stateId === ShoppingCartStateTypes.OrderWaitingForApproval)
        }
        statusBanner={ShoppingCartStatusBanner({
          shoppingCart,
          userInformation,
          t,
          dispatch
        })}
        reviseMode={isReviseMode != ReviseMode.None}
        isTotalSalesPriceVisible={
          !isActiveCart && (shoppingCart?.company?.isInternalBuyer ?? false)
        }
      />
    </AppContentWrapper>
  );
};

export default ShoppingCartView;
