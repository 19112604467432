import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import {
  ApiTermsAndConditionsCompaniesCompanyIdPostRequest,
  ApiTermsAndConditionsCountriesCountryCodePostRequest,
  ApiTermsAndConditionsSupplierTypePostRequest,
  TermsAndConditionsExternalInfoDto
} from "api";
import { Action } from "redux";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { AppState } from "setup/appRootReducer";
import { manageTermsConditionsSagas } from "../sagas/manageTermsConditionsSagas";
import { TermsConditionType } from "enums/TermsConditionType";
import { handleFileDownloadResponse } from "utilities/fileDownload";
import { produce } from "immer";

export type AllAddUpdateRequestTypes =
  | ApiTermsAndConditionsCompaniesCompanyIdPostRequest
  | ApiTermsAndConditionsCountriesCountryCodePostRequest
  | ApiTermsAndConditionsSupplierTypePostRequest;
interface Requests {
  getExternalTermsConditions: RequestState;
  getInternalTermsConditions: RequestState;
  addOrUpdateCompanyTermsConditions: RequestState<AllAddUpdateRequestTypes>;
  addOrUpdateCountryTermsConditions: RequestState<AllAddUpdateRequestTypes>;
  addOrUpdateSupplierTermsConditions: RequestState<AllAddUpdateRequestTypes>;
  deleteTermsConditions: RequestState;
}

export interface ManageTermsConditionsState {
  currentUserTermsConditions?: TermsAndConditionsExternalInfoDto;
  requests: Requests;
}

const defaultState: ManageTermsConditionsState = {
  requests: {
    getExternalTermsConditions: createInitialRequest(),
    getInternalTermsConditions: createInitialRequest(),
    addOrUpdateCompanyTermsConditions: createInitialRequest(),
    addOrUpdateCountryTermsConditions: createInitialRequest(),
    addOrUpdateSupplierTermsConditions: createInitialRequest(),
    deleteTermsConditions: createInitialRequest()
  }
};

export function manageTermsConditionsReducer(
  state: ManageTermsConditionsState = defaultState,
  action: Action
): ManageTermsConditionsState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageTermsConditionsSagas.addOrUpdateCompanyTermsConditions.actionTypes,
      key: "addOrUpdateCompanyTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.addOrUpdateCountryTermsConditions.actionTypes,
      key: "addOrUpdateCountryTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.addOrUpdateSupplierTermsConditions.actionTypes,
      key: "addOrUpdateSupplierTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.deleteTermsConditions.actionTypes,
      key: "deleteTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.downloadSupplierTermsConditions.actionTypes,
      key: "downloadSupplierTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.downloadCompanyTermsConditions.actionTypes,
      key: "downloadCompanyTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.downloadCountryTermsConditions.actionTypes,
      key: "downloadCountryTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.downloadQuoteTermsConditions.actionTypes,
      key: "downloadQuoteTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.downloadCurrentUserTermsConditions.actionTypes,
      key: "downloadCurrentUserTermsConditions"
    },
    {
      actionTypes: manageTermsConditionsSagas.getCurrentUserTermsConditions.actionTypes,
      key: "getCurrentUserTermsConditions"
    }
  ]);

  if (
    manageTermsConditionsSagas.downloadSupplierTermsConditions.isCompletedAction(action) ||
    manageTermsConditionsSagas.downloadCompanyTermsConditions.isCompletedAction(action) ||
    manageTermsConditionsSagas.downloadCountryTermsConditions.isCompletedAction(action) ||
    manageTermsConditionsSagas.downloadQuoteTermsConditions.isCompletedAction(action) ||
    manageTermsConditionsSagas.downloadCurrentUserTermsConditions.isCompletedAction(action)
  ) {
    handleFileDownloadResponse(action.payload.raw, `Terms & Conditions.pdf`);
  } else if (manageTermsConditionsSagas.getCurrentUserTermsConditions.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.currentUserTermsConditions = action.payload;
    });
  } else if (manageTermsConditionsSagas.getCurrentUserTermsConditions.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.currentUserTermsConditions = undefined;
    });
  }

  return state;
}
export const getInternalTermsConditionsRequest = (state: AppState): RequestState =>
  state.manageTermsConditions.requests.getInternalTermsConditions;

export const getExternalTermsConditionsRequest = (state: AppState): RequestState =>
  state.manageTermsConditions.requests.getExternalTermsConditions;

export const getDeleteTermsConditionsRequest = (state: AppState): RequestState =>
  state.manageTermsConditions.requests.deleteTermsConditions;

export const getCurrentUserTermsConditions = (
  state: AppState
): TermsAndConditionsExternalInfoDto | undefined =>
  state.manageTermsConditions.currentUserTermsConditions;

export const addOrUpdateTermsConditionsRequest = (termsConditionType: TermsConditionType) => {
  return (state: AppState): RequestState<AllAddUpdateRequestTypes> => {
    switch (termsConditionType) {
      case TermsConditionType.Company:
        return state.manageTermsConditions.requests.addOrUpdateCompanyTermsConditions;
      case TermsConditionType.Country:
        return state.manageTermsConditions.requests.addOrUpdateCountryTermsConditions;
      case TermsConditionType.Supplier:
        return state.manageTermsConditions.requests.addOrUpdateSupplierTermsConditions;
      default:
        throw Error("Not supported");
    }
  };
};
