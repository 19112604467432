import styled from "styled-components";
import Card from "@mui/material/Card";
import { Checkbox, Dropdown } from "@abb/abb-common-ux-react";
const LeftMenuContainer = styled.div`
  background-color: ${(props) => props.theme.colors.grey10};
  flex: 0 1 auto;
  padding: ${(props) => props.theme.sizes.m};
  .FeatureClass {
    font-size: ${(props) => props.theme.fonts.sizes.fontSizeXxs};
    font-weight: ${(props) => props.theme.fonts.weights.heavy};
  }
`;
export const SelectApplicationStdContainer = styled(LeftMenuContainer)``;
export const SearchByKeywordContainer = styled(LeftMenuContainer)``;
export const RefineByFunctionalityContainer = styled(LeftMenuContainer)`
  .ABB_CommonUX_Collapsible__root {
    padding-top: 5px;
  }
  .ABB_CommonUX_Collapsible__root > .ABB_CommonUX_Collapsible__header {
    height: unset;
    min-height: 32px;
  }
  .ABB_CommonUX_Collapsible__root
    > .ABB_CommonUX_Collapsible__header
    > .ABB_CommonUX_Collapsible__title {
    white-space: normal;
  }
`;

export const ClearFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  align-items: center;
  gap: 8px;
  color: ${(props) => props.theme.colors.grey60};
  cursor: pointer;
`;
export const DescriptionContainer = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const FlexCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .MuiCardMedia-media {
    flex: 1 1 50%;
    object-fit: scale-down;
    padding: 8% 16% !important;
  }
  .MuiCardContent-root {
    flex: 1 1 auto;
  }
  .MuiCardActions-root {
    flex: 0 1 auto;
  }
`;
/* export const ClearFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${(props) => props.theme.sizes.s};
`; */
export const LoadingIndicatorContainer = styled.div`
  margin: auto;
  margin-top: ${(props) => props.theme.sizes.l};
  .ABB_CommonUX_LoadingIndicator__root.ABB_CommonUX_LoadingIndicator__radial {
    margin-right: auto;
    margin-left: auto;
  }
`;
export const HitLine = styled.li`
  display: flex;
  [class^="ais-"] {
    font-size: unset;
  }
  .hit-name {
    font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  }
  .hit-name-feature {
    display: flex;
    flex-direction: column;

    em {
      background-color: lightblue;
    }
  }
`;
export const Description = styled.p`
  margin-bottom: ${(props) => props.theme.sizes.s};
`;
export const CustomCheckbox = styled(Checkbox)`
  margin-top: 0;
  height: auto;
  min-height: ${(props) => props.theme.sizes.l};
  span {
    white-space: pre-wrap;
  }
`;
export const CustomDropdown = styled(Dropdown)`
  margin-top: ${(props) => props.theme.sizes.s};
`;
