/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyUserDto,
    CompanyUserDtoFromJSON,
    CompanyUserDtoFromJSONTyped,
    CompanyUserDtoToJSON,
} from './CompanyUserDto';

/**
 * 
 * @export
 * @interface UpdateCompanyUserVm
 */
export interface UpdateCompanyUserVm {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyUserVm
     */
    companyHasCompanyAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyUserVm
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyUserVm
     */
    hasDeliverItUsers: boolean;
    /**
     * 
     * @type {CompanyUserDto}
     * @memberof UpdateCompanyUserVm
     */
    user: CompanyUserDto;
}

export function UpdateCompanyUserVmFromJSON(json: any): UpdateCompanyUserVm {
    return UpdateCompanyUserVmFromJSONTyped(json, false);
}

export function UpdateCompanyUserVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyUserVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyHasCompanyAdmin': json['companyHasCompanyAdmin'],
        'companyId': json['companyId'],
        'hasDeliverItUsers': json['hasDeliverItUsers'],
        'user': CompanyUserDtoFromJSON(json['user']),
    };
}

export function UpdateCompanyUserVmToJSON(value?: UpdateCompanyUserVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyHasCompanyAdmin': value.companyHasCompanyAdmin,
        'companyId': value.companyId,
        'hasDeliverItUsers': value.hasDeliverItUsers,
        'user': CompanyUserDtoToJSON(value.user),
    };
}

