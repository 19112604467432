/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetGmdProductHistoryByMultipleSerialNumbersCommand
 */
export interface GetGmdProductHistoryByMultipleSerialNumbersCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetGmdProductHistoryByMultipleSerialNumbersCommand
     */
    serialNumbers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetGmdProductHistoryByMultipleSerialNumbersCommand
     */
    materialCode: string;
}

export function GetGmdProductHistoryByMultipleSerialNumbersCommandFromJSON(json: any): GetGmdProductHistoryByMultipleSerialNumbersCommand {
    return GetGmdProductHistoryByMultipleSerialNumbersCommandFromJSONTyped(json, false);
}

export function GetGmdProductHistoryByMultipleSerialNumbersCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGmdProductHistoryByMultipleSerialNumbersCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumbers': json['serialNumbers'],
        'materialCode': json['materialCode'],
    };
}

export function GetGmdProductHistoryByMultipleSerialNumbersCommandToJSON(value?: GetGmdProductHistoryByMultipleSerialNumbersCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumbers': value.serialNumbers,
        'materialCode': value.materialCode,
    };
}

