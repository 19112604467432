import React, { UIEvent, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon } from "@abb/abb-common-ux-react";
import Grid from "@mui/material/Grid";
import { useWindowWidth } from "framework/hooks/useWindowWidth";

const ScrollContainer = styled(Grid)`
  scroll-behavior: smooth;
`;

const NextButton = styled(Icon)`
  cursor: pointer;
  position: absolute;
  margin-top: auto;
  top: 50%;
  right: 0;
`;

const PrevButton = styled(Icon)`
  cursor: pointer;
  position: absolute;
  margin-top: auto;
  top: 50%;
  left: 0;
`;

interface CarouselItemProps {
  children?: JSX.Element | Element;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  minHeight?: number;
}

export const CarouselItem = ({
  children,
  minWidth,
  width,
  maxWidth,
  minHeight
}: CarouselItemProps): JSX.Element => {
  return (
    <Grid width={width} maxWidth={maxWidth} minWidth={minWidth ?? 200} minHeight={minHeight ?? 200}>
      {children}
    </Grid>
  );
};

interface CarouselProps {
  /**
   * Number of pixels to scroll when Next,Prev nav buttons clicked
   */
  navBtnSensitivity?: number;
  gap?: number;
  children: JSX.Element[] | React.ReactNode;
  triggerResize?: () => () => void;
  paddingBottom?: number;
}

export const Carousel = ({
  navBtnSensitivity,
  children,
  gap,
  paddingBottom
}: CarouselProps): JSX.Element => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [nextVisible, setNextVisible] = useState(true);
  const [prevVisible, setPrevVisible] = useState(false);
  const windowWidth = useWindowWidth();
  const scrollWidth = scrollContainerRef.current?.scrollWidth;

  const rightScrollOffset = 5;

  const hasScrollEnabled = useCallback(() => {
    return scrollContainerRef.current && scrollWidth
      ? scrollContainerRef.current.clientWidth < scrollWidth
      : false;
  }, [scrollWidth]);

  useEffect(() => {
    if (hasScrollEnabled()) {
      setNextVisible(true);
    } else {
      setNextVisible(false);
      setPrevVisible(false);
    }
  }, [windowWidth, scrollWidth, scrollContainerRef, hasScrollEnabled]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollPosition = e.currentTarget.scrollLeft;
    const width = e.currentTarget.clientWidth;

    setPrevVisible(scrollPosition !== 0);
    if (scrollPosition !== undefined && scrollWidth !== undefined)
      setNextVisible(scrollPosition + width + rightScrollOffset < scrollWidth);
  };

  const onNextClick = () => {
    scrollContainerRef.current?.scroll({
      left: scrollContainerRef.current.scrollLeft + (navBtnSensitivity ?? 200)
    });
  };

  const onPrevClick = () => {
    scrollContainerRef.current?.scroll({
      left: scrollContainerRef.current.scrollLeft - (navBtnSensitivity ?? 200)
    });
  };

  return (
    <>
      <Grid minWidth={250} position={"relative"}>
        <ScrollContainer
          onScroll={handleScroll}
          ref={scrollContainerRef}
          container
          flexWrap={"nowrap"}
          overflow={"auto"}
          gap={gap != undefined && gap >= 0 ? gap : 2}
          paddingBottom={paddingBottom}
        >
          {children}
        </ScrollContainer>
        {nextVisible && (
          <NextButton sizeClass="large" onClick={onNextClick} name="abb/right"></NextButton>
        )}
        {prevVisible && (
          <PrevButton sizeClass="large" onClick={onPrevClick} name="abb/left"></PrevButton>
        )}
      </Grid>
    </>
  );
};
