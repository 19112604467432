import {
  createGenericSaga,
  defaultSagaErrorHandler,
  defaultSagaSuccessHandler
} from "framework/sagas/sagaUtilities";
import { getApiRegistry } from "framework/state/apiRegistry";

export const manageTerminologyBankSagas = {
  getNodesWithPath: createGenericSaga(
    "manageTerminologyBankSagas/getNodesWithPath",
    getApiRegistry().trainingApi.apiTrainingNodesWithPathGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getArticlesFreeAndAssigned: createGenericSaga(
    "manageTerminologyBankSagas/getArticlesFreeAndAssigned",
    getApiRegistry().trainingApi.apiTrainingArticlesFreeAndAssignedGet.bind(
      getApiRegistry().trainingApi
    ),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateArticle: createGenericSaga(
    "manageTerminologyBankSagas/updateArticle",
    getApiRegistry().trainingApi.apiTrainingArticlePut.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  getArticles: createGenericSaga(
    "manageTerminologyBankSagas/getArticles",
    getApiRegistry().trainingApi.apiTrainingArticlesGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  updateArticleNode: createGenericSaga(
    "manageTerminologyBankSagas/updateArticleNode",
    getApiRegistry().trainingApi.apiTrainingUpdateArticleNodePut.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  ),
  publish: createGenericSaga(
    "manageTerminologyBankSagas/publish",
    getApiRegistry().trainingApi.apiTrainingPublishArticlesGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler, // for some reason I can not return error from here to check
      onSuccess: defaultSagaSuccessHandler("The articles are going to be published in a minute!")
    }
  ),
  retrieveArticles: createGenericSaga(
    "manageTerminologyBankSagas/retrieveArticles",
    getApiRegistry().trainingApi.apiTrainingRetrieveArticlesGet.bind(getApiRegistry().trainingApi),
    {
      takeEvery: false,
      onFail: defaultSagaErrorHandler
    }
  )
};
