import { RequestState, createInitialRequest } from "framework/state/requestState";
import {
  ApiResponse,
  ApiTechDocSearchGetRequest,
  ApiTrainingArticleIdGetRequest,
  ApiTrainingAttachmentGetRequest,
  ApiTrainingFeedbackPostRequest,
  ApiTrainingNodesRootsGetRequest,
  ApiTrainingNodesWithPathGetRequest,
  ApiTrainingSearchArticlePostRequest,
  ArticleDto,
  ArticleDtoFormatted,
  NodeDto,
  NodeWithPath,
  SearchResponseDto,
  TechDocArticleDto,
  TechDocSearchResponse
} from "api";
import { produce } from "immer";
import { RequestStatus } from "framework/state/requestStatus";
import {
  KnowledgeWarehouseAction,
  KnowledgeWarehouseActionType,
  KnowledgeWarehouseActions
} from "../actions/knowledgeWarehouseActions";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { knowledgeWarehouseSagas } from "../sagas/knowledgeWarehouseSagas";
import { AppState } from "setup/appRootReducer";

interface Requests {
  getNodesRoots: RequestState<ApiTrainingNodesRootsGetRequest>;
  getArticleById: RequestState<ApiTrainingArticleIdGetRequest>;
  sendFeedback: RequestState<string, ApiTrainingFeedbackPostRequest>;
  getAttachment: RequestState<ApiResponse<Blob>, ApiTrainingAttachmentGetRequest>;
  getNodesWithPath: RequestState<Array<NodeWithPath>, ApiTrainingNodesWithPathGetRequest>;
  getSearchInTechDoc: RequestState<TechDocSearchResponse, ApiTechDocSearchGetRequest>;
  getSearchArticles: RequestState<SearchResponseDto, ApiTrainingSearchArticlePostRequest>;
}

export interface KnowledgeWarehouseState {
  requests: Requests;
  nodeExpanded: NodeDto | undefined;
  roots: NodeDto[];
  nodes: NodeWithPath[];
  article: ArticleDto | undefined;
  currentAction: KnowledgeWarehouseAction | undefined;
  attachmentLoadingList: number[];
  searchResponseTechDoc: TechDocSearchResponse | undefined;
  search: string | undefined;
  isSearch: boolean | undefined;
  isLoading: boolean | undefined;
  pageSize: number;
  pageNumber: number;
  forceScroll: boolean;
  categories:
    | {
        [key: string]: number;
      }
    | undefined;
  activeCategories: string[];
  articlesHits: ArticleDtoFormatted[];
  articlesHitsNumber: number | undefined;
}
const defaultState: KnowledgeWarehouseState = {
  requests: {
    getNodesRoots: createInitialRequest(),
    getArticleById: createInitialRequest(),
    sendFeedback: createInitialRequest(),
    getAttachment: createInitialRequest(),
    getNodesWithPath: createInitialRequest(),
    getSearchInTechDoc: createInitialRequest(),
    getSearchArticles: createInitialRequest()
  },
  roots: [],
  nodes: [],
  nodeExpanded: undefined,
  article: undefined,
  currentAction: undefined,
  attachmentLoadingList: [],
  search: undefined,
  isSearch: undefined,
  isLoading: undefined,
  pageNumber: 0,
  pageSize: 5,
  forceScroll: false,
  categories: undefined,
  searchResponseTechDoc: undefined,
  activeCategories: [],
  articlesHits: [],
  articlesHitsNumber: undefined
};

export function knowledgeWarehouseReducer(
  state: KnowledgeWarehouseState = defaultState,
  action: KnowledgeWarehouseActions
): KnowledgeWarehouseState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: knowledgeWarehouseSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: knowledgeWarehouseSagas.getNodesWithPath.actionTypes,
      key: "getNodesWithPath"
    },
    {
      actionTypes: knowledgeWarehouseSagas.getArticleById.actionTypes,
      key: "getArticleById"
    },
    {
      actionTypes: knowledgeWarehouseSagas.sendFeedback.actionTypes,
      key: "sendFeedback"
    },
    {
      actionTypes: knowledgeWarehouseSagas.getAttachment.actionTypes,
      key: "getAttachment"
    },
    {
      actionTypes: knowledgeWarehouseSagas.getSearchInTechDoc.actionTypes,
      key: "getSearchInTechDoc"
    },
    {
      actionTypes: knowledgeWarehouseSagas.getSearchArticles.actionTypes,
      key: "getSearchArticles"
    }
  ]);

  if (knowledgeWarehouseSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.roots = action.payload;
    });
  if (knowledgeWarehouseSagas.getNodesWithPath.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload;
    });
  if (knowledgeWarehouseSagas.getArticleById.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.article = action.payload;
    });

  if (knowledgeWarehouseSagas.getAttachment.isRequestAction(action)) {
    state = produce(state, (draft) => {
      if (!action.payload.attachmentId) return state;
      draft.attachmentLoadingList.push(action.payload.attachmentId);
    });
  } else if (knowledgeWarehouseSagas.getAttachment.isCompletedAction(action)) {
    const b = action.payload;
    const url = window.URL.createObjectURL(b);
    const a = document.createElement("a");
    a.href = url;
    const fileName = state.requests.getAttachment.requestPayload?.fileName;
    fileName ? (a.download = fileName) : (a.target = "_blank");
    a.click();
    state = produce(state, (draft) => {
      draft.attachmentLoadingList = draft.attachmentLoadingList.filter(
        (n) => n !== state.requests.getAttachment.requestPayload?.attachmentId
      );
    });
  } else if (knowledgeWarehouseSagas.getAttachment.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.attachmentLoadingList = draft.attachmentLoadingList.filter(
        (n) => n !== state.requests.getAttachment.requestPayload?.attachmentId
      );
    });
  } else if (knowledgeWarehouseSagas.getSearchInTechDoc.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.searchResponseTechDoc = action.payload;
      draft.isLoading = false;
    });
  } else if (knowledgeWarehouseSagas.getSearchInTechDoc.isFailedAction(action)) {
    state = produce(state, (draft) => {
      draft.isLoading = false;
    });
  } else if (knowledgeWarehouseSagas.getSearchArticles.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.articlesHits = action.payload.articles ?? [];
      draft.articlesHitsNumber = action.payload.estimatedTotalHits ?? 0;
      draft.categories = action.payload.facetDistribution ?? undefined;
      draft.isLoading = false;
    });
  }

  switch (action.type) {
    case KnowledgeWarehouseActionType.NodeExpanded:
      state = produce(state, (draft) => {
        draft.nodeExpanded = action.node;
        draft.isSearch = false;
        draft.isLoading = true;
        draft.search = undefined;
        draft.pageNumber = 0;
        draft.article = undefined;
        draft.forceScroll = true;
      });
      break;
    case KnowledgeWarehouseActionType.CurrentAction:
      state = produce(state, (draft) => {
        draft.currentAction = action.action;
      });
      break;
    case KnowledgeWarehouseActionType.SetSearchText:
      state = produce(state, (draft) => {
        if (draft.search !== action.search) {
          draft.isLoading = !action.search ? false : true;
          draft.pageNumber = 0;
          draft.article = undefined;
          draft.search = action.search;
        }
        draft.isSearch = !action.search ? false : true;
        draft.forceScroll = true;
      });
      break;
    case KnowledgeWarehouseActionType.SetLoadingResults:
      state = produce(state, (draft) => {
        draft.isLoading = action.loading;
      });
      break;
    case KnowledgeWarehouseActionType.SetPageNumber:
      state = produce(state, (draft) => {
        draft.pageNumber = action.page;
        draft.forceScroll = true;
      });
      break;
    case KnowledgeWarehouseActionType.SetPageSize:
      state = produce(state, (draft) => {
        draft.pageSize = action.size;
        draft.pageNumber = 0;
        draft.forceScroll = true;
      });
      break;
    case KnowledgeWarehouseActionType.SetForceScroll:
      state = produce(state, (draft) => {
        draft.forceScroll = action.force;
      });
      break;
    case KnowledgeWarehouseActionType.SetResetSearch:
      state = produce(state, (draft) => {
        draft.pageSize = 5;
        draft.pageNumber = 0;
        draft.nodeExpanded = undefined;
        draft.isSearch = undefined;
        draft.search = undefined;
        draft.article = undefined;
        draft.isLoading = undefined;
        draft.searchResponseTechDoc = undefined;
      });
      break;
    case KnowledgeWarehouseActionType.SetActiveCategories:
      state = produce(state, (draft) => {
        draft.activeCategories = action.categories;
      });
      break;
  }
  return state;
}
export const getNodeExpanded = (state: AppState): NodeDto | undefined =>
  state.knowledgeWarehouse.nodeExpanded;
export const getCurrentAction = (state: AppState): KnowledgeWarehouseAction | undefined =>
  state.knowledgeWarehouse.currentAction;

export const getNodesRoots = (state: AppState): NodeDto[] => state.knowledgeWarehouse.roots;
export const getNodesRootsStatus = (state: AppState): RequestStatus =>
  state.knowledgeWarehouse.requests.getNodesRoots.status;
export const getArticleById = (state: AppState): ArticleDto | undefined =>
  state.knowledgeWarehouse.article;
export const getArticleByIdStatus = (state: AppState): RequestStatus =>
  state.knowledgeWarehouse.requests.getArticleById.status;
export const getAttachmentLoadingList = (state: AppState) =>
  state.knowledgeWarehouse.attachmentLoadingList;
export const getPostFeedbackRequest = (
  state: AppState
): RequestState<string, ApiTrainingFeedbackPostRequest> =>
  state.knowledgeWarehouse.requests.sendFeedback;

export const getSearch = (state: AppState): string | undefined => state.knowledgeWarehouse.search;
export const getIsSearch = (state: AppState): boolean | undefined =>
  state.knowledgeWarehouse.isSearch;
export const getIsLoading = (state: AppState): boolean | undefined =>
  state.knowledgeWarehouse.isLoading;
export const getPageSize = (state: AppState): number => state.knowledgeWarehouse.pageSize;
export const getPageNumber = (state: AppState): number => state.knowledgeWarehouse.pageNumber;
export const getForceScroll = (state: AppState): boolean => state.knowledgeWarehouse.forceScroll;
export const getCategories = (state: AppState) => state.knowledgeWarehouse.categories;
export const getNodesWithPath = (state: AppState): NodeWithPath[] => state.knowledgeWarehouse.nodes;

export const getArticlesTechDoc = (state: AppState): TechDocArticleDto[] =>
  state.knowledgeWarehouse.searchResponseTechDoc?.response ?? [];
export const getNumberOfArticlesTechDoc = (state: AppState): number =>
  state.knowledgeWarehouse.searchResponseTechDoc?.totalResultsCount ?? -1;
export const getSearchResponseTechDocStatus = (state: AppState): RequestStatus =>
  state.knowledgeWarehouse.requests.getSearchInTechDoc.status;

export const getActiveCategories = (state: AppState): string[] =>
  state.knowledgeWarehouse.activeCategories;
export const getArticlesHits = (state: AppState) => state.knowledgeWarehouse.articlesHits;
export const getArticlesHitsNumber = (state: AppState) =>  state.knowledgeWarehouse.articlesHitsNumber;
export const getArticlesSearchStatus = (state: AppState) =>  state.knowledgeWarehouse.requests.getSearchArticles.status;
