/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendContactUsCommand
 */
export interface SendContactUsCommand {
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    userEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    businessType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    industry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    productType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SendContactUsCommand
     */
    consent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendContactUsCommand
     */
    userSelectedProductUrl?: string | null;
}

export function SendContactUsCommandFromJSON(json: any): SendContactUsCommand {
    return SendContactUsCommandFromJSONTyped(json, false);
}

export function SendContactUsCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendContactUsCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'userEmail': !exists(json, 'userEmail') ? undefined : json['userEmail'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'businessType': !exists(json, 'businessType') ? undefined : json['businessType'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'industry': !exists(json, 'industry') ? undefined : json['industry'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'consent': !exists(json, 'consent') ? undefined : json['consent'],
        'userSelectedProductUrl': !exists(json, 'userSelectedProductUrl') ? undefined : json['userSelectedProductUrl'],
    };
}

export function SendContactUsCommandToJSON(value?: SendContactUsCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'userEmail': value.userEmail,
        'country': value.country,
        'city': value.city,
        'businessType': value.businessType,
        'company': value.company,
        'industry': value.industry,
        'additionalInfo': value.additionalInfo,
        'productType': value.productType,
        'consent': value.consent,
        'userSelectedProductUrl': value.userSelectedProductUrl,
    };
}

