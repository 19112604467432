import { commonUXTheme } from "./commonUXVariables";
import { css, createGlobalStyle } from "styled-components";
import { headerStyles } from "./headerStyles";

export const normalize = css`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body:not(a) {
    // SELECTOR HACK: increase specificity to overwrite ABB common UX styles
    margin: 0; // reset
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    //overflow-y: scroll; // Scrollbar will always be visible
    background-color: ${commonUXTheme.colors.whitePrimary};
    font-size: ${commonUXTheme.fonts.sizes.fontSizeM};
    font-family: ${commonUXTheme.fonts.families.fontAbbRegular},
      ${commonUXTheme.fonts.families.fontAbbMedium}, ${commonUXTheme.fonts.families.fontAbbLight},
      ${commonUXTheme.fonts.families.fontAbbBold};
    color: ${commonUXTheme.colors.blackPrimary};
    line-height: ${commonUXTheme.fonts.lineHeights.lineHeightM};
    -webkit-text-size-adjust: 100%; // What does this do?
    -ms-text-size-adjust: 100%; // What does this do?
    text-rendering: optimizeLegibility;
  }

  a {
    color: ${commonUXTheme.colors.uiBlue60};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  audio,
  embed,
  img,
  object,
  video {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    border: ${commonUXTheme.borders.widths.borderWidthS} solid
      ${commonUXTheme.colors.whiteSecondary};
    border-collapse: collapse;
  }

  td,
  th {
    padding: 0.25rem 0.5rem;
    border: ${commonUXTheme.borders.widths.borderWidthS} solid
      ${commonUXTheme.colors.whiteSecondary};
  }

  th {
    text-align: left;
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        td {
          background-color: ${commonUXTheme.colors.whiteTertiary};
        }
        tr {
          background-color: ${commonUXTheme.colors.whiteTertiary};
        }
      }
    }
  }

  p + * {
    margin-top: ${commonUXTheme.sizes.m};
  }

  ul,
  ol,
  dl {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  dt {
    font-weight: bold;
  }

  hr {
    position: relative;
    margin: ${commonUXTheme.sizes.m} 0;
    border: 0;
    border-top: ${commonUXTheme.borders.widths.borderWidthS} solid
      ${commonUXTheme.colors.whiteSecondary};
  }

  blockquote {
    margin: 0.8rem 0;
    padding: 0.5rem 1rem;
    border-left: 0.25rem solid ${commonUXTheme.colors.whiteSecondary};
    color: ${commonUXTheme.colors.opacities.halfOpacityOnDark};
  }
`;

export const NormalizedStyles = createGlobalStyle`
  ${normalize}
  ${headerStyles}
`;

export default NormalizedStyles;
