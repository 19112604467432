/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SelectorApplicationFeatureSubSegmentValueDto,
    SelectorApplicationFeatureSubSegmentValueDtoFromJSON,
    SelectorApplicationFeatureSubSegmentValueDtoFromJSONTyped,
    SelectorApplicationFeatureSubSegmentValueDtoToJSON,
} from './SelectorApplicationFeatureSubSegmentValueDto';

/**
 * 
 * @export
 * @interface SelectorApplicationFeatureDto
 */
export interface SelectorApplicationFeatureDto {
    /**
     * 
     * @type {number}
     * @memberof SelectorApplicationFeatureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureDto
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectorApplicationFeatureDto
     */
    isSelected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectorApplicationFeatureDto
     */
    image?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectorApplicationFeatureDto
     */
    _default?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SelectorApplicationFeatureDto
     */
    disabledFeatureIds?: Array<number> | null;
    /**
     * 
     * @type {Array<SelectorApplicationFeatureSubSegmentValueDto>}
     * @memberof SelectorApplicationFeatureDto
     */
    selectorApplicationFeatureSubSegmentValues?: Array<SelectorApplicationFeatureSubSegmentValueDto> | null;
}

export function SelectorApplicationFeatureDtoFromJSON(json: any): SelectorApplicationFeatureDto {
    return SelectorApplicationFeatureDtoFromJSONTyped(json, false);
}

export function SelectorApplicationFeatureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorApplicationFeatureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'isSelected': !exists(json, 'isSelected') ? undefined : json['isSelected'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'disabledFeatureIds': !exists(json, 'disabledFeatureIds') ? undefined : json['disabledFeatureIds'],
        'selectorApplicationFeatureSubSegmentValues': !exists(json, 'selectorApplicationFeatureSubSegmentValues') ? undefined : (json['selectorApplicationFeatureSubSegmentValues'] === null ? null : (json['selectorApplicationFeatureSubSegmentValues'] as Array<any>).map(SelectorApplicationFeatureSubSegmentValueDtoFromJSON)),
    };
}

export function SelectorApplicationFeatureDtoToJSON(value?: SelectorApplicationFeatureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'code': value.code,
        'isSelected': value.isSelected,
        'image': value.image,
        'default': value._default,
        'disabledFeatureIds': value.disabledFeatureIds,
        'selectorApplicationFeatureSubSegmentValues': value.selectorApplicationFeatureSubSegmentValues === undefined ? undefined : (value.selectorApplicationFeatureSubSegmentValues === null ? null : (value.selectorApplicationFeatureSubSegmentValues as Array<any>).map(SelectorApplicationFeatureSubSegmentValueDtoToJSON)),
    };
}

