/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    StatisticsPreDefinedTimePeriods,
    StatisticsPreDefinedTimePeriodsFromJSON,
    StatisticsPreDefinedTimePeriodsToJSON,
    UsageStatisticsVm,
    UsageStatisticsVmFromJSON,
    UsageStatisticsVmToJSON,
} from '../models';

export interface ApiStatisticsExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaTimePeriod?: StatisticsPreDefinedTimePeriods;
    criteriaFrom?: Date;
    criteriaTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiStatisticsGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaTimePeriod?: StatisticsPreDefinedTimePeriods;
    criteriaFrom?: Date;
    criteriaTo?: Date;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     */
    async apiStatisticsExportPostRaw(requestParameters: ApiStatisticsExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiStatisticsExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiStatisticsExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaTimePeriod !== undefined) {
            queryParameters['Criteria.TimePeriod'] = requestParameters.criteriaTimePeriod;
        }

        if (requestParameters.criteriaFrom !== undefined) {
            queryParameters['Criteria.From'] = (requestParameters.criteriaFrom as any).toISOString();
        }

        if (requestParameters.criteriaTo !== undefined) {
            queryParameters['Criteria.To'] = (requestParameters.criteriaTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Statistics/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiStatisticsExportPost(requestParameters: ApiStatisticsExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiStatisticsExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiStatisticsGetRaw(requestParameters: ApiStatisticsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsageStatisticsVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiStatisticsGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiStatisticsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaTimePeriod !== undefined) {
            queryParameters['Criteria.TimePeriod'] = requestParameters.criteriaTimePeriod;
        }

        if (requestParameters.criteriaFrom !== undefined) {
            queryParameters['Criteria.From'] = (requestParameters.criteriaFrom as any).toISOString();
        }

        if (requestParameters.criteriaTo !== undefined) {
            queryParameters['Criteria.To'] = (requestParameters.criteriaTo as any).toISOString();
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsageStatisticsVmFromJSON(jsonValue));
    }

    /**
     */
    async apiStatisticsGet(requestParameters: ApiStatisticsGetRequest, initOverrides?: RequestInit): Promise<UsageStatisticsVm> {
        const response = await this.apiStatisticsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
