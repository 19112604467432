import { css } from "styled-components";
import { commonUXTheme } from "./commonUXVariables";

export const commonUXStyles = css`
  .ABB_CommonUX_Input__focus {
    border-color: ${commonUXTheme.colors.grey50} !important;
  }

  .ABB_CommonUX_NumericInput__focused {
    border-color: ${commonUXTheme.colors.grey50} !important;
  }

  .ABB_CommonUX_Dialog__root {
    margin-inline: auto; // Make it centered
    max-width: calc(100% - ${commonUXTheme.sizes.m}); // 16px margin
  }
`;
