import { IStatusPanelParams } from "ag-grid-community";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import styled from "styled-components";

const RowLabel = styled.span`
  color: ${(props) => props.theme.colors.grey50};
  font-weight: normal;
  margin-right: ${(props) => props.theme.sizes.xs};
`;

const RowCount = styled.span`
  color: ${(props) => props.theme.colors.grey90};
  font-weight: normal;
`;

const RowCountContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  align-self: center;
  margin-left: ${(props) => props.theme.sizes.lm};
`;

export interface GridStatusBarTotalRowsCountProps extends IStatusPanelParams {
  controlRowCountFromOutside?: boolean;
}

export interface IGridStatusBarTotalRowsCount {
  setCount: (count: number | undefined | "loading") => void;
}

export default forwardRef((props: GridStatusBarTotalRowsCountProps, ref) => {
  const [count, setCount] = useState<number | undefined | "loading">();
  const previousCount = useRef<number | undefined | null>();

  const handleRowCount = useCallback(() => {
    const rowModelType = props.api.getModel().getType();
    let currentCount: undefined | number;
    if (rowModelType === "infinite") {
      currentCount = props.api.getInfiniteRowCount();
    } else {
      currentCount = props.api.getDisplayedRowCount();
      setCount(currentCount);
    }

    // getInfiniteRowCount() returns 1 when new data is being loaded so hacky logic not to show the 1 every time data is being loaded
    if (
      (rowModelType === "infinite" &&
        currentCount !== undefined &&
        (previousCount.current == null || previousCount.current === 1) &&
        currentCount !== 1) ||
      (previousCount.current === 1 && currentCount === 1) ||
      (previousCount.current !== 1 && currentCount !== undefined && currentCount !== 1)
    ) {
      setCount(currentCount);
    }
    previousCount.current = currentCount ? currentCount : null;
  }, [props.api]);

  useEffect(() => {
    let listenerSet = false;
    if (!props.controlRowCountFromOutside) {
      listenerSet = true;
      props.api.addEventListener("modelUpdated", handleRowCount);
    }
    return () => {
      if (listenerSet) {
        props.api.removeEventListener("modelUpdated", handleRowCount);
      }
    };
  }, [props.api, handleRowCount, props.controlRowCountFromOutside]);

  useImperativeHandle<any, IGridStatusBarTotalRowsCount>(ref, () => ({ setCount }));

  return (
    <RowCountContainer>
      {count != null ? (
        <>
          <RowLabel>{"Total Rows"}</RowLabel>
          <RowCount>{count === "loading" ? "Loading..." : count}</RowCount>
        </>
      ) : null}
    </RowCountContainer>
  );
});
