import React from "react";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { IconWithTooltip } from "./IconWithTooltip";

interface InputLabelProps {
  tooltipText?: string;
  label: string;
  icon?: string;
  iconStyle?: React.CSSProperties | undefined;
  isRequired?: boolean;
}

const StyledSpan = styled.span`
  color: ${(props) => props.theme.colors.brandBlack};
`;

export const InputLabel = ({
  tooltipText,
  label,
  icon = "abb/information-circle-1",
  iconStyle = { marginLeft: commonUXTheme.sizes.xs, marginTop: commonUXTheme.sizes.xxs },
  isRequired
}: InputLabelProps): JSX.Element => (
  <span className={"ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal"}>
    <div className={"ABB_CommonUX_Input__labelContainer"}>
      <span className={"ABB_CommonUX_Input__label"}>{label}</span>
      {isRequired ? <span className={"ABB_CommonUX_Input__requiredAsterisk"}>&nbsp;*</span> : null}
      <StyledSpan>
        {icon && tooltipText ? (
          <IconWithTooltip icon={icon} tooltipText={tooltipText ?? ""} style={iconStyle} />
        ) : null}
      </StyledSpan>
    </div>
  </span>
);
