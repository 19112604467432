import styled from "styled-components";
import { Heading, HeadingProps } from "@abb/abb-common-ux-react/components/Heading";
import React from "react";

const StyledSubHeader = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.families.fontAbbBold} !important;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeL} !important;
  line-height: ${(props) => props.theme.fonts.lineHeights.lineHeightMl} !important;
`;

export const SubHeader = (
  props: Omit<HeadingProps, "blackAndWhite" | "showCursor" | "level">
): JSX.Element => <StyledSubHeader {...props} level={2} blackAndWhite={true} showCursor={false} />;
