/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrentUserCompanyUserDto,
    CurrentUserCompanyUserDtoFromJSON,
    CurrentUserCompanyUserDtoFromJSONTyped,
    CurrentUserCompanyUserDtoToJSON,
} from './CurrentUserCompanyUserDto';

/**
 * 
 * @export
 * @interface CurrentUserCompanyRoleDto
 */
export interface CurrentUserCompanyRoleDto {
    /**
     * 
     * @type {CurrentUserCompanyUserDto}
     * @memberof CurrentUserCompanyRoleDto
     */
    companyUser: CurrentUserCompanyUserDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CurrentUserCompanyRoleDto
     */
    roles: Array<string>;
}

export function CurrentUserCompanyRoleDtoFromJSON(json: any): CurrentUserCompanyRoleDto {
    return CurrentUserCompanyRoleDtoFromJSONTyped(json, false);
}

export function CurrentUserCompanyRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserCompanyRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyUser': CurrentUserCompanyUserDtoFromJSON(json['companyUser']),
        'roles': json['roles'],
    };
}

export function CurrentUserCompanyRoleDtoToJSON(value?: CurrentUserCompanyRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyUser': CurrentUserCompanyUserDtoToJSON(value.companyUser),
        'roles': value.roles,
    };
}

