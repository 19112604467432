/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateApplicationNotificationIsReadStatusCommand
 */
export interface UpdateApplicationNotificationIsReadStatusCommand {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicationNotificationIsReadStatusCommand
     */
    statusToSet?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateApplicationNotificationIsReadStatusCommand
     */
    applicationNotificationIds?: Array<number> | null;
}

export function UpdateApplicationNotificationIsReadStatusCommandFromJSON(json: any): UpdateApplicationNotificationIsReadStatusCommand {
    return UpdateApplicationNotificationIsReadStatusCommandFromJSONTyped(json, false);
}

export function UpdateApplicationNotificationIsReadStatusCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateApplicationNotificationIsReadStatusCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusToSet': !exists(json, 'statusToSet') ? undefined : json['statusToSet'],
        'applicationNotificationIds': !exists(json, 'applicationNotificationIds') ? undefined : json['applicationNotificationIds'],
    };
}

export function UpdateApplicationNotificationIsReadStatusCommandToJSON(value?: UpdateApplicationNotificationIsReadStatusCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusToSet': value.statusToSet,
        'applicationNotificationIds': value.applicationNotificationIds,
    };
}

