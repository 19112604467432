import { css } from "styled-components";
import { commonUXTheme } from "./commonUXVariables";

export const dialogStyles = css`
  .ABB_CommonUX_Dialog__closeButton {
    z-index: ${commonUXTheme.zIndices.dialog} !important;
  }

  .ABB_CommonUX_Dialog__title {
    font-size: ${commonUXTheme.fonts.sizes.fontSizeLM} !important;
    font-family: ${commonUXTheme.fonts.families.fontAbbBold} !important;
  }

  .ABB_CommonUX_MessageDialog__buttons > button {
    border-radius: ${commonUXTheme.borders.radiuses.borderRadiusXl} !important;
    height: ${commonUXTheme.heights.elementMediumHeight} !important;
  }

  .ABB_CommonUX_MessageDialog__buttons > .ABB_CommonUX_Button__primaryblue {
    background-color: ${commonUXTheme.colors.brandRed} !important;
    border-color: ${commonUXTheme.colors.brandRed} !important;
    &:hover {
      background-color: ${commonUXTheme.colors.red70} !important;
      border-color: ${commonUXTheme.colors.red70} !important;
    }
  }

  .ABB_CommonUX_Notification__buttons > button {
    border-radius: ${commonUXTheme.borders.radiuses.borderRadiusXl} !important;
    height: ${commonUXTheme.heights.elementSmallHeight} !important;
  }

  .ABB_CommonUX_Notification__buttons > .ABB_CommonUX_Button__primaryblue {
    background-color: hsla(0, 0%, 100%, 0.05) !important;
    border: ${commonUXTheme.borders.widths.borderWidthM} solid ${commonUXTheme.colors.grey90} !important;
    color: ${commonUXTheme.colors.grey90} !important;
    &:hover {
      background-color: ${commonUXTheme.colors.grey10} !important;
    }
  }

  .ABB_CommonUX_MessageDialog__message {
    white-space: pre-wrap !important;
  }

  .dialog-content {
    max-height: calc(100vh - 75px);
    padding-right: ${commonUXTheme.sizes.l} !important;
    position: relative;
  }

  .dialog-container {
    max-width: 60%;
    @media screen and (max-width: 992px) {
      max-width: 100%;
    }
  }

  .dialog-no-padding {
    max-height: calc(100vh - 75px);
    padding: 0 !important;
    padding-top: ${commonUXTheme.sizes.m} !important;
    position: relative;
  }

  .dialog-container-wide {
    @media screen and (max-width: ${commonUXTheme.breakpoints.xxl}) {
      width: 100%;
      height: 100%;
    }
  }

  .dialog-container-root-wide {
    @media screen and (max-width: ${commonUXTheme.breakpoints.xxl}) {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      margin: 10px auto;
    }
  }

  .ABB_CommonUX_Pagination__rowsPerPagePopup.ABB_CommonUX_Pagination__light
    > ul
    > li.ABB_CommonUX_Pagination__selected {
    background: unset !important;
    border-left: ${commonUXTheme.borders.widths.borderWidthL} solid ${commonUXTheme.colors.red50};
    color: unset;
  }
`;
