import React, { useCallback, useEffect } from "react";
import { Icon } from "@abb/abb-common-ux-react";
import { Button } from "framework/components/Button";
import { TFunction } from "i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SubHeader } from "./styled/SubHeader";
import { Dialog } from "./Dialog";

const Container = styled.div`
  max-width: 500px;
  width: 100%;
  padding-bottom: ${(props) => props.theme.sizes.m};
  white-space: pre-line;
`;

const CustomButton = styled(Button)`
  display: block;
  width: fit-content;
  margin-left: auto;
`;

const RedirectText = styled.p`
  text-align: center;
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.sizes.l};
  gap: ${(props) => props.theme.sizes.s};
`;

const Bold = styled.b`
  display: inline-block;
  width: 50px;
  text-align: left;
`;

interface RedirectDialogProps {
  showDialog: boolean;
  text: string;
  buttonText?: string;
  /**
   * Path to redirect to.
   */
  redirectTo?: string;
  /**
   * Callback function that will be used instead of the default redirect.
   *
   * Using this will cause `redirectTo` to be ignored.
   */
  redirectCallback?: () => void;
  countdownFrom?: number;
  t: TFunction;
  icon?: string;
  title?: string;
  hideBackground?: boolean;
}

export const RedirectDialog = ({
  showDialog,
  text,
  redirectTo,
  redirectCallback,
  buttonText = "Ok",
  countdownFrom,
  t,
  icon,
  title,
  hideBackground = true
}: RedirectDialogProps): JSX.Element => {
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(countdownFrom);

  const redirect = useCallback(() => {
    if (redirectCallback) {
      redirectCallback();
    } else if (redirectTo) {
      navigate(redirectTo, { replace: true });
    }
  }, [navigate, redirectCallback, redirectTo]);

  useEffect(() => {
    let timer: number | undefined | NodeJS.Timeout;
    if (countdownFrom) {
      timer = setInterval(
        () =>
          setCounter((v) => {
            if (v) {
              return v - 1;
            }
            return v;
          }),
        1000
      );
    }

    return () => clearInterval(timer);
  }, [countdownFrom]);

  useEffect(() => {
    if (countdownFrom) {
      if (counter === 0) {
        redirect();
      }
    }
  }, [counter, countdownFrom, redirect, navigate]);

  return (
    <Dialog
      isOpen={showDialog}
      closeOnLostFocus={false}
      closeOnEscape={false}
      showCloseButton={false}
      hideBackground={hideBackground}
    >
      <Container>
        <TitleContainer>
          {icon ? <Icon name={icon} sizeClass="large" color="color-status-error" /> : null}
          {title ? <SubHeader text={title} /> : null}
        </TitleContainer>

        <Text>{text}</Text>
        {counter !== undefined ? (
          <RedirectText>
            {t("You will be redirected in...")} <Bold>{counter}</Bold>
          </RedirectText>
        ) : null}
        <CustomButton
          style={{ marginLeft: "auto" }}
          buttonType="primary"
          text={buttonText}
          onClick={redirect}
        />
      </Container>
    </Dialog>
  );
};
