/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserBaseDto
 */
export interface UserBaseDto {
    /**
     * 
     * @type {number}
     * @memberof UserBaseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserBaseDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserBaseDto
     */
    azureAdId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserBaseDto
     */
    isDeleted: boolean;
}

export function UserBaseDtoFromJSON(json: any): UserBaseDto {
    return UserBaseDtoFromJSONTyped(json, false);
}

export function UserBaseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBaseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'azureAdId': json['azureAdId'],
        'isDeleted': json['isDeleted'],
    };
}

export function UserBaseDtoToJSON(value?: UserBaseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'azureAdId': value.azureAdId,
        'isDeleted': value.isDeleted,
    };
}

