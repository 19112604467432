/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactSalesSupportReasons,
    ContactSalesSupportReasonsFromJSON,
    ContactSalesSupportReasonsFromJSONTyped,
    ContactSalesSupportReasonsToJSON,
} from './ContactSalesSupportReasons';

/**
 * 
 * @export
 * @interface ContactSalesSupportReasonDto
 */
export interface ContactSalesSupportReasonDto {
    /**
     * 
     * @type {ContactSalesSupportReasons}
     * @memberof ContactSalesSupportReasonDto
     */
    reasonId?: ContactSalesSupportReasons;
    /**
     * 
     * @type {string}
     * @memberof ContactSalesSupportReasonDto
     */
    description?: string | null;
}

export function ContactSalesSupportReasonDtoFromJSON(json: any): ContactSalesSupportReasonDto {
    return ContactSalesSupportReasonDtoFromJSONTyped(json, false);
}

export function ContactSalesSupportReasonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSalesSupportReasonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonId': !exists(json, 'reasonId') ? undefined : ContactSalesSupportReasonsFromJSON(json['reasonId']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ContactSalesSupportReasonDtoToJSON(value?: ContactSalesSupportReasonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonId': ContactSalesSupportReasonsToJSON(value.reasonId),
        'description': value.description,
    };
}

