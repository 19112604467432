import React, { useEffect } from "react";
import styled from "styled-components";
import { CompanyDto, QuoteBaseDto, ShoppingCartDto } from "api";
import { TFunction } from "i18next";
import { ReviseQuoteFromCart } from "applications/manage/manageQuotes/containers/QuoteView";
import { ErrorMessage, FormikProps } from "formik";
import { InputLabel } from "framework/components/InputLabel";
import { Dropdown, DropdownOption, Input } from "@abb/abb-common-ux-react";
import { LabelWithText } from "framework/components/LabelWithText";
import { handleFormikValueChange } from "utilities/formikUtils";
import { DetailsRadioGroup } from "applications/shoppingCart/components/orderDetails/DetailsRadioGroup";
import { Error } from "framework/components/Error";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { accessRequestsSagas } from "applications/accessRequests/sagas/accessRequestsSagas";
import { ContentContainer } from "applications/shoppingCart/components/orderDetails/ContentContainer";

const FlexColumn = styled.div`
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: ${(props) => props.theme.sizes.m};
`;

const FlexRow = styled.div`
  display: flex;
  column-gap: ${(props) => props.theme.sizes.lm};
`;

const CustomDropdown = styled.div`
  .ABB_CommonUX_Dropdown__comp {
    div[class$="-menu"] > div {
      max-height: 200px !important;
    }
  }
`;

/**
 * @param isEditable Override internal logic to decide if component should be in edit mode.
 */
interface ProjectDetailsInfoProps {
  quote: QuoteBaseDto | undefined;
  t: TFunction;
  shoppingCart: ShoppingCartDto | undefined;
  selectedCompany: CompanyDto | undefined;
  formik: FormikProps<ReviseQuoteFromCart> | undefined;
  isShoppingCartInfo?: boolean;
  reviseMode: boolean;
  isEditable?: boolean;
}

const ProjectDetailsInfo = ({
  quote,
  t,
  selectedCompany,
  shoppingCart,
  formik,
  isShoppingCartInfo,
  reviseMode,
  isEditable
}: ProjectDetailsInfoProps): JSX.Element => {
  const dispatch = useDispatch();
  const countries = useSelector(getCountries);

  useEffect(() => {
    if (dispatch && (!countries || countries.length === 0)) {
      dispatch(accessRequestsSagas.getCountries.createAction({}));
    }
  },[quote]);

  const isReadMode = !!quote;
  const isCreatingQuote = !quote && !!shoppingCart;

  return (
    <ContentContainer title={t("Project details")}>
      {(isCreatingQuote || reviseMode || isEditable) && formik && (
        <div>
          <div>
            <InputLabel label={t("Project name")} isRequired />
            <Input
              value={formik.values.projectName}
              dataType={"text"}
              validationResult={{
                valid: !!formik.errors.projectName && !!formik.touched.projectName ? false : true,
                text: formik.errors.projectName
              }}
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              inputAttributes={{
                name: "projectName",
                onChange: formik.handleChange
              }}
              onLostFocus={formik.handleBlur}
            />
          </div>
          <br />
          <div>
            <InputLabel label={t("SFDC opportunity reference")} />
            <Input
              value={formik.values.sfdcReference}
              dataType={"text"}
              validationResult={{
                valid:
                  !!formik.errors.sfdcReference && !!formik.touched.sfdcReference ? false : true,
                text: formik.errors.sfdcReference
              }}
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              inputAttributes={{
                name: "sfdcReference",
                onChange: formik.handleChange
              }}
              onLostFocus={formik.handleBlur}
            />
          </div>
          <br />
          <div>
            <DetailsRadioGroup
              t={t}
              isRequired
              title={t("Company is account customer?")}
              value={formik.values.isAccountCustomer?.toString()}
              setValue={(v) =>
                handleFormikValueChange(formik, "isAccountCustomer", v === "true", true)
              }
            />
            <ErrorMessage name={"isAccountCustomer"}>{(err) => <Error msg={err} />}</ErrorMessage>
          </div>
          {formik.values.isAccountCustomer === false && (
            <>
              <div>
                <InputLabel label={"Account customer company name"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "accountCustomerCompanyName",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.accountCustomerCompanyName}
                  validationResult={{
                    valid:
                      formik.errors.accountCustomerCompanyName &&
                      formik.touched.accountCustomerCompanyName
                        ? false
                        : true,
                    text: formik.errors.accountCustomerCompanyName
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"Account customer street address"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "accountCustomerCompanyStreetAddress",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.accountCustomerCompanyStreetAddress}
                  validationResult={{
                    valid:
                      formik.errors.accountCustomerCompanyStreetAddress &&
                      formik.touched.accountCustomerCompanyStreetAddress
                        ? false
                        : true,
                    text: formik.errors.accountCustomerCompanyStreetAddress
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"Account customer city"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "accountCustomerCompanyCity",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.accountCustomerCompanyCity}
                  validationResult={{
                    valid:
                      formik.errors.accountCustomerCompanyCity &&
                      formik.touched.accountCustomerCompanyCity
                        ? false
                        : true,
                    text: formik.errors.accountCustomerCompanyCity
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"Account customer country"} isRequired={true} />
                <CustomDropdown>
                  <Dropdown
                    value={[
                      {
                        value: formik.values.accountCustomerCompanyCountryCode,
                        label: formik.values.accountCustomerCompanyCountry ?? ""
                      }
                    ]}
                    onChange={(v) => {
                      formik.setValues({
                        ...formik.values,
                        accountCustomerCompanyCountryCode: v[0].value,
                        accountCustomerCompanyCountry: v[0].label
                      });
                    }}
                    searchable
                    monochrome
                    validationState={{
                      valid:
                        formik.errors.accountCustomerCompanyCountryCode &&
                        formik.touched.accountCustomerCompanyCountryCode
                          ? false
                          : true,
                      message: formik.errors.accountCustomerCompanyCountryCode
                    }}
                    showValidationIconWhenInvalid={false}
                    showValidationBarWhenInvalid={true}
                  >
                    {countries?.map((c) => (
                      <DropdownOption key={c.code} value={c.code} label={c.name} />
                    ))}
                  </Dropdown>
                </CustomDropdown>
              </div>
              <div>
                <InputLabel label={"Account customer ICV Code"} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "accountCustomerIcvCode",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.accountCustomerIcvCode}
                  validationResult={{
                    valid:
                      formik.errors.accountCustomerIcvCode && formik.touched.accountCustomerIcvCode
                        ? false
                        : true,
                    text: formik.errors.accountCustomerIcvCode
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
            </>
          )}
          {formik.values.isAccountCustomer === true && (
            <div>
              <LabelWithText
                label={t("Account customer")}
                text={[
                  selectedCompany?.name,
                  selectedCompany?.addressStreet,
                  selectedCompany?.addressCity,
                  selectedCompany?.countryName,
                  selectedCompany?.globalCustomerNumber
                ]
                  .filter((v) => !!v)
                  .join("\n")}
              />
            </div>
          )}
          <div>
            <DetailsRadioGroup
              t={t}
              isRequired
              title={t("Company is end customer?")}
              value={formik.values.isEndCustomer?.toString()}
              setValue={(v) => handleFormikValueChange(formik, "isEndCustomer", v === "true", true)}
            />
            <ErrorMessage name={"isEndCustomer"}>{(err) => <Error msg={err} />}</ErrorMessage>
          </div>
          {formik.values.isEndCustomer === false && (
            <>
              <div>
                <InputLabel label={"End customer company name"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "endCustomerCompanyName",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.endCustomerCompanyName}
                  validationResult={{
                    valid:
                      formik.errors.endCustomerCompanyName && formik.touched.endCustomerCompanyName
                        ? false
                        : true,
                    text: formik.errors.endCustomerCompanyName
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"End customer street address"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "endCustomerCompanyStreetAddress",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.endCustomerCompanyStreetAddress}
                  validationResult={{
                    valid:
                      formik.errors.endCustomerCompanyStreetAddress &&
                      formik.touched.endCustomerCompanyStreetAddress
                        ? false
                        : true,
                    text: formik.errors.endCustomerCompanyStreetAddress
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"End customer city"} isRequired={true} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "endCustomerCompanyCity",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.endCustomerCompanyCity}
                  validationResult={{
                    valid:
                      formik.errors.endCustomerCompanyCity && formik.touched.endCustomerCompanyCity
                        ? false
                        : true,
                    text: formik.errors.endCustomerCompanyCity
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
              <div>
                <InputLabel label={"End customer country"} isRequired={true} />
                <CustomDropdown>
                  <Dropdown
                    value={[
                      {
                        value: formik.values.endCustomerCompanyCountryCode,
                        label: formik.values.endCustomerCompanyCountry ?? ""
                      }
                    ]}
                    onChange={(v) => {
                      formik.setValues({
                        ...formik.values,
                        endCustomerCompanyCountryCode: v[0].value,
                        endCustomerCompanyCountry: v[0].label
                      });
                    }}
                    searchable
                    monochrome
                    validationState={{
                      valid:
                        formik.errors.endCustomerCompanyCountryCode &&
                        formik.touched.endCustomerCompanyCountryCode
                          ? false
                          : true,
                      message: formik.errors.endCustomerCompanyCountryCode
                    }}
                    showValidationIconWhenInvalid={false}
                    showValidationBarWhenInvalid={true}
                  >
                    {countries?.map((c) => (
                      <DropdownOption key={c.code} value={c.code} label={c.name} />
                    ))}
                  </Dropdown>
                </CustomDropdown>
              </div>
              <div>
                <InputLabel label={"End customer GUID"} />
                <Input
                  dataType="text"
                  inputAttributes={{
                    name: "endCustomerGuid",
                    onChange: formik.handleChange,
                    rows: 8
                  }}
                  value={formik.values.endCustomerGuid}
                  validationResult={{
                    valid:
                      formik.errors.endCustomerGuid && formik.touched.endCustomerGuid
                        ? false
                        : true,
                    text: formik.errors.endCustomerGuid
                  }}
                  instantValidation={true}
                  showValidationBarWhenInvalid={true}
                  onLostFocus={formik.handleBlur}
                />
              </div>
            </>
          )}
          {formik.values.isEndCustomer === true && (
            <div>
              <LabelWithText
                label={t("End customer")}
                text={[
                  selectedCompany?.name,
                  selectedCompany?.addressStreet,
                  selectedCompany?.addressCity,
                  selectedCompany?.countryName,
                  selectedCompany?.globalCustomerNumber
                ]
                  .filter((v) => !!v)
                  .join("\n")}
              />
            </div>
          )}
        </div>
      )}

      {(isReadMode || isShoppingCartInfo) && !reviseMode && !isEditable && (
        <div>
          {shoppingCart?.projectName && (
            <LabelWithText label={t("Project name")} text={shoppingCart?.projectName} />
          )}
          {shoppingCart?.sfdcReference && (
            <LabelWithText
              label={t("SFDC opportunity reference")}
              text={shoppingCart?.sfdcReference}
            />
          )}
          <FlexRow>
            {shoppingCart?.shoppingCartAccountCustomer && (
              <FlexColumn>
                <LabelWithText
                  label={t("Account customer")}
                  text={[
                    shoppingCart.shoppingCartAccountCustomer.name,
                    shoppingCart.shoppingCartAccountCustomer.streetAddress,
                    shoppingCart.shoppingCartAccountCustomer.city,
                    shoppingCart.shoppingCartAccountCustomer.country,
                    shoppingCart.shoppingCartAccountCustomer.etcdIcvCode
                  ]
                    .filter((v) => !!v)
                    .join("\n")}
                />
              </FlexColumn>
            )}
            {!shoppingCart?.shoppingCartAccountCustomer && quote && (
              <FlexColumn>
                <LabelWithText
                  label={t("Account customer")}
                  text={[quote.company, quote.companyCountry, quote.companyId]
                    .filter((v) => !!v)
                    .join("\n")}
                />
              </FlexColumn>
            )}
            {shoppingCart?.shoppingCartEndCustomer && (
              <FlexColumn>
                <LabelWithText
                  label={t("End customer")}
                  text={[
                    shoppingCart.shoppingCartEndCustomer.name,
                    shoppingCart.shoppingCartEndCustomer.streetAddress,
                    shoppingCart.shoppingCartEndCustomer.city,
                    shoppingCart.shoppingCartEndCustomer.country,
                    shoppingCart.shoppingCartEndCustomer.guidCode
                  ]
                    .filter((v) => !!v)
                    .join("\n")}
                />
              </FlexColumn>
            )}
          </FlexRow>
        </div>
      )}
    </ContentContainer>
  );
};

export default ProjectDetailsInfo;
