/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryContactPersonRoleTypes,
    CountryContactPersonRoleTypesFromJSON,
    CountryContactPersonRoleTypesFromJSONTyped,
    CountryContactPersonRoleTypesToJSON,
} from './CountryContactPersonRoleTypes';
import {
    CountryModel,
    CountryModelFromJSON,
    CountryModelFromJSONTyped,
    CountryModelToJSON,
} from './CountryModel';
import {
    PartiallyActiveState,
    PartiallyActiveStateFromJSON,
    PartiallyActiveStateFromJSONTyped,
    PartiallyActiveStateToJSON,
} from './PartiallyActiveState';

/**
 * 
 * @export
 * @interface UserCountryRolesModel
 */
export interface UserCountryRolesModel {
    /**
     * 
     * @type {CountryModel}
     * @memberof UserCountryRolesModel
     */
    country?: CountryModel;
    /**
     * 
     * @type {Array<CountryContactPersonRoleTypes>}
     * @memberof UserCountryRolesModel
     */
    roleList?: Array<CountryContactPersonRoleTypes> | null;
    /**
     * 
     * @type {PartiallyActiveState}
     * @memberof UserCountryRolesModel
     */
    isActive?: PartiallyActiveState;
}

export function UserCountryRolesModelFromJSON(json: any): UserCountryRolesModel {
    return UserCountryRolesModelFromJSONTyped(json, false);
}

export function UserCountryRolesModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCountryRolesModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': !exists(json, 'country') ? undefined : CountryModelFromJSON(json['country']),
        'roleList': !exists(json, 'roleList') ? undefined : (json['roleList'] === null ? null : (json['roleList'] as Array<any>).map(CountryContactPersonRoleTypesFromJSON)),
        'isActive': !exists(json, 'isActive') ? undefined : PartiallyActiveStateFromJSON(json['isActive']),
    };
}

export function UserCountryRolesModelToJSON(value?: UserCountryRolesModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': CountryModelToJSON(value.country),
        'roleList': value.roleList === undefined ? undefined : (value.roleList === null ? null : (value.roleList as Array<any>).map(CountryContactPersonRoleTypesToJSON)),
        'isActive': PartiallyActiveStateToJSON(value.isActive),
    };
}

