/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDisabledManagedPriceListDto
 */
export interface UserDisabledManagedPriceListDto {
    /**
     * 
     * @type {number}
     * @memberof UserDisabledManagedPriceListDto
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof UserDisabledManagedPriceListDto
     */
    managedPriceListId: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDisabledManagedPriceListDto
     */
    isDisabled: boolean;
}

export function UserDisabledManagedPriceListDtoFromJSON(json: any): UserDisabledManagedPriceListDto {
    return UserDisabledManagedPriceListDtoFromJSONTyped(json, false);
}

export function UserDisabledManagedPriceListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDisabledManagedPriceListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'managedPriceListId': json['managedPriceListId'],
        'isDisabled': json['isDisabled'],
    };
}

export function UserDisabledManagedPriceListDtoToJSON(value?: UserDisabledManagedPriceListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'managedPriceListId': value.managedPriceListId,
        'isDisabled': value.isDisabled,
    };
}

