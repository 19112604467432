import { Input } from "@abb/abb-common-ux-react";
import styled, { DefaultTheme } from "styled-components";

export interface ResizableInputParams {
  resizeAxis?: "vertical" | "horizontal" | undefined;
  maxWidth?: string;
  maxHeight?: string;
}
type DefaultType = "verticalS" | "verticalM" | "verticalL" | "large";

export const ResizableInput = styled(Input)<{
  defaultType?: DefaultType;
  resizeParams?: ResizableInputParams;
}>`
  ${(props) => {
    const params = getDefaultParams(props.theme, props.defaultType, props.resizeParams);
    return `.ABB_CommonUX_Input__resizable {
    ${params.resizeAxis !== undefined ? "resize: " + params.resizeAxis : ""};
      ${params.maxWidth !== undefined ? "max-width: " + params.maxWidth : ""};
      ${params.maxHeight !== undefined ? "max-height: " + params.maxHeight : ""};
    }
    .ABB_CommonUX_Input__inputWrapper {
      ${params.maxWidth !== undefined ? "max-width: " + params.maxWidth : ""};
    }
    .ABB_CommonUX_Input__labelContainer {
      ${params.maxWidth !== undefined ? "max-width: " + params.maxWidth : ""};
    }`;
  }}
`;

ResizableInput.defaultProps = { resizable: true };

const getDefaultParams = (
  theme: DefaultTheme,
  defaultType?: DefaultType,
  resizeParams?: ResizableInputParams
): ResizableInputParams => {
  if (defaultType) {
    switch (defaultType) {
      case "verticalS":
        return { resizeAxis: "vertical", maxHeight: "10lh", maxWidth: resizeParams?.maxWidth };
      case "verticalM":
        return { resizeAxis: "vertical", maxHeight: "20lh", maxWidth: resizeParams?.maxWidth };
      case "verticalL":
        return { resizeAxis: "vertical", maxHeight: "30lh", maxWidth: resizeParams?.maxWidth };
      case "large":
        return {
          resizeAxis: resizeParams?.resizeAxis,
          maxHeight: theme?.breakpoints.sm,
          maxWidth: theme?.breakpoints.md
        };
    }
  } else return { ...resizeParams };
};
