/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeliveryTimeDto,
    DeliveryTimeDtoFromJSON,
    DeliveryTimeDtoToJSON,
    UpdateHeaderDescriptionCommand,
    UpdateHeaderDescriptionCommandFromJSON,
    UpdateHeaderDescriptionCommandToJSON,
} from '../models';

export interface ApiDeliveryTimesDeliveryTimesGetRequest {
    materialCode?: string;
    orderingCode?: string;
}

export interface ApiDeliveryTimesUpdateDescriptionDeliveryTimesPutRequest {
    updateHeaderDescriptionCommand?: UpdateHeaderDescriptionCommand;
}

/**
 * 
 */
export class DeliveryTimesApi extends runtime.BaseAPI {

    /**
     */
    async apiDeliveryTimesDeliveryTimesGetRaw(requestParameters: ApiDeliveryTimesDeliveryTimesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeliveryTimeDto>>> {
        const queryParameters: any = {};

        if (requestParameters.materialCode !== undefined) {
            queryParameters['MaterialCode'] = requestParameters.materialCode;
        }

        if (requestParameters.orderingCode !== undefined) {
            queryParameters['OrderingCode'] = requestParameters.orderingCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/DeliveryTimes/delivery-times`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryTimeDtoFromJSON));
    }

    /**
     */
    async apiDeliveryTimesDeliveryTimesGet(requestParameters: ApiDeliveryTimesDeliveryTimesGetRequest, initOverrides?: RequestInit): Promise<Array<DeliveryTimeDto>> {
        const response = await this.apiDeliveryTimesDeliveryTimesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDeliveryTimesUpdateDescriptionDeliveryTimesPutRaw(requestParameters: ApiDeliveryTimesUpdateDescriptionDeliveryTimesPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/DeliveryTimes/update-description-delivery-times`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHeaderDescriptionCommandToJSON(requestParameters.updateHeaderDescriptionCommand),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiDeliveryTimesUpdateDescriptionDeliveryTimesPut(requestParameters: ApiDeliveryTimesUpdateDescriptionDeliveryTimesPutRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiDeliveryTimesUpdateDescriptionDeliveryTimesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
