/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationReturnModel,
    PaginationReturnModelFromJSON,
    PaginationReturnModelFromJSONTyped,
    PaginationReturnModelToJSON,
} from './PaginationReturnModel';
import {
    SupplierTermsAndConditionsDto,
    SupplierTermsAndConditionsDtoFromJSON,
    SupplierTermsAndConditionsDtoFromJSONTyped,
    SupplierTermsAndConditionsDtoToJSON,
} from './SupplierTermsAndConditionsDto';

/**
 * 
 * @export
 * @interface SupplierTermsConditionListVm
 */
export interface SupplierTermsConditionListVm {
    /**
     * 
     * @type {Array<SupplierTermsAndConditionsDto>}
     * @memberof SupplierTermsConditionListVm
     */
    termsAndConditions: Array<SupplierTermsAndConditionsDto>;
    /**
     * 
     * @type {PaginationReturnModel}
     * @memberof SupplierTermsConditionListVm
     */
    criteria: PaginationReturnModel;
}

export function SupplierTermsConditionListVmFromJSON(json: any): SupplierTermsConditionListVm {
    return SupplierTermsConditionListVmFromJSONTyped(json, false);
}

export function SupplierTermsConditionListVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierTermsConditionListVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'termsAndConditions': ((json['termsAndConditions'] as Array<any>).map(SupplierTermsAndConditionsDtoFromJSON)),
        'criteria': PaginationReturnModelFromJSON(json['criteria']),
    };
}

export function SupplierTermsConditionListVmToJSON(value?: SupplierTermsConditionListVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'termsAndConditions': ((value.termsAndConditions as Array<any>).map(SupplierTermsAndConditionsDtoToJSON)),
        'criteria': PaginationReturnModelToJSON(value.criteria),
    };
}

