import { Input, Popup, Table, WithPopup } from "@abb/abb-common-ux-react";
import {
  ProFormaItem,
  ShoppingCartForm
} from "applications/shoppingCart/containers/ShoppingCartView";
import { FormikProps } from "formik";
import { Button } from "framework/components/Button";
import { Dialog } from "framework/components/Dialog";
import { TFunction } from "i18next";
import React, { memo } from "react";
import styled from "styled-components";
import { priceStringToNumber } from "utilities/currencyUtils";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.s};
  max-width: 1000px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.sizes.sm};
  margin-block: ${(props) => props.theme.sizes.m};
`;

interface ProFormaDialogProps {
  t: TFunction;
  isOpen: boolean;
  onDialogClose: () => void;
  formik: FormikProps<ShoppingCartForm>;
  currency: string | null | undefined;
  isReadOnly?: boolean;
}

export const ProFormaDialog = ({
  t,
  onDialogClose,
  isOpen,
  formik,
  currency,
  isReadOnly
}: ProFormaDialogProps): JSX.Element | null => {
  return (
    <Dialog
      isOpen={isOpen}
      title={t("Pro forma values")}
      onClose={() => onDialogClose()}
      contentClassName="dialog-content overflow-auto"
      closeOnLostFocus={true}
    >
      <Form>
        <Table
          sizeClass={"small"}
          borderType={"none"}
          fitToContent
          style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          <thead>
            <tr>
              <th>{t("Product")}</th>
              <th style={{ width: "60px" }}>{t("Qty")}</th>
              <th style={{ width: "120px" }}>Unit Price</th>
              <th style={{ width: "120px" }}>Total Price</th>
              <th style={{ width: "150px" }}>PF unit price</th>
              <th style={{ width: "150px" }}>PF total price</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.proFormaItems?.map((proFormaItem, i) => (
              <ProFormaRow
                key={proFormaItem.orderingCode}
                handleChange={formik.handleChange}
                proFormaItem={proFormaItem}
                index={i}
                currency={currency}
                handleBlur={formik.handleBlur}
                errorMessage={(formik.errors.proFormaItems as string[] | undefined)?.[i]}
                isTouched={(formik.touched.proFormaItems as boolean[] | undefined)?.[i]}
                isReadOnly={isReadOnly}
              />
            ))}
          </tbody>
        </Table>
        <ButtonContainer>
          <Button
            buttonType="secondary"
            text={t("Clear")}
            onClick={() => {
              const resetProFormaItems = formik.values.proFormaItems?.map((proFormaItem) => ({
                ...proFormaItem,
                proFormaPrice: priceStringToNumber(
                  proFormaItem.unitQuotedPrice ?? proFormaItem.unitPrice
                )
              }));
              formik.setFieldValue("proFormaItems", resetProFormaItems);
            }}
          />
          <Button buttonType="primary" text={t("Save")} onClick={() => onDialogClose()} />
        </ButtonContainer>
      </Form>
    </Dialog>
  );
};

const FormRow = styled.tr`
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
`;

const Text = styled.p<{ isBold?: boolean; isSmall?: boolean }>`
  margin: 0;
  font-size: ${(props) =>
    props.isSmall ? props.theme.fonts.sizes.fontSizeXs : props.theme.fonts.sizes.fontSizeS};
  font-weight: ${(props) =>
    props.isBold ? props.theme.fonts.weights.heavy : props.theme.fonts.weights.normal};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FormRowItem = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface ProFormaRowProps {
  proFormaItem: ProFormaItem;
  index: number;
  handleChange: (e: React.ChangeEvent<any>) => void;
  currency: string | null | undefined;
  handleBlur: (e: React.FocusEvent<any>) => void;
  errorMessage: string | undefined;
  isTouched: boolean | undefined;
  isReadOnly?: boolean;
}

const ProFormaRow = memo(
  ({
    proFormaItem,
    index,
    handleChange,
    currency,
    handleBlur,
    errorMessage,
    isTouched,
    isReadOnly
  }: ProFormaRowProps): JSX.Element => {
    const name = `proFormaItems.${index}.proFormaPrice`;
    const totalProForma =
      proFormaItem?.proFormaPrice !== undefined && proFormaItem.quantity !== undefined
        ? proFormaItem.proFormaPrice * proFormaItem.quantity
        : undefined;
    return (
      <FormRow>
        <FormRowItem>
          <WithPopup>
            <div>
              <Text isBold>{proFormaItem.orderingCode}</Text>
              <Text isSmall>{proFormaItem.displayName}</Text>
            </div>
            <Popup
              style={{ maxWidth: "400px", whiteSpace: "pre-wrap", fontSize: "14px" }}
              trigger={"hover"}
            >
              {proFormaItem.displayName}
            </Popup>
          </WithPopup>
        </FormRowItem>
        <FormRowItem>{proFormaItem.quantity}</FormRowItem>
        <FormRowItem>{proFormaItem.unitQuotedPrice ?? proFormaItem.unitPrice}</FormRowItem>
        <FormRowItem>{proFormaItem.quotedPrice ?? proFormaItem.price}</FormRowItem>
        <FormRowItem>
          {isReadOnly ? (
            proFormaItem.proFormaPrice ? (
              `${proFormaItem.proFormaPrice} ${currency}`
            ) : (
              ""
            )
          ) : (
            <Input
              suffix={currency ?? undefined}
              dataType="text"
              value={proFormaItem.proFormaPrice?.toString()}
              inputAttributes={{
                name: name,
                onChange: handleChange
              }}
              validationResult={{
                valid: !!errorMessage && !!isTouched ? false : true,
                text: errorMessage
              }}
              instantValidation={true}
              showValidationIconWhenInvalid={false}
              showValidationBarWhenInvalid={true}
              onLostFocus={handleBlur}
            />
          )}
        </FormRowItem>
        <FormRowItem>{totalProForma ? `${totalProForma} ${currency}` : ""}</FormRowItem>
      </FormRow>
    );
  }
);

ProFormaRow.displayName = "ProFormaRow";
