/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreatedItem,
    CreatedItemFromJSON,
    CreatedItemFromJSONTyped,
    CreatedItemToJSON,
} from './CreatedItem';

/**
 * 
 * @export
 * @interface ReviseQuoteItemDto
 */
export interface ReviseQuoteItemDto {
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteItemDto
     */
    orderingCode: string;
    /**
     * 
     * @type {number}
     * @memberof ReviseQuoteItemDto
     */
    quotedPrice?: number | null;
    /**
     * 
     * @type {Array<CreatedItem>}
     * @memberof ReviseQuoteItemDto
     */
    modificationItems?: Array<CreatedItem> | null;
    /**
     * 
     * @type {number}
     * @memberof ReviseQuoteItemDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ReviseQuoteItemDto
     */
    additionalInformation?: string | null;
}

export function ReviseQuoteItemDtoFromJSON(json: any): ReviseQuoteItemDto {
    return ReviseQuoteItemDtoFromJSONTyped(json, false);
}

export function ReviseQuoteItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviseQuoteItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderingCode': json['orderingCode'],
        'quotedPrice': !exists(json, 'quotedPrice') ? undefined : json['quotedPrice'],
        'modificationItems': !exists(json, 'modificationItems') ? undefined : (json['modificationItems'] === null ? null : (json['modificationItems'] as Array<any>).map(CreatedItemFromJSON)),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function ReviseQuoteItemDtoToJSON(value?: ReviseQuoteItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderingCode': value.orderingCode,
        'quotedPrice': value.quotedPrice,
        'modificationItems': value.modificationItems === undefined ? undefined : (value.modificationItems === null ? null : (value.modificationItems as Array<any>).map(CreatedItemToJSON)),
        'quantity': value.quantity,
        'additionalInformation': value.additionalInformation,
    };
}

