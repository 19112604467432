import React, { memo } from "react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";
import { Menu, MenuItem } from "@abb/abb-common-ux-react/components/Menu";
import { Icon, Popup, WithPopup } from "@abb/abb-common-ux-react";
import { AccessRights, TeamMemberDto, TeamMemberSources, TeamMemberStatus } from "api";
import { useSelector } from "react-redux";
import { getUserAccessRights } from "applications/common/reducers/userReducer";
import { useTranslation } from "react-i18next";
import { hasAnyAccessRight } from "utilities/authUtils";

export interface TeamMembersGridActionMethods {
  approve: (teamMember: TeamMemberDto) => void;
  reject: (teamMember: TeamMemberDto) => void;
  remove: (teamMember: TeamMemberDto) => void;
  changeRole: (teamMember: TeamMemberDto) => void;
}

export interface TeamMembersGridActionsProps extends ICellRendererParams {
  icon: string;
  hide?: boolean;
  methods: TeamMembersGridActionMethods;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const CustomMenu = styled(Menu)`
  right: -10px !important;
  top: -10px !important;
  .ABB_CommonUX_Menu__menuItemTitle {
    margin-right: ${(props) => props.theme.sizes.m};
  }
`;

export const TeamMembersGridActions = memo((props: TeamMembersGridActionsProps) => {
  const { t } = useTranslation();

  const teamMember = props.data as TeamMemberDto | undefined;

  const userAccessRights = useSelector(getUserAccessRights);

  const isTeamAdmin = hasAnyAccessRight(teamMember?.team?.id?.toString() ?? null, userAccessRights, AccessRights.ManageTeamMembers, AccessRights.SystemAdmin, AccessRights.ManagePlatformUsers);

  const menuItems: JSX.Element[] = [];

  if(teamMember && isTeamAdmin) {
    if(teamMember?.status === TeamMemberStatus.Approved) {
      menuItems.push(
        <MenuItem
          key={"abb/edit"}
          text={t("Change role")}
          icon="abb/edit"
          onSelect={() => props.methods.changeRole(teamMember)}
        ></MenuItem>
      );
    }

    if(teamMember?.status === TeamMemberStatus.Requested || teamMember?.status === TeamMemberStatus.Rejected) {
      menuItems.push(
        <MenuItem
          key={"abb/approve"}
          text={t("Approve")}
          icon="abb/add-user"
          onSelect={() => props.methods.approve(teamMember)}
        ></MenuItem>
      );
    }
  
    if(teamMember?.status === TeamMemberStatus.Requested) {
      menuItems.push(
        <MenuItem
          key={"abb/not-allowed"}
          text={t("Reject")}
          icon="abb/not-allowed"
          onSelect={() => props.methods.reject(teamMember)}
        ></MenuItem>
      );
    }
  
    if(teamMember?.source !== TeamMemberSources.AzureAd) {
      menuItems.push(
        <MenuItem
          key={"abb/remove"}
          text={t("Remove")}
          icon="abb/trash"
          onSelect={() => props.methods.remove(teamMember)}
        ></MenuItem>
      );
    }
  }
  
  // Hide if no items or hide is set
  if(props.hide || menuItems.length === 0) {
    return null;
  }
  
  return (
    <WithPopup>
      <div>
        <Container
          style={{
            height: props.node.rowHeight + "px"
          }}
        >
          <Icon name={"abb/menu-narrow"} sizeClass="small" />
        </Container>
      </div>
      <Popup trigger="click" position={["left center"]} disablePaddings disableDefaultStyle>
        <CustomMenu isOpen={true} alignToParent="right">
          {menuItems.map((x) => x)}
        </CustomMenu>
      </Popup>
    </WithPopup>
  );
});
