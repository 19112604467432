import { ShoppingCartDto } from "api";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getUserPriceListForDropdown } from "applications/quickPriceCheck/reducers/quickPriceCheckReducer";
import { quickPriceCheckSagas } from "applications/quickPriceCheck/sagas/quickPriceCheckSagas";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePriceListsDropdown = (
  shoppingCart: ShoppingCartDto | undefined,
  disabled = false
) => {
  const dispatch = useDispatch();
  const [selectedPriceList, setSelectedPriceList] = useState<number | null>(
    shoppingCart?.managedPriceListId ?? null
  );
  const userInformation = useSelector(getUserInformation);
  const userPriceLists = useSelector(getUserPriceListForDropdown);

  const changeSelectedPriceList = (rawPriceList: number | null | "null") => {
    const priceList = rawPriceList === "null" || rawPriceList === null ? null : rawPriceList;

    if (shoppingCart?.guid) {
      dispatch(
        shoppingCartSagas.changeShoppingCartPriceList.createAction({
          updateShoppingCartPriceListCommand: {
            shoppingCartGuid: shoppingCart.guid,
            managedPriceListId: priceList
          }
        })
      );
    }

    setSelectedPriceList(priceList);
  };

  useEffect(() => {
    if (userInformation) {
      dispatch(quickPriceCheckSagas.getUserPriceLists.createAction(undefined));
    }
  }, [dispatch, userInformation]);

  // Selected price list is not in the user's price lists (ie. company selection was changed) so fetch new data with the user's 'default' price list
  useEffect(() => {
    if (!disabled && userPriceLists && userPriceLists.length === 0 && selectedPriceList) {
      changeSelectedPriceList(null);
    } else if (
      !disabled &&
      selectedPriceList &&
      userPriceLists &&
      shoppingCart?.guid &&
      userPriceLists.length > 0 &&
      (!userPriceLists?.some((x) => x.value === selectedPriceList) ||
        selectedPriceList === null ||
        selectedPriceList === undefined)
    ) {
      changeSelectedPriceList(userPriceLists[0].value);
    }
    // eslint-disable-next-line
  }, [userPriceLists, dispatch]);

  return {
    selectedPriceList: [
      {
        value: selectedPriceList ?? "null",
        label: selectedPriceList ? shoppingCart?.priceListDisplayName : "No prices"
      }
    ] as {
      value: number | "null";
      label?: string | undefined;
    }[],
    userPriceLists,
    changeSelectedPriceList
  } as const;
};
