/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeamMemberStatus {
    Requested = 'Requested',
    Rejected = 'Rejected',
    Approved = 'Approved',
    Removed = 'Removed'
}

export function TeamMemberStatusFromJSON(json: any): TeamMemberStatus {
    return TeamMemberStatusFromJSONTyped(json, false);
}

export function TeamMemberStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMemberStatus {
    return json as TeamMemberStatus;
}

export function TeamMemberStatusToJSON(value?: TeamMemberStatus | null): any {
    return value as any;
}

