import { Popup, WithPopup } from "@abb/abb-common-ux-react/components/Popup";
import { SmallHeader } from "framework/components/styled/SmallHeader";
import { TFunction } from "i18next";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  min-height: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  max-width: 200px;
`;

const InfoText = styled.p`
  margin: 0;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
`;

const TooltipHeader = styled(SmallHeader)`
  margin-bottom: ${(props) => props.theme.sizes.m};
`;

interface QuoteAttachmentTooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  t: TFunction;
}

export const QuoteAttachmentTooltip = ({
  children,
  tooltipText,
  t
}: QuoteAttachmentTooltipProps): JSX.Element => (
  <WithPopup>
    {children}
    <Popup trigger="hover">
      <Container>
        <TooltipHeader text={t("Instructions")} />
        <Wrapper>
          <TextWrapper>
            <InfoText>{tooltipText}</InfoText>
          </TextWrapper>
        </Wrapper>
      </Container>
    </Popup>
  </WithPopup>
);
