/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LicenseDocumentRequestStates {
    Initial = 'Initial',
    RequestSent = 'RequestSent',
    RequestCompleted = 'RequestCompleted',
    Skip = 'Skip',
    RequestFailed = 'RequestFailed'
}

export function LicenseDocumentRequestStatesFromJSON(json: any): LicenseDocumentRequestStates {
    return LicenseDocumentRequestStatesFromJSONTyped(json, false);
}

export function LicenseDocumentRequestStatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseDocumentRequestStates {
    return json as LicenseDocumentRequestStates;
}

export function LicenseDocumentRequestStatesToJSON(value?: LicenseDocumentRequestStates | null): any {
    return value as any;
}

