/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleAccessRightDto,
    RoleAccessRightDtoFromJSON,
    RoleAccessRightDtoFromJSONTyped,
    RoleAccessRightDtoToJSON,
} from './RoleAccessRightDto';
import {
    RoleTypes,
    RoleTypesFromJSON,
    RoleTypesFromJSONTyped,
    RoleTypesToJSON,
} from './RoleTypes';

/**
 * 
 * @export
 * @interface ManageUserRoleDto
 */
export interface ManageUserRoleDto {
    /**
     * 
     * @type {number}
     * @memberof ManageUserRoleDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ManageUserRoleDto
     */
    userRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ManageUserRoleDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageUserRoleDto
     */
    abbreviation: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserRoleDto
     */
    description?: string | null;
    /**
     * 
     * @type {RoleTypes}
     * @memberof ManageUserRoleDto
     */
    roleTypeId: RoleTypes;
    /**
     * 
     * @type {boolean}
     * @memberof ManageUserRoleDto
     */
    defaultRole: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManageUserRoleDto
     */
    externallyAssigned?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ManageUserRoleDto
     */
    subRoleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<RoleAccessRightDto>}
     * @memberof ManageUserRoleDto
     */
    roleAccessRights: Array<RoleAccessRightDto>;
}

export function ManageUserRoleDtoFromJSON(json: any): ManageUserRoleDto {
    return ManageUserRoleDtoFromJSONTyped(json, false);
}

export function ManageUserRoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageUserRoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userRoleId': !exists(json, 'userRoleId') ? undefined : json['userRoleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'abbreviation': json['abbreviation'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'roleTypeId': RoleTypesFromJSON(json['roleTypeId']),
        'defaultRole': json['defaultRole'],
        'externallyAssigned': !exists(json, 'externallyAssigned') ? undefined : json['externallyAssigned'],
        'subRoleIds': !exists(json, 'subRoleIds') ? undefined : json['subRoleIds'],
        'roleAccessRights': ((json['roleAccessRights'] as Array<any>).map(RoleAccessRightDtoFromJSON)),
    };
}

export function ManageUserRoleDtoToJSON(value?: ManageUserRoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userRoleId': value.userRoleId,
        'name': value.name,
        'abbreviation': value.abbreviation,
        'description': value.description,
        'roleTypeId': RoleTypesToJSON(value.roleTypeId),
        'defaultRole': value.defaultRole,
        'externallyAssigned': value.externallyAssigned,
        'subRoleIds': value.subRoleIds,
        'roleAccessRights': ((value.roleAccessRights as Array<any>).map(RoleAccessRightDtoToJSON)),
    };
}

