import { Icon } from "@abb/abb-common-ux-react";
import { useMsal } from "@azure/msal-react";
import { commonActions } from "applications/common/actions/commonActions";
import { loginRequestScopes } from "configuration/authConfig";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { commonUXTheme } from "styles/commonUXVariables";
import { routes } from "utilities/routes";
import { Button } from "./Button";
import { PillLink } from "./PillLink";

// The whole description should fit comfortably within the dialog with height `totalHeight` on mobile
// to prevent overflowing text.

const totalHeight = "--total-height";
const spacing = "--spacing";
const titleRowHeight = "--title-row-height";

const breakpoint = commonUXTheme.breakpoints.lg;

const Banner = styled.div<{ isOpen: boolean }>`
  ${totalHeight}: max(35vh, 350px);
  ${titleRowHeight}: 60px;
  ${spacing}: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: ${(props) =>
    props.isOpen
      ? `calc(100% - var(${totalHeight}))`
      : `calc(100% - var(${titleRowHeight}) - 3 * var(${spacing}))`};
  z-index: ${(props) => props.theme.zIndices.dialog};
  height: var(${totalHeight});
  transition-duration: 500ms;
  pointer-events: none;

  @media screen and (max-width: ${breakpoint}) {
    ${titleRowHeight}: 90px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.xs};
`;

const SmallRedLine = styled.div`
  height: 2px;
  width: 20px;
  background-color: ${(props) => props.theme.colors.brandRed};
`;

const Gradient = styled.div`
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 55%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
`;

const BannerContent = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.whitePrimary};
  display: flex;
  flex-direction: column;
  gap: var(${spacing});
  padding: var(${spacing});
  max-width: ${(props) => props.theme.breakpoints.xl};
  left: 0;
  right: 0;
  margin-inline: auto;
  bottom: 0;
  height: calc(var(${totalHeight}) - var(${spacing}));
  pointer-events: auto;
`;

const Buttons = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.m};
`;

const Description = styled.p<{ isVisible: boolean }>`
  margin: 0;
  max-width: 600px;
  ${(props) => (props.isVisible ? "" : "display: none;")}
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.sizes.m};
  height: var(${titleRowHeight});

  @media screen and (max-width: ${breakpoint}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const CloseButton = styled.button<{ isVisible: boolean }>`
  ${(props) => (!props.isVisible ? "display: none;" : "display: flex;")}
  position: absolute;
  top: ${(props) => props.theme.sizes.s};
  right: ${(props) => props.theme.sizes.s};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  background: transparent;
  border: none;
`;

export const RegistrationBanner = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  // This is needed, so that text is not visible for short time when expanding
  // window width from narrow -> wide when banner is closed.
  const [isTextVisible, setIsTextVisible] = useState(false);

  const { instance } = useMsal();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(true);
      setIsTextVisible(true);
    }, 15 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Banner
      isOpen={isOpen}
      onTransitionEnd={() => {
        setIsTextVisible(isOpen);
      }}
    >
      <Gradient />
      <BannerContent>
        <CloseButton isVisible={isOpen}>
          <Icon
            name="abb/close"
            onClick={() => {
              dispatch(commonActions.disableRegistrationWall());
            }}
          />
        </CloseButton>
        <TitleRow>
          <TitleContainer>
            <SmallRedLine />
            <b>{t("Please sign in to use Relays-Online to its full potential")}</b>
          </TitleContainer>
          <Buttons>
            <PillLink
              to={`https://www.usermanagement.abb.com/account/Register?returnUrl=${window.location.origin}${routes.registrationCompleted}`}
              type="secondary"
            >
              {t("Register for free")}
            </PillLink>
            <Button
              buttonType="primary"
              onClick={() => {
                instance.loginRedirect({
                  scopes: loginRequestScopes,
                  prompt: "select_account"
                });
              }}
              text={t("Sign in")}
            />
          </Buttons>
        </TitleRow>
        <Description isVisible={isTextVisible}>
          {t(
            "You are not logged in to Relays-Online. You will see a limited number of functions and content. Please log in to use Relays-Online. If you don't have an account yet, you can create one for free from the link."
          )}
        </Description>
      </BannerContent>
    </Banner>
  );
};
