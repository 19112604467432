/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProductsNamesDto,
    ProductsNamesDtoFromJSON,
    ProductsNamesDtoToJSON,
    ResultComparisonVm,
    ResultComparisonVmFromJSON,
    ResultComparisonVmToJSON,
} from '../models';

export interface ApiProductComparatorCompareProductsGetRequest {
    productVersionIdList?: Array<number>;
    showAllFeature?: boolean;
}

export interface ApiProductComparatorProductsNamesGetRequest {
    lastVersionOnly?: boolean;
}

/**
 * 
 */
export class ProductComparatorApi extends runtime.BaseAPI {

    /**
     */
    async apiProductComparatorCompareProductsGetRaw(requestParameters: ApiProductComparatorCompareProductsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResultComparisonVm>> {
        const queryParameters: any = {};

        if (requestParameters.productVersionIdList) {
            queryParameters['ProductVersionIdList'] = requestParameters.productVersionIdList;
        }

        if (requestParameters.showAllFeature !== undefined) {
            queryParameters['ShowAllFeature'] = requestParameters.showAllFeature;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductComparator/compare-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultComparisonVmFromJSON(jsonValue));
    }

    /**
     */
    async apiProductComparatorCompareProductsGet(requestParameters: ApiProductComparatorCompareProductsGetRequest, initOverrides?: RequestInit): Promise<ResultComparisonVm> {
        const response = await this.apiProductComparatorCompareProductsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductComparatorProductsNamesGetRaw(requestParameters: ApiProductComparatorProductsNamesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProductsNamesDto>>> {
        const queryParameters: any = {};

        if (requestParameters.lastVersionOnly !== undefined) {
            queryParameters['LastVersionOnly'] = requestParameters.lastVersionOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ProductComparator/products-names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductsNamesDtoFromJSON));
    }

    /**
     */
    async apiProductComparatorProductsNamesGet(requestParameters: ApiProductComparatorProductsNamesGetRequest, initOverrides?: RequestInit): Promise<Array<ProductsNamesDto>> {
        const response = await this.apiProductComparatorProductsNamesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
