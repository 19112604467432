import styled from "styled-components";
import { Grid } from "../framework/components/grid/Grid";

export const DetailGrid = styled(Grid)`
  .ag-row-group-expanded {
    border: none !important;
  }
  .ag-row-group-expanded,
  .ag-row-group-contracted {
    .ag-icon {
      padding-top: 10px !important;
    }
  }

  .ag-cell {
    line-height: 25px !important;
  }

  .grid-hide-header {
    height: 0;
    border: none !important;
  }

  .grid-detail-row {
    span {
      line-height: 25px !important;
    }
  }

  .grid-detail-value {
    font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  }

  .ag-details-row {
    padding: 0 0 20px 170px;
    .ag-cell {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
    }
    .ag-row {
      background-color: white !important;
      border: none;
    }
    .ag-cell-wrapper {
      align-items: flex-start;
    }
    .ag-cell-wrap-text {
      word-break: break-word !important;
    }
  }

  .ag-details-mod-sales-row {
    .ag-cell.ag-mod-sales-cell {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
  }

  @media print {
    .ag-details-row .ag-root-wrapper-body,
    .ag-details-row .ag-root,
    .ag-details-row .ag-body-viewport,
    .ag-details-row .ag-center-cols-viewport,
    .ag-details-row .ag-center-cos-container {
      display: flex !important;
    }
  }
`;
