import { Action } from "redux";
import moment from "moment";
import { PayloadAction } from "./payloadAction";

export interface AsyncRequest<TRequestPayload = undefined> extends PayloadAction<TRequestPayload> {
  startTime: string;
}

export function request<TRequestPayload = undefined>(
  type: string,
  payload: TRequestPayload
): AsyncRequest<TRequestPayload> {
  return {
    type: type,
    startTime: moment().format(),
    payload: payload
  };
}

export interface RequestCompleted<TResponsePayload = undefined>
  extends PayloadAction<TResponsePayload> {
  endTime: string;
}

export function completeRequest<TResponsePayload = undefined>(
  type: string,
  payload: TResponsePayload
): RequestCompleted<TResponsePayload> {
  return {
    type: type,
    payload: payload,
    endTime: moment().format()
  };
}

export interface RequestFailed<TRequestPayload = undefined> extends Action {
  endTime: string;
  error: string;
  requestPayload: TRequestPayload;
}

export function failRequest<TRequestPayload = undefined>(
  type: string,
  error: string,
  requestPayload: TRequestPayload
): RequestFailed<TRequestPayload> {
  return {
    type: type,
    error: error,
    endTime: moment().format(),
    requestPayload: requestPayload
  };
}
