import React, { forwardRef, useImperativeHandle } from "react";
import { ITooltipParams } from "ag-grid-community";
import { unescape } from "lodash-es";

export const GridTooltip = forwardRef((props: ITooltipParams, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ["custom-tooltip"];
      }
    };
  });

  let tooltipString = props.value;

  if (typeof props.value === "number") {
    tooltipString = props.value.toString();
  }

  if (tooltipString && typeof tooltipString === "string" && tooltipString !== " ") {
    const unescapedString = unescape(tooltipString);
    return (
      <div className="custom-tooltip">
        <div>
          <p>
            <span>{unescapedString}</span>
          </p>
        </div>
      </div>
    );
  }
  return null;
});

GridTooltip.displayName = "GridTooltip";
