/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTrainingNodeCommand
 */
export interface UpdateTrainingNodeCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainingNodeCommand
     */
    nodeId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainingNodeCommand
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTrainingNodeCommand
     */
    parentNodeId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateTrainingNodeCommand
     */
    order?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTrainingNodeCommand
     */
    isMoveToRoot?: boolean;
}

export function UpdateTrainingNodeCommandFromJSON(json: any): UpdateTrainingNodeCommand {
    return UpdateTrainingNodeCommandFromJSONTyped(json, false);
}

export function UpdateTrainingNodeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTrainingNodeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nodeId': json['nodeId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parentNodeId': !exists(json, 'parentNodeId') ? undefined : json['parentNodeId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'isMoveToRoot': !exists(json, 'isMoveToRoot') ? undefined : json['isMoveToRoot'],
    };
}

export function UpdateTrainingNodeCommandToJSON(value?: UpdateTrainingNodeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nodeId': value.nodeId,
        'name': value.name,
        'parentNodeId': value.parentNodeId,
        'order': value.order,
        'isMoveToRoot': value.isMoveToRoot,
    };
}

