/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TechDocSearchResponse,
    TechDocSearchResponseFromJSON,
    TechDocSearchResponseToJSON,
} from '../models';

export interface ApiTechDocSearchGetRequest {
    query?: string;
    page?: number;
    limit?: number;
    showLatest?: boolean;
}

/**
 * 
 */
export class TechDocApi extends runtime.BaseAPI {

    /**
     */
    async apiTechDocSearchGetRaw(requestParameters: ApiTechDocSearchGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechDocSearchResponse>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['Limit'] = requestParameters.limit;
        }

        if (requestParameters.showLatest !== undefined) {
            queryParameters['ShowLatest'] = requestParameters.showLatest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/TechDoc/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechDocSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiTechDocSearchGet(requestParameters: ApiTechDocSearchGetRequest, initOverrides?: RequestInit): Promise<TechDocSearchResponse> {
        const response = await this.apiTechDocSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
