import { ShoppingCartDto } from "api/models/ShoppingCartDto";
import { ShoppingCartTabs } from "enums/shoppingCartTabs";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dispatch } from "redux";
import styled, { useTheme } from "styled-components";
import { AddressContainer } from "./orderDetails/AddressContainer";
import { OrderDetails } from "./orderDetails/OrderDetails";
import { ShipmentDetails } from "./orderDetails/ShipmentDetails";
import { shoppingCartSagas } from "applications/common/sagas/shoppingCartSagas";
import { getCountries } from "applications/accessRequests/reducers/accessRequestsReducer";
import { useSelector } from "react-redux";
import { accessRequestsSagas } from "applications/accessRequests/sagas/accessRequestsSagas";
import { ShoppingCartForm } from "../containers/ShoppingCartView";
import { FormikProps } from "formik";
import { ContentContainer } from "./orderDetails/ContentContainer";
import { InformationFormDialog, InformationFormKeys } from "./orderDetails/InformationFormDialog";
import { setNestedTouched } from "utilities/formikUtils";
import { ProFormaDialog } from "./orderDetails/ProFormaDialog";
import { ExportInfo } from "./orderDetails/ExportInfo";
import {
  CompanyPartnerInfo,
  CurrentUserDto,
  PreviousOrderSuggestion,
  SalesChannelDto,
  ShoppingCartAttachmentTypes,
  ShoppingCartStateTypes
} from "api";
import { Input } from "@abb/abb-common-ux-react";
import { InputLabel } from "framework/components/InputLabel";
import { LabelWithText } from "framework/components/LabelWithText";
import { isInEu } from "../helpers";
import { shoppingCartCompanySagas } from "../sagas/shoppingCartCompanySagas";
import { OrderSuggestionOptions } from "enums/orderSuggestionOptions";
import {
  getCompanyPartnersInfo,
  getPreviousOrderSuggestion
} from "applications/common/reducers/shoppingCartReducer";
import QuoteDetailsInfo from "applications/manage/manageQuotes/components/QuoteDetailsInfo";
import PriceInfo from "applications/shoppingCart/components/PriceInfo";

interface SideSectionProps {
  shoppingCart: ShoppingCartDto | undefined;
  activeTab: ShoppingCartTabs;
  userInformation: CurrentUserDto | undefined | null;
  t: TFunction;
  dispatch: Dispatch;
  formik: FormikProps<ShoppingCartForm>;
  salesChannels: SalesChannelDto[] | undefined;
  pgCodes: string[] | undefined;
}

export const InformationContainer = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.sizes.sm};
`;

export const InformationGridContainer = styled.div`
  display: grid;
  gap: ${(props) => props.theme.sizes.sm};
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
`;

const deliveryAddressRequiredFields: InformationFormKeys[] = [
  "name",
  "streetAddress",
  "city",
  "country",
  "contactPerson",
  "contactPersonEmail",
  "contactPersonTelephone"
];

const billingAddressRequiredFields: InformationFormKeys[] = ["name", "city", "country"];

export const OrderDetailsSection = ({
  shoppingCart,
  activeTab,
  t,
  userInformation,
  dispatch,
  formik,
  salesChannels,
  pgCodes
}: SideSectionProps): JSX.Element => {
  const countries = useSelector(getCountries);
  const previousOrderSuggestion = useSelector(getPreviousOrderSuggestion);
  const companyPartnersInfo = useSelector(getCompanyPartnersInfo);

  const selectedCompanyIdRef = useRef<undefined | string>();
  const [isDeliveryAddressDialogOpen, setIsDeliveryAddressDialogOpen] = useState(false);
  const [isBillingAddressDialogOpen, setIsBillingAddressDialogOpen] = useState(false);
  const [isConsigneeDialogOpen, setIsConsigneeDialogOpen] = useState(false);
  const [isProFormaDialogOpen, setIsProFormaDialogOpen] = useState(false);
  const [deliveryAddresses, setDeliveryAddresses] = useState<any[] | undefined | null>(null);
  const [billingAddresses, setBillingAddresses] = useState<any[] | undefined | null>(null);
  const [endCustomers, setEndCustomers] = useState<any[] | undefined | null>(null);
  const [consignees, setConsignees] = useState<any[] | undefined | null>(null);

  const selectedCompany = useMemo(
    () => shoppingCart?.company ?? userInformation?.selectedCompany,
    [shoppingCart, userInformation]
  );

  const isReadOnly = useMemo(
    () =>
      activeTab === ShoppingCartTabs.ConfirmOrder || activeTab === ShoppingCartTabs.OrderInProcess,
    [activeTab]
  );

  const isMaintainedCustomer = useMemo(
    () =>
      selectedCompany?.isDeliverITRelevant &&
      (selectedCompany?.isInternal || selectedCompany?.isMaintainedCustomer),
    [selectedCompany]
  );

  const setCompanyPartnerAddresses = useCallback(
    (addresses: CompanyPartnerInfo | undefined, addressType: OrderSuggestionOptions) => {
      if (addresses?.defaultAddresses && addresses?.defaultAddresses?.length > 0) {
        switch (addressType) {
          case OrderSuggestionOptions.DeliveryAddress:
            setDeliveryAddresses(addresses?.defaultAddresses);
            break;
          case OrderSuggestionOptions.BillingAddress:
            setBillingAddresses(addresses?.defaultAddresses);
            break;
          case OrderSuggestionOptions.Consignee:
            setConsignees(addresses?.defaultAddresses);
            break;
        }
        if (addresses?.defaultAddresses?.length == 1) {
          if (!formik.values[addressType]) {
            const address = {
              ...addresses?.defaultAddresses[0],
              id: undefined
            };
            formik.setFieldValue(addressType, address);
          }
        }
      } else {
        switch (addressType) {
          case OrderSuggestionOptions.DeliveryAddress:
            setDeliveryAddresses(addresses?.companyPartnerAddresses);
            break;
          case OrderSuggestionOptions.BillingAddress:
            setBillingAddresses(addresses?.companyPartnerAddresses);
            break;
          case OrderSuggestionOptions.Consignee:
            setConsignees(addresses?.companyPartnerAddresses);
            break;
        }
      }
    },
    [formik, setDeliveryAddresses, setBillingAddresses, setConsignees]
  );

  useEffect(() => {
    if (!countries) {
      dispatch(accessRequestsSagas.getCountries.createAction({}));
    }
  }, [dispatch, countries]);

  useEffect(() => {
    if (activeTab == ShoppingCartTabs.OrderDetails && selectedCompany) {
      if (!isMaintainedCustomer) {
        setDeliveryAddresses(previousOrderSuggestion?.deliveryAddresses);
        setBillingAddresses(previousOrderSuggestion?.billingAddresses);
        setEndCustomers(previousOrderSuggestion?.endCustomers);
        setConsignees(previousOrderSuggestion?.consignees);
      } else {
        setCompanyPartnerAddresses(
          companyPartnersInfo?.shippingAddresses,
          OrderSuggestionOptions.DeliveryAddress
        );
        setCompanyPartnerAddresses(
          companyPartnersInfo?.billingAddresses,
          OrderSuggestionOptions.BillingAddress
        );
        setCompanyPartnerAddresses(
          companyPartnersInfo?.consignees,
          OrderSuggestionOptions.Consignee
        );
      }
    }
    // eslint-disable-next-line
  }, [activeTab, selectedCompany, previousOrderSuggestion, companyPartnersInfo]);

  useEffect(() => {
    if (
      activeTab == ShoppingCartTabs.OrderDetails &&
      selectedCompany?.id &&
      selectedCompanyIdRef.current !== selectedCompany.id
    ) {
      selectedCompanyIdRef.current = selectedCompany.id;
      dispatch(
        shoppingCartCompanySagas.getCompanyPgCodes.createAction({
          id: selectedCompany.id
        })
      );
      dispatch(
        shoppingCartCompanySagas.getCompanyTermsOfPayment.createAction({
          id: selectedCompany.id
        })
      );
    }
  }, [activeTab, dispatch, isReadOnly, selectedCompany]);

  const uploadAttachment = (uploadedFile: Blob, attachmentType: ShoppingCartAttachmentTypes) => {
    if (shoppingCart?.guid) {
      dispatch(
        shoppingCartSagas.uploadAttachment.createAction({
          guid: shoppingCart.guid,
          uploadedFile,
          type: attachmentType
        })
      );
    }
  };

  const deleteAttachment = (attachmentIdentifier: string) => {
    if (shoppingCart?.guid) {
      dispatch(
        shoppingCartSagas.deleteAttachment.createAction({
          guid: shoppingCart.guid,
          attachmentId: attachmentIdentifier
        })
      );
    }
  };

  const setSuggestion = (option: OrderSuggestionOptions, previousSuggestionId: number) => {
    let previousSuggestions: PreviousOrderSuggestion[] | undefined | null;

    switch (option) {
      case OrderSuggestionOptions.BillingAddress:
        previousSuggestions = billingAddresses;
        break;
      case OrderSuggestionOptions.DeliveryAddress:
        previousSuggestions = deliveryAddresses;
        break;
      case OrderSuggestionOptions.EndCustomer:
        previousSuggestions = endCustomers;
        break;
      case OrderSuggestionOptions.Consignee:
        previousSuggestions = consignees;
        break;
    }

    const suggestion = previousSuggestions?.find((a) => a.id == previousSuggestionId);

    if (suggestion) {
      const address = {
        ...suggestion,
        id: undefined
      };
      formik.setFieldValue(option, address);
    }
  };

  const deliveryCountry = formik.values.deliveryAddress?.countryCode;

  const isEuCountry = useMemo(
    () => isInEu(countries, deliveryCountry),
    [deliveryCountry, countries]
  );

  const hasAllAddressInfo = (address: any) => {
    if (!address || typeof address !== "object") {
      return false;
    }

    return Object.values(address).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  };

  const theme = useTheme();

  return (
    <div style={{ flex: "0 0 auto", width: "100%" }}>
      <InformationContainer>
        <InformationGridContainer>
          <InformationFormDialog
            t={t}
            isOpen={isBillingAddressDialogOpen}
            onDialogClose={() => {
              setNestedTouched(formik, "billingAddress");
              setIsBillingAddressDialogOpen(false);
            }}
            title={t("Add billing address")}
            values={formik.values.billingAddress}
            countries={countries}
            formik={formik}
            parentObjName={"billingAddress"}
            requiredFields={billingAddressRequiredFields}
            suggestions={billingAddresses}
            onSetSuggestion={(suggestionId: number) => {
              setSuggestion(OrderSuggestionOptions.BillingAddress, suggestionId);
            }}
          />
          <InformationFormDialog
            t={t}
            isOpen={isDeliveryAddressDialogOpen}
            onDialogClose={() => {
              setNestedTouched(formik, "deliveryAddress");
              setIsDeliveryAddressDialogOpen(false);
            }}
            title={t("Add delivery address")}
            values={formik.values.deliveryAddress}
            countries={countries}
            formik={formik}
            parentObjName={"deliveryAddress"}
            requiredFields={deliveryAddressRequiredFields}
            suggestions={deliveryAddresses}
            onSetSuggestion={(suggestionId: number) => {
              setSuggestion(OrderSuggestionOptions.DeliveryAddress, suggestionId);
            }}
          />
          <InformationFormDialog
            t={t}
            isOpen={isConsigneeDialogOpen}
            onDialogClose={() => {
              setNestedTouched(formik, "consignee");
              setIsConsigneeDialogOpen(false);
            }}
            title={t("Add consignee address")}
            values={formik.values.consignee}
            countries={countries}
            formik={formik}
            parentObjName={"consignee"}
            requiredFields={undefined}
            suggestions={consignees}
            onSetSuggestion={(suggestionId: number) => {
              setSuggestion(OrderSuggestionOptions.Consignee, suggestionId);
            }}
          />
          <ProFormaDialog
            t={t}
            isOpen={isProFormaDialogOpen}
            onDialogClose={() => setIsProFormaDialogOpen(false)}
            formik={formik}
            currency={shoppingCart?.priceListCurrency}
            isReadOnly={isReadOnly}
          />
          <OrderDetails
            t={t}
            selectedCompany={shoppingCart?.company ?? userInformation?.selectedCompany}
            shoppingCartGuid={shoppingCart?.guid}
            formik={formik}
            isReadOnly={isReadOnly}
            countries={countries}
            uploadAttachment={uploadAttachment}
            deleteAttachment={deleteAttachment}
            salesChannels={salesChannels}
            pgCodes={pgCodes}
            isQuote={shoppingCart?.stateId === ShoppingCartStateTypes.Quote}
            suggestions={endCustomers}
            onSetSuggestion={(suggestionId: number) => {
              setSuggestion(OrderSuggestionOptions.EndCustomer, suggestionId);
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: theme.sizes.sm
            }}
          >
            <ContentContainer title={t("Delivery address")}>
              <AddressContainer
                setIsDialogOpen={() => setIsDeliveryAddressDialogOpen(true)}
                t={t}
                values={formik.values.deliveryAddress}
                isReadOnly={isReadOnly}
                objKeyName={"deliveryAddress"}
                buttonText={"Add delivery address"}
              />
              {isEuCountry ? (
                <>
                  {isReadOnly ? (
                    <LabelWithText text={formik.values.vatNumber} label={t("VAT number")} />
                  ) : (
                    <>
                      <InputLabel label={t("VAT number")} isRequired={true} />
                      <Input
                        inputAttributes={{
                          name: "vatNumber",
                          onChange: formik.handleChange
                        }}
                        dataType="text"
                        value={formik.values.vatNumber}
                        validationResult={{
                          valid:
                            !!formik.errors.vatNumber && !!formik.touched.vatNumber ? false : true,
                          text: formik.errors.vatNumber
                        }}
                        instantValidation={true}
                        showValidationBarWhenInvalid={true}
                        onLostFocus={formik.handleBlur}
                      />
                    </>
                  )}
                </>
              ) : null}
            </ContentContainer>
            <ContentContainer title={t("Billing address")}>
              <AddressContainer
                setIsDialogOpen={() => setIsBillingAddressDialogOpen(true)}
                t={t}
                values={formik.values.billingAddress}
                isReadOnly={
                  isReadOnly ||
                  ((isMaintainedCustomer && hasAllAddressInfo(formik.values.billingAddress)) ??
                    false)
                }
                objKeyName={"billingAddress"}
                buttonText={"Add billing address"}
              />
            </ContentContainer>
            <ContentContainer title={t("Consignee")}>
              <AddressContainer
                setIsDialogOpen={() => setIsConsigneeDialogOpen(true)}
                t={t}
                values={formik.values.consignee}
                isReadOnly={isReadOnly}
                objKeyName={"consignee"}
                buttonText={"Add consignee"}
              />
            </ContentContainer>
            <ContentContainer title={t("Terms of payment")} flex={0.5}>
              <p>{formik.values.termsOfPaymentDescription}</p>
            </ContentContainer>
            {formik.values.deliveryAddress?.country &&
            !isInEu(countries, formik.values.deliveryAddress?.countryCode) ? (
              <ExportInfo
                t={t}
                formik={formik}
                onClick={() => setIsProFormaDialogOpen(true)}
                uploadAttachment={uploadAttachment}
                deleteAttachment={deleteAttachment}
                isReadOnly={isReadOnly}
              />
            ) : null}
          </div>
          <ShipmentDetails
            t={t}
            isReadOnly={isReadOnly}
            formik={formik}
            selectedCompany={userInformation?.selectedCompany}
            dispatch={dispatch}
          />
          {shoppingCart?.activeQuote && (
            <>
              <QuoteDetailsInfo
                shoppingCart={shoppingCart}
                quote={shoppingCart?.activeQuote}
                t={t}
                selectedCompany={undefined}
                formik={undefined}
                showVisibility={true}
              />
              <PriceInfo
                t={t}
                quote={shoppingCart?.activeQuote}
                currency={shoppingCart?.priceListCurrency}
                shoppingCart={shoppingCart}
                selectedCompany={shoppingCart?.company}
              />
            </>
          )}
        </InformationGridContainer>
      </InformationContainer>
    </div>
  );
};
