import {
  CompanyDto,
  ApiCompaniesGetRequest,
  ApiApplicationNotificationsGetRequest,
  ApiPricesManagedPriceListsGetRequest,
  ApplicationNotificationDto,
  RoleGroups,
  ApiShoppingCartsGetRequest,
  UserShoppingCartDto,
  ShoppingCartStateTypes,
  ManageQuoteDto,
  ApiShoppingCartsManageQuotesGetRequest,
  QuoteStateTypes,
  SoftwareSubscriptionsDto,
  ApiOrderSubscriptionsGetRequest,
  TeamDto,
  ApiTeamsGetRequest,
  TeamTypes,
  ApiTeamsMembersGetRequest,
  TeamMemberDto
} from "../api";
import { ActiveStatus } from "../enums/grid/activeStatus";
import { ApiUsersGetRequest } from "../api";
import { getDateTime } from "./dateUtils";
import { CompanyOverrideStatus } from "../enums/grid/companyOverrideStatus";
import { AccessRequestDto } from "../api/models/AccessRequestDto";
import { ApiAccessRequestsGetRequest } from "../api/apis/AccessRequestsApi";
import { CompanyAdminStatus } from "../enums/grid/companyAdminStatus";
import { GuestUserStatus } from "enums/grid/guestUserStatus";
import { AccessRequestStatusTypes } from "../api/models/AccessRequestStatusTypes";
import { SubscriptionRenewalStatus } from "enums/grid/subscriptionRenewalStatus";

export const formUserCriteriaFilters = (filterModel: {
  // Not sure how to do typesafe index type with nested value ("homeCompany.name") etc
  [key: string]: {
    filter: string;
    type: string;
    dateFrom?: string;
    dateTo?: string;
    values?: string[];
  };
}): Partial<ApiUsersGetRequest> => {
  const criteriaToForm: Partial<ApiUsersGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "firstName":
        criteriaToForm.criteriaFirstName = filterModel[filter].filter;
        break;
      case "lastName":
        criteriaToForm.criteriaLastName = filterModel[filter].filter;
        break;
      case "email":
        criteriaToForm.criteriaEmail = filterModel[filter].filter;
        break;
      case "homeCompany.name":
        criteriaToForm.criteriaUserCompanyName = filterModel[filter].filter;
        break;
      case "homeCompany.countryCode":
        criteriaToForm.criteriaCountryCode = filterModel[filter].filter;
        break;
      case "position":
        criteriaToForm.criteriaPosition = filterModel[filter]?.filter;
        break;
      case "active":
        criteriaToForm.criteriaActive = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === ActiveStatus.Active) !== -1) {
            criteriaToForm.criteriaActive.push(true);
          } else if (filters.findIndex((s) => s === ActiveStatus.InActive) !== -1) {
            criteriaToForm.criteriaActive.push(false);
          }
        }
        break;
      case "lastActive":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaLastActiveTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaLastActiveFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaLastActiveFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaLastActiveTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "isGuest":
        criteriaToForm.criteriaIsGuest = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === GuestUserStatus.IsGuestUser) !== -1) {
            criteriaToForm.criteriaIsGuest.push(true);
          } else if (filters.findIndex((s) => s === GuestUserStatus.IsNormalUser) !== -1) {
            criteriaToForm.criteriaIsGuest.push(false);
          }
        }
        break;
      case "isExternal":
        criteriaToForm.criteriaIsExternal = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === "true") !== -1) {
            criteriaToForm.criteriaIsExternal.push(true);
          } else if (filters.findIndex((s) => s === "false") !== -1) {
            criteriaToForm.criteriaIsExternal.push(false);
          }
        }
        break;
      case "isInternal":
        criteriaToForm.criteriaIsInternal = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === "true") !== -1) {
            criteriaToForm.criteriaIsInternal.push(true);
          } else if (filters.findIndex((s) => s === "false") !== -1) {
            criteriaToForm.criteriaIsInternal.push(false);
          }
        }
        break;
      case "userRoles":
        criteriaToForm.criteriaUserRoles = filterModel[filter].filter.split(",");
        break;
    }
  }
  return criteriaToForm;
};

export function formApplicationNotificationCriteriaFilters(filterModel: {
  [key in keyof ApplicationNotificationDto]: {
    filter: keyof ApplicationNotificationDto;
    filterType: string;
    type: string;
    dateFrom?: string;
    dateTo?: string;
    values?: string[];
  };
}): Partial<ApiApplicationNotificationsGetRequest> {
  const criteriaToForm: Partial<ApiApplicationNotificationsGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "header":
        criteriaToForm.criteriaHeader = filterModel[filter].filter;
        break;
      case "from":
        criteriaToForm.criteriaFrom = filterModel[filter].filter;
        break;
      case "company":
        criteriaToForm.criteriaCompany = filterModel[filter]?.filter;
        break;
      case "created":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaReceivedTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaReceivedFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaReceivedFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaReceivedTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
    }
  }
  return criteriaToForm;
}

export function formSubscriptionCriteriaFilters(filterModel: {
  [key in keyof SoftwareSubscriptionsDto]: {
    filter: keyof SoftwareSubscriptionsDto;
    filterType: string;
    type: string;
    dateFrom?: string;
    dateTo?: string;
    values?: string[];
  };
}): Partial<ApiOrderSubscriptionsGetRequest> {
  const criteriaToForm: Partial<ApiOrderSubscriptionsGetRequest> = {};

  for (const filter in filterModel) {
    switch (filter) {
      case "renewalPeriodStart":
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          criteriaToForm.criteriaCanRenew = [];
          if (filters?.some((s) => s === SubscriptionRenewalStatus.CanRenew)) {
            criteriaToForm.criteriaCanRenew?.push(true);
          }
          if (filters?.some((s) => s === SubscriptionRenewalStatus.CannotRenew)) {
            criteriaToForm.criteriaCanRenew?.push(false);
          }
        }
        break;
    }
  }

  return criteriaToForm;
}

export function formCompanyCriteriaFilters(filterModel: {
  [key in keyof CompanyDto]: {
    filter: keyof CompanyDto;
    filterType: string;
    type: string;
    dateFrom?: string;
    dateTo?: string;
    values?: string[];
  };
}): Partial<ApiCompaniesGetRequest> {
  const criteriaToForm: Partial<ApiCompaniesGetRequest> = {};

  for (const filter in filterModel) {
    switch (filter) {
      case "name":
        criteriaToForm.criteriaName = filterModel[filter].filter;
        break;
      case "countryCode":
        criteriaToForm.criteriaCountryCode = filterModel[filter]?.filter;
        break;
      case "addressCity":
        criteriaToForm.criteriaAddressCity = filterModel[filter]?.filter;
        break;
      case "addressStreet":
        criteriaToForm.criteriaAddressStreet = filterModel[filter]?.filter;
        break;
      case "globalCustomerNumber":
        criteriaToForm.criteriaGlobalCustomerNumber = filterModel[filter]?.filter;
        break;
      case "id":
        criteriaToForm.criteriaCustomerCode = filterModel[filter]?.filter;
        break;
      case "lastActive":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaLastActiveTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaLastActiveFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaLastActiveFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaLastActiveTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "accessAuditDueTo":
        if (filterModel[filter]?.values?.every((s) => s === "Late")) {
          criteriaToForm.criteriaHasLateAccessAudit = true;
        } else {
          criteriaToForm.criteriaHasLateAccessAudit = false;
        }
        break;

      case "overrideStatusActive":
        criteriaToForm.criteriaOverrideStatus = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === CompanyOverrideStatus.Set) !== -1) {
            criteriaToForm.criteriaOverrideStatus.push(true);
          }
          if (filters?.findIndex((s) => s === CompanyOverrideStatus.NotSet) !== -1) {
            criteriaToForm.criteriaOverrideStatus.push(false);
          }
        }
        break;
      case "active":
        criteriaToForm.criteriaStatus = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === ActiveStatus.Active) !== -1) {
            criteriaToForm.criteriaStatus.push(true);
          } else if (filters.findIndex((s) => s === ActiveStatus.InActive) !== -1) {
            criteriaToForm.criteriaStatus.push(false);
          }
        }
        break;
      case "hasCompanyAdmin":
        criteriaToForm.criteriaHasCompanyAdminStatus = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === CompanyAdminStatus.Has) !== -1) {
            criteriaToForm.criteriaHasCompanyAdminStatus.push(true);
          } else if (filters.findIndex((s) => s === CompanyAdminStatus.DoesntHave) !== -1) {
            criteriaToForm.criteriaHasCompanyAdminStatus.push(false);
          }
        }
        break;
    }
  }
  return criteriaToForm;
}

export function formAccessRequestCriteriaFilters(filterModel: {
  [key in keyof AccessRequestDto]: {
    filter: keyof AccessRequestDto;
    values?: string[];
    type: string;
    dateFrom?: string;
    dateTo?: string;
  };
}): Partial<ApiAccessRequestsGetRequest> {
  const criteriaToForm: Partial<ApiAccessRequestsGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "assignees":
        criteriaToForm.criteriaAssigneeEmail = filterModel[filter]?.filter;
        break;
      case "firstName":
        criteriaToForm.criteriaFirstName = filterModel[filter]?.filter;
        break;
      case "lastName":
        criteriaToForm.criteriaLastName = filterModel[filter]?.filter;
        break;
      case "email":
        criteriaToForm.criteriaEmail = filterModel[filter]?.filter;
        break;
      case "userCompanyName":
        criteriaToForm.criteriaUserCompanyName = filterModel[filter]?.filter;
        break;
      case "position":
        criteriaToForm.criteriaPosition = filterModel[filter]?.filter;
        break;
      case "comment":
        criteriaToForm.criteriaComment = filterModel[filter]?.filter;
        break;
      case "countryCode":
        criteriaToForm.criteriaCountryCode = filterModel[filter]?.filter;
        break;
      case "statusId":
        criteriaToForm.criteriaStatuses = filterModel[filter]?.values as AccessRequestStatusTypes[];
        break;
      case "created":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaCreatedTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaCreatedFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaCreatedFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaCreatedTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "roleGroup":
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values as RoleGroups[];
          criteriaToForm.criteriaRoleGroups = filters;
        }
    }
  }
  return criteriaToForm;
}

export function formAddUserCriteriaFilters(filterModel: {
  [key: string]: { filter: string; filterType: string; type: string };
}): Partial<ApiUsersGetRequest> {
  const criteriaToForm: Partial<ApiUsersGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "firstName":
        criteriaToForm.criteriaFirstName = filterModel[filter].filter;
        break;
      case "lastName":
        criteriaToForm.criteriaLastName = filterModel[filter].filter;
        break;
      case "email":
        criteriaToForm.criteriaEmail = filterModel[filter].filter;
        break;
    }
  }
  return criteriaToForm;
}

export function formPriceListsCriteriaFilters(filterModel: {
  [key: string]: {
    filter: string;
    filterType: string;
    type: string;
    values?: string[];
    dateFrom?: string;
    dateTo?: string;
  };
}): Partial<ApiPricesManagedPriceListsGetRequest> {
  const criteriaToForm: Partial<ApiPricesManagedPriceListsGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "priceListId":
        criteriaToForm.criteriaPriceListId = filterModel[filter].filter;
        break;
      case "priceListDisplayName":
        criteriaToForm.criteriaPriceListDisplayName = filterModel[filter].filter;
        break;
      case "countryCode":
        criteriaToForm.criteriaCountry = filterModel[filter].filter;
        break;
      case "multiplier":
        criteriaToForm.criteriaMultiplier = parseFloat(filterModel[filter].filter);
        break;
      case "company.name":
        criteriaToForm.criteriaCompanyName = filterModel[filter].filter;
        break;
      case "active":
        criteriaToForm.criteriaActive = [];
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values;
          if (filters?.findIndex((s) => s === ActiveStatus.Active) !== -1) {
            criteriaToForm.criteriaActive.push(true);
          } else if (filters.findIndex((s) => s === ActiveStatus.InActive) !== -1) {
            criteriaToForm.criteriaActive.push(false);
          }
        }
        break;
      case "validityDate":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaValidityDateTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaValidityDateFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaValidityDateFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaValidityDateTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
    }
  }
  return criteriaToForm;
}

export function formShoppingCartsCriteriaFilters(filterModel: {
  [key in keyof UserShoppingCartDto]: {
    filter: keyof UserShoppingCartDto;
    values?: string[];
    type: string;
    dateFrom?: string;
    dateTo?: string;
  };
}): Partial<ApiShoppingCartsGetRequest> {
  const criteriaToForm: Partial<ApiShoppingCartsGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "projectName":
        criteriaToForm.criteriaProjectName = filterModel[filter]?.filter;
        break;
      case "reference":
        criteriaToForm.criteriaReference = filterModel[filter]?.filter;
        break;
      case "created":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaCreatedTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaCreatedFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaCreatedFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaCreatedTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "stateId":
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values as ShoppingCartStateTypes[];
          criteriaToForm.criteriaStateIds = filters;
        }
    }
  }
  return criteriaToForm;
}

export function formManageQuotesCriteriaFilters(filterModel: {
  [key in keyof ManageQuoteDto]: {
    filter: keyof ManageQuoteDto;
    values?: string[];
    type: string;
    dateFrom?: string;
    dateTo?: string;
  };
}): Partial<ApiShoppingCartsManageQuotesGetRequest> {
  const criteriaToForm: Partial<ApiShoppingCartsManageQuotesGetRequest> = {};
  for (const filter in filterModel) {
    switch (filter) {
      case "reference":
        criteriaToForm.criteriaReference = filterModel[filter]?.filter;
        break;
      case "quoteRequestedDate":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaQuoteRequestedDateTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaQuoteRequestedDateFrom = getDateTime(
            filterModel[filter]?.dateFrom
          );
        } else {
          criteriaToForm.criteriaQuoteRequestedDateFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaQuoteRequestedDateTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "tenderValidityDate":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaQuoteValidityDateTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaQuoteValidityDateFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaQuoteValidityDateFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaQuoteValidityDateTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "expectedOrderDate":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaExpectedOrderDateTo = getDateTime(filterModel[filter]?.dateFrom);
        } else if (filterModel[filter]?.type === "greaterThan") {
          criteriaToForm.criteriaExpectedOrderDateFrom = getDateTime(filterModel[filter]?.dateFrom);
        } else {
          criteriaToForm.criteriaExpectedOrderDateFrom = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "startOf"
          );
          criteriaToForm.criteriaExpectedOrderDateTo = getDateTime(
            filterModel[filter]?.dateFrom,
            undefined,
            "endOf"
          );
        }
        break;
      case "stateId":
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values as QuoteStateTypes[];
          criteriaToForm.criteriaStateIds = filters;
        }
        break;
      case "requestedBy":
        criteriaToForm.criteriaRequestedBy = filterModel[filter]?.filter;
        break;
      case "quotedByName":
        criteriaToForm.criteriaQuotedBy = filterModel[filter]?.filter;
        break;
      case "quoteReceiverEmail":
        criteriaToForm.criteriaQuoteReceiver = filterModel[filter]?.filter;
        break;
      case "companyDisplayName":
        criteriaToForm.criteriaCompanyDisplayName = filterModel[filter]?.filter;
        break;
      case "totalDisplayPrice":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaTotalPriceTo = filterModel[filter]?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaTotalPriceFrom = filterModel[filter]?.filter as unknown as number;
        }
        break;
      case "totalDisplayQuotedPrice":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaTotalQuotedPriceTo = filterModel[filter]
            ?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaTotalQuotedPriceFrom = filterModel[filter]
            ?.filter as unknown as number;
        }
        break;
      case "totalDisplaySalesPrice":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaTotalSalesPriceTo = filterModel[filter]
            ?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaTotalSalesPriceFrom = filterModel[filter]
            ?.filter as unknown as number;
        }
        break;
      case "discountPercentage":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaTotalDiscountPercentageTo = filterModel[filter]
            ?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaTotalDiscountPercentageFrom = filterModel[filter]
            ?.filter as unknown as number;
        }
        break;
      case "markupPercentage":
        if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaMarkupPercentageTo = filterModel[filter]
            ?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaMarkupPercentageFrom = filterModel[filter]
            ?.filter as unknown as number;
        }
        break;
      case "totalQuantity":
        if (filterModel[filter]?.type === "equals") {
          criteriaToForm.criteriaTotalQtyFrom = filterModel[filter]?.filter as unknown as number;
          criteriaToForm.criteriaTotalQtyTo = filterModel[filter]?.filter as unknown as number;
        } else if (filterModel[filter]?.type === "lessThan") {
          criteriaToForm.criteriaTotalQtyTo = filterModel[filter]?.filter as unknown as number;
        } else {
          criteriaToForm.criteriaTotalQtyFrom = filterModel[filter]?.filter as unknown as number;
        }
        break;
      case "projectName":
        criteriaToForm.criteriaProjectName = filterModel[filter]?.filter;
        break;
      case "accountCustomer":
        criteriaToForm.criteriaAccountCustomer = filterModel[filter]?.filter;
        break;
      case "endCustomer":
        criteriaToForm.criteriaEndCustomer = filterModel[filter]?.filter;
        break;
      case "endCustomerCountry":
        criteriaToForm.criteriaEndCustomerCountry = filterModel[filter]?.filter;
        break;
      case "soldToCountry":
        criteriaToForm.criteriaSoldToCountry = filterModel[filter]?.filter;
        break;
      case "quotedToChannel":
        criteriaToForm.criteriaQuotedToChannel = filterModel[filter]?.filter;
        break;
      case "sfdcOpportunityReference":
        criteriaToForm.criteriaSfdcOpportunityReference = filterModel[filter]?.filter;
        break;
      default:
        console.warn("Unimplemented filter in ManageQuotes criteria: " + filter);
    }
  }
  return criteriaToForm;
}

export function formTeamsCriteriaFilters(filterModel: {
  [key in keyof TeamDto]: {
    filter: keyof TeamDto;
    filterType: string;
    values?: string[];
  };
}): Partial<ApiTeamsGetRequest> {
  const criteriaToForm: Partial<ApiTeamsGetRequest> = {};

  for (const filter in filterModel) {
    switch (filter) {
      case "name":
        criteriaToForm.criteriaName = filterModel[filter]?.filter;
        break;
      case "description":
        criteriaToForm.criteriaDescription = filterModel[filter]?.filter;
        break;
      case "type":
        if (filterModel[filter]?.values !== undefined) {
          const filters = filterModel[filter]?.values as TeamTypes[];
          criteriaToForm.criteriaTypes = filters;
        }
        break;
    }
  }
  return criteriaToForm;
}