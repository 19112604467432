import React from "react";
import { Stack } from "@mui/system";
import { ClickableLink, LinkIcon, SmallText } from "./Containers";
import { useTranslation } from "react-i18next";
import { SelectorProductDto } from "api";

interface productLinksProps {
  product?: SelectorProductDto;
}

const ProductLinks = ({ product }: productLinksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink discreet target="_blank" href={product?.pageUrl ?? ""}>
          <SmallText> {t("Go to product page")}</SmallText>
        </ClickableLink>
      </Stack>
      <Stack direction={"row"}>
        <LinkIcon name="abb/caret-right" />
        <ClickableLink discreet target="_blank" href={product?.referenceCaseUrl ?? ""}>
          <SmallText> {t("Reference cases")}</SmallText>
        </ClickableLink>
      </Stack>
    </Stack>
  );
};

export default ProductLinks;
