/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShoppingCartUpdatedDto
 */
export interface ShoppingCartUpdatedDto {
    /**
     * 
     * @type {string}
     * @memberof ShoppingCartUpdatedDto
     */
    guid: string;
    /**
     * 
     * @type {Date}
     * @memberof ShoppingCartUpdatedDto
     */
    lastModified: Date;
}

export function ShoppingCartUpdatedDtoFromJSON(json: any): ShoppingCartUpdatedDto {
    return ShoppingCartUpdatedDtoFromJSONTyped(json, false);
}

export function ShoppingCartUpdatedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartUpdatedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function ShoppingCartUpdatedDtoToJSON(value?: ShoppingCartUpdatedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'lastModified': (value.lastModified.toISOString()),
    };
}

