/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GlobalSettingDto,
    GlobalSettingDtoFromJSON,
    GlobalSettingDtoToJSON,
    UpdateGlobalSettingsCommand,
    UpdateGlobalSettingsCommandFromJSON,
    UpdateGlobalSettingsCommandToJSON,
} from '../models';

export interface ApiGlobalSettingGetRequest {
    query?: object;
}

export interface ApiGlobalSettingPutRequest {
    updateGlobalSettingsCommand?: UpdateGlobalSettingsCommand;
}

/**
 * 
 */
export class GlobalSettingApi extends runtime.BaseAPI {

    /**
     */
    async apiGlobalSettingGetRaw(requestParameters: ApiGlobalSettingGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<GlobalSettingDto>>> {
        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/GlobalSetting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GlobalSettingDtoFromJSON));
    }

    /**
     */
    async apiGlobalSettingGet(requestParameters: ApiGlobalSettingGetRequest, initOverrides?: RequestInit): Promise<Array<GlobalSettingDto>> {
        const response = await this.apiGlobalSettingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGlobalSettingPutRaw(requestParameters: ApiGlobalSettingPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/GlobalSetting`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGlobalSettingsCommandToJSON(requestParameters.updateGlobalSettingsCommand),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiGlobalSettingPut(requestParameters: ApiGlobalSettingPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiGlobalSettingPutRaw(requestParameters, initOverrides);
    }

}
