import { VisibleSupportPersonDto, SupportPersonProfilePictureDto } from "api";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { Action } from "redux";
import { supportSagas } from "../sagas/supportSagas";
import { produce } from "immer";
import { AppState } from "setup/appRootReducer";

interface Requests {
  getSupportPersons: RequestState<VisibleSupportPersonDto[]>;
  getSupportPersonProfilePictures: RequestState<SupportPersonProfilePictureDto[]>;
  createSupportRequest: RequestState;
  setSupportPersonVisibility: RequestState;
}

export interface SupportViewState {
  requests: Requests;
  supportPersons: VisibleSupportPersonDto[];
  supportPersonProfilePictures: SupportPersonProfilePictureDto[];
}

const defaultState: SupportViewState = {
  requests: {
    getSupportPersons: createInitialRequest(),
    getSupportPersonProfilePictures: createInitialRequest(),
    createSupportRequest: createInitialRequest(),
    setSupportPersonVisibility: createInitialRequest()
  },
  supportPersons: [],
  supportPersonProfilePictures: []
};

export function supportReducer(
  state: SupportViewState = defaultState,
  action: Action
): SupportViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: supportSagas.getSupportPersons.actionTypes,
      key: "getSupportPersons"
    },
    {
      actionTypes: supportSagas.getSupportPersonProfilePictures.actionTypes,
      key: "getSupportPersonProfilePictures"
    },
    {
      actionTypes: supportSagas.createSupportRequest.actionTypes,
      key: "createSupportRequest"
    },
    {
      actionTypes: supportSagas.setSupportPersonVisibility.actionTypes,
      key: "setSupportPersonVisibility"
    }
  ]);

  if (supportSagas.getSupportPersons.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.supportPersons = action.payload;
    });
  }
  if (supportSagas.getSupportPersonProfilePictures.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.supportPersonProfilePictures = action.payload;
    });
  }

  return state;
}

export const getSupportPersons = (state: AppState) => state.support.supportPersons;
export const getSupportPersonProfilePictures = (state: AppState) =>
  state.support.supportPersonProfilePictures;
export const getSetSupportPersonVisibilityRequest = (state: AppState) =>
  state.support.requests.setSupportPersonVisibility;
export const getCreateSupportRequestRequest = (state: AppState) =>
  state.support.requests.createSupportRequest;
export const getSupportPersonsRequest = (state: AppState) =>
  state.support.requests.getSupportPersons;
