/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CountryContactPersonRoleTypes,
    CountryContactPersonRoleTypesFromJSON,
    CountryContactPersonRoleTypesFromJSONTyped,
    CountryContactPersonRoleTypesToJSON,
} from './CountryContactPersonRoleTypes';
import {
    CountryDto,
    CountryDtoFromJSON,
    CountryDtoFromJSONTyped,
    CountryDtoToJSON,
} from './CountryDto';
import {
    PartiallyActiveState,
    PartiallyActiveStateFromJSON,
    PartiallyActiveStateFromJSONTyped,
    PartiallyActiveStateToJSON,
} from './PartiallyActiveState';

/**
 * 
 * @export
 * @interface ManageUserCountryRolesDto
 */
export interface ManageUserCountryRolesDto {
    /**
     * 
     * @type {CountryDto}
     * @memberof ManageUserCountryRolesDto
     */
    country: CountryDto;
    /**
     * 
     * @type {Array<CountryContactPersonRoleTypes>}
     * @memberof ManageUserCountryRolesDto
     */
    roleNames: Array<CountryContactPersonRoleTypes>;
    /**
     * 
     * @type {PartiallyActiveState}
     * @memberof ManageUserCountryRolesDto
     */
    isActive: PartiallyActiveState;
}

export function ManageUserCountryRolesDtoFromJSON(json: any): ManageUserCountryRolesDto {
    return ManageUserCountryRolesDtoFromJSONTyped(json, false);
}

export function ManageUserCountryRolesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageUserCountryRolesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'country': CountryDtoFromJSON(json['country']),
        'roleNames': ((json['roleNames'] as Array<any>).map(CountryContactPersonRoleTypesFromJSON)),
        'isActive': PartiallyActiveStateFromJSON(json['isActive']),
    };
}

export function ManageUserCountryRolesDtoToJSON(value?: ManageUserCountryRolesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country': CountryDtoToJSON(value.country),
        'roleNames': ((value.roleNames as Array<any>).map(CountryContactPersonRoleTypesToJSON)),
        'isActive': PartiallyActiveStateToJSON(value.isActive),
    };
}

