import { useCallback, useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { SignalRMethods } from "./signalRMethods";
import { NonTransientNotification } from "api/models/NonTransientNotification";
import { TransientNotification } from "api/models/TransientNotification";
import { NonTransientNotificationsUnreadCountUpdated } from "api/models/NonTransientNotificationsUnreadCountUpdated";
import { ShoppingCartUpdatedEvent } from "api/models/ShoppingCartUpdatedEvent";
import { TokenRefreshThresholdLimit } from "shared/constants";
import { decodeAccessToken } from "utilities/authUtils";

export interface UseSignalRProps {
  accessToken: string | undefined;
  onNonTransientNotificationReceived: (message: NonTransientNotification) => void;
  onTransientNotificationReceived: (message: TransientNotification) => void;
  onNonTransientNotificationsIsReadStatusUpdatedReceived: (
    message: NonTransientNotificationsUnreadCountUpdated
  ) => void;
  onShoppingCartUpdatedEvent: (message: ShoppingCartUpdatedEvent) => void;
  refreshToken?: () => void;
  debug?: boolean;
}

export const useSignalR = ({
  accessToken,
  onNonTransientNotificationReceived,
  onTransientNotificationReceived,
  onNonTransientNotificationsIsReadStatusUpdatedReceived,
  onShoppingCartUpdatedEvent,
  refreshToken,
  debug
}: UseSignalRProps): void => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [resetConnection, setResetConnection] = useState(false);

  const log = useCallback(
    (message?: any, ...optionalParameters: any[]) => {
      if (debug) {
        console.log(message, optionalParameters);
      }
    },
    [debug]
  );

  const getUrl = () => {
    // Proxying the initial HTTP request seems not to work in the local development environment
    return process.env.NODE_ENV === "development"
      ? "https://localhost:44374/hubs/user"
      : "/hubs/user";
  };

  useEffect(() => {
    if (accessToken) {
      const decodedToken = decodeAccessToken(accessToken);
      const tokenExpTime = decodedToken.exp;
      const isLocal = process.env.NODE_ENV === "development";
      const expires = new Date(tokenExpTime * 1000).toUTCString();
      document.cookie = `token=${accessToken}; SameSite=${
        isLocal ? "None" : "Lax"
      }; expires=${expires}; path=/; secure`;
    }
    setResetConnection(true);
  }, [accessToken]);

  const getToken = useCallback(() => {
    if (accessToken) {
      const decodedToken = decodeAccessToken(accessToken);
      const tokenExpTime = decodedToken.exp;
      const refreshThreshold = Math.floor(Date.now() / 1000) + TokenRefreshThresholdLimit;

      //  token about to expire OR expired
      if (refreshThreshold > tokenExpTime && refreshToken) {
        refreshToken();
      }
    }

    return accessToken ?? "";
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (accessToken) {
      if (!connection || resetConnection) {
        log("SignalR. Creating a connection with token: ", accessToken);

        getToken();

        const newConnection = new HubConnectionBuilder()
          .withUrl(getUrl())
          .withAutomaticReconnect()
          .build();

        setConnection(newConnection);
        setResetConnection(false);
      } else {
        if (connection.state === HubConnectionState.Connected && resetConnection) {
          log("Token changed, connection is open. Recreating the connection");
          connection.stop();
        }
      }
    }
  }, [accessToken, connection, getToken, log, resetConnection]);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      const resetConnection = (delay: number) => {
        const timer = setTimeout(() => {
          log("Resetting connection", new Date());
          setConnection(null);
          setResetConnection(true);
        }, delay);
        return () => clearTimeout(timer);
      };
      log("Setting handlers");
      connection
        .start()
        .then(() => {
          connection.on(
            SignalRMethods.ReceiveNonTransientNotification,
            (message: NonTransientNotification) => {
              onNonTransientNotificationReceived(message);
            }
          );

          connection.on(
            SignalRMethods.ReceiveTransientNotification,
            (message: TransientNotification) => {
              onTransientNotificationReceived(message);
            }
          );

          connection.on(
            SignalRMethods.ReceiveNonTransientNotificationCountUpdated,
            (message: NonTransientNotificationsUnreadCountUpdated) => {
              onNonTransientNotificationsIsReadStatusUpdatedReceived(message);
            }
          );

          connection.on(
            SignalRMethods.ReceiveShoppingCartUpdatedEvent,
            (message: ShoppingCartUpdatedEvent) => {
              onShoppingCartUpdatedEvent(message);
            }
          );

          connection.onclose((err) => {
            log("Connection closed", err);
            // Connection closed for some reason (over retry limit, manually closed, etc.)
            // try reconnect after a second
            resetConnection(1000);
          });
        })
        .catch((e) => {
          log("Connection failed: ", e, new Date());
          // When initianing the connection fails, set 30 s delay before starting the process again.
          resetConnection(30000);
        });
    }
  }, [
    connection,
    log,
    onNonTransientNotificationReceived,
    onNonTransientNotificationsIsReadStatusUpdatedReceived,
    onShoppingCartUpdatedEvent,
    onTransientNotificationReceived
  ]);
};
