import * as React from "react";
import { useSelector } from "react-redux";
import {
  getAuthInformation,
  getUserAccessRights
} from "../../applications/common/reducers/userReducer";
import { isAuthorizedToComponent } from "../../utilities/authUtils";
import { AccessRights } from "api/models/AccessRights";
import { useMsal } from "@azure/msal-react";
import { RedirectDialog } from "./RedirectDialog";
import { useTranslation } from "react-i18next";
import { RegistrationBanner } from "./RegistrationBanner";
import { getIsRegistrationWallDisabled } from "applications/common/reducers/commonReducer";

export interface AuthorizedComponentProps {
  companyId?: string;
  accessRights: AccessRights[];
  requireAll?: boolean;
  children?: React.ReactNode;
  showRegistrationBanner?: boolean;
  showRedirectDialog?: boolean;
}

const AuthorizedComponent: React.FunctionComponent<AuthorizedComponentProps> = ({
  showRedirectDialog = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const authInformation = useSelector(getAuthInformation);
  const userAccessRights = useSelector(getUserAccessRights);
  const isRegistrationWallDisabled = useSelector(getIsRegistrationWallDisabled);

  const notSignedIn = authInformation.initialUserCheckDone && !authInformation.isSignedIn;

  if (
    isAuthorizedToComponent(userAccessRights, {
      identifier: props.companyId,
      operation: props.requireAll ? "all" : "any",
      accessRights: props.accessRights
    })
  ) {
    return (
      <>
        {props.children}
        {notSignedIn && props.showRegistrationBanner && !isRegistrationWallDisabled && (
          <RegistrationBanner />
        )}
      </>
    );
  } else {
    // Redirect anonymous users to login page if they can't access the current page
    if (notSignedIn && showRedirectDialog) {
      return (
        <RedirectDialog
          showDialog={true}
          text={t(
            "This page is only available for logged in users.\n\nYou will be redirected to the login page shortly."
          )}
          t={t}
          redirectCallback={() => instance.loginRedirect()}
          countdownFrom={10}
          icon={"abb/warning-circle-1"}
          title={t("Not allowed")}
        />
      );
    }
    return <></>;
  }
};

export default AuthorizedComponent;
