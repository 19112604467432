/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddUserVm
 */
export interface AddUserVm {
    /**
     * 
     * @type {boolean}
     * @memberof AddUserVm
     */
    companyHasCompanyAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddUserVm
     */
    companyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserVm
     */
    hasDeliverItUsers: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AddUserVm
     */
    companyAccessAuditDueTo?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserVm
     */
    firstUserWasAdded: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddUserVm
     */
    userId: number;
}

export function AddUserVmFromJSON(json: any): AddUserVm {
    return AddUserVmFromJSONTyped(json, false);
}

export function AddUserVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddUserVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyHasCompanyAdmin': json['companyHasCompanyAdmin'],
        'companyId': json['companyId'],
        'hasDeliverItUsers': json['hasDeliverItUsers'],
        'companyAccessAuditDueTo': !exists(json, 'companyAccessAuditDueTo') ? undefined : (json['companyAccessAuditDueTo'] === null ? null : new Date(json['companyAccessAuditDueTo'])),
        'firstUserWasAdded': json['firstUserWasAdded'],
        'userId': json['userId'],
    };
}

export function AddUserVmToJSON(value?: AddUserVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyHasCompanyAdmin': value.companyHasCompanyAdmin,
        'companyId': value.companyId,
        'hasDeliverItUsers': value.hasDeliverItUsers,
        'companyAccessAuditDueTo': value.companyAccessAuditDueTo === undefined ? undefined : (value.companyAccessAuditDueTo === null ? null : value.companyAccessAuditDueTo.toISOString()),
        'firstUserWasAdded': value.firstUserWasAdded,
        'userId': value.userId,
    };
}

