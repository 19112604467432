/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateReleaseNoteCommand,
    CreateReleaseNoteCommandFromJSON,
    CreateReleaseNoteCommandToJSON,
    ReleaseNoteDto,
    ReleaseNoteDtoFromJSON,
    ReleaseNoteDtoToJSON,
    UpdateReleaseNoteDto,
    UpdateReleaseNoteDtoFromJSON,
    UpdateReleaseNoteDtoToJSON,
} from '../models';

export interface ApiReleaseNotesPostRequest {
    createReleaseNoteCommand?: CreateReleaseNoteCommand;
}

export interface ApiReleaseNotesVersionDeleteRequest {
    version: string;
}

export interface ApiReleaseNotesVersionPutRequest {
    version: string;
    updateReleaseNoteDto?: UpdateReleaseNoteDto;
}

/**
 * 
 */
export class ReleaseNotesApi extends runtime.BaseAPI {

    /**
     */
    async apiReleaseNotesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ReleaseNoteDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ReleaseNotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReleaseNoteDtoFromJSON));
    }

    /**
     */
    async apiReleaseNotesGet(initOverrides?: RequestInit): Promise<Array<ReleaseNoteDto>> {
        const response = await this.apiReleaseNotesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReleaseNotesPostRaw(requestParameters: ApiReleaseNotesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReleaseNoteDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ReleaseNotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReleaseNoteCommandToJSON(requestParameters.createReleaseNoteCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReleaseNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiReleaseNotesPost(requestParameters: ApiReleaseNotesPostRequest, initOverrides?: RequestInit): Promise<ReleaseNoteDto> {
        const response = await this.apiReleaseNotesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReleaseNotesVersionDeleteRaw(requestParameters: ApiReleaseNotesVersionDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiReleaseNotesVersionDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ReleaseNotes/{version}`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiReleaseNotesVersionDelete(requestParameters: ApiReleaseNotesVersionDeleteRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiReleaseNotesVersionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReleaseNotesVersionPutRaw(requestParameters: ApiReleaseNotesVersionPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReleaseNoteDto>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling apiReleaseNotesVersionPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/ReleaseNotes/{version}`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReleaseNoteDtoToJSON(requestParameters.updateReleaseNoteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReleaseNoteDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiReleaseNotesVersionPut(requestParameters: ApiReleaseNotesVersionPutRequest, initOverrides?: RequestInit): Promise<ReleaseNoteDto> {
        const response = await this.apiReleaseNotesVersionPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
