/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemFromJSONTyped,
    CriteriaSortItemToJSON,
} from './CriteriaSortItem';

/**
 * 
 * @export
 * @interface OrderItemsCriteria
 */
export interface OrderItemsCriteria {
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    sortColumn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemsCriteria
     */
    isAscendingOrder?: boolean;
    /**
     * 
     * @type {Array<CriteriaSortItem>}
     * @memberof OrderItemsCriteria
     */
    sortItems?: Array<CriteriaSortItem> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderItemsCriteria
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemsCriteria
     */
    pageSize: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemsCriteria
     */
    skipTotalRowCount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    readonly querySortByColumn?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderItemsCriteria
     */
    serialNumbers?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    serialNumberFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    serialNumberTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    orderAcknowledgementNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    orderLineItem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    order?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderItemsCriteria
     */
    orderingCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemsCriteria
     */
    isSoftwareQuery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemsCriteria
     */
    isDocumentQuery?: boolean;
}

export function OrderItemsCriteriaFromJSON(json: any): OrderItemsCriteria {
    return OrderItemsCriteriaFromJSONTyped(json, false);
}

export function OrderItemsCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItemsCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sortColumn': !exists(json, 'sortColumn') ? undefined : json['sortColumn'],
        'isAscendingOrder': !exists(json, 'isAscendingOrder') ? undefined : json['isAscendingOrder'],
        'sortItems': !exists(json, 'sortItems') ? undefined : (json['sortItems'] === null ? null : (json['sortItems'] as Array<any>).map(CriteriaSortItemFromJSON)),
        'page': json['page'],
        'pageSize': json['pageSize'],
        'skipTotalRowCount': !exists(json, 'skipTotalRowCount') ? undefined : json['skipTotalRowCount'],
        'querySortByColumn': !exists(json, 'querySortByColumn') ? undefined : json['querySortByColumn'],
        'serialNumbers': !exists(json, 'serialNumbers') ? undefined : json['serialNumbers'],
        'serialNumberFrom': !exists(json, 'serialNumberFrom') ? undefined : json['serialNumberFrom'],
        'serialNumberTo': !exists(json, 'serialNumberTo') ? undefined : json['serialNumberTo'],
        'orderAcknowledgementNumber': !exists(json, 'orderAcknowledgementNumber') ? undefined : json['orderAcknowledgementNumber'],
        'orderLineItem': !exists(json, 'orderLineItem') ? undefined : json['orderLineItem'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'orderingCode': !exists(json, 'orderingCode') ? undefined : json['orderingCode'],
        'isSoftwareQuery': !exists(json, 'isSoftwareQuery') ? undefined : json['isSoftwareQuery'],
        'isDocumentQuery': !exists(json, 'isDocumentQuery') ? undefined : json['isDocumentQuery'],
    };
}

export function OrderItemsCriteriaToJSON(value?: OrderItemsCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sortColumn': value.sortColumn,
        'isAscendingOrder': value.isAscendingOrder,
        'sortItems': value.sortItems === undefined ? undefined : (value.sortItems === null ? null : (value.sortItems as Array<any>).map(CriteriaSortItemToJSON)),
        'page': value.page,
        'pageSize': value.pageSize,
        'skipTotalRowCount': value.skipTotalRowCount,
        'serialNumbers': value.serialNumbers,
        'serialNumberFrom': value.serialNumberFrom,
        'serialNumberTo': value.serialNumberTo,
        'orderAcknowledgementNumber': value.orderAcknowledgementNumber,
        'orderLineItem': value.orderLineItem,
        'companyId': value.companyId,
        'order': value.order,
        'orderingCode': value.orderingCode,
        'isSoftwareQuery': value.isSoftwareQuery,
        'isDocumentQuery': value.isDocumentQuery,
    };
}

