import React, { RefObject } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "./Button";
import { TFunction } from "i18next";
import styled, { useTheme } from "styled-components";
import { filterNulls } from "utilities/objectUtils";

const ButtonContainer = styled.div`
  display: contents;
  @media print {
    display: none;
  }
`;

export interface PrintButtonProps {
  t: TFunction;
  componentsToPrint: (RefObject<HTMLElement> | string)[];
  gapBetweenComponents?: string;
  documentTitle: string;
  onBeforeGetContent?: () => void;
  onAfterPrint?: () => void;
  timeoutMilliseconds?: number;
  isPrintLoading?: boolean;
  setIsPrintLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailGridStyles?: boolean;
  setBreakInsideAvoid?: boolean;
}

/**
 * @param componentsToPrint Components to print in order from top to bottom. Refs (from useRef) and component Ids (without #)
 * @param gapBetweenComponents Margin-bottom between components. theme.sizes.xl (40px) by default
 * @param documentTitle Printed document's title
 * @param onBeforeGetContent Function to execute before getting content (do not add timers here)
 * @param onAfterPrint Function to execute after printing (or failing print)
 * @param timeoutMilliseconds Milliseconds to wait before starting print after calling onBeforeGetContent
 * @param isPrintLoading Boolean for button's isLoading parameter
 * @param setIsPrintLoading SetState for isPrintLoading
 * @param setDetailGridStyles True to set detail grids' heights
 * @param setBreakInsideAvoid True to add break inside: avoid for parent components
 */
export const PrintButton = ({
  t,
  componentsToPrint,
  gapBetweenComponents,
  documentTitle,
  onBeforeGetContent,
  onAfterPrint,
  timeoutMilliseconds,
  isPrintLoading,
  setIsPrintLoading,
  setDetailGridStyles,
  setBreakInsideAvoid
}: PrintButtonProps) => {
  const afterPrint = () => {
    if (onAfterPrint) onAfterPrint();
    if (setIsPrintLoading) setIsPrintLoading(false);
    return null;
  };
  const logErrors = process.env.NODE_ENV == "development";
  const theme = useTheme();

  return (
    <ReactToPrint
      documentTitle={documentTitle}
      content={() => {
        if (componentsToPrint?.length == 0) return afterPrint();

        const components = componentsToPrint
          ?.map((reference) => {
            if (typeof reference == "string") {
              // Component Id
              const component = document.querySelector<HTMLElement>(`#${reference}`);
              if (!component && logErrors)
                console.error(
                  `No component found with id: ${reference}, list index: ${componentsToPrint.indexOf(
                    reference
                  )}`
                );
              return component;
            } else {
              // Component ref
              const component = reference.current;
              if (!component && logErrors)
                console.error(
                  `No component found with ref at list index: ${componentsToPrint.indexOf(
                    reference
                  )}`,
                  reference
                );
              return component;
            }
          })
          .filter(filterNulls);

        if (components.length == 0) return afterPrint();
        else if (components.length == 1) return components[0];
        else {
          const printContainer = document.createElement("div");
          const style = document.createElement("style");
          style.textContent = `
            @media print
            {
              .printDiv {
                ${
                  setBreakInsideAvoid
                    ? "break-inside: avoid !important; page-break-inside: avoid !important;"
                    : ""
                }
                margin-top: ${gapBetweenComponents ?? theme.sizes.xl} !important;
              }
            }
          `;
          printContainer.appendChild(style);
          for (let i = 0; i < components.length; i++) {
            const component = components[i];
            if (i > 0) component.classList.add("printDiv");
            printContainer.appendChild(component.cloneNode(true));
          }
          return printContainer;
        }
      }}
      trigger={() => (
        <ButtonContainer>
          <Button
            buttonType={"discreet"}
            text={t("Print")}
            icon="abb/print"
            isLoading={isPrintLoading ?? false}
          />
        </ButtonContainer>
      )}
      onBeforeGetContent={() => {
        if (setIsPrintLoading) setIsPrintLoading(true);
        if (onBeforeGetContent) onBeforeGetContent();
        if (setDetailGridStyles) {
          setTimeout(() => {
            const detailsRows = document.getElementsByClassName("ag-details-row");
            for (let i = 0; i < detailsRows.length; i++) {
              const detailRow = detailsRows[i];
              const container = detailRow.getElementsByClassName(
                "ag-center-cols-container"
              )[0] as HTMLElement;
              const height = container.style.getPropertyValue("height");
              container.style.setProperty("height", height, "important");
            }
          }, 2000);
        }
        if (timeoutMilliseconds && timeoutMilliseconds > 0)
          return new Promise((resolve) => {
            setTimeout(resolve, timeoutMilliseconds);
          });
      }}
      onAfterPrint={() => {
        afterPrint();
      }}
      pageStyle={() => {
        return `
          @media print {
            @page {
              margin: 20px !important;
              size: landscape;
            }
          }`;
      }}
    />
  );
};
