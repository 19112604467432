/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestFunctionalitySelectionDto,
    AccessRequestFunctionalitySelectionDtoFromJSON,
    AccessRequestFunctionalitySelectionDtoFromJSONTyped,
    AccessRequestFunctionalitySelectionDtoToJSON,
} from './AccessRequestFunctionalitySelectionDto';

/**
 * 
 * @export
 * @interface AccessRequestMetadataVm
 */
export interface AccessRequestMetadataVm {
    /**
     * 
     * @type {Array<AccessRequestFunctionalitySelectionDto>}
     * @memberof AccessRequestMetadataVm
     */
    selections: Array<AccessRequestFunctionalitySelectionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRequestMetadataVm
     */
    canSelectCountry: boolean;
}

export function AccessRequestMetadataVmFromJSON(json: any): AccessRequestMetadataVm {
    return AccessRequestMetadataVmFromJSONTyped(json, false);
}

export function AccessRequestMetadataVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestMetadataVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selections': ((json['selections'] as Array<any>).map(AccessRequestFunctionalitySelectionDtoFromJSON)),
        'canSelectCountry': json['canSelectCountry'],
    };
}

export function AccessRequestMetadataVmToJSON(value?: AccessRequestMetadataVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selections': ((value.selections as Array<any>).map(AccessRequestFunctionalitySelectionDtoToJSON)),
        'canSelectCountry': value.canSelectCountry,
    };
}

