/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModificationProduct
 */
export interface ModificationProduct {
    /**
     * 
     * @type {string}
     * @memberof ModificationProduct
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationProduct
     */
    compositionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModificationProduct
     */
    materialCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModificationProduct
     */
    maxAmountOfDevices?: number;
}

export function ModificationProductFromJSON(json: any): ModificationProduct {
    return ModificationProductFromJSONTyped(json, false);
}

export function ModificationProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModificationProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'compositionCode': !exists(json, 'compositionCode') ? undefined : json['compositionCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'maxAmountOfDevices': !exists(json, 'maxAmountOfDevices') ? undefined : json['maxAmountOfDevices'],
    };
}

export function ModificationProductToJSON(value?: ModificationProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'compositionCode': value.compositionCode,
        'materialCode': value.materialCode,
        'maxAmountOfDevices': value.maxAmountOfDevices,
    };
}

