import {
  ApiTrainingNodesFlatGetRequest,
  ApiTrainingNodesRootsGetRequest,
  ApiTrainingSearchArticlePostRequest,
  ArticleDto,
  ArticleDtoFormatted,
  NodeDto,
  NodeFlattenedDto,
  SearchResponseDto
} from "api";
import { produce } from "immer";
import { NewTrainingActions, TrainingActionType } from "../actions/trainingActions";
import { AppState } from "setup/appRootReducer";
import { handleRequestActions } from "framework/state/genericAsyncRequest";
import { trainingSagas } from "../sagas/trainingSagas";
import { RequestState, createInitialRequest } from "framework/state/requestState";
import { RequestStatus } from "framework/state/requestStatus";

interface Requests {
  getNodesRoots: RequestState<ApiTrainingNodesRootsGetRequest>;
  getNodesFlat: RequestState<ApiTrainingNodesFlatGetRequest>;
  search: RequestState<SearchResponseDto, ApiTrainingSearchArticlePostRequest>;
}

export interface TrainingState {
  requests: Requests;
  nodeExpandedTraining: NodeDto | undefined;
  nodeFlattenedDto: NodeFlattenedDto | undefined;
  openArticle: ArticleDto | undefined;
  nodes: NodeFlattenedDto[];
  roots: NodeDto[];
  nodeOnScreenId: string | undefined;
  search: string | undefined;
  isSearch: boolean | undefined;
  isLoading: boolean | undefined;
  pageSize: number;
  pageNumber: number;
  forceScroll: boolean;
  articlesHits: ArticleDtoFormatted[];
  articlesHitsNumber: number | undefined;
  categories:
    | {
        [key: string]: number;
      }
    | undefined;
}
const defaultState: TrainingState = {
  requests: {
    getNodesRoots: createInitialRequest(),
    getNodesFlat: createInitialRequest(),
    search: createInitialRequest()
  },
  nodeExpandedTraining: undefined,
  nodeFlattenedDto: undefined,
  openArticle: undefined,
  nodes: [],
  roots: [],
  nodeOnScreenId: undefined,
  search: undefined,
  isSearch: undefined,
  isLoading: undefined,
  pageNumber: 0,
  pageSize: 2,
  forceScroll: false,
  articlesHits: [],
  articlesHitsNumber: undefined,
  categories: undefined
};

export function trainingReducer(
  state: TrainingState = defaultState,
  action: NewTrainingActions
): TrainingState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: trainingSagas.getNodesFlat.actionTypes,
      key: "getNodesFlat"
    },
    {
      actionTypes: trainingSagas.getNodesRoots.actionTypes,
      key: "getNodesRoots"
    },
    {
      actionTypes: trainingSagas.search.actionTypes,
      key: "search"
    }
  ]);
  if (trainingSagas.getNodesFlat.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.nodes = action.payload;
    });

  if (trainingSagas.getNodesRoots.isCompletedAction(action))
    state = produce(state, (draft) => {
      draft.roots = action.payload;
    });
  if (trainingSagas.search.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.articlesHits = action.payload.articles ?? [];
      draft.articlesHitsNumber = action.payload.estimatedTotalHits ?? 0;
      draft.categories = action.payload.facetDistribution ?? undefined;
      draft.isLoading = false;
    });
  }

  switch (action.type) {
    case TrainingActionType.SetVisibleArticle:
      state = produce(state, (draft) => {
        draft.openArticle = action.article;
      });
      break;

    case TrainingActionType.SetNodeOpen:
      state = produce(state, (draft) => {
        draft.nodeFlattenedDto = undefined;
        draft.nodeExpandedTraining = action.node;
        draft.isSearch = false;
        draft.openArticle = undefined;
        draft.isLoading = true;
        draft.search = undefined;
      });
      break;
    case TrainingActionType.SetNodeOpenFlat:
      state = produce(state, (draft) => {
        draft.nodeExpandedTraining = undefined;
        draft.nodeFlattenedDto = action.node;
        draft.isSearch = false;
        draft.openArticle = undefined;
        draft.search = undefined;
        draft.isLoading = true;
      });
      break;
    case TrainingActionType.SetNodeOnScreen:
      state = produce(state, (draft) => {
        draft.nodeOnScreenId = action.id;
      });
      break;
    case TrainingActionType.SetSearchText:
      state = produce(state, (draft) => {
        draft.search = action.search;
        draft.isSearch = !action.search ? false : true;
        draft.isLoading = !action.search ? false : true;
      });
      break;
    case TrainingActionType.SetLoadingResults:
      state = produce(state, (draft) => {
        draft.isLoading = action.loading;
      });
      break;
    case TrainingActionType.SetPageNumber:
      state = produce(state, (draft) => {
        draft.pageNumber = action.page;
      });
      break;
    case TrainingActionType.SetPageSize:
      state = produce(state, (draft) => {
        draft.pageSize = action.size;
      });
      break;
    case TrainingActionType.SetForceScroll:
      state = produce(state, (draft) => {
        draft.forceScroll = action.force;
      });
      break;
  }
  return state;
}
export const getOpenArticle = (state: AppState): ArticleDto | undefined =>
  state.training.openArticle;
export const getHits = (state: AppState) => state.training.articlesHits;
export const getHitsCategories = (state: AppState) => state.training.categories;
export const getNodeExpandedTraining = (state: AppState): NodeDto | undefined =>
  state.training.nodeExpandedTraining;
export const getNodeExpandedFlattedTraining = (state: AppState): NodeFlattenedDto | undefined =>
  state.training.nodeFlattenedDto;
export const getNodes = (state: AppState): NodeFlattenedDto[] => state.training.nodes;
export const getNodesStatus = (state: AppState): RequestStatus =>
  state.training.requests.getNodesFlat.status;
export const getNodesRoots = (state: AppState): NodeDto[] => state.training.roots;
export const getNodesRootsStatus = (state: AppState): RequestStatus =>
  state.training.requests.getNodesRoots.status;
export const getNodesOnScreenId = (state: AppState): string | undefined =>
  state.training.nodeOnScreenId;
export const getSearch = (state: AppState): string | undefined => state.training.search;
export const getIsSearch = (state: AppState): boolean | undefined => state.training.isSearch;
export const getIsLoading = (state: AppState): boolean | undefined => state.training.isLoading;
export const getPageSize = (state: AppState): number => state.training.pageSize;
export const getPageNumber = (state: AppState): number => state.training.pageNumber;
export const getForceScroll = (state: AppState): boolean => state.training.forceScroll;
