import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import { OrderNotificationsMetadataVm } from "../../../../api";
import { Action } from "redux";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { notificationViewSagas } from "../sagas/notificationSagas";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { formatDate } from "../../../../utilities/dateUtils";

interface Requests {
  getNotificationsMetadata: RequestState<OrderNotificationsMetadataVm>;
}

export interface NotificationsViewState {
  requests: Requests;
  notificationProcessingLimit: string | undefined;
}

const defaultState: NotificationsViewState = {
  requests: {
    getNotificationsMetadata: createInitialRequest()
  },
  notificationProcessingLimit: undefined
};

export function notificationsViewReducer(
  state: NotificationsViewState = defaultState,
  action: Action
): NotificationsViewState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: notificationViewSagas.getNotificationsMetadata.actionTypes,
      key: "getNotificationsMetadata"
    }
  ]);

  if (notificationViewSagas.getNotificationsMetadata.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.notificationProcessingLimit = formatDate(action.payload.processingLimit);
    });
  }
  return state;
}

export const getNotificationProcessingLimit = (state: AppState): string | undefined =>
  state.deliveries.notificationsView.notificationProcessingLimit;
