import { css } from "styled-components";

export const utilStyles = css`
  .overflow-visible {
    overflow: visible;
  }

  .overflow-auto {
    overflow: auto;
  }
`;
