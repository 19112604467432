import { RequestState, createInitialRequest } from "../../../../framework/state/requestState";
import {
  CompanyDto,
  AddUserVm,
  SystemSettingsVm,
  OverrideStatusAuditVm,
  DeleteUserCompanyAccessVm,
  CompanyAccessAuditVm,
  CompanyVm,
  UpdateCompanyUserVm
} from "../../../../api";
import { Action } from "redux";
import { handleRequestActions } from "../../../../framework/state/genericAsyncRequest";
import { produce } from "immer";
import { AppState } from "../../../../setup/appRootReducer";
import { manageCompaniesSagas } from "../sagas/manageCompaniesSagas";
import { deliveriesHomeSagas } from "../../../deliveries/deliveriesHome/sagas/deliveriesHomeSagas";

interface Requests {
  getCompanyToManage: RequestState<CompanyVm>;
  deleteCompanyUser: RequestState<DeleteUserCompanyAccessVm>;
  updateUserCompanyAccess: RequestState<UpdateCompanyUserVm>;
  addUserToCompany: RequestState<AddUserVm>;
  updateCompanyOverrideStatus: RequestState<CompanyDto>;
  companyAccessAudit: RequestState<CompanyAccessAuditVm>;
  getSystemSettings: RequestState<SystemSettingsVm>;
  overrideStatusAudit: RequestState<OverrideStatusAuditVm>;
}

export interface ManageCompaniesState {
  requests: Requests;
  company: CompanyDto | undefined;
  overrideStatusAuditStatus: OverrideStatusAuditVm | undefined;
}

const defaultState: ManageCompaniesState = {
  requests: {
    getCompanyToManage: createInitialRequest(),
    deleteCompanyUser: createInitialRequest(),
    updateUserCompanyAccess: createInitialRequest(),
    addUserToCompany: createInitialRequest(),
    updateCompanyOverrideStatus: createInitialRequest(),
    companyAccessAudit: createInitialRequest(),
    getSystemSettings: createInitialRequest(),
    overrideStatusAudit: createInitialRequest()
  },
  company: undefined,
  overrideStatusAuditStatus: undefined
};

export function manageCompaniesReducer(
  state: ManageCompaniesState = defaultState,
  action: Action
): ManageCompaniesState {
  state = handleRequestActions(state, "requests", action, [
    {
      actionTypes: manageCompaniesSagas.getCompanyToManage.actionTypes,
      key: "getCompanyToManage"
    },
    {
      actionTypes: manageCompaniesSagas.deleteCompanyUser.actionTypes,
      key: "deleteCompanyUser"
    },
    {
      actionTypes: manageCompaniesSagas.updateUserCompanyAccess.actionTypes,
      key: "updateUserCompanyAccess"
    },
    {
      actionTypes: manageCompaniesSagas.addUserToCompany.actionTypes,
      key: "addUserToCompany"
    },
    {
      actionTypes: manageCompaniesSagas.updateCompanyOverrideStatus.actionTypes,
      key: "updateCompanyOverrideStatus"
    },
    {
      actionTypes: manageCompaniesSagas.companyAccessAudit.actionTypes,
      key: "companyAccessAudit"
    },
    {
      actionTypes: deliveriesHomeSagas.getSystemSettings.actionTypes,
      key: "getSystemSettings"
    },
    {
      actionTypes: manageCompaniesSagas.overrideStatusAudit.actionTypes,
      key: "overrideStatusAudit"
    }
  ]);

  if (manageCompaniesSagas.getCompanyToManage.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.company = action.payload.company;
    });
  } else if (manageCompaniesSagas.updateCompanyOverrideStatus.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.company = action.payload;
    });
  } else if (manageCompaniesSagas.companyAccessAudit.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.company) {
        draft.company.accessAuditDueTo = action.payload.companyAccessAuditDueTo;
        draft.company.hasLateAccessAudit =
          action.payload.hasLateAccessAudit == null ? undefined : action.payload.hasLateAccessAudit;
        draft.company.accessAuditCompleted = action.payload.companyAccessAuditCompleted;
      }
    });
  } else if (manageCompaniesSagas.addUserToCompany.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.company) {
        draft.company.accessAuditDueTo = action.payload.companyAccessAuditDueTo;
        draft.company.hasCompanyAdmin = action.payload.companyHasCompanyAdmin;
        draft.company.hasDeliverItUsers = action.payload.hasDeliverItUsers;
      }
    });
  } else if (manageCompaniesSagas.updateUserCompanyAccess.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.company) {
        draft.company.hasCompanyAdmin = action.payload.companyHasCompanyAdmin;
        draft.company.hasDeliverItUsers = action.payload.hasDeliverItUsers;
      }
    });
  } else if (manageCompaniesSagas.deleteCompanyUser.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      if (draft.company) {
        draft.company.hasCompanyAdmin = action.payload.companyHasCompanyAdmin;
        draft.company.hasDeliverItUsers = action.payload.hasDeliverItUsers;
      }
    });
  } else if (deliveriesHomeSagas.getSystemSettings.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.overrideStatusAuditStatus = {
        overrideStatusAuditCompleted:
          action.payload.systemSetting.overrideStatusAuditCompleted ?? undefined,
        overrideStatusAuditDueTo: action.payload.systemSetting.overrideStatusAuditDueTo ?? undefined
      };
    });
  } else if (manageCompaniesSagas.overrideStatusAudit.isCompletedAction(action)) {
    state = produce(state, (draft) => {
      draft.overrideStatusAuditStatus = {
        overrideStatusAuditCompleted: action.payload.overrideStatusAuditCompleted,
        overrideStatusAuditDueTo: action.payload.overrideStatusAuditDueTo
      };
    });
  }

  return state;
}

export const getManagedCompany = (state: AppState): CompanyDto | undefined =>
  state.manageCompanies.company;

export const getGetManagedCompanyRequest = (state: AppState): RequestState<CompanyVm> =>
  state.manageCompanies.requests.getCompanyToManage;

export const getDeleteCompanyUserRequest = (
  state: AppState
): RequestState<DeleteUserCompanyAccessVm> => state.manageCompanies.requests.deleteCompanyUser;

export const getUpdateUserCompanyAccessRequest = (
  state: AppState
): RequestState<UpdateCompanyUserVm> => state.manageCompanies.requests.updateUserCompanyAccess;

export const getAddUserToCompanyRequest = (state: AppState): RequestState<AddUserVm> =>
  state.manageCompanies.requests.addUserToCompany;

export const getUpdateCompanyOverrideStatusRequest = (state: AppState): RequestState<CompanyDto> =>
  state.manageCompanies.requests.updateCompanyOverrideStatus;

export const getCompanyAccessAuditRequest = (state: AppState): RequestState<CompanyAccessAuditVm> =>
  state.manageCompanies.requests.companyAccessAudit;

export const getOverrideStatusAuditRequest = (
  state: AppState
): RequestState<OverrideStatusAuditVm> => state.manageCompanies.requests.overrideStatusAudit;

export const getOverrideStatusAuditStatus = (state: AppState): OverrideStatusAuditVm | undefined =>
  state.manageCompanies.overrideStatusAuditStatus;
