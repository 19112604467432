/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessRights,
    AccessRightsFromJSON,
    AccessRightsToJSON,
    AddCompanyUserCommand,
    AddCompanyUserCommandFromJSON,
    AddCompanyUserCommandToJSON,
    AddUserVm,
    AddUserVmFromJSON,
    AddUserVmToJSON,
    ColumnDefinitionsDto,
    ColumnDefinitionsDtoFromJSON,
    ColumnDefinitionsDtoToJSON,
    CompaniesVm,
    CompaniesVmFromJSON,
    CompaniesVmToJSON,
    CompanyAccessAuditCommand,
    CompanyAccessAuditCommandFromJSON,
    CompanyAccessAuditCommandToJSON,
    CompanyAccessAuditVm,
    CompanyAccessAuditVmFromJSON,
    CompanyAccessAuditVmToJSON,
    CompanyDto,
    CompanyDtoFromJSON,
    CompanyDtoToJSON,
    CompanyUsersVm,
    CompanyUsersVmFromJSON,
    CompanyUsersVmToJSON,
    CompanyVm,
    CompanyVmFromJSON,
    CompanyVmToJSON,
    CreditCheckDto,
    CreditCheckDtoFromJSON,
    CreditCheckDtoToJSON,
    CriteriaSortItem,
    CriteriaSortItemFromJSON,
    CriteriaSortItemToJSON,
    DeleteUserCompanyAccessVm,
    DeleteUserCompanyAccessVmFromJSON,
    DeleteUserCompanyAccessVmToJSON,
    RoleGroups,
    RoleGroupsFromJSON,
    RoleGroupsToJSON,
    TermsOfPaymentDto,
    TermsOfPaymentDtoFromJSON,
    TermsOfPaymentDtoToJSON,
    UpdateCompanyOverrideStatusCommand,
    UpdateCompanyOverrideStatusCommandFromJSON,
    UpdateCompanyOverrideStatusCommandToJSON,
    UpdateCompanyUserActiveStatusCommand,
    UpdateCompanyUserActiveStatusCommandFromJSON,
    UpdateCompanyUserActiveStatusCommandToJSON,
    UpdateCompanyUserVm,
    UpdateCompanyUserVmFromJSON,
    UpdateCompanyUserVmToJSON,
} from '../models';

export interface ApiCompaniesAccessAuditPostRequest {
    companyAccessAuditCommand?: CompanyAccessAuditCommand;
}

export interface ApiCompaniesCompanyUsersDeleteRequest {
    userId?: number;
    companyId?: string;
}

export interface ApiCompaniesCompanyUsersGetRequest {
    criteriaCompanyId: string;
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiCompaniesCompanyUsersPatchRequest {
    updateCompanyUserActiveStatusCommand?: UpdateCompanyUserActiveStatusCommand;
}

export interface ApiCompaniesCompanyUsersPostRequest {
    addCompanyUserCommand?: AddCompanyUserCommand;
}

export interface ApiCompaniesExportPostRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaCustomerCode?: string;
    criteriaName?: string;
    criteriaCountryCode?: string;
    criteriaAddressCity?: string;
    criteriaAddressStreet?: string;
    criteriaGetOnlyActive?: boolean;
    criteriaGetOnlyVisible?: boolean;
    criteriaUserIdToEdit?: string;
    criteriaLastActiveFrom?: Date;
    criteriaLastActiveTo?: Date;
    criteriaHasLateAccessAudit?: boolean;
    criteriaDisplayName?: string;
    criteriaRoleGroup?: RoleGroups;
    criteriaIsAccessRequestCompanySelection?: boolean;
    criteriaRequireAnyOfAccessRight?: Array<AccessRights>;
    criteriaAlreadyInCompanyIds?: Array<string>;
    criteriaStatus?: Array<boolean>;
    criteriaOverrideStatus?: Array<boolean>;
    criteriaHasCompanyAdminStatus?: Array<boolean>;
    criteriaGetOnlyExternalCompanies?: boolean;
    criteriaGetOnlyInternalCompanies?: boolean;
    criteriaHasTermsAndConditions?: boolean;
    criteriaGlobalCustomerNumber?: string;
    criteriaGetPgCodes?: boolean;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
    columnDefinitionsDto?: ColumnDefinitionsDto;
}

export interface ApiCompaniesGetRequest {
    criteriaPage: number;
    criteriaPageSize: number;
    criteriaCompanyId?: string;
    criteriaCustomerCode?: string;
    criteriaName?: string;
    criteriaCountryCode?: string;
    criteriaAddressCity?: string;
    criteriaAddressStreet?: string;
    criteriaGetOnlyActive?: boolean;
    criteriaGetOnlyVisible?: boolean;
    criteriaUserIdToEdit?: string;
    criteriaLastActiveFrom?: Date;
    criteriaLastActiveTo?: Date;
    criteriaHasLateAccessAudit?: boolean;
    criteriaDisplayName?: string;
    criteriaRoleGroup?: RoleGroups;
    criteriaIsAccessRequestCompanySelection?: boolean;
    criteriaRequireAnyOfAccessRight?: Array<AccessRights>;
    criteriaAlreadyInCompanyIds?: Array<string>;
    criteriaStatus?: Array<boolean>;
    criteriaOverrideStatus?: Array<boolean>;
    criteriaHasCompanyAdminStatus?: Array<boolean>;
    criteriaGetOnlyExternalCompanies?: boolean;
    criteriaGetOnlyInternalCompanies?: boolean;
    criteriaHasTermsAndConditions?: boolean;
    criteriaGlobalCustomerNumber?: string;
    criteriaGetPgCodes?: boolean;
    criteriaQuerySortByColumn?: string;
    criteriaSortColumn?: string;
    criteriaIsAscendingOrder?: boolean;
    criteriaSortItems?: Array<CriteriaSortItem>;
    criteriaSkipTotalRowCount?: boolean;
}

export interface ApiCompaniesIdCompanyApproversGetRequest {
    id: string;
}

export interface ApiCompaniesIdCreditCheckGetRequest {
    id: string;
    totalEur: number;
}

export interface ApiCompaniesIdGetRequest {
    id: string;
}

export interface ApiCompaniesIdPgCodesGetRequest {
    id: string;
}

export interface ApiCompaniesIdTermsOfPaymentGetRequest {
    id: string;
}

export interface ApiCompaniesOverrideStatusPatchRequest {
    updateCompanyOverrideStatusCommand?: UpdateCompanyOverrideStatusCommand;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     */
    async apiCompaniesAccessAuditPostRaw(requestParameters: ApiCompaniesAccessAuditPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyAccessAuditVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/accessAudit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAccessAuditCommandToJSON(requestParameters.companyAccessAuditCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAccessAuditVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesAccessAuditPost(requestParameters: ApiCompaniesAccessAuditPostRequest, initOverrides?: RequestInit): Promise<CompanyAccessAuditVm> {
        const response = await this.apiCompaniesAccessAuditPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesCompanyUsersDeleteRaw(requestParameters: ApiCompaniesCompanyUsersDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeleteUserCompanyAccessVm>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['CompanyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/companyUsers`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteUserCompanyAccessVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesCompanyUsersDelete(requestParameters: ApiCompaniesCompanyUsersDeleteRequest, initOverrides?: RequestInit): Promise<DeleteUserCompanyAccessVm> {
        const response = await this.apiCompaniesCompanyUsersDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesCompanyUsersGetRaw(requestParameters: ApiCompaniesCompanyUsersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUsersVm>> {
        if (requestParameters.criteriaCompanyId === null || requestParameters.criteriaCompanyId === undefined) {
            throw new runtime.RequiredError('criteriaCompanyId','Required parameter requestParameters.criteriaCompanyId was null or undefined when calling apiCompaniesCompanyUsersGet.');
        }

        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiCompaniesCompanyUsersGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiCompaniesCompanyUsersGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/companyUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUsersVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesCompanyUsersGet(requestParameters: ApiCompaniesCompanyUsersGetRequest, initOverrides?: RequestInit): Promise<CompanyUsersVm> {
        const response = await this.apiCompaniesCompanyUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesCompanyUsersPatchRaw(requestParameters: ApiCompaniesCompanyUsersPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateCompanyUserVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/companyUsers`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyUserActiveStatusCommandToJSON(requestParameters.updateCompanyUserActiveStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCompanyUserVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesCompanyUsersPatch(requestParameters: ApiCompaniesCompanyUsersPatchRequest, initOverrides?: RequestInit): Promise<UpdateCompanyUserVm> {
        const response = await this.apiCompaniesCompanyUsersPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesCompanyUsersPostRaw(requestParameters: ApiCompaniesCompanyUsersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AddUserVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/companyUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCompanyUserCommandToJSON(requestParameters.addCompanyUserCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddUserVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesCompanyUsersPost(requestParameters: ApiCompaniesCompanyUsersPostRequest, initOverrides?: RequestInit): Promise<AddUserVm> {
        const response = await this.apiCompaniesCompanyUsersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesExportPostRaw(requestParameters: ApiCompaniesExportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiCompaniesExportPost.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiCompaniesExportPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaCustomerCode !== undefined) {
            queryParameters['Criteria.CustomerCode'] = requestParameters.criteriaCustomerCode;
        }

        if (requestParameters.criteriaName !== undefined) {
            queryParameters['Criteria.Name'] = requestParameters.criteriaName;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaAddressCity !== undefined) {
            queryParameters['Criteria.AddressCity'] = requestParameters.criteriaAddressCity;
        }

        if (requestParameters.criteriaAddressStreet !== undefined) {
            queryParameters['Criteria.AddressStreet'] = requestParameters.criteriaAddressStreet;
        }

        if (requestParameters.criteriaGetOnlyActive !== undefined) {
            queryParameters['Criteria.GetOnlyActive'] = requestParameters.criteriaGetOnlyActive;
        }

        if (requestParameters.criteriaGetOnlyVisible !== undefined) {
            queryParameters['Criteria.GetOnlyVisible'] = requestParameters.criteriaGetOnlyVisible;
        }

        if (requestParameters.criteriaUserIdToEdit !== undefined) {
            queryParameters['Criteria.UserIdToEdit'] = requestParameters.criteriaUserIdToEdit;
        }

        if (requestParameters.criteriaLastActiveFrom !== undefined) {
            queryParameters['Criteria.LastActiveFrom'] = (requestParameters.criteriaLastActiveFrom as any).toISOString();
        }

        if (requestParameters.criteriaLastActiveTo !== undefined) {
            queryParameters['Criteria.LastActiveTo'] = (requestParameters.criteriaLastActiveTo as any).toISOString();
        }

        if (requestParameters.criteriaHasLateAccessAudit !== undefined) {
            queryParameters['Criteria.HasLateAccessAudit'] = requestParameters.criteriaHasLateAccessAudit;
        }

        if (requestParameters.criteriaDisplayName !== undefined) {
            queryParameters['Criteria.DisplayName'] = requestParameters.criteriaDisplayName;
        }

        if (requestParameters.criteriaRoleGroup !== undefined) {
            queryParameters['Criteria.RoleGroup'] = requestParameters.criteriaRoleGroup;
        }

        if (requestParameters.criteriaIsAccessRequestCompanySelection !== undefined) {
            queryParameters['Criteria.IsAccessRequestCompanySelection'] = requestParameters.criteriaIsAccessRequestCompanySelection;
        }

        if (requestParameters.criteriaRequireAnyOfAccessRight) {
            queryParameters['Criteria.RequireAnyOfAccessRight'] = requestParameters.criteriaRequireAnyOfAccessRight;
        }

        if (requestParameters.criteriaAlreadyInCompanyIds) {
            queryParameters['Criteria.AlreadyInCompanyIds'] = requestParameters.criteriaAlreadyInCompanyIds;
        }

        if (requestParameters.criteriaStatus) {
            queryParameters['Criteria.Status'] = requestParameters.criteriaStatus;
        }

        if (requestParameters.criteriaOverrideStatus) {
            queryParameters['Criteria.OverrideStatus'] = requestParameters.criteriaOverrideStatus;
        }

        if (requestParameters.criteriaHasCompanyAdminStatus) {
            queryParameters['Criteria.HasCompanyAdminStatus'] = requestParameters.criteriaHasCompanyAdminStatus;
        }

        if (requestParameters.criteriaGetOnlyExternalCompanies !== undefined) {
            queryParameters['Criteria.GetOnlyExternalCompanies'] = requestParameters.criteriaGetOnlyExternalCompanies;
        }

        if (requestParameters.criteriaGetOnlyInternalCompanies !== undefined) {
            queryParameters['Criteria.GetOnlyInternalCompanies'] = requestParameters.criteriaGetOnlyInternalCompanies;
        }

        if (requestParameters.criteriaHasTermsAndConditions !== undefined) {
            queryParameters['Criteria.HasTermsAndConditions'] = requestParameters.criteriaHasTermsAndConditions;
        }

        if (requestParameters.criteriaGlobalCustomerNumber !== undefined) {
            queryParameters['Criteria.GlobalCustomerNumber'] = requestParameters.criteriaGlobalCustomerNumber;
        }

        if (requestParameters.criteriaGetPgCodes !== undefined) {
            queryParameters['Criteria.GetPgCodes'] = requestParameters.criteriaGetPgCodes;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ColumnDefinitionsDtoToJSON(requestParameters.columnDefinitionsDto),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiCompaniesExportPost(requestParameters: ApiCompaniesExportPostRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiCompaniesExportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesGetRaw(requestParameters: ApiCompaniesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompaniesVm>> {
        if (requestParameters.criteriaPage === null || requestParameters.criteriaPage === undefined) {
            throw new runtime.RequiredError('criteriaPage','Required parameter requestParameters.criteriaPage was null or undefined when calling apiCompaniesGet.');
        }

        if (requestParameters.criteriaPageSize === null || requestParameters.criteriaPageSize === undefined) {
            throw new runtime.RequiredError('criteriaPageSize','Required parameter requestParameters.criteriaPageSize was null or undefined when calling apiCompaniesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.criteriaCompanyId !== undefined) {
            queryParameters['Criteria.CompanyId'] = requestParameters.criteriaCompanyId;
        }

        if (requestParameters.criteriaCustomerCode !== undefined) {
            queryParameters['Criteria.CustomerCode'] = requestParameters.criteriaCustomerCode;
        }

        if (requestParameters.criteriaName !== undefined) {
            queryParameters['Criteria.Name'] = requestParameters.criteriaName;
        }

        if (requestParameters.criteriaCountryCode !== undefined) {
            queryParameters['Criteria.CountryCode'] = requestParameters.criteriaCountryCode;
        }

        if (requestParameters.criteriaAddressCity !== undefined) {
            queryParameters['Criteria.AddressCity'] = requestParameters.criteriaAddressCity;
        }

        if (requestParameters.criteriaAddressStreet !== undefined) {
            queryParameters['Criteria.AddressStreet'] = requestParameters.criteriaAddressStreet;
        }

        if (requestParameters.criteriaGetOnlyActive !== undefined) {
            queryParameters['Criteria.GetOnlyActive'] = requestParameters.criteriaGetOnlyActive;
        }

        if (requestParameters.criteriaGetOnlyVisible !== undefined) {
            queryParameters['Criteria.GetOnlyVisible'] = requestParameters.criteriaGetOnlyVisible;
        }

        if (requestParameters.criteriaUserIdToEdit !== undefined) {
            queryParameters['Criteria.UserIdToEdit'] = requestParameters.criteriaUserIdToEdit;
        }

        if (requestParameters.criteriaLastActiveFrom !== undefined) {
            queryParameters['Criteria.LastActiveFrom'] = (requestParameters.criteriaLastActiveFrom as any).toISOString();
        }

        if (requestParameters.criteriaLastActiveTo !== undefined) {
            queryParameters['Criteria.LastActiveTo'] = (requestParameters.criteriaLastActiveTo as any).toISOString();
        }

        if (requestParameters.criteriaHasLateAccessAudit !== undefined) {
            queryParameters['Criteria.HasLateAccessAudit'] = requestParameters.criteriaHasLateAccessAudit;
        }

        if (requestParameters.criteriaDisplayName !== undefined) {
            queryParameters['Criteria.DisplayName'] = requestParameters.criteriaDisplayName;
        }

        if (requestParameters.criteriaRoleGroup !== undefined) {
            queryParameters['Criteria.RoleGroup'] = requestParameters.criteriaRoleGroup;
        }

        if (requestParameters.criteriaIsAccessRequestCompanySelection !== undefined) {
            queryParameters['Criteria.IsAccessRequestCompanySelection'] = requestParameters.criteriaIsAccessRequestCompanySelection;
        }

        if (requestParameters.criteriaRequireAnyOfAccessRight) {
            queryParameters['Criteria.RequireAnyOfAccessRight'] = requestParameters.criteriaRequireAnyOfAccessRight;
        }

        if (requestParameters.criteriaAlreadyInCompanyIds) {
            queryParameters['Criteria.AlreadyInCompanyIds'] = requestParameters.criteriaAlreadyInCompanyIds;
        }

        if (requestParameters.criteriaStatus) {
            queryParameters['Criteria.Status'] = requestParameters.criteriaStatus;
        }

        if (requestParameters.criteriaOverrideStatus) {
            queryParameters['Criteria.OverrideStatus'] = requestParameters.criteriaOverrideStatus;
        }

        if (requestParameters.criteriaHasCompanyAdminStatus) {
            queryParameters['Criteria.HasCompanyAdminStatus'] = requestParameters.criteriaHasCompanyAdminStatus;
        }

        if (requestParameters.criteriaGetOnlyExternalCompanies !== undefined) {
            queryParameters['Criteria.GetOnlyExternalCompanies'] = requestParameters.criteriaGetOnlyExternalCompanies;
        }

        if (requestParameters.criteriaGetOnlyInternalCompanies !== undefined) {
            queryParameters['Criteria.GetOnlyInternalCompanies'] = requestParameters.criteriaGetOnlyInternalCompanies;
        }

        if (requestParameters.criteriaHasTermsAndConditions !== undefined) {
            queryParameters['Criteria.HasTermsAndConditions'] = requestParameters.criteriaHasTermsAndConditions;
        }

        if (requestParameters.criteriaGlobalCustomerNumber !== undefined) {
            queryParameters['Criteria.GlobalCustomerNumber'] = requestParameters.criteriaGlobalCustomerNumber;
        }

        if (requestParameters.criteriaGetPgCodes !== undefined) {
            queryParameters['Criteria.GetPgCodes'] = requestParameters.criteriaGetPgCodes;
        }

        if (requestParameters.criteriaQuerySortByColumn !== undefined) {
            queryParameters['Criteria.QuerySortByColumn'] = requestParameters.criteriaQuerySortByColumn;
        }

        if (requestParameters.criteriaSortColumn !== undefined) {
            queryParameters['Criteria.SortColumn'] = requestParameters.criteriaSortColumn;
        }

        if (requestParameters.criteriaIsAscendingOrder !== undefined) {
            queryParameters['Criteria.IsAscendingOrder'] = requestParameters.criteriaIsAscendingOrder;
        }

        if (requestParameters.criteriaSortItems) {
            queryParameters['Criteria.SortItems'] = requestParameters.criteriaSortItems;
        }

        if (requestParameters.criteriaPage !== undefined) {
            queryParameters['Criteria.Page'] = requestParameters.criteriaPage;
        }

        if (requestParameters.criteriaPageSize !== undefined) {
            queryParameters['Criteria.PageSize'] = requestParameters.criteriaPageSize;
        }

        if (requestParameters.criteriaSkipTotalRowCount !== undefined) {
            queryParameters['Criteria.SkipTotalRowCount'] = requestParameters.criteriaSkipTotalRowCount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompaniesVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesGet(requestParameters: ApiCompaniesGetRequest, initOverrides?: RequestInit): Promise<CompaniesVm> {
        const response = await this.apiCompaniesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesIdCompanyApproversGetRaw(requestParameters: ApiCompaniesIdCompanyApproversGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompaniesIdCompanyApproversGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/{id}/CompanyApprovers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiCompaniesIdCompanyApproversGet(requestParameters: ApiCompaniesIdCompanyApproversGetRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiCompaniesIdCompanyApproversGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesIdCreditCheckGetRaw(requestParameters: ApiCompaniesIdCreditCheckGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreditCheckDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompaniesIdCreditCheckGet.');
        }

        if (requestParameters.totalEur === null || requestParameters.totalEur === undefined) {
            throw new runtime.RequiredError('totalEur','Required parameter requestParameters.totalEur was null or undefined when calling apiCompaniesIdCreditCheckGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.totalEur !== undefined) {
            queryParameters['totalEur'] = requestParameters.totalEur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/{id}/creditCheck`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditCheckDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesIdCreditCheckGet(requestParameters: ApiCompaniesIdCreditCheckGetRequest, initOverrides?: RequestInit): Promise<CreditCheckDto> {
        const response = await this.apiCompaniesIdCreditCheckGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesIdGetRaw(requestParameters: ApiCompaniesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompaniesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyVmFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesIdGet(requestParameters: ApiCompaniesIdGetRequest, initOverrides?: RequestInit): Promise<CompanyVm> {
        const response = await this.apiCompaniesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesIdPgCodesGetRaw(requestParameters: ApiCompaniesIdPgCodesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompaniesIdPgCodesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/{id}/PgCodes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiCompaniesIdPgCodesGet(requestParameters: ApiCompaniesIdPgCodesGetRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiCompaniesIdPgCodesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesIdTermsOfPaymentGetRaw(requestParameters: ApiCompaniesIdTermsOfPaymentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TermsOfPaymentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompaniesIdTermsOfPaymentGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/{id}/TermsOfPayment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsOfPaymentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesIdTermsOfPaymentGet(requestParameters: ApiCompaniesIdTermsOfPaymentGetRequest, initOverrides?: RequestInit): Promise<TermsOfPaymentDto> {
        const response = await this.apiCompaniesIdTermsOfPaymentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompaniesOverrideStatusPatchRaw(requestParameters: ApiCompaniesOverrideStatusPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("aad-jwt", []);
        }

        const response = await this.request({
            path: `/api/Companies/overrideStatus`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyOverrideStatusCommandToJSON(requestParameters.updateCompanyOverrideStatusCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiCompaniesOverrideStatusPatch(requestParameters: ApiCompaniesOverrideStatusPatchRequest, initOverrides?: RequestInit): Promise<CompanyDto> {
        const response = await this.apiCompaniesOverrideStatusPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
