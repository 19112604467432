/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyPartnerInfo,
    CompanyPartnerInfoFromJSON,
    CompanyPartnerInfoFromJSONTyped,
    CompanyPartnerInfoToJSON,
} from './CompanyPartnerInfo';

/**
 * 
 * @export
 * @interface CompanyPartnerInfoDto
 */
export interface CompanyPartnerInfoDto {
    /**
     * 
     * @type {CompanyPartnerInfo}
     * @memberof CompanyPartnerInfoDto
     */
    billingAddresses?: CompanyPartnerInfo;
    /**
     * 
     * @type {CompanyPartnerInfo}
     * @memberof CompanyPartnerInfoDto
     */
    shippingAddresses?: CompanyPartnerInfo;
    /**
     * 
     * @type {CompanyPartnerInfo}
     * @memberof CompanyPartnerInfoDto
     */
    consignees?: CompanyPartnerInfo;
}

export function CompanyPartnerInfoDtoFromJSON(json: any): CompanyPartnerInfoDto {
    return CompanyPartnerInfoDtoFromJSONTyped(json, false);
}

export function CompanyPartnerInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPartnerInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingAddresses': !exists(json, 'billingAddresses') ? undefined : CompanyPartnerInfoFromJSON(json['billingAddresses']),
        'shippingAddresses': !exists(json, 'shippingAddresses') ? undefined : CompanyPartnerInfoFromJSON(json['shippingAddresses']),
        'consignees': !exists(json, 'consignees') ? undefined : CompanyPartnerInfoFromJSON(json['consignees']),
    };
}

export function CompanyPartnerInfoDtoToJSON(value?: CompanyPartnerInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingAddresses': CompanyPartnerInfoToJSON(value.billingAddresses),
        'shippingAddresses': CompanyPartnerInfoToJSON(value.shippingAddresses),
        'consignees': CompanyPartnerInfoToJSON(value.consignees),
    };
}

