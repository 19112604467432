import React from "react";
import { TFunction } from "i18next";
import { RadioGroup, RadioOption } from "@abb/abb-common-ux-react";
import styled, { useTheme } from "styled-components";
import { IconWithTooltip } from "framework/components/IconWithTooltip";

export type TrueOrFalse = "true" | "false";

const Label = styled.label`
  display: block;
  font-size: ${(props) => props.theme.fonts.sizes.fontSizeS};
  font-family: ${(props) => props.theme.fonts.families.fontAbbMedium};
  margin-top: ${(props) => props.theme.sizes.sm};
  margin-bottom: ${(props) => props.theme.sizes.s};
`;

const RedAsterisk = styled.span`
  color: ${(props) => props.theme.colors.statusRed};
`;

const LabelWrapper = styled.span`
  display: inline-flex;
`;

interface DetailsRadioGroupInterface {
  title: string;
  value: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<TrueOrFalse | undefined>>;
  t: TFunction;
  firstOptionLabel?: string;
  secondOptionLabel?: string;
  isReversed?: boolean;
  isRequired?: boolean;
  tooltipText?: string;
  forceApproval?: boolean;
}

export const DetailsRadioGroup = ({
  t,
  title,
  value,
  setValue,
  firstOptionLabel,
  secondOptionLabel,
  isRequired,
  isReversed,
  tooltipText,
  forceApproval
}: DetailsRadioGroupInterface): JSX.Element => {
  const theme = useTheme();
  return (
    <div>
      <LabelWrapper>
        <Label>
          <span>{title}</span>
          {isRequired ? <RedAsterisk>&nbsp;*</RedAsterisk> : null}
        </Label>
        {tooltipText ? (
          <IconWithTooltip
            icon={"abb/information-circle-1"}
            tooltipText={tooltipText}
            style={{ marginLeft: theme.sizes.xs, marginTop: theme.sizes.xs }}
          />
        ) : null}
      </LabelWrapper>
      <RadioGroup
        orientation="horizontal"
        monochrome
        value={value}
        onChange={(v) => setValue(v as TrueOrFalse)}
      >
        <RadioOption
          text={firstOptionLabel ?? t("Yes")}
          value={isReversed ? "false" : "true"}
          disabled={forceApproval}
        ></RadioOption>
        <RadioOption
          text={secondOptionLabel ?? t("No")}
          value={isReversed ? "true" : "false"}
          disabled={forceApproval}
        ></RadioOption>
      </RadioGroup>
    </div>
  );
};
