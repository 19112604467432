/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessRequestAssigneeUserDto
 */
export interface AccessRequestAssigneeUserDto {
    /**
     * 
     * @type {number}
     * @memberof AccessRequestAssigneeUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestAssigneeUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestAssigneeUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessRequestAssigneeUserDto
     */
    email?: string | null;
}

export function AccessRequestAssigneeUserDtoFromJSON(json: any): AccessRequestAssigneeUserDto {
    return AccessRequestAssigneeUserDtoFromJSONTyped(json, false);
}

export function AccessRequestAssigneeUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestAssigneeUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function AccessRequestAssigneeUserDtoToJSON(value?: AccessRequestAssigneeUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
    };
}

