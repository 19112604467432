/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManageUserDto
 */
export interface ManageUserDto {
    /**
     * 
     * @type {number}
     * @memberof ManageUserDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    azureAdId: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    homeCompanyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManageUserDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManageUserDto
     */
    isGuest: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    userPrincipalName: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    position: string;
    /**
     * 
     * @type {Date}
     * @memberof ManageUserDto
     */
    lastActive: Date;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    userAccountTypeName: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    userAccountTypeDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof ManageUserDto
     */
    azureAdCountryCode?: string | null;
}

export function ManageUserDtoFromJSON(json: any): ManageUserDto {
    return ManageUserDtoFromJSONTyped(json, false);
}

export function ManageUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'azureAdId': json['azureAdId'],
        'homeCompanyId': json['homeCompanyId'],
        'active': json['active'],
        'isGuest': json['isGuest'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'userPrincipalName': json['userPrincipalName'],
        'position': json['position'],
        'lastActive': (new Date(json['lastActive'])),
        'userAccountTypeName': json['userAccountTypeName'],
        'userAccountTypeDescription': json['userAccountTypeDescription'],
        'countryCode': json['countryCode'],
        'azureAdCountryCode': !exists(json, 'azureAdCountryCode') ? undefined : json['azureAdCountryCode'],
    };
}

export function ManageUserDtoToJSON(value?: ManageUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'azureAdId': value.azureAdId,
        'homeCompanyId': value.homeCompanyId,
        'active': value.active,
        'isGuest': value.isGuest,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'userPrincipalName': value.userPrincipalName,
        'position': value.position,
        'lastActive': (value.lastActive.toISOString()),
        'userAccountTypeName': value.userAccountTypeName,
        'userAccountTypeDescription': value.userAccountTypeDescription,
        'countryCode': value.countryCode,
        'azureAdCountryCode': value.azureAdCountryCode,
    };
}

