/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyOverrideStatusCommand
 */
export interface UpdateCompanyOverrideStatusCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyOverrideStatusCommand
     */
    companyId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyOverrideStatusCommand
     */
    overrideStatus?: boolean | null;
}

export function UpdateCompanyOverrideStatusCommandFromJSON(json: any): UpdateCompanyOverrideStatusCommand {
    return UpdateCompanyOverrideStatusCommandFromJSONTyped(json, false);
}

export function UpdateCompanyOverrideStatusCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyOverrideStatusCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'overrideStatus': !exists(json, 'overrideStatus') ? undefined : json['overrideStatus'],
    };
}

export function UpdateCompanyOverrideStatusCommandToJSON(value?: UpdateCompanyOverrideStatusCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'overrideStatus': value.overrideStatus,
    };
}

