export const titleCaseToSentenceCase = (input: string): string => {
  return (
    input
      .match(/[A-Z][a-z]+|[0-9]+/g)
      ?.map((v, i) => {
        if (i !== 0) {
          return v.toLowerCase();
        }
        return v;
      })
      .join(" ") ?? ""
  );
};

// https://stackoverflow.com/a/14919494
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param decimalPlaces Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, decimalPlaces = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** decimalPlaces;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(decimalPlaces) + " " + units[u];
}

export const fromStrToBoolean = (str: string | null) => (str === "0" ? false : true);
