/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PartiallyActiveState {
    Active = 'Active',
    PartiallyActive = 'PartiallyActive',
    Inactive = 'Inactive'
}

export function PartiallyActiveStateFromJSON(json: any): PartiallyActiveState {
    return PartiallyActiveStateFromJSONTyped(json, false);
}

export function PartiallyActiveStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartiallyActiveState {
    return json as PartiallyActiveState;
}

export function PartiallyActiveStateToJSON(value?: PartiallyActiveState | null): any {
    return value as any;
}

