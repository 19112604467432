/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactOrderHandlingReasons,
    ContactOrderHandlingReasonsFromJSON,
    ContactOrderHandlingReasonsFromJSONTyped,
    ContactOrderHandlingReasonsToJSON,
} from './ContactOrderHandlingReasons';

/**
 * 
 * @export
 * @interface ContactOrderHandlingReasonDto
 */
export interface ContactOrderHandlingReasonDto {
    /**
     * 
     * @type {ContactOrderHandlingReasons}
     * @memberof ContactOrderHandlingReasonDto
     */
    reasonId?: ContactOrderHandlingReasons;
    /**
     * 
     * @type {string}
     * @memberof ContactOrderHandlingReasonDto
     */
    description?: string | null;
}

export function ContactOrderHandlingReasonDtoFromJSON(json: any): ContactOrderHandlingReasonDto {
    return ContactOrderHandlingReasonDtoFromJSONTyped(json, false);
}

export function ContactOrderHandlingReasonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactOrderHandlingReasonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonId': !exists(json, 'reasonId') ? undefined : ContactOrderHandlingReasonsFromJSON(json['reasonId']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ContactOrderHandlingReasonDtoToJSON(value?: ContactOrderHandlingReasonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonId': ContactOrderHandlingReasonsToJSON(value.reasonId),
        'description': value.description,
    };
}

