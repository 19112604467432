/* tslint:disable */
/* eslint-disable */
/**
 * Relays-Online
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModificationProduct,
    ModificationProductFromJSON,
    ModificationProductFromJSONTyped,
    ModificationProductToJSON,
} from './ModificationProduct';

/**
 * 
 * @export
 * @interface VerifyGmdModificationByMultipleSerialNumbersCommand
 */
export interface VerifyGmdModificationByMultipleSerialNumbersCommand {
    /**
     * 
     * @type {number}
     * @memberof VerifyGmdModificationByMultipleSerialNumbersCommand
     */
    modificationProcess: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyGmdModificationByMultipleSerialNumbersCommand
     */
    serialNumbers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VerifyGmdModificationByMultipleSerialNumbersCommand
     */
    materialCode: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyGmdModificationByMultipleSerialNumbersCommand
     */
    orderCode: string;
    /**
     * 
     * @type {ModificationProduct}
     * @memberof VerifyGmdModificationByMultipleSerialNumbersCommand
     */
    modificationProduct: ModificationProduct;
}

export function VerifyGmdModificationByMultipleSerialNumbersCommandFromJSON(json: any): VerifyGmdModificationByMultipleSerialNumbersCommand {
    return VerifyGmdModificationByMultipleSerialNumbersCommandFromJSONTyped(json, false);
}

export function VerifyGmdModificationByMultipleSerialNumbersCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyGmdModificationByMultipleSerialNumbersCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modificationProcess': json['modificationProcess'],
        'serialNumbers': json['serialNumbers'],
        'materialCode': json['materialCode'],
        'orderCode': json['orderCode'],
        'modificationProduct': ModificationProductFromJSON(json['modificationProduct']),
    };
}

export function VerifyGmdModificationByMultipleSerialNumbersCommandToJSON(value?: VerifyGmdModificationByMultipleSerialNumbersCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modificationProcess': value.modificationProcess,
        'serialNumbers': value.serialNumbers,
        'materialCode': value.materialCode,
        'orderCode': value.orderCode,
        'modificationProduct': ModificationProductToJSON(value.modificationProduct),
    };
}

