import { defaultSagaSuccessHandler } from "../../../../framework/sagas/sagaUtilities";
import {
  createGenericSaga,
  defaultSagaErrorHandler
} from "../../../../framework/sagas/sagaUtilities";
import { getApiRegistry } from "../../../../framework/state/apiRegistry";

export const translationSagas = {
  uploadTranslationFile: createGenericSaga(
    "translationSagas/uploadTranslationFile",
    getApiRegistry().translationApi.apiTranslationImportPost.bind(getApiRegistry().translationApi),
    {
      takeEvery: false,
      onSuccess: (requestPayload, responsePayload) =>
        defaultSagaSuccessHandler(
          `Translation file uploaded successfully. Following language codes are available: ${responsePayload.availableLanguages.join(
            ", "
          )}`,
          10000
        )(),
      onFail: (requestPayload, errorTitle, errorDetails, errorDetail) =>
        defaultSagaErrorHandler(
          requestPayload,
          errorTitle,
          errorDetails,
          errorDetail,
          undefined,
          false,
          true
        )
    }
  ),
  getAvailableLanguages: createGenericSaga(
    "translationSagas/getAvailableLanguages",
    getApiRegistry().translationApi.apiTranslationLanguagesGet.bind(
      getApiRegistry().translationApi
    ),
    {
      takeEvery: false,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  ),
  exportTranslationFile: createGenericSaga(
    "translationSagas/exportTranslationFile",
    getApiRegistry().translationApi.apiTranslationExportGetRaw.bind(
      getApiRegistry().translationApi
    ),
    {
      takeEvery: false,
      onSuccess: undefined,
      onFail: defaultSagaErrorHandler
    }
  )
};
